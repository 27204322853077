<template>
  <!-- v-for="comment in comments"
            :key="comment._id" -->
  <div
    v-observe-visibility="visible => $emit('visibility-change', visible)"
    :class="[
      'relative border p-12 pb-8 group rounded-lg transition-all',
      'hover:bg-white dark:hover:bg-darkGray-1000',
      'border-gray-200 dark:border-darkGray-700',
      !comment.read && 'bg-yellow-10 dark:bg-opacity-10 hover:bg-yellow-10 dark:hover:bg-opacity-20'
    ]"
    @click.stop="$emit('comment-click')"
  >
    <!-- COMMENT BODY -->

    <div class="flex items-start justify-between mb-4">
      <div class="dark:text-darkGray-300">
        <!-- v-if="comment.createdBy" -->
        <AvatarUser
          :user-id="comment.createdBy"
          :fallback-name="comment.name"
          :size="32"
          :timestamp="comment.createdAt"
          font-size="14"
          :class="[comment.isResolved && 'opacity-40','font-semibold']"
        />
      </div>
      <!-- COMMENT ACTIONS -->

      <b-dropdown
        v-if="user && !isCommentBeingEdited && !isViewer"
        no-caret
        dropleft
        toggle-class="invisible text-gray-600 btn btn-icon-sm group-hover:visible"
        menu-class="w-140 dropdown-menu-comments text-14"
        variant="link"
      >
        <template #button-content>
          <span class="icon_v2-so_menu-circle-vertical"></span>
        </template>
        <b-dropdown-item
          v-if="!activeComment && user._id === comment.createdBy"
          @click.stop="$emit('comment-edit')"
        >
          <span class="mr-8 icon_v2-so_pencil"></span>
          Edit
        </b-dropdown-item>
        <b-dropdown-item
          v-if="comment.isResolved"
          @click.stop="$emit('comment-set-resolved', false)"
        >
          <span class="mr-8 icon_v2-so_undo-circle"></span>
          Reopen
        </b-dropdown-item>
        <b-dropdown-item
          v-if="checkDeletePrivilege(comment)"
          @click.stop="$emit('comment-remove')"
        >
          <span class="mr-8 icon_v2-so_trash text-18"></span>
          Delete
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- COMMENT MESSAGE -->

    <div
      :class="[comment.isResolved
        ? 'text-gray-500 dark:text-darkGray-400'
        : 'dark:text-darkGray-300']"
    >
      <div
        class="whitespace-pre-wrap text-14"
        :class="[!user && !comment.isResolved &&
          comment.documentNode ? 'mb-32' : 'mb-8']"
      >
        {{ comment.message }}
      </div>


      <div class="flex items-center justify-between space-x-16">
        <!-- RESOLVED BUTTON -->
        <b-button
          v-if="user && !comment.isResolved && !isViewer"
          variant="link"
          class="px-0 py-0 font-medium text-gray-600 text-14 hover:text-green-600"
          @click.stop="$emit('comment-set-resolved', true)"
        >
          <span class="w-16 mr-4 icon_v2-so_tick text-16"></span> Resolve
        </b-button>
        <!-- RESOLVED LABEL -->
        <span
          v-if="comment.isResolved"
          class="inline-flex items-center font-medium text-gray-500 text-14 dark:text-darkGray-400"
        >
          <span class="w-16 mr-4 icon_v2-so_tick text-16"></span> Resolved
        </span>

        <!-- LINKED COMMENT FLAG -->
        <button
          v-if="comment.documentNode"
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Go to content block`"
          variant="link"
          :class="[
            'text-14',
            'bg-green-50 text-green-700 ring-1 ring-green-200',
            'flex items-end justify-start',
            'p-8 overflow-hidden',
            'absolute right-0 bottom-0',
            'rounded-tl-2xl transition-all',
            'hover:bg-green-200 hover:text-green-800',
            'hover:ring-8 hover:ring-green-200 hover:ring-opacity-50 focus:outline-none',
            comment.isResolved && 'opacity-50',
          ]"
          @click.stop="$emit('comment-click')"
        >
          <span class="icon-link text-14 w-14" />
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
import AvatarUser from '@/components/AvatarUser.vue'

export default defineComponent({
  name: 'CommentsItem',
  components: {
    AvatarUser,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    comment: {
      type: Object,
      default: () => {},
    },
    activeComment: {
      type: Object,
      default: () => {},
    },
    commentNodeId: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const isViewer = inject('isViewer', false)

    const checkDeletePrivilege = comment => {
      if (
        props.user
        && (!props.activeComment || comment._id !== props.activeComment._id)
      ) {
        return true
      }
      return false
    }
    const isCommentBeingEdited = computed(() => {
      if (props.activeComment && props.activeComment._id === props.comment._id) {
        return true
      }
      return false
    })

    return {
      isViewer,
      checkDeletePrivilege,
      isCommentBeingEdited,
    }
  },

})
</script>

<style lang="scss" scoped>

</style>
