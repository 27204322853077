<template>
  <svg
    class="svg-indicator"
    :style="{ width: `${variant.size}px`, height: `${variant.size}px` }"
  >
    <circle
      class="svg-indicator-track"
      :cx="center"
      :cy="center"
      fill="transparent"
      :r="radius"
      :stroke-width="variant.trackWidth"
    />
    <circle
      class="svg-indicator-indication"
      :cx="center"
      :cy="center"
      fill="transparent"
      :r="radius"
      :stroke-width="variant.indicatorWidth"
      :stroke-dasharray="dashArray"
      :stroke-dashoffset="dashOffset"
    />
  </svg>
</template>
<script>
import { computed } from '@vue/composition-api'

const variants = {
  default: {
    size: 40,
    trackWidth: 10,
    indicatorWidth: 10,
  },
  small: {
    size: 24,
    trackWidth: 4,
    indicatorWidth: 4,
  },
}

export default {
  name: 'PieProgressBar',
  props: {
    ratio: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const percent = computed(() => Math.round(props.ratio * 100))
    const variant = computed(() => variants[props.small ? 'small' : 'default'])

    const center = computed(() => variant.value.size / 2)
    const radius = computed(() => center.value - (
      variant.value.trackWidth > variant.value.indicatorWidth
        ? variant.value.trackWidth
        : variant.value.indicatorWidth
    ))

    const dashArray = computed(() => 2 * Math.PI * radius.value)
    const dashOffset = computed(() => dashArray.value * ((100 - percent.value) / 100))

    return {
      variant,
      center,
      radius,
      dashArray,
      dashOffset,
    }
  },
}
</script>
<style lang="scss" scoped>
.svg-indicator {
  transform: rotate(-90deg);
}

.svg-indicator-track,
.svg-indicator-indication {
  fill: transparent;
}

.svg-indicator-track {
  stroke: $gray-200;
}

.svg-indicator-indication {
  stroke: $gray-400;
  stroke-linecap: round;
}
</style>
