<template>
  <ModalBasic
    size="xl"
    :no-close="isLoading"
    :hide-footer="true"
    content-class="modal-content-template-preview"
    body-class="modal-body-template-preview"
    footer-class="modal-footer-template-preview"
    v-bind="$attrs"
    close-text="Close Preview"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <!-- <div v-if="isProjectSelectVisible" > -->
      <div
        v-if="isProjectSelectVisible"
        class="absolute inset-0 z-10 flex
        items-center justify-center bg-black/40 filter backdrop-blur-md"
      >
        <div
          class="absolute top-8 right-8 card w-[420px]"
        >
          <b-form v-if="isProjectSelectVisible" @submit.prevent="$emit('use', state)">
            <OrganizationFolderTree
              v-model="state.selectedFolderId"
              :organization-id="organizationId"
              @project-selected="projectSelected"
            />
            <Button
              type="submit"
              size="sm"
              variant="primary"
              class="mt-24 w-full"
              :disabled="isDisabled"
            >
              Confirm &amp; Use Template
            </Button>
          </b-form>
        </div>
      </div>
      <GuestTemplateDocument
        :document-id="template.documentId"
        :category="template.template_category.name"
      />
    </template>
    <template #header>
      <Button
        class="whitespace-nowrap"
        size="xl"
        @click="useTemplate"
      >
        <span class="icon_v2-so_tick mr-4 text-24 w-24" />Use this template
      </Button>
    </template>
  </ModalBasic>
</template>


<script>
import { computed, ref, provide, defineComponent, reactive } from '@vue/composition-api'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import { TEMPLATE_CATEGORY } from '@/v2/services/documents/documentsTypes'
import GuestTemplateDocument from '@/components/GuestTemplateDocument.vue'
import Button from '@/components/Button.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import OrganizationFolderTree from '@/components/OrganizationFolderTree.vue'

export default defineComponent({
  name: 'DocumentTemplateModal',
  components: {
    ModalBasic,
    Button,
    GuestTemplateDocument,
    OrganizationFolderTree,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const isLoading = ref(false)
    const isProjectSelectVisible = ref(false)
    const state = reactive({
      selectedFolderId: null,
      project: null,
    })

    provide('hideNodeComments', true)
    provide('hideNodeActionItems', true)

    const { organizationId } = useRouteParams(['organizationId'])
    const isOrganizationLevel = computed(
      () => Boolean(TEMPLATE_CATEGORY[props.template?.template_category?.name])
    )

    const useTemplate = () => {
      if (isOrganizationLevel.value) {
        context.emit('use', state)
      } else {
        isProjectSelectVisible.value = !isProjectSelectVisible.value
      }
    }
    const projectSelected = projectId => {
      state.project = projectId
    }

    const isDisabled = computed(() => !state.project || (state.project && !state.selectedFolderId))


    return {
      organizationId,
      isProjectSelectVisible,
      useTemplate,
      isLoading,
      projectSelected,
      state,
      isDisabled,
    }
  },
})
</script>

<style lang="scss" module>
// .share-link-text {
//   flex: 1;
//   h4 {
//     display: flex;
//     align-items: center;
//     :global(.badge) {
//       margin-left: 4px;
//     }
//   }
// }

// .share-btn-link {
//   padding-left: 10px;
//   padding-right: 10px;
// }
// .enabled {
//   font-weight: $regular;
//   color: $green;
// }
// .share-note {
//   border-top: 1px solid rgba($gray-400, 0.7);
//   background-color: $green-10;
//   margin: 48px -32px 0 -32px;
//   padding: 28px 32px 30px 32px;
//   list-style: none;
//   border-bottom-left-radius: $border-radius * 4;
//   border-bottom-right-radius: $border-radius * 4;
//   li {
//     position: relative;
//     padding-left: 32px;
//     padding-right: 42px;
//     margin-bottom: 16px;
//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
//   p {
//     font-size: $fs16;
//     color: $gray-800;
//     margin: 0;
//   }
//   h4 {
//     font-size: $fs16;
//     font-weight: $medium;
//     margin: 0 0 4px;
//   }
//   :global(.icon_v2-so_info) {
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }
</style>
