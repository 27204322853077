import Vue from 'vue'
import { format as dateFnsFormat } from 'date-fns'

const ensureDate = (date, disregardTime) => {
  if (typeof date !== 'string') return date; // Must be Date obj or number => go ahead

  if (disregardTime) {
    // Disregard time portion from date.
    const [dateString] = date.split('T') // Remove time
    // Get date info
    const [year, nextMonthIndex, day] = dateString.split('-').map(Number);
    // Build new, local date and enforce 23:49 (end of day) as time
    return new Date(year, nextMonthIndex - 1, day, 23, 59);
  }

  return new Date(date)
}

Vue.filter('dateCompact', (value, { format = 'E, MMM d', disregardTime = false } = {}) => (value
  ? dateFnsFormat(ensureDate(value, disregardTime), format)
  : ''))
