<template>
  <div class="screen:flex flex-col relative w-full">
    <!-- ------------------------------------------------ -->
    <!-- DOCUMENT CONTENT ------------------------------- -->
    <!-- ------------------------------------------------ -->
    <DocumentGuestResolver
      v-slot="{ component }"
      :document="document"
    >
      <component
        :is="component"
        v-if="document"
        :document="document"
        :view-context="DOCUMENT_VIEW_DEFAULTS"
      />
    </DocumentGuestResolver>
  </div>
</template>

<script>
import { computed, provide, toRef, onUnmounted, onMounted, getCurrentInstance, watch, defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import debounce from '@/v2/lib/helpers/debounce'
import useWindowSize from '@/v2/lib/composition/useWindowSize'
import { useNodeWidth, widthSize } from '@/v2/lib/composition/useNodeWidth'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import { DOCUMENT_VIEW_DEFAULTS } from '@/v2/services/documents/documentsTypes'
import ScrollContainer from '@/components/ScrollContainer.vue'
import DocumentViewerContent from '@/components/Document/Viewer/DocumentViewerContent.vue'
import DocumentViewerProgress from '@/components/Document/Viewer/DocumentViewerProgress.vue'
import DocumentEditorLastUpdate from '@/components/Document/Editor/DocumentEditorLastUpdate.vue'
import ActionItems from '@/components/ActionItems.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import DocumentGuestResolver from '@/components/DocumentGuestResolver.vue'


const { useActions } = createNamespacedHelpers('documentEditor')
const { useState: useStateGlobalFlags } = createNamespacedHelpers('globalFlags')

export default defineComponent({
  name: 'DocumentViewerClientPortalDesktop',
  components: {
    DocumentViewerContent,
    DocumentViewerProgress,
    ScrollContainer,
    DocumentEditorLastUpdate,
    ActionItems,
    ProjectTheme,
    DocumentGuestResolver,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
    print: {
      type: Boolean,
      default: false,
    },
    isClientPortal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { DocumentStructure } = vm.$FeathersVuex.api

    const { width, resizeEventListener } = useWindowSize()
    const { load } = useNodeWidth()

    provide('isEditor', computed(() => false))
    provide('isViewer', computed(() => true))
    provide('isViewerPDF', computed(() => props.print))
    provide('isLocked', computed(() => props.document.isApproved))
    provide('isSandbox', computed(() => false))
    provide('document', toRef(props, 'document'))


    useBrowserTitle(computed(() => (props.document
      ? `${props.document.title} - ${props.organization.name}`
      : 'SuperOkay')))

    const { sidebarIsExpanded: isExpandedFromStore } = useStateGlobalFlags(['sidebarIsExpanded'])

    const sidebarIsExpanded = computed(
      () => (!props.isClientPortal ? false : isExpandedFromStore.value)
    )

    const {
      addNodeWidth,
      clearNodesWidth,
    } = useActions([
      'addNodeWidth',
      'clearNodesWidth',
    ])

    const updateNodesWidth = debounce(async () => {
      const structure = await DocumentStructure.getFromStore(props.document.structure)
      structure?.nodes.forEach(async nodeId => {
        const nodeWidth = await load(nodeId)
        addNodeWidth(widthSize({ [nodeId]: nodeWidth }))
      })
    }, 500)

    onMounted(() => {
      resizeEventListener()
    })

    onUnmounted(() => {
      resizeEventListener()
      clearNodesWidth()
    })

    watch(
      () => width.value,
      () => updateNodesWidth()
    )

    // fetchtotalActionItemsCount()

    return {
      DOCUMENT_VIEW_DEFAULTS,
      // comments state

      // toc

      // comments

      // QA nav

      // nonAnsweredQAs,


      // CP Sidebar State
      sidebarIsExpanded,
    }
  },
})
</script>

<style lang="postcss" module>
@media print {
  .toc {
    display: none;
  }
}

@media print {
  .editor-stats-wrapper {
    @apply border border-gray-200 dark:border-darkGray-700 rounded-md mb-24;
  }
}

@media screen {
  .editor-stats-wrapper {
    @apply border-b border-gray-200 dark:border-darkGray-700;
  }
}
</style>

<style lang="scss" scoped>

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-contents-enter-active {
  transition: all .1s ease;
}
.slide-fade-contents-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-contents-enter, .slide-fade-contents-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-266px);
  opacity: 0;
}


.slide-fade-sidebar-enter-active {
  transition: all .2s ease;
}
.slide-fade-sidebar-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-sidebar-enter, .slide-fade-sidebar-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-320px);
  opacity: 0;
}
</style>
