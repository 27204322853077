<template>
  <div v-fragment>
    <div
      :class="[
        'flex flex-col ',
        {
          'items-start': styling.objectAlign === 'left',
          'items-center': styling.objectAlign === 'center',
          'items-end': styling.objectAlign === 'right',
          'items-stretch': styling.objectAlign === 'stretch',
        },
      ]"
    >
      <b-link
        v-b-tooltip.hover.top.v-info.dh0.ds200="urlPreview"
        :href="buttonURL"
        :target="contentBlock.settings.newTab ? '_blank':'_self'"
        :style="{
          ...cssVars,
        }"
        :title="contentBlock.settings.label"
        :class="[
          'so-custom-button',
          'portal-paragraph-font',
          styling.objectAlign === 'stretch' && 'w-full',
        ]"
      >
        {{ contentBlock.settings.label }}
      </b-link>

      <DocumentContentBlockButtonToolbarPortal
        :popover-target-id="settingsPopoverTargetId"
        :content-block="contentBlock"
        :node-id="nodeId"
        @update="(data, options) => $emit('update', data, options)"
      />
      <!-- -------------------- -->
      <!-- TOGGLE NODE SETTINGS -->
      <!-- -------------------- -->
      <div
        v-if="isEditable"
        :class="[
          'mt-16 space-x-4 bg-gray-100 p-4 rounded-md inline-flex transition-opacity print:hidden',
          isHover || isFocus ? 'opacity-100' : 'opacity-30',
        ]"
      >
        <b-button
          v-if="isEditable"
          variant="neutral"
          size="xs"
          class="grow"
          @click="openSettings"
        >
          <span class="mr-6 icon-toolbar-settings" />
          Edit Button
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, inject, getCurrentInstance, onMounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import tinycolor from 'tinycolor2'
import DocumentContentBlockButtonToolbarPortal from './DocumentContentBlockButtonToolbarPortal.vue'

const { useState } = createNamespacedHelpers('documentEditor')

const sizes = {
  S: {
    fontSize: 14,
    height: 32,
    paddingX: 16,
  },
  M: {
    fontSize: 16,
    height: 40,
    paddingX: 24,
  },
  L: {
    fontSize: 18,
    height: 56,
    paddingX: 32,
  },
}


export default defineComponent({
  name: 'DocumentContentBlockButton',
  components: {
    DocumentContentBlockButtonToolbarPortal,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
    isFocus: Boolean,
    isHover: {
      type: Boolean,
      default: false,
    },

  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')

    const vm = getCurrentInstance().proxy

    const { lastCreatedNodeId } = useState(['lastCreatedNodeId'])
    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const settingsPopoverTargetId = computed(
      () => `settings-popover-${props.nodeId}`
    )

    const buttonURL = computed(() => (props.contentBlock?.settings?.url.startsWith('http')
      ? props.contentBlock?.settings?.url
      : `//${props.contentBlock?.settings?.url}`))

    const fontSize = computed(() => sizes[props.contentBlock.settings.size].fontSize)
    const buttonHeight = computed(() => sizes[props.contentBlock.settings.size].height)
    const paddingX = computed(() => sizes[props.contentBlock.settings.size].paddingX)
    const dynamicBorderRadius = computed(() => {
      const radiusRatio = props.contentBlock.settings.border / 100
      return Math.round(
        radiusRatio * buttonHeight.value
      )
    })

    const urlPreview = computed(() => {
      // If empty, return nothing
      if (!props.contentBlock.settings.url) return ''
      // If it does not need shortening
      if (props.contentBlock.settings.url.length < 28) return props.contentBlock.settings.url
      // Return shortened
      return `${props.contentBlock.settings.url.slice(0, 28)}...`
    })

    const cssVars = computed(() => ({
      color: props.contentBlock.settings.textColor,
      '--so-custom-button-font-size': `${fontSize.value}px`,
      '--so-custom-button-border-radius': `${dynamicBorderRadius.value}px`,
      '--so-custom-button-background-color': props.contentBlock.settings.backgroundColor,
      '--so-custom-button-text-color': props.contentBlock.settings.textColor,
      '--so-custom-button-height': `${buttonHeight.value}px`,
      '--so-custom-button-padding-x': `${paddingX.value}px`,
      '--so-custom-button-hover-background-color': tinycolor(props.contentBlock.settings.backgroundColor).darken().toString(),
    }))

    const emitShowPopover = () => setTimeout(
      () => vm.$root.$emit('bv::show::popover', settingsPopoverTargetId.value),
      0
    )
    const openSettings = () => {
      emitShowPopover()
    }

    onMounted(() => lastCreatedNodeId.value === props.nodeId && emitShowPopover())

    return {
      settingsPopoverTargetId,
      cssVars,
      openSettings,
      isEditable,
      urlPreview,
      buttonURL,
    }
  },
})
</script>

<style lang="postcss" scoped>
  .so-custom-button {
    background: var(--so-custom-button-background-color);
    color: var(--so-custom-button-text-color);
    border-radius: var(--so-custom-button-border-radius);
    font-size: var(--so-custom-button-font-size);
    height: var(--so-custom-button-height);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--so-custom-button-padding-x);
    line-height: 1;
    transition: all .2s ease-in;
    @apply font-semibold shadow hover:shadow-lg active:shadow;
  }

  .so-custom-button:hover {
    transition: all .2s ease-out;
    background-color: var(--so-custom-button-hover-background-color);
  }
  .so-custom-button:active {
    transition: all .2s ease-in;
    background-color: var(--so-custom-button-background-color);
  }
</style>
