
<ProjectTimelineRendererSingle :notification="notification">
  <template #action>
    <div class="drafted-badge ml-6">
      <span class="icon_v2-so_tick text-16" />
      <span class="ml-2 mr-2 font-medium leading-none text-12 capitalize">
        {{ $t('common.drafted') }}
      </span>
    </div>
  </template>
  <template #default="{ document, isClientPortal }">
    <DocumentLink v-slot="{ navigate }" :document="document">
      <FileDocList
        :document="document"
        standalone
        :show-delete="false"
        :show-analytics="!isClientPortal"
        show-status
        @open="navigate"
      />
    </DocumentLink>
  </template>
</ProjectTimelineRendererSingle>
