import { isObject } from 'ramda-extension'
import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import projectsService, { servicePath } from './projectsService'
import { PROJECT_MODULE_ICON } from './projectsTypes'


class Project extends BaseModel {
  static modelName = 'Project'

  static instanceDefaults() {
    return {
      name: '',
      archived: false,
      client: null, // ''
      currency: 'USD',
      organization: null,
      brandingColors: [],
      brandingFontHeading: '',
      brandingFontParagraph: '',
      portalTheme: 'light',
      portalAccentColor: '#23B839',
      portalSecondaryColor: '#848E99',
      portalLinkColor: '',
      portalHeadingFont: 'Inter',
      portalHeaderTheme: 'dark',
      portalHeaderBackgroundColor: '#011B29',
      portalHeaderImage: 'https://s3.eu-west-2.amazonaws.com/superokay/ee6985b45a318a1e5c506a85dab303527bce4a66956050b0b4bf800814d7a2a7.jpeg',
      portalHeaderImageOpacity: 10,
      portalQuickLinks: [],
      portalLinkedApps: [],
      portalIntegrations: [],

      // -- action items
      taskSections: [],

      // -- modules
      modules: {
        documents: {
          title: '',
          icon: PROJECT_MODULE_ICON.iconDocuments,
          enabled: true,
          enabledPortal: true,
        },
        tasks: {
          title: '',
          icon: PROJECT_MODULE_ICON.iconActionItems,
          enabled: true,
          enabledPortal: true,
        },
        timeline: {
          title: '',
          icon: PROJECT_MODULE_ICON.iconTimeline,
          enabled: true,
          enabledPortal: true,
        },
        packagedServices: {
          title: PROJECT_MODULE_ICON.iconPackagedServices,
          icon: '',
          enabled: false,
          enabledPortal: false,
        },
        pinnedFolders: {
          title: '',
          enabled: false,
          enabledPortal: false,
        },
        pages: {
          title: '',
          enabled: false,
          enabledPortal: false,
        },
        quickLinks: {
          enabled: true,
          enabledPortal: true,
        },
        embeddedApps: {
          enabled: false,
          enabledPortal: false,
        },
        integrations: {
          enabled: false,
          enabledPortal: false,
        },
        recentlyUpdated: {
          enabled: true,
          enabledPortal: true,
        },
      },

      pinnedFolders: [],
      members: [],

      statistics: {
        cost: 0,
        notApprovedCost: 0,
        approvedCost: 0,
      },
      tags: [],
      packagedServices: [],
      pages: [],
      permissions: {},
    }
  }

  static setupInstance(data, { models }) {
    const { Client, User } = models.api
    const _data = { ...data }


    if (isObject(_data.client$)) {
      _data.client$ = new Client(_data.client$)
    }

    if (isObject(_data.updatedBy$)) {
      _data.updatedBy$ = new User(_data.updatedBy$)
    }

    return _data
  }

  get isDarkTheme() {
    return this.portalTheme === 'dark'
  }
}

export default makeServicePlugin({
  Model: Project,
  service: projectsService,
  servicePath,
})

// projectsService.on('patched', proj => console.log('patched', proj.taskSections))
