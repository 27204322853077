
<ModalBasic
  :no-close="false"
  :hide-footer="true"
  :show-back="isSelectedTemplate"
  :content-class="$style['modal-content-template']"
  :header-class="$style['modal-header-template']"
  :body-class="$style['modal-body-template']"
  close-text="Close"
  footer-class="modal-footer-tertiary"
  size="xl"
  :dialog-class="$style['modal-dialog-template']"
  v-bind="$attrs"
  v-on="$listeners"
  @back="onTemplatePreview(null)"
>
  <template #header-buttons>
    <Button
      v-if="isSelectedTemplate"
      type="button"
      variant="primary"
      class="whitespace-nowrap"
      @click="useTemplate"
    >
      <span class="w-20 mr-4 icon_v2-so_tick text-20"></span>Use Template
    </Button>
  </template>
  <template #body>
    <DocumentFromTemplate
      :templates-category="templatesCategory"
      :template-preview="templatePreview"
      :show-blank="true"
      :excluded-categories="excludedCategories"
      @select="useTemplate"
      @preview="onTemplatePreview"
    />
  </template>
</ModalBasic>
