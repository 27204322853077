<template>
  <div
    class="project-task-list-item group p-8 flex flex-col gap-8 px-10 py-6'"
    :class="{
      'hover:bg-gray-100 dark:hover:bg-darkGray-900': !$isMobile,
    }"
    v-on="$listeners"
  >
    <div
      class="flex items-start gap-8 "
      @dblclick="!isViewer && $emit('edit-item')"
    >
      <button
        v-if="!hideDragHandle"
        class="
          w-16 flex items-center justify-center
          text-gray-600 hover:text-gray-700 opacity-25 group-hover:opacity-100
          dark:text-darkGray-400 dark:hover:text-darkGray-400
        "
        title="Drag to move"
      >
        <span class="icon_v2-so_drag" />
      </button>
      <div @click.stop>
        <b-form-checkbox
          :checked="task.isResolved"
          class="custom-checkbox-list"
          @change="val => $emit('update', task._id, { isResolved: val })"
        />
      </div>
      <div
        class="flex-1 w-128 flex items-center justify-between"
      >
        <div
          class="text-gray-800 dark:text-white flex flex-wrap gap-8"
        >
          <div
            class="text-14"
            :class="{
              'resolved': task.isResolved,
              'pending': !task.isResolved,
              'due': !task.isResolved && task.isDue
            }"
          >
            {{ task.title }}
          </div>
          <div
            v-if="!hideDeleteButton"
            class="items-center gap-8 hidden"
            :class="{
              'group-hover:flex opacity-25 group-hover:opacity-100': !$isMobile,
            }"
          >
            <ButtonIcon
              icon="icon_v2-so_trash"
              class="dark:text-darkGray-400"
              size="18"
              @click.stop="$emit('remove')"
            />
          </div>
        </div>
        <div
          class="flex items-center gap-8 mt-4 self-start"
        >
          <ActionItemDateDisplay
            v-if="task.dueDate && !task.isResolved"
            v-slot="{ displayDate }"
            :item="task"
          >
            <span class="icon-util-calendar text-16" />
            {{ displayDate.message }}
          </ActionItemDateDisplay>
          <ActionItemAssignedTo :action-item="task" />
          <span
            v-if="task.notes"
            class="text-16 icon_v2-so_file"
          />
          <ClientVisibilityBadge
            v-if="!hideVisibilityBadge && task.public"
            :visible="task.public"
            variant="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useCurrentMember } from '@/v2/services/myMembers/compositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import ClientVisibilityBadge from '@/components/ClientVisibilityBadge.vue'
import ActionItemDateDisplay from '@/components/ActionItemDateDisplay.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ActionItemAssignedTo from '@/components/ActionItemAssignedTo.vue'

export default defineComponent({
  name: 'ProjectTaskListItem',
  components: {
    ClientVisibilityBadge,
    ActionItemDateDisplay,
    ButtonIcon,
    ActionItemAssignedTo,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    isViewer: {
      type: Boolean,
      default: false,
    },
    hideDragHandle: {
      type: Boolean,
      default: false,
    },
    hideVisibilityBadge: {
      type: Boolean,
      default: false,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showNote = ref(false)
    const { isClientContact } = useCurrentMember()
    const { user } = useUser()
    const isItemAuthor = user.value?._id === props.task.createdBy

    return {
      showNote,
      isClientContact,
      isItemAuthor,
      dense: false,
      disableDrag: false,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .resolved {
    @apply text-green-600;
  }

  .pending {
    @apply text-gray-500;
  }

  .due {
    @apply text-red-500;
  }
</style>
