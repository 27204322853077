
<nav>
  <ProjectTheme :project="project">
    <SidebarGroup>
      <!-- dashboard -->
      <SidebarMenu>
        <SidebarMenuLink :to="{ name: routes.projectDashboard }">
          <SidebarItemBasic icon="icon_v2-so_home" label="Dashboard" />
        </SidebarMenuLink>

        <!-- tasks -->
        <SidebarMenuLink
          v-if="projectModules.tasks.enabled"
          :to="{ name: routes.projectTasks }"
          activate-on-child-route
        >
          <SidebarItemBasic
            :icon="projectModules.tasks.icon || 'icon_v2-so_action-items'"
            :label="projectModules.tasks.title || $t('modules.tasks')"
          >
            <!-- <template #right>
              <ProjectNavModuleVisibility :enabled="projectModules.tasks.enabledPortal" />
            </template> -->
          </SidebarItemBasic>
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>

    <SidebarGroup
      v-if="groupEnabled.files"
      label="Files"
    >
      <SidebarMenu>
        <!-- docs & files -->
        <template v-if="projectModules.documents.enabled">
          <mq-layout :mq="['sm', 'md', 'lg']">
            <SidebarMenuLink
              :to="{
                name: routes.projectFsFolder,
                params: {
                  folderId: project.rootFolder,
                  projectId: project._id,
                },
              }"
              activate-on-child-route
              :activate-on-parent-routes="[routes.projectFsFolder]"
            >
              <SidebarItemBasic
                :icon="projectModules.documents.icon || 'icon_v2-so_folder'"
                :label="projectModules.documents.title || $t('modules.docs-and-files')"
              >
                <!-- <template #right>
                  <ProjectNavModuleVisibility :enabled="projectModules.documents.enabledPortal" />
                </template> -->
              </SidebarItemBasic>
            </SidebarMenuLink>
          </mq-layout>
          <mq-layout mq="xl+">
            <SidebarTree
              :node="folders"
              :hide-empty-nodes="false"
            >
              <template #folder="{ node, isExpanded }">
                <SidebarTreeItemFolder :node="node" :is-open="isExpanded" />
              </template>
            </SidebarTree>
          </mq-layout>
        </template>

        <template v-if="projectModules.pinnedFolders.enabled">
          <SidebarItemFolder
            v-for="pinnedFolder in pinnedFolders"
            :key="`pinned-folder-${pinnedFolder._id}`"
            :document="pinnedFolder"
            pinned
          />
        </template>
      </SidebarMenu>
    </SidebarGroup>

    <SidebarGroup v-if="groupEnabled.apps" label="Apps">
      <!-- <template #right>
        <ProjectNavModuleVisibility :enabled="projectModules.embeddedApps.enabledPortal" />
      </template> -->
      <SidebarMenu>
        <!-- pinned apps -->
        <SidebarMenuLink
          v-for="app in project.portalLinkedApps"
          :key="app._id"
          :to="{ name: routes.projectLinkedApp, params: { linkedAppId: app._id } }"
        >
          <SidebarItemBasic
            :label="app.title"
            :icon="app.icon || 'icon_v2-so_apps'"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>

    <SidebarGroup v-if="groupEnabled.packagedServices" label="Services">
      <!-- <template #right>
        <ProjectNavModuleVisibility :enabled="projectModules.packagedServices.enabledPortal" />
      </template> -->
      <SidebarMenu>
        <SidebarMenuLink
          v-for="service in services"
          :key="`service-${service._id}`"
          :to="{
            name: routes.projectService,
            params: {
              organization,
              project,
              documentId: service._id
            }
          }"
        >
          <SidebarItemBasic
            :icon="projectModules.packagedServices.icon || 'icon_v2-so_open-box'"
            :label="service.title"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>

    <SidebarGroup v-if="groupEnabled.pages" :label="projectModules.pages.title || 'Pages'">
      <!-- <template #right>
        <ProjectNavModuleVisibility :enabled="projectModules.pages.enabledPortal" />
      </template> -->
      <SidebarMenu>
        <SidebarMenuLink
          v-for="page in pages"
          :key="`page-${page._id}`"
          :to="{
            name: routes.projectPage,
            params: {
              organization,
              project,
              documentId: page._id
            }
          }"
        >
          <SidebarItemBasic
            icon="icon_v2-so_direction-right"
            :label="page.title"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>

    <SidebarGroup label="Activity">
      <!-- <template #right>
        <ProjectNavModuleVisibility :enabled="false" />
      </template> -->
      <SidebarMenu>
        <SidebarMenuLink
          v-if="routes.projectNotifications"
          :to="{ name: routes.projectNotifications }"
        >
          <SidebarItemBasic icon="icon_v2-so_notification-ringing" label="Notifications" />
        </SidebarMenuLink>
        <!-- timeline -->
        <SidebarMenuLink
          v-if="projectModules.timeline.enabled"
          :to="{ name: routes.projectUpdates }"
        >
          <SidebarItemBasic
            :label="projectModules.timeline.title || 'Timeline'"
            :icon="projectModules.timeline.icon || 'icon_v2-so_timeline'"
          />
        </SidebarMenuLink>
      </SidebarMenu>
    </SidebarGroup>
  </ProjectTheme>
</nav>
