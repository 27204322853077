<template>
  <div class="flex items-center gap-16">
    <DropdownSelect
      v-model="filterBy"
      icon="icon_v2-so_action-items"
      :options="options"
      class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
  </div>
</template>
<script>
import { pluck } from 'ramda'
import { defineComponent, computed } from '@vue/composition-api'
import DropdownSelect from '@/components/DropdownSelect.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'

// these are also translation keys from 'tasks.filter.[OPTION]'
const OPTIONS = [
  'allTasks',
  'assignedToMe',
  'createdByMe',
  'resolvedTasks',
  'pendingTasks',
  'dueTasks',
  'publicTasks',
  'privateTasks',
];

export default defineComponent({
  name: 'ProjectTasksViewOptions',
  components: {
    DropdownSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        filterBy: 'allTasks',
      }),
    },
    hidden: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const { translate } = useLocalization()

    const options = computed(
      () => OPTIONS.filter(({ value }) => !props.hidden.includes(value))
        .map(option => ({
          text: translate(`tasks.filter.${option}`).value,
          value: option,
        }))
    )

    const update = (key, value) => {
      context.emit('input', { ...props.value, [key]: value })
    }

    const filterBy = computed({
      get() {
        return props.value.filterBy
      },
      set(value) {
        update('filterBy', value)
      },
    })

    const validateValueOrDefault = () => {
      const viewAsValues = pluck('value', options.value)

      if (!viewAsValues.includes(filterBy.value)) {
        update('viewAs', viewAsValues[0])
      }
    }

    validateValueOrDefault()

    return {
      filterBy,
      options,
    }
  },
})
</script>
