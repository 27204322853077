import { computed, getCurrentInstance } from '@vue/composition-api'
import { indexBy, prop } from 'ramda'
import { useGet, useFind } from 'feathers-vuex'


const indexById = indexBy(prop('_id'))

export default function useDocumentNodes(documentId) {
  const vm = getCurrentInstance().proxy
  const { DocumentStructure, DocumentNode, Document } = vm.$FeathersVuex.api

  // document
  const { item: document } = useGet({
    model: Document,
    id: documentId,
    local: true,
  });

  // structures
  const { item: structure } = useGet({
    model: DocumentStructure,
    id: document.value?.structure ?? null,
    local: true,
  });

  // nodes
  const nodesParams = computed(() => (structure.value ? {
    query: {
      _id: { $in: structure.value.nodes },
    },
  } : null))

  const { items: nodes } = useFind({
    model: DocumentNode,
    params: nodesParams,
    local: true,
  })

  const nodesMap = computed(() => indexById(nodes.value))
  const orderedNodes = computed(
    () => (structure.value ? structure.value.nodes.map(nodeId => nodesMap.value[nodeId]) : [])
  )

  return orderedNodes
}

