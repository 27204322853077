<template>
  <div
    :class="{ interactive: !disabled }"
    style="text-align: left"
  >
    <div
      ref="container"
      class="slider1d-container relative"
      @dblclick.stop="$emit('reset')"
    >
      <!-- <div class="slider1d-gradient-wrapper">
        <div
          class="slider1d-gradient"
          :style="
            `background: radial-gradient(circle ` +
              `at ${(xPosition + 0.5) * 100}% 50%, ` +
              `rgba(35, 184, 57, 0.3) 0%, ` +
              `rgba(35, 184, 57, 0.1) 15%, ` +
              `rgba(35, 184, 57, 0) 55%, ` +
              `rgba(35, 184, 57, 0) 100%)`
          "
        />
      </div> -->
      <span class="slider-container-line" />
      <div
        ref="draggable"
        v-b-tooltip="{
          placement: 'bottom',
          trigger: 'hover',
          variant: 'info',
          delay: {'show':200,'hide':0},
          title: showTooltip ? 'Drag me to answer' : '',
        }"
        class="slider1d-draggable"
        :class="[shouldPing && 'ping']"
        :style="draggableStyle"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
      >
        <span
          v-if="!disabled"
          :class="[
            'icon_v2-so_drag',
            'transition-colors pointer-events-none',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'ramda'
import {
  ref,
  computed,
  onUnmounted,
  onMounted,
  reactive,
} from '@vue/composition-api'
import { restrictValue } from '@/v2/lib/helpers/fp'

const pickSize = pick(['width', 'height'])

const round = (value, digits = 2) => {
  const c = 10 ** digits
  return Math.round(value * c) / c
}

const eventOffset = (event, target) => {
  const { x } = (target || event.target).getBoundingClientRect()

  return {
    x: (event.clientX || event.touches[0].clientX) - x,
  }
}

const restrictPosition = restrictValue(0, 1)

export default {
  name: 'Slider1D',
  props: {
    value: {
      type: Number,
      default: 0.5,
    },
    disabled: Boolean,
    ping: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const container = ref(null)
    const containerRect = reactive({ width: 0, height: 0 })

    const draggable = ref(null)
    const draggableRect = reactive({ width: 0, height: 0 })
    const draggableClickOffset = { x: 0, y: 0 }
    const shouldPing = computed(() => props.ping && !props.disabled)
    const showTooltip = ref(true)

    const setRects = () => {
      Object.assign(
        containerRect,
        pickSize(container.value.getBoundingClientRect())
      )
      Object.assign(
        draggableRect,
        pickSize(draggable.value.getBoundingClientRect())
      )
    }

    const onDragStart = event => {
      if (props.disabled) {
        return
      }

      event.preventDefault()
      Object.assign(draggableClickOffset, eventOffset(event))
      setRects()

      document.addEventListener('mouseup', onDragEnd, { once: true })
      document.addEventListener('touchend', onDragEnd, { once: true })
      document.addEventListener('mousemove', onDrag)
      document.addEventListener('touchmove', onDrag)

      // Disable the tooltip while dragging
      showTooltip.value = false
    }

    const onDrag = event => {
      event.preventDefault()
      const { x: offsetX } = eventOffset(event, container.value)

      const newX = (offsetX - draggableClickOffset.x + draggableRect.width / 2)
        / containerRect.width

      context.emit('input', restrictPosition(newX))
    }

    const onDragEnd = () => {
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('touchmove', onDrag)

      // Re-enable the tooltip when dragging is done
      showTooltip.value = true
    }

    const draggableStyle = computed(() => ({
      left: `${round(props.value) * 100}%`,
      top: '36px',
    }))

    const xPosition = computed(() => props.value - 0.5)

    onMounted(() => {
      setRects()
    })

    onUnmounted(() => {
      onDragEnd()
      document.removeEventListener('mouseup', onDragEnd)
      document.removeEventListener('touchend', onDragEnd)
    })

    return {
      container,
      draggable,
      onDragStart,
      onDrag,
      draggableStyle,
      xPosition,
      shouldPing,
      showTooltip,
    }
  },
}
</script>

<style lang="postcss" scoped>
.slider1d-container {
  width: 100%;
  max-width: 800px;
  height: 72px;
  /* border: 1px dotted #b5e6bc; */
  /* @apply border-b-2 border-green-200 mb-2; */
}
.slider-container-line {
  @apply block absolute h-4 -mt-2 bg-green-200 w-full top-1/2 left-0 right-0 z-auto rounded-full;
}
.slider1d-gradient-wrapper {
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 5%,
    black 95%,
    transparent 100%
  );
  width: 100%;
  height: 72px;
  position: absolute;
  z-index: auto;
  pointer-events: none;
  transition: all 600ms ease-in-out;
  opacity: 1;
}

.slider1d-gradient {
  @apply absolute inset-0;
  mask-image: radial-gradient(
    black 0%
    black 50%,
    transparent 100%,
  );
}

.slider1d-draggable {
  @apply bg-green-500 rounded-lg absolute flex items-center justify-center text-green-600;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 44px;
  z-index: 1;
  transition: box-shadow 400ms ease-in;
  box-shadow: 0px 0px 16px 2px rgba(35,184,57,0.19),
              0px 0px 24px 4px rgba(35,184,57,0.26),
              0px 0px 40px 8px rgba(35,184,57,0.26);
}

.interactive .slider1d-draggable {
  @apply hover:cursor-move hover:text-green-700;
}

.interactive .slider1d-draggable:hover,
.interactive .slider1d-draggable:active {
  transition: box-shadow 200ms ease-out;
  box-shadow: 0px 0px 16px 2px rgba(35,184,57,0.09),
            0px 0px 24px 4px rgba(35,184,57,0.16),
            0px 0px 40px 8px rgba(35,184,57,0.1);
}

.ping::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  animation: ping-border 2s ease-in-out infinite;
  z-index: auto;
  @apply border-8 rounded-lg border-green-400 border-opacity-30;
}

</style>
