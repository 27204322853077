<template>
  <DocumentNodeRenderer
    :node="node"
    :config-override="{
      stylingToolbar: {
        features: {
          textColor: true,
        }
      },
    }"
  >
    <template #default="{ styling, textVariant, isFocus, nodeWidth }">
      <DocumentContentBlockCost
        :document="document"
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        :is-focus="isFocus"
        :node-width="nodeWidth"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { inject } from '@vue/composition-api'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockCost from './DocumentContentBlockCost.vue'

export default {
  name: 'DocumentNodeCost',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockCost,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const document = inject('document')

    return {
      document,
    }
  },
}
</script>
