
<DocumentNodeEditorToolbarSettings
  :popover-target-id="popoverTargetId"
  :prevent-popover-close="preventPopoverClose"
>
  <b-form>
    <SettingsSwitch
      tooltip="Show/hide the title"
      :value="hasTitle"
      label="Title"
      @input="value => $emit('input', { value: value, type: 'hasTitle'})"
    />
    <SettingsSwitch
      tooltip="Show/hide the description"
      :value="description"
      label="Description"
      @input="value => $emit('input', { value: value, type: 'description'})"
    />
    <slot />
  </b-form>
</DocumentNodeEditorToolbarSettings>
