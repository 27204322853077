<template>
  <li
    :href="`#node-${node._id}`"
    :class="[
      'group toc-item heading',
      (node._id === selectedNodeId) && 'active',
    ]"
    @click="$emit('go-to-node')"
  >
    <i class="active-indicator" />
    <p class="truncate">{{ node.strippedTitle }}</p>
  </li>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TocItemHeading',
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    selectedNodeId: {
      type: String,
      default: '',
    },
  },

  setup(props) {

  },
})
</script>

<style lang="postcss" scoped>

</style>
