
<ProjectTheme
  :project="project"
  no-dark-mode
  class-name="relative flex flex-col flex-1 p-0 h-full"
>
  <div
    class="
      flex flex-col w-full h-full p-0 overflow-hidden
      transition-all group card-interactive cursor-pointer
    "
    @click="$emit(template.noPreview ? 'create' : 'preview')"
  >
    <!-- <div class="relative pb-2/3">
      <img class="absolute object-cover w-full h-full" :src="template.thumbnail" />
    </div> -->
    <img :src="template.thumbnail" />
    <div class="relative flex flex-col items-start flex-1 p-24">
      <div class="mb-24">
        <h2
          class="mb-6 font-semibold text-18"
        >
          {{ project.name }}
        </h2>

        <p class="text-gray-600 text-14 opacity-80">{{ template.heading }}</p>
      </div>
      <Button
        v-if="!template.noPreview"
        type="button"
        variant="neutral"
        size="md"
        class="pl-16 pr-12 mt-auto"
      >
        Preview Template <span class="w-24 ml-8 icon-util-view text-24" />
      </Button>
      <Button
        v-else
        type="button"
        variant="neutral"
        size="md"
        class="pl-16 pr-12 mt-auto"
      >
        Use Template<span class="w-24 ml-8 icon_v2-so_plus text-24" />
      </Button>
    </div>
  </div>
</ProjectTheme>
