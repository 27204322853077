<template>
  <input
    :value="value"
    type="range"
    step="1"
    :min="min"
    :max="max"
    :style="{ backgroundSize: backgroundSize }"
    @input="updateSlider"
  />
</template>
<script>
import { getCurrentInstance, ref } from '@vue/composition-api'

export default {
  name: 'RangeSlider',

  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const initialStep = (props.value * 100) / (props.max + props.min)
    const backgroundSize = ref(`${initialStep}% 100% `)
    const vm = getCurrentInstance().proxy
    const updateSlider = e => {
      const clickedElement = e.target;
      const min = Number(clickedElement.min);
      const max = Number(clickedElement.max);
      const val = Number(clickedElement.value)

      backgroundSize.value = `${((val - min) * 100) / (max - min)}% 100%`
      vm.$emit('input', Number(clickedElement.value))
    }
    return {
      backgroundSize,
      updateSlider,
    }
  },
}
</script>
<style lang="postcss" scoped>
/* Slider CSS */

input[type='range'] {
  @apply m-0 w-full block appearance-none;
  background: theme('colors.gray.400');
  background-image: linear-gradient(
    to right,
    theme('colors.green.600') 0%,
    theme('colors.green.200') 100%
  );
  background-repeat: no-repeat;
  border-radius: 2px;
  height: 4px;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type='range']::-webkit-slider-thumb {
  border: 1px solid theme('colors.green.700');
  @apply bg-green-600 rounded-full h-16 w-16 shadow-none cursor-pointer appearance-none -mt-6;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type='range']::-moz-range-thumb {
  box-shadow: none;
  border: 1px solid theme('colors.green.700');
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background: theme('colors.green.600');
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type='range']::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type='range']::-ms-thumb {
  box-shadow: none;
  border: 1px solid theme('colors.green.700');
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  height: 4px;
}
input[type='range']:focus::-ms-fill-lower {
  background: transparent;
}
input[type='range']:focus::-ms-fill-upper {
  background: transparent;
}
/* End Range Slider */
</style>
