/* jshint esversion: 6 */
import { Node, mergeAttributes } from '@tiptap/core'
import { TextSelection } from '@tiptap/pm/state'

class PageBreak extends HTMLElement {
  static text() {
    return 'This content block will force a page break'
      + ' in the PDF or print version of the document.'
  }

  connectedCallback() {
    this.textContent = PageBreak.text();
  }
}

if (!customElements.get('page-break')) {
  customElements.define('page-break', PageBreak)
}

export default Node.create({
  name: 'pageBreak',
  group: 'block',
  selectable: true,
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [{ tag: 'page-break' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['page-break', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setPageBreak:
        () => ({ chain }) => (
          chain()
            .insertContent({ type: this.name })
          // set cursor after horizontal rule
            .command(({ tr, dispatch }) => {
              if (dispatch) {
                const { $to } = tr.selection
                const posAfter = $to.end()

                if ($to.nodeAfter) {
                  tr.setSelection(TextSelection.create(tr.doc, $to.pos))
                } else {
                  // add node after horizontal rule if it’s the end of the document
                  const node = $to.parent.type.contentMatch.defaultType?.create()

                  if (node) {
                    tr.insert(posAfter, node)
                    tr.setSelection(TextSelection.create(tr.doc, posAfter))
                  }
                }

                tr.scrollIntoView()
              }

              return true
            })
            .run()
        ),
    }
  },
})
