<template>
  <ButtonIcon
    v-b-tooltip.hover.right.v-info.dh0.ds200="tooltip"
    icon="icon icon_v2-so_close"
    v-on="$listeners"
  />
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonIcon from '@/components/ButtonIcon.vue'

export default defineComponent({
  name: 'ButtonClear',
  components: {
    ButtonIcon,
  },
  props: {
    tooltip: {
      type: String,
      default: 'Reset',
    },
  },
})
</script>
