
<b-dropdown
  ref="dropdown"
  :right="position === 'right'"
  :variant="variant"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #button-content>
    <slot name="button-content" />
  </template>
  <slot />
</b-dropdown>
