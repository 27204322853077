<template>
  <div v-if="documents.length" class="mt-24 pb-24">
    <h3
      class="project-page-h3 mb-0 border-b
    border-gray-200 dark:border-darkGray-700 pb-12
    text-gray-700 dark:text-darkGray-300 portal-heading-font"
    >
      {{ $t('fs.recently-updated') }}
    </h3>
    <div class="flex flex-col flex-1 w-full">
      <DocumentLink
        v-for="doc in documents"
        v-slot="{ navigate }"
        :key="doc._id"
        :document="doc"
      >
        <FolderItemRow
          :document="doc"
          read-only
          @select="navigate"
        />
      </DocumentLink>
    </div>
  </div>
</template>


<script>
import { defineComponent, computed, getCurrentInstance } from '@vue/composition-api'
import { rejectNil } from 'ramda-extension'
import { useFind } from 'feathers-vuex'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FolderItemRow from '@/components/FolderItemRow.vue'
import DocumentLink from '@/components/DocumentLink.vue'


export default defineComponent({
  name: 'RecentDocuments',
  components: {
    DocumentLink,
    FolderItemRow,

  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    hidePrivate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { Document } = vm.$FeathersVuex.api
    const docParams = computed(() => ({
      query: rejectNil({
        project: props.project?._id,
        category: {
          $nin: [
            CATEGORY.ProjectRootFolder,
            CATEGORY.ProjectPublicFolder,
            CATEGORY.Folder],
        },
        $sort: { updatedAt: -1 },
        $limit: 25,
      }),
    }))
    const { items: documents } = useFind({
      model: Document,
      params: docParams,
    })
    return {
      documents,
    }
  },
})
</script>
