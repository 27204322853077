
<div>
  <p
    class="text-14 text-gray-700 dark:text-darkGray-400 leading-snug mb-16"
  >
    <span>Showing comments on</span>
    <transition
      name="fade"
      class="inline-flex"
      mode="out-in"
    >
      <span
        class=" bg-yellow-50 font-semibold text-gray-800 px-2 mx-2"
      >
        {{ contentPreview }}
      </span>
    </transition>
    block
  </p>
  <div class="mb-16">
    <a
      class="text-13 text-gray-700 dark:text-darkGray-400 underline mt-4 mb-12
          hover:text-gray-600 dark:hover:text-darkGray-300 hover:no-underline cursor-pointer"
      @click="$emit('show-all-comments')"
    >
      View all document comments
    </a>
  </div>
</div>
