import { getCurrentInstance } from '@vue/composition-api'

export const getCurrentInstanceOrThrow = () => {
  const vm = getCurrentInstance().proxy
  if (!vm) {
    throw new ReferenceError(
      'No Vue instance found (functional components are not supported)'
    )
  }

  return vm
}
