<template>
  <div
    v-if="document"
    class="header-folder-icon-wrapper scale-75 flex-none"
  >
    <!-- Document icon -->
    <div
      class="w-24 md:w-56 h-full"
    >
      <!-- If is Folder -->
      <span
        v-if="isFolder"
        class="inline-flex w-24 text-32 md:w-56 md:text-56 icon_v2-so_folder-solid"
        :style="{ color: document.color || theme.backgroundColor}"
      ></span>

      <!-- If is File -->
      <!-- {{ document }} -->


            <span
         v-if="isLink || isDoc || isImageFile || isOrgDoc"
        class="inline-flex w-24 text-32 md:w-56 md:text-56 icon_v2-so_file-full opacity-20"
        :style="{ color: document.color || theme.backgroundColor}"
      ></span>

      <FileIcon
        v-if="isFile && !isImageFile"
        :content-type="document?.file?.contentType"
        :size="$isMobile ? '32' : 'lg'"
      />

      <!-- <span v-if="isFile">.{{ customIconForFiles }}</span> -->
    </div>

    <span
      v-if="isFolder"
      :style="{
        color: isPublicFolder ? 'var(--cp-accent-most-readable) '
          : theme.mostReadableColor}"
      :class="[
        document.icon,
        'header-folder-icon',
      ]"
    />

    <!-- if is Doc or Link -->
    <span
      v-if="isLink || isDoc"
      :style="{ color: document.color || theme.backgroundColor}"
      :class="[
        document.icon,
        'header-folder-icon header-position',
      ]"
    />
    <!-- if is Org level doc -->
    <span
      v-if="isOrgDoc"
      :style="{ color: document.color || theme.backgroundColor}"
      :class="[
        DOCUMENT_ICON[document.category],
        'header-folder-icon',
      ]"
    />
  </div>
</template>
<script>
import { last, split, toUpper, pipe } from 'ramda'
import { computed, defineComponent } from '@vue/composition-api'
import tinycolor from 'tinycolor2'
import { CATEGORY, ORGANIZATION_LIBRARY_CATEGORIES } from '@/v2/services/documents/documentsTypes'
import { ICON as DOCUMENT_ICON } from '@/types/document'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import { isFileTypeImage } from '@/v2/lib/helpers/validators'
import FileIcon from '@/components/Blocks/File/FileIcon.vue'

export default defineComponent({
  name: 'DocIcon',
  components: {
    FileIcon,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const project = useCurrentProject()
    const getContentType = pipe(split('.'), last, toUpper)
    const isFile = props.document.category === CATEGORY.File
    const isLink = props.document.category === CATEGORY.FileLink
    const isDoc = props.document.category === CATEGORY.Document

    const isOrgDoc = ORGANIZATION_LIBRARY_CATEGORIES.includes(props.document.category)

    const isImageFile = computed(() => isFileTypeImage(props.document?.file))
    const customIconForFiles = computed(() => getContentType(props.document?.file?.name ?? ''))

    const isPublicFolder = computed(() => props.document.category === CATEGORY.ProjectPublicFolder)

    const folderCategories = [CATEGORY.ProjectPublicFolder, CATEGORY.Folder]
    const isFolder = computed(() => folderCategories.includes(props.document.category))
    const accentColorSpace = computed(() => tinycolor(props.document.color).monochromatic(3))
    const mostReadable = computed(() => tinycolor.mostReadable(
      props.document.color,
      [
        ...accentColorSpace.value,
        '#FFFFFF',
      ],
      {
        level: 'AA',
        size: 'large',
      }
    ).toHexString())

    const theme = computed(() => ({
      textColor: mostReadable.value,
      backgroundColor: props.document?.sharingClientEnabled ? 'var(--cp-accent)' : 'var(--cp-secondary-color)',
      mostReadableColor: props.document?.sharingClientEnabled ? 'var(--cp-accent-most-readable)' : 'var(--cp-secondary-most-readable)',
      package: 'var(--cp-accent)',
    }))


    return {
      customIconForFiles,
      isFile,
      isLink,
      isDoc,
      isOrgDoc,
      isImageFile,
      DOCUMENT_ICON,
      project,
      theme,
      isFolder,
      isPublicFolder,
    }
  },
})
</script>
<style lang="postcss" scoped>
.header-folder-icon-wrapper {
  @apply w-24 h-32 md:w-56 md:h-56 relative;
}
.header-folder-icon {
  @apply absolute left-1/2 transform-gpu -translate-x-1/2 top-1/2 -translate-y-1/2 mt-3;
  @apply text-16 w-16 md:text-24 md:w-24 opacity-70;
}

.header-position {
  @apply -mt-2;
}

.header-file-icon-wrapper-corner {
  content: '';
  @apply absolute right-0 top-0 w-14 h-14 ;
  @apply pointer-events-none rounded-bl z-1 opacity-50;
  clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.edit-button-wrapper {
  /* @apply flex items-center justify-center;
  @apply w-40 h-40 text-gray-600 cursor-pointer;
  @apply hover:text-gray-900; */
  @apply w-32 h-32 flex items-center justify-center;
  @apply hover:bg-gray-500 hover:bg-opacity-10 outline-none focus:outline-none;
  @apply text-gray-200 hover:text-white;
  @apply transition-none;
}


</style>
