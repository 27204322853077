<template>
  <div class="flex items-center opacity-75">
    <Spinner class="mr-4" /> Expanding reusable block...
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'DocumentContentBlockReusableBlock',
  components: {
    Spinner,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scope></style>
