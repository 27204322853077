<template>
  <router-link
    v-slot="{ href, navigate, isActive: isRouteActive, isExactActive: isRouteExactActive }"
    :to="to"
    custom
  >
    <Watch
      :value="[active, isRouteExactActive, isRouteActive]"
      immediate
      @change="onActiveChange"
    >
      <a
        class="no-hover grow min-w-0 flex items-center"
        :href="href"
        @click="navigate"
      >
        <slot
          v-bind="{
            isActive: isRouteActive,
            isExactActive: isRouteExactActive
          }"
        />
      </a>
    </Watch>
  </router-link>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'
import Watch from '@/components/Watch.vue'

export default defineComponent({
  name: 'SidebarTreeLink',
  components: {
    Watch,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    activateOnExactRoute: {
      type: Boolean,
      default: true,
    },
    activateOnChildRoute: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const setActive = inject('setActive')

    const onActiveChange = ([active, isExactActive, isActive]) => {
      const activeValue = [
        active,
        props.activateOnExactRoute && isExactActive,
        props.activateOnChildRoute && isActive,
      ].some(Boolean)

      setActive(props.id, activeValue)
    }

    return {
      onActiveChange,
    }
  },
})
</script>
<style scoped lang="postcss">
  .no-hover:hover {
    color: inherit;
  }
</style>
