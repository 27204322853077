<template>
  <NotificationRenderer
    v-if="!isSingleton"
    :notification="notification"
    icon-variant="icon"
    icon="icon-document"
    icon-class="bg-green-200 text-green-700"
    badge-icon="icon-document"
    badge-class="bg-blue-500"
    @mark-as-read="notification => $emit('mark-as-read', notification)"
  >
    <template #default="{ options }">
      <div>
        Organization library has {{ notification.events.length }}
        new {{ $tc('common.shares', notification.events.length) }}
      </div>
    </template>
    <template #events>
      <NotificationList :notifications="notification.events" />
    </template>
  </NotificationRenderer>
  <NotifDocumentsCreated v-else :notification="singleton" />
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useNotificationGroup } from '@/v2/services/notifications/compositions'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationList from './NotificationList.vue'
import NotifDocumentsCreated from './NotifDocumentsCreated.vue'

export default defineComponent({
  name: 'NotifOrganizationsLibraryDocumentAdded',
  components: {
    NotificationRenderer,
    NotifDocumentsCreated,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ NotificationList })
    const { singleton, isSingleton } = useNotificationGroup(toRef(props, 'notification'))

    return {
      singleton,
      isSingleton,
    }
  },
})
</script>
