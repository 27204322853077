
<ButtonTransparent
  class="text-gray-500 dark:text-darkGray-400 cursor-pointer hover:text-gray-900 transition-all"
  :class="
    variant === 'rounded' && [
      '!border-solid border-[1.5px]',
      'border-gray-500 dark:border-darkGray-400 rounded-full',
      'hover:border-gray-900 hover:dark:border-darkGray-300 hover:shadow-md'
    ]"
  v-on="$listeners"
>
  <span
    :class="[
      icon,
      `text-${size}`
    ]"
  />
</ButtonTransparent>
