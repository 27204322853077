<template>
  <div>
    <h3 v-if="hasTitle" class="font-semibold text-24">
      Fonts
    </h3>
    <!-- Heading -->
    <b-form-group>
      <FormField label="Heading Font">
        <div class="flex flex-col gap-24 md:grid md:grid-cols-4">
          <!-- PICKER -->
          <div class="col-span-2 lg:col-span-1">
            <div
              class="flex flex-row items-center justify-between pl-12 pr-8 border border-gray-200 rounded-md dark:border-darkGray-700 h-44"
            >
              <p class="mr-16 text-gray-800 truncate text-14 dark:text-darkGray-400">
                {{ model.brandingFontHeading || 'Not Set' }}
              </p>
              <b-button
                size="xs"
                variant="neutral"
                @click="$emit('change-font','brandingFontHeading')"
              >
                {{ model.brandingFontHeading ? 'Change' : 'Add' }}
              </b-button>
            </div>
          </div>
          <!-- PREVIEW -->
          <div class="col-span-2 lg:col-span-3">
            <BrandingFontsPreview
              :font="model.brandingFontHeading"
              :font-size="24"
            />
          </div>
        </div>
      </FormField>
    </b-form-group>


    <!-- Paragraph Font -->
    <b-form-group>
      <FormField label="Paragraph Font">
        <div class="flex flex-col gap-24 md:grid md:grid-cols-4">
          <!-- PICKER -->
          <div class="col-span-2 lg:col-span-1">
            <div
              class="flex flex-row items-center justify-between pl-12 pr-8 border border-gray-200 rounded-md dark:border-darkGray-700 h-44"
            >
              <p class="mr-16 text-gray-800 truncate text-14 dark:text-darkGray-400">
                {{ model.brandingFontParagraph || 'Not Set' }}
              </p>
              <b-button
                size="xs"
                variant="neutral"
                @click="$emit('change-font','brandingFontParagraph')"
              >
                {{ model.brandingFontParagraph ? 'Change' : 'Add' }}
              </b-button>
            </div>
          </div>
          <!-- PREVIEW -->
          <div class="col-span-2 lg:col-span-3 opacity-60">
            <!-- Preview -->
            <BrandingFontsPreview
              :font="model.brandingFontParagraph"
              :font-size="14"
              variant="paragraph"
            />
          </div>
        </div>
      </FormField>
    </b-form-group>
  </div>
</template>

<script>
import FormField from '@/components/FormField.vue';
import BrandingFontsPreview from '@/components/BrandingFontsPreview.vue'

export default {
  name: 'FormPickFont',
  components: {
    FormField,
    BrandingFontsPreview,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
    }
  },

}
</script>

<style lang="sass" scoped>

</style>
