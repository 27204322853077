
<div v-if="documents.length" class="mt-24 pb-24">
  <h3
    class="project-page-h3 mb-0 border-b
  border-gray-200 dark:border-darkGray-700 pb-12
  text-gray-700 dark:text-darkGray-300 portal-heading-font"
  >
    {{ $t('fs.recently-updated') }}
  </h3>
  <div class="flex flex-col flex-1 w-full">
    <DocumentLink
      v-for="doc in documents"
      v-slot="{ navigate }"
      :key="doc._id"
      :document="doc"
    >
      <FolderItemRow
        :document="doc"
        read-only
        @select="navigate"
      />
    </DocumentLink>
  </div>
</div>
