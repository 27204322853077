<template>
  <div class="flex flex-col">
    <small class="flex-1 inline-block text-gray-500 text-12 relative"
      >{{ label }}

      <b-badge v-if="newBadge" variant="new" :class="$style['new-badge']"
        >NEW</b-badge
      >
    </small>
    <div class="flex items-center h-32"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarGroup',
  props: {
    label: String,
    newBadge: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" module>
.new-badge {
  @apply relative px-4 py-0 text-11 rounded-sm -top-8;
}
</style>
