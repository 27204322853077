
<div
  class="flex items-center leading-snug gap-4 hover:bg-gray-100 rounded-md -m-2"
  v-on="$listeners"
>
  <TickIcon size="sm" :checked="item.isResolved" />
  <h3
    class="
      m-2
      flex-1 text-medium p-2
      text-15 md:text-12 dark:text-darkGray-300
      portal-paragraph-font
      inline md:inline-flex md:items-center
    "
  >
    <span
      :class="{
        'resolved': item.isResolved,
        'pending': !item.isResolved,
        'due': !item.isResolved && item.isDue
      }"
    >{{ item.title }}</span>
    <span
      v-if="item.notes"
      class="text-14 icon_v2-so_file ml-8"
    />
    <span class="pl-4 md:pl-0 md:ml-auto">
      <ActionItemDateDisplay
        v-if="!item.isResolved"
        :item="item"
        class-name="text-12"
      />
    </span>
  </h3>
</div>
