
<div class="inline-flex items-center h-32 space-x-8 text-14">
  <div
    v-if="isMobile && ancestors.length > 1"
    class="flex items-center text-gray-500 md:ml-24 dark:text-darkGray-400"
  >
    <span class="w-20 mr-6 icon-arrow-left-util text-20"></span>
    <DocumentLinkTitle
      v-if="ancestors.length > 1"
      :document="ancestors[ancestors.length - 2]"
      class="text-gray-500 dark:text-darkGray-400
         hover:underline hover:text-[color:var(--cp-accent)]"
    />
  </div>
  <div v-if="!isMobile" class="flex">
    <router-link
      v-if="!isClientPortal"
      :to="{
        name: pathName,
        params: { projectId: project._id }
      }"
      class="text-gray-500 dark:text-darkGray-400
      hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1"
    >
      {{ project.name }}
    </router-link>
    <div
      v-for="(doc, index) in splitAncestors.pre"
      :key="doc._id"
      class="ml-22 so-breadcrumb-item flex items-center"
    >
      <span
        class="so-breadcrumb-arrow
        w-12 mr-4 -ml-16
        text-gray-400 dark:text-darkGray-600
        text-12 icon-arrow-right"
      />
      <DocumentLinkTitle
        v-if="index < ancestors.length - 1"
        :document="doc"
        class="text-gray-500 dark:text-darkGray-400
         hover:underline hover:text-[color:var(--cp-accent)]"
      />
      <DocumentComputedData
        v-else
        v-slot="{ title }"
        :document="document"
      >
        <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
          <WrappedTitle
            :text="title"
            :pre-size="34"
          />
        </p>
      </DocumentComputedData>
    </div>
    <!-- IF MORE ANCESTORS THAN WOULD FIT -->
    <div
      v-if="splitAncestors.after.length"
      class="ml-12 text-gray-500 dark:text-darkGray-300"
    >
      ...
    </div>
    <div v-if="splitAncestors.after.length" v-fragment>
      <div
        v-for="(doc, index) in splitAncestors.after"
        :key="doc._id"
        class="ml-22 so-breadcrumb-item flex items-center"
      >
        <span
          class="so-breadcrumb-arrow
          w-12 mr-4 -ml-16
          text-gray-400 dark:text-darkGray-600
          text-12 icon-arrow-right"
        />
        <DocumentLinkTitle
          v-if="index < splitAncestors.after.length - 1"
          :document="doc"
          class="text-gray-500 dark:text-darkGray-400
           hover:underline hover:text-[color:var(--cp-accent)]"
        />
        <DocumentComputedData
          v-else
          v-slot="{ title }"
          :document="document"
        >
          <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
            <WrappedTitle
              :text="title"
              :pre-size="34"
            />
          </p>
        </DocumentComputedData>
      </div>
    </div>
  </div>
</div>
