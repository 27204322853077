<template>
  <div
    class="
      flex items-center space-x-4 text-gray-700 text-12 md:ml-24
      dark:text-darkGray-400 mt-4
    "
  >
    <NotificationAvatar
      :notification="notification"
      :size="18"
      :icon-size="12"
      :font-size="10"
    />
    <p>
      {{ authorName }}
    </p>
    <p v-if="document">
      {{ $t('project-updates.on', { doc: document.title }) }}
    </p>
    </notificationavatar>
  </div>
</template>

<script>
import { defineComponent, toRef } from '@vue/composition-api'
import { useNotificationAuthor } from '@/v2/services/notifications/compositions'
import NotificationAvatar from '@/components/NotificationAvatar.vue'

export default defineComponent({
  name: 'ProjectTimelineItemAuthor',
  components: {
    NotificationAvatar,
  },
  props: {
    notification: {
      type: Object,
      default: null,
    },
    document: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const {
      authorProfile,
      authorName,
    } = useNotificationAuthor(toRef(props, 'notification'))

    return {
      authorProfile,
      authorName,
    }
  },
})
</script>
