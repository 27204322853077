
<span
  v-b-tooltip.hover
  :class="['avatar-wrap', $style['avatar-wrap'], position]"
  :disabled="!showTooltip"
  :title="name"
>
  <span
    v-if="showAvatar"
    :class="[
      'avatar',
      $style['avatar'],
      `avatar-${size}`,
      showName && 'mr-4',
    ]"
  >
    <VueAvatar
      :size="size"
      :username="fullName"
      :src="url"
      :custom-style="{
        fontFamily: 'inherit',
        fontSize: fontSize + 'px',
        fontWeight: 'bold',
        color: '#ffffff',
      }"
      :initials="computedInitials"
    />
  </span>
  <span v-if="showName || timestamp" class="flex justify-center flex-col">
    <i
      v-if="showName"
      :class="[
        'text-' + fontSize,
        'm-0 leading-tight',
        nowrap && 'whitespace-nowrap',
      ]"
    >
      {{ fullName }}
    </i>
    <i
      v-if="timestamp"
      :class="[
        'text-' + fontSize,
        'font-normal leading-tight text-gray-700 dark:text-darkGray-400 m-0',
        nowrap && 'whitespace-nowrap',
      ]"
    >
      {{ formattedTimestamp }}
    </i>
  </span>
</span>
