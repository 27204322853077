
<!-- :body-class="$style['modal-body-apps']"
  :header-class="$style['modal-header-apps']"
  :title-class="$style['modal-title-apps']" -->
<ModalBasic
  title="Link to an externally hosted file"
  :should-close-on-backdrop="false"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="md"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <Form
      v-slot="{ v }"
      :state="file"
      :validation="validation"
      @submit="$emit('file-linked', file)"
    >
      <b-form-group class="mb-32">
        <FormField
          label="File URL"
          required
          :v="v.url"
        >
          <b-input
            v-model="file.url"
            autofocus
            trim
            debounce="500"
            type="text"
            placeholder="https://cloud.com/some-file.txt"
          />
        </FormField>
      </b-form-group>
      <Buttons>
        <Button
          type="submit"
          size="md"
          variant="primary"
        >
          Confirm
        </Button>
        <Button
          size="md"
          variant="secondary"
          @click="close"
        >
          Cancel
        </Button>
      </Buttons>
    </Form>
  </template>
</ModalBasic>
