// import ContentBlockSkeleton from '@/components/ContentBlockSkeleton.vue'
import { CATEGORY, LABEL } from '@/v2/services/contentBlocks/contentBlocksTypes'
import DocumentNodeApproval from '@/v2/features/document/documentNodeImpl/approval/DocumentNodeApproval.vue'
import DocumentNodeAttachments from '@/v2/features/document/documentNodeImpl/attachments/DocumentNodeAttachments.vue'
import DocumentNodeCost from '@/v2/features/document/documentNodeImpl/cost/DocumentNodeCost.vue'
import DocumentNodeCostBreakdown from '@/v2/features/document/documentNodeImpl/costBreakdown/DocumentNodeCostBreakdown.vue'
import DocumentNodeHeading from '@/v2/features/document/documentNodeImpl/heading/DocumentNodeHeading.vue'
import DocumentNodeImage from '@/v2/features/document/documentNodeImpl/image/DocumentNodeImage.vue'
import DocumentNodeQA from '@/v2/features/document/documentNodeImpl/qa/DocumentNodeQA.vue'
import DocumentNodeText from '@/v2/features/document/documentNodeImpl/text/DocumentNodeText.vue'
import DocumentNodeEmbed from '@/v2/features/document/documentNodeImpl/embed/DocumentNodeEmbed.vue'
import DocumentNodeButton from '@/v2/features/document/documentNodeImpl/button/DocumentNodeButton.vue'
import DocumentNodeTable from '@/v2/features/document/documentNodeImpl/table/DocumentNodeTable.vue'
import DocumentNodeCaseStudy from '@/v2/features/document/documentNodeImpl/caseStudy/DocumentNodeCaseStudy.vue'
import DocumentNodePackagedService from '@/v2/features/document/documentNodeImpl/packagedService/DocumentNodePackagedService.vue'
import DocumentNodeTeamBio from '@/v2/features/document/documentNodeImpl/teamBio/DocumentNodeTeamBio.vue'
import DocumentNodeReusableBlock from '@/v2/features/document/documentNodeImpl/reusableBlock/DocumentNodeReusableBlock.vue'
import DocumentNodeEmbeddedDoc from '@/v2/features/document/documentNodeImpl/embeddedDoc/DocumentNodeEmbeddedDoc.vue'
import DocumentNodePageBreak from '@/v2/features/document/documentNodeImpl/pageBreak/DocumentNodePageBreak.vue'
import DocumentNodeSpacer from '@/v2/features/document/documentNodeImpl/spacer/DocumentNodeSpacer.vue'

export default {
  [CATEGORY.ElementApproval]: {
    label: LABEL[CATEGORY.ElementApproval],
    component: DocumentNodeApproval,
  },
  [CATEGORY.ElementAttachments]: {
    label: LABEL[CATEGORY.ElementAttachments],
    component: DocumentNodeAttachments,
  },
  [CATEGORY.ElementCost]: {
    label: LABEL[CATEGORY.ElementCost],
    component: DocumentNodeCost,
  },
  [CATEGORY.ElementCostBreakdown]: {
    label: LABEL[CATEGORY.ElementCostBreakdown],
    component: DocumentNodeCostBreakdown,
  },
  [CATEGORY.ElementHeading]: {
    label: LABEL[CATEGORY.ElementHeading],
    component: DocumentNodeHeading,
  },
  [CATEGORY.ElementImage]: {
    label: LABEL[CATEGORY.ElementImage],
    component: DocumentNodeImage,
  },
  [CATEGORY.ElementQA]: {
    label: LABEL[CATEGORY.ElementQA],
    component: DocumentNodeQA,
  },
  [CATEGORY.ElementText]: {
    label: LABEL[CATEGORY.ElementText],
    component: DocumentNodeText,
  },
  [CATEGORY.ElementEmbed]: {
    label: LABEL[CATEGORY.ElementEmbed],
    component: DocumentNodeEmbed,
  },
  [CATEGORY.ElementButton]: {
    label: LABEL[CATEGORY.ElementButton],
    component: DocumentNodeButton,
  },
  [CATEGORY.ElementTable]: {
    label: LABEL[CATEGORY.ElementTable],
    component: DocumentNodeTable,
  },
  [CATEGORY.ElementCaseStudy]: {
    label: LABEL[CATEGORY.ElementCaseStudy],
    component: DocumentNodeCaseStudy,
  },
  [CATEGORY.ElementPackagedService]: {
    label: LABEL[CATEGORY.ElementPackagedService],
    component: DocumentNodePackagedService,
  },
  [CATEGORY.ElementTeamBio]: {
    label: LABEL[CATEGORY.ElementTeamBio],
    component: DocumentNodeTeamBio,
  },
  [CATEGORY.ElementReusableBlock]: {
    label: LABEL[CATEGORY.ElementReusableBlock],
    component: DocumentNodeReusableBlock,
  },
  [CATEGORY.ElementEmbeddedDocument]: {
    label: LABEL[CATEGORY.ElementEmbeddedDocument],
    component: DocumentNodeEmbeddedDoc,
  },
  [CATEGORY.ElementPageBreak]: {
    label: LABEL[CATEGORY.ElementPageBreak],
    component: DocumentNodePageBreak,
  },
  [CATEGORY.ElementSpacer]: {
    label: LABEL[CATEGORY.ElementSpacer],
    component: DocumentNodeSpacer,
  },
}
