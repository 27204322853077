
<div
  v-b-hover="handleHover"
  class="fs-card-wrapper"
  :class="`${size}`"
  @click="event => $emit('select', event)"
>
  <div class="fs-card-container">
    <div
      class="fs-card-body fs-card--folder"
      role="link"
    >
      <!-- <div class="fs-card-status-badge">
        <DocumentStatusBadge
          :status="document.status"
          display-as="small"
          only-approved
        />
      </div> -->
      <!-- INSIDE COVER -->
      <span
        :class="[
          'absolute left-0 bottom-0 right-0 pointer-events-none',
          'filter brightness-75',
          size === 'l' && 'rounded-tr-lg rounded-b-lg top-6 md:top-12 ',
          size === 'm' && 'rounded-tr-md rounded-b-md top-6 '
        ]"
        :style="{ backgroundColor: document.color || theme.backgroundColor}"
      ></span>

      <!-- FOLDER EAR TOP -->
      <div
        class="relative flex flex-row items-start
          w-full filter brightness-75 fs-card--folder-background"
        :class="[
          size === 'l' && 'h-12 md:h-24',
          size === 'm' && 'h-12'
        ]"
      >
        <div
          class="overflow-hidden rounded-tl-lg"
          :class="[
            size === 'l' && 'w-20 md:w-40 h-6 md:h-12 ',
            size === 'm' && 'w-20 h-6 '
          ]"
          :style="{ backgroundColor: document.color || theme.backgroundColor}"
        ></div>
        <SvgGraphic
          v-if="$isMobile"
          :width="size==='l' ? '23px' : '12px'"
          :height="size==='l' ? '12px' : '6px'"
          name="folder-ear"
          :style="{ color: document.color || theme.backgroundColor }"
        />
        <SvgGraphic
          v-else
          :width="size==='l' ? '23px' : '12px'"
          :height="size==='l' ? '12px' : '6px'"
          name="folder-ear"
          :style="{ color: document.color || theme.backgroundColor }"
        />
      </div>

      <!-- FOLDER CONTENTS -->
      <!-- <span class="fs-card--folder-paper fs-card--folder-paper-1"></span>
      <span class="fs-card--folder-paper fs-card--folder-paper-2"></span> -->

      <!-- FACING COVER -->
      <div
        class="fs-card--folder-body group-hover:cursor-pointer hover:cursor-pointer"
        :style="{ backgroundColor: document.color || theme.backgroundColor}"
      >
        <div
          class="flex flex-col items-center justify-center flex-1"
          :style="{ color: theme.textColor || theme.mostReadableColor}"
        >
          <!-- ICON -->
          <span
            v-if="!isPublicFolder"
            :class="[
              `${document.icon}`,
              'fs-card--folder-icon'
            ]"
            :style="{ color: theme.textColor || theme.mostReadableColor}"
          />
        </div>
        <!-- Shared -->
        <div
          v-if="document.sharingClientEnabled && !isClientPortal"
          v-b-tooltip.hover.left.v-info.dh0.ds200="'this is your shared folder'"
          class="fs-card-status-wrapper"
        >
          <span
            :class="[
              'fs-card-status-icon',
              isPublicFolder ? 'icon_v2-so_folder' : 'icon_v2-so_shared'
            ]"
            :style="{ color: theme.textColor || theme.mostReadableColor}"
          ></span>
        </div>

        <div
          v-if="!isClientPortal && isPublicFolder"
          class="flex items-center justify-center rounded-lg
            absolute inset-0 -translate-y-[4px]"
        >
          <span
            class="icon_v2-so_shared fs-card--folder-icon-public"
            :style="{ color: theme.textColor || theme.mostReadableColor}"
          ></span>
        </div>
      </div>
    </div>
    <!-- MORE INFO -->
    <!-- <div
      v-if="isPublicFolder"
      class="absolute flex items-center justify-center flex-none
        rounded-full
        shadow -bottom-12 left-8 bg-gray/70
        outline outline-1 outline-gray-300 filter backdrop-blur-sm
        pr-8"
      :class="isHovered ? 'visible' : 'invisible'"
    >
      <span class="icon_v2-so_info text-14 text-gray-600 bg-transparent mr-2"></span>
      <span class="text-13 text-gray-600">this is what your clients see</span>
    </div> -->

    <div
      v-if="totalActiveActionItems > 0 || showCommentsCount > 0 || showCommentsCount > 0"
      class="absolute flex items-center justify-center flex-none
       divide-x divide-gray-300 rounded-md
        shadow -bottom-16 left-8 bg-gray/70
        outline outline-1 outline-gray-300 filter backdrop-blur-sm"
      :class="isHovered ? 'visible' : 'invisible'"
    >
      <!-- Action Items Count -->
      <div
        v-if="totalActiveActionItems > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Action items'"
        class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13"
      >
        <span class="w-16 mr-4 icon_v2-so_action-items text-16"></span>
        {{ totalActiveActionItems }}
      </div>
      <!-- Comments Count -->
      <div
        v-if="showCommentsCount > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Comments'"
        class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13"
      >
        <span class="w-16 mr-4 icon_v2-so_chat text-16"></span> {{ showCommentsCount }}
      </div>
      <!-- Comments Count -->
      <div
        v-if="document.viewCount > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Views'"
        class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13"
      >
        <span class="w-16 mr-4 icon-util-view text-16"></span> {{ document.viewCount }}
      </div>
    </div>
  </div>

  <div class="fs-card-title-card">
    <DocumentTitleEditable
      :size="size"
      :document="document"
      :disabled="document.category === CATEGORY.ProjectPublicFolder || readOnly"
    />
    <DocumentStatusBadge
      :status="document.status"
      display-as="small"
      only-approved
    />
  </div>
</div>
