<template>
  <DocumentContentBlockQASettings
    :description="description"
    :has-title="hasTitle"
    :popover-target-id="popoverTargetId"
    @input="handleSettingsToggle"
  >
    <SettingsSwitch
      v-model="hasNotes"
      tooltip="Enable client notes"
      label="Notes"
    />
  </DocumentContentBlockQASettings>
</template>

<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQADateSettings',
  components: {
    DocumentContentBlockQASettings,
    SettingsSwitch,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    // bindings props are refs
    const bindings = useContentBlockSettings(props, [
      'description',
      'hasTitle',
      'hasNotes',
    ])
    const handleSettingsToggle = object => {
      bindings[object.type].value = object.value
    }
    return {
      ...bindings,
      handleSettingsToggle,
    }
  },
}
</script>
<style lang="scss" scoped></style>
