<template>
  <NotificationRenderer
    v-if="!isSingleton"
    :notification="notification"
    icon-variant="icon"
    icon="icon-util-upload-alt"
    icon-class="bg-blue-200 text-blue-500"
    badge-class="bg-blue-500"
    :badge-count="notification.events.length"
    @mark-as-read="notification => $emit('mark-as-read', notification)"
  >
    <template #default="{ options }">
      <div>
        Project <span class="font-semibold">{{ notification.subject$.name }}</span>
        has {{ notification.events.length }}
        new {{ $tc('common.shares', notification.events.length) }}
      </div>
    </template>
    <template #events>
      <NotificationList :notifications="notification.events" />
    </template>
  </NotificationRenderer>
  <NotifDocumentsShared
    v-else
    :notification="singleton"
    :project="notification.project$"
  />
</template>
<script>
import { defineComponent, computed, toRef } from '@vue/composition-api'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useNotificationGroup } from '@/v2/services/notifications/compositions'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationList from './NotificationList.vue'
import NotifDocumentsShared from './NotifDocumentsShared.vue'

export default defineComponent({
  name: 'NotifProjectsNewShares',
  components: {
    NotificationRenderer,
    NotifDocumentsShared,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ NotificationList })
    const document = computed(() => props.notification?.subject$)
    const { singleton, isSingleton } = useNotificationGroup(toRef(props, 'notification'))

    return {
      document,
      singleton,
      isSingleton,
    }
  },
})
</script>
