<template>
  <NotificationDocument v-slot="{ document, badgeIcon }" :notification="notification">
    <DocumentLink v-slot="{ navigate }" :document="document">
      <NotificationRenderer
        v-slot="{ options }"
        :notification="notification"
        :project="project"
        icon-variant="icon"
        icon="icon-document-view"
        icon-class="bg-violet-200 text-violet-700"
        :badge-icon="badgeIcon"
        badge-class="bg-violet-500"
        @click="navigate"
        @mark-as-read="notification => $emit('mark-as-read', notification)"
      >
        New public link view
        <template v-if="options.showDocument">
          on <NotificationLinkDocument :document="document" />
          <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
        </template>
      </NotificationRenderer>
    </DocumentLink>
  </NotificationDocument>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationDocument from './NotificationDocument.vue'

export default defineComponent({
  name: 'NotifDocumentsViewGuest',
  components: {
    DocumentLink,
    NotificationRenderer,
    NotificationLinkDocument,
    NotificationDocument,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
  },
})
</script>
