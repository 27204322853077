
<input
  :value="value"
  type="range"
  step="1"
  :min="min"
  :max="max"
  :style="{ backgroundSize: backgroundSize }"
  @input="updateSlider"
/>
