
<component
  :is="tag"
  ref="element"
  v-focus="autofocus"
  :class="['editable', className]"
  :contenteditable="contenteditable"
  :placeholder="placeholder"
  @input="onInput"
  @blur="onBlur"
  @focus="onFocus"
  @paste="onPaste"
  @keypress="onKeypress"
  @keydown="fwdEv"
  @keyup="fwdEv"
  @mouseenter="fwdEv"
  @mouseover="fwdEv"
  @mousemove="fwdEv"
  @mousedown="fwdEv"
  @mouseup="fwdEv"
  @auxclick="fwdEv"
  @click="fwdEv"
  @dblclick="fwdEv"
  @contextmenu="fwdEv"
  @wheel="fwdEv"
  @mouseleave="fwdEv"
  @mouseout="fwdEv"
  @select="fwdEv"
  @pointerlockchange="fwdEv"
  @pointerlockerror="fwdEv"
  @dragstart="fwdEv"
  @drag="fwdEv"
  @dragend="fwdEv"
  @dragenter="fwdEv"
  @dragover="fwdEv"
  @dragleave="fwdEv"
  @drop="fwdEv"
  @transitionstart="fwdEv"
  @transitioncancel="fwdEv"
  @transitionend="fwdEv"
  @transitionrun="fwdEv"
  @compositionstart="fwdEv"
  @compositionupdate="fwdEv"
  @compositionend="fwdEv"
  @cut="fwdEv"
  @copy="fwdEv"
/>
