
<FeathersVuexGet
  :id="nodeId"
  v-slot="{ item: node, isGetPending }"
  service="document-nodes"
  local
>
  <DocumentNodeResolverViewer
    v-if="!isGetPending"
    v-slot="{ component }"
    :node="node"
  >
    <component
      :is="component"
      :node="node"
      :width="width"
      :content-block="node.contentBlock$"
      @update="data => updateContentBlock(node.contentBlock$, data)"
      @async-save-start="$emit('async-save-start')"
    />
  </DocumentNodeResolverViewer>
</FeathersVuexGet>
