<template>
  <NotificationLinkActionItem v-slot="{ navigate }" :notification="notification">
    <NotificationRenderer
      v-slot="{ options }"
      :notification="notification"
      icon-variant="author"
      badge-icon="icon-link"
      badge-class="bg-violet-500"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      <div class="inline-flex justify-center space-x-6">
        <span>
          Action item
          <span class="font-semibold">{{ notification.subject$.title }}</span>
          assigned to
        </span>
        <Avatar
          class="font-semibold"
          :profile="notification.subject$.assignedTo$.profile$"
          font-size="14"
          :size="18"
          show-name
        />
        <span v-if="options.showDocument && notification.document">
          on <NotificationLinkDocument :document="notification.document$" />
        </span>
        <span v-else-if="!notification.document">
          on <NotificationLinkProjectActionItems :notification="notification" />
        </span>
      </div>
    </NotificationRenderer>
  </NotificationLinkActionItem>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkActionItem from './NotificationLinkActionItem.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationLinkProjectActionItems from './NotificationLinkProjectActionItems.vue'

export default defineComponent({
  name: 'NotifActionItemsAssigned',
  components: {
    Avatar,
    NotificationRenderer,
    NotificationLinkActionItem,
    NotificationLinkDocument,
    NotificationLinkProjectActionItems,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const assignedToFullName = computed(
      () => props.notification.subject$.assignedTo$.user$.profile$.fullName
    )

    return {
      assignedToFullName,
    }
  },
})
</script>
