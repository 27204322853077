<script>
import { defineComponent } from '@vue/composition-api'
import { useFeatureEnabled } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'FeatureEnabled',
  props: {
    flag: {
      type: String,
      required: true,
    },
    ignore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { checkFeatureEnabled } = useFeatureEnabled()

    const checkEnabled = (event, ...args) => {
      if (props.ignore) {
        context.emit('allow', ...args)
        return
      }

      const isEnabled = checkFeatureEnabled(props.flag)

      if (isEnabled) {
        context.emit('allow', ...args)
      }

      event?.preventDefault()
    }

    return () => context.slots.default({
      checkFeatureEnabled: checkEnabled,
    })
  },
})
</script>
