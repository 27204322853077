import { CATEGORY as CB_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'

export const CATEGORY = {
  DocumentCaseStudy: 'DocumentCaseStudy',
  DocumentTeamBio: 'DocumentTeamBio',
  DocumentReusableBlock: 'DocumentReusableBlock',
  DocumentPackagedService: 'DocumentPackagedService',
  DocumentPage: 'DocumentPage',
  /** The most comonly used document type. Old briefs, proposals and estimates have been replaced
   * by it */
  Document: 'Document',
  /** Similar to `Folder`, but more specific. Each `project` must have **exactly 1, non-removable**
   * 'root folder' where the front-end file navigation starts */
  ProjectRootFolder: 'ProjectRootFolder',

  /**
   * Hard-coded child of `ProjectRootFolder` containing everything visible in the client portal.
   * This is the entry point for client portal's `Docs & Files`
   * Anything that is added here is automatically shared
   */
  ProjectPublicFolder: 'ProjectPublicFolder',

  /** A special kind of document used to contain (embed) files (special documents) and other
   * folders */
  Folder: 'Folder',
  /** A special kind of document used to to repesent the contents of folders (special documents).
   * It contains **1 uploaded file**. */
  File: 'File',
  /** A special kind of document used to to repesent the contents of folders (special documents).
   * It contains **1 URL to a file hosted somewhere in the cloud** */
  FileLink: 'FileLink',

  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentBrief: 'DocumentBrief',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentEstimate: 'DocumentEstimate',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentProposal: 'DocumentProposal',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentContract: 'DocumentContract',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentStatementOfWork: 'DocumentStatementOfWork',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentChangeRequest: 'DocumentChangeRequest',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentClientSignOff: 'DocumentClientSignOff',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentProgressUpdate: 'DocumentProgressUpdate',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentCustom: 'DocumentCustom',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentKnowledgeBase: 'DocumentKnowledgeBase',
};

export const DEFAULT_DOCUMENT_ICON = {
  [CATEGORY.DocumentCaseStudy]: 'document-case-study',
  [CATEGORY.DocumentPackagedService]: 'document-case-study',
  [CATEGORY.DocumentPage]: 'icon_v2-so-docs-files',
  [CATEGORY.DocumentTeamBio]: 'document-reusable-block',
  [CATEGORY.DocumentReusableBlock]: 'document-reusable-block',
  [CATEGORY.Document]: 'icon_v2-so_docs-files',
  [CATEGORY.Folder]: '',
  [CATEGORY.File]: 'icon_v2-so_file',
  [CATEGORY.FileLink]: 'icon_v2-so_link',
}

export const STATUS = {
  // NO_STATUS: 'NO_STATUS',
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  // IN_REVIEW: 'IN_REVIEW',
  // POSTPONED: 'POSTPONED',
  // IN_PROGRESS: 'IN_PROGRESS',
  // DELIVERY_ACCEPTED: 'DELIVERY_ACCEPTED',
}

export const PROPOSAL_APPROVAL_METHOD = {
  APPROVE_ALL: 'APPROVE_ALL',
  REMOVE_UNAPPROVED: 'REMOVE_UNAPPROVED',
}

export const DEFAULT_DOCUMENT_TITLE = {
  [CATEGORY.DocumentCaseStudy]: 'Untitled Project',
  [CATEGORY.DocumentPackagedService]: 'Untitled Packaged Service',
  [CATEGORY.DocumentPage]: 'Untitled Page',
  [CATEGORY.DocumentTeamBio]: 'John Appleseed',
  [CATEGORY.DocumentReusableBlock]: 'Untitled Document',
  [CATEGORY.Document]: 'Untitled Document',
  [CATEGORY.ProjectRootFolder]: 'Docs & Files', // This might not be needed
  [CATEGORY.Folder]: 'Untitled Folder',
  [CATEGORY.File]: 'Untitled File',
  [CATEGORY.FileLink]: 'Untitled Linked File',

  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentCustom]: 'Untitled Document',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentBrief]: 'Untitled Brief',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentProposal]: 'Untitled Proposal',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentEstimate]: 'Untitled Estimate',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentContract]: 'Untitled Contract',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentStatementOfWork]: 'Untitled Statement of Work',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentChangeRequest]: 'Untitled Change Request',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentClientSignOff]: 'Untitled Client Sign-Off',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentProgressUpdate]: 'Untitled Progress Update',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentKnowledgeBase]: 'Untitled Knowledge Base',
}

/** Maps document categories (as registered in the databse) to a 'readable', titlecased version  */
export const CATEGORY_TO_LIST_TITLE = {
  [CATEGORY.DocumentCaseStudy]: 'Case Study',
  [CATEGORY.DocumentPackagedService]: 'Packaged Service',
  [CATEGORY.DocumentPage]: 'Page',
  [CATEGORY.DocumentTeamBio]: 'Team Bio',
  [CATEGORY.DocumentReusableBlock]: 'Reusable Block',
  [CATEGORY.Document]: 'Document',
  [CATEGORY.ProjectRootFolder]: 'Project Root Folder', // This might not be needed
  [CATEGORY.Folder]: 'Folder',
  [CATEGORY.File]: 'File',
  [CATEGORY.FileLink]: 'Linked File',

  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentCustom]: 'Freeform Document',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentBrief]: 'Brief',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentEstimate]: 'Estimate',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentProposal]: 'Proposal',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentContract]: 'Contract',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentStatementOfWork]: 'Statement Of Work',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentChangeRequest]: 'Change Request',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentClientSignOff]: 'Client Sign-Off',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentProgressUpdate]: 'Progress Update',
  /** @deprecated replaced by `CATEGORY_TO_LIST_TITLE.Document` */
  [CATEGORY.DocumentKnowledgeBase]: 'Knowledge Base',
};

export const FOLDER_CATEGORIES = [
  CATEGORY.ProjectRootFolder,
  CATEGORY.ProjectPublicFolder,
  CATEGORY.Folder,
]

export const ORGANIZATION_LIBRARY_CATEGORIES = [
  CATEGORY.DocumentCaseStudy,
  CATEGORY.DocumentTeamBio,
  CATEGORY.DocumentReusableBlock,
  CATEGORY.DocumentPackagedService,
  CATEGORY.DocumentPage,
]

export const DOCUMENT_VIEW_DEFAULTS = {
  sortBy: 'unsorted',
  viewAs: 'grid',
}

export const FOLDER_DETAILS_OVERWRITES = {
  [CATEGORY.ProjectRootFolder]: {
    // label: 'Docs & Files',
  },
}

export const TEMPLATE_CATEGORY = {
  [CATEGORY.DocumentReusableBlock]: CATEGORY.DocumentReusableBlock,
  [CATEGORY.DocumentCaseStudy]: CATEGORY.DocumentCaseStudy,
  [CATEGORY.DocumentTeamBio]: CATEGORY.DocumentTeamBio,
  [CATEGORY.DocumentPackagedService]: CATEGORY.DocumentPackagedService,
  [CATEGORY.DocumentPage]: CATEGORY.DocumentPage,
  default: CATEGORY.Document,
}

export const DOCUMENT_TEMPLATE_FIELDS = {
  [CATEGORY.DocumentPackagedService]: {
    title: 'title',
    description: 'description',
    imageUrl: 'preview_image.url',
  },
}

export const FS_STATE = /** @type {const} */ ({
  cut: 'cut',
  delete: 'delete',
});

// Doc types that can be shared to a portal
export const SHARABLE_TO_PORTAL_CATEGORIES = [
  CATEGORY.Document,
  CATEGORY.File,
  CATEGORY.FileLink,
  CATEGORY.Folder,
]

// Doc types that can be saved as PDF
export const PRINTABLE_CATEGORIES = [
  CATEGORY.Document,
  CATEGORY.DocumentTeamBio,
  CATEGORY.DocumentCaseStudy,
  CATEGORY.DocumentPackagedService,
  CATEGORY.DocumentReusableBlock,
]

export const PREVIEW_VIEW_AS = {
  list: 'list',
  card: 'card',
}

export const CONTENT_BLOCK_BLACKLIST = {
  [CATEGORY.DocumentPage]: {
    [CB_CATEGORY.ElementCost]: true,
    [CB_CATEGORY.ElementCostBreakdown]: true,
    [CB_CATEGORY.ElementPageBreak]: true,
    [CB_CATEGORY.ElementQA]: true,
    [CB_CATEGORY.ElementApproval]: true,
  },
}
