
<SidebarTree
  :node="projectTreeModel"
  item-class="so-sidebar-item"
  active-item-class="so-sidebar-item-active"
  :selected="value"
  :hide-empty-nodes="false"
  :expand-root="expandRoot"
>
  <template #folder="{ node }">
    <ButtonTransparent @click="selectNode(node)">
      <DocumentComputedData v-slot="{ title, icon }" :document="node.data.document">
        <SidebarItemBasic :icon="icon" :label="title" />
      </DocumentComputedData>
    </ButtonTransparent>
  </template>
</SidebarTree>
