
<div v-fragment>
  <!-- billable list editor -->
  <SlickList
    v-model="billablesList"
    use-drag-handle
    lock-to-container-edges
    lock-axis="y"
    class="table-row-group px-16 portal-paragraph-font"
    helper-class="so-table-row-dragging portal-paragraph-font"
  >
    <SlickItem
      v-for="(billable, index) in value"
      :key="billable.id"
      :index="index"
      class="relative table-row transform scale-100 cost-editor-item group"
      :class="[
        !billable.isBillable && 'freebie',
        activeBillableIndex === index && 'active',
      ]"
      :style="{
        color: styling.textColor,
      }"
    >
      <DocumentContentBlockCostEditorItem
        :item="billable"
        :currency="currency"
        class="so-slick-table-row-inner"
        :styling="styling"
        @update="({ key, value }) => update({ index, key, value })"
        @remove="del(index)"
        @set-active-billable-item="val => setActiveBillable(index, val)"
      />
    </SlickItem>
  </SlickList>

  <!-- ------------------------------ -->
  <!-- ADD NEW ITEM ----------------- -->
  <!-- ------------------------------ -->
  <div class="flex w-full px-8 mt-16 mb-16 space-x-8 print:hidden">
    <b-button
      variant="primary"
      @click="createNewBillable"
    >
      <span class="w-24 mr-8 icon-add-rounded-s text-24"></span>
      Add
    </b-button>
    <CheckMemberRole v-slot="{ hasRole: isAdmin }" :roles="['admin']">
      <b-button
        v-if="isAdmin"
        variant="primary"
        @click="isAddModalOpen = true"
      >
        <span class="w-20 mr-8 icon-org-billable-services text-20"></span>
        Import from library
      </b-button>
    </CheckMemberRole>
    <DocumentContentBlockCostAddFromLibrary
      v-if="isAddModalOpen"
      v-model="isAddModalOpen"
      :currency="currency"
      :organization-id="organizationId"
      @save="billables => createFromLibrary(billables)"
    />
  </div>
</div>
