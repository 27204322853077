import { ref } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { PROJECT_DASHBOARD } from '@/router/project/type'
import projectsService from '../projectsService'

/**
 * @typedef Options
 * @property {boolean} redirect
 */

/**
 * Clone a project
 * @param {Options} options
 */
export default function useCloneProject(options = {}) {
  const { redirect = true } = options

  const { routerPush } = useRouter()
  const currentOrganization = useCurrentOrganization()

  const isPending = ref(false)
  const error = ref(null)

  const reset = () => {
    isPending.value = false
    error.value = null
  }

  const clone = async project => {
    try {
      isPending.value = true
      const newProject = await projectsService.create({
        name: `${project.name} copy`,
        cloneOf: project._id,
        organization: currentOrganization.value._id,
        // organization: project.organization,
      })
      isPending.value = false

      if (redirect) {
        routerPush({
          name: PROJECT_DASHBOARD,
          params: {
            projectId: newProject._id,
          },
        })
      }
    } catch (err) {
      error.value = err.message
      throw err;
    } finally {
      isPending.value = false
    }
  }

  return {
    reset,
    clone,
    isPending,
    error,
  }
}
