import { PROJECT_TASKS_DOCUMENTS_TASK, PROJECT_TASKS_LIST_TASK } from '@/router/project/type'
import { PORTAL_TASKS_DOCUMENTS_TASK, PORTAL_TASKS_LIST_TASK } from '@/client-portal/router/types'

import { computed, inject, unref } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'

function buildLink(actionItem, isClientPortal) {
  const {
    project: projectId,
    document: documentId,
    taskSection: sectionId,
  } = actionItem

  const commonParams = { projectId, taskId: actionItem._id }

  if (documentId) {
    return {
      name: isClientPortal ? PORTAL_TASKS_DOCUMENTS_TASK : PROJECT_TASKS_DOCUMENTS_TASK,
      params: {
        ...commonParams,
        documentId,
      },
    }
  }

  if (sectionId) {
    return {
      name: isClientPortal ? PORTAL_TASKS_LIST_TASK : PROJECT_TASKS_LIST_TASK,
      params: {
        ...commonParams,
        sectionId,
      },
    }
  }

  return null
}

export function useGoToActionItem() {
  const { routerPush } = useRouter()
  const isClientPortal = inject('isPortal', false)

  return actionItem => {
    const to = buildLink(unref(actionItem), isClientPortal)
    return routerPush(to)
  }
}

export function useActionItemLink(actionItem) {
  const isClientPortal = inject('isClientPortal', false)
  const to = computed(() => buildLink(unref(actionItem), isClientPortal))
  return to
}
