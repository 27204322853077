
<ModalBasic
  :title="title"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="md"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <section class="pb-16">
      <p v-if="showSubtitle" class="text-gray-500 text-14">Where do you want to move this?</p>
      <ProjectFolderTree
        v-model="selectedFolderId"
        :project-id="projectId"
        :folder-id="folderId"
        :folder-category="folderCategory"
        :hidden-folder-ids="hiddenFolderIds"
        :hidden-folder-categories="hiddenFolderCategories"
      />
    </section>
    <Buttons>
      <Button
        type="button"
        variant="neutral"
        size="md"
        @click="close"
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="branded"
        size="md"
        :disabled="!selectedFolderId"
        @click="onSelect"
      >
        {{ okLabel }}
      </Button>
    </Buttons>
  </template>
</ModalBasic>
