import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import notificationsService, { servicePath } from './notificationsService'

export class Notification extends BaseModel {
  static modelName = 'Notification'

  static setupInstance(data, { models }) {
    const {
      Member,
      Project,
      ActionItem,
      Comment,
      ContentBlock,
      Document,
    } = models.api

    const _data = { ...data }

    if (_data.project$) {
      _data.project$ = new Project(_data.project$)
    }

    if (_data.document$) {
      _data.document$ = new Document(_data.document$)
    }

    if (data.author$) {
      _data.author$ = new Member(_data.author$);
    }

    switch (data.subjectType) {
      case 'documents':
        _data.subject$ = new Document(_data.subject$)
        break
      case 'projects':
        _data.subject$ = new Project(_data.subject$)
        break
      case 'content-blocks':
        _data.subject$ = new ContentBlock(_data.subject$)
        break
      case 'action-items':
        _data.subject$ = new ActionItem(_data.subject$)
        break
      case 'comments':
        _data.subject$ = new Comment(_data.subject$)
        break
      default:
        break
    }
  }

  static instanceDefaults() {
    return {
      read: false,
    }
  }
}

export default makeServicePlugin({
  service: notificationsService,
  Model: Notification,
  servicePath,
})
