
<div class="flex w-full items-center gap-x-20">
  <div class="flex-1 flex items-center">
    <MainHeaderLogo
      :label="organization.name"
      :logo-url="organizationLogoUrl"
    />
    <template v-if="client">
      <span class="icon_v2-so_plus mx-8 text-gray-400"></span>
      <MainHeaderLogo
        :label="client.name"
        :logo-url="clientLogoUrl"
      />
    </template>
  </div>
  <div v-if="Boolean($slots.nav)">
    <slot name="nav" />
  </div>
  <div>
    <slot name="user" />
  </div>
  <OrganizationSwitcher v-if="!noSwitch" />
</div>
