import Vue from 'vue'
import { computed, unref } from '@vue/composition-api'
import { uniq } from 'ramda'
import { isNotNil } from 'ramda-extension'
import { useFind } from 'feathers-vuex'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import matchKeyword from '@/lib/match-keyword'

export default function useSearchProjects(options = {}) {
  const { query, selectedTags = {}, keyword = '', local = false } = options;

  const organization = useCurrentOrganization()

  const { Project } = Vue.$FeathersVuex.api

  const params = computed(() => ({
    query: {
      organization: organization.value._id,
      $sort: { name: 1 },
      ...unref(query),
    },
  }))

  const { items: projects, isPending } = useFind({
    model: Project,
    params,
    local,
  })

  const allProjectsTags = computed(() => uniq(
    projects.value
      .flatMap(p => [...(p.tags ?? []), p.client$?.name])
      .filter(isNotNil)
  ))

  const selectedTagCount = computed(
    () => Object.values(selectedTags.value).filter(Boolean).length
  )

  const filteredProjects = computed(() => {
    const byKeyword = matchKeyword(keyword.value)

    const byTags = project => [
      !selectedTagCount.value,
      project.tags.some(tag => Boolean(selectedTags.value[tag])),
      Boolean(selectedTags.value[project.client$?.name]),
    ].some(Boolean);

    return projects.value.filter(project => byKeyword(project.name) && byTags(project))
  })

  return {
    projects: filteredProjects,
    tags: allProjectsTags,
    selectedTagCount,
    isPending,
  }
}

