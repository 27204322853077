
<li class="task-section">
  <div
    class="
      flex items-center group/section w-full
      gap-8 border-b dark:border-b-darkGray-900 p-8
    "
  >
    <ButtonCollapse v-model="collapsedSections[section.taskSection._id]" />
    <div class="flex-1">
      <slot v-if="$slots['section-title']" name="section-title" />
      <ContentEditable
        v-else
        :value="section.taskSection.title"
        lazy
        placeholder="Title..."
        tag="p"
        :no-html="true"
        no-newline
        trim
        class-name="smart-word-break text-16 portal-heading-font dark:text-white/70"
        :contenteditable="!readonlySections"
        @input="updateTitle"
      />
      <ProgressBarCompleted
        class="w-128"
        :count="taskCount"
        :completed-count="resolvedTaskCount"
      />
    </div>
    <div class="items-center gap-8 flex" :class="{'mr-4': noSectionMenu }">
      <b-form-checkbox
        v-if="!noSectionPinToDashboard"
        v-b-popover.hover.topleft="
          'If enabled, this section is shown as a card on the project\'s dashboard.'
        "
        :checked="section.taskSection.showOnProjectDashboard"
        class="flex items-center mr-8 scale-90"
        size="sm"
        switch
        @change="val => update({ showOnProjectDashboard: val })"
      >
        <span
          class="icon_v2-so_home text-gray-400"
          :class="{ 'text-green-600': section.taskSection.showOnProjectDashboard }"
        />
      </b-form-checkbox>
      <ButtonIcon
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="$t('tasks.addTask')"
        icon="icon_v2-so_plus"
        size="18"
        @click="$emit('create-task', 0)"
      />
      <ButtonIcon
        v-if="!readonlySections"
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Delete section`"
        icon="icon_v2-so_trash"
        size="18"
        @click="$emit('remove')"
      />
    </div>
    <TaskSectionMenu
      v-if="!noSectionMenu"
      @select="action => $emit('menu-action', action)"
    />
  </div>
  <div v-show="!collapsedSections[section.taskSection._id]" class="pt-8">
    <TaskList
      :tasks="section.tasks"
      :section-id="section.taskSection._id"
      :selected-task-id="selectedTaskId"
      :lock-tasks-to-section="lockTasksToSection"
      :no-task-reordering="noTaskReordering"
      @remove="task => $emit('remove-task', task)"
      @drag-event="event => $emit('drag-event-tasks', event)"
    >
      <template #task="{ task }">
        <slot name="task" v-bind="{ task }" />
      </template>
    </TaskList>
    <div class="p-8">
      <b-button
        type="button"
        size="sm"
        variant="neutral"
        class="capitalize portal-paragraph-font"
        @click="$emit('create-task', section.tasks.length)"
      >
        {{ $t('tasks.addTask') }}
      </b-button>
    </div>
  </div>
</li>
