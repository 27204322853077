export const execDelayed = (promiseFn, timeout) => new Promise((resolve, reject) => setTimeout(
  () => promiseFn()
    .then(resolve)
    .catch(reject),
  timeout
))

export async function retryUntil(
  promiseFn,
  conditionFn,
  { interval = 1000, retries = 3 } = {}
) {
  let result = null
  let lastError = null
  let tryIndex = 0

  do {
    try {
      result = await (tryIndex === 0
        ? promiseFn()
        : execDelayed(promiseFn, interval))

      if (typeof conditionFn === 'function' && !conditionFn(result)) {
        throw new Error('condition not satisfied')
      }
      break
    } catch (err) {
      result = null
      lastError = err
      tryIndex++
    }
  } while (tryIndex < retries)

  if (result) {
    return result
  }

  throw lastError
}

/**
 * @callback AsyncPredicate
 * @param {*} element an element inside an array
 * @returns {Promise<Boolean>}
 */
/**
 * Tests if at least one element in the array passes the test provided by the *async* `predicateFn`.
 * Similar to `Array.some`, but capable of handling async operations
 * @param {Array} array Source array on which to perform operations
 * @param {AsyncPredicate} predicateFn An async predicate (callback) function called for each
 * element in the array. Must return `boolean` promise
 * @returns {Promise<Boolean>}
 */
export const asyncSome = async (array, predicateFn) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const el of array) {
    // eslint-disable-next-line no-await-in-loop
    if (await predicateFn(el)) return true;
  }
  return false;
};

export const serial = tasks => tasks.reduce(
  (promise, task) => promise.then(
    result => task(result).then(r => [...result, r])
  ),
  Promise.resolve([])
);

/**
 * @template T
 * @param {Array<[() => boolean, () => Promise<T>]>} tasks
 */
export const execTasks = tasks => Promise.all(
  tasks
    .filter(([condFn]) => condFn())
    .map(([, taskFn]) => taskFn())
);
