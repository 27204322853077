export const CATEGORY = {
  ElementText: 'ElementText',
  ElementImage: 'ElementImage',
  ElementHeading: 'ElementHeading',
  ElementTeamBio: 'ElementTeamBio',
  ElementCaseStudy: 'ElementCaseStudy',
  ElementPackagedService: 'ElementPackagedService',
  ElementAttachments: 'ElementAttachments',
  ElementApproval: 'ElementApproval',
  ElementEmbeddedDocument: 'ElementEmbeddedDocument',
  ElementCost: 'ElementCost',
  ElementCostBreakdown: 'ElementCostBreakdown',
  ElementQA: 'ElementQA',
  ElementReusableBlock: 'ElementReusableBlock',
  ElementEmbed: 'ElementEmbed',
  ElementTable: 'ElementTable',
  ElementButton: 'ElementButton',
  ElementPageBreak: 'ElementPageBreak',
  ElementSpacer: 'ElementSpacer',

  // -- deprecated blocks
  /** @deprecated Should now be `ElementEmbeddedDocument` */
  ElementEstimate: 'ElementEstimate',
  /** @deprecated Should now be `ElementEmbeddedDocument` */
  ElementBrief: 'ElementBrief',
}

export const LABEL = {
  [CATEGORY.ElementImage]: 'Image',
  [CATEGORY.ElementAttachments]: 'Uploaded Files',
  [CATEGORY.ElementApproval]: 'Approval Button',
  [CATEGORY.ElementText]: 'Text',
  [CATEGORY.ElementHeading]: 'Heading',
  [CATEGORY.ElementTeamBio]: 'Bio',
  [CATEGORY.ElementCaseStudy]: 'Case Study',
  [CATEGORY.ElementPackagedService]: 'Packaged Service',
  [CATEGORY.ElementCost]: 'Pricing Table',
  [CATEGORY.ElementCostBreakdown]: 'Price Breakdown',
  [CATEGORY.ElementQA]: 'Q&A',
  [CATEGORY.ElementReusableBlock]: 'Document',
  [CATEGORY.ElementEmbed]: 'Embed',
  [CATEGORY.ElementTable]: 'Table',
  [CATEGORY.ElementButton]: 'Button',
  [CATEGORY.ElementPageBreak]: 'Page Break',
  [CATEGORY.ElementSpacer]: 'Spacer',

  /** @deprecated Should now be `ElementEmbeddedDocument` */
  [CATEGORY.ElementEstimate]: 'Estimate',
  /** @deprecated Should now be `ElementEmbeddedDocument` */
  [CATEGORY.ElementBrief]: 'Brief',
  [CATEGORY.ElementEmbeddedDocument]: 'Document',
}
