<script>
import { defineComponent, toRef } from '@vue/composition-api'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'

export default defineComponent({
  name: 'FormEntityEditor',
  props: {
    model: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    defaults: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Array,
      default: null,
    },
    throwError: {
      type: Boolean,
      default: true,
    },
    resetAfterCreate: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const {
      state,
      entity,
      isNew,
      isSaving,
      error,

      save,
      update,
      reset,
    } = useFormEntityEditor({
      model: props.model,
      id: toRef(props, 'id'),
      defaults: toRef(props, 'defaults'),
      fields: toRef(props, 'fields'),
      throwError: props.throwError,
      resetAfterCreate: props.resetAfterCreate,
    })

    return () => context.slots.default({
      // methods
      update,
      save,
      reset,

      // data
      state: state.value,
      isNew: isNew.value,
      error: error.value,
      entity: entity.value,
      isSaving: isSaving.value,
    })
  },
})
</script>
