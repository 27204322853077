import { makeServicePlugin } from '@/v2/services/feathersClientService'
import projectEventsService, { servicePath } from './projectEventsService'
import { Notification } from '../notifications/notificationsStore'

export class ProjectEvent extends Notification {
  static modelName = 'ProjectEvent'
}

export default makeServicePlugin({
  service: projectEventsService,
  Model: ProjectEvent,
  servicePath,
})
