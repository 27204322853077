
<FileUploader
  v-slot="{ selectFiles, isDragging }"
  multiple
  block
  @upload-start="onUploadStart"
  @upload-done="onUploadDone"
  @file-progress="onFileProgress"
  @file-done="onFileDone"
  @file-error="onFileError"
>
  <div
    v-if="!(isLocked && !hasFiles)"
    class="p-16 overflow-hidden border-2 border-gray-200 border-dashed rounded-xl portal-paragraph-font print:hidden"
  >
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="top"
    />
    <!-- ------------- -->
    <!-- Error message -->
    <!-- ------------- -->
    <div
      v-if="filesErrors.length"
      class="attach-note"
    >
      <h4>
        <span class="w-16 mr-4 text-16 icon-alert"></span>
        <span>{{ $t('blocks.qa.files-error') }}</span>
      </h4>
      <div
        v-for="fileError in filesErrors"
        :key="fileError.uuid"
      >
        <strong>{{ fileError.name }}</strong>
        {{ fileError.local.error | error }}
      </div>
    </div>

    <!-- ------------ -->
    <!-- IS UPLOADING -->
    <!-- ------------ -->
    <section v-if="isUploading || uploadedBatch.files.length > 0">
      <portal to="right-drawer">
        <PendingUploads :pending-uploads="uploadedBatch.files" @close="onPendingUploadsDone" />
      </portal>
    </section>

    <!-- If we have files -->
    <div v-if="hasFiles">
      <LightboxGallery
        :files="filesMedia"
        :index="index"
        @close="index = null"
      />

      <section class="attach-images-wrap">
        <div
          v-for="(file, fileIndex) in filesMedia"
          :key="file.uuid"
          :class="['attach-item-wrap', file.local && 'uploading-image']"
        >
          <DocumentContentBlockAttachmentsMedia
            v-if="!file.local"
            :file="file"
            :read-only="isLocked"
            @remove="remove(file)"
            @click-thumbnail="index = fileIndex"
          />
        </div>
      </section>
      <section class="mb-16 attach-files-wrap">
        <div
          v-for="fileNonImage in filesNonMedia"
          :key="fileNonImage.uuid"
          :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
        >
          <DocumentContentBlockAttachmentsFile
            v-if="!fileNonImage.local"
            :file="fileNonImage"
            :read-only="isLocked"
            @remove="remove(fileNonImage)"
          />
        </div>
      </section>
    </div>


    <!-- UPLOAD FILES BUTTON -->
    <div
      v-else
      class="mb-16 font-medium text-gray-500 text-16"
    >
      {{ $t('blocks.qa.files-instructions') }}
    </div>
    <div
      v-if="!isLocked"
      :class="
        [
          isUploading && 'opacity-70 pointer-events-none',
          'flex justify-start items-center',
          'w-full',
          'px-16 py-8 my-4',
          'bg-green rounded-md border border-green-200',
          'cursor-pointer select-none transition-all transform-gpu',
          'group',
          'hover:ring-4 hover:ring-opacity-20 hover:ring-green-500 hover:border-green-500'
        ]
      "
      @click="selectFiles"
    >
      <span
        :class="[
          'icon-util-upload h-64 text-64',
          'text-green-900 opacity-20',
          'mr-16',
          'transition-all group-hover:text-green-600 group-hover:opacity-100'

        ]"
      />
      <div class="leading-4 text-left text-green-600 text-14">
        <span class="block font-semibold underline text-18">
          {{ $t('blocks.qa.files-choose') }}
        </span>
        <span
          class="font-medium text-green-900 opacity-30"
        > {{ $t('blocks.qa.files-limit') }}</span>
      </div>
    </div>

    <!-- Drop overlay -->
    <div
      v-if="isDragging"
      :class="[
        'attach-empty attach-hovered',
        'absolute inset-0 pointer-events-none z-1',
        'flex items-center justify-center flex-col',
      ]"
    >
      <span class="font-semibold text-green-900 opacity-75 text-42">Drop your files here</span>
    </div>
  </div>

  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</FileUploader>
