<template>
  <div>
    <div v-if="hasProjects">
      <div>
        <FormField label="Select a project:">
          <!-- v-model="projectId" -->
          <b-select
            :value="projectId"
            size="lg"
            :options="projectOptions"
            @change="projectSelected"
          />
        </FormField>
      </div>
      <div v-if="projectId" class="mt-8">
        <FormField label="Select a folder:">
          <ProjectFolderTree
            :value="value"
            :project-id="projectId"
            :folder-category="folderCategory"
            :hidden-folder-categories="hiddenFolderCategories"
            @input="val => $emit('input', val)"
          />
        </FormField>
      </div>
    </div>
    <div v-else>No projects available</div>
  </div>
</template>
<script>
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import ProjectFolderTree from '@/components/ProjectFolderTree.vue'
import FormField from '@/components/FormField.vue'

export default defineComponent({
  name: 'OrganizationFolderTree',
  components: {
    ProjectFolderTree,
    FormField,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    selectedProjectId: {
      type: String,
      default: null,
    },
    folderCategory: {
      type: String,
      default: null,
    },
    hiddenFolderCategories: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Select project',
    },
    value: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const { Project } = context.root.$FeathersVuex.api
    const projectId = ref(props.selectedProjectId)

    const findProjectsParams = computed(() => ({
      query: {
        $sort: { updatedAt: -1 },
        organization: props.organizationId,
        archived: { $ne: true },
      },
    }))

    const { items: projects } = useFind({
      model: Project,
      params: findProjectsParams,
    })

    const projectOptions = computed(() => [
      { value: null, text: props.placeholder },
      ...projects.value.map(({ _id, name }) => ({ value: _id, text: name })),
    ])

    const hasProjects = computed(() => isNotNilOrEmpty(projects.value))
    const projectSelected = value => {
      projectId.value = value
      context.emit('project-selected', value)
    }

    watch(projectId, () => {
      context.emit('input', null)
    })

    return {
      projectOptions,
      projectId,
      hasProjects,
      projectSelected,
    }
  },
})
</script>
