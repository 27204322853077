// eslint-disable-next-line import/prefer-default-export
export const CATEGORY = {
  NodeGroupEstimate: 'NodeGroupEstimate',
  NodeGroupTeamBio: 'NodeGroupTeamBio',
  NodeGroupCaseStudy: 'NodeGroupCaseStudy',
  NodeGroupPackagedService: 'NodeGroupPackagedService',
  NodeGroupBasic: 'NodeGroupBasic',
  NodeGroupColumnsContainer: 'NodeGroupColumnsContainer',
  NodeGroupColumn: 'NodeGroupColumn',
  NodeContentBlock: 'NodeContentBlock',
  NodeReusableBlock: 'NodeReusableBlock',
}

export const LABEL = {
  [CATEGORY.NodeGroupCaseStudy]: 'Case Studies',
  [CATEGORY.NodeGroupTeamBio]: 'Team Bios',
  [CATEGORY.NodeGroupPackagedService]: 'Packaged Services',
  [CATEGORY.NodeGroupBasic]: 'Group',
  [CATEGORY.NodeGroupColumnsContainer]: 'Columns Container',
  [CATEGORY.NodeGroupColumn]: 'Column',
}

