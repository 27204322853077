<template>
    <span
        :class="[
            $style['icon-graphic'],
            $style['icon-graphic-' + className],
            $style[type],
        ]"
    >
        <SvgIcon :name="icon" :class="$style.icon" />
    </span>
</template>
<script>
export default {
    name: 'IconGraphic',
    props: {
        icon: String,
        className: String,
        type: String,
    },
}
</script>
<style module lang="scss">
.icon-graphic-smart {
    display: inline-flex;
    color: white;
    background-color: $primary;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-x2;
    align-items: center;
    justify-content: center;
    margin-right: $gutter;
    .icon {
        color: white;
        width: 24px;
        height: 24px;
        margin: 0;
    }
}
.icon-graphic-placeholder-grid {
    display: inline-flex;
    color: white;
    background-color: $primary;
    width: 64px;
    height: 64px;
    border-radius: $border-radius-x2;
    align-items: center;
    justify-content: center;
    margin-right: $gutter;
    .icon {
        color: white;
        width: 32px !important;
        height: 32px !important;
        margin: 0;
    }
}

.icon-graphic-standard {
    .icon {
        color: $primary;
        margin-right: 10px;
    }
}

.icon-graphic-cost-table {
    background-color: $orange-100;
    .icon {
        width: 24px;
        height: 24px;
    }
}

.icon-graphic-cost-summary {
    background-color: $green-1000;
    .icon {
        width: 24px;
        height: 24px;
    }
}
.icon-graphic-estimate-status {
    background-color: $green-300;
    .icon {
        width: 24px;
        height: 24px;
    }
}

.icon-graphic-proposal {
    background-color: $green-300;
    .icon {
        width: 24px;
        height: 24px;
    }
}

.icon-graphic-brief {
    background-color: $primary-100;
    .icon {
        width: 24px;
        height: 24px;
    }
}
.icon-graphic-estimate {
    background-color: $purple;
    .icon {
        width: 24px;
        height: 24px;
    }
}

.icon-graphic-reusable-block {
    background-color: $gray-500;
    .icon {
        width: 24px;
        height: 24px;
    }
}

.icon-graphic-template {
    .icon {
        width: 72px;
        height: 72px;
    }
}

.icon-graphic-portfolio {
    background-color: $gray-500;
    .icon {
        width: 24px;
        height: 24px;
    }
}
.icon-graphic-billable {
    background-color: $gray-500;
    .icon {
        width: 24px;
        height: 24px;
    }
}
.icon-graphic-team {
    background-color: $gray-500;
    .icon {
        width: 24px;
        height: 24px;
    }
}
.icon-graphic-estimates-group {
    background-color: $primary;
    .icon {
        width: 24px;
        height: 24px;
    }
}
</style>
