
<DocumentContentLoader
  v-slot="{ isLoading }"
  :document="document"
>
  <div
    v-if="!isLoading"
    :class="[
      'flex flex-col lg:flex-row relative print:block -mx-16 lg:mx-0 flex-1 bg-page rounded-lg',
      !isClientPortal && 'print:pt-0'
    ]"
  >
    <DocumentViewerClientPortalDesktop
      :document="document"
      :organization="organization"
      :project="project"
      :print="print"
      :is-client-portal="isClientPortal"
    />
  </div>
</DocumentContentLoader>
