import Vue from 'vue'
import { prop, indexBy, move, pluck } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { computed, unref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import matchKeyword from '@/lib/match-keyword'
import useOptimisticUpdate from '@/v2/lib/composition/useOptimisticUpdate'
import { useUser } from '@/v2/services/users/usersCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'
import { useTracking } from '../../tracking/compositions'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {Object} Options
 * @property {Record<string, string>} [viewContext]
 * @property {string} [keyword]
 * @property {Object} [taskSectionsQuery]
 */

const propId = prop('_id')
const indexById = indexBy(propId)
const pluckId = pluck('_id')

const constrainValue = (n, min, max) => Math.max(min, Math.min(n, max));

export const insertElemAtIndex = (arr, elem, index) => {
  const safeIndex = constrainValue(index, 0, arr.length)
  const arrCopy = [...arr]
  arrCopy.splice(safeIndex, 0, elem)

  return arrCopy
}

export const removeElemByCond = (arr, condFn) => arr.filter(elem => !condFn(elem))


export const filterTasks = (tasks, { user, filterBy, keyword }) => {
  const byKeyword = matchKeyword(keyword)
  const matchTask = task => byKeyword(task.title)

  switch (filterBy) {
    case 'resolvedTasks':
      return tasks.filter(task => task.isResolved && matchTask(task))
    case 'pendingTasks':
      return tasks.filter(task => !task.isResolved && matchTask(task))
    case 'dueTasks':
      return tasks.filter(task => task.isDue && matchTask(task))
    case 'assignedToMe':
      return tasks.filter(task => task.assignedTo === user._id && matchTask(task))
    case 'createdByMe':
      return tasks.filter(task => task.createdBy === user._id && matchTask(task))
    case 'publicTasks':
      return tasks.filter(task => task.public && matchTask(task))
    case 'privateTasks':
      return tasks.filter(task => !task.public && matchTask(task))
    default:
      return tasks.filter(matchTask)
  }
}

export const groupTasksByStatus = section => {
  const groups = {
    resolved: [],
    open: [],
    due: [],
    notDue: [],
  }

  section.tasks.forEach(task => {
    if (task.isResolved) {
      groups.resolved.push(task)
    } else {
      groups.open.push(task)
    }

    if (task.isDue) {
      groups.due.push(task)
    } else {
      groups.notDue.push(task)
    }
  })

  return groups
}

const getDueTime = task => new Date(task.dueDate).getTime()

export const sortByDueDate = tasks => {
  const sortedTasks = [...tasks]
  sortedTasks.sort((a, b) => getDueTime(a) - getDueTime(b))
  return sortedTasks
}

/**
 * @param {Ref<import('../projects').ProjectData} project
 * @param {Ref<Options>} [options]
 */
export default function useProjectTasks(project, options) {
  const { t } = useLocalization()
  const { ActionItem, TaskSection } = Vue.$FeathersVuex.api

  const { track } = useTracking()
  const { user } = useUser()

  const {
    update: optimisticUpdate,
    updateMany: optimisticUpdates,
  } = useOptimisticUpdate()

  const msgBoxConfirmDelete = useMsgBoxConfirmDelete()

  // -- TASK SECTIONS
  const { items: taskSections, isPending } = useFind({
    model: TaskSection,
    params: {
      query: {
        project: unref(project)._id,
        ...unref(options)?.taskSectionsQuery,
      },
    },
  })

  const taskIds = computed(() => taskSections.value.flatMap(section => section.tasks))
  const indexedTaskSections = computed(() => indexById(taskSections.value))

  // TASKS (ACTION ITEMS)
  const paramsTasks = computed(() => ({
    query: {
      _id: { $in: taskIds.value },
      project: unref(project)._id,
    },
  }))

  const { items: tasks } = useFind({
    model: ActionItem,
    params: paramsTasks,
  })

  const filteredTasks = computed(() => {
    const { viewContext = null, keyword = '' } = unref(options) ?? {}
    return filterTasks(tasks.value, {
      user: user.value,
      filterBy: viewContext?.filterBy,
      keyword,
    })
  });

  const indexedTasks = computed(() => indexById(filteredTasks.value))

  // -- AGGREGATE ALL DATA
  const sections = computed(
    () => rejectNil(project.value.taskSections.map(taskSectionId => {
      const taskSection = indexedTaskSections.value[taskSectionId];

      if (!taskSection) {
        return null;
      }

      const _tasks = rejectNil(taskSection.tasks?.map(
        taskId => indexedTasks.value?.[taskId] ?? null
      ))

      return { taskSection, tasks: _tasks }
    }))
  )

  const updateProject = data => optimisticUpdate(unref(project), rejectNil(data))

  // TASK SECTION METHODS
  const createSection = async (title = 'untitled section') => {
    const {
      _id: projectId,
      organization: organizationId,
      taskSections: currentSectionIds,
    } = unref(project)

    const data = {
      organization: organizationId,
      project: projectId,
      title,
    }
    const taskSection = new TaskSection(data)
    await taskSection.save()

    const updatedSectionIds = [...currentSectionIds, taskSection._id]
    updateProject({ taskSections: updatedSectionIds })

    track('Task Section Created')

    return taskSection
  }

  const removeSection = async section => {
    const { taskSection } = section

    let message;
    if (taskSection.tasks?.length) {
      message = 'Are you sure you want to delete this section? '
        + 'This section contains tasks that will be permanently deleted. '
        + 'You can move the tasks to another section to keep them. '
        + 'This action is irreversible.'
    } else {
      message = 'Are you sure you want to delete this section? This action is irreversible.'
    }

    const ok = await msgBoxConfirmDelete({
      title: 'Delete section',
      message,
      size: 'md',
    })

    if (!ok) {
      return
    }

    const { taskSections: currentSectionIds } = unref(project)
    const updatedSectionIds = removeElemByCond(currentSectionIds, id => id === taskSection._id)

    updateProject({ taskSections: updatedSectionIds })
    taskSection.remove()
  }

  const updateSection = (section, data) => {
    const { taskSection } = section
    optimisticUpdate(taskSection, rejectNil(data))
  }

  const reorderSections = reorderedSections => {
    const reorderedIds = reorderedSections.map(({ taskSection }) => taskSection._id)
    updateProject({ taskSections: reorderedIds })
  }

  // TASK METHODS
  const removeTask = async (section, task) => {
    const ok = await msgBoxConfirmDelete({
      title: t('tasks.dialogs.deleteTask.title', { title: task.title }).value,
      message: t('tasks.dialogs.deleteTask.message').value,
      size: 'md',
    })

    if (!ok) {
      return false
    }

    await task.remove()
    return true
  }


  const updateTask = (taskId, data) => {
    const actionItem = ActionItem.getFromStore(taskId)
    optimisticUpdate(actionItem, data)
  }

  const moveTask = params => {
    const { task, oldSection, oldIndex, newSection, newIndex } = params

    const { taskSection: oldTaskSection } = oldSection
    const { taskSection: newTaskSection } = newSection


    if (newTaskSection._id === oldTaskSection._id) {
      // moved within the same section
      const updatedTaskIds = move(oldIndex, newIndex, oldTaskSection.tasks);
      optimisticUpdate(oldTaskSection, { tasks: updatedTaskIds })
      return
    }

    // moved from one section to other section
    const oldTaskIds = removeElemByCond(oldTaskSection.tasks, taskId => taskId === task._id)
    const newTaskIds = insertElemAtIndex(newTaskSection.tasks, task._id, newIndex)

    const updates = [
      [oldTaskSection, { tasks: oldTaskIds }],
      [newTaskSection, { tasks: newTaskIds }],
      [task, { taskSection: newTaskSection._id }],
    ]

    optimisticUpdates(updates)
  }

  const moveResolvedTasksToBottom = section => {
    const { taskSection } = section
    const { open, resolved } = groupTasksByStatus(section)

    const updatedTaskIds = [...pluckId(open), ...pluckId(resolved)]

    optimisticUpdate(taskSection, { tasks: updatedTaskIds })
  }

  const movePastDueTasksToTop = section => {
    const { taskSection } = section
    const { due, notDue } = groupTasksByStatus(section)

    const orderedDue = sortByDueDate(due)
    const updatedTaskIds = [...pluckId(orderedDue), ...pluckId(notDue)]

    optimisticUpdate(taskSection, { tasks: updatedTaskIds })
  }


  const deleteResolvedTasks = async section => {
    const { taskSection } = section
    const { resolved } = groupTasksByStatus(section)

    const ok = await msgBoxConfirmDelete({
      title: `Delete resolved tasks from "${taskSection.title}" ?`,
      message: 'Are you sure you want to delete all resolved tasks? This action is irreversible.',
      size: 'md',
    })

    if (!ok) {
      return
    }

    Promise.all(resolved.map(task => task.remove()))
  }


  const handleSectionMenuAction = (section, action) => {
    switch (action) {
      case 'moveResolvedToBottom':
        moveResolvedTasksToBottom(section)
        break
      case 'movePastDueToTop':
        movePastDueTasksToTop(section)
        break
      case 'deleteResolved':
        deleteResolvedTasks(section)
        break
      default:
        break
    }
  }

  return {
    sections,
    isPending,

    // -- sections
    createSection,
    updateSection,
    removeSection,
    reorderSections,
    handleSectionMenuAction,

    // -- tasks
    // createTask,
    removeTask,
    updateTask,
    moveTask,
    moveResolvedTasksToBottom,
    movePastDueTasksToTop,
    deleteResolvedTasks,
  }
}
