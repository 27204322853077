<template>
  <b-dropdown
    ref="dropdown"
    :right="position === 'right'"
    :variant="variant"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #button-content>
      <slot name="button-content" />
    </template>
    <slot />
  </b-dropdown>
</template>

<script>
export default {
  name: 'ListLightDropdown',
  props: {
    variant: {
      type: String,
      default: 'reset',
    },
    position: {
      type: String,
      default: 'center',
    },
  },
}
</script>
