export const COLORS = {
  none: 'none',
  green: 'green',
  blue: 'blue',
  yellow: 'yellow',
  orange: 'orange',
  red: 'red',
  violet: 'violet',
  gray: 'gray',
}

export const HIGHLIGHT_COLORS = {
  [COLORS.none]: 'background-none',
  [COLORS.yellow]: 'background-yellow',
  [COLORS.red]: 'background-red',
  [COLORS.blue]: 'background-blue',
  [COLORS.green]: 'background-green',
  [COLORS.violet]: 'background-violet',
  [COLORS.orange]: 'background-orange',
  [COLORS.gray]: 'background-gray',
}

export const EDITOR_FEATURES = {
  highlight: 'highlight',
  headings: 'headings',
  bulletList: 'bulletList',
  orderedList: 'orderedList',
  blockquote: 'blockquote',
  image: 'image',
  code: 'code',
  link: 'link',
  bold: 'bold',
  italic: 'italic',
  strike: 'strike',
  textAlign: 'textAlign',
}


export const EDITOR_HEADINGS = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 5,
}
