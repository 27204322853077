export default function injectPluginFactory() {
  return {
    install(Vue, { name, dependency }) {
      Object.defineProperty(Vue.prototype, name, {
        get() {
          return dependency
        },
      })
    },
  }
}
