
<!-- v-if="isToggled" -->
<FeathersVuexFind
  v-slot="{ items: comments }"
  :params="findParams"
  service="comments"
  watch="params"
>
  <div v-fragment>
    <!-- HEADER -->
    <div
      class="
        flex items-center px-0 py-8 space-x-6 text-14
        border-b border-gray-200 dark:border-darkGray-700
      "
    >
      <span class="text-gray-600 dark:text-darkGray-400">{{ $t('comments.showing') }}</span>
      <b-dropdown
        :text="filter"
        toggle-class="dropdown-arrow"
        variant="neutral"
        size="xs"
        menu-class="w-128 text-13"
      >
        <b-dropdown-item
          v-for="f in ['All', 'Resolved', 'Open']"
          :key="f"
          :active="filter === f"
          @click="filter = f"
        >
          {{ filterNames[f] }}
        </b-dropdown-item>
      </b-dropdown>
      <span class="text-gray-600 dark:text-darkGray-400">{{ $t('common.comments') }}</span>
    </div>

    <!-- COMMENTS -->
    <div class="flex flex-col" style="height: calc(100% - 50px);">
      <div ref="sidebarScrollContainer" class="flex-1 py-16 space-y-12 overflow-y-auto">
        <CommentsHeader
          v-if="documentSidebarStatus.commentNodeId"
          :node-id="documentSidebarStatus.commentNodeId"
          @show-all-comments="toggleCommentsWithNode(null)"
        />

        <!-- LIST OF COMMENTS -->
        <transition-group
          v-if="comments.length"
          class="space-y-12"
          name="comments-list"
          tag="div"
        >
          <CommentsItem
            v-for="comment in comments"
            :key="comment._id"
            :comment="comment"
            :active-comment="activeComment"
            :user="user"
            :comment-node-id="documentSidebarStatus.commentNodeId"
            @comment-click="() => onCommentClick(comment)"
            @comment-edit="() => onEdit(comment)"
            @comment-set-resolved="flag => onSetResolvedStatus(comment, flag)"
            @comment-remove="() => onRemove(comment)"
            @visibility-change="visible => onVisibilityChange(visible, comment)"
          />
        </transition-group>
        <div v-else class="inline-flex text-left text-gray-500  dark:text-darkGray-400 text-14">
          {{ $t('comments.no-comments') }}
        </div>
      </div>

      <div class="pb-16 md:pb-8">
        <CommentsForm
          :comments-toggled="documentSidebarStatus.isExpanded"
          :user="user"
          :new-comment-data="newCommentData"
          :comment="activeComment"
          @created="onCommentSaved"
          @updated="onCommentSaved"
        />
      </div>
    </div>
  </div>
</FeathersVuexFind>
