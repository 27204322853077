
<div
  class="relative z-10 p-16"
  data-intercom-target="Project Quick Links"
>
  <!-- grid grid-cols-4 w-full auto-cols-fr gap-24 -->
  <div class="relative group quick-links">
    <template v-if="project.portalQuickLinks.length">
      <ProjectQuickLink
        v-for="link in project.portalQuickLinks"
        :key="link._id"
        :link="link"
      />
    </template>

    <ProjectQuickLinkPlaceholder
      v-if="canManage"
      @click="$emit('create')"
    />

    <!-- redirect to project quick links settings -->
    <ProjectDashboardQuickLinksManage
      v-if="canManage"
      :to="routes.settingsQuickLinks"
    />
  </div>
</div>
