
<div id="kanban-board" class="grid overflow-x-auto kanban">
  <KanbanColumns
    :sections="sections"
    :selected-task-id="selectedTaskId"
    :lock-tasks-to-section="lockTasksToSection"
    :readonly-sections="readonlySections"
    :readonly-task="readonlyTask"
    :no-task-reordering="noTaskReordering"
    :no-section-menu="noSectionMenu"
    @update="(section, data) => $emit('update-section', section, data)"
    @remove="section => $emit('remove-section', section)"
    @reorder="reorderedSections => $emit('reorder-sections', reorderedSections)"
    @menu-action="(section, action) => $emit('section-menu-action', section, action)"
    @create-task="(section, index) => $emit('create-task', section, index)"
    @remove-task="(section, task) => $emit('remove-task', section, task)"
    @reorder-tasks="(section, reorderedTasks) => $emit('reorder-tasks', section, reorderedTasks)"
    @drag-event-tasks="onDragEventTasks"
  >
    <template #task="{ section, task }">
      <slot name="task" v-bind="{ section, task }" />
    </template>
  </KanbanColumns>
</div>
