<template>
  <CurrencyFormatter
    v-if="currency"
    :value="value"
    :symbol="symbol"
    :currency="currency"
  />
</template>
<script>
import Vue from 'vue'
import { defineComponent, computed, inject } from '@vue/composition-api';
import CurrencyFormatter from '@/components/CurrencyFormatter.vue';

export default defineComponent({
  name: 'DocumentCurrency',
  components: {
    CurrencyFormatter,
  },
  props: {
    documentId: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 0,
    },
    symbol: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const project = inject('project', null)
    const { Document } = Vue.$FeathersVuex.api
    const document = computed(() => Document.getFromStore(props.documentId))

    const currency = computed(() => {
      if (document.value?.isOrganizationLevel && project.value) {
        return project.value.currency
      }

      return document.value?.currency
    })

    return {
      document,
      currency,
    }
  },
})
</script>
