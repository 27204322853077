
<ListLightDropdownItem
  :label="label"
  :icon="icon"
  has-submenu
  @select="$emit('select')"
>
  <ul
    ref="menuRef"
    v-click-outside.capture="() => $emit('close')"
    class="dropdown-menu dropdown-submenu group-hover:block text-14"
    role="menu"
    @click.stop="$emit('close')"
  >
    <b-dropdown-header class="mb-4">
      Question types
    </b-dropdown-header>
    <slot />
  </ul>
</ListLightDropdownItem>
