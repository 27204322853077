
<ListMultiSelect
  v-slot="{ item: billable }"
  v-model="selectedBillables"
  :items="items"
  id-prop="rateId"
>
  <p class="grow inline-flex items-center">
    {{ billable.name }}
    <b-badge
      v-if="!billable.isBillable"
      variant="neutral"
      class="mx-4"
    >
      Not billable
    </b-badge>
  </p>
  <div class="text-right mr-auto font-semibold">
    <DocumentCurrency
      :value="Number(billable.rate)"
      :document-id="document._id"
    /> / {{ billable.unit }}
  </div>
</ListMultiSelect>
