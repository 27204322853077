<template>
  <div
    id="page-header-search-input"
    v-click-outside.capture="() => $emit('close')"
  >
    <div class="w-320 relative">
      <b-input
        :value="value"
        trim
        type="text"
        :placeholder="placeholder"
        class="rounded-sm pr-36"
        size="lg"
        @input="onKeywordChange"
        @keydown.esc="$emit('close')"
      />
      <span class="icon_v2-so_search text-gray-300 absolute right-10 top-6"></span>
    </div>
    <b-popover
      ref="popover"
      triggers="manual"
      target="page-header-search-input"
      :show="showResult"
      placement="bottomright"
      custom-class="max-w-[600px] shadow-md border-gray-300 p-8 pb-0"
      boundary-padding="25"
      @update:show="val => $emit('update:showResult', val)"
    >
      <div>
        <slot />
      </div>
    </b-popover>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import debounce from '@/v2/lib/helpers/debounce'

export default defineComponent({
  name: 'PageHeaderSearch',
  components: {

  },
  props: {
    value: {
      type: String,
      default: '',
    },
    showResult: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const popover = ref(null)

    const onKeywordChange = debounce(keyword => {
      context.emit('input', keyword)
    }, 500)

    return {
      popover,
      onKeywordChange,
    }
  },
})
</script>
