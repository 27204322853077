<template>
  <div v-fragment>
    <slot
      v-bind="{
        remove,
        update,
        duplicate,
        move,
        download,
        share,
        saveAsReusableBlock,
        changeFonts,
        approve
      }"
    />
    <!-- share modal -->
    <DocumentEditorShareModal
      v-if="modalState.share"
      v-model="modalState.share"
      :document="document"
      size="lg"
    />

    <!-- move modal -->
    <ProjectFolderSelectModal
      v-if="modalState.move"
      key="header-folder-select-modal"
      v-model="modalState.move"
      title="Select destination"
      :hidden-folder-ids="[document._id]"
      ok-label="Move"
      :project-id="document.project"
      @select="onMoveTargetFolderSelected"
    />

    <!-- document download -->
    <portal v-if="modalState.download" to="right-drawer">
      <DocumentDownload
        :document="document"
        @close="modalState.download = false"
      />
    </portal>

    <!-- document fonts -->
    <DocumentFormModal
      v-if="modalState.fonts"
      v-model="modalState.fonts"
      :document="document"
      @save="modalState.fonts = false"
    />

    <!-- duplicate modal -->
    <DocumentCopyModal
      v-if="modalState.duplicate"
      v-model="modalState.duplicate"
      :document="document"
      @success="onDuplicateSuccess"
    />
  </div>
</template>
<script>
import { computed, defineComponent, reactive } from '@vue/composition-api'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import useCustomFileSystem from '@/v2/lib/composition/useCustomFileSystem'
import { useDocumentRemove, useDocumentLimit, useDocumentOpen } from '@/v2/services/documents/compositions'
import { useFeatureEnabled } from '@/v2/services/organizations/compositions'
import ProjectFolderSelectModal from '@/components/ProjectFolderSelectModal.vue'
import DocumentDownload from '@/components/DocumentDownload.vue'
import DocumentEditorShareModal from '@/components/Document/Editor/DocumentEditorShareModal.vue'
import DocumentFormModal from '@/components/Document/DocumentFormModal.vue'
import DocumentCopyModal from '@/components/DocumentCopyModal.vue';
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import { STATUS } from '@/v2/services/documents/documentsTypes'

const msgBoxConfirmProps = () => ({
  size: 'md',
  cancelLabel: 'No, Thanks',
  okLabel: 'Yes, Take Me There',
})

export default defineComponent({
  name: 'DocActions',
  components: {
    ProjectFolderSelectModal,
    DocumentDownload,
    DocumentEditorShareModal,
    DocumentFormModal,
    DocumentCopyModal,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const modalState = reactive({
      share: false,
      move: false,
      download: false,
      fonts: false,
      duplicate: false,
    })

    const msgBoxConfirm = useMsgBoxConfirm()
    const { checkDocumentLimit } = useDocumentLimit()
    const { checkFeatureEnabled } = useFeatureEnabled()
    const openDocument = useDocumentOpen()

    const {
      saveAsReusableBlock: _saveAsReusableBlock,
      duplicate: _duplicate,
    } = useDocuments()

    const _remove = useDocumentRemove(computed(() => props.document))
    const remove = ({ redirect = true, route = null } = {}) => _remove(redirect, route)

    const { moveCurrentToFolder } = useCustomFileSystem()

    const update = data => {
      props.document.save({ data })
    }

    const download = () => {
      if (checkFeatureEnabled('downloadFolder')) {
        modalState.download = true
      }
    }

    const changeFonts = () => {
      modalState.fonts = true
    }

    const share = () => {
      modalState.share = true
    }

    // -- move
    const move = () => {
      modalState.move = true
    }

    const onMoveTargetFolderSelected = targetFolderId => {
      modalState.move = false
      moveCurrentToFolder(targetFolderId)
    }

    // -- save as reusable block
    const onSaveAsReusableBlockSuccess = async document => {
      const ok = await msgBoxConfirm({
        title: 'Reusable Block Saved',
        message: 'Would you like to navigate to the reusable block?',
        ...msgBoxConfirmProps(),
      })

      if (ok) {
        openDocument(document)
      }
    }

    const saveAsReusableBlock = async () => {
      if (checkDocumentLimit(props.document.category)) {
        return
      }

      const document = await _saveAsReusableBlock(props.document, {}, false)
      onSaveAsReusableBlockSuccess(document)
    }

    // -- duplicate
    const onDuplicateSuccess = async document => {
      modalState.duplicate = false

      const ok = await msgBoxConfirm({
        title: 'Document Successfully Duplicated',
        message: 'Would you like to view the duplicated document?',
        ...msgBoxConfirmProps(),
      })

      if (ok) {
        openDocument(document)
      }
    }

    const duplicate = async () => {
      if (checkDocumentLimit(props.document.category)) {
        // plan limit reached
        return
      }

      // library docs
      if (props.document.isOrganizationLevel) {
        const document = await _duplicate(props.document)
        onDuplicateSuccess(document)
        return
      }

      // project docs
      modalState.duplicate = true
    }

    const approve = approved => {
      const newStatus = approved ? STATUS.APPROVED : STATUS.DRAFT
      update({ status: newStatus })
    }

    return {
      modalState,
      update,
      move,
      remove,
      duplicate,
      approve,
      onMoveTargetFolderSelected,
      saveAsReusableBlock,
      download,
      changeFonts,
      share,
      onDuplicateSuccess,
    }
  },
})
</script>
