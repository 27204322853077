
<span
  :style="{ backgroundColor: labelBackgroundColor, color: textColor }"
  class="inline-flex items-center justify-center flex-none font-extrabold leading-none"
  :class="[
    !fullLabel && fontSize,
    !fullLabel && labelSize,
    fullLabel && 'w-full p-6 font-medium text-11 rounded-full'
  ]"
>
  <span v-if="!noText">{{ displayLabel }}</span>
</span>
