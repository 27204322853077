import { computed } from '@vue/composition-api'
import { isNil } from 'lodash'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

export default function useNotificationAuthor(notification) {
  const authorMemberProfile = computed(() => notification.value.author$?.user$?.profile$)
  const isGuest = computed(() => Boolean(notification.value.authorGuest))
  const isMember = computed(() => Boolean(authorMemberProfile.value))
  const isGroup = computed(() => Array.isArray(notification.value.events))

  // deleted or other error
  const isAuthorUnavailable = computed(
    () => !isGroup.value
      && !isGuest.value
      && isNil(authorMemberProfile.value)
  )

  const authorProfile = computed(() => {
    if (isGuest.value) {
      const { authorGuest } = notification.value;

      return authorGuest?.name
        ? { fullName: authorGuest.name } // return a pseudo-profile for Avatar component
        : null; // no guest name
    }

    if (isAuthorUnavailable.value) {
      return null;
    }

    return authorMemberProfile.value // return full member profile
  });

  const authorName = computed(
    () => authorProfile.value?.fullName
      || (isGuest.value && 'Guest')
      || (isAuthorUnavailable.value && '[User Deleted]')
      || null
  )

  return {
    isGuest,
    isMember,
    isAuthorUnavailable,
    authorProfile,
    authorName,
  }
}
