<template>
  <DatePicker
    v-model="model"
    :clearable="clearable"
    :placeholder="placeholder ?? $t('tasks.taskEditor.dueDatePlaceholder')"
    :no-input="noInput"
    :readonly="readonly"
  />
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { isNil } from 'ramda';
import DatePicker from '@/components/DatePicker/DatePicker.vue'

export default defineComponent({
  name: 'TaskDatePicker',
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    noInput: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const model = computed({
      get() {
        if (isNil(props.value)) {
          return null
        }

        return new Date(props.value)
      },
      set(val) {
        if (isNil(val)) {
          context.emit('input', null)
          return
        }

        const dueDate = new Date(val.getTime());
        dueDate.setHours(23);
        dueDate.setMinutes(59);
        dueDate.setSeconds(59);
        context.emit('input', dueDate.toISOString())
      },
    })

    return {
      model,
    }
  },
})
</script>
