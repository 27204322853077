<template>
  <Vue2DatePicker
    v-model="model"
    class="so-datepicker"
    type="date"
    prefix-class="xmx"
    :formatter="dateFormatter"
    :show-animation="false"
    :clearable="clearable && !readonly"
    :append-to-body="false"
    :editable="!readonly"
    :disabled="readonly"
  >
    <template #input="{ props, events }">
      <b-input
        v-if="!noInput"
        :value="props.value"
        :readonly="readonly"
        :disabled="readonly"
        class="so-datepicker-input capitalize text-14 cursor-pointer"
        :placeholder="placeholder"
        v-on="events"
      />
      <span v-else-if="props.value" class="pr-32">{{ props.value }}</span>
      <span v-else class="text-gray-400 pr-32">{{ placeholder }}</span>
    </template>
  </Vue2DatePicker>
</template>
<script>
import { isNil } from 'ramda';
import { computed, defineComponent, getCurrentInstance,watch } from '@vue/composition-api'
import Vue2DatePicker from 'vue2-datepicker'

export default defineComponent({
  name: 'DatePicker',
  components: {
    Vue2DatePicker,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    noInput: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const lang = computed(() => vm.$root.$i18n.locale)

    const dateTimeFormat = computed(() => new Intl.DateTimeFormat(lang.value, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }))

    const dateFormatter = {
      stringify(date) {
        if (isNil(date)) {
          return null
        }

        return dateTimeFormat.value.format(date);
      },
    }

    const model = computed({
      get() {
        return props.value
      },
      set(val) {
        context.emit('input', val)
      },
    })

    watch(lang, async newLang => {
      const { default: locale } = await import(/* @vite-ignore */`./locales/${newLang}.js`)
      Vue2DatePicker.locale(newLang, {
        formatLocale: locale,
        yearFormat: 'YYYY',
        monthFormat: 'MMM',
        monthBeforeYear: true,
      })
    }, { immediate: true })

    return {
      dateFormatter,
      model,
    }
  },
})
</script>
<style lang="postcss" scoped>
.so-datepicker {
  width: 100%;
}

.so-datepicker-input[readonly] {
  background-color: initial;
}
</style>
