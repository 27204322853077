<template>
  <div class="relative z-20 flex flex-col gap-8 w-full">
    <b-button
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Copy link to clipboard`"
      :variant="variant==='guest' ? 'dark' : 'primary'"
      size="sm"
      :block="false"
      class="px-16 w-full"
      @click.stop="copyLinkToClipboard"
    >
      <span v-if="!showCopyLinkPopover" class="w-16 mr-4 icon-util-copy text-16"></span>
      <span v-if="showCopyLinkPopover">Copied!</span>
      <slot v-else></slot>
    </b-button>
    <b-button
      v-if="showOpenInNewTab"
      variant="reset"
      size="sm"
      :href="link"
      target="_blank"
      class="font-normal text-gray-500 text-13 self-start p-0"
    >
      <span class="w-14 icon-external-link text-14 text-gray-400"></span>
      <span class="pl-6">Open public link in a new tab</span>
    </b-button>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useDomain from '@/v2/services/domains/useDomain'
import useClipboard from '@/v2/lib/composition/useClipboard'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'ShareLink',
  components: {
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: 'guest',
    },
    showOpenInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showCopyLinkPopover = ref(false)

    const { withOrganizationUrl, withClientPortalUrl } = useDomain({ fetch: false })
    const organization = useCurrentOrganization()
    const clipboard = useClipboard()

    const relativeUrl = computed(() => `share/${props.document.sharingLinkToken}`);
    const link = computed(
      () => withClientPortalUrl(organization, relativeUrl.value).value
        ?? withOrganizationUrl(organization, relativeUrl.value).value
    );
    const portalUrl = computed(() => withClientPortalUrl(organization, relativeUrl.value).value)
    const copyLinkToClipboard = async () => {
      await clipboard.writeText(link.value)
      showCopyLinkPopover.value = true
    }

    const closeLinkPopover = () => {
      showCopyLinkPopover.value = false
    }

    return {
      showCopyLinkPopover,
      link,
      portalUrl,
      copyLinkToClipboard,
      closeLinkPopover,
    }
  },
})
</script>
