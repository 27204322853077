import Vue from 'vue'
import { when, is } from 'ramda'
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  parseISO,
} from 'date-fns'

const ensureDate = when(is(String), parseISO)

const unitToFnMap = {
  day: differenceInCalendarDays,
  month: differenceInCalendarMonths,
  year: differenceInCalendarYears,
}

Vue.filter('dateCountdown', (value, unit = 'day') => (value
  ? unitToFnMap[unit](ensureDate(value), new Date())
  : ''))
