<template>
  <ModalBasic
    :title="`Share this ${CATEGORY_TITLES[document.category]}`"
    :no-close="isLoading"
    :hide-footer="true"
    dialog-class="modal-dialog-share"
    content-class="modal-content-tertiary"
    body-class="modal-body-share"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    hide-header
    size="sm"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body>
      <h3 class="project-page-title">
        Share this <span class="lowercase">{{ CATEGORY_TITLES[document.category] }}</span>
      </h3>
      <p class="text-13 text-gray-400 mt-4">Manage sharing options for {{ document.title }}</p>
      <FormMessage v-if="formMessage">
        {{ formMessage }}
      </FormMessage>
      <!-- --------------------------------------------------- -->
      <!-- SHARING TO CLIENT PORTAL -->
      <!-- --------------------------------------------------- -->
      <div class="flex flex-col pt-32 gap-16">
        <!-- Sharing to client portal -->
        <div v-if="isClientSharable">
          <!-- Trigger -->
          <div class="flex items-center justify-between">
            <h4 class="text-gray-700 flex items-center gap-6">
              <span class="icon_v2-so_shared text-20"></span>
              <span class="font-medium text-gray-900">Client portal</span>
              <span
                v-b-tooltip.hover.top.v-info.dh0.ds200="
                  'All client contacts logged in to your portal can see this'
                "
                class="icon_v2-so_info text-14 text-gray-400 bg-transparent"
              ></span>
            </h4>
            <!-- <SettingsSwitch
              :value="isClientShared"
              @input="toggleSharingClient()"
            /> -->
            <span
              v-if="!isClientShared"
              class="inline-flex items-center px-8 py-4 leading-none
            bg-gray-200 text-gray-800 text-12 font-semibold
            rounded-md uppercase"
            >Not Shared</span>
            <span
              v-else
              class="inline-flex items-center px-8 py-4 leading-none
            bg-green-200 text-green-900 text-12 font-semibold
            rounded-md uppercase"
            >Shared</span>
          </div>
          <!-- Content -->
          <div v-if="isClientShared" class="mt-8 pt-8">
            <!-- <ShareLink
              :document="document"
              variant="portal"
            >
              Copy client portal link
            </ShareLink> -->
            <p class="text-gray-600 text-14 leading-7">
              Unshare this <span class="lowercase">
                {{ CATEGORY_TITLES[document.category] }}
              </span> by moving it <em>outside</em> the <strong
                class="inline-flex px-4 py-2 not-italic font-semibold
              leading-none text-green-900 rounded
              bg-green-200/50"
              >
                <span class="icon_v2-so_shared text-14 mr-4"></span>
                Shared</strong> folder.
              <b-button
                variant="neutral"
                size="xs"
                @click="toggleSharingClient()"
              >
                <span class="icon_v2-so_folder-move text-13 text-gray-500"></span>
                <span class="pl-4">Move from Shared</span>
              </b-button>
            </p>
          </div>
          <div v-if="!isClientShared">
            <p class="text-gray-600 text-14 mt-8 leading-7">
              Share this
              <span class="lowercase">
                {{ CATEGORY_TITLES[document.category] }}
              </span> with client contacts by moving it <em>inside</em> the
              <strong
                class="inline-flex px-4 py-2 not-italic
                font-semibold leading-none text-green-900 rounded
                bg-green-200/50"
              >
                <span class="icon_v2-so_shared text-14 mr-4"></span>
                Shared</strong> folder.
              <b-button
                variant="neutral"
                size="xs"
                @click="toggleSharingClient()"
              >
                <span class="icon_v2-so_folder-move text-13 text-gray-500"></span>
                <span class="pl-4">Move to Shared</span>
              </b-button>
            </p>
          </div>

          <div
            v-if="showProjectTree"
            class="flex flex-col items-stretch gap-12 p-16
            border border-gray-300 rounded-md bg-gray mt-12"
          >
            <p class="text-gray-600 text-13">Select a folder:</p>
            <ProjectFolderTree
              v-model="selectedFolderId"
              :project-id="document.project"
              :folder-category="shownTreeCategory"
              :hidden-folder-categories="hiddenTreeCategory"
            />
            <div class="mr-auto flex items-center">
              <Button
                :variant="isClientShared ? 'dark' : 'branded'"
                type="button"
                size="xs"
                :disabled="isLoading || !selectedFolderId"
                data-intercom-target="Enable Sharing Button"
                class=""
                @click.stop="changeSharingClient()"
              >
                <!-- <i class="mr-6 icon-util-view"></i> -->
                <span>Confirm &amp; {{ isClientShared ? 'Unshare' : 'Share' }}</span>
              </Button>
            </div>
          </div>
        </div>
        <!-- Sharing Public Link -->
        <div>
          <div class="flex items-center justify-between">
            <h4 class="text-gray-700 flex items-center gap-6">
              <span class="icon_v2-so_globe text-20"></span>
              <span class="font-medium text-gray-900">Public Link</span>
              <span
                v-b-tooltip.hover.top.v-info.dh0.ds200="'Only people with the link can see this'"
                class="icon_v2-so_info text-14 text-gray-400 bg-transparent"
              ></span>
            </h4>
            <FeatureEnabled
              v-slot="{ checkFeatureEnabled }"
              flag="documentPublicLink"
            >
              <div
                @click="() => checkFeatureEnabled(/** no evt arg to prevent stop propagation */)"
              >
                <FeatureFlags v-slot="{ documentPublicLink }" :flags="['documentPublicLink']">
                  <SettingsSwitch
                    :value="document.sharingLinkEnabled"
                    :disabled="!documentPublicLink"
                    @input="value => toggleSharingLink(value)"
                  />
                </FeatureFlags>
              </div>
            </FeatureEnabled>
          </div>
          <ShareLink
            v-if="document.sharingLinkEnabled"
            :document="document"
            variant="guest"
            show-open-in-new-tab
          >
            Copy public link
          </ShareLink>
        </div>
      </div>
      <div class="flex flex-col hidden">
        <div class="py-16">
          <div class="flex justify-between">
            <!-- CURRENT STATUS -->
            <!-- SHARED -->
            <div
              v-if="isClientShared"
              class="mt-4 text-gray-700 text-13 grow"
            >
              <p
                class="inline-flex px-6 py-5 mb-4 font-semibold leading-none text-green-800 bg-green-100 rounded-md text-18"
              >
                Visibile on the client portal
              </p>
              <p class="pl-6 text-14">Client contacts <strong>can view this document</strong>.</p>
            </div>
            <!-- NOT SHARED -->
            <div
              v-else
              class="mt-4 text-gray-700 text-13 grow"
            >
              <p
                class="inline-flex px-6 py-5 mb-4 font-semibold leading-none text-red-900 bg-red-100 rounded-md text-18"
              >
                Not visibile on the client portal
              </p>
              <p class="pl-6 text-14">Your client contacts <strong>cannot view this document</strong>.</p>
            </div>

            <div class="flex-shrink-0">
              <Button
                v-if="!isClientShared"
                variant="primary"
                type="button"
                size="sm"
                :disabled="isLoading"
                data-intercom-target="Enable Sharing Button"
                class="px-16"
                @click.stop="toggleSharingClient()"
              >
                <i class="mr-6 icon-util-share-arrow"></i>
                <span>Share to client portal</span>
              </Button>
              <Button
                v-else
                size="sm"
                type="button"
                variant="neutral"
                :disabled="isLoading"
                class="px-16"
                data-intercom-target="Disable Sharing Button"
                @click.stop="toggleSharingClient()"
              >
                <i class="mr-6 icon_v2-so_lock"></i>
                <span>Unshare</span>
              </Button>
            </div>
          </div>
        </div>

        <!-- --------------------------------------------------- -->
        <!-- More Options -->
        <!-- --------------------------------------------------- -->
        <div
          class="flex flex-col items-stretch p-16 mt-8 border border-gray-300 rounded-md bg-gray"
        >
          <h4 class="mb-8 font-semibold text-gray-800 text-16 ">
            More sharing options:
          </h4>

          <!-- LINK INPUT -->
          <ShareLink
            :document="document"
            variant="guest"
            :show-link="false"
            @toggle-sharing-link="toggleSharingLink"
          />
        </div>
      </div>
    </template>
  </ModalBasic>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import {
  CATEGORY, CATEGORY_TO_LIST_TITLE as CATEGORY_TITLES,
  SHARABLE_TO_PORTAL_CATEGORIES as SHARABLE_CATEGORIES,
  PRINTABLE_CATEGORIES,
} from '@/v2/services/documents/documentsTypes'
import Button from '@/components/Button.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import FormMessage from '@/components/FormMessage.vue'
import ShareLink from '@/components/ShareLink.vue'
import ProjectFolderTree from '@/components/ProjectFolderTree.vue'
import useCustomFileSystem from '@/v2/lib/composition/useCustomFileSystem'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import FeatureEnabled from '@/components/FeatureEnabled.vue'
import FeatureFlags from '@/components/FeatureFlags.vue'

export default defineComponent({
  name: 'DocumentEditorShareModal',
  components: {
    Button,
    ModalBasic,
    FormMessage,
    ShareLink,
    ProjectFolderTree,
    SettingsSwitch,
    FeatureEnabled,
    FeatureFlags,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  setup(props) {
    const { track } = useTracking()
    const formMessage = ref(null)
    const isLoading = ref(false)
    const selectedFolderId = ref(null)
    const showProjectTree = ref(null)
    const { moveCurrentToFolder } = useCustomFileSystem()

    const isClientShared = computed(() => Boolean(props.document?.sharingClientEnabled))
    const isLinkShared = computed(() => Boolean(props.document?.sharingLinkEnabled))
    // ONLY PROJECT LEVEL DOCS CAN BE SHARED TO A PORTAL
    const isClientSharable = computed(() => SHARABLE_CATEGORIES.includes(props.document?.category))
    // ONLY SOME DOC TYPES CAN BE SAVED AS PDF
    const isPrintable = computed(() => PRINTABLE_CATEGORIES.includes(props.document?.category))
    const shownTreeCategory = computed(
      () => (isClientShared.value ? null : CATEGORY.ProjectPublicFolder)
    )

    const hiddenTreeCategory = computed(
      () => (isClientShared.value ? [CATEGORY.ProjectPublicFolder] : null)
    )

    const onFolderSelected = folderId => {
      selectedFolderId.value = folderId
    }

    const tryCatch = async asyncTask => {
      isLoading.value = true
      formMessage.value = null

      try {
        await asyncTask()
      } catch (err) {
        formMessage.value = err.message
        console.log(err)
      } finally {
        isLoading.value = false
      }
    }
    const toggleSharingClient = () => {
      showProjectTree.value = !showProjectTree.value
    }

    const changeSharingClient = () => {
      if (isClientShared.value) {
        track('Document Unshared')
      } else {
        track('Document Shared')
      }
      tryCatch(() => moveCurrentToFolder(selectedFolderId.value))
      toggleSharingClient()
      selectedFolderId.value = null
    }

    const toggleSharingLink = value => {
      if (value) {
        track('Document Public Link Enabled')
      } else {
        track('Document Public Link Disabled')
      }

      tryCatch(() => props.document.patch({ data: { sharingLinkEnabled: value } }))
    }

    return {
      // Flags
      isLoading,
      isClientShared,
      isLinkShared,
      showProjectTree,
      isPrintable,
      isClientSharable,
      // State,
      selectedFolderId,
      formMessage,
      shownTreeCategory,
      hiddenTreeCategory,

      // Methods
      changeSharingClient,
      toggleSharingLink,
      onFolderSelected,
      toggleSharingClient,
      CATEGORY_TITLES,
    }
  },
})
</script>
