
<span
  :class="[
    className,
    'text-gray-600 whitespace-nowrap',
    displayDate && displayDate.color,
  ]"
>
  <slot v-bind="{ displayDate }">
    {{ displayDate && displayDate.message }}
  </slot>
</span>
