<template>
  <div
    v-bind="$attrs"
    class="p-0 m-0 relative"
  >
    <template v-if="isLoading">
      Loading...
    </template>
    <template v-else>
      <div
        class="bg-gray-1000 h-56 flex items-center justify-between dark text-white px-16
        shadow-lg -mb-8 z-10 relative
      "
      >
        <span v-if="showAllPages">
          {{ pageCount }} page(s)
        </span>

        <div v-else class="flex items-center justify-start">
          <b-button
            variant="dark"
            :disabled="page <= 1"
            @click="page--"
          >
            ❮
          </b-button>

          <div class="px-16">{{ page }} / {{ pageCount }}</div>

          <b-button
            variant="dark"
            :disabled="page >= pageCount"
            @click="page++"
          >
            ❯
          </b-button>
        </div>

        <label class="right">
          <input v-model="showAllPages" type="checkbox" />

          Show all pages
        </label>
      </div>
    </template>
    <div class="app-content h-screen overflow-y-auto pb-256">
      <VuePdfEmbed
        ref="pdfRef"
        :source="source"
        :page="page"
        @rendered="handleDocumentRender"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default defineComponent({
  name: 'PDFViewer',
  components: {
    VuePdfEmbed,
  },
  props: {
    source: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props, context) {
    const isLoading = ref(true)
    const page = ref(null)
    const pageCount = ref(1)
    const showAllPages = ref(true)

    // const togglePagination = () => {
    //   page.value = showAllPages.value ? null : 1
    // }

    const handleDocumentRender = () => {
      isLoading.value = false
      pageCount.value = context.refs.pdfRef.pageCount
    }

    watch(() => showAllPages.value, newValue => {
      page.value = newValue ? null : 1
    })

    return {
      isLoading,
      page,
      pageCount,
      showAllPages,
      // methods
      // togglePagination,
      handleDocumentRender,
    }
  },
})
</script>

<style lang="postcss" scoped>
/* .vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
} */

</style>
