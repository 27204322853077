import { pipe, when, is } from 'ramda'
import Vue from 'vue'

const optionsDate = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const optionsTime = {
  hour: '2-digit',
  minute: '2-digit',
}

const makeDate = dateStr => new Date(dateStr)
const makeFormatter = (lang, options) => pipe(
  when(is(String), makeDate),
  date => date.toLocaleDateString(lang, options)
)

const formatters = {
  datetime: makeFormatter(navigator.language, {
    ...optionsDate,
    ...optionsTime,
  }),
  date: makeFormatter(navigator.language, optionsDate),
  time: makeFormatter(navigator.language, optionsTime),
}

Vue.filter('dateTime', (value, formatter = 'datetime') => (value
  ? formatters[formatter](value)
  : ''))

Vue.filter('date', (value, formatter = 'date') => (value
  ? formatters[formatter](value)
  : ''))
