<template>
  <div class="relative group-event mb-24">
    <!-- HEADER -->
    <div
      class="
        mb-8 font-medium
        text-gray-800 dark:text-darkGray-400
        portal-paragraph-font
      "
    >
      <slot name="label" />
      <p class="event-timeline-circle">
        <span :class="icon" />
      </p>
    </div>
    <ul class="flex flex-col portal-paragraph-font space-y-12 md:space-y-6 text-gray-700">
      <li v-for="event in notification.events" :key="event._id">
        <div class="cursor-pointer" @click="$emit('event-click', event)">
          <slot name="event" v-bind="{ event, document: event.document$, isClientPortal }" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'

export default defineComponent({
  name: 'ProjectTimelineRendererGroup',
  components: {
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isClientPortal = inject('isClientPortal', false)

    return {
      isClientPortal,
    }
  },
})
</script>

<style lang="postcss" scoped>
.event-timeline-circle {
  @apply absolute right-full transform -top-4 ;
  @apply block w-32 h-32 rounded-full overflow-hidden mr-12;
  @apply flex items-center justify-center leading-none;
  @apply bg-page dark:bg-darkGray-1000;
  @apply text-[color:var(--cp-accent)];
  @apply text-24 lg:text-24;
}
</style>
