import { pipe, identity } from 'ramda'
import { computed } from '@vue/composition-api'
import { getDate, getMonth, getYear, parseISO } from 'date-fns/fp'

/**
 * @typedef {Object} Options
 * @property {string} [dateKey]
 * @property {string} [outputItemsKey]
 * @property {Function} [mapGroupItems]
 * @property {'day'} [mode]
 */

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

const getDay = dateKey => item => {
  const date = parseISO(item[dateKey])
  return [
    getYear(date),
    String(getMonth(date) + 1).padStart(0, 2),
    String(getDate(date)).padStart(0, 2),
  ].join('-')
}

const groupByDay = ({ dateKey }) => {
  const getDayWithKey = getDay(dateKey)

  return items => items.reduce(
    (acc, item) => {
      const day = getDayWithKey(item)
      acc[day] ??= []
      acc[day].push(item)
      return acc
    },
    {}
  )
}

const dayToDate = day => {
  const [year, month, date] = day.split('-').map(Number)
  const now = new Date()
  now.setFullYear(year, month - 1, date)
  now.setHours(0, 0, 0, 0)
  return now.toISOString()
}

/**
 * @param {Options} options
 * @returns
 */
const groupsToList = ({
  outputItemsKey,
  mapGroupItems,
}) => groups => Object.entries(groups)
  .map(([day, items]) => ({
    day,
    time: dayToDate(day),
    [outputItemsKey]: mapGroupItems(items),
  }))

const sortByDay = dayGroups => [...dayGroups]
  .sort((a, b) => b.time - a.time)

/**
 * @param {Options} options
 */
const groupAndSort = options => pipe(
  groupByDay(options),
  groupsToList(options),
  sortByDay
)

/**
 * @param {Ref<any[]>} items
 * @param {Options} [options]
 */
function useGroupByDate(items = [], options = {}) {
  const {
    dateKey = 'date',
    outputItemsKey = 'items',
    mapGroupItems = identity,
  } = options

  const groupFn = groupAndSort({
    dateKey,
    outputItemsKey,
    mapGroupItems,
  })

  return computed(() => (items?.value?.length ? groupFn(items.value) : []))
}

export default useGroupByDate
