<template>
  <div
    class="
      h-full flex flex-col justify-center items-center
      flex-1 flex-grow bg-yellow-10 dark:bg-darkGray-900
    "
    :class="{ 'min-h-[197px]': !noMinHeight }"
  >
    <span class="icon-document-contract text-64 w-64 opacity-70"></span>
    <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">PDF Document</span>
  </div>
</template>

<script>
export default {
  name: 'PDFThumbnail',
  props: {
    file: {
      type: Object,
      required: true,
    },
    noMinHeight: Boolean,
  },
}
</script>
<style lang="postcss" scoped>

</style>
