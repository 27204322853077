import { computed } from '@vue/composition-api'
import tinycolor from 'tinycolor2'

const defaults = {
  portalAccentColor: '#23B839',
  portalSecondaryColor: '#848E99',
  portalLinkColor: '',
  brandingFontHeading: '',
  brandingFontParagraph: '',
  portalHeadingFont: 'Inter',
}

export default function useProjectTheme(options = {}) {
  const { project, documentOverride } = options

  // For Unsplash images, we can add query params to optimize size
  const optimizedImageUrl = url => {
    const isUnsplash = url && url.includes('images.unsplash.com')
    if (isUnsplash) {
      return `${url}&w=1600&h=1600&q=80`
    }
    return url
  }

  // Create a list of derived colors from portal accent color
  // Computed because when switching projects the props change
  // const accentColorSpace = computed(() => tinycolor(props.project.portalAccentColor)
  //   .monochromatic(3))

  // Try to find one that is readable so we can use it
  // as text color when the accent color is the background
  const mostReadableOption = color => {
    const accentSpace = tinycolor(color).monochromatic(3)
    return tinycolor.mostReadable(
      color,
      [
        ...accentSpace,
        '#FFFFFF',
      ],
      {
        level: 'AA',
        size: 'large',
      }
    ).toHexString()
  }

  const mostReadableAccent = computed(
    () => mostReadableOption(project?.value?.portalAccentColor ?? defaults.portalAccentColor)
  )
  const mostReadableSecondary = computed(
    () => mostReadableOption(project?.value?.portalSecondaryColor ?? defaults.portalSecondaryColor)
  )

  const theme = computed(() => {
    if (project?.value) {
      // Check for document fonts override
      let headingFont = project.value.brandingFontHeading ?? defaults.brandingFontHeading
      let paragraphFont = project.value.brandingFontParagraph ?? defaults.brandingFontParagraph

      if (documentOverride?.value?.brandingFontHeading) {
        headingFont = documentOverride.value.brandingFontHeading
      }

      if (documentOverride?.value?.brandingFontParagraph) {
        paragraphFont = documentOverride.brandingFontParagraph
      }

      // Build CSS Variables
      return {
        '--cp-header-bg': project.value.portalHeaderBackgroundColor ?? defaults.portalHeaderBackgroundColor,
        '--cp-accent': project.value.portalAccentColor ?? defaults.portalAccentColor,
        '--cp-secondary-color': project.value.portalSecondaryColor ?? defaults.portalSecondaryColor,
        '--cp-link-color': project.value.portalLinkColor || project?.value?.portalAccentColor,
        '--cp-accent-rgb': `
            ${tinycolor(project.value.portalAccentColor ?? defaults.portalAccentColor).toRgb().r},
            ${tinycolor(project.value.portalAccentColor ?? defaults.portalAccentColor).toRgb().g},
            ${tinycolor(project.value.portalAccentColor ?? defaults.portalAccentColor).toRgb().b}`,
        '--cp-accent-most-readable': mostReadableAccent.value,
        '--cp-secondary-most-readable': mostReadableSecondary.value,
        '--cp-header-image-url': `url(${optimizedImageUrl(project.value.portalHeaderImage)})`,
        '--cp-heading-font': headingFont,
        '--cp-paragraph-font': paragraphFont,
        '--cp-header-image-opacity': project.value.portalHeaderImageOpacity
          ? project.value.portalHeaderImageOpacity / 100
          : 1,
      }
    }

    return {}
  })

  return theme
}
