<template>
  <ModalBasic
    :no-close="false"
    :hide-footer="true"
    :show-back="isSelectedTemplate"
    :content-class="$style['modal-content-template']"
    :header-class="$style['modal-header-template']"
    :body-class="$style['modal-body-template']"
    close-text="Close"
    footer-class="modal-footer-tertiary"
    size="xl"
    :dialog-class="$style['modal-dialog-template']"
    v-bind="$attrs"
    v-on="$listeners"
    @back="onTemplatePreview(null)"
  >
    <template #header-buttons>
      <Button
        v-if="isSelectedTemplate"
        type="button"
        variant="primary"
        class="whitespace-nowrap"
        @click="useTemplate"
      >
        <span class="w-20 mr-4 icon_v2-so_tick text-20"></span>Use Template
      </Button>
    </template>
    <template #body>
      <DocumentFromTemplate
        :templates-category="templatesCategory"
        :template-preview="templatePreview"
        :show-blank="true"
        :excluded-categories="excludedCategories"
        @select="useTemplate"
        @preview="onTemplatePreview"
      />
    </template>
  </ModalBasic>
</template>

<script>
import { ref, defineComponent, computed } from '@vue/composition-api'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import DocumentFromTemplate from '@/components/DocumentFromTemplate.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'DocumentFromTemplateModal',
  components: {
    ModalBasic,
    DocumentFromTemplate,
    Button,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    excludedCategories: {
      type: Array,
      default: () => [],
    },
    templatesCategory: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const templatePreview = ref(null)
    const isSelectedTemplate = computed(() => Boolean(templatePreview.value))

    const onTemplatePreview = template => {
      templatePreview.value = template
    }

    const useTemplate = () => {
      context.emit('select', {
        document: templatePreview.value?.documentId ?? null,
        template: templatePreview.value,
      })
    }

    return {
      templatePreview,
      isSelectedTemplate,
      onTemplatePreview,
      useTemplate,
    }
  },
})
</script>

<style lang="scss" module>
.modal-dialog-template {
  max-width: 960px;
}
.modal-content-template {
  background-color: theme('colors.gray.DEFAULT');
  padding: 0;
}
.modal-header-template {
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
    @apply bg-gray border border-b border-gray-300;
  margin-bottom: 0;
  :global(.modal-back) {
    position: relative;
    padding: 4px 8px;
    margin-right: 4px;
  }
}
.modal-body-template {
  max-width: none;
  min-height: 320px;
  padding: 0;
  :global(.document-page) {
    overflow-x: hidden;
  }
}
</style>
