<template>
  <router-link
    v-if="actionItem"
    v-slot="scope"
    :to="to"
    :custom="custom"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-bind="{ ...scope, to }" />
  </router-link>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useActionItemLink } from '@/v2/services/actionItems/compositions'

export default defineComponent({
  name: 'ActionItemLink',
  props: {
    actionItem: {
      type: Object,
      default: null,
    },
    custom: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const to = useActionItemLink(computed(() => props.actionItem))
    return {
      to,
    }
  },
})
</script>
