export const PROJECT = 'PROJECT'

export const PROJECT_CONTENT = 'PROJECT_CONTENT'
export const PROJECT_PROGRESS = 'PROJECT_PROGRESS'

export const PROJECT_DASHBOARD = 'PROJECT_DASHBOARD'
export const PROJECT_KNOWLEDGE_BASE = 'PROJECT_KNOWLEDGE_BASE'
export const PROJECT_LINKED_APP = 'PROJECT_LINKED_APP'
export const PROJECT_NOTIFICATIONS = 'PROJECT_NOTIFICATIONS'
export const PROJECT_UPDATES = 'PROJECT_UPDATES'
export const PROJECT_VIEW_AS_CLIENT = 'PROJECT_VIEW_AS_CLIENT'

// Tasks
export const PROJECT_TASKS = 'PROJECT_TASKS'
export const PROJECT_TASKS_LIST = 'PROJECT_TASKS_LIST'
export const PROJECT_TASKS_LIST_TASK = 'PROJECT_TASKS_LIST_TASK'
export const PROJECT_TASKS_KANBAN = 'PROJECT_TASKS_KANBAN'
export const PROJECT_TASKS_KANBAN_TASK = 'PROJECT_TASKS_KANBAN_TASK'
export const PROJECT_TASKS_DOCUMENTS = 'PROJECT_TASKS_DOCUMENTS'
export const PROJECT_TASKS_DOCUMENTS_TASK = 'PROJECT_TASKS_DOCUMENTS_TASK'


// FS
export const PROJECT_FS_FOLDER = 'PROJECT_FS_FOLDER'
export const PROJECT_FS_DOCUMENT = 'PROJECT_FS_DOCUMENT'

export const PROJECT_PAGE = 'PROJECT_PAGE'
export const PROJECT_SERVICE = 'PROJECT_SERVICE'

// Pinned Folders
export const PROJECT_PINNED_FS_FOLDER = 'PROJECT_PINNED_FS_FOLDER'
export const PROJECT_PINNED_FS_DOCUMENT = 'PROJECT_PINNED_FS_DOCUMENT'

// -- settings
export const PROJECT_SETTINGS = 'PROJECT_SETTINGS'
export const PROJECT_SETTINGS_DETAILS = 'PROJECT_SETTINGS_DETAILS'
export const PROJECT_SETTINGS_BRANDING = 'PROJECT_SETTINGS_BRANDING'
export const PROJECT_SETTINGS_QUICK_LINKS = 'PROJECT_SETTINGS_QUICK_LINKS'
export const PROJECT_SETTINGS_PACKAGED_SERVICES = 'PROJECT_SETTINGS_PACKAGED_SERVICES'
export const PROJECT_SETTINGS_PAGES = 'PROJECT_SETTINGS_PAGES'
export const PROJECT_SETTINGS_APPS = 'PROJECT_SETTINGS_APPS'
export const PROJECT_SETTINGS_MODULES = 'PROJECT_SETTINGS_MODULES'
export const PROJECT_SETTINGS_TOOLS = 'PROJECT_SETTINGS_TOOLS'
export const PROJECT_SETTINGS_INTEGRATIONS = 'PROJECT_SETTINGS_INTEGRATIONS'
export const PROJECT_SETTINGS_PINNED_FOLDERS = 'PROJECT_SETTINGS_PINNED_FOLDERS'
export const PROJECT_SETTINGS_MANAGE_ACCESS = 'PROJECT_SETTINGS_MANAGE_ACCESS'
