const debounce = (fn, delay = 500) => {
  let timer = null
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(fn, delay, ...args)
  }
}
export default debounce

export const debounceWithArgs = (fn, delay = 1000, hashFn = JSON.stringify) => {
  const timers = new Map();

  return (...args) => {
    const key = hashFn(args);

    if (timers.has(key)) {
      clearTimeout(timers.get(key));
    }

    const timer = setTimeout(() => {
      fn.apply(this, args);
      timers.delete(key);
    }, delay);

    timers.set(key, timer);
  };
};

export function debounceAsync(fn, delay = 500) {
  const debounced = debounce(async (resolve, reject, args) => {
    try {
      const result = await fn(...args);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  }, delay);

  return (...args) => new Promise((resolve, reject) => {
    debounced(resolve, reject, args);
  });
}

