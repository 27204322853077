
<b-dropdown
  v-click-outside.capture="() => $emit('visibility-change', false)"
  variant="reset"
  lazy
  no-caret
  right
  offset="210"
  :menu-class="`w-420 text-14 print:hidden !mb-24 !mt-24 ${$style.menu}`"
  toggle-class="flex-1 p-0 print:hidden"
  @show="onDropdownShow"
  @shown="$emit('visibility-change', true)"
  @hidden="$emit('visibility-change', false)"
>
  <!-- Trigger Button -->
  <template #button-content>
    <span
      v-if="!contentLibrary"
      :class="[
        mode==='root' && 'add-btn-icon',
        mode==='child' && 'add-btn-full',
      ]"
    >
      <span class="w-24 m-0 leading-none icon_v2-so_plus text-24"></span>
      <span v-if="buttonLabel">{{ buttonLabel }}</span>
    </span>

    <!-- Used for Case Studies & Team Bios when adding new cards inside them -->
    <span v-else class="add-btn-full">
      <span class="w-24 leading-none icon_v2-so_plus text-24"></span>
      {{ label }}
    </span>
  </template>
  <div class="flex">
    <div class="flex-1">
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementHeading)"
        icon="heading"
        label="Section Heading"
        @select="createBlock(ELEMENT_CATEGORY.ElementHeading)"
      >
        Structure your document with Section Headings. These will also appear in
        the Table of Contents on the shared link
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementText)"
        icon="text"
        label="Rich Text"
        @select="createBlock(ELEMENT_CATEGORY.ElementText)"
      >
        A powerful rich text editor
      </DocumentBlockButtonCreateMenuItem>
      <!-- Table Content-Block -->
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementTable)"
        icon="table"
        label="Table"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-table.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementTable)"
      >
        Add rich tables.
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementImage)"
        icon="image"
        label="Image"
        @select="createBlock(ELEMENT_CATEGORY.ElementImage)"
      >
        Include images straight in the document
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementButton)"
        icon="button"
        label="Button"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-button.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementButton)"
      >
        Create custom buttons
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementAttachments)"
        icon="link"
        label="Uploaded Files"
        @select="createBlock(ELEMENT_CATEGORY.ElementAttachments)"
      >
        Attach multiple images and other types of files to any document
      </DocumentBlockButtonCreateMenuItem>

      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementPageBreak)"
        icon="pagebreak"
        label="Page Break"
        @select="createBlock(ELEMENT_CATEGORY.ElementPageBreak)"
      >
        This content block will force a page break in the PDF or print version of the document.
      </DocumentBlockButtonCreateMenuItem>

      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementSpacer)"
        icon="spacer"
        label="Spacer"
        @select="createBlock(ELEMENT_CATEGORY.ElementSpacer)"
      >
        Add space between blocks and customize its height and background style.
      </DocumentBlockButtonCreateMenuItem>
    </div>
    <div class="flex-1">
      <DocumentBlockButtonCreateSubMenu
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementQA)"
        icon="chat-notification"
        label="Q&amp;A"
        @select="createBlock(ELEMENT_CATEGORY.ElementQA)"
      >
        <template v-for="input in inputTypes">
          <DocumentBlockButtonCreateMenuItem
            :key="input.id"
            :icon="input.icon"
            :label="input.label"
            @select="createBlockQA(ELEMENT_CATEGORY.ElementQA, input.id)"
          />
        </template>
      </DocumentBlockButtonCreateSubMenu>
      <!-- Embed Content-Block -->
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementEmbed)"
        icon="embed"
        label="Embed"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-embed.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementEmbed)"
      >
        Add media embeds. Over 1900 publishers.
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="!noContentLibrary"
        icon="library"
        label="Content Library"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-content-library.jpg"
        @select="openContentLibrary"
      >
        Add reusable content blocks, include Team Bios or Case Studies in any
        document
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="!accepts"
        icon="grouping"
        label="Group"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-groups.jpg"
        @select="createNodeGroup(NODE_CATEGORY.NodeGroupBasic)"
      >
        Group multiple content blocks so they can share the same background.
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="!accepts"
        icon="columns"
        label="Columns"
        @select="createNodeGroup(NODE_CATEGORY.NodeGroupColumnsContainer )"
      >
        Create sections where you group content on 2 or 3 columns.
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="accepts && accepts.includes(NODE_CATEGORY.NodeGroupColumn)"
        icon="object-group"
        label="Column"
        new-badge
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-groups.jpg"
        @select="createNodeGroup(NODE_CATEGORY.NodeGroupColumn)"
      >
        Create multiple columns.
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementCost)"
        icon="pricing-table"
        label="Pricing Table"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-cost-table.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementCost)"
      >
        Add your billable services and break down the cost for a proposal,
        estimate or anything else
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementCostBreakdown)"
        icon="pie-chart"
        label="Price Breakdown"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-cost-summary.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementCostBreakdown)"
      >
        A visual overview of how the total budget is calculated
      </DocumentBlockButtonCreateMenuItem>
      <DocumentBlockButtonCreateMenuItem
        v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementApproval)"
        icon="button-approval"
        label="Approval Button"
        image="https://superokay-static.s3.amazonaws.com/block-previews/preview-approval.jpg"
        @select="createBlock(ELEMENT_CATEGORY.ElementApproval)"
      >
        Allow clients to approve documents when viewing them via a Shared Link
      </DocumentBlockButtonCreateMenuItem>
    </div>
  </div>
</b-dropdown>
