<template>
  <DocumentContentLoader
    v-slot="{ isLoading }"
    :document="document"
  >
    <div
      v-if="!isLoading"
      :class="[
        'flex flex-col lg:flex-row relative print:block -mx-16 lg:mx-0 flex-1 bg-page rounded-lg',
        !isClientPortal && 'print:pt-0'
      ]"
    >
      <DocumentViewerClientPortalDesktop
        :document="document"
        :organization="organization"
        :project="project"
        :print="print"
        :is-client-portal="isClientPortal"
      />
    </div>
  </DocumentContentLoader>
</template>

<script>
import { computed, provide, toRef, defineComponent } from '@vue/composition-api'
import DocumentContentLoader from '@/components/DocumentContentLoader.vue'
import DocumentViewerClientPortalDesktop from './DocumentViewerClientPortalDesktop.vue'


export default defineComponent({
  name: 'DocumentViewerClientPortal',
  components: {
    DocumentContentLoader,
    DocumentViewerClientPortalDesktop,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: () => {},
    },
    print: {
      type: Boolean,
      default: false,
    },
    isClientPortal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    provide('isEditor', computed(() => false))
    provide('isViewer', computed(() => true))
    provide('isViewerPDF', computed(() => props.print))
    provide('isLocked', computed(() => props.document.isApproved))
    provide('isSandbox', computed(() => false))
    provide('document', toRef(props, 'document'))
  },
})
</script>
