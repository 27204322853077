
<NotificationDocument
  v-if="!isSingleton"
  v-slot="{ document }"
  :notification="notification"
>
  <DocumentLink v-slot="{ navigate }" :document="document">
    <NotificationRenderer
      :notification="notification"
      icon-variant="icon"
      :icon="document.icon || 'icon_v2-so_folder'"
      icon-class="bg-blue-200 text-blue-500"
      badge-class="bg-blue-500"
      :badge-count="notification.events.length"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      <template #default="{ options }">
        <div>
          {{ $tc(`doc-category.${document.category}`, 1) }}
          <NotificationLinkDocument :document="document" />
          has {{ notification.events.length }}
          new {{ $tc('blocks.qa.answer', notification.events.length) }}
        </div>
      </template>
      <template #events>
        <NotificationList :notifications="notification.events" />
      </template>
    </NotificationRenderer>
  </DocumentLink>
</NotificationDocument>
<NotifContentBlocksQaAnswered
  v-else
  :notification="singleton"
  :project="notification.project$"
/>
