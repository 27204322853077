
<DocumentContentLoader v-slot="{ isLoading }" :document="document">
  <ScrollContainer
    v-if="!isLoading"
    class="lg:overflow-y-auto lg:flex lg:overscroll-contain lg:h-[calc(100vh-143px)] lg:rounded-br-lg lg:rounded-bl-lg"
  >
    <div class="flex-1">
      <div class="p-0 m-0">
        <div
          class="mx-auto bg-white"
        >
          <DocumentViewerContent :document="document" />
        </div>
      </div>
    </div>
  </ScrollContainer>
</DocumentContentLoader>
