
<Form
  v-if="state"
  v-slot="{ isBusy, submit, hasChanges }"
  :state="state"
  :on-submit="() => save()"
>
  <div
    v-for="item in model"
    :key="item.category"
    class="border rounded-md mt-16 px-16 py-8 hover:bg-gray-100"
  >
    <div class="capitalize font-semibold mb-4">
      <template v-if="item.translationKey">
        {{ $tc(item.translationKey, 2, { count: '' }) }}
      </template>
      <template v-else>
        {{ item.category }}
      </template>
    </div>
    <div v-for="notification in item.notifications" :key="notification">
      <SettingsSwitch
        :value="state.settings.emailNotifications[item.category].includes(notification)"
        :label="$t(`notifications.${item.category}.${notification}`)"
        class="!p-0"
        @input="val => update(item.category, notification, val)"
      />
    </div>
  </div>
  <slot name="button" v-bind="{ isBusy, submit, hasChanges }" />
</Form>
