<template>
  <FileUploader
    v-slot="{ selectFiles, isDragging }"
    data-intercom-target="Attachments Block"
    multiple
    block
    inline-block
    @upload-start="onUploadStart"
    @upload-done="onUploadDone"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <!-- If we have files -->
    <div
      :class="[
        ['XXS','XS','S','M'].includes(nodeWidth) && 'sm:px-24',
        'portal-paragraph-font',
      ]"
      @contextmenu.stop
    >
      <LightboxGallery
        :files="filesMedia"
        :index="index"
        @close="index = null"
      />

      <!-- Audio -->
      <section class="attach-images-wrap">
        <div
          v-for="(file, fileIndex) in filesMedia"
          :key="file.uuid"
          :class="['attach-item-wrap', file.local && 'uploading-image']"
        >
          <DocumentContentBlockAttachmentsMedia
            v-if="!file.local"
            :file="file"
            :read-only="!isEditable"
            @remove="remove(file)"
            @click-thumbnail="index = fileIndex"
          />
        </div>
      </section>
      <section class="mb-16 attach-files-wrap">
        <div
          v-for="fileNonImage in filesNonMedia"
          :key="fileNonImage.uuid"
          :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
        >
          <DocumentContentBlockAttachmentsFile
            v-if="!fileNonImage.local"
            :file="fileNonImage"
            :read-only="!isEditable"
            @remove="remove(fileNonImage)"
          />
        </div>
      </section>
    </div>

    <div
      v-if="isEditable"
      :class="[ 'attach-empty','portal-paragraph-font']"
    >
      <!-- ------------- -->
      <!-- Error message -->
      <!-- ------------- -->
      <div v-if="filesErrors.length" class="attach-note">
        <h4 class="portal-paragraph-font">
          <span class="w-16 mr-4 text-16 icon-alert"></span>
          <span> The files below failed to upload. Please try again. </span>
        </h4>
        <div v-for="fileError in filesErrors" :key="fileError.uuid">
          <strong>{{ fileError.name }}</strong>
          {{ fileError.local.error | error }}
        </div>
      </div>
      <!-- ------------ -->
      <!-- IS UPLOADING -->
      <!-- ------------ -->
      <section v-if="isUploading || uploadedBatch.files.length > 0">
        <portal to="right-drawer">
          <PendingUploads :pending-uploads="uploadedBatch.files" @close="onPendingUploadsDone" />
        </portal>
      </section>

      <!-- ------------- -->
      <!-- UPLOAD BUTTON -->
      <!-- ------------- -->
      <div class="flex flex-row justify-center print:hidden">
        <b-button
          variant="primary"
          :disabled="isUploading"
          @click="selectFiles"
        >
          <span class="w-20 mr-6 icon-util-upload text-20" />
          Select files
        </b-button>
      </div>
    </div>
    <!-- Drop overlay -->
    <div
      v-if="isDragging"
      :class="[
        'attach-empty attach-hovered',
        'absolute inset-0 pointer-events-none z-1',
        'flex items-center justify-center flex-col',
        'portal-paragraph-font'
      ]"
    >
      <span class="font-semibold text-green-900 opacity-75 text-42">Drop your files here</span>
    </div>
  </FileUploader>
</template>

<script>
import { computed, defineComponent, inject, ref, reactive } from '@vue/composition-api'
import { useFilesUpload } from '@/v2/services/uploads/uploadsCompositions'
// File Uploader
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import LightboxGallery from '@/components/LightboxGallery.vue'
import DocumentContentBlockAttachmentsFile from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsFile.vue'
import DocumentContentBlockAttachmentsMedia from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsMedia.vue'
import PendingUploads from '@/components/PendingUploads.vue'

export default defineComponent({
  name: 'DocumentContentBlockAttachments',
  components: {
    // FILE UPLOADER
    FileUploader,
    DocumentContentBlockAttachmentsFile,
    DocumentContentBlockAttachmentsMedia,
    LightboxGallery,
    PendingUploads,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const index = ref(null)

    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const save = files => context.emit('update', { files })
    const add = files => save([...props.contentBlock.files, ...files])
    const remove = file => save(props.contentBlock.files.filter(({ uuid }) => file.uuid !== uuid))

    const {
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
    } = useFilesUpload(
      computed(() => props.contentBlock.files),
      add
    )

    const pendingUploads = computed(() => files.value.filter(file => file.local))
    const uploadedBatch = reactive({
      files: [],
    })

    const onPendingUploadsDone = () => {
      uploadedBatch.files = []
    }

    const _onUploadStart = (...args) => {
      context.emit('async-save-start')
      onUploadStart(...args)
      uploadedBatch.files = [...pendingUploads.value]
    }

    return {
      // data
      isEditable,
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      index,
      pendingUploads,
      uploadedBatch,
      // methods
      onUploadStart: _onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
      remove,
      onPendingUploadsDone,
    }
  },
})
</script>

<style lang="scss" scoped>
.attach-empty {
  @apply border-dashed border-2 border-gray-400 bg-gray-100 bg-opacity-20;
  @apply p-24;
  @apply rounded-lg;
}

.attach-hovered {
  @apply border-dashed border-2 border-green-400 bg-green-200 bg-opacity-95;
  @apply p-24;
  @apply text-center rounded-lg;
}

.attach-images-wrap {
  @apply grid grid-cols-3 md:grid-cols-3 xl:grid-cols-4 gap-16 md:gap-24 auto-cols-fr;
  @media print {
    break-inside: avoid;
  }
}
.attach-item-wrap {
  @apply flex flex-col items-stretch w-full;
  @media print {
    width: 33.333%;
    break-inside: avoid;
  }
}
.attach-files-wrap {
  @apply flex flex-col mx-8 lg:mx-0 w-full;

  :global(.attach-item-wrap) {
    @apply mb-16;
  }
}
.attach-note {
  margin-bottom: $gutterx2;
  position: relative;
  padding-left: 32px;
  h4 {
    color: $error;
    font-weight: $medium;
    font-size: $fs18;
    display: inline-flex;
    align-items: center;
    margin-bottom: $gutter;
    :global(.icon) {
      position: absolute;
      left: 0;
      top: -1px;
    }
  }
}

.attach-active {
  border: 1px solid red;
}
</style>
