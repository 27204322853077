<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
    custom
  >
    <div
      :href="href"
      :class="[
        'relative col-span-2 row-span-2 overflow-hidden',
        'cursor-pointer group',
        'card-interactive p-0 transition-all hover:text-gray-900',
        'min-h-256 sm:min-h-320 md:h-[340px] xl:h-[400px] group',
        'rounded-lg'
      ]"
      :data-intercom-target="label"
      @click="navigate"
    >
      <div class="card-fade-top"></div>
      <!-- HEADER -->
      <div
        v-if="icon && label"
        class="relative flex items-center justify-center p-16 z-1 md:p-24"
      >
        <div
          class="flex items-center px-8 pt-2 pb-4 space-x-4 leading-none bg-white rounded-full dark:bg-darkGray-900/70 filter backdrop-blur-lg"
        >
          <span
            :class="[icon, 'portal-accent-foreground ']"
            class="text-24 md:text-24"
          ></span>
          <h4
            class="inline font-semibold leading-none text-gray-800 text-16 md:text-18 portal-heading-font dark:text-darkGray-300"
          >
            {{ label }}
            <span
              v-if="tooltip"
              v-b-tooltip.hover.top.v-info.dh0.ds200="tooltip"
              class="relative z-10 text-gray-400 bg-transparent icon_v2-so_info-solid text-16"
            ></span>
          </h4>
        </div>
      </div>
      <b-skeleton
        v-else
        :width="`${Math.round(30+(Math.random() * 40))}%`"
        height="18px"
        class="mx-auto mt-12 mb-20"
      />
      <!-- Content -->
      <div
        class="absolute inset-0 z-0 flex flex-col items-center flex-1 h-full px-16 overflow-x-hidden overflow-y-auto grow pt-72 md:px-24 scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin dark:scrollbar-thumb-darkGray-600 dark:scrollbar-track-darkGray-700 scrollbar-rounded-md"
      >
        <slot />
      </div>

      <!-- View all button -->
      <div
        class="absolute left-0 right-0 flex items-center justify-center w-full transition-all transform z-2 bottom-8 group-hover:opacity-100 group-hover:translate-y-0 group-hover:scale-100 md:scale-50 md:opacity-0 md:translate-y-64 "
      >
        <button
          variant="slim"
          size="s"
          class="flex items-center justify-center h-32 pl-16 pr-12 space-x-8 font-medium leading-none text-gray-900 rounded-full bg-white/70 dark:bg-darkGray-900/30 dark:text-darkGray-100 filter backdrop-blur-md text-12 ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 hover:ring-opacity-40 dark:hover:ring-opacity-40 "
          @click="navigate"
        >
          <span>{{ buttonLabel ?? $t('common.view-all') }}</span>
          <span class="icon-arrow-right-head text-12 opacity-30"></span>
        </button>
      </div>

      <!-- View Options -->
      <slot name="options" />
    </div>
    <!-- Content -->
  </router-link>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DashboardAppCard',
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>

<style lang="postcss" scoped>
.so-mask-wrapper {
  mask-image: linear-gradient(180deg, black 0%, black 90%, transparent 100%);
}
/* .so-mask-top {
  mask-image: linear-gradient(180deg, transparent 0%, black 30%, black 90% black 100%);
} */
.card-fade-top {
  /* @apply bg-page/90; */
  @apply absolute inset-0 z-1 pointer-events-none overflow-hidden rounded-md;
}

.card-fade-top::before {
    content: '';
    @apply h-64 absolute inset-x-0 z-1 top-0;
    @apply bg-gradient-to-b from-white to-transparent;
    @apply dark:from-darkGray-900;
}
.card-fade-top::after {
    content: '';
    @apply h-64 absolute inset-x-0 z-1 bottom-0;
    @apply bg-gradient-to-t from-white to-transparent;
    @apply dark:from-darkGray-900;
}
</style>
