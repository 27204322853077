
<div v-if="!isEmpty" class="so-node-list">
  <DocumentNode
    v-for="(nodeId, index) in children"
    :key="nodeId"
    :node-id="nodeId"
    parent="root"
    :index="index"
    :is-first="index === 0"
    :is-last="index === children.length - 1"
  />
</div>
<DocumentNodeGroupRootPlaceholder v-else :is-read-only="isReadOnly" />
