
<AvatarIcon
  v-if="!authorProfile"
  variant="gray"
  icon="icon-util-person-full"
  :icon-size="iconSize"
  :size="size"
/>
<Avatar
  v-else
  :profile="authorProfile"
  :show-name="false"
  :font-size="String(fontSize)"
  :size="size"
/>
