
<div class="flex">
  <div v-if="hasCollections">
    <CategoryList
      title="Collections"
      :items="collections"
      :active-id="collection?.id"
      @select="onCollectionSelect"
    />
  </div>
  <div class="flex-1">
    <div
      :class="!noScroll && `
        lg:overflow-y-auto lg:flex lg:overscroll-contain lg:h-[calc(100vh-128px)]
        w-full
      `"
    >
      <ProjectTemplateList
        :templates="collection?.templates ?? []"
        @preview="template => $emit('preview', template)"
        @create="template => $emit('create', template)"
      />
    </div>
  </div>
</div>
