<template>
  <div>NotifDocumentsNotify</div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NotifDocumentsNotify',
  components: {

  },
  props: {

  },
  setup(props, context) {
    return {

    }
  },
})
</script>
