
<div
  class="
  text-gray-400 text-13
  bg-page p-16 -ml-16 -mr-16
    print:bg-transparent
    print:p-0
    print:ml-0
    print:mr-0
  "
  :class="$style['printMarginTop']"
  :style="cssVars"
>
  <div class="print:hidden">
    This content block will force a page break in the PDF or print version
    of the document with a top margin of <span class="text-green-600 font-semibold">{{ styling.printMarginTop }}px</span> or <span class="text-green-600 font-semibold">{{ pxTocm }}</span>
  </div>
</div>
