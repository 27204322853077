
<DocumentBlockButtonCreateMenu
  :parent-node-id="parentNodeId"
  :index="index"
  :accepts="accepts"
  :mode="mode"
  :content-library="contentLibrary"
  :button-label="buttonLabel"
  :no-content-library="noContentLibrary"
/>
