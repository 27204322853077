<template>
  <div
    v-b-tooltip.hover.top.v-info.dh0.ds200="tooltip"
    :class="[
      'inline-flex items-center rounded',
      'font-normal whitespace-nowrap leading-none',
      'h-24 px-6 text-12',
      countMap[variant].theme,
    ]"
    @click.stop="$emit('count-clicked')"
  >
    <span class="inline-block mr-4 text-12 opacity-75" :class="countMap[variant].icon" />
    <span>{{ count }}</span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

const countMap = {
  comments: {
    icon: 'icon_v2-so_chat',
    theme: 'count-wrapper-default',
  },
  actionItems: {
    icon: 'icon_v2-so_action-items',
    theme: 'count-wrapper-default',
  },
  views: {
    icon: 'icon-util-view',
    theme: 'count-wrapper-views',
  },
  files: {
    icon: 'icon-attach-files',
    theme: 'count-wrapper-default',
  },
}

export default defineComponent({
  name: 'DocumentCommentCount',
  props: {
    variant: {
      type: String,
      default: '',
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      countMap,
    }
  },
})
</script>

<style lang="postcss" scoped>
.count-wrapper-default {
  @apply text-gray-700 dark:text-darkGray-400;
  @apply dark:bg-darkGray-900;
  @apply bg-gray-100 bg-opacity-70;
}
.count-wrapper-views {
  @apply bg-green-300 text-green-700 dark:text-green-300;
  @apply bg-opacity-10;
  @apply border-green-200 dark:border-green-300 dark:border-opacity-50;
}
</style>
