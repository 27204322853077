<template>
  <ul
    v-if="hasChildren"
    :key="node.id"
  >
    <SidebarTreeItem
      v-for="(child, index) in node.children"
      v-slot="{ isNode, isEmpty, isLeaf, isExpanded, isActive, toggleCollapse, onItemClick }"
      :key="child.id"
      :node="child"
      :level="level"
      :index="index"
      :expand-on-click="expandOnClick"
      :expand-root="expandRoot"
      @open-parent="open"
    >
      <li
        v-if="isLeaf || !isEmpty || !hideEmptyNodes"
      >
        <div
          class="group mb-4"
          :class="[
            (((isLeaf || !isEmpty || !disableEmptyNodes) && isActive) || child.id === selected)
              ? cssClasses.activeItemClass
              : cssClasses.itemClass,
            { 'opacity-50 pointer-events-none': isNode && isEmpty && disableEmptyNodes },
          ]"
          :style="level > 0 ? `padding-left: ${level}rem` : null"
        >
          <!-- ITEM CONTENT -->
          <div
            class="grow min-w-0 flex items-center"
            @click="onItemClick"
          >
            <!-- :class="{ '': isLeaf && parentContainsNodes }" -->
            <slot
              :name="child.slot"
              v-bind="{
                isNode,
                isEmpty,
                isLeaf,
                isActive,
                level,
                index,
                isExpanded: isActive || (isNode && !isEmpty && isExpanded),
                node: child,
              }"
            />
          </div>
          <!-- COLLAPSE / EXPAND BUTTON -->
          <ButtonTransparent
            v-if="isNode"
            class="text-gray-400 w-12 h-20 flex items-center pl-0 pr-6"
            :class="{
              'hover:text-gray-900': !isEmpty,
              'invisible': isEmpty,
            }"
            @click="toggleCollapse"
          >
            <span
              class="
                  inline-block transition duration-200 text-24
                  ease-in-out transform icon_v2-so_direction-down
                "
              :class="{ '-rotate-180': isExpanded }"
            />
          </ButtonTransparent>
          <!-- OR EMPTY SPACE -->
          <div v-else class="w-16 shrink-0"></div>
        </div>
        <!-- <b-collapse v-if="!isEmpty" :visible="isExpanded"> -->
        <!-- SHOW CHILDREN IF THEY EXIST -->
        <SidebarTree
          v-if="!isEmpty"
          v-show="isExpanded"
          :is-root="false"
          :node="child"
          :level="level + 1"
          :item-class="cssClasses.itemClass"
          :active-item-class="cssClasses.activeItemClass"
          :selected="selected"
          :hide-empty-nodes="hideEmptyNodes"
          :disable-empty-nodes="disableEmptyNodes"
          :expand-on-click="expandOnClick"
          @open="open"
        >
          <template
            v-for="slot in Object.keys($scopedSlots)"
            #[slot]="scope"
          >
            <slot :name="slot" v-bind="scope" />
          </template>
        </SidebarTree>
        <!-- </b-collapse> -->
      </li>
    </SidebarTreeItem>
  </ul>
</template>
<script>
import { defineComponent, inject, set, computed, provide, ref } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import SidebarTreeItem from './SidebarTreeItem.vue'

const isNotEmptyNode = node => Boolean(node?.children?.length)

export default defineComponent({
  name: 'SidebarTree',
  components: {
    ButtonTransparent,
    SidebarTreeItem,
  },
  props: {
    isRoot: {
      type: Boolean,
      default: true,
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    level: {
      type: Number,
      default: 0,
    },
    selected: {
      type: String,
      default: null,
    },
    itemClass: {
      type: [String, Object, Array],
      default: null,
    },
    activeItemClass: {
      type: [String, Object, Array],
      default: null,
    },
    hideEmptyNodes: {
      type: Boolean,
      default: false,
    },
    disableEmptyNodes: {
      type: Boolean,
      default: false,
    },
    expandOnClick: {
      type: Boolean,
      default: true,
    },
    expandRoot: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const hasChildren = computed(() => isNotEmptyNode(props.node))

    const expanded = props.isRoot ? ref({}) : inject('expanded')
    const activeNodes = props.isRoot ? ref({}) : inject('activeNodes')

    const providedItemClass = inject('itemClass', null)
    const providedActiveItemClass = inject('activeItemClass', null)

    const cssClasses = computed(() => ({
      itemClass: props.itemClass ?? providedItemClass?.value,
      activeItemClass: props.activeItemClass ?? providedActiveItemClass?.value,
    }))

    const setActive = (id, value) => {
      set(activeNodes.value, id, value)
    }

    const open = () => {
      set(expanded.value, props.node.id, true)
      context.emit('open')
    }

    const parentContainsNodes = computed(() => props.node.children?.some(
      child => Array.isArray(child.children)
    ));

    if (props.isRoot) {
      provide('activeNodes', activeNodes)
      provide('setActive', setActive)
      provide('expanded', expanded)
    }


    return {
      hasChildren,
      open,
      parentContainsNodes,
      activeNodes,
      cssClasses,
    }
  },
})
</script>
