<template>
  <FileUploader
    :multiple="false"
    accept="image/*"
    @upload-start="onUploadStart"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <template #default="{ selectFiles }">
      <div class="w-full flex flex-col items-center justify-center">
        <ImageThumbnail
          v-if="hasUrl"
          :url="fileUrl"
          :width="width"
          :height="height"
          :class="variantClasses['imageClasses']"
        >
          <!-- Progress Bar -->
          <div
            v-if="fileUploadState.isUploading"
            class="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
          >
            <PieProgressBar :ratio="fileUploadState.progress" />
          </div>
        </ImageThumbnail>

        <!-- Placeholder -->
        <div v-else :class="variantClasses['placeholderClasses']">
          <span class="icon_v2-so_image icon-48 text-48"></span>
        </div>

        <!-- Buttons -->
        <div :class="variantClasses['buttonClasses']">
          <!-- Remove -->
          <Button
            v-if="!fileUploadState.isUploading && hasUrl"
            variant="danger"
            size="sm"
            @click.stop="removeFile"
          >
            <span class="icon_v2-so_trash"></span>
          </Button>
          <!-- Add -->
          <Button
            variant="primary"
            size="sm"
            @click.stop="selectFiles()"
          >
            <span class="icon_v2-so_image"></span>
            <span v-if="!hasUrl" class="ml-8">{{ $t('settings.upload') }}</span>
          </Button>
        </div>
      </div>
    </template>
  </FileUploader>
</template>

<script>
import { computed, watch } from '@vue/composition-api'
import { useFileUpload } from '@/v2/services/uploads/uploadsCompositions'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import ImageThumbnail from '@/components/Blocks/Image/ImageThumbnail.vue'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import Button from '@/components/Button.vue'

// Variants:
// cover | round

export default {
  name: 'ImageThumbnailUpload',
  components: {
    FileUploader,
    ImageThumbnail,
    PieProgressBar,
    Button,
  },
  props: {
    value: String,
    width: Number,
    height: Number,
    variant: {
      type: String,
      default: 'cover',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const url = computed(() => props.value)
    const {
      state: fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
    } = useFileUpload(url)

    const variantMap = {
      cover: {
        buttonClasses:
          'w-full flex flex-row justify-end items-center p-24 space-x-8 relative -mt-80',
        imageClasses: 'w-full',
        placeholderClasses:
          'w-full text-gray-300 w-full h-160 bg-gray flex items-center justify-center border-b border-gray-200',
      },
      cover2xl: {
        buttonClasses:
          'w-full flex flex-row justify-end items-center p-24 space-x-8 relative -mt-80',
        imageClasses: 'w-full',
        placeholderClasses:
          'w-full text-gray-300 w-full h-[256px] bg-gray flex items-center justify-center border-b border-gray-200',
      },

      round: {
        buttonClasses:
          'w-full flex flex-row justify-center items-center space-x-8 mt-8',
        imageClasses: 'rounded-full overflow-hidden relative',
        placeholderClasses:
          'text-gray-300 w-128 h-128 bg-gray flex items-center justify-center border border-gray-200 bg-gray-100 rounded-full overflow-hidden',
      },
    }

    const uploading = computed(() => fileUploadState.isUploading)

    watch(uploading, uploading => context.emit('uploading', uploading))

    const cssStyle = computed(() => ({
      width: props.width && `${props.width}px`,
      height: props.height && `${props.height}px`,
    }))

    const buttonText = computed(() => (hasUrl.value ? 'Replace' : 'Upload'))
    const variantClasses = computed(() => variantMap[props.variant])
    const hasSlotButtons = computed(() => Boolean(context.slots.buttons))

    return {
      cssStyle,
      buttonText,
      hasSlotButtons,

      // file upload
      fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileDone,
      onFileError,
      onUploadEnd,
      variantClasses,
    }
  },
}
</script>

<style module lang="scss">
@import './ImageThumbnailUpload.scss';
</style>
