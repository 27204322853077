
<a
  class="
    flex items-center justify-center
    px-12 font-medium
    bg-white
    dark:bg-darkGray-800
    md:rounded h-36
    transform-gpu transition-all
    hover:cursor-pointer hover:-translate-y-2
    z-10
  "
  href="https://superokay.com"
  target="_blank"
>
  <span class="w-16 mr-8 icon-superokay text-16"></span>
  <span class="text-gray-600 dark:text-darkGray-500 text-12">
    Powered by <span class="text-gray-700 dark:text-darkGray-400">SuperOkay</span>
  </span>
</a>
