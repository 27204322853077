
<!-- Document structure -->
<DocumentContentLoader
  v-slot="{ isLoading }"
  :document="document"
>
  <!-- Nodes from structure -->
  <DocumentNodeFolder
    v-if="!isLoading && document"
    :view-context="viewContext"
    :permissions="permissions"
  />
</DocumentContentLoader>
