<script>
import { defineComponent } from '@vue/composition-api'
import { useCheckMemberRole } from '@/v2/services/myMembers/compositions'

/** @typedef {import('@/v2/services/members/membersTypes').Role} Role */

export default defineComponent({
  name: 'CheckMemberRole',
  props: {
    roles: {
      /** @type {import('vue').PropType<Role[]>} */
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const { hasRole } = useCheckMemberRole(props.roles);
    return () => context.slots.default({ hasRole: hasRole.value });
  },
})
</script>
