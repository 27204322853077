
<LoaderWebFont :families="[styling.font || '']">
  <component
    :is="styling.headingSize"
    v-if="isEditable"
    role="content-block-heading"
    data-intercom-target="Heading Block"
    @contextmenu.stop
  >
    <TextEditor
      v-if="isEditable"
      placeholder="Type something..."
      :lazy="true"
      :class="[
        $style[`text-variant-${textVariant}`],
        $style['heading'],
        $style[`is-${styling.headingSize}`],
        `text-${styling.textAlign}`,
      ]"
      :style="{
        fontFamily: styling.font || 'var(--cp-heading-font)',
        color: styling.textColor,
      }"
      :value="contentBlock.heading"
      :editor-features="textEditorFeatures"
      :show-floating-menu="false"
      @input="val => $emit('update', { heading: val })"
    />
  </component>

  <component
    :is="styling.headingSize"
    v-else
    role="content-block-heading"
    data-placeholder="Type something..."
    :class="[
      $style[`text-variant-${textVariant}`],
      $style['heading'],
      $style[`is-${styling.headingSize}`],
      `text-${styling.textAlign}`,
      'editor-content-preview',
      'editor-content',
      isEditable && 'editor-content-editable',
    ]"
    :style="{
      fontFamily: styling.font || 'var(--cp-heading-font)',
      color: styling.textColor,
    }"
    v-html="contentBlock.heading"
  />
</LoaderWebFont>
