export const GUEST_HOME = 'GUEST_HOME'
export const GUEST_SIGN_IN = 'GUEST_SIGN_IN'
export const GUEST_SIGN_UP = 'GUEST_SIGN_UP'
export const GUEST_SIGN_UP_INVITE = 'GUEST_SIGN_UP_INVITE'
export const GUEST_SIGN_UP_PROFILE = 'GUEST_SIGN_PROFILE'
export const GUEST_RECOVER_PASSWORD_REQUEST = 'GUEST_RECOVER_PASSWORD_REQUEST'
export const GUEST_RECOVER_PASSWORD_RESET = 'GUEST_RECOVER_PASSWORD_RESET'
export const GUEST_SHARED_DOCUMENT = 'GUEST_SHARED_DOCUMENT'
export const GUEST_EMBEDDED_DOCUMENT = 'GUEST_EMBEDDED_DOCUMENT'
export const GUEST_PRINT_DOCUMENT = 'GUEST_PRINT_DOCUMENT'
export const GUEST_REGISTER_SUMOLING = 'GUEST_REGISTER_SUMOLING'

export const GUEST_TEMPLATE_DOCUMENT = 'GUEST_TEMPLATE_DOCUMENT'

export const GUEST_STYLE_GUIDE = 'GUEST_STYLE_GUIDE'
