<template>
  <div
    ref="container"
    :class="[
      'resize-container relative',
      { 'is-resizing': isResizing },
      { 'show-selection': showSelection },
    ]"
    :style="containerStyle"
  >
    <div
      :class="[
        'transition-opacity',
        isResizing && 'opacity-75 transition-opacity',
      ]"
    >
      <slot />
    </div>
    <div
      v-if="isResizing"
      :class="[
        'absolute px-4 py-4 leading-none z-10',
        'bg-gray-900 bg-opacity-80 text-white font-regular',
        'transform',
        width > 320
          ? 'text-14 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md'
          : 'left-8 top-8 text-12 whitespace-nowrap rounded'
      ]"
    >
      {{ containerSize.width }} x {{ containerSize.height }}
    </div>
    <template v-if="!disabled">
      <div
        class="handler handler-lt"
        @mousedown="event => onDragStart(event, 'lt')"
      />
      <div
        class="handler handler-rt"
        @mousedown="event => onDragStart(event, 'rt')"
      />
      <div
        class="handler handler-rb"
        @mousedown="event => onDragStart(event, 'rb')"
      />
      <div
        class="handler handler-lb"
        @mousedown="event => onDragStart(event, 'lb')"
      />
    </template>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import { restrictValue } from '@/v2/lib/helpers/fp'

const HANDLER_WIDTH = 20
const restrictWidth = restrictValue(HANDLER_WIDTH * 2, Infinity)

const eventOffset = (event, target) => {
  const { x, y, width, height } = (
    target || event.target
  ).getBoundingClientRect()

  return {
    x: (event.touches ? event.touches[0].clientX : event.clientX) - x,
    y: (event.touches ? event.touches[0].clientY : event.clientY) - y,
    width,
    height,
  }
}

const cursorMap = {
  lt: 'nwse-resize',
  rt: 'nesw-resize',
  rb: 'nwse-resize',
  lb: 'nesw-resize',
}

export default {
  name: 'ObjectResize',
  props: {
    src: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
    disabled: Boolean,
    showSelection: Boolean,
  },
  setup(props, context) {
    const container = ref(null)
    const isResizing = ref(false)
    const containerSize = reactive({ width: 0, height: 0 })

    let handlerOffsetX = 0
    let handlerLeft = false
    let resizeStartEventEmitted = false

    const setSizeInfo = () => {
      const rect = container.value.getBoundingClientRect()
      containerSize.width = Math.round(rect.width)
      containerSize.height = Math.round(rect.height)
    }

    const onDragStart = (event, handler) => {
      event.preventDefault()
      document.body.parentNode.style.cursor = cursorMap[handler]
      // document.body.style.pointerEvents = 'none'

      isResizing.value = true
      const { x } = eventOffset(event)

      const isLeft = ['lt', 'lb'].includes(handler)
      handlerOffsetX = isLeft ? x : HANDLER_WIDTH - x
      handlerLeft = isLeft

      document.addEventListener('mouseup', onDragEnd, { once: true })
      document.addEventListener('mousemove', onDrag)
    }

    const onDrag = event => {
      event.preventDefault()

      if (!resizeStartEventEmitted) {
        context.emit('resize-start')
        resizeStartEventEmitted = true
      }

      const { x } = eventOffset(event, container.value)
      const { width: containerWidth } = container.value.getBoundingClientRect()

      const newWidth = (handlerLeft ? containerWidth - x : x) + handlerOffsetX
      context.emit('change', { width: restrictWidth(newWidth) })
      setSizeInfo()
    }

    const onDragEnd = () => {
      isResizing.value = false
      document.removeEventListener('mousemove', onDrag)
      resizeStartEventEmitted = false
      context.emit('resize-end')

      document.body.parentNode.style.cursor = ''
      // document.body.style.pointerEvents = ''
    }

    const containerStyle = computed(() => ({
      width: props.width ? `${props.width}px` : '100%',
    }))

    onMounted(() => {
      setSizeInfo()
    })

    return {
      onDragStart,
      container,
      containerSize,
      isResizing,
      containerStyle,
    }
  },
}
</script>
<style scoped lang="postcss">
.resize-container:after {
  content: '';
  @apply inset-0 pointer-events-none select-none absolute z-0 ring-2 ring-blue-500 ring-inset hidden;
}

.resize-container.is-resizing:after,
.resize-container:hover:after,
.resize-container.show-selection:after {
  @apply block;
}

.resize-container:not(:hover):not(.is-resizing):not(.show-selection) .handler {
  @apply hidden;
}

.handler {
  @apply bg-blue-500 absolute w-16 h-16 z-1 hover:bg-blue-600 transition-all;
}

.handler-lt {
  @apply left-0 top-0;
  cursor: nwse-resize;
}

.handler-rt {
  @apply right-0 top-0;
  cursor: nesw-resize;
}

.handler-rb {
  @apply right-0 bottom-0;
  cursor: nwse-resize;
}

.handler-lb {
  @apply left-0 bottom-0;
  cursor: nesw-resize;
}
</style>
