import { inject } from '@vue/composition-api'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useMsgBoxConfirm, useMsgBoxOK } from '@/v2/lib/composition/useMsgBox'
import { ORGANIZATION_SUBSCRIPTION } from '@/router/organization/type'
import { useCurrentMember } from '@/v2/services/myMembers/compositions';
import { ROLES } from '@/v2/services/members/membersTypes';

export default function useUpgradeDialog() {
  const { member } = useCurrentMember()
  const msgBoxConfirm = useMsgBoxConfirm()
  const msgBoxOK = useMsgBoxOK()
  const { routerPush } = useRouter()
  const licensing = inject('licensing', null)

  return async (
    {
      title = 'Upgrade Your Plan',
      message = 'This feature is not included in your subscription plan.',
    } = {}
  ) => {
    const dialogProps = {
      title,
      message,
    }

    if (member.value.role !== ROLES.admin) {
      msgBoxOK(dialogProps)
      return
    }

    const ok = await msgBoxConfirm({
      ...dialogProps,
      okLabel: 'Upgrade',
      okVariant: 'tertiary',
      cancelLabel: 'Close',
    })

    if (ok && licensing) {
      routerPush({
        name: ORGANIZATION_SUBSCRIPTION,
        params: { provider: licensing.value.provider },
      })
    }
  }
}
