<template>
  <FileUploader
    v-slot="{ selectFiles, isDragging }"
    data-intercom-target="Attachments Block"
    multiple
    block
    inline-block
    @upload-start="onUploadStart"
    @upload-done="onUploadDone"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <div class="task-attachments">
      <portal to="body">
        <LightboxGallery
          :files="filesMedia"
          :index="index"
          @close="index = null"
        />
      </portal>
      <div
        v-if="batchRatio === null"
        class="uppercase text-10 flex justify-between items-center px-16 pt-16"
        :class="{ 'pb-16': !files.length }"
      >
        <div v-if="files.length">{{ $t('tasks.taskEditor.attachments') }}</div>
        <div v-else>{{ $t('tasks.taskEditor.attachmentsPlaceholder') }}</div>
        <ButtonIcon
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="$t('tasks.taskEditor.attachmentsUpload')"
          icon="icon-util-upload"
          :disabled="isUploading"
          @click="selectFiles"
        />
      </div>
      <div v-else class="px-16 pt-16">
        <ProgressBar
          class="flex-1"
          :ratio="batchRatio"
        />
      </div>
      <div v-if="files.length" class="attachments">
        <!-- Audio -->
        <section v-if="filesMedia.length" class="attach-media">
          <div
            v-for="(file, fileIndex) in filesMedia"
            :key="file.uuid"
            :class="file.local && 'uploading-image'"
            class="flex flex-col"
          >
            <DocumentContentBlockAttachmentsMedia
              v-if="!file.local"
              :file="file"
              :read-only="readonly"
              no-min-height
              @remove="remove(file)"
              @click-thumbnail="index = fileIndex"
            />
          </div>
        </section>
        <section
          v-if="filesNonMedia.length"
          class="attach-files mt-16 pt-8"
          :class="{'dark:border-t-darkGray-700 border-t': filesMedia.length }"
        >
          <div
            v-for="fileNonImage in filesNonMedia"
            :key="fileNonImage.uuid"
            :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
          >
            <DocumentContentBlockAttachmentsFile
              v-if="!fileNonImage.local"
              :file="fileNonImage"
              :read-only="readonly"
              @remove="remove(fileNonImage)"
            />
          </div>
        </section>

        <div v-if="filesErrors.length" class="attach-note">
          <h4 class="portal-paragraph-font">
            <span class="w-16 mr-4 text-16 icon-alert"></span>
            <span>{{ $t('tasks.taskEditor.attachmentsUploadError') }}</span>
          </h4>
          <div v-for="fileError in filesErrors" :key="fileError.uuid">
            <strong>{{ fileError.name }}</strong>
            {{ fileError.local.error | error }}
          </div>
        </div>
      </div>
    </div>
  </FileUploader>
</template>
<script>
import { computed, defineComponent, ref, reactive } from '@vue/composition-api'
import { useFilesUpload } from '@/v2/services/uploads/uploadsCompositions'
// File Uploader
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import LightboxGallery from '@/components/LightboxGallery.vue'
import DocumentContentBlockAttachmentsFile from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsFile.vue'
import DocumentContentBlockAttachmentsMedia from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsMedia.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import ProgressBar from '@/components/Blocks/ProgressBar/ProgressBar.vue'
// import PendingUploads from '@/components/PendingUploads.vue'

export default defineComponent({
  name: 'ProjectTaskEditorAttachments',
  components: {
    FileUploader,
    DocumentContentBlockAttachmentsFile,
    DocumentContentBlockAttachmentsMedia,
    LightboxGallery,
    ButtonIcon,
    ButtonTransparent,
    ProgressBar,
    // PendingUploads,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const index = ref(null)

    const update = files => context.emit('update', { files })
    const add = files => update([...props.task.files, ...files])
    const remove = file => update(props.task.files.filter(({ uuid }) => file.uuid !== uuid))

    const {
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
      batchRatio,
    } = useFilesUpload(
      computed(() => props.task.files),
      add
    )

    const pendingUploads = computed(() => files.value.filter(file => file.local))

    const uploadedBatch = reactive({
      files: [],
    })

    const onPendingUploadsDone = () => {
      uploadedBatch.files = []
    }

    const _onUploadStart = (...args) => {
      onUploadStart(...args)
      uploadedBatch.files = [...pendingUploads.value]
    }

    return {
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      index,
      pendingUploads,
      uploadedBatch,
      // methods
      onUploadStart: _onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
      remove,
      onPendingUploadsDone,
      batchRatio,
    }
  },
})
</script>
<style lang="postcss" scoped>
.task-attachments {
  @apply bg-gray-100 rounded-sm;
  @apply dark:bg-darkGray-1000 dark:text-white/70;
}

.attach-media {
  @apply gap-16 grid grid-cols-4;
  grid-auto-rows: 140px;
}

.attach-files {

}


.attach-note {
  margin-bottom: $gutterx2;
  position: relative;
  padding-left: 32px;
  h4 {
    color: $error;
    font-weight: $medium;
    font-size: $fs18;
    display: inline-flex;
    align-items: center;
    margin-bottom: $gutter;
    :global(.icon) {
      position: absolute;
      left: 0;
      top: -1px;
    }
  }
}

.attachments {
  @apply p-16 overflow-y-auto;
  max-height: 30vh;
}
</style>
