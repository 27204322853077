<template>
  <div class="group-placeholder mb-24">
    <section>
      <span class="icon-bio-placeholder w-128 text-128"></span>
    </section>
    <section>
      <span class="icon-bio-placeholder w-128 text-128"></span>
    </section>
    <section>
      <span class="icon-bio-placeholder w-128 text-128"></span>
    </section>
    <section>
      <span class="icon-bio-placeholder w-128 text-128"></span>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeGroupTeamBiosPlaceholder',
}
</script>
<style lang="postcss" scoped>
.group-placeholder {
  @apply flex flex-row text-gray-100 space-x-20;
}

.group-placeholder section {
  @apply flex justify-center rounded-lg overflow-hidden items-center p-20 border-2 border-gray-100;
}

.group-placeholder .icon-bio-placeholder {
  @apply w-160 h-160;
}
</style>
