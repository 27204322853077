import { makeServicePlugin } from '@/v2/services/feathersClientService'
import documentEventsService, { servicePath } from './documentEventsService'
import { Notification } from '../notifications/notificationsStore'

export class DocumentEvent extends Notification {
  static modelName = 'DocumentEvent'
}

export default makeServicePlugin({
  service: documentEventsService,
  Model: DocumentEvent,
  servicePath,
})
