<template>
  <div>
    <button
      :id="targetId"
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="tooltip"
      :class="[
        'flex items-center justify-center h-32 relative rounded',
        'outline-none focus:outline-none',
        className,
      ]"
      @click.stop="popoverShow = !popoverShow"
    >
      <slot name="button-content" />
    </button>
    <b-popover
      ref="popoverRef"
      :target="targetId"
      :triggers="triggers"
      :show="popoverShow"
      :placement="placement"
      :fallback-placement="fallbackPlacement"
      :custom-class="[
        `${$style.popover}`,
        'popover-light popover-color-picker',
        'p-0 mt-12',
        'bg-gray border border-gray-200 rounded-md shadow-xl'
      ].join(' ')"
      :no-fade="true"
      delay="0"
      boundary="main"
      @show="onPopoverOpen"
    >
      <div
        v-click-outside.capture="
          !triggers.includes('blur')
            ? () => closeOnClickOutside && close()
            : null
        "
        :class="[!slim && 'p-12']"
        @click.stop
      >
        <div
          class="text-gray-700 w-235 text-14"
          :style="{ width: `${width}px` }"
        >
          <slot />
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
import { getCurrentInstance, ref, onUnmounted } from '@vue/composition-api'

export default {
  name: 'DocumentNodeEditorToolbarPopoverLight',
  props: {
    targetId: {
      type: String,
      default: null,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    fallbackPlacement: {
      // fallback-placement accepts either a string or an array of positions
      // to handle custom behaviours, it can receive ['bottom'] as a prop or nothing
      // to maintain the default behaviour
      type: [String, Array],
      default: () => 'flip',
    },
    width: {
      type: Number,
      default: null,
    },
    triggers: {
      type: String,
      default: 'click blur',
    },
    className: {
      type: [String, Object, Array],
      default: 'text-white',
    },
    slim: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const popoverRef = ref(null)
    const popoverShow = ref(false)

    // eslint-disable-next-line vue/custom-event-name-casing
    const onPopoverOpen = () => {
      context.emit('popover-opening')
      vm.$root.$emit('bv::hide::popover')
    }
    const close = () => {
      context.emit('popover-closing')
      popoverRef.value.$emit('close')
    }

    const onPopoverShown = bvEvent => {
      if (bvEvent.vueTarget.target === props.targetId) {
        popoverShow.value = true
      }
    }

    const onPopoverHidden = bvEvent => {
      if (bvEvent.vueTarget.target === props.targetId) {
        popoverShow.value = false
      }
    }

    vm.$root.$on('bv::popover::shown', onPopoverShown)
    vm.$root.$on('bv::popover::hidden', onPopoverHidden)

    onUnmounted(() => {
      vm.$root.$off('bv::popover::shown', onPopoverShown)
      vm.$root.$off('bv::popover::hidden', onPopoverHidden)
    })

    return {
      onPopoverOpen,
      popoverRef,
      close,
      popoverShow,
    }
  },
}
</script>
<style module lang="scss">
.popover {
  &[x-placement^='top'] {
    :global(.arrow) {
      &::after {
        border-top-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  &[x-placement^='bottom'] {
    :global(.arrow) {
      &::after {
        border-bottom-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  :global(.popover-body) {
    color: theme('colors.gray.700');
    padding: 0;
  }
}
</style>
