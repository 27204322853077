<template>
  <portal to="page-header">
    <div class="flex items-center gap-x-4 h-full flex-1">
      <ButtonTransparent
        v-if="showBack"
        @click="$emit('back')"
      >
        <span class="icon_v2-so_direction-left" />
      </ButtonTransparent>
      <div class="flex flex-1 justify-between items-center">
        <h2 v-if="title" class="font-semibold text-16">{{ title }}</h2>
        <slot />
        <div class="flex items-center gap-x-16">
          <slot name="actions" />
          <PageHeaderSearch
            v-if="search"
            v-model="keyword"
            :show-result="showSearchResult"
            :placeholder="searchPlaceholder"
            @update:show-result="val => $emit('update:show-search-result', val)"
            @close="closeSearchResult"
          >
            <slot name="search-result" />
          </PageHeaderSearch>
          <slot name="filter" />
        </div>
      </div>
    </div>
  </portal>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import PageHeaderSearch from '@/components/PageHeaderSearch.vue'

export default defineComponent({
  name: 'PageHeader',
  components: {
    ButtonTransparent,
    PageHeaderSearch,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    showBack: {
      type: Boolean,
      default: null,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchKeyword: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    showSearchResult: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const keyword = computed({
      set(value) {
        context.emit('update:search-keyword', value)
      },
      get() {
        return props.searchKeyword
      },
    })

    const closeSearchResult = () => {
      context.emit('update:show-search-result', false)
      keyword.value = ''
    }

    return {
      keyword,
      closeSearchResult,
    }
  },
})
</script>
