
<section
  class="
    max-w-screen-sm
    md:max-w-[90%]
    lg:max-w-[80%]
    xl:max-w-editor
    2xl:max-w-screen-lg
    grid grid-cols-2 md:grid-cols-4 gap-16
    auto-cols-fr auto-rows-fr
    -mt-80 2xl:-mt-192
    mx-16 sm:mx-auto
  "
>
  <slot />
</section>
