
<div
  v-b-tooltip="{
    placement: 'bottom',
    trigger: 'hover',
    variant: 'info',
    delay: {'show':200,'hide':0},
    title: `${name}`,
  }"
  class="file-name"
>
  <div class="file-name-part truncate">
    {{ name | fileName }}
  </div>
  <div class="file-ext-part">
    {{ name | fileExt }}
  </div>
</div>
