<template>
  <li
    ref="element"
    class="kanban-card group/tasks"
    :class="{ 'selected': selected }"
  >
    <slot />
    <div
      v-if="!drag && !isReadonly"
      class="
        bg-gray-200 absolute right-0 top-0 overflow-hidden rounded-bl-md
        dark:bg-white/10
        items-center justify-center h-24 w-24 hidden group-hover/tasks:flex
      "
    >
      <ButtonIcon
        icon="icon_v2-so_trash text-red-500"
        size="18"
        @click.stop="$emit('remove')"
      />
    </div>
  </li>
</template>
<script>
import { computed, defineComponent, ref, toRef } from '@vue/composition-api'
import { useScrollIntoViewIfActive } from '@/v2/lib/composition/useScrollIntoView'
import ButtonIcon from '@/components/ButtonIcon.vue'

export default defineComponent({
  name: 'KanbanCard',
  components: {
    ButtonIcon,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: false,
    },
    readonlyTask: {
      type: [Boolean, Function],
      default: false,
    },
  },
  setup(props) {
    const element = ref(null)
    useScrollIntoViewIfActive(element, toRef(props, 'selected'))

    const isReadonly = computed(
      () => (typeof props.readonlyTask === 'function'
        ? props.readonlyTask(props.task)
        : props.readonlyTask)
    )

    return {
      element,
      isReadonly,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .kanban-card {
    @apply text-gray-500 hover:text-gray-800;
    @apply cursor-pointer my-4 shadow-sm border rounded-md box-border relative;
    @apply dark:border-darkGray-700 dark:hover:text-white/70;

    /* dark */

    &:not(.selected) {
      @apply bg-gray-100/50 hover:border-gray-300;
      @apply dark:bg-darkGray-900 dark:hover:border-darkGray-600;
    }

    &.selected {
      @apply bg-gray-200 text-gray-800 border-gray-300;
      @apply dark:bg-darkGray-700 dark:text-white dark:border-darkGray-600;
    }
  }
</style>
