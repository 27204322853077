
<NotificationDocument v-slot="{ document }" :notification="notification">
  <DocumentLink v-slot="{ navigate }" :document="document">
    <NotificationRenderer
      v-slot="{ options }"
      :notification="notification"
      icon-variant="author"
      badge-icon="icon_v2-so_tick"
      badge-class="bg-green-500"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      Changed status of <NotificationLinkDocument :document="document" />
      <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
      from <span class="font-semibold">Approved</span>
      to <span class="font-semibold">No Status</span>
    </NotificationRenderer>
  </DocumentLink>
</NotificationDocument>
