<template>
  <div>
    <div class="relative">
      <AnswerBadge
        v-if="['XL', 'XXL'].includes(nodeWidth)"
        :is-answerable="isAnswerable"
        :has-no-answer="!contentBlock.isDirty"
        position="top"
      />
      <div
        v-if="!contentBlock.settings.hasMultipleAnswers"
        :class="isVerticalStyle"
      >
        <b-form-radio
          v-for="(answer, index) in composedAnswersFromOptions"
          :key="index"
          :name="`radio-choices`"
          :value="index"
          :checked="answer.checked ? index : null"
          :disabled="!isAnswerable"
          button
          button-variant="radio-success"
          class="whitespace-nowrap portal-paragraph-font"
          @change="val => handleCheckboxChange(true, index)"
        >
          {{ answer.label }}
        </b-form-radio>
      </div>
      <div
        v-else
        :class="isVerticalStyle"
      >
        <b-form-checkbox
          v-for="(answer, index) in composedAnswersFromOptions"
          :key="index"
          :checked="answer.checked"
          :name="`checkbox-${index}`"
          :disabled="!isAnswerable"
          button
          button-variant="checkbox-success"
          class="whitespace-nowrap portal-paragraph-font"
          @change="val => handleCheckboxChange(val, index)"
        >
          {{ answer.label }}
        </b-form-checkbox>
      </div>
    </div>
    <Notes
      v-if="contentBlock.settings.hasNotes"
      :is-answerable="isAnswerable"
      :text-variant="textVariant"
      :value="contentBlock.notes"
      @input="data => $emit('update', data)"
    />
  </div>
</template>

<script>
import { inject, computed, watch } from '@vue/composition-api'
import Notes from '@/components/Notes.vue'
import AnswerBadge from '@/components/Document/AnswerBadge.vue'

export default {
  name: 'DocumentContentBlockQAMultipleChoice',
  components: {
    Notes,
    AnswerBadge,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isAnswerable: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const isLocked = inject('isLocked')

    const composedAnswersFromOptions = computed(() => props.contentBlock.settings.possibleAnswers.map((element, index) => ({
      label: element,
      checked: props.contentBlock.answer.includes(index),
    })))

    watch(
      () => props.contentBlock.settings.hasMultipleAnswers,
      () => {
        context.emit('update', {
          answer: [],
          isDirty: false,
        })
      }
    )

    const isVerticalStyle = computed(() => (props.contentBlock.settings.displayVertical
      ? 'flex flex-col gap-8'
      : 'flex flex-wrap gap-8'))

    const handleCheckboxChange = (val, idx) => {
      let newChoices
      let isEmpty = false
      if (!props.contentBlock.settings.hasMultipleAnswers) {
        newChoices = val ? [idx] : []
      } else {
        newChoices = val
          ? [...props.contentBlock.answer, idx]
          : props.contentBlock.answer.filter(i => i !== idx)
        // If we're about to unselect an item AND the answer array length is 1 (aka there is just)
        // one answer selected, consider the input empty and emmit isDirty false
        isEmpty = props.contentBlock.answer.length === 1 && !val
      }
      context.emit('update', {
        answer: newChoices,
        isDirty: !isEmpty,
      })
    }

    return {
      isLocked,
      handleCheckboxChange,
      composedAnswersFromOptions,
      isVerticalStyle,
    }
  },
}
</script>
<style lang="scss" module>
.text-variant-light {
  color: #fff !important;
}
</style>
