<template>
  <b-form-checkbox
    v-model="approved"
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Toggle Approved Status`"
    size="md"
    switch
    class="hidden pr-4 pl-36 md:flex"
  >
    <span
      class="text-14 font-semibold leading-none"
      :class="approved ? 'text-green-600' : 'text-gray-600'"
    >
      Approved
    </span>
  </b-form-checkbox>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { STATUS as DOCUMENT_STATUS } from '@/types/document'

export default defineComponent({
  name: 'DocStatusToggle',
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const approved = computed({
      get() {
        return props.value === DOCUMENT_STATUS.APPROVED
      },
      set(value) {
        const newStatus = value ? DOCUMENT_STATUS.APPROVED : DOCUMENT_STATUS.DRAFT
        context.emit('input', newStatus)
      },
    })

    return {
      approved,
    }
  },
})
</script>

<style lang="scss" scoped>
.dropdown-badge {
  position: relative;
}
</style>
