import { pipe, propOr, match, pathOr, applySpec, always, prop } from 'ramda'
import { noop } from 'ramda-adjunct'
import { isNotEmpty } from 'ramda-extension'
import axios from 'axios'
import qs from 'qs'
import FileSaver from 'file-saver'
import { round } from '@/lib/math'
import { requestContextParams } from '@/v2/lib/hooks/requestContextForServer'
import feathersClientService from './feathersClientService'

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT

const client = axios.create({
  baseURL: API_ENDPOINT,
  failWithError: true,
  params: {},
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  },
})

client.interceptors.request.use(
  async config => {
    const _config = { ...config }

    // set feathers session token (if authenticated)
    const accessToken = await feathersClientService.authentication.getAccessToken()
    if (accessToken) {
      _config.headers.Authorization = `Bearer ${accessToken}`
    }

    const requestContext = requestContextParams({ grant: 'document' })
    if (isNotEmpty(requestContext)) {
      Object.assign(_config.params, { $client: requestContext })
    }

    return _config;
  },
  error => {
    Promise.reject(error)
  }
)

const parseContentDisposition = pipe(
  match(/filename="(?<filename>[^"]+)"/),
  applySpec({
    filename: pathOr(null, ['groups', 'filename']),
  })
)

const filenameFromHeaders = defaultFilename => pipe(
  propOr('', 'content-disposition'),
  parseContentDisposition,
  propOr(defaultFilename, 'filename')
)

const upload = (url, data, onUploadProgress) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  }

  return client.post(url, data, config)
}

export const download = async ({
  url,
  defaultFilename = null,
  onProgress = noop,
}) => {
  const { data, headers } = await client.get(url, {
    responseType: 'blob',
    timeout: 60000,
    onDownloadProgress: progressEvent => onProgress({
      progressEvent,
      ratio: round(progressEvent.loaded / progressEvent.total, 2),
    }),
  })

  FileSaver(data, filenameFromHeaders(defaultFilename)(headers))
}

export const getMetadata = url => axios.head(url, { timeout: 3000 })
  .then(pipe(
    propOr({}, 'headers'),
    applySpec({
      contentType: prop('content-type'),
      length: prop('content-length'),
    })
  ))
  .catch(always({}))

export default { upload, download }
