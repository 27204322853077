import { checkContextIf, getItems, replaceItems } from 'feathers-hooks-common'
import { omitCond } from '@/v2/lib/helpers/fp'

export default function discardCond(predicate) {
  return context => {
    checkContextIf(context, 'before', ['create', 'update', 'patch'], 'discard')

    const items = getItems(context)
    const convert = omitCond(predicate)
    const converted = Array.isArray(items) ? items.map(convert) : convert(items)

    replaceItems(context, converted)

    return context
  };
}
