
<div class="radial-progress-container" :style="containerStyle">
  <div class="radial-progress-inner" :style="innerCircleStyle">
    <slot></slot>
  </div>
  <svg
    class="radial-progress-bar"
    :width="diameter"
    :height="diameter"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <radialGradient
        :id="'radial-gradient' + _uid"
        :fx="gradient.fx"
        :fy="gradient.fy"
        :cx="gradient.cx"
        :cy="gradient.cy"
        :r="gradient.r"
      >
        <stop offset="30%" :stop-color="startColor" />
        <stop offset="100%" :stop-color="stopColor" />
      </radialGradient>
    </defs>
    <circle
      :r="innerCircleRadius"
      :cx="radius"
      :cy="radius"
      fill="transparent"
      :stroke="innerStrokeColor"
      :stroke-dasharray="circumference"
      stroke-dashoffset="0"
      :stroke-linecap="strokeLinecap"
      :style="strokeStyle"
    />
    <circle
      :transform="'rotate(270, ' + radius + ',' + radius + ')'"
      :r="innerCircleRadius"
      :cx="radius"
      :cy="radius"
      fill="transparent"
      :stroke="'url(#radial-gradient' + _uid + ')'"
      :stroke-dasharray="circumference"
      :stroke-dashoffset="circumference"
      :stroke-linecap="strokeLinecap"
      :style="progressStyle"
    />
  </svg>
</div>
