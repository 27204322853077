<template>
  <b-dropdown
    v-click-outside.capture="() => $emit('visibility-change', false)"
    variant="reset"
    lazy
    no-caret
    right
    offset="210"
    :menu-class="`w-420 text-14 print:hidden !mb-24 !mt-24 ${$style.menu}`"
    toggle-class="flex-1 p-0 print:hidden"
    @show="onDropdownShow"
    @shown="$emit('visibility-change', true)"
    @hidden="$emit('visibility-change', false)"
  >
    <!-- Trigger Button -->
    <template #button-content>
      <span
        v-if="!contentLibrary"
        :class="[
          mode==='root' && 'add-btn-icon',
          mode==='child' && 'add-btn-full',
        ]"
      >
        <span class="w-24 m-0 leading-none icon_v2-so_plus text-24"></span>
        <span v-if="buttonLabel">{{ buttonLabel }}</span>
      </span>

      <!-- Used for Case Studies & Team Bios when adding new cards inside them -->
      <span v-else class="add-btn-full">
        <span class="w-24 leading-none icon_v2-so_plus text-24"></span>
        {{ label }}
      </span>
    </template>
    <div class="flex">
      <div class="flex-1">
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementHeading)"
          icon="heading"
          label="Section Heading"
          @select="createBlock(ELEMENT_CATEGORY.ElementHeading)"
        >
          Structure your document with Section Headings. These will also appear in
          the Table of Contents on the shared link
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementText)"
          icon="text"
          label="Rich Text"
          @select="createBlock(ELEMENT_CATEGORY.ElementText)"
        >
          A powerful rich text editor
        </DocumentBlockButtonCreateMenuItem>
        <!-- Table Content-Block -->
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementTable)"
          icon="table"
          label="Table"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-table.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementTable)"
        >
          Add rich tables.
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementImage)"
          icon="image"
          label="Image"
          @select="createBlock(ELEMENT_CATEGORY.ElementImage)"
        >
          Include images straight in the document
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementButton)"
          icon="button"
          label="Button"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-button.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementButton)"
        >
          Create custom buttons
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementAttachments)"
          icon="link"
          label="Uploaded Files"
          @select="createBlock(ELEMENT_CATEGORY.ElementAttachments)"
        >
          Attach multiple images and other types of files to any document
        </DocumentBlockButtonCreateMenuItem>

        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementPageBreak)"
          icon="pagebreak"
          label="Page Break"
          @select="createBlock(ELEMENT_CATEGORY.ElementPageBreak)"
        >
          This content block will force a page break in the PDF or print version of the document.
        </DocumentBlockButtonCreateMenuItem>

        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementSpacer)"
          icon="spacer"
          label="Spacer"
          @select="createBlock(ELEMENT_CATEGORY.ElementSpacer)"
        >
          Add space between blocks and customize its height and background style.
        </DocumentBlockButtonCreateMenuItem>
      </div>
      <div class="flex-1">
        <DocumentBlockButtonCreateSubMenu
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementQA)"
          icon="chat-notification"
          label="Q&amp;A"
          @select="createBlock(ELEMENT_CATEGORY.ElementQA)"
        >
          <template v-for="input in inputTypes">
            <DocumentBlockButtonCreateMenuItem
              :key="input.id"
              :icon="input.icon"
              :label="input.label"
              @select="createBlockQA(ELEMENT_CATEGORY.ElementQA, input.id)"
            />
          </template>
        </DocumentBlockButtonCreateSubMenu>
        <!-- Embed Content-Block -->
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementEmbed)"
          icon="embed"
          label="Embed"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-embed.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementEmbed)"
        >
          Add media embeds. Over 1900 publishers.
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="!noContentLibrary"
          icon="library"
          label="Content Library"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-content-library.jpg"
          @select="openContentLibrary"
        >
          Add reusable content blocks, include Team Bios or Case Studies in any
          document
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="!accepts"
          icon="grouping"
          label="Group"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-groups.jpg"
          @select="createNodeGroup(NODE_CATEGORY.NodeGroupBasic)"
        >
          Group multiple content blocks so they can share the same background.
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="!accepts"
          icon="columns"
          label="Columns"
          @select="createNodeGroup(NODE_CATEGORY.NodeGroupColumnsContainer )"
        >
          Create sections where you group content on 2 or 3 columns.
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="accepts && accepts.includes(NODE_CATEGORY.NodeGroupColumn)"
          icon="object-group"
          label="Column"
          new-badge
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-groups.jpg"
          @select="createNodeGroup(NODE_CATEGORY.NodeGroupColumn)"
        >
          Create multiple columns.
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementCost)"
          icon="pricing-table"
          label="Pricing Table"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-cost-table.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementCost)"
        >
          Add your billable services and break down the cost for a proposal,
          estimate or anything else
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementCostBreakdown)"
          icon="pie-chart"
          label="Price Breakdown"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-cost-summary.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementCostBreakdown)"
        >
          A visual overview of how the total budget is calculated
        </DocumentBlockButtonCreateMenuItem>
        <DocumentBlockButtonCreateMenuItem
          v-if="checkBlockCompatible(ELEMENT_CATEGORY.ElementApproval)"
          icon="button-approval"
          label="Approval Button"
          image="https://superokay-static.s3.amazonaws.com/block-previews/preview-approval.jpg"
          @select="createBlock(ELEMENT_CATEGORY.ElementApproval)"
        >
          Allow clients to approve documents when viewing them via a Shared Link
        </DocumentBlockButtonCreateMenuItem>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { isNonEmptyArray } from 'ramda-adjunct'
import { includes } from 'ramda'
import { inject, computed, defineComponent, unref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import translations from '@/translations'
import { CATEGORY as ELEMENT_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
import DocumentBlockButtonCreateMenuItem from '@/components/Document/DocumentBlockButtonCreateMenuItem.vue'
import DocumentBlockButtonCreateSubMenu from './DocumentBlockButtonCreateSubMenu.vue'

const inputTypes = [
  {
    id: 'longText',
    label: 'Long Text',
    icon: 'subject',
  },
  {
    id: 'multipleChoice',
    label: 'Multiple Choice',
    icon: 'checkmark-rectangle',
  },
  {
    id: 'fileUpload',
    label: 'File Upload',
    icon: 'file-upload',
  },
  {
    id: 'rating',
    label: 'Rating',
    icon: 'star',
  },
  {
    id: 'imageChoice',
    label: 'Image Choice',
    icon: 'image-favorite',
  },
  {
    id: 'slider',
    label: 'Slider',
    icon: 'slider',
  },
  {
    id: '2dSlider',
    label: '2D Slider',
    icon: 'slider-2d',
  },
  {
    id: 'date',
    label: 'Date',
    icon: 'calendar',
  },
]

const { useMutations } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocumentBlockButtonCreateMenu',
  components: {
    DocumentBlockButtonCreateMenuItem,
    DocumentBlockButtonCreateSubMenu,
  },
  props: {
    parentNodeId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    accepts: {
      type: Array,
      default: null,
    },
    contentLibrary: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'root',
    },
    noContentLibrary: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: 'before',
    },
  },
  setup(props, context) {
    const createNode = useCreateNode()
    const { startSessionContentLibrary } = useMutations([
      'startSessionContentLibrary',
    ])

    const document = inject('document')
    const contentBlockBlacklist = inject('contentBlockBlacklist', {});

    const checkBlockCompatible = contentBlockCategory => {
      const isAccepted = Array.isArray(props.accepts)
        ? includes(contentBlockCategory, props.accepts)
        : true

      const isBlacklisted = unref(contentBlockBlacklist)[contentBlockCategory] ?? false;
      return isAccepted && !isBlacklisted;
    }

    // Where do we add the new block?
    const targetIndex = computed(() => {
      // Index of current node in document's structure.
      const { index: currentNodeIndex } = props
      // If adding before, use current index and increment by 1 if pasting after
      const offset = props.location === 'before' ? 0 : 1
      return currentNodeIndex + offset
    })

    const noSmartBlocks = computed(() => [
      DOCUMENT_CATEGORY.DocumentCaseStudy,
      DOCUMENT_CATEGORY.DocumentTeamBio,
    ].includes(document.value.category))

    const openContentLibrary = (contentDocumentCategory = null) => {
      startSessionContentLibrary({
        contentDocumentCategory,
        documentId: document.value._id,
        parentNodeId: props.parentNodeId,
        index: targetIndex.value,
      })
    }

    const onDropdownShow = bvEvt => {
      if (props.contentLibrary && isNonEmptyArray(props.accepts)) {
        bvEvt.preventDefault()
        openContentLibrary(props.accepts[0])
      } else {
        context.emit('show')
      }
    }

    const createBlock = category => {
      createNode({
        index: targetIndex.value,
        parentNodeId: props.parentNodeId,
        contentBlockData: { category },
      })
    }
    const createNodeGroup = category => {
      createNode({
        index: targetIndex.value,
        parentNodeId: props.parentNodeId,
        nodeData: { category },

      })
    }

    const createBlockQA = (category, input) => {
      createNode({
        index: targetIndex.value,
        parentNodeId: props.parentNodeId,
        contentBlockData: { category, inputType: input },
      })
    }

    const label = computed(() => {
      if (isNonEmptyArray(props.accepts)) {
        return `Add ${translations[props.accepts[0]]}`
      }
      return ''
    })

    return {
      // static data
      ELEMENT_CATEGORY,
      DOCUMENT_CATEGORY,
      NODE_CATEGORY,

      // data
      document,
      inputTypes,
      // methods
      checkBlockCompatible,
      openContentLibrary,
      onDropdownShow,
      createBlock,
      createNodeGroup,
      createBlockQA,
      label,

      // computed
      noSmartBlocks,
      targetIndex,
      contentBlockBlacklist,
    }
  },
})
</script>

<style lang="postcss" scoped>
.add-btn-icon {
  @apply rounded-full bg-gray-700 flex items-center justify-center;
  @apply h-24 w-24;
  @apply text-gray-200 leading-none;
  @apply transition-all transform-gpu;
  @apply hover:bg-gray-800 ring-green-500 ring-0 ring-opacity-0;
  @apply hover:ring-4 hover:ring-opacity-40 hover:rotate-180;
  @apply hover:text-white;
  @apply hover:ring-offset-gray-800 hover:ring-offset-4;
}

.add-btn-full {
  @apply rounded-lg bg-gray-100 flex items-center justify-center hover:bg-gray-800 transition-all;
  @apply text-gray-600 hover:text-white;
  @apply h-32 text-center px-8 leading-none;
  @apply w-full;
}
</style>
<style lang="postcss" module>
.menu {
  z-index: 99999;
}
</style>
