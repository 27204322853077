import { computed } from '@vue/composition-api'
import useGroupByDate from '@/v2/lib/composition/useGroupByDate'
import { ACTION, SUBJECT } from '@/v2/services/notifications/notificationsTypes'
import useFindPagination from '@/v2/lib/composition/useFindPagination'

const createVGroupKey = ({ subjectType, action }) => `${subjectType}-${action}`

export const vGroupKeyActionItemsCreated = createVGroupKey({
  subjectType: SUBJECT.actionItems,
  action: ACTION[SUBJECT.actionItems].created,
})

export const vGroupKeyActionItemsResolved = createVGroupKey({
  subjectType: SUBJECT.actionItems,
  action: ACTION[SUBJECT.actionItems].resolved,
})

export const vGroupKeyCommentsCreated = createVGroupKey({
  subjectType: SUBJECT.comments,
  action: ACTION[SUBJECT.comments].created,
})

export const vGroupKeyCommentsResolved = createVGroupKey({
  subjectType: SUBJECT.comments,
  action: ACTION[SUBJECT.comments].resolved,
})

const createVGroup = (vGroupKey, vGroups) => {
  const events = vGroups[vGroupKey]
  if (!events?.length) {
    return []
  }

  return {
    _id: vGroupKey,
    subjectType: 'vGroup',
    action: vGroupKey,
    events,
  }
}

const computeVirtualGroups = notifications => {
  const vGroups = {
    [vGroupKeyActionItemsCreated]: [],
    [vGroupKeyActionItemsResolved]: [],
    [vGroupKeyCommentsCreated]: [],
    [vGroupKeyCommentsResolved]: [],
    ungrouped: [],
  }

  notifications.forEach(notification => {
    const groupKey = createVGroupKey(notification)
    const vGroup = vGroups[groupKey] ?? vGroups.ungrouped
    vGroup.push(notification)
  })

  return [
    createVGroup(vGroupKeyActionItemsCreated, vGroups),
    createVGroup(vGroupKeyActionItemsResolved, vGroups),
    createVGroup(vGroupKeyCommentsCreated, vGroups),
    createVGroup(vGroupKeyCommentsResolved, vGroups),
    vGroups.ungrouped,
  ].flat()
}

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {Ref<string>} params.projectId
 * @param {number} [params.pageSize]
 * @param {string} [params.queryId]
 */
export default function useProjectEvents({
  projectId,
  pageSize = 50,
}) {
  const query = computed(() => ({
    project: projectId.value,
    // target: 'timeline',
    $sort: { time: -1 },
  }))

  const {
    items: notifications,
    ...rest
  } = useFindPagination({
    modelName: 'ProjectEvent',
    query,
    pageSize,
  })

  const items = useGroupByDate(notifications, {
    dateKey: 'time',
    outputItemsKey: 'notifications',
    mapGroupItems: computeVirtualGroups,
  })

  return {
    ...rest,
    items,
  }
}
