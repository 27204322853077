<template>
  <DocumentNodeRenderer
    :node="node"
    root-class=" masonry-item"
    :inner-class="['p-0']"
    :config-override="{
      ignoreNodePropStyling: true,
      block: {
        accepts,
        enableFocus: false,
        enableCreateBefore: false,
        enableStylingToolbar: false,
      },
      styling: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    }"
  >
    <template #default>
      <DocumentContentBlockTeamBio
        :content-block="contentBlock"
        :show-analytics="isEditor"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { inject, provide } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/types/document'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockTeamBio from './DocumentContentBlockTeamBio.vue'

export default {
  name: 'DocumentNodeTeamBio',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockTeamBio,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup() {
    provide('hideNodeComments', true)
    provide('hideNodeActionItems', true)

    const isEditor = inject('isEditor')
    return {
      isEditor,
      accepts: [DOCUMENT_CATEGORY.DocumentTeamBio],
    }
  },
}
</script>
