import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { propEq, propSatisfies, any, equals, propOr, move, without, indexOf, insertAll, includes } from 'ramda'
import { isNilOrEmpty } from 'ramda-extension'
import { inject, computed } from '@vue/composition-api'
import { useGet } from 'feathers-vuex'
import debounce from '@/v2/lib/helpers/debounce'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'


const { useActions } = createNamespacedHelpers('documentEditor')
/**
 * @typedef {import('@vue/composition-api').Ref} Ref
 */


const getBranchAndDescendants = (branches, id, accum) => branches.reduce((acc, branch) => {
  if (branch.node === id) {
    acc.push(branch.node)
    branch.children && branch.children.forEach(
      nodeId => getBranchAndDescendants(branches, nodeId, acc)
    )
  }
  return acc
}, accum || [])

const removeDescendants = (branches, descendants) => branches.reduce((acc, branch) => {
  if (!includes(branch.node, descendants)) {
    return [...acc, branch]
  }
  return acc
}, [])

export const findBranchByNodeId = (structure, id) => structure?.branches
  .find(propSatisfies(any(equals(id)), 'children'))

export const findBranchByParent = (structure, parent) => structure?.branches.find(propEq('node', parent))

/**
 * @param {Ref} document
 * @returns {Ref}
 */
export const useStructure = (document = null) => {
  const vm = getCurrentInstanceOrThrow()

  const _document = document || inject('document')
  const { DocumentStructure } = vm.$FeathersVuex.api

  const { item: structure } = useGet({
    model: DocumentStructure,
    id: computed(() => _document.value.structure),
    local: true,
  })

  return structure
}

export const useStructureNode = () => {
  const structure = useStructure()

  return node => {
    const branch = findBranchByNodeId(structure.value, node._id)
    const index = branch && propOr([], 'children', branch).indexOf(node._id)
    const parentId = branch && (branch.node === 'root' ? 'root' : branch.node)

    return {
      index,
      parentId,
    }
  }
}

/**
 * @param {Ref<any>} [document]
 */
export const useStructureBranch = (document = null) => {
  const structure = useStructure(document)

  return parent => {
    const children = computed(() => structure.value.tree[parent] || [])
    const isEmpty = computed(() => isNilOrEmpty(children.value))
    const meta = computed(() => structure.value.meta)

    return {
      children,
      isEmpty,
      meta,
    }
  }
}

export const useStructureMoveNode = () => {
  const structure = useStructure()
  const save = debounce(data => structure.value.save({ data }), 1000)

  return ({ id, index, local = false }) => {
    const newStructure = structure.value.clone()
    const branch = findBranchByNodeId(newStructure, id)
    const currentIndex = branch.children.indexOf(id)
    // Move the node from it's current position to the desired one
    branch.children = move(currentIndex, index, branch.children)
    // Save the new order
    newStructure.commit()

    if (!local) {
      save({ branches: newStructure.branches })
    }
  }
}

export const useStructureReorderChildren = () => {
  const structure = useStructure()
  // REMOVED DEBOUNCE
  // it was causing an issue with structures getting
  // saved to the wrong documents when quickly
  // navigating after reordering
  const save = data => structure.value.save({ data })

  return ({ parent = 'root', children }) => {
    const clone = structure.value.clone()
    const branch = findBranchByParent(clone, parent)
    branch.children = children
    clone.commit()
    save({ branches: clone.branches })
  }
}


export const useStructureRemoveNode = () => {
  const { removeNodeFromSelection } = useActions(['removeNodeFromSelection'])
  const structure = useStructure()
  return async ({ id, local = false, targetStructureRef }) => {
    const _structure = targetStructureRef ?? structure
    const clone = _structure.value.clone()
    const branch = findBranchByNodeId(clone, id)

    const index = branch.children.indexOf(id)
    // TODO: Remove node should be done in one go. At the moment is done in two steps.
    // 1st step: Remove node(id) from the base branch
    branch.children.splice(index, 1)
    // 2nd step: Remove the descendants from the structure (structure.branches)
    // clone.branches = without(getBranchAndDescendants(clone.branches, id), clone.branches)
    clone.branches = removeDescendants(clone.branches, getBranchAndDescendants(clone.branches, id))

    clone.commit()

    if (!local) {
      await _structure.value.save({ data: { branches: clone.branches } })
    }
    // If there is a selection of nodes made,
    // we need to update the selected nodes list after one node is removed
    removeNodeFromSelection(id)
  }
}

export const useStructureRemoveNodes = () => {
  const structure = useStructure()
  /**
   * Removes multiple nodes from a document's structure at once
   * @param {Object} param
   * @param {Array<String>} param.ids The list of node ids to be removed
   * @param {String} param.parent Id of provided to nodes. Used to find branch
   * @param {String} param.local Should the modifications only be performed on the client?
   */
  const removeNodes = async ({ ids = [], parent = 'root', local = false }) => {
    const clone = structure.value.clone()
    const branch = findBranchByParent(clone, parent)
    // TODO: Remove nodes should be done in one go. At the moment is done in two steps.
    // 1st step: Remove nodes(ids) from the base branch
    branch.children = without(ids, branch.children)
    // 2nd step: Remove the descendants from the structure (structure.branches)
    // ids.forEach(id => clone.branches = without(
    //   getBranchAndDescendants(clone.branches, id),
    //   clone.branches
    // ))
    ids.forEach(id => {
      clone.branches = removeDescendants(
        clone.branches,
        getBranchAndDescendants(clone.branches, id)
      )
    })

    clone.commit()
    if (!local) {
      await structure.value.save({ data: { branches: clone.branches } })
    }
  }

  return removeNodes
}

export const useStructureInsertNode = () => {
  const structure = useStructure()

  return async ({
    id,
    parentNode,
    /** Node will be inserted in this structure, if provided. If not, current document's structure
     *  will be used (`inject('document')`) */
    targetStructureRef = null,
    /** Node will be inserted last in `parentNode`'s branch if not provided */
    index = null,
    isNodeGroup = false,
    local = false,
    clone = null,
  }) => {
    const _structure = targetStructureRef ?? structure
    const structureClone = clone || _structure.value.clone()
    const branch = findBranchByParent(structureClone, parentNode)
    const _index = index ?? branch.children.length // Place last in branch if no index provided

    if (_index < 0 || _index > branch.children.length) {
      throw new Error(
        `invalid node=${parentNode} index=${_index} branchSize=${branch.children.length}`
      )
    }
    if (_index === 0) {
      branch.children.unshift(id)
    } else if (_index === branch.children.length) {
      branch.children.push(id)
    } else {
      branch.children.splice(_index, 0, id)
    }

    if (isNodeGroup) {
      structureClone.branches.push({
        node: id,
        children: [],
      })
    }

    structureClone.commit()

    if (!local) {
      await _structure.value.save({ data: { branches: structureClone.branches } })
    }
  }
}


export const useStructureGroupNodes = () => {
  const createNode = useCreateNode()
  const structure = useStructure()

  return async ({ nodeIds, fromParent = 'root', local = false }) => {
    const clone = structure.value.clone()
    const save = debounce(data => structure.value.save({ data }), 1000)
    // NOTE: Group selected nodes from other groups currently not supported
    if (fromParent !== 'root') {
      return
    }

    const fromBranch = findBranchByParent(clone, fromParent)
    // Sort the nodes according to their order in the doc (currently group nodes only from root.)
    const nodeIdsSet = new Set(nodeIds)
    const sortedNodeIds = fromBranch.children.filter(nodeId => nodeIdsSet.has(nodeId))
    //  Find the index of the first node selected (array from 1st step)
    const index = fromBranch.children.indexOf(nodeIds[0])

    // Create group to move the nodes into, need the id in order to find group branch (toBranch)
    const { _id: toParent } = await createNode({
      index,
      parentNodeId: fromParent,
      nodeData: { category: NODE_CATEGORY.NodeGroupBasic },
      structureClone: clone,
    })

    // Remove nodes from current parent
    fromBranch.children = without(nodeIds, fromBranch.children)
    // Add sorted nodes to group node
    const toBranch = findBranchByParent(clone, toParent)
    toBranch.children = sortedNodeIds

    clone.commit()

    if (!local) {
      save({ branches: clone.branches })
    }
  }
}

export const useStructureUngroupNodes = () => {
  const structure = useStructure()

  return ({ nodeId, local = false }) => {
    const clone = structure.value.clone()
    const save = debounce(data => structure.value.save({ data }), 1000)
    // Find parent branch of the group node
    const parentBranch = findBranchByNodeId(clone, nodeId)
    // Find the group node branch
    const fromBranch = findBranchByParent(clone, nodeId)
    // FInd group node index to insert in parent as children the nodes from group node
    const index = indexOf(nodeId, parentBranch.children)
    // Add the nodes from group node to parent
    parentBranch.children = insertAll(index, fromBranch.children, parentBranch.children)
    // Remove the group node from the parent branch
    parentBranch.children = without(nodeId, parentBranch.children)
    // Remove the group from the structure branches
    clone.branches = clone.branches?.filter(branch => branch.node !== nodeId)

    clone.commit()

    if (!local) {
      save({ branches: clone.branches })
    }
  }
}

// CUT/PASTE nodes between different branches (target !== source)
// NOTE: currently used for CUT from/to group
export const useStrucureCutNodes = () => {
  const structure = useStructure()

  return ({ nodeIds, targetParent, index, local = false }) => {
    const clone = structure.value.clone()
    const save = debounce(data => structure.value.save({ data }), 1000)
    // Find the source branch & remove the nodes
    const sourceBranch = findBranchByNodeId(clone, nodeIds[0])
    sourceBranch.children = without(nodeIds, sourceBranch.children)
    // Find the target branch and add the nodes
    const targetBranch = findBranchByParent(clone, targetParent)
    targetBranch.children = insertAll(index, nodeIds, targetBranch.children)

    clone.commit()

    if (!local) {
      save({ branches: clone.branches })
    }
  }
}

