import { clone, dissoc, dissocPath, has, isEmpty } from 'ramda'

export default function paramsForServer(params = {}, ...keys) {
  if (!keys.length) {
    throw new Error('no keys provided')
  }

  let newParams = clone(params)
  newParams.query ??= {}
  newParams.query.$client ??= {}

  keys.forEach(key => {
    if (has(key, params)) {
      newParams.query.$client[key] = params[key]
      newParams = dissoc(key, newParams)
    }
  })

  if (isEmpty(newParams.query.$client)) {
    newParams = dissocPath(['query', '$client'], newParams)
  }

  return newParams
}
