
<ListLightDropdownItem
  :id="id"
  :label="label"
  :icon="icon"
  @select="$emit('select')"
>
  <Popover
    v-if="hasDefaultSlot"
    :target="id"
    triggers="hover"
    placement="right"
    :delay="{ show: 500, hide: 0 }"
    :no-fade="true"
    :no-padding="true"
    mode="rich"
  >
    <div
      v-if="image"
      :class="$style.image"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    ></div>
    <div :class="$style.wrapper">
      <h3>{{ label }}</h3>
      <p>
        <slot />
      </p>
    </div>
  </Popover>
  <b-badge
    v-if="newBadge"
    variant="new"
    :class="$style['new-badge']"
  >
    NEW
  </b-badge>
</ListLightDropdownItem>
