
<div v-fragment>
  <slot
    v-bind="{
      remove,
      update,
      duplicate,
      move,
      download,
      share,
      saveAsReusableBlock,
      changeFonts,
      approve
    }"
  />
  <!-- share modal -->
  <DocumentEditorShareModal
    v-if="modalState.share"
    v-model="modalState.share"
    :document="document"
    size="lg"
  />

  <!-- move modal -->
  <ProjectFolderSelectModal
    v-if="modalState.move"
    key="header-folder-select-modal"
    v-model="modalState.move"
    title="Select destination"
    :hidden-folder-ids="[document._id]"
    ok-label="Move"
    :project-id="document.project"
    @select="onMoveTargetFolderSelected"
  />

  <!-- document download -->
  <portal v-if="modalState.download" to="right-drawer">
    <DocumentDownload
      :document="document"
      @close="modalState.download = false"
    />
  </portal>

  <!-- document fonts -->
  <DocumentFormModal
    v-if="modalState.fonts"
    v-model="modalState.fonts"
    :document="document"
    @save="modalState.fonts = false"
  />

  <!-- duplicate modal -->
  <DocumentCopyModal
    v-if="modalState.duplicate"
    v-model="modalState.duplicate"
    :document="document"
    @success="onDuplicateSuccess"
  />
</div>
