
<div>
  <div>
    <h2 class="text-gray-700 mb-4">PDF / Print Top Margin</h2>
    <p class="text-12 text-gray-400 mb-8">Visible only on PDF or Print</p>
  </div>
  <div class="flex items-center py-4">
    <label
      :for="componentID"
      class="mr-24 text-gray-600 text-14"
    >
      Margin
    </label>
    <RangeSlider
      v-model="printMarginTop"
      :min="minRange"
      :max="maxRange"
    />
    <div class="flex-none ml-8">
      <b-input
        :id="componentID"
        v-model="printMarginTop"
        type="number"
        step="5"
        :min="minRange"
        :max="maxRange"
        size="sm"
        class="form-control-number w-46"
        number
        required
      />
    </div>
  </div>
</div>
