<template>
  <b-modal
    no-fade
    :visible="value"
    :dialog-class="[$style['basic-modal-dialog'], dialogClass, dialogSizeClass]"
    :size="size"
    :content-class="[$style['basic-modal-content'], contentClass]"
    :footer-class="[$style['basic-modal-footer'], footerClass]"
    :body-class="[$style['basic-modal-body'], bodyClass, bodySizeClass]"
    :header-class="[$style['basic-modal-header'], headerClass]"
    :no-close-on-backdrop="!shouldCloseOnBackdrop"
    :close-text="closeText"
    v-bind="$attrs"
    v-on="$listeners"
    @hide="onHide"
    @click.stop
  >
    <template #modal-header="bindings">
      <slot
        name="header"
        v-bind="bindings"
      />
      <div :class="[$style['basic-modal-header-inner'], 'modal-header-inner']">
        <Button
          v-if="showBack"
          variant="icon-isolated"
          :class="[$style['basic-modal-back'], 'modal-back rounded-full p-8']"
          @click="$emit('back')"
        >
          <span class="w-24 text-24 icon-arrow-left-util" />
        </Button>
        <slot name="header-buttons" />
        <div class="flex flex-col items-center w-full">
          <h2
            v-if="title && !hasHeaderSlot"
            class="w-full"
            :class="[
              $style['basic-modal-title'],
              titleClass,
              'modal-title',
              showBack && 'pl-5 pr-5',
            ]"
          >
            <IconGraphic
              v-if="titleIcon"
              :icon="titleIcon"
              :class-name="titleIconClass"
              :class="$style['modal-header-icon']"
              type="icon-graphic-smart"
            />
            <div>{{ title }}</div>
          </h2>
          <h4 v-if="subtitle" class="text-gray-500">{{ subtitle }}</h4>
        </div>
        <Button
          :variant="!closeText ? 'icon-isolated' : 'neutral'"
          :disabled="noClose"
          :size="!closeText ? 'md' : 'sm'"
          :class="[
            $style['basic-modal-close'],
            !closeText ? 'modal-close !p-0 rounded-full w-36 h-36' : '',
            closeClass,
          ]"
          @click.stop="bindings.close()"
        >
          <span
            v-if="closeText"
            class="inline-block pl-4 mr-6"
          >{{ closeText }}</span>
          <span class="w-24 text-24 icon_v2-so_close"></span>
        </Button>
      </div>
    </template>
    <template #default="bindings">
      <slot
        name="body"
        v-bind="bindings"
      />
    </template>
    <template #modal-footer="bindings">
      <slot
        name="footer"
        v-bind="bindings"
      />
    </template>
  </b-modal>
</template>

<script>
import { computed, useCssModule, defineComponent } from '@vue/composition-api'
import modal from '@/lib/mixins/modal'
import IconGraphic from '@/components/Blocks/Graphics/IconGraphic.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'ModalBasic',
  components: {
    IconGraphic,
    Button,
  },
  mixins: [modal],
  props: {
    value: Boolean,
    visible: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    shouldCloseOnBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const $style = useCssModule()
    const hasHeaderSlot = computed(() => Boolean(context.slots.header));
    const dialogSizeClass = computed(() => (props.size ? $style[`dialog-size-${props.size}`] : null))
    const bodySizeClass = computed(() => (props.size ? $style[`body-size-${props.size}`] : null))
    const onHide = () => context.emit('input', false)

    return {
      hasHeaderSlot,
      onHide,
      dialogSizeClass,
      bodySizeClass,
    }
  },
// ``  computed: {
//     hasHeaderSlot() {
//       return Boolean(this.$slots.header)
//     },
//   },
//   methods: {
//     onHide() {
//       this.$emit('input', false)
//     },
//   },
})
</script>
<style lang="scss" module>
@import './ModalBasic.scss';

.dialog-size-lg {
  max-width: 924px;
}

.body-size-lg {
  // max-width: 750px + 64px;
  margin-left: auto;
  margin-right: auto;
}
</style>
