<template>
  <portal to="page-header">
    <div class="flex justify-between h-full -ml-16 flex-1">
      <b-nav class="flex items-stretch">
        <b-nav-item
          v-for="(tab, index) in tabs"
          :key="tab.id"
          :to="tab.to"
          :exact="!tab.activeOnChildren"
          class="flex items-stretch group border-r-transparent"
          :class="index > 0 && 'border-l-transparent'"
          link-classes="
            flex items-center gap-x-4 p-0 px-18
            flex-col xl:flex-row justify-center
            xl:px-32 text-gray-500 hover:text-gray-900
            font-semibold rounded-none dark:hover:text-white/70
          "
          :active-class="[
            'bg-white text-gray-900 border-r',
            'dark:bg-darkGray-1000 dark:text-white/80 dark:border-r-darkGray-800',
            index > 0 ? 'border-l dark:border-l-darkGray-800' : ''
          ].join(' ')"
        >
          <!-- active-class="bg-white group-first:rounded-tl-lg shadow-sm text-gray-900" -->
          <span v-if="tab.icon" :class="tab.icon" />
          <span class="text-12 xl:text-16">
            {{ tab.translationKey ? $t(tab.translationKey) : tab.label }}
          </span>
        </b-nav-item>
      </b-nav>
      <div
        v-if="Boolean($slots.actions)"
        class="flex items-center gap-8"
      >
        <slot name="actions" />
        <PageHeaderSearch
          v-if="search"
          v-model="keyword"
          :show-result="showSearchResult"
          :placeholder="searchPlaceholder"
          @update:show-result="val => $emit('update:showSearchResult', val)"
          @close="closeSearchResult"
        >
          <slot name="search-result" />
        </PageHeaderSearch>
      </div>
    </div>
  </portal>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import PageHeaderSearch from '@/components/PageHeaderSearch.vue'

export default defineComponent({
  name: 'TabsPageHeader',
  components: { PageHeaderSearch },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchKeyword: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    showSearchResult: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const keyword = computed({
      set(value) {
        context.emit('update:searchKeyword', value)
      },
      get() {
        return props.searchKeyword
      },
    })

    const closeSearchResult = () => {
      context.emit('update:showSearchResult', false)
      keyword.value = ''
    }

    return {
      keyword,
      closeSearchResult,
    }
  },
})
</script>
