import useUpgradeDialog from './useUpgradeDialog';
import useCurrentOrganization from './useCurrentOrganization';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

export default function useFeatureEnabled() {
  const upgradeDialog = useUpgradeDialog()
  const organization = useCurrentOrganization()

  const showUpgradeDialog = () => upgradeDialog({
    message: 'This feature is not included in your subscription plan.',
  })


  const checkFeatureEnabled = flag => {
    const isEnabled = organization.value?.featureFlags?.[flag] === true

    if (!isEnabled) {
      showUpgradeDialog()
      return false
    }

    return true
  }

  return {
    checkFeatureEnabled,
  }
}
