import { pick } from 'ramda'
import { noop } from 'ramda-adjunct'
import { unref } from '@vue/composition-api'
import useNotification from '@/v2/lib/composition/useNotification'
import useForm from './useForm'

export default function useFormFeathers({
  model,
  fields,
  validation,
  onSave = noop,
  successMessage = null,
  autoCommit = false,
  vuelidateOptions,
} = {}) {
  const notification = useNotification()

  const form = useForm({
    state: model,
    validation,
    onSubmit: async () => {
      const _fields = unref(fields)
      const _model = unref(model)

      if (Array.isArray(_fields)) {
        await _model.save({ data: pick(_fields, _model) })
      } else {
        await _model.save()
      }

      autoCommit && _model.commit()
      await onSave(_model)

      if (successMessage) {
        notification({ message: successMessage })
      }
    },
    vuelidateOptions,
  })

  return form
}
