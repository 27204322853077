
<div
  :class="{
    'is-share-view': isViewer,
    'is-edit-view': isEditor,
    'document-page': document.category===DOCUMENT_CATEGORY.Document && !hidePageBorder
  }"
>
  <slot />
</div>
