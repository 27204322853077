<template>
  <div class="flex flex-1 flex-grow w-full">
    <div
      :class="[
        'fs-list-wrapper',
        size,
      ]"
      role="link"
      @click="event => $emit('select', event)"
    >
      <div class="flex items-center justify-start flex-1">
        <!-- ICON + NAME -->
        <div
          class="flex items-center"
        >
          <!-- ################## -->
          <!-- ICON -->
          <!-- ################## -->

          <!-- FILE WITH ICON -->
          <div v-if="!isImageFile && isFile" class="fs-list-icon-wrapper">
            <!-- <IconDocument v-if="isFile" class-name="w-40 h-40 scale-50" /> -->
            <FileIcon
              v-if="isFile"
              :content-type="contentType"
              :size="size==='l' ? '36' : '24'"
            />
            <!-- LINKED FILE??? -->
            <div
              v-else
              class="fs-list-icon-wrapper"
              :style="{ color: document.color || theme.textColor}"
            >
              <span
                :class="document.icon"
                class="w-36 text-36"
              ></span>
            </div>
          </div>
          <!-- IMAGE -->
          <div
            v-if="isImageFile"
            class="relative w-36 fs-card--doc-img"
          >
            <div class="absolute flex items-center justify-center w-full h-full p-px">
              <img :src="document.file.url" class="object-contain max-h-full" />
            </div>
          </div>

          <!-- NOT FILE -->
          <!-- NOT IMAGE -->
          <div v-if="!isFile && !isImageFile" class="fs-list-icon-wrapper">
            <span
              :class="icon || 'icon-document'"
              class="w-24 text-24"
              :style="{ color: document.color || theme.textColor}"
            ></span>
          </div>

          <!-- EMD ICON -->
          <!-- ################## -->
          <div class="fs-card-title-list">
            <WrappedTitle
              :text="document.title"
              :pre-size="size==='m' ? 30 : 48"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
// import tinycolor from 'tinycolor2'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FileIcon from '@/components/Blocks/File/FileIcon.vue'
import { isFileTypeImage } from '@/v2/lib/helpers/validators'
import WrappedTitle from '@/components/WrappedTitle.vue'

export default defineComponent({
  name: 'FolderItemList',
  components: {
    FileIcon,
    WrappedTitle,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'l',
    },
  },
  setup(props) {
    const isClientPortal = inject('isClientPortal', false)

    const isPublicFolder = computed(() => props.document.category === CATEGORY.ProjectPublicFolder)

    // const accentColorSpace = computed(() => tinycolor(props.document.color).monochromatic(3))
    // const mostReadable = computed(() => tinycolor.mostReadable(
    //   props.document.color,
    //   [
    //     ...accentColorSpace.value,
    //     '#FFFFFF',
    //   ],
    //   {
    //     level: 'AA',
    //     size: 'large',
    //   }
    // ).toHexString())

    const theme = computed(() => ({
      textColor: props.document.color || props.document?.sharingClientEnabled ? 'var(--cp-accent)' : 'var(--cp-secondary-color)',
    }))

    const icon = computed(() => {
      if ([CATEGORY.Folder, CATEGORY.ProjectRootFolder].includes(props.document.category)) {
        return 'icon_v2-so_folder'
      }
      if ([CATEGORY.Document].includes(props.document.category)) {
        return props.document.icon || 'icon-document-custom'
      }
      if ([CATEGORY.ProjectPublicFolder].includes(props.document.category)) {
        return props.document.icon || 'icon_v2-so_shared'
      }
      if ([CATEGORY.File, CATEGORY.FileLink].includes(props.document.category)) {
        return 'icon_v2-so_file'
      }
      return props.document.icon
    })

    const totalActiveActionItems = computed(() => props.document.countActionItemsOpenPublic
     + props.document.countActionItemsResolvedPublic)

    const showActionItemsCount = computed(() => totalActiveActionItems.value)

    const showCommentsCount = computed(() => props.document.countComments)

    const isImageFile = computed(() => isFileTypeImage(props.document?.file))
    const isFile = computed(() => props.document.category === CATEGORY.File && !isImageFile.value)
    const contentType = computed(() => props.document?.file?.contentType ?? null)

    return {
      isPublicFolder,
      isClientPortal,
      theme,
      icon,
      showActionItemsCount,
      showCommentsCount,
      totalActiveActionItems,
      isImageFile,
      isFile,
      contentType,
    }
  },
})
</script>
<style lang="postcss" scoped>

.fs-card--doc-img::after {
  @apply bg-white dark:bg-darkGray-800;
  @apply border border-gray-200 block dark:border-darkGray-900;
  content: '';
  padding-bottom: 100%;
}

</style>
