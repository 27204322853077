<template>
  <div>
    <DocumentContentBlockQASettings
      :description="description"
      :has-title="hasTitle"
      :popover-target-id="popoverTargetId"
      @input="handleSettingsToggle"
    />
  </div>
</template>

<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQALongTextSettings',
  components: {
    DocumentContentBlockQASettings,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const bindings = useContentBlockSettings(props, ['description', 'hasTitle'])
    const handleSettingsToggle = object => {
      bindings[object.type].value = object.value
    }
    return {
      ...bindings,
      handleSettingsToggle,
    }
  },
}
</script>

<style lang="scss" scoped></style>
