<template>
  <DocumentContentBlockQASettings
    :description="description"
    :has-title="hasTitle"
    :prevent-popover-close="true"
    :popover-target-id="popoverTargetId"
    @input="handleSettingsToggle"
  >
    <SettingsSwitch
      v-model="hasNotes"
      tooltip="Enable client notes"
      label="Notes"
    />
    <div class="flex flex-row items-center justify-start my-8">
      <span class="text-11 text-gray-700 uppercase font-semibold bg-gray-100 px-4 py-1 rounded-sm">
        Labels
      </span>
      <span class="block h-2 bg-gray-100 grow ml-4" />
    </div>
    <SettingsInput
      v-model="leftLabel"
      label="Left"
      icon="util-label-l"
    />
    <SettingsInput
      v-model="rightLabel"
      label="Right"
      icon="util-label-r"
    />
  </DocumentContentBlockQASettings>
</template>
<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import SettingsInput from '@/components/SettingsInput.vue'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQASliderSettings',
  components: {
    DocumentContentBlockQASettings,
    SettingsSwitch,
    SettingsInput,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    // bindings props are refs
    const bindings = useContentBlockSettings(props, [
      'description',
      'hasTitle',
      'hasSliderLabel',
      'leftLabel',
      'rightLabel',
      'sliderLabel',
      'hasNotes',
    ])
    const handleSettingsToggle = object => {
      bindings[object.type].value = object.value
    }

    return {
      ...bindings,
      handleSettingsToggle,

    }
  },
}
</script>
<style lang="scss" scoped></style>
