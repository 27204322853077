
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  :root-class="[
    'node-image',
    noPaddingTop && $style['no-top-border-radius'],
  ]"
  :section-class="['node-image-section']"
  :config-override="{
    stylingToolbar: {
      features: {
        objectAlign: true,
        sizes: true,
      },
    },
    styling: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }"
>
  <template #default="{ styling, textVariant, isHover }">
    <DocumentContentBlockImage
      :content-block="contentBlock"
      :node-id="node._id"
      :styling="styling"
      :text-variant="textVariant"
      :is-hover="isHover"
      @update="data => $emit('update', data)"
      @update-node="data => $emit('update-node', data)"
      @async-save-start="$emit('async-save-start')"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
