/**
 * Throttles the execution of the provided function by the desired amount of time
 * @param {Function} fn The function for which to throttle the execution
 * @param {Number} interval The minimum amount of time in milliseconds that should pass before the
 * next `fn` execution can take place
 */
export const throttle = (fn, interval = 500) => {
  /** Timestamp of last execution of `fn` */
  let lastTime = null
  /** Id for `setTimeout` */
  let timer = null
  return (...args) => {
    clearTimeout(timer) // Prevent delayed execution, if any
    const time = Date.now()
    /** Amount of time which passed since last `fn` execution */
    const delta = time - lastTime
    if (!lastTime || delta >= interval) {
      // If the function hasn't been called yet or if the wait amount elapsed, call `fn`
      fn(...args)
      lastTime = time // Remember last execution time
    } else {
      // Delay function execution
      // timer = setTimeout(fn, Math.max(500, delta), ...args)
      timer = setTimeout(fn, interval, ...args)
    }
  }
}
