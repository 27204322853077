<template>
  <div class="flex w-full items-center gap-x-20">
    <div class="flex-1 flex items-center">
      <MainHeaderLogo
        :label="organization.name"
        :logo-url="organizationLogoUrl"
      />
      <template v-if="client">
        <span class="icon_v2-so_plus mx-8 text-gray-400"></span>
        <MainHeaderLogo
          :label="client.name"
          :logo-url="clientLogoUrl"
        />
      </template>
    </div>
    <div v-if="Boolean($slots.nav)">
      <slot name="nav" />
    </div>
    <div>
      <slot name="user" />
    </div>
    <OrganizationSwitcher v-if="!noSwitch" />
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import MainHeaderLogo from '@/components/MainHeaderLogo.vue'
import OrganizationSwitcher from '@/components/OrganizationSwitcher.vue'

export default defineComponent({
  name: 'MainHeader',
  components: {
    MainHeaderLogo,
    OrganizationSwitcher,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      default: null,
    },
    noSwitch: {
      type: Boolean,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const organizationLogoUrl = computed(() => (props.darkTheme
      ? (props.organization.logoDarkUrl || props.organization.logoUrl)
      : (props.organization.logoUrl || props.organization.logoDarkUrl)))

    const clientLogoUrl = computed(() => (props.darkTheme
      ? (props.client.logoDark || props.client.logoLight)
      : (props.client.logoLight || props.client.logoDark)))

    return {
      organizationLogoUrl,
      clientLogoUrl,
    }
  },
})
</script>
