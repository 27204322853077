import { ref } from '@vue/composition-api'
import { head, last, keys, values } from 'ramda'

export const useNodeWidth = () => {
  const width = ref(null)

  const load = id => {
    const node = document.getElementById(`node-${id}`)
    // Because we care about the actual width of the content, not just the wrapper
    // this way we take into account S/M/L sizes
    const blockSection = node?.querySelector('.block-section')
    width.value = blockSection?.offsetWidth
    return width.value
  }
  return {
    width,
    load,
  }
}

const widthList = [
  { XXS: [0, 320] },
  { XS: [321, 480] },
  { S: [481, 576] },
  { M: [577, 720] },
  { L: [721, 860] },
  { XL: [861, 960] },
  { XXL: [961] },
]

export const widthSize = obj => widthList.reduce((acc, size) => {
  const item = Number(head(values(obj)))
  if (item >= head(size[keys(size)]) && item <= last(size[keys(size)])) {
    return { ...acc, [keys(obj)]: head(keys(size)) }
  }
  if (item >= head(size[keys(size)]) && size[keys(size)].length === 1) {
    return { ...acc, [keys(obj)]: head(keys(size)) }
  }
  return acc
}, {})
