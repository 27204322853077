import { dissoc, pipe, max, over, lensProp, add, defaultTo } from 'ramda'
import { docSidebarTypes, docSidebarTitles } from '@/types/document-sidebar-tabs'

// The below rule has been disabled as Vuex mutations are supposed to directly alter the
// `state` which they receive as a param
/* eslint-disable no-param-reassign */
import { CB_ACTIONS } from '@/types/clipboard'
/** @typedef {import('vuex').ActionContext} ActionContext */
const updateCounterWith = fn => pipe(defaultTo(0), fn, max(0))
const setCounterForKey = (key, offset) => over(lensProp(key), updateCounterWith(add(offset)))


const sessionContentLibraryInit = () => ({
  contentDocumentCategory: null,
  documentId: null,
  parentNodeId: null,
  index: null,
})

const contextMenuDefaults = () => ({
  /** The ID of the node for which the context menu was opened */
  nodeId: null,
  /** Top (y) position where menu should be opened */
  top: null,
  /** Left (x) position where menu should be opened */
  left: null,
})

const clipboardDefaults = () => ({
  /** The actual content of the clipboard. Holds a list of node ids on which an `action` was
  * applied */
  content: [],
  /** The action which was triggered in order to populate the `content`.
  * Can be either `copy` or `cut` */
  action: '',
})

const commentsSidebarDefaults = () => ({
  isShown: false,
  nodeId: null,
})


export default {
  namespaced: true,
  state: {
    hoverNodeId: null,
    focusNodeId: null,
    menuOpenNodeId: null,
    lastCreatedNodeId: null,
    focusDisabled: false,
    visibleNodes: {},
    modalDocumentId: null,
    previewDocumentId: null,
    sessionContentLibrary: sessionContentLibraryInit(),
    contextMenu: contextMenuDefaults(),
    /** A list of nodes marked for multiple selection */
    selectedNodes: [],
    selectedNodeParent: '',
    clipboard: clipboardDefaults(),
    commentsSidebarStatus: commentsSidebarDefaults(),
    showNotifications: false,
    /** `true` if TipTap's bubble menu for editing Rich Text sections is visible,
     * `false` otherwise */
    isTextEditorToolbarVisible: false,
    nodesWidth: {},
    documentSidebarTab: docSidebarTypes.ACTION_ITEMS,
    documentSidebarStatus: {
      isExpanded: false,
      tab: null,
      title: docSidebarTitles[docSidebarTypes.ACTION_ITEMS],
      commentNodeId: null,
      actionItemNodeId: null,
    },
    filesUploadingNode: {},
  },
  getters: {
    /** `true` if clipboard is empty, `false` otherwise */
    isClipboardEmpty(state) {
      return !state.clipboard.content.length
    },
  },
  mutations: {
    setHover(state, nodeId) {
      state.hoverNodeId = nodeId
    },
    setFocus(state, nodeId) {
      state.focusNodeId = nodeId
      // Clear `contextMenu` data to ensure any opened context menu is closed
      state.contextMenu = contextMenuDefaults()
    },
    setFocusDisabled(state, val) {
      state.focusDisabled = val
    },
    setMenuOpen(state, nodeId) {
      state.menuOpenNodeId = nodeId
    },
    setLastCreated(state, nodeId) {
      state.lastCreatedNodeId = nodeId
    },
    setNodeVisibility(state, { nodeId, isVisible }) {
      state.visibleNodes = { ...state.visibleNodes, [nodeId]: isVisible }
    },
    startSessionContentLibrary(state, session) {
      Object.assign(state.sessionContentLibrary, session)
    },
    closeSessionContentLibrary(state) {
      Object.assign(state.sessionContentLibrary, sessionContentLibraryInit())
    },
    setModalDocumentId(state, documentId) {
      state.modalDocumentId = documentId
    },
    setPreviewDocumentId(state, documentId) {
      state.previewDocumentId = documentId
    },
    setContextMenuData(state, { nodeId, top, left }) {
      state.contextMenu = { nodeId, top, left }
    },
    setSelectedNodes(state, nodes) {
      if (Array.isArray(nodes)) state.selectedNodes = nodes
      else console.error('The list of selected nodes must be an array')
    },
    setSelectedNodeParent(state, nodeParent = '') {
      if (nodeParent === '' && state.selectedNodes.length) {
        return
      }
      state.selectedNodeParent = nodeParent
    },
    setClipboardData(state, newClipboardData) {
      state.clipboard = newClipboardData
    },
    setCommentsData(state, { isShown, nodeId }) {
      state.commentsSidebarStatus = { isShown, nodeId }
    },
    setDocumentSidebarStatus(state, { isExpanded, tab, title, commentNodeId, actionItemNodeId }) {
      state.documentSidebarStatus = {
        // ...state.documentSidebarStatus,
        isExpanded,
        tab,
        title,
        commentNodeId,
        actionItemNodeId,
      }
    },
    // setActionItemsData(state, { isExpanded, nodeId }) {
    //   state.documentSidebarStatus = {
    //     ...state.documentSidebarStatus,
    //     isExpanded,
    //     actionItemNodeId: nodeId,
    //   }
    // },
    setShowNotifications(state, newVal) {
      state.showNotifications = newVal
    },
    setTextEditorToolbarVisibility(state, newValue) {
      state.isTextEditorToolbarVisible = newValue
    },
    setNodeWidth(state, nodeWidth) {
      state.nodesWidth = nodeWidth
    },
    removeNodeWidth(state, id) {
      state.nodesWidth = dissoc(id, state.nodesWidth)
    },
    setFilesUploadingNode(state, { nodeId, isUploading }) {
      const setCounter = setCounterForKey(nodeId, isUploading ? 1 : -1)
      state.filesUploadingNode = setCounter(state.filesUploadingNode)
    },
  },
  actions: {
    /** Clears all data regarding the ContextMenu so that any open instance is closed */
    clearContextMenuData({ commit }) {
      commit('setContextMenuData', contextMenuDefaults())
    },
    /**
     * Adds a node to the list of selected nodes
     * @param {ActionContext} param0
     * @param {String} nodeId The ID of the new node
     */
    addNodesToSelection({ commit, state }, nodeIds = []) {
      commit('setSelectedNodes', [...state.selectedNodes, ...nodeIds])
    },
    /**
     * Removes a node from the list of selected nodes
     * @param {ActionContext} param0
     * @param {String} nodeId The ID of the node to be removed
     */
    removeNodeFromSelection({ commit, state }, nodeId) {
      commit('setSelectedNodes', state.selectedNodes.filter(n => n !== nodeId))
    },
    /**
     * Removes all nodes from the list of selected nodes
     * @param {ActionContext} param0
     */
    clearNodeSelection({ commit }) {
      commit('setSelectedNodes', [])
      commit('setSelectedNodeParent', '')
    },
    copyNodesUsingClipboard({ commit }, nodeIds = []) {
      commit('setClipboardData', { content: nodeIds, action: CB_ACTIONS.copy })
    },
    cutNodesUsingClipboard({ commit }, nodeIds = []) {
      commit('setClipboardData', { content: nodeIds, action: CB_ACTIONS.cut })
    },
    /** @param {import('vuex').ActionContext} param0 */
    clearClipboard({ commit }) {
      commit('setClipboardData', clipboardDefaults())
    },
    toggleComments({ commit, state }) {
      commit('setCommentsData', {
        nodeId: state.commentsSidebarStatus.isShown ? null : state.commentsSidebarStatus.nodeId,
        isShown: !state.commentsSidebarStatus.isShown,
      })
      commit('setShowNotifications', false)
    },
    toggleDocumentSidebar({ commit, state }, tab) {
      if (tab !== null && state.documentSidebarStatus.tab === tab) {
        commit('setDocumentSidebarStatus', {
          // ...state.documentSidebarStatus,
          tab: null,
          title: docSidebarTitles[tab],
          isExpanded: !state.documentSidebarStatus.isExpanded,
          commentNodeId: null,
          actionItemNodeId: null,
        })
      } else {
        commit('setDocumentSidebarStatus', {
          // nodeId: state.documentSidebarStatus.isExpanded
          // ? null : state.documentSidebarStatus.commentNodeId,
          // ...state.documentSidebarStatus,
          isExpanded: !!tab,
          tab: tab || null,
          title: docSidebarTitles[tab],
          commentNodeId: null,
          actionItemNodeId: null,
        })
      }
    },
    toggleActionItemsWithNode({ commit, state }, nodeId) {
      commit('setDocumentSidebarStatus', {
        isExpanded: state.documentSidebarStatus.actionItemNodeId !== nodeId,
        tab: docSidebarTypes.ACTION_ITEMS,
        title: docSidebarTitles[docSidebarTypes.ACTION_ITEMS],
        commentNodeId: null,
        actionItemNodeId: state.documentSidebarStatus.actionItemNodeId === nodeId ? null : nodeId,
      })
    },
    toggleCommentsWithNode({ commit, state }, nodeId) {
      commit('setDocumentSidebarStatus', {
        isExpanded: state.documentSidebarStatus.commentNodeId !== nodeId,
        tab: docSidebarTypes.COMMENTS,
        title: docSidebarTitles[docSidebarTypes.COMMENTS],
        commentNodeId: state.documentSidebarStatus.commentNodeId === nodeId ? null : nodeId,
        actionItemNodeId: null,
      })
    },
    // toggleCommentsWithNode({ commit, state }, nodeId) {
    //   commit('setCommentsData', {
    //     // set data if nodeId not visible, otherwise clear data
    //     // so this works as a toggle
    //     nodeId: state.commentsSidebarStatus.nodeId === nodeId ? null : nodeId,
    //     // show if not already showing nodeId
    //     isShown: state.commentsSidebarStatus.nodeId !== nodeId,
    //   })
    //   commit('setShowNotifications', false)
    // },
    resetCommentsNode({ commit, state }) {
      commit('setCommentsData', {
        nodeId: null,
        isShown: state.commentsSidebarStatus.isShown,
      })
    },
    toggleNotificationsSidebar({ commit, state }) {
      commit('setShowNotifications', !state.showNotifications)
      commit('setCommentsData', { nodeId: null, isShown: false })
    },
    addNodeWidth({ commit, state }, payload) {
      commit('setNodeWidth', { ...state.nodesWidth, ...payload })
    },
    clearNodesWidth({ commit }) {
      commit('setNodeWidth', {})
    },
    removeNodesWidth({ commit }, ids = []) {
      ids.forEach(nodeId => commit('removeNodeWidth', nodeId))
    },
  },
}
