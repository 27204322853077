import { has } from 'ramda'
import { mergeDeepRightAll } from 'ramda-extension'
import { rejectFalsy } from '@/v2/lib/helpers/fp'
import {
  BaseModel,
  makeServicePlugin,
} from '@/v2/services/feathersClientService'
import contentBlocksService, { servicePath } from './contentBlocksService'
import contentBlocksDefaults from './contentBlocksDefaults'
import contentBlocksFields from './contentBlocksFields'
import { CATEGORY } from './contentBlocksTypes'

const mergeDefaults = (data, defaults) => {
  const cbDefaults = defaults[data.category] || {}
  const hasInputTypes = Boolean(cbDefaults.defaultInputType)
  const dataChunks = [defaults.common]

  if (hasInputTypes) {
    const inputType = data.inputType || cbDefaults.defaultInputType
    dataChunks.push(cbDefaults.common, cbDefaults[inputType], data, {
      inputType,
    })
  } else {
    dataChunks.push(cbDefaults, data)
  }

  return mergeDeepRightAll(rejectFalsy(dataChunks))
}

class ContentBlock extends BaseModel {
  static modelName = 'ContentBlock'

  static setupInstance(data, { models }) {
    const { Document } = models.api
    const _data = { ...data };

    if (_data.embeddedDocument$) {
      _data.embeddedDocument$ = new Document(_data.embeddedDocument$)
    }
    return _data;
  }

  static fieldsCreate(data) {
    const cbFields = contentBlocksFields()
    const commonFields = cbFields.common ?? []
    const fields = cbFields[data.category] ?? []

    const specificFields = fields.subcategoryField
      ? [...fields.common, ...fields[data[fields.subcategoryField]] ?? []]
      : fields

    return [...commonFields, ...specificFields]
  }

  static instanceDefaults(data) {
    return has('category', data)
      ? mergeDefaults(data, contentBlocksDefaults())
      : data
  }

  /** `true` if the content block refers to an embedded document, `false` otherwise */
  get isEmbeddedDocument() {
    return [
      CATEGORY.ElementBrief,
      CATEGORY.ElementCaseStudy,
      CATEGORY.ElementPackagedService,
      CATEGORY.ElementTeamBio,
      CATEGORY.ElementEstimate,
      CATEGORY.ElementEmbeddedDocument,
    ].includes(this.category)
  }
}

export default makeServicePlugin({
  Model: ContentBlock,
  service: contentBlocksService,
  servicePath,
})
