<template>
  <div
    v-if="document"
    class="print:hidden h-full relative px-8"
  >
    <!-- CONTENT -->
    <div
      v-if="documentSidebarStatus.isExpanded"
      class="
        absolute top-0 bottom-0 right-full bg-white/90 backdrop-blur-sm
        shadow-xs border-l border-r border-gray-200 w-320 flex flex-col
        dark:bg-darkGray-800 dark:border-darkGray-700
      "
    >
      <!-- HEADER -->
      <div
        class="
          flex items-center min-h-50 z-10 px-8 flex-none bg-gray-100/90 dark:bg-darkGray-700
          border-b border-gray-200 dark:border-darkGray-700
        "
      >
        <div class="flex items-center justify-between flex-1">
          <h3
            class="
              px-8 mr-6 font-semibold text-gray-700 dark:text-darkGray-400
              text-16 tablet:text-18
            "
          >
            <span v-if="documentSidebarStatus.tab === docSidebarTypes.ACTION_ITEMS">
              {{ projectModules.tasks.title || $t('modules.action-items') }}
            </span>
            <span v-else>{{ documentSidebarStatus.title }}</span>
          </h3>
          <portal-target name="doc-sidebar-header" />
        </div>
        <b-button
          variant="icon-isolated"
          size="xs"
          class="btn-icon-xs"
          @click="setTab(null)"
        >
          <span class="w-24 text-gray-700 icon_v2-so_close text-24" />
        </b-button>
      </div>

      <div class="flex-1 overflow-y-auto">
        <!-- TABLE OF CONTENTS -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.CONTENTS"
          :key="document._id"
          class="px-16 pt-16 pb-64"
        >
          <DocumentViewerToc
            variant="desktop"
            :document="document"
          />
        </div>

        <!-- NOTIFICATIONS -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.NOTIFICATIONS"
          class="px-16 pt-16 pb-64"
        >
          <DocumentActivity :document="document._id" />
        </div>

        <!-- ACTION ITEMS -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.ACTION_ITEMS"
          class="px-8"
        >
          <ActionItems
            :is-viewer="false"
            :document="document"
            :organization-id="document.organization"
            :node-id="documentSidebarStatus.actionItemNodeId"
            :project="project || null"
            class-name="block"
            :disable-drag="noTaskReordering"
            dense
            @scroll-to-node="nodeId => scrollToNode(nodeId)"
          />
        </div>

        <!-- COMMENTS -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.COMMENTS"
          class="px-16 h-full pb-16"
        >
          <CommentsSidebar
            :document-id="document._id"
            :is-toggled="true"
            :node-id="documentSidebarStatus.commentNodeId"
            @scroll-to-node="nodeId => scrollToNode(nodeId)"
          />
        </div>

        <!-- ANALYTICS -->
        <div
          v-if="documentSidebarStatus.tab === docSidebarTypes.ANALYTICS && !isClientPortal"
          class="px-16 pt-16 pb-64"
        >
          <DocumentAnalyticsList :document-id="document._id" />
        </div>
      </div>
    </div>
    <!-- END CONTENT -->

    <!-- BUTTONS -->
    <div class="flex flex-col items-center pt-10 gap-10">
      <!--TABLE OF CONTENTS -->
      <div
        v-if="activeItemsIndexed[docSidebarTypes.CONTENTS] && !isEmptyToc"
        :key="document._id"
      >
        <button
          class="sidebar-button"
          :class="documentSidebarStatus.tab === 'contents' && 'sidebar-button-active'"
          @click="setTab(docSidebarTypes.CONTENTS)"
        >
          <span class="relative icon_v2-so_list-view" />
        </button>
      </div>

      <!-- INBOX -->
      <button
        v-if="activeItemsIndexed[docSidebarTypes.NOTIFICATIONS]"
        v-b-tooltip.hover.left.v-info.dh0.ds200="
          showNotifications
            ? `Hide notifications`
            : `See what's new`
        "
        class="sidebar-button "
        :class="documentSidebarStatus.tab === 'notifications' && 'sidebar-button-active'"
        :query="{ document: document._id }"
        @click="setTab(docSidebarTypes.NOTIFICATIONS)"
      >
        <span class="relative icon_v2-so_inbox" />
      </button>

      <!-- ACTION ITEMS -->
      <FeathersCount
        v-if="activeItemsIndexed[docSidebarTypes.ACTION_ITEMS]"
        :key="`actionItems-counter-${document._id}`"
        v-slot="{ count: actionItemsCount }"
        :query="{ document: document._id, isResolved: false }"
        model="ActionItem"
        :events="['created', 'patched', 'removed']"
      >
        <button
          class="sidebar-button"
          :class="documentSidebarStatus.tab === 'actionItems' && 'sidebar-button-active'"
          @click="setTab(docSidebarTypes.ACTION_ITEMS)"
        >
          <span class="relative w-24 text-24 icon_v2-so_action-items" />
          <span
            v-if="actionItemsCount"
            class="
              bg-orange-600 text-white text-12 font-bold leading-none rounded-md
              items-center absolute -top-4 h-18 px-4 z-10 select-none pointer-events-none
              sidebar-counter -right-4
            "
          >{{ actionItemsCount }}</span>
        </button>
      </FeathersCount>

      <!-- COMMENTS -->
      <FeathersCount
        v-if="activeItemsIndexed[docSidebarTypes.COMMENTS]"
        :key="`comments-counter-${document._id}`"
        v-slot="{ count: commentsCount }"
        :query="{
          document: document._id,
          isResolved: false,
        }"
        model="Comment"
      >
        <button
          class="sidebar-button"
          :class="documentSidebarStatus.tab === 'comments' && 'sidebar-button-active'"
          @click="setTab(docSidebarTypes.COMMENTS)"
        >
          <span class="relative icon_v2-so_chat" />
          <span
            v-if="commentsCount"
            class="
              bg-orange-600 text-white text-12 font-bold leading-none rounded-md
              items-center absolute -top-4 h-18 px-4 z-10 select-none pointer-events-none
              sidebar-counter -right-4
            "
          >{{ commentsCount }}</span>
        </button>
      </FeathersCount>

      <!--ANALYTICS -->
      <div v-if="activeItemsIndexed[docSidebarTypes.ANALYTICS]">
        <button
          class="sidebar-button"
          :class="documentSidebarStatus.tab === 'analytics' && 'sidebar-button-active'"
          @click="setTab(docSidebarTypes.ANALYTICS)"
        >
          <span class="relative w-24 icon_v2-so_bar-chart text-24" />
          <span
            v-if="analyticsCount > 0 && !isClientPortal"
            class="
              bg-gray-500 text-white text-12 font-bold leading-none rounded-md
              items-center absolute -top-4 h-18 px-4 z-10 select-none pointer-events-none
              sidebar-counter -right-4
            "
          >{{ analyticsCount }}</span>
        </button>
      </div>
    </div>
    <!-- END BUTTONS -->
  </div>
</template>

<script>
import { defineComponent, computed, watch, inject, onBeforeUnmount } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { docSidebarTypes } from '@/types/document-sidebar-tabs'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import useDocumentNav from '@/v2/lib/composition/useDocumentNav'
import EventBus from '@/event-bus/event-bus'
import { useUser } from '@/v2/services/users/usersCompositions'
import { useProjectModules } from '@/v2/services/projects/compositions'
import CommentsSidebar from '@/components/CommentsSidebar.vue'
import FeathersCount from '@/components/FeathersCount.vue'
import ActionItems from '@/components/ActionItems.vue'
import DocumentAnalyticsList from '@/components/DocumentAnalyticsList.vue'
import DocumentActivity from '@/components/DocumentActivity.vue'
import DocumentViewerToc from '@/components/Document/Viewer/DocumentViewerToc.vue'

const SCROLL_OFFSET = -128
const { useState: useStateDocumentEditor, useActions } = createNamespacedHelpers('documentEditor')

const allItems = Object.values(docSidebarTypes)

export default defineComponent({
  name: 'DocSidebar',
  components: {
    CommentsSidebar,
    FeathersCount,
    DocumentActivity,
    ActionItems,
    DocumentAnalyticsList,
    DocumentViewerToc,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    activeItems: {
      type: Array,
      default: null, // if null, all items are active
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isClientPortal = inject('isClientPortal', false)
    const activeItemsIndexed = computed(() => {
      const _activeItems = props.activeItems ?? allItems
      return _activeItems.reduce((acc, item) => ({ ...acc, [item]: true }), {})
    })

    const {
      commentsSidebarStatus,
      showNotifications,
      documentSidebarStatus,
    } = useStateDocumentEditor([
      'commentsSidebarStatus',
      'showNotifications',
      'documentSidebarStatus',
    ])

    const { toggleDocumentSidebar } = useActions(['toggleDocumentSidebar'])
    const { Project } = context.root.$FeathersVuex.api

    const project = computed(() => {
      if (props.document) return Project.getFromStore(props.document.project)
      return null
    })

    const projectModules = useProjectModules(project)

    const setTab = tab => {
      toggleDocumentSidebar(tab)
    }

    // TOC
    const tocNodes = computed(() => {
      const { nodes: _tocNodes } = useDocumentNav(
        props.document?._id,
        [CONTENT_BLOCK_CATEGORY.ElementHeading, CONTENT_BLOCK_CATEGORY.ElementQA]
      )
      return _tocNodes?.value
    })

    const isEmptyToc = computed(() => Boolean(!tocNodes?.value?.length))

    watch(() => props.document._id, () => {
      setTab(null)
    }, { immediate: true })

    const scrollToNode = node => {
      if (node) {
        EventBus.$emit('scrollToElementId:appScrollContainer', `node-${node}`, SCROLL_OFFSET)
      }
    }

    const { user } = useUser()

    // QA Progerss helpers
    const totalQuestions = computed(() => props.document?.countQA)
    const showProgress = computed(() => Boolean(totalQuestions.value))

    const analyticsCount = computed(
      () => (props.document?.viewCount ?? 0)
      // + (props.document?.countQAAnswered ?? 0)
    )

    onBeforeUnmount(() => {
      setTab(null)
    })

    return {
      // Static
      docSidebarTypes,

      // Flags
      isEmptyToc,
      showNotifications,
      showProgress,

      // Copmputed
      user,
      project,
      commentsSidebarStatus,
      documentSidebarStatus,
      analyticsCount,
      tocNodes,

      // Methods
      setTab,
      scrollToNode,
      activeItemsIndexed,
      isClientPortal,

      projectModules,
    }
  },

})
</script>

<style lang="postcss" scoped>
.sidebar-button {
  @apply h-36 w-36 p-0 inline-flex items-center justify-center relative;
  @apply border-none focus:outline-none rounded-md; /* bg-gray dark:bg-darkGray-600 */;
  /* @apply hover:bg-gray-200/50 hover:text-gray-1000; */
  @apply transition-all text-gray-700 dark:text-darkGray-300;
  @apply whitespace-nowrap;
  @apply hover:bg-[color:var(--cp-accent)];
  @apply hover:text-[color:var(--cp-accent-most-readable)];
}

.sidebar-button-active {
  @apply bg-[color:var(--cp-accent)] dark:bg-[color:var(--cp-accent)];
  @apply text-[color:var(--cp-accent-most-readable)];
  @apply dark:text-[color:var(--cp-accent-most-readable)];
}
</style>
