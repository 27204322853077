import { computed, inject, unref } from '@vue/composition-api';

const makeConfig = (isClientPortal, modules) => moduleName => {
  const moduleConfig = modules[moduleName]

  return {
    ...moduleConfig,
    enabled: (!isClientPortal && moduleConfig.enabled)
      || (isClientPortal && moduleConfig.enabledPortal),
  }
}

export function useProjectModules(project) {
  const isClientPortal = inject('isClientPortal', false)

  return computed(() => {
    const { modules } = unref(project)
    const makeModuleConfig = makeConfig(isClientPortal, modules)

    return Object.keys(modules).reduce((acc, moduleName) => ({
      ...acc,
      [moduleName]: makeModuleConfig(moduleName),
    }), {})
  })
}

export function useProjectModule(project, moduleName) {
  const isClientPortal = inject('isClientPortal', false)

  return computed(() => {
    const { modules } = unref(project)
    const makeModuleConfig = makeConfig(isClientPortal, modules)
    return makeModuleConfig(moduleName)
  })
}
