
<div>
  <template v-if="!isFolderEmpty">
    <SlickList
      v-if="nodes.length"
      v-model="nodes"
      :disabled="isViewer || !permissions.update"
      lock-to-container-edges
      axis="xy"
      use-drag-handle
      custom-container-id="appScrollContainer"
      :custom-container-offset-y="15"
      :class="
        viewContext.viewAs === 'list'
          ? 'flex flex-col justify-center items-stretch'
          : 'flex flex-wrap gap-y-12'
      "
      @sort-start="handleSortStart"
      @sort-end="handleSortEnd"
    >
      <SlickItem
        v-for="(nodeId, index) in nodes"
        :key="nodeId"
        :index="index"
        tag="div"
        :disabled="!isUnsorted || !permissions.update"
        :class="[
          isLargeGrid && 'fs-card-width-l',
          isSmallGrid && 'fs-card-width-m',
        ]"
      >
        <ActionsToolbar
          v-if="!isViewer && !$isMobile"
          :show-delete="
            permissions.remove
              && nodeId !== publicNodeId
              && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED
          "
          :show-move="
            (permissions.create && permissions.remove)
              && nodeId !== publicNodeId
              && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED
          "
          :show-drag="permissions.update && isUnsorted"
          :show-customize="
            permissions.update
              && nodeId !== publicNodeId
              && mappedNodes.get(nodeId)?.category !== CATEGORY.File
              && viewContext.viewAs !== 'list'
              && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED"
          :show-hover="!editNodes.nodesBeingEdited.includes(nodeId)"
          :show-download="mappedNodes.get(nodeId)?.category === CATEGORY.File"
          @customize="toggleEditOverlay(nodeId)"
          @remove="removeFile(nodeId)"
          @move="openFolderPicker(nodeId)"
          @download="downloadFile(nodeId)"
        >
          <DocumentNodeFolderEditable
            :node-id="nodeId"
            parent="root"
            :index="index"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            :view-as="viewContext.viewAs"
            :read-only="!permissions.update"
          >
            <template #edit>
              <transition
                name="toolbar"
                appear
                enter-active-class="transition-all duration-150 ease-out"
                leave-active-class="transition-all duration-100 ease-in"
                enter-class="scale-75 opacity-0"
                enter-to-class="scale-100 opacity-100"
                leave-class="scale-100 opacity-100"
                leave-to-class="scale-75 opacity-0"
              >
                <DocumentThemeEditor
                  v-if="editNodes.nodesBeingEdited.includes(nodeId)"
                  :node-id="nodeId"
                  :document="mappedNodes.get(nodeId)"
                  :view-as="viewContext.viewAs"
                  @save="toggleEditOverlay(nodeId)"
                  @discard="toggleEditOverlay(nodeId)"
                />
              </transition>
            </template>
          </DocumentNodeFolderEditable>
        </ActionsToolbar>
        <DocumentNodeFolderEditable
          v-else
          :node-id="nodeId"
          parent="root"
          :index="index"
          :is-first="index === 0"
          :is-last="index === children.length - 1"
          :view-as="viewContext.viewAs"
        />
      </SlickItem>
    </Slicklist>
  </template>
  <template v-else>
    <div class="w-full flex items-center justify-center h-235 md:h-420">
      <h3 class="font-normal text-gray-400 dark:text-darkGray-300">
        {{ $t('fs.folder-placeholder') }}
      </h3>
    </div>
  </template>
  <ProjectFolderSelectModal
    v-if="isFolderSelectOpen"
    key="item-folder-select-modal"
    v-model="isFolderSelectOpen"
    title="Select destination"
    ok-label="Move"
    :hidden-folder-ids="[docSelectedForMove]"
    :project-id="currentFolder.project"
    @select="moveFile"
  />
</div>
