import { mergeRight } from 'ramda'
import { getCurrentInstanceOrThrow } from './helpers'

const withDefaults = mergeRight({
  solid: true,
  // toaster: 'b-toaster-top-full',
  toaster: 'b-toaster-top-right',
  autoHideDelay: 3000,
})

export default () => {
  const vm = getCurrentInstanceOrThrow()
  return ({ title, message, variant = 'success' }) => vm.$root.$bvToast.toast(
    message,
    withDefaults({
      title,
      variant,
    })
  )
}
