<script>
import { replace } from 'ramda'
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'FileDownload',
  props: {
    url: {
      type: String,
      default: null,
    },
    passthru: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const enableProxy = import.meta.env.VITE_ENABLE_S3_PROXY

    const proxyUrl = computed(() => (!props.passthru && enableProxy === '1' && props.url
      ? replace(/^https:\/\/s3.*?\.amazonaws\.com\//, '/files/', props.url)
      : props.url))

    return () => context.slots.default({
      proxyUrl: proxyUrl.value,
    })
  },
})
</script>
