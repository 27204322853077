<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="false"
    :root-class="['node-spacer min-h-32']"
    :config-override="{
      stylingToolbar: {
        features: {
          textColor: false,
          fontFamily: false,
          sizes: false,
          spacing: false,
        },
      },
      styling: {
        size: 'F',
        paddingTop: 0,
        paddingBottom: 0
      },
    }"
  >
    <template #default="{ styling, textVariant }">
      <DocumentContentBlockSpacer
        :content-block="contentBlock"
        :node-id="node._id"
        :styling="styling"
        :text-variant="textVariant"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockSpacer from './DocumentContentBlockSpacer.vue'

export default {
  name: 'DocumentNodeSpacer',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockSpacer,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
}
</script>
