<template>
  <div>
    <button
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Go to content block`"
      variant="link"
      class="
        inline text-green-700
        hover:text-green-800
        focus:outline-none
      "
      v-on="$listeners"
    >
      <span class="icon-link text-18" />
    </button>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ActionItemNode',
})
</script>
