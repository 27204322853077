
<router-link
  v-slot="{ href, navigate, isActive: isRouteActive, isExactActive: isRouteExactActive }"
  :to="to"
  custom
>
  <Watch
    :value="[active, isRouteExactActive, isRouteActive]"
    immediate
    @change="onActiveChange"
  >
    <a
      class="no-hover grow min-w-0 flex items-center"
      :href="href"
      @click="navigate"
    >
      <slot
        v-bind="{
          isActive: isRouteActive,
          isExactActive: isRouteExactActive
        }"
      />
    </a>
  </Watch>
</router-link>
