<template>
  <div
    class="flex flex-col gap-8 p-8 project-task-card portal-paragraph-font"
    :class="{
      'resolved': task.isResolved,
      'pending': !task.isResolved,
      'due': !task.isResolved && task.isDue
    }"
    v-on="$listeners"
  >
    <div class="flex items-start pr-6" @click.stop>
      <b-form-checkbox
        class="task-checkbox"
        :checked="task.isResolved"
        @change="val => $emit('update', task._id, { isResolved: val })"
      />
      <h4 class="text-14 smart-word-break mt-2">
        {{ task.title | textEllipsis(100) }}
      </h4>
      <!-- <ContentEditable
        ref="titleRef"
        :contenteditable="isTitleEditable"
        class="text-14 font-semibold"
        :value="task.title"
        lazy
        placeholder="Title..."
        tag="h4"
        :no-html="true"
        no-newline
        trim
        @input="updateTitle"
        @dblclick="editTitle"
      /> -->
    </div>
    <ActionItemDateDisplay
      v-if="task.dueDate && !task.isResolved"
      v-slot="{ displayDate }"
      class-name="text-11"
      :item="task"
    >
      <span class="icon-util-calendar text-14" />
      {{ displayDate.message }}
    </ActionItemDateDisplay>
    <div
      class="flex items-center"
      :class="{
        'justify-between': task.assignedTo,
        'justify-end' : !task.assignedTo
      }"
    >
      <ActionItemAssignedTo :action-item="task" show-name />

      <div class="flex items-center">
        <span
          v-if="task.notes"
          class="text-16 icon_v2-so_file"
        />

        <ClientVisibilityBadge
          v-if="!hideVisibilityBadge && task.public"
          :visible="task.public"
          variant="icon"
        />
      </div>
    </div>
    <!-- <p class="text-12">{{ task.notes }}</p> -->
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ActionItemAssignedTo from '@/components/ActionItemAssignedTo.vue'
import ActionItemDateDisplay from '@/components/ActionItemDateDisplay.vue'
import ClientVisibilityBadge from '@/components/ClientVisibilityBadge.vue'
// import ContentEditable from '@/components/ContentEditable.vue'

export default defineComponent({
  name: 'ProjectTaskCard',
  components: {
    ActionItemDateDisplay,
    ActionItemAssignedTo,
    ClientVisibilityBadge,
    // ContentEditable,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    hideVisibilityBadge: {
      type: Boolean,
      default: false,
    },
  },
  // setup(props, context) {
  // const isTitleEditable = ref(false)
  // const titleRef = ref(null)

  // const updateTitle = newTitle => {
  //   isTitleEditable.value = false
  //   context.emit('update', props.task._id, { title: newTitle })
  // }

  // const editTitle = () => {
  //   isTitleEditable.value = true
  //   Vue.nextTick(() => titleRef.value.focus())
  // }

  // return {
  //   isTitleEditable,
  //   updateTitle,
  //   editTitle,
  //   titleRef,
  // }
  // },
})
</script>
<style lang="postcss" scoped>
  .project-task-card {
    &::before {
      content: '';
      @apply absolute left-0 right-0 top-0 bottom-0;
      @apply border-l-3 rounded-md;
    }

    &.resolved::before {
      @apply border-green-600;
    }

    &.pending::before {
      @apply border-gray-400;
    }

    &.due::before {
      @apply border-red-500;
    }
  }

  .task-checkbox::v-deep {
    label {
      display: inline-block;
    }
  }
</style>
