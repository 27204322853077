import { ref, computed } from '@vue/composition-api'
import announcementsService from '../announcementsService'
import { ANNOUNCEMENT_TYPE } from '../announcementsTypes'

export default function useAnnouncement() {
  const lastAnnouncement = ref(null)

  const handleAnnouncement = announcement => {
    switch (announcement.type) {
      case ANNOUNCEMENT_TYPE.newAppVersion:
        if (announcement.version !== window.APP_VERSION) {
          lastAnnouncement.value = announcement
        }
        break;
      default:
        lastAnnouncement.value = announcement;
        break;
    }
  }

  announcementsService.on('created', handleAnnouncement);
  return computed(() => lastAnnouncement.value);
}
