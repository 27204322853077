import { map, dissoc, over, lensProp, has, when, pathSatisfies } from 'ramda'
import { keep, alterItems, debug, iff } from 'feathers-hooks-common'
import feathersClient from '@/v2/services/feathersClientService'

export const servicePath = 'projects'
const service = feathersClient.service(servicePath)

// Discard portalLinkedApps.*.data as it's computed on the server
// Web app is NOT allowed to send arbitrary data (server will throw Forbidden if so)
const discardPortalLinkedAppsData = alterItems(
  when(
    has('portalLinkedApps'),
    over(
      lensProp('portalLinkedApps'),
      map(dissoc('data'))
    )
  )
)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      // TODO: investigate if we really need to use 'keep' here
      keep(
        // details
        'organization',
        'name',
        'client',
        'currency',
        'locale',

        // branding
        'brandingColors',
        'brandingFontHeading',
        'brandingFontParagraph',
        'portalTheme',
        'portalAccentColor',
        'portalLinkColor',
        'portalSecondaryColor',
        'portalHeadingFont',
        'portalHeaderTheme',
        'portalHeaderBackgroundColor',
        'portalHeaderImage',
        'portalHeaderImageOpacity',

        // apps & quick links
        'portalQuickLinks',
        'portalLinkedApps',
        'portalIntegrations',

        // cloning
        'cloneOf'
      ),
      discardPortalLinkedAppsData,
    ],
    update: [],
    patch: [
      discardPortalLinkedAppsData,
    ],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})
// Set a generous timeout as a project clone request can take some time before it clones all
// documents, action items & comments
service.timeout = 20000

/** @type {import('@feathersjs/feathers').Service} */
export default service
