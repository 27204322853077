<template>
  <span v-if="formatted">{{ formatted }}</span>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

const makeFormatterForCurrency = currency => new Intl.NumberFormat(navigator.language, {
  currency,
  style: 'currency',
  minimumFractionDigits: 0,
})

const makeFormatter = () => {
  const formatters = new Map()
  return (currency, value) => {
    if (!formatters.has(currency)) {
      formatters.set(currency, makeFormatterForCurrency(currency))
    }

    return formatters.get(currency).format(value)
  }
}

const makeSymbolGetter = formatter => currency => formatter(currency, 0).replace(/0|\s/g, '')

const formatter = makeFormatter()
const symbolGetter = makeSymbolGetter(formatter)

export default defineComponent({
  name: 'CurrencyFormatter',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    symbol: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },
  computed: {
    formatted() {
      if (!this.currency) return null // Nothing to format if no currency provided

      return this.symbol
        ? symbolGetter(this.currency)
        : formatter(this.currency, this.value || 0)
    },
  },
})
</script>
