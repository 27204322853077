
<div v-fragment>
  <!-- block -->
  <div :style="{ height: height + 'px' }"></div>

  <!-- toolbar settings -->
  <DocumentContentBlockSpacerToolbarPortal
    :popover-target-id="`settings-popover-${nodeId}`"
    :content-block="contentBlock"
    :node-id="nodeId"
    @update="(data, options) => $emit('update', data, options)"
  />
</div>
