
<DocumentNodeEditorModel
  v-slot="{ contentBlock }"
  :node-id="nodeId"
  :use-clone="true"
>
  <DocumentLink
    v-if="contentBlock.embeddedDocument$"
    v-slot="{ navigate }"
    :document="contentBlock.embeddedDocument$"
  >
    <div v-fragment>
      <!-- SLOT FOR EDITING -->
      <slot name="edit" />
      <DocumentResolver
        v-if="isLargeGrid || isSmallGrid"
        v-slot="{ component }"
        :document="contentBlock.embeddedDocument$"
      >
        <!-- Component from doc category -->
        <component
          :is="component"
          :document="contentBlock.embeddedDocument$"
          :read-only="
            contentBlock.embeddedDocument$.category === CATEGORY.ProjectPublicFolder
              || readOnly
          "
          :size="isLargeGrid ? 'l':'m'"
          @select="navigate"
        />
      </DocumentResolver>
      <FolderItemRow
        v-else-if="isList"
        :document="contentBlock.embeddedDocument$"
        :read-only="
          contentBlock.embeddedDocument$.category === CATEGORY.ProjectPublicFolder
            || readOnly
        "
        @select="navigate"
      />
    </div>
  </DocumentLink>
  <FileNoLongerAvailable
    v-else
    :list="isList"
    :size="isLargeGrid ? 'l':'m'"
  />
</DocumentNodeEditorModel>
