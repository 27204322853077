import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import billablesService, { servicePath } from './billablesService'

class Billable extends BaseModel {
  static modelName = 'Billable'
}

export default makeServicePlugin({
  Model: Billable,
  service: billablesService,
  servicePath,
})
