<template>
  <img
    v-if="logoUrl"
    :src="logoUrl"
    class="object-contain w-auto h-full"
    :class="[alignMap[align]]"
  />
  <LabelAvatar
    v-else
    size="24"
    :label="fallbackName"
    :background-color="color"
  />
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import LabelAvatar from '@/components/LabelAvatar.vue'

const alignMap = {
  left: 'object-left',
  center: 'object-center',
  right: 'object-right',
}

export default defineComponent({
  name: 'Logo',
  components: {
    LabelAvatar,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    dark: {
      type: String,
      default: '',
    },
    light: {
      type: String,
      default: '',
    },
    fallbackName: {
      type: String,
      default: 'P',
    },
    color: {
      type: String,
      default: '#CCCCCC',
    },
    align: {
      type: String,
      default: 'left',
    },
  },

  setup(props) {
    // Whether there is a logo available for the theme we are displaying
    // if no matching logo available, we display the label avatar.
    const logoUrl = computed(() => {
      if (props.theme === 'light' && props.light) { return props.light }
      if (props.theme === 'dark' && props.dark) { return props.dark }
      return props.light || ''
    })

    return {
      logoUrl,
      alignMap,
    }
  },
})
</script>
