
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  :root-class="[$style['node-qa']]"
  :config-override="{
    events: {
      syncStatus: true,
    },
    styling: {
      size: 'S',
      paddingTop: 40,
      paddingBottom: 40,
    },
  }"
>
  <template #default="{ styling, textVariant, isHover, nodeWidth }">
    <DocumentContentBlockQA
      :node-id="node._id"
      :index-by-category="indexByCategory"
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      :is-focus="isFocus"
      :is-hover="isHover"
      :node-width="nodeWidth"
      @update="(data, options) => $emit('update', data, options)"
      @async-save-start="$emit('async-save-start')"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
