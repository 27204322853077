<template>
  <ul
    ref="menuRef"
    v-click-outside.capture="() => $emit('close')"
    :style="menuPosition"
    class="dropdown-menu dropdown-cm show z-1010"
    role="menu"
    @click.stop="$emit('close')"
  >
    <b-dropdown-text>
      <b-badge
        v-if="label"
        variant="neutral"
        class="rounded-md normal-case"
      >
        {{ label }}
      </b-badge>
    </b-dropdown-text>
    <slot />
  </ul>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { checkVisibility } from '@/helpers/dom'

export default defineComponent({
  name: 'ContextMenu',
  components: {},
  props: {
    /** Top (y) position where menu should be opened */
    top: {
      type: Number || String,
      required: true,
      default: 0,
    },
    /** Left (x) position where menu should be opened */
    left: {
      type: Number || String,
      required: true,
      default: 0,
    },
    /** A label to be shown as a heading, above menu items */
    label: {
      type: String,
      default: null,
    },
  },
  setup(props/* , context */) {
    const menuRef = ref(null)
    const menuPosition = reactive({ top: `${props.top}px`, left: `${props.left}px` })

    onMounted(() => {
      // Check initial position of ContextMenu and make necessary adjustments
      if (!menuRef.value) return // Fail-safe
      // Check if the menu is completely within the viewport
      const { isBottomVisible, isRightVisible, height, width } = checkVisibility(menuRef.value)

      if (!isBottomVisible) {
        // Bottom side not completely visible. Position above cursor
        menuPosition.top = `${props.top - height}px`
      }
      if (!isRightVisible) {
        // Right side not completely visible. Position left of cursor
        menuPosition.left = `${props.left - width}px`
      }
    })
    // Update the menu's position if the user right clicks in another place
    watch(() => [props.top, props.left], ([top, left]) => {
      menuPosition.top = `${top}px`
      menuPosition.left = `${left}px`
    })

    return {
      menuRef,
      menuPosition,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
