<template>
  <NotificationLinkActionItem v-slot="{ navigate }" :notification="notification">
    <NotificationRenderer
      v-slot="{ options }"
      :notification="notification"
      icon-variant="author"
      badge-icon="icon_v2-so_tick"
      badge-class="bg-green-500"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      Action item <span class="font-semibold">{{ notification.subject$.title }}</span> resolved

      <template v-if="options.showDocument && notification.document">
        on <NotificationLinkDocument :document="notification.document$" />
      </template>
      <template v-else-if="!notification.document">
        on <NotificationLinkProjectActionItems :notification="notification" />
      </template>
    </NotificationRenderer>
  </NotificationLinkActionItem>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkActionItem from './NotificationLinkActionItem.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationLinkProjectActionItems from './NotificationLinkProjectActionItems.vue'

export default defineComponent({
  name: 'NotifActionItemsResolved',
  components: {
    NotificationRenderer,
    NotificationLinkActionItem,
    NotificationLinkDocument,
    NotificationLinkProjectActionItems,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
})
</script>
