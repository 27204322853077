<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="[
      'node-group-basic',
    ]"
  >
    <template v-if="!isEmpty">
      <DocumentNode
        v-for="(nodeId, index) in children"
        :key="nodeId"
        :node-id="nodeId"
        :parent="node._id"
        :index="index"
        :is-first="index === 0"
        :is-last="index === children.length - 1"
      />
    </template>
    <DocumentNodeGroupBasicPlaceholder v-else />
  </DocumentNodeRenderer>
</template>

<script>
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNode from '@/v2/features/document/documentNode/DocumentNode.vue'
import DocumentNodeGroupBasicPlaceholder from './DocumentNodeGroupBasicPlaceholder.vue'

export default {
  name: 'DocumentNodeGroupBasic',
  components: {
    DocumentNodeRenderer,
    DocumentNodeGroupBasicPlaceholder,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNode })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)

    return {
      children,
      isEmpty,
    }
  },
}
</script>
