<template>
  <div class="relative editor-content table-editor-content">
    <bubble-menu
      :editor="editor"
      :tippy-options="{
        moveTransition: 'transform 0.1s ease-out',
        maxWidth: 'none',
        hideOnClick: true,
        onShow: onBubbleMenuShow,
        onHide: onBubbleMenuHide,
      }"
      :class="[
        'flex items-center space-x-2',
        'p-4 mb-4',
        'text-white rounded-md bg-gray-800 leading-none shadow'
      ]"
    >
      <!-- Highlight -->
      <EditorToolbarHighlight
        v-if="editorFeatures.includes(features.highlight)"
        :is-active="
          editor.isActive('colorHighlight')
        "
        :active-color="
          checkNestedMarkAttributes('colorHighlight', 'backgroundColor')
        "
        @select="item => setColorHighlight(item)"
      />
      <DocumentNodeEditorToolbarSeparator
        v-if="editorFeatures.includes(features.highlight)"
      />

      <!-- Headings -->
      <EditorToolbarHeading
        v-if="editorFeatures.includes(features.headings)"
        :is-active="editor.isActive('heading')"
        :active-heading="checkNestedNodeAttributes('heading', 'level')"
        @select="item => editor.chain().toggleHeading({ level: item }).focus().run()"
      />
      <!-- Bullet List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.bulletList)"
        :is-active="editor.isActive('bulletList')"
        @click="editor.chain().toggleBulletList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
      </EditorToolbarButton>

      <!-- Ordered List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.orderedList)"
        :is-active="editor.isActive('orderedList')"
        @click="editor.chain().toggleOrderedList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-number-list" />
      </EditorToolbarButton>

      <!-- Blockquote -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.blockquote)"
        :is-active="editor.isActive('blockquote')"
        @click="editor.chain().toggleBlockquote().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-quote" />
      </EditorToolbarButton>

      <!-- Code -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.code)"
        :is-active="editor.isActive('code')"

        @click="editor.chain().toggleCode().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-code" />
      </EditorToolbarButton>

      <!-- Link -->
      <DocumentNodeEditorToolbarPopoverLight
        v-if="editorFeatures.includes(features.link) && bubbleMenuShown"
        :width="420"
        placement="bottom"
        :target-id="componentID"
        triggers="click blur"
        slim
      >
        <template #button-content>
          <!-- Button before link menu is set -->
          <EditorToolbarButton
            v-if="editorFeatures.includes(features.link)"
            :is-active="editor.isActive('link')"
            has-dropdown
            @click="showLinkMenu(editor.getAttributes('link'))"
          >
            <span class="relative icon_v2-so_link" />
          </EditorToolbarButton>
        </template>
        <template #default>
          <form
            class="flex items-center px-8 py-6 space-x-8"
            @mouseenter="toggleIsPopoverActive(true)"
            @mouseleave="toggleIsPopoverActive(false)"
            @submit.prevent="setLink(linkUrl)"
          >
            <b-input
              ref="linkInput"
              v-model="linkUrl"
              :class="[
                'form-control-sm',
                'flex-1'
              ]"
              type="text"
              placeholder="https://"
              @keydown.esc="hideLinkMenu"
            />
            <b-button
              v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Confirm link`"
              :disabled="!linkUrl"
              variant="primary"
              :class="[
                'inline-flex items-center justify-center p-0 w-28 h-26',
                'text-green-600 rounded-full',
              ]"
              type="submit"
            >
              <span class="icon_v2-so_tick" />
            </b-button>
            <b-button
              v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Remove link`"
              :disabled="!linkUrl"
              variant="icon-isolated"
              :class="[
                'inline-flex items-center justify-center p-0 w-28 h-26',
                'text-gray-600 rounded-full',
              ]"
              type="button"
              @click="editor.chain().focus().unsetLink().run()"
            >
              <span class="icon_v2-so_link-unlink" />
            </b-button>
          </form>
        </template>
      </DocumentNodeEditorToolbarPopoverLight>


      <DocumentNodeEditorToolbarSeparator />

      <!-- Bold -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.bold)"
        :is-active="editor.isActive('bold')"

        @click="editor.chain().toggleBold().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-bold" />
      </EditorToolbarButton>

      <!-- Italic -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.italic)"
        :is-active="editor.isActive('italic')"

        @click="editor.chain().toggleItalic().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-italic" />
      </EditorToolbarButton>

      <!-- Strike -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.strike)"
        :is-active="editor.isActive('strike')"

        @click="editor.chain().toggleStrike().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-strike" />
      </EditorToolbarButton>

      <!-- Align -->
      <DocumentNodeEditorToolbarSeparator />
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({textAlign: 'left'})"

        @click="editor.chain().setTextAlign('left').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-left" />
      </EditorToolbarButton>
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({textAlign: 'center'})"

        @click="editor.chain().setTextAlign('center').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-center" />
      </EditorToolbarButton>
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({textAlign: 'right'})"

        @click="editor.chain().setTextAlign('right').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-right" />
      </EditorToolbarButton>
    </bubble-menu>
    <!-- Editor Content -->
    <section
      class="flex justify-between h-40 mb-16 print:hidden"
    >
      <section
        :class="[
          !isFocus && 'opacity-30',
          'p-4 bg-white border border-gray-200 shadow rounded-lg',
          'inline-flex items-center space-x-4',
          'leading-none'
        ]"
      >
        <!-- NEW TABLE -->
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add column before'"
          variant="light"
          :disabled="!editor.can().addColumnBefore()"
          @click="editor.chain().focus().addColumnBefore().run()"
        >
          <span class="w-20 text-20 icon-table-column-plus-before"></span>
        </EditorToolbarButton>
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add column after'"
          variant="light"
          :disabled="!editor.can().addColumnAfter()"
          @click="editor.chain().focus().addColumnAfter().run()"
        >
          <span class="w-20 text-20 icon-table-column-plus-after"></span>
        </EditorToolbarButton>
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add row before'"
          variant="light"
          :disabled="!editor.can().addRowBefore()"
          @click="editor.chain().focus().addRowBefore().run()"
        >
          <span class="w-20 text-20 icon-table-row-plus-before"></span>
        </EditorToolbarButton>

        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add row after'"
          variant="light"
          :disabled="!editor.can().addRowAfter()"
          @click="editor.chain().focus().addRowAfter().run()"
        >
          <span class="w-20 text-20 icon-table-row-plus-after"></span>
        </EditorToolbarButton>
        <DocumentNodeEditorToolbarSeparator variant="small" />
        <!-- REMOVE COLUMN -->
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete column'"
          variant="light"
          :disabled="!editor.can().deleteColumn()"
          @click="editor.chain().focus().deleteColumn().run()"
        >
          <span class="w-20 text-20 icon-table-column-remove"></span>
        </EditorToolbarButton>

        <!-- REMOVE ROW -->
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete row'"
          variant="light"
          :disabled="!editor.can().deleteRow()"
          @click="editor.chain().focus().deleteRow().run()"
        >
          <span class="w-20 text-20 icon-table-row-remove"></span>
        </EditorToolbarButton>

        <DocumentNodeEditorToolbarSeparator variant="small" />
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Merge cells'"
          variant="light"
          :disabled="!editor.can().mergeCells()"
          @click="editor.chain().focus().mergeCells().run()"
        >
          <span class="w-20 text-20 icon-table-merge-cells"></span>
        </EditorToolbarButton>

        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Split cell'"
          variant="light"
          :disabled="!editor.can().splitCell()"
          @click="editor.chain().focus().splitCell().run()"
        >
          <span class="w-20 text-20 icon-table-split-cell"></span>
        </EditorToolbarButton>
        <DocumentNodeEditorToolbarSeparator variant="small" />
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Toggle header column'"
          variant="light"
          :disabled="!editor.can().toggleHeaderColumn()"
          @click="editor.chain().focus().toggleHeaderColumn().run()"
        >
          <span class="w-20 text-20 icon-table-column-toggle-header"></span>
        </EditorToolbarButton>
        <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Toggle header row'"
          variant="light"
          :disabled="!editor.can().toggleHeaderRow()"
          @click="editor.chain().focus().toggleHeaderRow().run()"
        >
          <span class="w-20 text-20 icon-table-row-toggle-header"></span>
        </EditorToolbarButton>

        <!-- <EditorToolbarButton
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete table'"
          variant="light"
          :disabled="!editor.can().deleteTable()"
          @click="editor.chain().focus().deleteTable().run()"
        >
          <span class="w-20 text-20 icon-table-large-remove"></span>
        </EditorToolbarButton> -->
        <!-- <EditorToolbarButton
          :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')"
          @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"
        >
          setCellAttribute
        </EditorToolbarButton> -->
        <!-- <EditorToolbarButton
          :disabled="!editor.can().fixTables()"
          @click="editor.chain().focus().fixTables().run()"
        >
          fixTables
        </EditorToolbarButton> -->
      </section>
      <!-- <b-button
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'New Table'"
        :class="[
          !isFocus && 'opacity-30',
          'h-auto inline-flex items-center leading-none rounded-lg'
        ]"
        variant="neutral"
        @click="editor.chain().focus().insertTable(
          {
            rows: 3,
            cols: 3,
            withHeaderRow: true
          }
        ).run()"
      >
        Add table
        <span class="w-20 ml-4 text-20 icon-table-large-plus"></span>
      </b-button> -->
    </section>
    <!-- CONTENT -->
    <editor-content :editor="editor" />
    <!-- CONTENT -->
  </div>
</template>

<script>
// --------------------------------------------------
// TYPES & HELPERS
// --------------------------------------------------


// --------------------------------------------------
// --------------------------------------------------
// ------------------- TIPTAP -----------------------
// --------------------------------------------------
// --------------------------------------------------
import {
  Editor,
  EditorContent,
  BubbleMenu,
} from '@tiptap/vue-2'

// -----STANDARD EXTENSIONS-------------------------------
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Focus from '@tiptap/extension-focus'
import TextAlign from '@tiptap/extension-text-align'
// -----TABLES--------------------------------------------
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import { mapState, mapMutations } from 'vuex'
import { CustomTableHeader } from '@/components/TextEditorExtensions/CustomTableHeader'

// -----CUSTOM EXTENSIONS---------------------------------
import { ColorHighlight } from '@/components/TextEditorExtensions/ColorHighlightV2'
// VUEX STORE
import { EDITOR_FEATURES } from '@/types/editor'
// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import DocumentNodeEditorToolbarSeparator from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSeparator.vue'
import DocumentNodeEditorToolbarPopoverLight from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopoverLight.vue'
import EditorToolbarButton from '@/components/TextEditor/EditorToolbarButton.vue'
import EditorToolbarHighlight from '@/components/TextEditor/EditorToolbarHighlight.vue'
import EditorToolbarHeading from '@/components/TextEditor/EditorToolbarHeading.vue'

// --------------------------------------------------
// EDITOR FEATURES
// --------------------------------------------------
const defaultEditorFeatures = [
  EDITOR_FEATURES.highlight,
  EDITOR_FEATURES.headings,
  EDITOR_FEATURES.bulletList,
  EDITOR_FEATURES.orderedList,
  EDITOR_FEATURES.blockquote,
  EDITOR_FEATURES.code,
  EDITOR_FEATURES.link,
  EDITOR_FEATURES.bold,
  EDITOR_FEATURES.italic,
  EDITOR_FEATURES.strike,
  EDITOR_FEATURES.textAlign,
]

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => ({
          'data-background-color': attributes.backgroundColor,
          style: `background-color: ${attributes.backgroundColor}`,
        }),
      },
    }
  },
})

export default {
  name: 'TableEditor',
  components: {
    EditorContent,
    BubbleMenu,
    DocumentNodeEditorToolbarSeparator,
    EditorToolbarHighlight,
    EditorToolbarButton,
    EditorToolbarHeading,
    DocumentNodeEditorToolbarPopoverLight,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    autofocus: Boolean,
    showFloatingMenu: {
      type: Boolean,
      default: true,
    },
    editorFeatures: {
      type: Array,
      default: () => defaultEditorFeatures,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          StarterKit.configure({
            heading: {
              levels: [1, 2, 3, 4, 5],
            },
          }),
          Link,
          Focus.configure({
            className: 'has-focus',
            nested: false,
            mode: 'shallowest',
          }),
          // new Underline(),
          TableRow,
          TableHeader,
          TableCell,
          CustomTableCell,
          Table.configure({
            resizable: true,
          }),
          ColorHighlight,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
        ],
        content: this.value,
        injectCSS: false,
        onUpdate: context => this.onUpdate(context),
        onBlur: context => this.onBlur(context),
        onFocus: context => this.onFocus(context),
        parseOptions: {
          preserveWhitespace: 'full',
        },
      }),
      linkUrl: null,
      bubbleMenuShown: false,
      features: EDITOR_FEATURES,
    }
  },
  computed: mapState('documentEditor', ['isTextEditorToolbarVisible']),
  watch: {
    value(newVal) {
      // TODO: //https://github.com/scrumpy/tiptap/issues/110
      if (newVal !== this.editor.getHTML()) {
        // for when text editor is emptied
        if (!newVal) {
          this.editor.commands.clearContent({
            emitUpdate: true,
          })
        } else {
          this.editor.commands.setContent({
            content: newVal,
            emitUpdate: true,
          })
        }
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.editor.focus())
    }
  },
  unMounted() {
    this.editor.destroy()
  },
  methods: {
    cleanContent() {
      // Remove emtpy paragraphs
      // https://github.com/ueberdosis/tiptap/issues/154
      let content = this.editor.getHTML();
      const json = this.editor.getJSON().content
      if (
        Array.isArray(json)
        && json.length === 1
        && !Object.prototype.hasOwnProperty.call(json[0], 'content')
      ) {
        content = '' // or any other default value
      }
      return content
    },
    onUpdate() {
      !this.lazy && this.$emit('input', this.cleanContent())
    },
    onFocus(event) {
      this.$emit('focus', event)
    },
    onBlur() {
      const html = this.cleanContent()
      this.$emit('blur', html)
      this.lazy && this.$emit('input', html)
    },

    // Set and unset LINKS
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
    },
    hideLinkMenu() {
      this.linkUrl = null
    },
    setLink(url) {
      this.editor.chain().focus().setLink({ href: url }).run()
      this.hideLinkMenu()
    },

    onBubbleMenuShow() {
      this.bubbleMenuShown = true
      this.setTextEditorToolbarVisibility(true)
    },

    toggleIsPopoverActive(state) {
      // so the Tippy doesn't hide when you click within the Link popover
      this.isPopoverActive = state
    },

    // eslint-disable-next-line consistent-return
    onBubbleMenuHide() {
      if (this.isPopoverActive) return false

      this.bubbleMenuShown = false
      this.setTextEditorToolbarVisibility(false)
    },

    // Expose Mark attributes to determnine active attribute in component
    checkNestedMarkAttributes(marker, attr) {
      const attrValue = this.editor.getAttributes(marker)[attr]
      if (attrValue) {
        const parsedObj = JSON.parse(JSON.stringify(attrValue))
        // This returns an observer unless the mark just got updated
        return typeof parsedObj === 'string' ? parsedObj : parsedObj[0]
      }
      return ''
    },

    checkNestedNodeAttributes(node, attr) {
      const attrValue = this.editor.getAttributes(node)[attr]
      if (attrValue) {
        const parsedObj = JSON.parse(JSON.stringify(attrValue))
        // TODO: clean this up?
        // This returns an observer unless the mark just got updated
        return typeof parsedObj === 'number' ? parsedObj : parsedObj[0]
      }
      return ''
    },

    setColorHighlight(color) {
      if (color === 'background-none') {
        this.editor.chain().unsetColorHighlight().focus().run()
      } else {
        this.editor.chain().focus().setColorHighlight({ backgroundColor: color }).focus()
          .run()
      }
    },
    ...mapMutations('documentEditor', ['setTextEditorToolbarVisibility']),
  },
}
</script>
