
<div class="flex flex-1 flex-grow w-full">
  <div
    :class="[
      'fs-list-wrapper',
      size,
    ]"
    role="link"
    @click="event => $emit('select', event)"
  >
    <div class="flex items-center justify-start flex-1">
      <!-- ICON + NAME -->
      <div
        class="flex items-center"
      >
        <!-- ################## -->
        <!-- ICON -->
        <!-- ################## -->

        <!-- FILE WITH ICON -->
        <div v-if="!isImageFile && isFile" class="fs-list-icon-wrapper">
          <!-- <IconDocument v-if="isFile" class-name="w-40 h-40 scale-50" /> -->
          <FileIcon
            v-if="isFile"
            :content-type="contentType"
            :size="size==='l' ? '36' : '24'"
          />
          <!-- LINKED FILE??? -->
          <div
            v-else
            class="fs-list-icon-wrapper"
            :style="{ color: document.color || theme.textColor}"
          >
            <span
              :class="document.icon"
              class="w-36 text-36"
            ></span>
          </div>
        </div>
        <!-- IMAGE -->
        <div
          v-if="isImageFile"
          class="relative w-36 fs-card--doc-img"
        >
          <div class="absolute flex items-center justify-center w-full h-full p-px">
            <img :src="document.file.url" class="object-contain max-h-full" />
          </div>
        </div>

        <!-- NOT FILE -->
        <!-- NOT IMAGE -->
        <div v-if="!isFile && !isImageFile" class="fs-list-icon-wrapper">
          <span
            :class="icon || 'icon-document'"
            class="w-24 text-24"
            :style="{ color: document.color || theme.textColor}"
          ></span>
        </div>

        <!-- EMD ICON -->
        <!-- ################## -->
        <div class="fs-card-title-list">
          <WrappedTitle
            :text="document.title"
            :pre-size="size==='m' ? 30 : 48"
          />
        </div>
      </div>
    </div>
  </div>
</div>
