
<div class="mt-8">
  <ButtonTransparent
    class="flex items-center gap-8 text-gray-400 text-14 font-semibold"
    @click="$emit('back')"
  >
    <span class="icon_v2-so_direction-left" />
    <slot />
  </ButtonTransparent>
</div>
