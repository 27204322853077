
<draggable
  v-model="modelSections"
  tag="ul"
  class="task-sections"
  draggable=".task-section-item"
  :animation="200"
  group="columns"
  :disabled="readonlySections"
  ghost-class="ghost"
>
  <TaskSection
    v-for="section in sections"
    :id="`section-${section.taskSection._id}`"
    :key="section.taskSection._id"
    class="task-section-item"
    :section="section"
    :selected-task-id="selectedTaskId"
    :lock-tasks-to-section="lockTasksToSection"
    :readonly-sections="readonlySections"
    :no-task-reordering="noTaskReordering"
    :no-section-pin-to-dashboard="noSectionPinToDashboard"
    :no-section-menu="noSectionMenu"
    @remove="$emit('remove', section)"
    @update="data => $emit('update', section, data)"
    @menu-action="action => $emit('menu-action', section, action)"
    @create-task="index => $emit('create-task', section, index)"
    @remove-task="task => $emit('remove-task', section, task)"
    @drag-event-tasks="event => $emit('drag-event-tasks', section, event)"
  >
    <template #section-title>
      <slot name="section-title" v-bind="{ section }" />
    </template>
    <template #task="{ task }">
      <slot name="task" v-bind="{ section, task }" />
    </template>
  </tasksection>
</draggable>
