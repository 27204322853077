
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="true"
  :root-class="['node-page-break']"
  :config-override="{
    stylingToolbar: {
      features: {
        sizes: false,
        backgroundColor: false,
        spacing: false,
        settings: true
      },
    },
    styling: {
      printMarginTop: 86,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      size: 'F',
    },
  }"
>
  <template #default="{ styling, textVariant }">
    <DocumentContentBlockPageBreak
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
  <template #styling-settings="{ value, update }">
    <DocumentNodePageBreakSettings
      :node-id="node._id"
      :styling="value"
      @update="update"
    />
  </template>
</DocumentNodeRenderer>
