<template>
  <ProjectTimelineRendererSingle :notification="notification">
    <template #action>
      <div class="drafted-badge ml-6">
        <span class="icon_v2-so_tick text-16" />
        <span class="ml-2 mr-2 font-medium leading-none text-12 capitalize">
          {{ $t('common.drafted') }}
        </span>
      </div>
    </template>
    <template #default="{ document, isClientPortal }">
      <DocumentLink v-slot="{ navigate }" :document="document">
        <FileDocList
          :document="document"
          standalone
          :show-delete="false"
          :show-analytics="!isClientPortal"
          show-status
          @open="navigate"
        />
      </DocumentLink>
    </template>
  </ProjectTimelineRendererSingle>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'
import FileDocList from '@/components/FileDocList.vue'
import ProjectTimelineRendererSingle from './ProjectTimelineRendererSingle.vue'

export default defineComponent({
  name: 'ItemDocumentsDrafted',
  components: {
    ProjectTimelineRendererSingle,
    DocumentLink,
    FileDocList,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
})
</script>
<style lang="postcss" scopd>
.drafted-badge {
  @apply inline-flex items-center justify-center;
  @apply z-1 relative rounded-md px-6 py-3;
  @apply bg-gray-600 text-white;
}
</style>
