
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  root-class="node-attachments"
>
  <template #default="{ styling, textVariant, nodeWidth }">
    <DocumentContentBlockAttachments
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      :node-width="nodeWidth"
      @update="data => $emit('update', data)"
      @async-save-start="$emit('async-save-start')"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
