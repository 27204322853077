// import { propOr, pipe, replace } from 'ramda'
// import FileSaver from 'file-saver'
import { round } from '@/lib/math'
import httpClientService from '@/v2/services/httpClientService'

const bytesToKb = bytes => bytes / 1024;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
}

/**
 * @typedef {Object} UploadOptions
 * @property {number} maxSizeKb
 */

/**
 * @typedef {Object} UploadEvent
 * @property {File} file
 * @property {'progress' | 'done' | 'error'} status
 * @property {number} [ratio]
 * @property {Error} [error]
 */


/**
 * @param {File[]} files
 * @param {UploadOptions} options
 * @returns {{ errors: Array<{ file: File, error: Error}>, validFiles: File[] }}
 */
const validateFiles = (files, options = {}) => {
  const { maxSizeKb = Infinity } = options;

  /**
   * @param {File} file
   * @returns {Error | null}
   */
  const checkForError = file => {
    if (bytesToKb(file.size) > Number(maxSizeKb)) {
      return new Error(`The maximum allowed size is ${formatBytes(maxSizeKb * 1024)}`);
    }

    return null;
  };

  return files.reduce((acc, file) => {
    const error = checkForError(file);
    if (error) {
      acc.errors.push({ file, error });
    } else {
      acc.validFiles.push(file);
    }
    return acc;
  }, { validFiles: [], errors: [] });
}

/**
 * @param {File[]} files
 * @param {(event: UploadEvent) => void} callback
 * @param {UploadOptions} [options]
 * @returns
 */
const create = (files, callback, options = {}) => {
  if (!files || !files.length) {
    return null
  }

  const { errors, validFiles } = validateFiles(Array.from(files), options);

  errors.forEach(({ file, error }) => {
    callback({
      file,
      error,
      status: 'error',
    })
  })

  const requests = validFiles.map(async file => {
    const formData = new FormData()
    formData.append('uri', file)

    const onUploadProgress = progressEvent => {
      callback({
        file,
        progressEvent,
        ratio: round(progressEvent.loaded / progressEvent.total, 2),
        status: 'progress',
      })
    }

    try {
      const { data } = await httpClientService.upload(
        '/uploads',
        formData,
        onUploadProgress
      )

      callback({
        file,
        status: 'done',
        data,
      })

      return { file, success: true }
    } catch (error) {
      callback({
        file,
        status: 'error',
        error,
      })

      return { file, error }
    }
  })

  return Promise.all(requests)
}

// const filenameFromHeaders = defaultFilename =>
//   pipe(
//     propOr(defaultFilename, 'content-disposition'),
//     replace('attachment; filename=', '')
//   )

// const download = async ({ url, defaultFilename = null, onProgress = noop }) => {
//   const { data, headers } = await this.http.get(url, {
//     responseType: 'blob',
//     timeout: 60000,
//     onDownloadProgress: progressEvent =>
//       onProgress({
//         progressEvent,
//         ratio: round(progressEvent.loaded / progressEvent.total, 2),
//       }),
//   })

//   await FileSaver(data, filenameFromHeaders(defaultFilename)(headers))
// }

export default { create }
