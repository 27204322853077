<template>
  <div
    :class="{
      'is-share-view': isViewer,
      'is-edit-view': isEditor,
      'document-page': document.category===DOCUMENT_CATEGORY.Document && !hidePageBorder
    }"
  >
    <slot />
  </div>
</template>
<script>
import { inject } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'

export default {
  name: 'DocumentPage',
  props: {
    document: {
      type: Object,
      required: true,
    },
    hidePageBorder: Boolean,
  },
  setup() {
    const isViewer = inject('isViewer')
    const isEditor = inject('isEditor')

    return {
      isViewer,
      isEditor,
      DOCUMENT_CATEGORY,
    }
  },
}
</script>
<style lang="scss" scoped>
.document-page {

  @apply bg-white;

  .is-team-bio & {
    margin-top: 0;
  }

  .is-portfolio & {
    margin-top: 0;
  }
  @media print {
    max-width: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
}
</style>
