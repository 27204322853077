
<DocumentNodeRenderer
  :node="node"
  root-class=" masonry-item"
  :inner-class="['p-0']"
  :config-override="{
    ignoreNodePropStyling: true,
    block: {
      accepts,
      enableFocus: false,
      enableCreateBefore: false,
      enableStylingToolbar: false,
    },
    styling: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }"
>
  <template #default>
    <DocumentContentBlockTeamBio
      :content-block="contentBlock"
      :show-analytics="isEditor"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
