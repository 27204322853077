
<DocumentLink v-slot="{ navigate, href }" :document="document">
  <slot v-bind="{ navigate, href }">
    <a
      class="font-semibold"
      :href="href"
      @click="navigate"
    >
      {{ document.title }}
    </a>
  </slot>
</DocumentLink>
