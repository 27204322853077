
<DocumentContentBlockQASettings
  :description="description"
  :has-title="hasTitle"
  :prevent-popover-close="true"
  :popover-target-id="popoverTargetId"
  @input="handleSettingsToggle"
>
  <SettingsSwitch
    v-model="hasNotes"
    tooltip="Enable client notes"
    label="Notes"
  />
  <div class="flex flex-row items-center justify-start my-8">
    <span class="text-11 text-gray-700 uppercase font-semibold bg-gray-100 px-4 py-1 rounded-sm">
      Labels
    </span>
    <span class="block h-2 bg-gray-100 grow ml-4" />
  </div>
  <SettingsInput
    v-model="leftLabel"
    label="Left"
    icon="util-label-l"
  />
  <SettingsInput
    v-model="rightLabel"
    label="Right"
    icon="util-label-r"
  />
  <SettingsInput
    v-model="topLabel"
    label="Top"
    icon="util-label-t"
  />
  <SettingsInput
    v-model="bottomLabel"
    label="Bottom"
    icon="util-label-b"
  />
</DocumentContentBlockQASettings>
