
<ProjectTimelineRendererGroup :notification="notification" icon="icon_v2-so_action-items">
  <template #label>{{ $t('project-updates.action-items-completed') }}</template>
  <template #event="{ event, document, isClientPortal }">
    <NotificationLinkActionItem v-slot="{ navigate }" :notification="event">
      <div>
        <div
          class="flex flex-col md:flex-row md:items-center md:justify-between"
          @click="navigate"
        >
          <p
            class="
          inline md:inline-flex md:items-center
          flex-1 dark:text-darkGray-300 leading-none
        "
          >
            <span class="so-event-checkbox-resolved">
              <span class="icon_v2-so_tick text-12 md:text-16" />
            </span>
            <span class="mr-4">{{ event?.subject$?.title ?? '?' }}</span>
            <ClientVisibilityBadge
              v-if="!isClientPortal"
              variant="icon"
              :visible="event.subject$?.public ?? false"
            />
            <span class="md:ml-auto">
              <ActionItemDateDisplay :item="event.subject$" />
            </span>
          </p>
        </div>
        <ProjectTimelineItemAuthor
          :notification="event"
          :document="event.document$"
        />
      </div>
    </NotificationLinkActionItem>
  </template>
</ProjectTimelineRendererGroup>
