import { useGet } from 'feathers-vuex'
import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { useUser } from '@/v2/services/users/usersCompositions'
import { ROLES } from '../../members/membersTypes'

export default function useCurrentMember() {
  const { MyMember } = Vue.$FeathersVuex.api
  const { member: memberId } = useUser()

  const { item: member } = useGet({ model: MyMember, id: memberId })
  /** Is the current member a client contact? */
  const isClientContact = computed(() => member.value?.role === ROLES.client)
  const isOwner = computed(() => member.value?.isOwner)

  return {
    member,
    isClientContact,
    isOwner,
  } // ref
}
