<template>
  <div class="flex items-center py-4">
    <label
      :for="componentID"
      class="mr-24 text-gray-600 text-14"
    >{{ label }}</label>
    <RangeSlider
      :value="value"
      :min="min"
      :max="max"
      @input="val => $emit('input', val)"
    />
    <div class="flex-none ml-8">
      <b-input
        :id="componentID"
        :value="value"
        type="number"
        step="1"
        :min="min"
        :max="max"
        size="sm"
        class="form-control-number w-48 text-center"
        number
        required
        @input="val => $emit('input', val)"
      />
    </div>
  </div>
</template>

<script>
import RangeSlider from '@/components/RangeSlider.vue'

export default {
  name: 'SettingsInput',
  components: {
    RangeSlider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    inputClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    const handleInput = val => {
      context.emit('input', val)
    }
    return {
      handleInput,
    }
  },
}
</script>

<style lang="scss" scoped></style>
