
<portal :to="`content-block-settings-${nodeId}`">
  <div class="flex flex-row">
    <DocumentNodeEditorToolbarSeparator />
    <DocumentNodeEditorToolbarGroup label="Settings">
      <DocumentContentBlockButtonSettings
        :popover-target-id="popoverTargetId"
        prevent-popover-close
        :value="contentBlock.settings"
        @input="(settings, data) => $emit('update', { settings, ...data })"
      />
    </DocumentNodeEditorToolbarGroup>
  </div>
</portal>
