<template>
  <div id="kanban-board" class="grid overflow-x-auto kanban">
    <KanbanColumns
      :sections="sections"
      :selected-task-id="selectedTaskId"
      :lock-tasks-to-section="lockTasksToSection"
      :readonly-sections="readonlySections"
      :readonly-task="readonlyTask"
      :no-task-reordering="noTaskReordering"
      :no-section-menu="noSectionMenu"
      @update="(section, data) => $emit('update-section', section, data)"
      @remove="section => $emit('remove-section', section)"
      @reorder="reorderedSections => $emit('reorder-sections', reorderedSections)"
      @menu-action="(section, action) => $emit('section-menu-action', section, action)"
      @create-task="(section, index) => $emit('create-task', section, index)"
      @remove-task="(section, task) => $emit('remove-task', section, task)"
      @reorder-tasks="(section, reorderedTasks) => $emit('reorder-tasks', section, reorderedTasks)"
      @drag-event-tasks="onDragEventTasks"
    >
      <template #task="{ section, task }">
        <slot name="task" v-bind="{ section, task }" />
      </template>
    </KanbanColumns>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import KanbanColumns from '@/components/Kanban/KanbanColumns.vue'
import Button from '../Button.vue'

export default defineComponent({
  name: 'KanbanBoard',
  components: {
    KanbanColumns,
    Button,
  },
  props: {
    sections: {
      type: Array,
      default: () => ([]),
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    readonlySections: {
      type: Boolean,
      default: false,
    },
    readonlyTask: {
      type: [Boolean, Function],
      default: false,
    },
    noSectionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let lastDragEvent = null
    let lastDragSection = null
    const onDragEventTasks = (section, event) => {
      if (event.moved) {
        const { oldIndex, newIndex, element: task } = event.moved
        context.emit('move-task', {
          task,
          oldSection: section,
          oldIndex,
          newSection: section,
          newIndex,
        })
        return
      }

      if (event.added) {
        lastDragSection = section
        lastDragEvent = event
        return
      }

      if (event.removed && lastDragEvent.added) {
        const { oldIndex } = event.removed
        const oldSection = section

        const { newIndex, element: task } = lastDragEvent.added
        const newSection = lastDragSection

        lastDragEvent = null
        lastDragSection = null

        context.emit('move-task', {
          task,
          oldSection,
          oldIndex,
          newSection,
          newIndex,
        })
      }
    }

    return {
      onDragEventTasks,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .kanban {
    grid-template-columns: auto 1fr;
    @apply justify-items-start gap-12;

  }
</style>
