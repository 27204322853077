<template>
  <div class="flex items-center">
    <DocIcon v-if="!hideIcon" :document="document" />
    <ContentEditable
      :value="document.title"
      :title-topbar="document.title"
      :lazy="true"
      placeholder="Title..."
      tag="p"
      :no-html="true"
      :contenteditable="!disabled"
      no-newline
      class="font-bold text-gray-800 dark:text-darkGray-300 text-24 portal-heading-font break-all"
      trim
      @input="title => $emit('update', title)"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ContentEditable from '@/components/ContentEditable.vue'
import DocIcon from '@/components/Doc/DocIcon.vue'

export default defineComponent({
  name: 'DocTitle',
  components: {
    ContentEditable,
    DocIcon,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
