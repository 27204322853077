import { always, reverse, T } from 'ramda'
import Vue from 'vue'
import { watch, ref } from '@vue/composition-api'
import { isNotNil } from 'ramda-adjunct'

/**
 * Get all ancestors for a specific file / folder / document
 * @param {import('@vue/composition-api').Ref<any>} document Document object ref
 * @param {import('@vue/composition-api').Ref<string>} [folderId] Folder id override (optional)
 * @param {(document: Object) => boolean} [filterFn] Filter function (optional)
 */
export default function useFSAncestors(document, folderId = null, filterFn = T) {
  const { Document } = Vue.$FeathersVuex.api
  const ancestors = ref([])

  /**
   * Walk parents upwards one by one (sequential requests) and build
   * @param {string} id Document id
   * @returns {Promise<Object[]>} Ancestors as a flat list
   */
  const walkParents = async (id, level = 0) => {
    if (!id) {
      return []
    }

    const doc = await Document.grabOrFetch(id).catch(always(null))
    if (!doc) {
      return []
    }

    const parentFolderId = level === 0 && isNotNil(folderId.value)
      ? folderId.value
      : doc.parentFolder

    const parents = await walkParents(parentFolderId, level + 1)
    return [doc, ...parents];
  }

  watch(
    () => [document.value._id, document.value.parentFolder],
    async ([newId]) => {
      ancestors.value = []
      if (!newId) {
        return
      }

      const chain = await walkParents(newId)
      ancestors.value = reverse(chain).filter(filterFn)
    },
    { immediate: true }
  )

  return ancestors
}
