import Vue from 'vue'

const listeners = new WeakMap()

const throttle = fn => {
  let ticking = false
  return (...args) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        fn(...args)
        ticking = false
      })
      ticking = true
    }
  }
}

const negativeToZero = v => (v < 0 ? 0 : v)
const makeListener = fn => {
  const state = { lastScrollTop: 0/* , lastDirection: '' */ }
  let timer = null

  return ({ target }) => {
    const { lastScrollTop/* , lastDirection */ } = state

    const scrollTop = negativeToZero(
      target === document ? window.pageYOffset : target.scrollTop
    )

    state.lastScrollTop = scrollTop

    const delta = lastScrollTop - scrollTop
    const direction = delta >= 0 ? 'UP' : 'DOWN'

    clearTimeout(timer)
    fn({ direction, isScrolling: true })
    timer = setTimeout(fn, 500, { direction, isScrolling: false })

    // if (direction !== lastDirection) {
    //   fn(direction)
    //   state.lastDirection = direction
    // }
  }
}

Vue.directive('scroll-direction', {
  bind(el, { value: fn, arg = {} }) {
    if (!arg.enabled) {
      return
    }

    const container = arg.window ? window : el
    const listener = throttle(makeListener(fn))

    listeners.set(el, listener)
    container.addEventListener('scroll', listener)
  },
  unbind(el, { arg = {} }) {
    const container = arg.window ? window : el
    container.removeEventListener('scroll', listeners.get(el))
    listeners.delete(el)
  },
})
