<template>
  <div v-fragment>
    <div
      :class="[
        'group relative flex flex-col justify-between overflow-hidden',
        'card-interactive transition-all cursor-pointer',
      ]"
      @click="openModal"
    >
      <TemplatePreviewCard
        :template="template"
        :category="category"
      />
    </div>
    <DocumentTemplateModal
      v-if="!noModal"
      v-model="showTemplateModal"
      :template="template"
      :is-loaded="isLoaded"
      @use="onTemplateSelect"
      @hide="$tracking.track('Document Template Preview Modal Closed')"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, defineComponent } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import { TEMPLATE_CATEGORY } from '@/v2/services/documents/documentsTypes'
import TemplatePreviewCard from '@/components/Template/TemplatePreviewCard.vue'
import DocumentTemplateModal from '@/components/Document/Editor/DocumentTemplateModal.vue'


export default defineComponent({
  name: 'DocumentTemplatePreview',
  components: {
    DocumentTemplateModal,
    TemplatePreviewCard,
  },
  props: {
    category: {
      type: String,
      default: '',
    },
    template: {
      type: Object,
      default: () => {},
    },
    noModal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const { Document } = context.root.$FeathersVuex.api
    const { create } = useDocuments()
    const { track } = useTracking()

    const showTemplateModal = ref(false)

    const document = computed(() => Document.getFromStore(props.template.documentId))
    const isLoaded = computed(() => Boolean(document.value))

    const state = reactive({
      isCreatingDocument: false,
      formMessage: '',
    })
    const category = computed(() => TEMPLATE_CATEGORY[TEMPLATE_CATEGORY[props.template?.template_category?.name] ?? 'default'])

    const onTemplateSelect = async value => {
      if (state.isCreatingDocument) {
        return
      }

      track('Document Template Used', {
        template: props.template.name,
      })

      try {
        state.isCreatingDocument = true
        await create({
          cloneOf: props.template.documentId,
          title: `Copy of ${props.template.name}`,
          project: value.project,
          parentFolder: value.selectedFolderId,
          category: category.value,
        })
      } catch (err) {
        console.error(err)
        state.formMessage = err
      } finally {
        state.isCreatingDocument = false
      }
    }

    const openModal = () => {
      if (props.noModal) {
        context.emit('preview', props.template)
        return
      }

      track('Document Template Previewed', {
        template: props.template.name,
      })

      showTemplateModal.value = true
    }

    return {
      showTemplateModal,
      onTemplateSelect,
      state,
      openModal,
      isLoaded,
    }
  },
})
</script>
