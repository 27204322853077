import * as R from 'ramda'

import Vue from 'vue'
import { format as timeagoFormat, register } from 'timeago.js'
// eslint-disable-next-line camelcase
import { ro, fr, es, pt_BR, de, nl, it, pl, cs, bg, fi, vi } from 'timeago.js/lib/lang'


register('ro', ro);
register('fr', fr);
register('es', es);
register('pt', pt_BR);
register('de', de);
register('nl', nl);
register('it', it);
register('pl', pl);
register('cs', cs);
register('bg', bg);
register('fi', fi);
register('vi', vi);

const makeDate = dateStr => new Date(dateStr)
const makeFormatDate = (date, locale) => timeagoFormat(date, locale)

const timeago = R.pipe(
  R.cond([
    [date => R.is(Date, date), makeFormatDate],
    [date => R.is(String, date), (date, locale) => makeFormatDate(makeDate(date), locale)],
    [R.T, R.always(null)],
  ])
)

Vue.filter('timeago', timeago)
