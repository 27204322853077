import Vue from 'vue';

export default function useScrollHorizontally() {
  return (scrollContainerId, elementId) => Vue.nextTick(() => {
    const container = document.getElementById(scrollContainerId)
    const targetElement = document.getElementById(elementId);

    if (container && targetElement) {
      // const container = targetElement.parentElement;
      container.scrollLeft = targetElement.offsetLeft;

      container.scrollTo({
        left: targetElement.offsetLeft,
        behavior: 'smooth',
      });
    } else {
      throw new Error('element not found')
    }
  })
}
