
<NotificationDocument v-slot="{ document /*, badgeIcon */ }" :notification="notification">
  <DocumentLink v-slot="{ navigate }" :document="document">
    <NotificationRenderer
      v-slot="{ options }"
      :notification="notification"
      :project="project"
      icon-variant="author"
      badge-icon="icon-util-upload-alt"
      badge-class="bg-blue-500"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
      <NotificationLinkDocument :document="document" /> shared
    </NotificationRenderer>
  </DocumentLink>
</NotificationDocument>
