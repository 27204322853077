
<div
  :class="{ interactive: !disabled }"
  style="text-align: left"
>
  <!-- <div>q: {{ activeQuadrant }}</div>
  <div>x: {{ coordinates.x }}</div>
  <div>y: {{ coordinates.y }}</div>
  <div>angle: {{ angle }}</div>
  <div>vector: {{ vector }}</div> -->
  <div
    ref="container"
    class="slider2d-container"
    @dblclick="$emit('reset')"
  >
    <div
      class="slider2d-quadrant quadrant-1"
      :class="activeQuadrant === 1 && 'active-quadrant'"
      :style="
        `background: linear-gradient(${quadrantAngles.q1}deg, ` +
          `rgba(35, 184, 57, 0.2) 0%, ` +
          `rgba(35, 184, 57, 0) ${quadrantVectors.q1}%)`
      "
    />
    <div
      class="slider2d-quadrant quadrant-2"
      :class="activeQuadrant === 2 && 'active-quadrant'"
      :style="
        `background: linear-gradient(${quadrantAngles.q2}deg, ` +
          `rgba(35, 184, 57, 0.2) 0%, ` +
          `rgba(35, 184, 57, 0) ${quadrantVectors.q2}%)`
      "
    />
    <div
      class="slider2d-quadrant quadrant-3"
      :class="activeQuadrant === 3 && 'active-quadrant'"
      :style="
        `background: linear-gradient(${quadrantAngles.q3}deg, ` +
          `rgba(35, 184, 57, 0.2) 0%, ` +
          `rgba(35, 184, 57, 0) ${quadrantVectors.q3}%)`
      "
    />
    <div
      class="slider2d-quadrant quadrant-4"
      :class="activeQuadrant === 4 && 'active-quadrant'"
      :style="
        `background: linear-gradient(${quadrantAngles.q4}deg, ` +
          `rgba(35, 184, 57, 0.2) 0%, ` +
          `rgba(35, 184, 57, 0) ${quadrantVectors.q4}%)`
      "
    />
    <div
      ref="draggable"
      v-b-tooltip="{
        placement: 'bottom',
        trigger: 'hover',
        variant: 'info',
        delay: {'show':200,'hide':0},
        title: showTooltip ? 'Drag me to answer' : '',
      }"
      class="slider2d-draggable"
      :class="[shouldPing && 'ping']"
      :style="draggableStyle"
      @mousedown="onDragStart"
      @touchstart="onDragStart"
    >
      <span
        v-if="!disabled"
        :class="[
          'icon_v2-so_drag',
          'transition-colors pointer-events-none transform rotate-90',
        ]"
      />
    </div>
  </div>
</div>
