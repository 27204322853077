<template>
  <div v-if="!isEmpty" class="so-node-list">
    <DocumentNode
      v-for="(nodeId, index) in children"
      :key="nodeId"
      :node-id="nodeId"
      parent="root"
      :index="index"
      :is-first="index === 0"
      :is-last="index === children.length - 1"
    />
  </div>
  <DocumentNodeGroupRootPlaceholder v-else :is-read-only="isReadOnly" />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { useStructureBranch } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNode from '@/v2/features/document/documentNode/DocumentNode.vue'
import DocumentNodeGroupRootPlaceholder from './DocumentNodeGroupRootPlaceholder.vue'

export default defineComponent({
  name: 'DocumentNodeGroupRoot',
  components: {
    DocumentNodeGroupRootPlaceholder,
    DocumentNode,
  },
  inheritAttrs: false,
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch('root')

    return {
      isEmpty,
      children,
    }
  },
})
</script>
