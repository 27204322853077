<template>
  <div
    class="
      grid gap-24 grid-cols-1 sm:grid-cols-2
      xl:grid-cols-3 2xl:grid-cols-4 auto-cols-min auto-rows-min w-full
    "
  >
    <ProjectTemplateListItem
      v-for="template in templates"
      :key="template._id"
      :template="template"
      @preview="$emit('preview', template)"
      @create="$emit('create', template)"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ProjectTemplateListItem from './ProjectTemplateListItem.vue'

export default defineComponent({
  name: 'ProjectTemplateList',
  components: {
    ProjectTemplateListItem,
  },
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
<style lang="postcss" scoped>
/* .header-background-filter {
  background-color: var(--cp-header-bg);
  @apply absolute inset-0 z-0;
}

.header-background-image {
  background-color: var(--cp-header-bg);
  @apply absolute inset-0 z-1;
  @apply transition-all;
}

.header-background-image::after {
  content: '';
  background-image: var(--cp-header-image-url);
  background-size: cover;
  background-position: center center;
  opacity: var(--cp-header-image-opacity);
  @apply absolute inset-0 z-1;
} */
</style>
