<template>
  <FeathersVuexFind
    v-slot="{ items: members }"
    :params="findMembersParams"
    service="myMembers"
    local
  >
    <div v-if="members?.length > 1" class="flex items-center">
      <ButtonIcon
        ref="button"
        v-b-tooltip.hover
        icon="icon_v2-so_switch"
        title="Switch Organization"
      />
      <b-popover
        v-if="button"
        :target="button"
        triggers="click blur"
        placement="bottomright"
        custom-class="w-320 shadow-2xl border-gray-300"
        boundary="window"
      >
        <!-- <template #title>Switch Organization</template> -->
        <ul class="max-h-240 overflow-y-auto  flex flex-col gap-y-4">
          <li
            v-for="member in members"
            :key="member._id"
            class="hover:bg-gray-100 p-4 rounded-md px-8"
            :class="{ 'bg-gray-100': member.organization === currentOrganization?._id }"
          >
            <ButtonTransparent
              class="w-full !justify-start font-semibold"
              @click="onSelectMember(member)"
            >
              <div class="flex justify-between w-full">
                {{ member.organization$.name }}
                <span
                  v-if="member.organization === currentOrganization?._id"
                  class="icon_v2-so_tick text-gray-400"
                />
              </div>
            </ButtonTransparent>
          </li>
        </ul>
      </b-popover>
    </div>
  </FeathersVuexFind>
</template>
<script>
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import { useLocalStorageRef } from '@/v2/lib/composition/useLocalStorage'
import { useRouter } from '@/v2/lib/composition/useRouter'
import useNotification from '@/v2/lib/composition/useNotification'
import { useUser } from '@/v2/services/users/usersCompositions'
import { ORGANIZATION } from '@/router/organization/type'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'

export default defineComponent({
  name: 'OrganizationSwitcher',
  components: {
    ButtonTransparent,
    ButtonIcon,
  },
  setup() {
    const button = ref(null)
    const { router } = useRouter()
    const notification = useNotification()
    const lastMember = useLocalStorageRef('lastMember')
    const { user, authenticateMember } = useUser()
    const currentOrganization = useCurrentOrganization()

    const findMembersParams = computed(() => (user.value ? ({
      query: {
        user: user.value._id,
        status: STATUS.ACTIVE,
        // Not interested in client contacts. Cannot switch to those orgs in the main app
        role: { $in: [ROLES.admin, ROLES.projectManager] },
      },
    }) : null))

    const goToOrganization = organizationId => new Promise((resolve, reject) => {
      Vue.nextTick(() => {
        router
          .push({
            name: ORGANIZATION,
            params: { organizationId },
          })
          .then(resolve)
          .catch(reject)
      })
    })

    const onSelectMember = async member => {
      const { _id, organization, organization$ } = member

      try {
        await authenticateMember(_id)
        lastMember.value = { _id, organization }
        await goToOrganization(organization)
        notification({ message: `Switched to ${organization$.name}` })
      } catch (err) {
        console.error(err)
        notification({
          message: `Unable to switch to ${organization$.name}`,
          variant: 'danger',
        })
      }
    }

    return {
      button,
      onSelectMember,
      STATUS,
      findMembersParams,
      currentOrganization,
    }
  },
})
</script>
