import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import configurationsService, { servicePath } from './configurationsService'

class Configuration extends BaseModel {
  static modelName = 'Configuration'
}

export default makeServicePlugin({
  Model: Configuration,
  service: configurationsService,
  servicePath,
})
