import { mergeRight } from 'ramda'
import { getCurrentInstanceOrThrow } from './helpers'

const withDefaults = mergeRight({
  // Config
  hideHeaderClose: true,
  centered: true,

  // Look & Feel
  size: 'sm',
  buttonSize: 'sm',
  okVariant: 'primary',

  // CSS classes
  modalClass: 'dialog-modal',
  dialogClass: 'dialog-modal-dialog',
  contentClass: 'dialog-modal-content',
  headerClass: 'dialog-modal-header',
  bodyClass: 'dialog-modal-body',
  footerClass: 'dialog-modal-footer',
})

export const useMsgBoxConfirm = () => {
  const vm = getCurrentInstanceOrThrow()
  return ({
    title,
    message,
    okLabel = 'OK',
    okVariant = 'primary',
    cancelLabel = 'Cancel',
    cancelVariant = 'neutral',
    size = 'sm',
  }) => vm.$root.$bvModal.msgBoxConfirm(
    message,
    withDefaults({
      title,
      size,
      okTitle: okLabel,
      okVariant,
      cancelTitle: cancelLabel,
      cancelVariant,
    })
  )
}

export const useMsgBoxConfirmDelete = () => {
  const vm = getCurrentInstanceOrThrow()
  return ({ title, message, size = 'sm' }) => vm.$root.$bvModal.msgBoxConfirm(
    message,
    withDefaults({
      title,
      size,
      okTitle: 'Delete',
      okVariant: 'danger',
      cancelTitle: 'Keep',
      cancelVariant: 'secondary',
    })
  )
}

export const useMsgBoxOK = () => {
  const vm = getCurrentInstanceOrThrow()
  return ({
    title,
    message,
    okLabel = 'OK',
    okVariant = 'primary',
    size = 'sm',
  }) => vm.$root.$bvModal.msgBoxOk(
    message,
    withDefaults({
      title,
      size,
      okVariant,
      okTitle: okLabel,
    })
  )
}

export const useMsgBoxError = () => {
  const vm = getCurrentInstanceOrThrow()
  return ({ title, message, size = 'md' }) => vm.$root.$bvModal.msgBoxOk(
    message,
    withDefaults({
      title,
      size,
      okVariant: 'danger',
      okTitle: 'Close',
    })
  )
}
