import { equals } from 'ramda'

const baseUrl = import.meta.env.BASE_URL
const isEnabled = equals('1')

export default {
  baseUrl,
  debug: isEnabled(localStorage.getItem('DEBUG')),
  isProduction: import.meta.env.PROD,
  isDevelopment: import.meta.env.DEV,
  api: {
    baseUrl: `${baseUrl}api/`,
    ws: {
      url: import.meta.env.VITE_WS_URL,
      nsGuest: '/guest',
      nsUser: '/user',
    },
  },
  appUrl: import.meta.env.VITE_URL,
  googleAnalytics: {
    code: import.meta.env.VITE_GOOGLE_ANALYTICS_CODE,
    enable: isEnabled(import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLE),
  },
  googleTagManager: {
    code: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE,
    enable: isEnabled(import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLE),
  },
  logger: {
    enable:
      isEnabled(import.meta.env.VITE_LOGGER_ENABLE)
      || isEnabled(localStorage.getItem('ENABLE_LOGGER')),
  },
  intercom: {
    enable: isEnabled(import.meta.env.VITE_INTERCOM_ENABLE),
    appId: import.meta.env.VITE_INTERCOM_APP_ID,
    cookieDomain: import.meta.env.VITE_INTERCOM_COOKIE_DOMAIN,
  },
  tracking: {
    enable: isEnabled(import.meta.env.VITE_TRACKING_ENABLE),
    debug: isEnabled(import.meta.env.VITE_TRACKING_DEBUG),
  },
  gtm: {
    enable: isEnabled(import.meta.env.VITE_GTM_ENABLE),
    id: import.meta.env.VITE_GTM_ID,
  },
  // mixpanel: {
  //   enable: isEnabled(import.meta.env.VITE_MIXPANEL_ENABLE),
  //   token: import.meta.env.VITE_MIXPANEL_TOKEN,
  //   initConfig: {
  //     debug: isEnabled(import.meta.env.VITE_MIXPANEL_CONFIG_DEBUG),
  //     api_host: import.meta.env.VITE_MIXPANEL_CONFIG_API_HOST,
  //   },
  // },
  locales: ['en', 'es', 'fr', 'de', 'pt', 'nl', 'it', 'pl', 'ro', 'cs', 'fi', 'bg', 'vi'],
  localesTimeago: {
    en: 'en-US',
    ro: 'ro',
    fr: 'fr',
    es: 'es',
    pt: 'pt',
    de: 'de',
    nl: 'nl',
    it: 'it',
    pl: 'pl',
    cs: 'cs',
    fi: 'fi',
    bg: 'bg',
    vi: 'vi',
  },
  localesNames:
    {
      en: 'English',
      es: 'Español',
      fr: 'Français',
      pt: 'Português',
      de: 'Deutsch',
      nl: 'Dutch',
      ro: 'Română',
      it: 'Italiano',
      pl: 'Polski',
      cs: 'Czech',
      fi: 'Finnish (BETA)',
      bg: 'Bulgarian (BETA)',
      vi: 'Vietnamese (BETA)',
    },
}
