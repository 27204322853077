<template>
  <div v-fragment>
    <ProjectDashboardTaskSection
      v-for="section in sections"
      :key="section.taskSection._id"
      :project="project"
      :section="section"
      :route-tasks="routeTasks"
    />
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useProjectTasks } from '@/v2/services/projects/compositions'
import ProjectDashboardTaskSection from '@/components/ProjectDashboardTaskSection.vue'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

export default defineComponent({
  name: 'ProjectDashboardTaskSections',
  components: {
    ProjectDashboardTaskSection,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    routeTasks: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const projectRef = computed(() => props.project)

    /** @type {Ref<import('@/v2/services/projects/compositions/useProjectTasks').Options>} */
    const options = computed(() => ({
      taskSectionsQuery: {
        showOnProjectDashboard: true,
      },
    }))

    const { sections } = useProjectTasks(projectRef, options)

    return {
      sections,
    }
  },
})
</script>
