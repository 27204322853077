<template>
  <DocumentBlockButtonCreateMenu
    :parent-node-id="parentNodeId"
    :index="index"
    :accepts="accepts"
    :mode="mode"
    :content-library="contentLibrary"
    :button-label="buttonLabel"
    :no-content-library="noContentLibrary"
  />
</template>

<script>
import DocumentBlockButtonCreateMenu from '@/components/Document/DocumentBlockButtonCreateMenu.vue'

export default {
  name: 'DocumentBlockButtonCreate',
  components: {
    DocumentBlockButtonCreateMenu,
  },
  props: {
    parentNodeId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    accepts: {
      type: Array,
      default: null,
    },
    mode: {
      type: String,
      default: 'child',
    },
    contentLibrary: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    noContentLibrary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
