<script>
import { watch } from '@vue/composition-api'

export default {
  name: 'Watch',
  props: {
    value: {
      type: [String, Number, Object, Boolean, Array],
      default: null,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    deep: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    watch(
      () => props.value,
      (newValue, oldValue) => {
        context.emit('change', newValue, oldValue)
      },
      { immediate: props.immediate, deep: props.deep }
    )

    return () => context.slots.default?.() ?? null
  },
}
</script>
