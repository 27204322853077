<template>
  <div>
    <NotificationList
      :notifications="items"
      :show-document="false"
      slim
    />
    <Button
      v-if="hasMore"
      size="sm"
      :is-loading="isPending"
      variant="neutral"
      class="w-full mt-16"
      @click="loadMore"
    >
      Load more
    </Button>
  </div>
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api'
import { useDocumentActivity } from '@/v2/services/documentEvents/compositions'
import Button from '@/components/Button.vue'
import NotificationList from '@/components/Notifications/NotificationList.vue'

export default defineComponent({
  name: 'DocumentActivity',
  components: {
    Button,
    NotificationList,
  },
  props: {
    document: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      items,
      hasMore,
      loadMore,
      isPending,
    } = useDocumentActivity({
      documentId: toRef(props, 'document'),
      pageSize: 50,
    })

    return {
      items,
      hasMore,
      loadMore,
      isPending,
    }
  },
})
</script>
