<template>
  <b-dropdown
    no-caret
    dropright
    variant="transparent"
    toggle-class="w-16"
    menu-class="text-14"
    size="xs"
  >
    <template #button-content>
      <span class="text-16 icon_v2-so_menu-circle-vertical"></span>
    </template>

    <!-- edit -->
    <b-dropdown-item @click.stop="$emit('select', 'moveResolvedToBottom')">
      <span
        class="mr-8 dropdown-menu-icon icon icon_v2-so_arrow-down"
      />
      <span>Move resolved to the bottom</span>
    </b-dropdown-item>

    <!-- settings -->
    <b-dropdown-item @click.stop="$emit('select', 'movePastDueToTop')">
      <span
        class="mr-8 dropdown-menu-icon icon icon_v2-so_arrow-up"
      />
      <span>Move past due to the top</span>
    </b-dropdown-item>

    <!-- remove -->
    <b-dropdown-item @click.stop="$emit('select', 'deleteResolved')">
      <span
        class="mr-8 dropdown-menu-icon icon icon_v2-so_trash text-red-500"
      />
      <span class="text-red-500">Delete resolved tasks</span>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TaskSectionMenu',
})
</script>
