
<div
  v-bind="$attrs"
  :class="[
    $style['form-buttons'],
    'form-buttons',
    'flex flex-row items-center justify-between px-16 tablet:px-32 pt-12 pb-12 -mx-16',
    'bg-gray dark:bg-darkGray-900 border-t border-gray-200',
    'dark:border-darkGray-700',
    'rounded-b-lg z-20',
    'md:-mx-24 lg:-mx-32',
    'sticky bottom-0']
  "
>
  <slot />
</div>
