<template>
  <div
    class="flex items-center text-14 cursor-pointer leading-none"
    :class="active && 'filter-link-active'"
    @click="$evt => $emit('click', $evt)"
  >
    <span
      v-if="item.icon"
      class="transition-all mr-4 opacity-50"
      :class="`icon-${item.icon}`"
    />
    <span class="mr-8 whitespace-nowrap">{{ item.label }}</span>
    <b-badge
      v-if="hasCount"
      class="badge-xs mt-px"
      :variant="
        active
          ? 'count-active'
          : 'count'"
    >
      {{ item.count }}
    </b-badge>
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { isNotNil } from 'ramda-adjunct'

export default defineComponent({
  name: 'CategoryListItem',
  components: {

  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hasCount = computed(() => isNotNil(props.item?.count));

    return {
      hasCount,
    }
  }
})
</script>
