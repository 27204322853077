
<li
  :href="`#node-${node._id}`"
  :class="[
    'group toc-item heading',
    (node._id === selectedNodeId) && 'active',
  ]"
  @click="$emit('go-to-node')"
>
  <i class="active-indicator" />
  <p class="truncate">{{ node.strippedTitle }}</p>
</li>
