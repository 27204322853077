
<!-- ------------------------------ -->
<!-- META INFO -------------------- -->
<!-- ------------------------------ -->
<div
  class="
    text-gray-700 leading-none dark:text-darkGray-500
    inline-flex items-center gap-4
  "
>
  <!-- Author of notification -->
  <span v-if="authorCount" class="capitalize">
    {{ authorCount }} {{ $tc('common.authors', authorCount) }} •
  </span>
  <span v-else-if="authorName">{{ authorName }} • </span>

  <!-- Project -->
  <template v-if="showProject && project$">
    <router-link
      :to="{
        name: PROJECT,
        params: {
          organizationId: notification.organization,
          projectId: notification.project,
        },
      }"
      @click.native="$event.stopImmediatePropagation()"
    >
      {{ project$.name }}
    </router-link>
    <span>•</span>
  </template>

  <!-- Date of notification -->
  <span
    v-b-tooltip="{
      placement: 'top',
      trigger: 'hover',
      variant: 'info',
      delay: { show: 200, hide: 0 },
      title: $options.filters.dateTime(notification.time),
    }"
  >
    <LiveFilter
      filter="timeago"
      locale="en-US"
      :value="notification.time"
    />
  </span>
  <b-badge
    v-if="showClientInfo && clientInfo"
    variant="secondary"
    class="font-light capitalize"
  >
    {{ clientInfo }}
  </b-badge>
</div>
