<template>
  <portal :to="`content-block-settings-${nodeId}`">
    <div class="flex flex-row">
      <DocumentNodeEditorToolbarSeparator />
      <DocumentNodeEditorToolbarGroup label="Settings">
        <DocumentContentBlockSpacerSettings
          :popover-target-id="popoverTargetId"
          prevent-popover-close
          :value="contentBlock.settings"
          @input="(settings, data) => $emit('update', { settings, ...data })"
        />
      </DocumentNodeEditorToolbarGroup>
    </div>
  </portal>
</template>

<script>
import DocumentNodeEditorToolbarGroup from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarGroup.vue'
import DocumentNodeEditorToolbarSeparator from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSeparator.vue'
import DocumentContentBlockSpacerSettings from './DocumentContentBlockSpacerSettings.vue'

export default {
  name: 'DocumentContentBlockButtonToolbarPortal',
  components: {
    DocumentNodeEditorToolbarGroup,
    DocumentNodeEditorToolbarSeparator,
    DocumentContentBlockSpacerSettings,

  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    popoverTargetId: {
      type: String,
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },

  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" module>

</style>
