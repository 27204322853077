import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { useGet } from 'feathers-vuex'
import { useRouteParams } from '@/v2/lib/composition/useRouter'

export default function useCurrentProject() {
  const { Project } = Vue.$FeathersVuex.api
  const { projectId } = useRouteParams(['projectId'])

  const queryWhen = computed(() => !Project.getFromStore(projectId))

  const { item: project } = useGet({
    queryWhen,
    model: Project,
    id: projectId,
  })

  return project
}
