<template>
  <ScrollContainer
    class="
      lg:overflow-y-auto lg:flex lg:overscroll-contain lg:h-[calc(100vh-143px)]
      lg:rounded-br-lg lg:rounded-bl-lg w-full
    "
  >
    <div class="w-full">
      <!-- <carousel
        :starting-image="0"
        :images="images"
      /> -->
      <b-img :src="template.thumbnail" fluid />
      <div class="py-48 mx-auto max-w-prose">
        <h2 class="mb-18 font-bold text-32 text-gray-500">{{ template.project$.name }}</h2>
        <div class="text-gray-600">
          <p class="text-16 mb-12 font-semibold">{{ template.heading }}</p>
          <ul class="text-14 list-disc ml-16">
            <li v-for="feature in template.features" :key="feature">{{ feature }}</li>
          </ul>
        </div>
        <div class="pt-32">
          <Button
            class="whitespace-nowrap"
            size="xl w-full"
            :is-loading="pending"
            @click="$emit('use')"
          >
            <span class="w-24 mr-4 icon_v2-so_tick text-24" />Use this template
          </Button>
        </div>
      </div>
    </div>
  </ScrollContainer>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import ScrollContainer from '@/components/ScrollContainer.vue'
import Button from '@/components/Button.vue'
import Carousel from '@/components/Carousel.vue'

export default defineComponent({
  name: 'ProjectTemplatePreview',
  components: {
    Carousel,
    ScrollContainer,
    Button,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const images = computed(() => (props.template.images ?? []).map(img => ({
    //   id: img._id,
    //   big: img.image,
    //   thumb: img.thumbnail,
    // })))

    // return {
    //   images,
    // }
  },
})
</script>
