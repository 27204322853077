<template>
  <component
    :is="component"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import TocItemHeading from './TocItemHeading.vue'
import TocItemQa from './TocItemQa.vue'

const tocItemComponentMap = {
  [CONTENT_BLOCK_CATEGORY.ElementHeading]: TocItemHeading,
  [CONTENT_BLOCK_CATEGORY.ElementQA]: TocItemQa,
}

export default defineComponent({
  name: 'TocItem',
  props: {
    category: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const component = computed(() => tocItemComponentMap[props.category])

    return {
      component,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
