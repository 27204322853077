<template>
  <div>
    <template v-if="!isFolderEmpty">
      <SlickList
        v-if="nodes.length"
        v-model="nodes"
        :disabled="isViewer || !permissions.update"
        lock-to-container-edges
        axis="xy"
        use-drag-handle
        custom-container-id="appScrollContainer"
        :custom-container-offset-y="15"
        :class="
          viewContext.viewAs === 'list'
            ? 'flex flex-col justify-center items-stretch'
            : 'flex flex-wrap gap-y-12'
        "
        @sort-start="handleSortStart"
        @sort-end="handleSortEnd"
      >
        <SlickItem
          v-for="(nodeId, index) in nodes"
          :key="nodeId"
          :index="index"
          tag="div"
          :disabled="!isUnsorted || !permissions.update"
          :class="[
            isLargeGrid && 'fs-card-width-l',
            isSmallGrid && 'fs-card-width-m',
          ]"
        >
          <ActionsToolbar
            v-if="!isViewer && !$isMobile"
            :show-delete="
              permissions.remove
                && nodeId !== publicNodeId
                && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED
            "
            :show-move="
              (permissions.create && permissions.remove)
                && nodeId !== publicNodeId
                && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED
            "
            :show-drag="permissions.update && isUnsorted"
            :show-customize="
              permissions.update
                && nodeId !== publicNodeId
                && mappedNodes.get(nodeId)?.category !== CATEGORY.File
                && viewContext.viewAs !== 'list'
                && mappedNodes.get(nodeId)?.status !== DOCUMENT_STATUS.APPROVED"
            :show-hover="!editNodes.nodesBeingEdited.includes(nodeId)"
            :show-download="mappedNodes.get(nodeId)?.category === CATEGORY.File"
            @customize="toggleEditOverlay(nodeId)"
            @remove="removeFile(nodeId)"
            @move="openFolderPicker(nodeId)"
            @download="downloadFile(nodeId)"
          >
            <DocumentNodeFolderEditable
              :node-id="nodeId"
              parent="root"
              :index="index"
              :is-first="index === 0"
              :is-last="index === children.length - 1"
              :view-as="viewContext.viewAs"
              :read-only="!permissions.update"
            >
              <template #edit>
                <transition
                  name="toolbar"
                  appear
                  enter-active-class="transition-all duration-150 ease-out"
                  leave-active-class="transition-all duration-100 ease-in"
                  enter-class="scale-75 opacity-0"
                  enter-to-class="scale-100 opacity-100"
                  leave-class="scale-100 opacity-100"
                  leave-to-class="scale-75 opacity-0"
                >
                  <DocumentThemeEditor
                    v-if="editNodes.nodesBeingEdited.includes(nodeId)"
                    :node-id="nodeId"
                    :document="mappedNodes.get(nodeId)"
                    :view-as="viewContext.viewAs"
                    @save="toggleEditOverlay(nodeId)"
                    @discard="toggleEditOverlay(nodeId)"
                  />
                </transition>
              </template>
            </DocumentNodeFolderEditable>
          </ActionsToolbar>
          <DocumentNodeFolderEditable
            v-else
            :node-id="nodeId"
            parent="root"
            :index="index"
            :is-first="index === 0"
            :is-last="index === children.length - 1"
            :view-as="viewContext.viewAs"
          />
        </SlickItem>
      </Slicklist>
    </template>
    <template v-else>
      <div class="w-full flex items-center justify-center h-235 md:h-420">
        <h3 class="font-normal text-gray-400 dark:text-darkGray-300">
          {{ $t('fs.folder-placeholder') }}
        </h3>
      </div>
    </template>
    <ProjectFolderSelectModal
      v-if="isFolderSelectOpen"
      key="item-folder-select-modal"
      v-model="isFolderSelectOpen"
      title="Select destination"
      ok-label="Move"
      :hidden-folder-ids="[docSelectedForMove]"
      :project-id="currentFolder.project"
      @select="moveFile"
    />
  </div>
</template>

<script>
import { sort, without, replace } from 'ramda'
import { useFind } from 'feathers-vuex'
import { defineComponent, computed, ref, inject, reactive } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import { useStructureBranch, useStructureReorderChildren } from '@/v2/services/documentStructures/documentStructuresCompositions'
import useCustomFileSystem from '@/v2/lib/composition/useCustomFileSystem'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import { STATUS as DOCUMENT_STATUS } from '@/types/document'
import { changeCursorStyle } from '@/helpers/dom'
import ActionsToolbar from '@/components/ActionsToolbar.vue'
import ProjectFolderSelectModal from '@/components/ProjectFolderSelectModal.vue'
import DocumentThemeEditor from '@/components/DocumentThemeEditor.vue'
import DocumentNodeFolderEditable from './DocumentNodeFolderEditable.vue'

export default defineComponent({
  name: 'DocumentNodeFolder',
  components: {
    DocumentNodeFolderEditable,
    SlickList,
    SlickItem,
    ActionsToolbar,
    ProjectFolderSelectModal,
    DocumentThemeEditor,
  },
  inheritAttrs: false,
  props: {
    viewContext: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const { DocumentNode } = context.root.$FeathersVuex.api
    const isFolderSelectOpen = ref(false)
    const isUnsorted = computed(() => props.viewContext.sortBy === 'unsorted')
    const isList = computed(() => props.viewContext.viewAs === 'list')
    const isLargeGrid = computed(() => props.viewContext.viewAs === 'grid')
    const isSmallGrid = computed(() => props.viewContext.viewAs === 'smallGrid')

    let nodeSelectedForMove = null
    const docSelectedForMove = ref(null)

    const editNodes = reactive({
      nodesBeingEdited: [],
    })
    const currentFolder = inject('document')
    const isClientPortal = inject('isClientPortal', false)
    const isViewer = inject('isViewer', false)

    const structureBranch = useStructureBranch()
    const { children, isEmpty: isFolderEmpty } = structureBranch('root')

    // nodes
    const { items: allNodes } = useFind({
      model: DocumentNode,
      params: computed(() => ({ query: { _id: { $in: children.value } } })),
    })
    const mappedNodes = computed(() => new Map(allNodes.value?.map(
      node => [node._id, node.contentBlock$.embeddedDocument$]
    ) ?? []))


    const orderedChildren = computed(() => (isUnsorted.value ? children.value : sort((a, b) => {
      const embeddedDocA = mappedNodes.value.get(a)
      const embeddedDocB = mappedNodes.value.get(b)

      return embeddedDocA[props.viewContext.sortBy]?.localeCompare(embeddedDocB[props.viewContext.sortBy]) ?? ''
    }, children.value ?? [])))

    const publicNodeId = computed(() => {
      const publicNode = allNodes.value?.find(
        node => node?.contentBlock$?.embeddedDocument$?.category === CATEGORY.ProjectPublicFolder
      ) ?? null
      return publicNode?._id ?? null
    })


    const structureReorderChildren = useStructureReorderChildren()
    const { openFile, removeFile, moveFileToFolder } = useCustomFileSystem()

    /** @type {String[]} */
    const nodes = computed({
      set: newOrder => structureReorderChildren({ parent: 'root', children: newOrder }),
      get: () => orderedChildren.value,
    })

    const handleSortStart = () => {
      changeCursorStyle('grabbing')
    }
    const handleSortEnd = () => {
      changeCursorStyle('')
    }

    const openFolderPicker = nodeId => {
      isFolderSelectOpen.value = true // Open modal
      nodeSelectedForMove = nodeId // Remember file which is to be moved
      // Used to hide it from project treee modal so we can't
      // move a folder inside itself.
      docSelectedForMove.value = mappedNodes.value.get(nodeId)?._id
    }

    const moveFile = targetFolderId => {
      // PREVENT MOVING FOLDER INSIDE ITSELF
      if (docSelectedForMove.value !== targetFolderId) {
        moveFileToFolder(nodeSelectedForMove, targetFolderId)
        isFolderSelectOpen.value = false // Close modal
      }
    }


    const toggleEditOverlay = nodeId => {
      if (editNodes.nodesBeingEdited.includes(nodeId)) {
        editNodes.nodesBeingEdited = without(nodeId, editNodes.nodesBeingEdited)
      } else {
        editNodes.nodesBeingEdited.push(nodeId)
      }
    }

    const enableProxy = import.meta.env.VITE_ENABLE_S3_PROXY
    const proxyUrl = url => {
      if (enableProxy === '1') return replace(/^https:\/\/s3.*?\.amazonaws\.com\//, '/files/', url)
      return url
    }

    const downloadFile = nodeId => {
      const doc = mappedNodes.value.get(nodeId)
      const url = proxyUrl(doc.file.url)
      const link = document.createElement('a');
      link.href = url;
      link.download = doc.file.name;
      link.click();
      document.removeChild(link)
    }

    return {
      // State
      isClientPortal,
      isViewer,
      isFolderEmpty,
      children,
      nodes,
      isFolderSelectOpen,
      currentFolder,
      editNodes,
      CATEGORY,
      DOCUMENT_STATUS,
      docSelectedForMove,
      // Methods
      handleSortStart,
      handleSortEnd,
      openFile,
      removeFile,
      moveFile,
      openFolderPicker,
      isUnsorted,
      publicNodeId,
      toggleEditOverlay,
      mappedNodes,
      downloadFile,
      isList,
      isLargeGrid,
      isSmallGrid,
    }
  },
})
</script>
<style lang="postcss" scoped>
.node-list-move {
  @apply transform  bg-white shadow-3xl z-50;
}
.drag-btn {
  /* visibility: hidden; */
  opacity: 0;
  position: relative;
  /* transition: opacity 0.2s linear; */
  right: 20px;
  cursor: pointer;
}
.drag-btn:hover {
  /* visibility: visible; */
  opacity: 1;
}

.so-node-dragging {
}

.so-node-dragging section {
  margin: 0!important;
}


</style>

<style lang="postcss">

.so-node-dragging .block-create-menu-button,
.so-node-dragging .node-highlight,
.so-node-dragging .editor-toolbar,
.so-node-dragging .block-actions-right,
.so-node-dragging .block-actions-move,
.so-node-dragging .resize-container .handler {
  display: none;
}

.so-node-dragging .resize-container::after {
  opacity: 0;
}

.so-slick-inner {
  @apply shadow-none;
}

.so-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-node-dragging .so-slick-inner .block-actions,
.so-node-dragging .so-slick-inner .block-add {
  display: none;
}

.so-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-90 bg-white bg-opacity-50;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
