
<div class="flex-none relative flex items-center mr-12">
  <!-- ------------------------------ -->
  <!-- VARIANT `author`  ------------ -->
  <!-- ------------------------------ -->
  <template v-if="variant === 'author'">
    <NotificationAvatar
      :notification="notification"
      :size="slim ? 32 : 40"
      :icon-size="parseInt(avatarFontSize) - 2"
      :font-size="parseInt(avatarFontSize)"
    />
  </template>
  <!-- ------------------------------ -->
  <!-- VARIANT `icon`  -------------- -->
  <!-- ------------------------------ -->
  <AvatarIcon
    v-else-if="variant === 'icon'"
    :class="iconClass"
    :slim="slim"
    :icon="icon"
  />

  <!-- ------------------------------ -->
  <!-- ICON BADGE ------------------- -->
  <!-- ------------------------------ -->
  <div
    v-if="badgeIcon || badgeCount"
    class="absolute -right-7 top-2 w-16 h-16"
    @click="$emit('click')"
  >
    <span
      class="w-16 h-16 flex items-center justify-center rounded-full text-white absolute"
      :class="badgeClass"
    >
      <span
        v-if="badgeIcon"
        class="w-10 text-10"
        :class="badgeIcon"
      />
      <span v-else-if="badgeCount" class="text-10">{{ badgeCount }}</span>
    </span>
  </div>
</div>
