<template>
  <b-button
    v-bind="$attrs"
    :disabled="disabled || isLoading"
    :variant="variant"
    :size="size"
    v-on="$listeners"
  >
    <slot />
    <Spinner
      v-if="isLoading"
      :size="16"
      variant="light"
      class="ml-8"
    />
  </b-button>
</template>
<script>
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Button',
  components: {
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'xs',
    },
  },
}
</script>
