<template>
  <ModalBasic
    v-bind="$attrs"
    title="Document Analytics"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    :dialog-class="$style['analytics-modal-dialog']"
    v-on="$listeners"
  >
    <template #body>
      <DocumentAnalyticsTimeline :document-id="documentId" />
    </template>
  </ModalBasic>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import DocumentAnalyticsTimeline from '@/components/DocumentAnalyticsTimeline.vue'


export default defineComponent({
  name: 'DocumentAnalyticsModal',
  components: {
    ModalBasic,
    DocumentAnalyticsTimeline,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
  },
  setup() {

  },
})
</script>

<style lang="scss" module>
.analytics-modal-dialog {
  max-width: 800px;
}
.table {
  margin-bottom: 32px;
}
.title {
  font-size: $fs18;
  margin: 0;
  flex: 1;
}
.header_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  .icon {
    flex: 0 0 auto;
    margin-right: 4px;
  }
}
.info {
  color: $green-30;
}

.note {
  color: $green-30;
  margin-bottom: 12px;
}
.empty {
  font-size: $fs16;
  color: $gray-500;
  margin: -8px 0 25px;
}
</style>
