<template>
  <div v-fragment>
    <DocumentContentBlockCostBreakdownToolbarPortal
      :popover-target-id="settingsPopoverTargetId"
      :content-block="contentBlock"
      :node-id="nodeId"
      @update="(data, options) => $emit('update', data, options)"
    />

    <div
      :class="[
        'block-element-cost-summary',

      ]"
      data-intercom-target="Price Breakdown Block"
    >
      <CostBreakdownTable
        :items="sortedBillables"
        :is-editable="isEditable"
        :document-id="document._id"
        :node-width="nodeWidth"
      />
      <!-- Table Total -->
      <TableTotal
        :total="document.cost || 0"
        :document-id="document._id"
      />

      <!-- Estimate Progress -->
      <template v-if="showTotalGraph">
        <progress-bar :ratio="approvedRatio" />

        <div class="cb-cols portal-paragraph-font">
          <div class="cb-col is-approved">
            <h3 class="cb-heading capitalize">
              {{ $t('common.approved') }}
            </h3>
            <div class="cb-cost portal-heading-font">
              <DocumentCurrency :document-id="document._id" :value="document.approvedCost" />
            </div>
            <small class="cb-progress portal-paragraph-font">
              {{ approvedRatio | percent }}
            </small>
          </div>
          <div class="cb-sep" />
          <div class="cb-col">
            <h3 class="cb-heading portal-paragraph-font">
              {{ $t('blocks.cost.remaining') }}
            </h3>
            <div class="cb-cost portal-heading-font">
              <DocumentCurrency :document-id="document._id" :value="document.notApprovedCost" />
            </div>
            <small class="'cb-progress portal-paragraph-font">
              {{ notApprovedRatio | percent }}
            </small>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  sortWith, descend, prop, ascend,
} from 'ramda'
import { computed, inject, getCurrentInstance, defineComponent } from '@vue/composition-api'

import { mergeElements } from '@/lib/array'
import { ratio } from '@/lib/math'
import ProgressBar from '@/components/Blocks/ProgressBar/ProgressBar.vue'
import TableTotal from '@/components/Blocks/TableTotal/TableTotal.vue'
import DocumentCurrency from '@/components/DocumentCurrency.vue'
import CostBreakdownTable from '@/components/CostBreakdownTable.vue'
import DocumentContentBlockCostBreakdownToolbarPortal from './DocumentContentBlockCostBreakdownToolbarPortal.vue'

const sortBillables = sortWith([
  descend(prop('isBillable')),
  ascend(prop('name')),
])

export default defineComponent({
  name: 'DocumentContentBlockCostBreakdown',
  components: {
    ProgressBar,
    TableTotal,
    DocumentContentBlockCostBreakdownToolbarPortal,
    CostBreakdownTable,
    DocumentCurrency,
  },
  inheritAttrs: false,
  props: {
    document: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')

    const vm = getCurrentInstance().proxy

    // settings
    const showTotalGraph = computed(() => props.contentBlock.settings.showTotalGraph)

    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const sortedBillables = computed(() => sortBillables(props.document.costMatrix || {}))

    const settingsPopoverTargetId = computed(
      () => `settings-popover-${props.nodeId}`
    )

    const approvedRatio = computed(() => ratio(
      props.document.approvedCost || 0,
      props.document.cost || 0
    ))

    const notApprovedRatio = computed(() => ratio(
      props.document.notApprovedCost || 0,
      props.document.cost || 0
    ))


    //  settings
    const openSettings = () => {
      setTimeout(
        () => vm.$root.$emit('bv::show::popover', settingsPopoverTargetId.value),
        0
      )
    }
    return {
      isEditable,
      sortedBillables,
      approvedRatio,
      notApprovedRatio,

      //  settings
      openSettings,
      settingsPopoverTargetId,
      showTotalGraph,
    }
  },
})
</script>

<style lang="scss" scoped>
.cb-cols {
  display: flex;
}
.cb-col {
  text-align: center;
  padding: $gutterx2 0 20px;
  color: $gray-500;
  flex: 1;
}
.cb-sep {
  width: 1px;
  flex: 0 0 1px;
  background-color: $gray-300;
}
.cb-heading {
  text-transform: uppercase;
  font-size: $fs12;
  font-weight: $semibold;
  .is-approved > & {
    color: $green-400;
  }
}
.cb-cost {
  font-size: $fs25;
  font-weight: $semibold;
  line-height: 1;
  margin-bottom: $gutter;
  .is-approved > & {
    color: $green-400;
  }
}
.cb-progress {
  font-size: $fs15;
  font-weight: $semibold;
  .is-approved > & {
    color: rgba($green-400, 0.6);
  }
}
.cb-sep-title {
  text-align: center;
  padding: $gutter 0;
}

// Status Variants
.cb-count-statuses {
  display: flex;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}
.cb-count-statuses-col {
  flex: 1;
  padding: 21px 26px;
  text-align: center;
  border-right: 1px solid $gray-300;
  &:last-child {
    border-right: none;
  }
  h3 {
    font-size: $fs13;
    font-weight: $medium;
    white-space: nowrap;
    margin-bottom: 0.6rem;
  }
  div {
    font-size: $fs24;
    margin-bottom: 0.6rem;
  }
  small {
  }
}

.billable-name {
  vertical-align: middle;
}

.billable-badge {
  margin-left: 0.5em;
}
</style>
