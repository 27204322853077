
<section
  :id="`node-${node._id}`"
  ref="nodeRef"
  v-observe-visibility="{
    callback: onVisibilityChanged,
    intersection: {
      rootMargin: '-120px 0px 0px 0px',
      threshold: 0,
    }
  }"
  v-click-outside.stop="onBlur"
  :class="[
    !width && 'flex-1',
    cssClass,
    rootClass,
    pulse && 'pulse-me',
    'node',
    isNodeBeingCut && 'so-node-cut',
  ]"
  :style="{
    width,
    ...style,
    //...cssVars
  }"
  @click.stop="onToggleFocus"
  @mouseover.stop="onMouseOver"
  @mouseleave="onMouseLeave"
  @contextmenu="handleContextmenuAction"
>
  <portal v-if="$scopedSlots['styling-settings']" :to="`node-settings-${node._id}`">
    <div slot-scope="settingsScope">
      <slot name="styling-settings" v-bind="settingsScope" />
    </div>
  </portal>

  <slot
    name="plugin"
    v-bind="{
      config,
      styling,
      textVariant,
      isFocus,
      isHover,
    }"
  />
  <div
    class="relative flex-col items-center screen:flex screen:h-full block-section-wrapper"
    :class="[`text-variant-${textVariant}`, innerClass]"
    :style="styleBgColor"
  >
    <div
      v-if="hasBgImage"
      class="background-image-overlay"
      :style="styleBgImage"
    />
    <div
      class="relative flex-1 w-full print:mx-auto block-section"
      :class="sectionClass"
      :style="styleSection"
    >
      <div
        class="h-full block-section-content"
        :class="contentClass"
        @click.stop="onSetFocus"
      >
        <slot v-bind="{ config, styling, textVariant, isFocus, isHover, nodeWidth }" />
      </div>
    </div>
  </div>
  <!-- Selection highlight -->
  <div
    v-if="isSelected"
    :class="'absolute inset-0 bg-green-500 bg-opacity-25 border border-dashed '
      + 'border-green-500 select-none pointer-events-none -mt-px z-1'"
  />
  <!-- Focus Selection highlight -->
  <div
    v-if="isFocus && !isViewer && !isSandbox"
    :class="'absolute inset-0 border border-dashed print:hidden '
      + 'border-green-500 select-none pointer-events-none -mt-px z-10'"
  />
  <!-- Comments Icon Portal to Block Actions -->
  <portal :to="`comments-${node._id}`">
    <div
      v-if="!hideNodeComments && !isGroupBasic && !isSandbox"
      :class="[
        'w-32 h-32 flex items-end justify-start relative'
      ]"
    >
      <b-button
        v-b-tooltip.hover.top.v-info.dh0.ds200
        variant="transparent"
        :class="[
          'w-32 h-32 flex items-center justify-center outline-none',
          'hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none',
          'active:outline-none',
          nodeCommentsCount ?
            'text-green-500 hover:text-green-300'
            : 'text-gray-100 hover:text-gray-100'
        ]"
        title="Comments"
        @click.stop="toggleCommentsWithNode(node._id)"
      >
        <span
          class="relative w-20 text-16"
          :class="[
            'icon_v2-so_chat',
          ]"
        ></span>
        <span
          v-if="nodeCommentsCount"
          :class="[
            'comment-count',
            commentsSidebarStatus.nodeId === node._id ? 'bg-green-500' : 'bg-gray-700',
          ]"
        >{{ nodeCommentsCount }}</span>
      </b-button>
    </div>
  </portal>
</section>
