
<div
  class="
    px-12 py-8 text-14
    text-gray-800 dark:text-darkGray-300 rounded-md shrink-0
    bg-yellow-50 dark:bg-yellow-50/10
    whitespace-pre-wrap leading-relaxed
  "
>
  {{ actionItem.notes }}
</div>
