
<div
  class="
    flex items-center
    text-gray-400 dark:text-darkGray-400 text-14
  "
>
  <template v-if="document.updatedAt">
    <span class="mr-4">{{ $t('common.last-update') }}</span>
    <LiveFilter filter="timeago" :value="document.updatedAt" />
    <span v-if="document.updatedBy" class="mr-6">&nbsp;by</span>
    <AvatarUser
      v-if="document.updatedBy"
      :user-id="document.updatedBy"
      show-avatar
      font-size="14"
    />
  </template>
</div>
