<template>
  <div
    class="relative z-10 p-16"
    data-intercom-target="Project Quick Links"
  >
    <!-- grid grid-cols-4 w-full auto-cols-fr gap-24 -->
    <div class="relative group quick-links">
      <template v-if="project.portalQuickLinks.length">
        <ProjectQuickLink
          v-for="link in project.portalQuickLinks"
          :key="link._id"
          :link="link"
        />
      </template>

      <ProjectQuickLinkPlaceholder
        v-if="canManage"
        @click="$emit('create')"
      />

      <!-- redirect to project quick links settings -->
      <ProjectDashboardQuickLinksManage
        v-if="canManage"
        :to="routes.settingsQuickLinks"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ProjectDashboardQuickLinksManage from '@/components/ProjectDashboardQuickLinksManage.vue'
import ProjectQuickLink from '@/components/ProjectQuickLink.vue'
import ProjectQuickLinkPlaceholder from '@/components/ProjectQuickLinkPlaceholder.vue'

export default defineComponent({
  name: 'ProjectQuickLinks',
  components: {
    ProjectDashboardQuickLinksManage,
    ProjectQuickLink,
    ProjectQuickLinkPlaceholder,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    canManage: {
      type: Boolean,
      default: false,
    },
    routes: {
      type: Object,
      default: null,
    },
  },
})
</script>
<style lang="postcss" scoped>
.quick-links {
  @apply gap-16 flex justify-center flex-wrap;
}

.quick-links > * {
  width: 100px;
}
</style>
