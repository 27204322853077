<template>
  <div
    v-bind="$attrs"
    class="w-full"
    :class="[ { dark }, wrapperClass]"
    :style="theme"
  >
    <div
      :class="['page-background' && !noDarkMode, className]"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
import useProjectTheme from '@/v2/lib/composition/useProjectTheme'

export default defineComponent({
  name: 'ProjectTheme',
  props: {
    className: {
      type: [String, Array, Object],
      default: '',
    },
    wrapperClass: {
      type: [String, Array, Object],
      default: '',
    },
    project: {
      type: Object,
      default: null,
    },
    // In some cases, we only need to set colors and fonts
    // and ignore the dark mode setting.
    // eg: when viewing documents
    noDarkMode: {
      type: Boolean,
      default: false,
    },
    // Documents can have custom paragraph and heading fonts
    // so we need an option to override them CSS variables
    documentOverride: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const isClientPortal = inject('isClientPortal', false)

    const theme = useProjectTheme({
      project: computed(() => props.project),
      documentOverride: computed(() => props.documentOverride),
    })

    const dark = computed(() => [
      isClientPortal,
      props.project?.portalTheme === 'dark',
      !props.noDarkMode,
    ].every(Boolean))

    return {
      theme,
      isClientPortal,
      dark,
    }
  },
})
</script>
