
<ScrollContainer name="appScrollContainer" scroll-on-body>
  <div
    class="layout"
    :class="{
      'mobile-sticky-footer': mobileStickyFooter,
      'is-mobile': $isMobile,
      'dark-theme dark': darkTheme,
      'light-theme': !darkTheme,
      'hide-sidebar': !hasSidebar || $isMobile,
      'hide-toolbar': !hasToolbar || $isMobile,
      'hide-sidebar-right': !hasSidebarRight,
      'hide-page-header': !hasPageHeader,
      'hide-footer': !hasPageFooter,
    }"
  >
    <!-- header -->
    <header>
      <ButtonIcon
        v-if="hasSidebar && $isMobile"
        v-b-toggle.mobile-menu-sidebar
        icon="icon-hamburger"
        class="mr-16"
      />
      <slot name="header" />
    </header>

    <!-- toolbar + sidebar -->
    <aside
      v-if="(hasToolbar || hasSidebar) && !$isMobile"
      class="aside-left"
    >
      <!-- toolbar -->
      <nav v-if="hasToolbar" class="toolbar">
        <slot name="nav" />
      </nav>

      <!-- sidebar -->
      <nav v-if="hasSidebar" class="sidebar">
        <!-- sidebar header -->
        <div class="sidebar-header">
          <portal-target name="app-sidebar-header" slim />
          <slot name="sidebar-header" />
        </div>
        <!-- sidebar content -->
        <div class="sidebar-content">
          <portal-target name="app-sidebar" slim />
          <slot name="sidebar" />
        </div>
      </nav>
    </aside>

    <!-- main content -->
    <main>
      <div v-if="hasPageHeader" class="page-header">
        <portal-target name="page-header" slim />
      </div>
      <div class="page-content grid">
        <slot name="main" />
      </div>
    </main>

    <aside v-if="hasSidebarRight" class="aside-right">
      <portal-target name="app-sidebar-right" slim />
    </aside>

    <!-- footer -->
    <footer v-if="hasPageFooter">
      <div class="footer-content">
        <portal-target name="page-footer" slim />
      </div>
    </footer>
  </div>

  <!-- mobile drawer -->
  <b-sidebar
    v-if="hasSidebar && $isMobile"
    id="mobile-menu-sidebar"
    sidebar-class="bg-gray-100/90 backdrop-blur-md"
    body-class="px-8"
    header-class="!p-0"
    backdrop
    backdrop-variant="dark"
    shadow
  >
    <template #header="{ hide }">
      <div
        class="
          flex items-center justify-between w-full mb-8 bg-gray-200   px-16 py-8
        "
      >
        <portal-target name="app-sidebar-header" slim />
        <ButtonIcon icon="icon_v2-so_close text-36" @click="hide" />
      </div>
    </template>
    <template #default>
      <portal-target name="app-sidebar" slim />
    </template>
  </b-sidebar>
  <div
    v-if="$isMobile && hasToolbar"
    class="
      fixed top-auto bottom-0 left-0 right-0
      bg-gray-100/30 p-8 z-1000 backdrop-blur-md
    "
  >
    <slot name="nav" />
  </div>
  <slot name="modals" />
  <portal-target name="right-drawer" slim />
  <portal-target name="body" slim />
</ScrollContainer>
