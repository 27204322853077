<template>
  <LoaderWebFont :families="[styling.font || paragraphFont]">
    <div
      :class="[
        'block-element-text',
        `text-variant-${textVariant}`,
        `text-${styling.textAlign}`,
        'portal-paragraph-font',
      ]"
      :style="{
        color: styling.textColor,
        fontFamily: styling.font || paragraphFont,
        ...cssVars,
      }"
      data-intercom-target="Rich Text Block"
      @contextmenu.stop
    >
      <TextEditor
        v-if="isEditable"
        :lazy="true"
        :value="contentBlock.content"
        @input="val => $emit('update', { content: val })"
      />
      <div
        v-else
        class="text-viewer editor-content editor-content-preview"
        v-html="contentBlock.content"
      />
    </div>
  </LoaderWebFont>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import LoaderWebFont from '@/components/LoaderWebFonts.vue'

export default {
  name: 'DocumentContentBlockText',
  components: {
    TextEditor,
    LoaderWebFont,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const document = inject('document')

    const isEditable = computed(() => isEditor.value && !isLocked.value)
    const paragraphFont = computed(() => document.value.brandingFontParagraph)
    const headingFont = computed(() => document.value.brandingFontHeading)
    const cssVars = computed(() => ({
      '--so-heading-font': headingFont.value || 'var(--cp-heading-font)',
      '--so-paragraph-font': props.styling?.font || paragraphFont.value || 'var(--cp-paragraph-font)',
    }))
    return {
      isEditable,
      document,
      paragraphFont,
      headingFont,
      cssVars,
    }
  },
}
</script>

<style lang="scss" scoped>
.block-element-text {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  @media print {
    padding: 0;
  }
}
.block-element-text-empty {
  padding: $gutterx2;
  color: $secondary;
  background-color: $white;
  position: absolute;
  left: 0;
  top: 0;
}

.text-variant-light {
  color: white;
}

</style>
