<template>
  <portal to="page-footer">
    <div class="flex justify-between items-center flex-1">
      <slot />
      <slot name="right" />
    </div>
  </portal>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PageFooter',
})
</script>
