
<div v-if="showProgress" v-fragment>
  <!-- Sidebar Variant  -->

  <div v-if="variant === 'sidebar'" :class="'card sidebar-wrapper dark:bg-darkGray-800'">
    <!-- ----------------- -->
    <!--  NOT STARTED  -->
    <!-- ----------------- -->
    <div
      v-if="step === STEPS.NOT_STARTED"
      class="flex flex-col items-start justify-center space-y-8 w-full"
    >
      <span class="text-14 text-gray-500">{{ $t('qa-progress.qa-progress') }}</span>
      <ButtonProgress
        :is-loading="false"
        :disabled="false"
        :value="0"
        :max="0"
        @click="handleSubmit('START')"
      >
        <span class="icon-util-resume text-14 w-14 mr-4" />
        {{ $t('qa-progress.get-started') }}
      </ButtonProgress>
    </div>

    <!-- ----------------- -->
    <!--  While Answering  -->
    <!-- ----------------- -->
    <div
      v-if="step === STEPS.IN_PROGRESS"
      :class="[
        'flex flex-row items-start justify-between w-full lg:w-240'
      ]"
    >
      <Confetti v-if="showConfetti" />

      <!-- Content -->
      <div
        :class="[
          'flex lg:flex-col items-center lg:items-start justify-start flex-1 lg:space-y-16 lg:mr-24'
        ]"
      >
        <!-- Copy -->
        <div class="grow">
          <span class="text-14 text-gray-500 dark:text-darkGray-500">{{ $t('qa-progress.your-progress') }}</span>
          <p class="text-gray-700 dark:text-darkGray-400 grow flex-1 text-14 lg:text-18 leading-none">
            {{ $t('qa-progress.completed',{totalAnswered, totalQuestions}) }}
          </p>
        </div>

        <!-- Submit Button -->
        <ButtonProgress
          v-if="showSubmitButton"
          :is-loading="false"
          :disabled="false"
          :value="totalAnswered"
          :max="totalQuestions"
          @click="handleSubmit('SUBMIT')"
        >
          <span class="icon-util-notify text-14 w-14 mr-4" />
          {{ step }}
        </ButtonProgress>
      </div>

      <ProgressCircle
        v-if="!isMobile"
        :diameter="56"
        :completed-steps="totalAnswered"
        :total-steps="totalQuestions"
        :stroke-width="6"
        :inner-stroke-width="6"
        start-color="#3FD255"
        stop-color="#23B839"
        inner-stroke-color="#00000010"
      >
        <span class="text-12 text-gray-600 dark:text-darkGray-400 font-semibold">
          {{ Math.round((totalAnswered/totalQuestions)*100) }}%
        </span>
      </ProgressCircle>
    </div>

    <!-- ------------------------- -->
    <!--  SUBMISSION CONFIRMATION  -->
    <!-- ------------------------- -->
    <div
      v-if="step === STEPS.SUBMITTING"
      class="flex flex-col items-start justify-center space-y-12 w-full lg:w-240"
    >
      <div>
        <span class="text-14 text-gray-500 dark:text-darkGray-500">{{ $t('common.summary') }}</span>
        <p class="text-gray-700 dark:text-darkGray-400">
          {{ $t('qa-progress.notify',{name:organization.name}) }}
        </p>
      </div>

      <div class="flex w-full flex-row items-center justify-between">
        <ButtonProgress
          :is-loading="false"
          :disabled="false"
          @click="handleSubmit('CONFIRM')"
        >
          <span class="icon_v2-so_tick text-14 w-14 mr-4" />
          {{ step }}
        </ButtonProgress>
        <b-button
          variant="slim"
          size="xs"
          class="font-normal text-gray-600 underline hover:underline hover:text-gray-800"
          @click="handleSubmit('DISCARD')"
        >
          {{ $t('common.discard') }}
        </b-button>
      </div>
    </div>


    <!-- ----------------------- -->
    <!-- Submission Success      -->
    <!-- ----------------------- -->
    <div
      v-if="step === STEPS.FINISHED"
      :class="[
        'flex flex-row items-center justify-start w-full lg:w-240',
      ]"
    >
      <Confetti />
      <div
        class="p-12 border-2 border-green-600 rounded-full flex items-center justify-center"
      >
        <span class="icon_v2-so_tick text-24 text-green-600" />
      </div>
      <div class="grow ml-24">
        <h5 class="font-semibold text-gray-800 dark:text-darkGray-300">
          {{ $t('qa-progress.content-submitted') }}
        </h5>
      </div>
    </div>
  </div>

  <!-- Card Variant
    TBD, this would be awesome added on the Brief cards
  -->

  <div
    v-if="variant === 'card'"
    v-b-tooltip.hover.top.v-info.dh0.ds200="'Questionnaire progress'"
    class="
    inline-flex items-center rounded
    font-normal whitespace-nowrap leading-none
    text-gray-700 dark:text-darkGray-400
    h-24 px-6 text-12 relative
    bg-gray-100 bg-opacity-70
    dark:bg-darkGray-900
    overflow-hidden
  "
    @click.stop="$emit('progress-clicked')"
  >
    <span class="inline-block mr-4 text-12 opacity-75 icon-object-QA" />
    <span>{{ totalAnswered }}/{{ totalQuestions }}</span>
    <div class="absolute inset-x-0 bottom-0 h-2">
      <b-progress
        :value="totalAnswered"
        :max="totalQuestions"
        class="flex-1 h-4 rounded-none"
      />
    </div>
  </div>

  <!-- Card Variant
    TBD, this would be awesome added on the Brief cards
  -->

  <div
    v-if="variant === 'inline'"
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Questionnaire progress'"
    class="flex items-center"
    :class="'inline-wrapper'"
  >
    <b-progress
      :value="totalAnswered"
      :max="totalQuestions"
      class="flex-1"
      :class="width"
    />
    <span
      class="block ml-8 leading-none text-gray-600 dark:text-darkGray-400"
    ><span
      class="text-gray-700 font-semibold dark:text-darkGray-300"
    ><span class="text-green-600">{{ totalAnswered }}</span>
      / {{ totalQuestions }}</span>
    </span>
  </div>

  <!-- HEADER -->
  <div
    v-if="variant === 'header'"
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Questionnaire progress'"
    :class="'header-wrapper'"
  >
    <b-progress
      :value="totalAnswered"
      :max="totalQuestions"
      class="flex-1 w-96"
    /><span
      class="block ml-8 leading-none text-gray-600"
    ><span
      class="text-gray-700"
    ><span class="text-green-600 font-semibold">{{ totalAnswered }}</span>
      / {{ totalQuestions }}</span></span>
  </div>
</div>
