
<div
  class="relative z-20 flex justify-center w-full h-full group"
  v-bind="$attrs"
>
  <slot />

  <div
    v-if="showDrag && cardDrag"
    class="absolute right-0 top-0"
  >
    <button
      v-handle
      type="button"
      class="text-gray-400 w-32 h-32 hover:text-gray-800"
      title="Drag to move"
      @click.stop
    >
      <span class="icon_v2-so_drag" />
    </button>
  </div>

  <!-- delete and edit buttons OR slot-->
  <div
    v-if="hasActions"
    class="block-actions transition-none opacity-0 invisible"
    :class="[
      sizeMap[size].wrapper,
      showHover && 'group-hover:visible group-hover:opacity-100'
    ]"
  >
    <!-- drag button -->
    <button
      v-if="showDrag && !cardDrag"
      v-handle
      v-b-tooltip.hover.top.v-info.dh0.ds200
      type="button"
      class="edit-button-wrapper"
      title="Drag to move"
      @click.stop
    >
      <span class="icon_v2-so_drag so-drag-icon" />
    </button>
    <button
      v-if="showEdit"
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Edit`"
      type="button"
      class="edit-button-wrapper"
      @click="$emit('edit')"
    >
      <span class="text-24 icon_v2-so_pencil"></span>
    </button>
    <button
      v-if="showCustomize"
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Change color & icon`"
      type="button"
      class="edit-button-wrapper"
      @click="$emit('customize')"
    >
      <span class="text-24 icon_v2-so_paint-bucket"></span>
    </button>
    <button
      v-if="showMove"
      v-b-tooltip.hover.top.v-info.dh0.ds200="'Move'"
      type="button"
      class="edit-button-wrapper"
      @click.stop="$emit('move')"
    >
      <span class="text-24 icon_v2-so_folder-move"></span>
    </button>
    <button
      v-if="showDownload"
      v-b-tooltip.hover.top.v-info.dh0.ds200="'Download'"
      type="button"
      class="edit-button-wrapper"
      @click.stop="$emit('download')"
    >
      <span class="text-24 icon_v2-so_download"></span>
    </button>

    <button
      v-if="showDelete"
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Delete`"
      type="button"
      class="edit-button-wrapper"
      @click.stop="$emit('remove')"
    >
      <span class="text-24 icon_v2-so_trash"></span>
    </button>
  </div>
</div>
