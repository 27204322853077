
<DocumentNodeRenderer
  :node="node"
  :root-class="$style['node-reusable-block']"
  :section-class="$style['node-section-reusable-block']"
  :config-override="{}"
>
  <template #default="{ styling, textVariant }">
    <DocumentContentBlockReusableBlock
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
