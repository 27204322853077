import {
  PORTAL_FS_FOLDER,
  PORTAL_FS_DOCUMENT,
  PORTAL_PINNED_FS_FOLDER,
  PORTAL_PINNED_FS_DOCUMENT,
  PORTAL_PACKAGED_SERVICES_DOCUMENT,
  PORTAL_PAGE,
} from '@/client-portal/router/types'
import { CATEGORY } from '../../documentsTypes'

const getLibraryRouteName = ({ isPinnedFolder, isPage }) => {
  if (isPinnedFolder) {
    return PORTAL_PINNED_FS_DOCUMENT
  }

  if (isPage) {
    return PORTAL_PAGE
  }

  return PORTAL_FS_DOCUMENT
}

// routes for portal
export default {
  // -- folders
  [CATEGORY.ProjectPublicFolder]: ({ document }) => ({
    name: PORTAL_FS_FOLDER,
    params: {
      folderId: document._id,
    },
  }),
  [CATEGORY.Folder]: ({ document, isPinnedFolder }) => ({
    name: isPinnedFolder ? PORTAL_PINNED_FS_FOLDER : PORTAL_FS_FOLDER,
    params: {
      folderId: document._id,
    },
  }),
  [CATEGORY.Document]: ({ document, requestContext, isPinnedFolder }) => ({
    name: isPinnedFolder ? PORTAL_PINNED_FS_DOCUMENT : PORTAL_FS_DOCUMENT,
    params: {
      folderId: document.parentFolder,
      documentId: document._id,
    },
    query: {
      grant: requestContext.document,
    },
  }),
  [CATEGORY.File]: ({ document, requestContext, isPinnedFolder }) => ({
    name: isPinnedFolder ? PORTAL_PINNED_FS_DOCUMENT : PORTAL_FS_DOCUMENT,
    params: {
      folderId: document.parentFolder,
      documentId: document._id,
    },
    query: {
      grant: requestContext.document,
    },
  }),
  [CATEGORY.FileLink]: ({ document, requestContext, isPinnedFolder }) => ({
    name: isPinnedFolder ? PORTAL_PINNED_FS_DOCUMENT : PORTAL_FS_DOCUMENT,
    params: {
      folderId: document.parentFolder,
      documentId: document._id,
    },
    query: {
      grant: requestContext.document,
    },
  }),
  // library docs
  [CATEGORY.DocumentCaseStudy]:
    ({ document, requestContext, folderId, isPinnedFolder, isPage }) => ({
      name: getLibraryRouteName({ isPage, isPinnedFolder }),
      params: {
        folderId,
        documentId: document._id,
      },
      query: {
        grant: requestContext.document,
      },
    }),
  [CATEGORY.DocumentTeamBio]:
    ({ document, requestContext, folderId, isPinnedFolder, isPage }) => ({
      name: getLibraryRouteName({ isPage, isPinnedFolder }),
      params: {
        folderId,
        documentId: document._id,
      },
      query: {
        grant: requestContext.document,
      },
    }),
  [CATEGORY.DocumentPackagedService]: ({ document, isPage }) => ({
    name: isPage ? PORTAL_PAGE : PORTAL_PACKAGED_SERVICES_DOCUMENT,
    params: {
      documentId: document._id,
    },
  }),
  [CATEGORY.DocumentPage]: ({ document }) => ({
    name: PORTAL_PAGE,
    params: {
      documentId: document._id,
    },
  }),
}
