<script>
import { computed, defineComponent } from '@vue/composition-api'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FileContent from '@/components/FileContent.vue'
import DocumentViewerContent from '@/components/Document/Viewer/DocumentViewerContent.vue'
import FolderContentList from '@/components/FolderContentList.vue'

const categoryToComponent = {
  [CATEGORY.Folder]: FolderContentList,
  [CATEGORY.Document]: DocumentViewerContent,
  [CATEGORY.File]: FileContent,
  [CATEGORY.FileLink]: FileContent,
  [CATEGORY.DocumentCaseStudy]: DocumentViewerContent,
  [CATEGORY.DocumentTeamBio]: DocumentViewerContent,
  [CATEGORY.DocumentReusableBlock]: DocumentViewerContent,
  [CATEGORY.DocumentPackagedService]: DocumentViewerContent,
  [CATEGORY.DocumentPage]: DocumentViewerContent,
}

export default defineComponent({
  name: 'DocumentGuestResolver',
  components: {

  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const resolvedComponent = computed(() => categoryToComponent[props.document.category])
    return () => context.slots.default({
      component: resolvedComponent.value,
    })
  },
})
</script>
