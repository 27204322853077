
<Button
  v-bind="$attrs"
  block
  size="md"
  variant="branded"
  class="text-15 font-semibold ring-1 rounded-md"

  v-on="$listeners"
>
  <span
    v-if="icon"
    :class="icon"
    class="text-24 w-24 mr-10"
  /> {{ label }}
</Button>
