<template>
  <ListLightDropdownItem
    :label="label"
    :icon="icon"
    has-submenu
    @select="$emit('select')"
  >
    <ul
      ref="menuRef"
      v-click-outside.capture="() => $emit('close')"
      class="dropdown-menu dropdown-submenu group-hover:block text-14"
      role="menu"
      @click.stop="$emit('close')"
    >
      <b-dropdown-header class="mb-4">
        Question types
      </b-dropdown-header>
      <slot />
    </ul>
  </ListLightDropdownItem>
</template>


<script>
import ListLightDropdownItem from '@/components/List/ListLightDropdownItem.vue'

export default {
  name: 'DocumentBlockButtonCreateSubMenu',
  components: {
    ListLightDropdownItem,

  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },

}
</script>

<style lang="postcss" scoped>

.dropdown-submenu {
  @apply absolute w-192 transform -top-32 -translate-x-4 left-full;
  bottom: initial;
}

</style>
