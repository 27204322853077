
<FeathersVuexGet
  :id="id"
  v-slot="{ item: document, ...rest }"
  watch="id"
  :edit-scope="editScope"
  :query-when="force || notInStore"
  :params="params"
  service="documents"
>
  <slot v-bind="{ document, ...rest }" />
</FeathersVuexGet>
