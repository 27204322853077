
<DocumentDrawer
  :title="$t('uploads.upload-details')"
  :close-label="$t('common.done')"
  @close="$emit('close')"
>
  <ul
    class="
      flex flex-col flex-grow flex-1 divide-y divide-gray-100
      dark:divide-darkGray-800 overflow-y-auto overscroll-contain pb-24
    "
  >
    <li
      v-for="file in pendingUploads"
      :key="file.uuid"
      class="flex items-center justify-between py-12 px-24"
    >
      <DocumentContentBlockAttachmentsFileUpload :file="file" />
    </li>
  </ul>
</DocumentDrawer>
