<template>
  <div class="flex-none relative flex items-center mr-12">
    <!-- ------------------------------ -->
    <!-- VARIANT `author`  ------------ -->
    <!-- ------------------------------ -->
    <template v-if="variant === 'author'">
      <NotificationAvatar
        :notification="notification"
        :size="slim ? 32 : 40"
        :icon-size="parseInt(avatarFontSize) - 2"
        :font-size="parseInt(avatarFontSize)"
      />
    </template>
    <!-- ------------------------------ -->
    <!-- VARIANT `icon`  -------------- -->
    <!-- ------------------------------ -->
    <AvatarIcon
      v-else-if="variant === 'icon'"
      :class="iconClass"
      :slim="slim"
      :icon="icon"
    />

    <!-- ------------------------------ -->
    <!-- ICON BADGE ------------------- -->
    <!-- ------------------------------ -->
    <div
      v-if="badgeIcon || badgeCount"
      class="absolute -right-7 top-2 w-16 h-16"
      @click="$emit('click')"
    >
      <span
        class="w-16 h-16 flex items-center justify-center rounded-full text-white absolute"
        :class="badgeClass"
      >
        <span
          v-if="badgeIcon"
          class="w-10 text-10"
          :class="badgeIcon"
        />
        <span v-else-if="badgeCount" class="text-10">{{ badgeCount }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { flipIncludes } from 'ramda-extension'
import NotificationAvatar from '@/components/NotificationAvatar.vue'
import AvatarIcon from '@/components/AvatarIcon.vue'

export default defineComponent({
  name: 'NotificationRendererIcon',
  components: {
    NotificationAvatar,
    AvatarIcon,
  },
  props: {
    notification: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      required: true,
      validator: flipIncludes(['author', 'icon']),
    },
    slim: {
      type: Boolean,
      default: false,
    },
    avatarShowName: {
      type: Boolean,
      default: true,
    },
    avatarFontSize: {
      type: String,
      default: null,
    },
    // -- icon props (for variant `icon`)
    icon: {
      type: String,
      default: null,
    },
    iconClass: {
      type: [String, Object, Array],
      default: null,
    },
    // -- badge props
    badgeIcon: {
      type: String,
      default: null,
    },
    badgeClass: {
      type: [String, Object, Array],
      default: null,
    },
    badgeCount: {
      type: Number,
      default: null,
    },
  },
})
</script>
