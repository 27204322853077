import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import membersService, { servicePath } from './membersService'

export class Member extends BaseModel {
  static modelName = 'Member'

  static setupInstance(data, { models }) {
    const { Organization, User } = models.api
    const _data = { ...data }

    if (_data.organization$) {
      _data.organization$ = new Organization(_data.organization$)
    }

    if (_data.user$) {
      _data.user$ = new User(_data.user$)
    }

    return _data
  }

  static instanceDefaults() {
    return {
      settings: {
        emailNotifications: {},
      },
      lastAccess: null,
    }
  }
}

export default makeServicePlugin({
  Model: Member,
  service: membersService,
  servicePath,
})
