import Vue from 'vue'

const focus = (el, { value }) => {
  if (value === undefined || Boolean(value)) {
    setTimeout(() => {
      el.focus()

      const contentEditableAttr = el.getAttribute('contenteditable')
      if (contentEditableAttr && contentEditableAttr.value !== 'false') {
        const range = document.createRange() // Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(el) // Select the entire contents of the element with the range
        range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start

        const selection = window.getSelection() // get the selection object (allows you to change selection)
        selection.removeAllRanges() // remove any selections already made
        selection.addRange(range)
      }
    }, 0)
  }
}

Vue.directive('focus', {
  inserted: focus,
  // update: focus,
})
