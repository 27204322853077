<script>
import { computed, defineComponent, toRef } from '@vue/composition-api'
import useFindByIds from '@/v2/lib/composition/useFindByIds'
import { indexBy } from 'ramda'

export default defineComponent({
  name: 'FindByIds',
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    model: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    indexed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { items, isPending } = useFindByIds(props.model, toRef(props, 'ids'), props.options)

    const data = computed(
      () => (props.indexed ? indexBy(item => item._id, items.value) : items.value)
    )

    return () => context.slots.default({
      items: data.value,
      isFindPending: isPending.value,
    })
  },
})
</script>
