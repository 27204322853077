
<div class="space-y-12 flex flex-col justify-stretch items-center">
  <FormMessage
    v-if="formError"
    supress-generic-message
    class="w-full"
  >
    {{ formError.message }}
  </FormMessage>
  <b-form class="space-y-12 w-full" @submit.prevent="formSubmit">
    <FormField
      :v="$v.email"
      :messages="{
        required: $t('login.email-required'),
        email: $t('login.email-not-valid'),
      }"
      wrapper-class="portal-paragraph-font"
    >
      <b-input
        autofocus
        autocomplete="username"
        :placeholder="$t('login.email')"
        class="form-control-lg portal-paragraph-font"
        :value="formState.email"
        @input="onEmailInput"
      />
    </FormField>

    <FormField
      :v="$v.password"
      :messages="{
        required: $t('login.password-required'),
      }"
      wrapper-class="portal-paragraph-font"
    >
      <FormInputPassword
        v-model="formState.password"
        :placeholder="$t('login.password')"
        input-class="portal-paragraph-font"
      />
    </FormField>
    <div class="links">
      <b-link @click="$emit('recover-password')">
        {{ $t('login.password-recover') }}
      </b-link>
    </div>
    <Button
      class="mt-16 portal-paragraph-font portal-button"
      type="submit"
      size="lg"
      variant="primary"
      :block="true"
      :is-loading="formIsBusy"
    >
      {{ $t('login.login') }}
    </Button>
  </b-form>
</div>
