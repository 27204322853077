<template>
  <li
    ref="element"
    class="task-list-item group/tasks portal-paragraph-font"
    :class="{ 'selected': selected }"
  >
    <slot />
  </li>
</template>
<script>
import { defineComponent, ref, toRef } from '@vue/composition-api'
import { useScrollIntoViewIfActive } from '@/v2/lib/composition/useScrollIntoView'

export default defineComponent({
  name: 'TaskListItem',
  props: {
    task: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const element = ref(null)
    useScrollIntoViewIfActive(element, toRef(props, 'selected'))

    return {
      element,
    }
  },
})
</script>
<style lang="postcss" scoped>
.task-list-item {
  @apply text-gray-500 hover:text-gray-800;
  @apply cursor-pointer relative;

  &:not(.selected) {
    @apply bg-transparent hover:border-gray-300;
  }

  &.selected {
    @apply bg-gray-200 text-gray-800 border-gray-300;
    @apply dark:bg-darkGray-900;
  }
}
</style>
