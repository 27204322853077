import { mergeDeepRight } from 'ramda'
import { getCurrentInstance, computed } from '@vue/composition-api'

export const useContentBlockSettings = (props, fields) => {
  const vm = getCurrentInstance().proxy
  // const defaults = settingsDefaults[inputType]

  const update = (key, val) => vm.$emit('input', mergeDeepRight(props.value, { [key]: val }))

  return fields.reduce((acc, field) => ({
    ...acc,
    [field]: computed({
      get() {
        // return propOr(defaults[field], field, props.value)
        return props.value[field]
      },
      set(value) {
        update(field, value)
      },
    }),
  }), {})
}
