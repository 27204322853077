// Icons of popular apps and services
// This will be used on Project Apps and/or Quick Links

export const brandIcons = [
  { name: 'Superokay', icon: 'brand-superokay' },
  { name: 'Aabstract', icon: 'brand-abstract' },
  { name: 'Adobe', icon: 'brand-adobe' },
  { name: 'Airtable', icon: 'brand-airtable' },
  { name: 'Android Studio', icon: 'brand-androidstudio' },
  { name: 'Apple', icon: 'brand-apple' },
  { name: 'App Store', icon: 'brand-appleappstore' },
  { name: 'Aasana', icon: 'brand-asana' },
  { name: 'Atlasian', icon: 'brand-atlasian' },
  { name: 'AWS', icon: 'brand-aws' },
  { name: 'Behance', icon: 'brand-behance' },
  { name: 'Campaign Monitor', icon: 'brand-campaignmonitor' },
  { name: 'Canva', icon: 'brand-canva' },
  { name: 'ClickUp', icon: 'brand-clickup' },
  { name: 'Cloudflare', icon: 'brand-cloudflare' },
  { name: 'Coda', icon: 'brand-coda' },
  { name: 'Codepen', icon: 'brand-codepen' },
  { name: 'Cpanel', icon: 'brand-cpanel' },
  { name: 'Data Studio', icon: 'brand-data-studio' },
  { name: 'Discord', icon: 'brand-discord' },
  { name: 'Dribbble', icon: 'brand-dribbble' },
  { name: 'Dropbox', icon: 'brand-dropbox' },
  { name: 'Drupal', icon: 'brand-drupal' },
  { name: 'Elementor', icon: 'brand-elementor' },
  { name: 'Envato', icon: 'brand-envato' },
  { name: 'Etsy', icon: 'brand-etsy' },
  { name: 'Facebook', icon: 'brand-facebook' },
  { name: 'Figma', icon: 'brand-figma' },
  { name: 'Fiverr', icon: 'brand-fiverr' },
  { name: 'Framer', icon: 'brand-framer' },
  { name: 'Freelancer', icon: 'brand-freelancer' },
  { name: 'Giphy', icon: 'brand-giphy' },
  { name: 'Git', icon: 'brand-git' },
  { name: 'Github', icon: 'brand-github' },
  { name: 'Gitlab', icon: 'brand-gitlab' },
  { name: 'Godaddy', icon: 'brand-godaddy' },
  { name: 'Google', icon: 'brand-google' },
  { name: 'Google Ads', icon: 'brand-googleads' },
  { name: 'Google Adsense', icon: 'brand-googleadsense' },
  { name: 'Google Analytics', icon: 'brand-googleanalytics' },
  { name: 'Google Calendar', icon: 'brand-googlecalendar' },
  { name: 'Google Cloud', icon: 'brand-googlecloud' },
  { name: 'Google Drive', icon: 'brand-googledrive' },
  { name: 'Google Fonts', icon: 'brand-googlefonts' },
  { name: 'Google Hangouts', icon: 'brand-googlehangouts' },
  { name: 'Google Meet', icon: 'brand-googlemeet' },
  { name: 'Google Pptimize', icon: 'brand-googleoptimize' },
  { name: 'Google Play', icon: 'brand-googleplay' },
  { name: 'Google Searchconsole', icon: 'brand-googlesearchconsole' },
  { name: 'Google Sheets', icon: 'brand-googlesheets' },
  { name: 'Google Tagmanager', icon: 'brand-googletagmanager' },
  { name: 'Hotjar', icon: 'brand-hotjar' },
  { name: 'Hubspot', icon: 'brand-hubspot' },
  { name: 'IFTTT', icon: 'brand-ifttt' },
  { name: 'Instagram', icon: 'brand-instagram' },
  { name: 'Intercom', icon: 'brand-intercom' },
  { name: 'Invision', icon: 'brand-invision' },
  { name: 'Jira', icon: 'brand-jira' },
  { name: 'Kickstarter', icon: 'brand-kickstarter' },
  { name: 'Linkedin', icon: 'brand-linkedin' },
  { name: 'Logmein', icon: 'brand-logmein' },
  { name: 'Loom', icon: 'brand-loom' },
  { name: 'Magento', icon: 'brand-magento' },
  { name: 'Mailchimp', icon: 'brand-mailchimp' },
  { name: 'Mamp', icon: 'brand-mamp' },
  { name: 'Marketo', icon: 'brand-marketo' },
  { name: 'Mdnwebdocs', icon: 'brand-mdnwebdocs' },
  { name: 'Mediatemple', icon: 'brand-mediatemple' },
  { name: 'Medium', icon: 'brand-medium' },
  { name: 'Messenger', icon: 'brand-messenger' },
  { name: 'Microsoft', icon: 'brand-microsoft' },
  { name: 'Microsoft Azure', icon: 'brand-microsoftazure' },
  { name: 'Microsoft Excel', icon: 'brand-microsoftexcel' },
  { name: 'Microsoft Office', icon: 'brand-microsoftoffice' },
  { name: 'Microsoft Onenote', icon: 'brand-microsoftonenote' },
  { name: 'Microsoft Powerpoint', icon: 'brand-microsoftpowerpoint' },
  { name: 'Microsoft Teams', icon: 'brand-microsoftteams' },
  { name: 'Microsoft Word', icon: 'brand-microsoftword' },
  { name: 'Miro', icon: 'brand-miro' },
  { name: 'Notion', icon: 'brand-notion' },
  { name: 'Npm', icon: 'brand-npm' },
  { name: 'Oculus', icon: 'brand-oculus' },
  { name: 'Patreon', icon: 'brand-patreon' },
  { name: 'Paypal', icon: 'brand-paypal' },
  { name: 'Php', icon: 'brand-php' },
  { name: 'Pinterest', icon: 'brand-pinterest' },
  { name: 'Pivotal Tracker', icon: 'brand-pivotaltracker' },
  { name: 'Quicktime', icon: 'brand-quicktime' },
  { name: 'Quora', icon: 'brand-quora' },
  { name: 'Reddit', icon: 'brand-reddit' },
  { name: 'Revolut', icon: 'brand-revolut' },
  { name: 'Salesforce', icon: 'brand-salesforce' },
  { name: 'Sap', icon: 'brand-sap' },
  { name: 'Shopify', icon: 'brand-shopify' },
  { name: 'Skype', icon: 'brand-skype' },
  { name: 'Slack', icon: 'brand-slack' },
  { name: 'Snapchat', icon: 'brand-snapchat' },
  { name: 'Soundcloud', icon: 'brand-soundcloud' },
  { name: 'Square', icon: 'brand-square' },
  { name: 'Squarespace', icon: 'brand-squarespace' },
  { name: 'Stackoverflow', icon: 'brand-stackoverflow' },
  { name: 'Statamic', icon: 'brand-statamic' },
  { name: 'Stripe', icon: 'brand-stripe' },
  { name: 'Substack', icon: 'brand-substack' },
  { name: 'Tableau', icon: 'brand-tableau' },
  { name: 'Tensorflow', icon: 'brand-tensorflow' },
  { name: 'Tidal', icon: 'brand-tidal' },
  { name: 'Tiktok', icon: 'brand-tiktok' },
  { name: 'Todoist', icon: 'brand-todoist' },
  { name: 'Toggl', icon: 'brand-toggl' },
  { name: 'Transferwise', icon: 'brand-transferwise' },
  { name: 'Treehouse', icon: 'brand-treehouse' },
  { name: 'Trello', icon: 'brand-trello' },
  { name: 'Tumblr', icon: 'brand-tumblr' },
  { name: 'Twilio', icon: 'brand-twilio' },
  { name: 'Twitter', icon: 'brand-twitter' },
  { name: 'Udemy', icon: 'brand-udemy' },
  { name: 'Unsplash', icon: 'brand-unsplash' },
  { name: 'Venmo', icon: 'brand-venmo' },
  { name: 'Vimeo', icon: 'brand-vimeo' },
  { name: 'Visa', icon: 'brand-visa' },
  { name: 'Wetransfer', icon: 'brand-wetransfer' },
  { name: 'Whatsapp', icon: 'brand-whatsapp' },
  { name: 'Wikipedia', icon: 'brand-wikipedia' },
  { name: 'Windows', icon: 'brand-windows' },
  { name: 'Wix', icon: 'brand-wix' },
  { name: 'Woo', icon: 'brand-woo' },
  { name: 'Wordpress', icon: 'brand-wordpress' },
  { name: 'Wpengine', icon: 'brand-wpengine' },
  { name: 'X', icon: 'brand-x' },
  { name: 'Xcode', icon: 'brand-xcode' },
  { name: 'Xero', icon: 'brand-xero' },
  { name: 'Ycombinator', icon: 'brand-ycombinator' },
  { name: 'Yelp', icon: 'brand-yelp' },
  { name: 'Youtube', icon: 'brand-youtube' },
  { name: 'Zapier', icon: 'brand-zapier' },
  { name: 'Zendesk', icon: 'brand-zendesk' },
  { name: 'Zoom', icon: 'brand-zoom' },

]
