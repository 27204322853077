import service from '../trackingService'
import Tracking from './Tracking'
import { track as trackDirective } from './trackingDirectives'

export default {
  install(Vue, options) {
    const tracking = new Tracking({ service, ...options })
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$tracking = tracking;
    Vue.directive('track', trackDirective(tracking));
  },
}
