<template>
  <div
    v-b-hover="handleHover"
    class="fs-card-wrapper"
    :class="`${size}`"
    @click="event => $emit('select', event)"
  >
    <div class="fs-card-container">
      <div
        class="fs-card-body fs-card--doc"
        role="link"
      >
        <!-- <div class="fs-card-status-badge">
          <DocumentStatusBadge
            :status="document.status"
            display-as="small"
            only-approved
          />
        </div> -->
        <div class="fs-card--doc-body">
          <template v-if="document">
            <div class="flex flex-col items-center justify-center flex-1">
              <!-- ICON -->
              <div
                class="fs-card--doc-icon-wrapper"
                :style="{ color: document.color || theme.textColor }"
              >
                <span
                  :class="document.icon || 'document-custom'"
                  class="fs-card--doc-icon"
                />
              </div>
              <div
                v-if="document.sharingClientEnabled && !isClientPortal"
                class="fs-card-status-wrapper"
              >
                <span
                  :class="[
                    'icon_v2-so_shared fs-card-status-icon'
                  ]"
                  :style="{ color: 'var(--cp-accent)'}"
                ></span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- MORE INFO -->
      <div
        v-if="totalActiveActionItems > 0 || showCommentsCount > 0 || showCommentsCount > 0"
        class="absolute flex items-center justify-center flex-none divide-x divide-gray-300 rounded-md shadow -bottom-16 left-8 bg-gray/70 dark:bg-darkGray-1000 outline outline-1 outline-gray-300 dark:outline-darkGray-700 filter backdrop-blur-sm "
        :class="isHovered ? 'visible' : 'invisible'"
      >
        <!-- Action Items Count -->
        <div
          v-if="totalActiveActionItems > 0"
          v-b-tooltip.hover.top.v-info.dh0.ds200="'Action items'"
          class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13 dark:text-darkGray-300"
        >
          <span class="w-16 mr-4 icon_v2-so_action-items text-16"></span>
          {{ totalActiveActionItems }}
        </div>
        <!-- Comments Count -->
        <div
          v-if="showCommentsCount > 0"
          v-b-tooltip.hover.top.v-info.dh0.ds200="'Comments'"
          class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13 dark:text-darkGray-300"
        >
          <span class="w-16 mr-4 icon_v2-so_chat text-16"></span> {{ showCommentsCount }}
        </div>
        <!-- Comments Count -->

        <div
          v-if="document.viewCount > 0"
          v-b-tooltip.hover.top.v-info.dh0.ds200="'Views'"
          class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13 dark:text-darkGray-300"
        >
          <span class="w-16 mr-4 icon-util-view text-16"></span> {{ document.viewCount }}
        </div>
      </div>
    </div>
    <div class="fs-card-title-card">
      <DocumentTitleEditable
        :size="size"
        :document="document"
        :disabled="readOnly"
      />
      <DocumentStatusBadge
        :status="document.status"
        display-as="small"
        only-approved
      />
    </div>
    <p
      v-if="document.cost"
      class="fs-card-meta"
    >
      <DocumentCurrency
        :value="document.cost"
        :document-id="document._id"
      />
    </p>
  </div>
</template>

<script>
import { sum } from 'ramda'
import { ref, defineComponent, computed, inject } from '@vue/composition-api'
import DocumentStatusBadge from '@/components/Document/Editor/DocumentStatusBadge.vue'
import DocumentCurrency from '@/components/DocumentCurrency.vue'
import DocumentTitleEditable from '@/components/DocumentTitleEditable.vue'

export default defineComponent({
  name: 'FileDocCard',
  components: {
    DocumentStatusBadge,
    DocumentCurrency,
    DocumentTitleEditable,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'm',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isClientPortal = inject('isClientPortal', false)

    const isHovered = ref(false)

    const handleHover = () => {
      isHovered.value = !isHovered.value
    }

    const totalActiveActionItems = computed(() => sum([
      props.document?.countActionItemsOpenPublic ?? 0,
      props.document?.countActionItemsResolvedPublic ?? 0,
    ]))

    const showActionItemsCount = computed(() => totalActiveActionItems.value)
    const showCommentsCount = computed(() => props.document?.countComments ?? 0)

    const theme = computed(() => ({
      textColor: props.document?.sharingClientEnabled ? 'var(--cp-accent)' : 'var(--cp-secondary-color)',
    }))

    return {
      totalActiveActionItems,
      showActionItemsCount,
      isHovered,
      handleHover,
      showCommentsCount,
      isClientPortal,
      theme,
    }
  },
})
</script>
<style lang="postcss" scoped>

</style>
