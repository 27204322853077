
<div>
  <!-- <pre>Active Font: {{ activeFont }}</pre> -->
  <div class="relative text-gray-600 focus-within:text-gray-700 mb-8">
    <b-input
      :value="filterFont"
      autofocus
      placeholder="Search fonts"
      size="lg"
      class="pr-32"
      @input="searchFont"
    />
    <span class="icon-magnify text-18 w-18 absolute right-12 top-12 text-gray-600" />
  </div>
  <div class="text-14 text-gray-600 text-right mb-12">
    Our library includes {{ receivedFonts && receivedFonts.length }} fonts from Google Fonts
  </div>
  <LoaderWebFont :families="families" preview>
    <div>
      <div
        v-for="family in families"
        :key="family"
        class="border-b border-gray-200 pb-16 mb-16"
      >
        <p class="text-gray-700 mb-4">
          {{ family }}
        </p>
        <div class="flex justify-between">
          <p
            class="text-24"
            :style="{fontFamily: family }"
          >
            The quick brown fox jumps over the lazy dog.
          </p>
          <b-button variant="primary" @click="$emit('select', family, applyFontOn)">
            Choose
          </b-button>
        </div>
      </div>
    </div>
  </LoaderWebFont>
  <div class="flex justify-center mt-16 mb-32 space-x-16">
    <b-button
      :disabled="Boolean(page===1)"
      variant="primary"
      class="w-128"
      @click="changePage('prev')"
    >
      Previous
    </b-button>
    <b-button
      :disabled="!nextPage"
      variant="primary"
      class="w-128"
      @click="changePage('next')"
    >
      Next
    </b-button>
  </div>
</div>
