const classType = [String, Array, Object]

export default {
  props: {
    title: String,
    subtitle: String,
    noClose: Boolean,
    showBack: Boolean,
    titleIcon: String,
    titleIconClass: String,

    titleClass: classType,
    closeClass: classType,
    dialogClass: classType,
    contentClass: classType,
    footerClass: classType,
    bodyClass: classType,
    headerClass: classType,
  },
  // props: {
  //     title: String,
  //     dialogClass: [String, Array, Object],
  //     contentClass: [String, Array, Object],
  //     footerClass: [String, Array, Object],
  //     bodyClass: [String, Array, Object],
  //     headerClass: [String, Array, Object],
  //     headerInnerClass: [String, Array, Object],
  //     titleClass: [String, Array, Object],
  //     closeClass: [String, Array, Object],
  //     hideFooter: Boolean,
  //     hideHeader: Boolean
  // }
}
