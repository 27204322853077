import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import clientsService, { servicePath } from './clientsService'

class Client extends BaseModel {
  static modelName = 'Client'

  static instanceDefaults() {
    return {
      mainColor: '#02F2EB',
      logoDark: null,
      logoLight: null,
    }
  }
}

export default makeServicePlugin({
  Model: Client,
  service: clientsService,
  servicePath,
})
