// Import other icon sets
import { brandIcons } from './brand-icons';
import { standardIcons } from './standard-icons';
import { materialIcons } from './material-icons';

// Export one big icon library to be used in various cases
const icons = {
  brandIcons,
  standardIcons,
  materialIcons,
}

export default icons
