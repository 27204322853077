import { omit, endsWith, head, pipe, propOr } from 'ramda'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard/* , debug */ } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'
import EventBus from '@/event-bus/event-bus'
import { isStatusCodeForbidden } from '@/v2/lib/hooks/errorHandlers'
import { discardCond, requestContextForServer } from '@/v2/lib/hooks'

const authClient = auth({ storage: window.localStorage })
const discardJoins = discardCond(endsWith('$'));

const publishSocketEventsToEventBus = (socket, bus) => {
  [
    'connect',
    // 'disconnect',
    // 'error',
    // 'connect_error',
    // 'connect_timeout',
    // 'reconnect',
    // 'reconnect_attempt',
    'reconnecting',
    // 'reconnect_error',
    // 'reconnect_failed',
    // 'ping',
    // 'pong',
  ].forEach(eventName => {
    socket.on(eventName, (...args) => {
      bus.$emit(`socketio:${eventName}`, ...args)
    })
  })
}

/** @type {import('@feathersjs/feathers').HooksObject} */
const hooks = {
  before: {
    all: [
      // ctx => {
      //   console.log(ctx.path, ctx.method)
      // },
      requestContextForServer({ grant: 'document' }),
      iff(
        context => ['create', 'update', 'patch'].includes(context.method),
        discard('__id', '__isTemp', '$id', '$key'),
        discardJoins
      ),
    ],
  },
  after: {
    all: [],
  },
  error: {
    all: [
      context => {
        if (!context.supressErrorMessage && isStatusCodeForbidden(context)) {
          EventBus.$emit('messageError', {
            title: 'Forbidden',
            error: context.error,
          });
        }
      },
      // context => {
      //   // Intercept 404s & emit an event on the bus. Currently used for redirecting auser to the
      //   // current organization's dashboard
      //   if (context.error && context.error.code == '404') {
      //     EventBus.$emit(EVENT_TYPES.contentNotFound)
      //   }
      // },
    ],
  },
}

const socket = io(import.meta.env.VITE_WS_URL, {
  transports: ['websocket'],
  upgrade: false,
})

publishSocketEventsToEventBus(socket, EventBus);

const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(authClient)
  .hooks(hooks)

// Setting up feathers-vuex
const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel: FeathersVuexBaseModel,
  models,
  FeathersVuex,
} = feathersVuex(feathersClient, {
  serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
  idField: '_id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options'],
})

class BaseModel extends FeathersVuexBaseModel {
  /** Grabs the resource identified by `_id` from the Store, if it exists, otherwise requests it
   * from the back end */
  static async grabOrFetch(_id) {
    return this.getFromStore(_id) ?? this.get(_id)
  }

  /**
   * Retrieves the first element matching the given `query`
   * @param {Object} query
   */
  static findOne(query) {
    return this.find({ query: { $limit: 1, ...query }, paginate: false })
      .then(pipe(propOr([], 'data'), head))
  }

  // get id() {
  //   return this._id ?? this.__id
  // }

  toPlainObject() {
    return omit(
      [
        '__id',
        '__isClone',
        '__isTemp',
        '__ob',
        '$id',
        '$key',
        'isCreatePending',
        'isUpdatePending',
        'isPatchPending',
        'isRemovePending',
        'isSavePending',
        'isPending',
      ],
      this
    )
  }
}

export default feathersClient

export {
  makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex,
}
