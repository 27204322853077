<template>
  <LoaderWebFont :families="[ styling.font, paragraphFont, headingFont]">
    <div
      :class="[
        'block-element-text',
        `text-variant-${textVariant}`,
        `text-${styling.textAlign}`,
        'portal-paragraph-font',
      ]"
      :style="{
        color: styling.textColor,
      }"
    >
      <!-- toolbar -->
      <!-- <DocumentContentBlockTableToolbarPortal
      :popover-target-id="settingsPopoverTargetId"
      :content-block="contentBlock"
      :node-id="nodeId"
      s
      @update="(data, options) => $emit('update', data, options)"
    /> -->

      <!-- input -->
      <section @contextmenu.stop>
        <TableEditor
          v-if="isEditable"
          :lazy="true"
          :value="contentBlock.content"
          :is-focus="isFocus"
          @input="val => $emit('update', { content: val })"
        />
        <div
          v-else
          :class="['editor-content table-editor-content', 'editor-content-preview']"
          v-html="contentBlock.content"
        />
      </section>
    </div>
  </LoaderWebFont>
</template>

<script>
import { inject, computed } from '@vue/composition-api'
import TableEditor from '@/components/TableEditor/TableEditor.vue'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';

export default {
  name: 'DocumentContentBlockTable',
  components: {
    TableEditor,
    LoaderWebFont,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    nodeId: {
      type: String,
      required: true,
    },

  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const document = inject('document')
    const isEditable = computed(() => isEditor.value && !isLocked.value)
    // Compute fonts
    const paragraphFont = computed(() => document.value.brandingFontParagraph)
    const headingFont = computed(() => document.value.brandingFontHeading)
    const cssVars = computed(() => ({
      '--so-heading-font': headingFont.value || 'var(--cp-heading-font)',
      '--so-paragraph-font': paragraphFont.value || 'var(--cp-paragraph-font)',
    }))


    const settingsPopoverTargetId = computed(
      () => `settings-popover-${props.nodeId}`
    )

    return {
      settingsPopoverTargetId,
      isEditable,
      document,
      // Styling
      headingFont,
      paragraphFont,
      cssVars,
    }
  },
}
</script>

<style lang="postcss" scoped>
  .so-table .row {
    @apply flex items-center py-8;
  }
</style>
