
<button
  v-b-tooltip.hover.top.v-info.dh0.ds200="
    $t('action-items.toggle-note')
  "
  class="inline-flex items-center hover:text-gray-700"
  :class="{
    'text-gray-700 dark:text-darkGray-300': active,
    'text-gray-500 dark:text-darkGray-500': !active,
  }"
  v-on="$listeners"
>
  <span
    class="
      icon_v2-so_file
      text-18
      bg-yellow-400
      dark:bg-transparent
      rounded-md
      text-black/40
      dark:text-yellow-200
    "
  />
  <!-- <span class="text-13 truncate max-w-112">{{ item.notes }}</span> -->
</button>
