
<portal
  v-if="isEditable"
  :to="`content-block-settings-${nodeId}`"
>
  <div class="flex flex-row">
    <DocumentNodeEditorToolbarSeparator />
    <DocumentNodeEditorToolbarGroup
      label="Input Type & Settings"
      new-badge
    >
      <div class="flex space-x-6">
        <DocumentNodeEditorToolbarMenu
          class-name="min-w-180"
          toggle-class="justify-between px-8 font-semibold bg-gray-500 bg-opacity-10 hover:bg-opacity-20 text-14"
          dropdown-class="w-full"
          menu-class="w-192 text-14"
          :no-carret="false"
        >
          <template
            #button
          >
            <span
              v-b-tooltip.hover.bottom.v-info.dh0.ds200
              class="inline-flex items-center"
              title="Select input type"
            ><span
              :class="`icon_v2-so_${inputType.icon}`"
              class="w-24 mr-8 text-24"
            />{{ inputType.label }}</span>
          </template>
          <template #default>
            <DocumentNodeEditorToolbarMenuItem
              v-for="item in inputTypes"
              :key="item.id"
              :icon="item.icon"
              :label="item.label"
              :active="item.id === inputType.id"
              @select="$emit('select',item.id)"
            />
          </template>
        </DocumentNodeEditorToolbarMenu>
        <!-- QA Input Type settings component -->
        <slot />
      </div>
    </DocumentNodeEditorToolbarGroup>
  </div>
</portal>
