const mapOptions = options => options.map(({ text }) => ({ value: text, text }))

export default Object.freeze({
  kind: mapOptions([
    // { value: null, text: 'Select an option', disabled: true },
    { /* value: 3, */ text: 'Digital Marketing' },
    { /* value: 4, */ text: 'Design' },
    { /* value: 5, */ text: 'SEO' },
    { /* value: 2, */ text: 'Web Development' },
    { /* value: 6, */ text: 'Mobile App Development' },
    { /* value: 7, */ text: 'Branding' },
    { /* value: 8, */ text: 'Video Production' },
    { /* value: 9, */ text: 'Consulting' },
    { /* value: 10, */ text: 'Outsourcing' },
    { /* value: 1, */ text: 'Full-service Agency' },
    { /* value: 11, */ text: 'Architecture' },
    { text: 'Event Production' },
    { text: 'Coaching' },
    { /* value: 100, */ text: 'Other' },
  ]),
  role: mapOptions([
    // { value: null, text: 'Select an option', disabled: true },
    { /* value: 1, */ text: 'Company Management' },
    { /* value: 2, */ text: 'Project Management' },
    { /* value: 3, */ text: 'Designer' },
    { /* value: 4, */ text: 'Engineering / Development' },
    { /* value: 5, */ text: 'Operations' },
    { /* value: 6, */ text: 'Sales' },
    { /* value: 7, */ text: 'Accounting / Finance' },
    { /* value: 100, */ text: 'Other' },
  ]),
  size: mapOptions([
    // { value: null, text: 'Select an option', disabled: true },
    { /* value: 1, */ text: 'Just me' },
    { /* value: 2, */ text: '2-5' },
    { /* value: 3, */ text: '6-10' },
    { /* value: 4, */ text: '11-25' },
    { /* value: 5, */ text: '26-50' },
    { /* value: 6, */ text: '50+' },
  ]),
})
