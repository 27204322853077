
<DocumentNodeEditorToolbarSettings
  :popover-target-id="popoverTargetId"
  :prevent-popover-close="preventPopoverClose"
>
  <b-form>
    <SettingsSwitch
      v-model="showTotalGraph"
      label="Approval progress"
      tooltip="Approved/Remaining cost"
    />
  </b-form>
</DocumentNodeEditorToolbarSettings>
