<template>
  <!-- HEADER -->
  <h2 :class="['text-24 font-bold text-gray-800 dark:text-darkGray-300 portal-heading-font']">
    {{ documentsModule.title || $t('modules.docs-and-files') }}
  </h2>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useProjectModule } from '@/v2/services/projects/compositions'

export default defineComponent({
  name: 'DocTitleReadonly',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const documentsModule = useProjectModule(computed(() => props.project), 'documents')

    return {
      documentsModule,
    }
  },
})
</script>
