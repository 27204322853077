<script>
import nodeImpl from '@/v2/features/document/documentNodeImpl'
import nodeGroupImpl from '@/v2/features/document/documentNodeGroupImpl/viewer'
import DocumentNodeNotImplemented from './DocumentNodeNotImplemented.vue'

const notImplementedNode = {
  label: 'Not Implemented',
  component: DocumentNodeNotImplemented,
}

export default {
  name: 'DocumentNodeResolverViewer',
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { label, component } = (
      props.node.isGroup
        ? nodeGroupImpl[props.node?.category]
        : nodeImpl[props.node?.contentBlock$?.category]
    ) ?? notImplementedNode

    return () => context.slots.default({ label, component });
  },
}
</script>
