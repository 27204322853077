<template>
  <div>
    <DocumentGet
      :id="documentId"
      v-slot="{ document, isGetPending: isDocumentPending }"
    >
      <template v-if="!isDocumentPending">
        <DocumentViewerTemplate
          v-if="document"
          :document="document"
          print
        />
        <div v-else>
          <b-alert show variant="warning">
            <h4>Template not found</h4>
            <p>Invalid template or no longer available.</p>
          </b-alert>
        </div>
      </template>
    </DocumentGet>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentGet from '@/components/DocumentGet.vue'
import DocumentViewerTemplate from '@/components/Document/Viewer/DocumentViewerTemplate.vue'

export default defineComponent({
  name: 'GuestTemplateDocument',
  components: {
    DocumentGet,
    DocumentViewerTemplate,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
  },
})
</script>
