
<div v-fragment>
  <slot
    v-bind="{ open }"
    name="button"
  />
  <b-button
    v-if="!hasButtonSlot"
    :id="targetId"
    :size="size"
    class="px-12 bg-white rounded-md dark:bg-darkGray-1000"
    @click.prevent="open"
  >
    <div class="flex flex-row items-center">
      <span
        class="ml-0 text-gray-600 dark:text-darkGray-300"
        :class="[
          currentIcon,
          { 'text-18 w-18': size === 'sm' },
          { 'text-24 w-24': size === 'md' },
        ]"
      />
      <span class="ml-8 icon_v2-so_direction-down text-16"></span>
    </div>
  </b-button>
  <ModalBasic
    v-if="popoverShow"
    v-model="popoverShow"
    :hide-footer="true"
    size="lg"
    :should-close-on-backdrop="false"
    dialog-class="p-0"
    content-class="p-0"
    body-class="modal-body-icon-picker"
    header-class="bg-gray-100 mb-0"
    footer-class=""
  >
    <template #body="{ close }">
      <!-- Header tabs -->
      <!-- Search -->
      <!-- Icons -->
      <div
        v-click-outside.capture="() => close()"
        class="relative"
        @click.stop
      >
        <b-tabs
          v-model="currentTab"
          content-class="fade-from-bottom "
          active-nav-item-class="text-green-500 bg-white"
          nav-class="bg-gray-100 pt-16 rounded-t-md"
          nav-wrapper-class="bg-red-500 rounded-t-md"
          lazy
          :activate-tab="switchTab"
        >
          <b-tab
            v-if="icons.brandIcons.length"
            title="Apps"
            class="tab-container"
          >
            <div class="px-16 my-8">
              <b-input
                v-model="search"
                type="search"
                placeholder="Search icons"
                size="lg"
                autofocus
              />
            </div>
            <ul class="icon-picker-list">
              <li
                v-for="icon in paginatedResults"
                :key="icon.name"
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="icon.name"
                class="icon-picker-button"
                @click.stop="selectIcon(icon)"
              >
                <span :class="[`${icon.icon}`, 'icon-preview']" />
                <p class="preview-icon-name">{{ icon.name }}</p>
              </li>
            </ul>
          </b-tab>
          <b-tab
            v-if="icons.materialIcons.length"
            title="Material Icons"
            class="tab-container"
          >
            <div class="px-16 my-8">
              <b-input
                v-model="search"
                type="search"
                placeholder="Search icons"
                size="lg"
                autofocus
              />
            </div>
            <ul class="icon-picker-list">
              <li
                v-for="icon in paginatedResults"
                :key="icon.name"
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="icon.name"
                class="icon-picker-button"
                @click.stop="selectIcon(icon)"
              >
                <span :class="[`${icon.icon}`, 'icon-preview']" />
                <p class="preview-icon-name">{{ icon.name }}</p>
              </li>
            </ul>
          </b-tab>
          <b-tab
            v-if="icons.standardIcons.length"
            title="Basic"
            class="tab-container"
          >
            <div class="px-16 my-8">
              <b-input
                v-model="search"
                type="search"
                placeholder="Search icons"
                size="lg"
                autofocus
              />
            </div>
            <ul class="icon-picker-list">
              <li
                v-for="icon in paginatedResults"
                :key="icon.name"
                class="icon-picker-button"
                @click.stop="selectIcon(icon)"
              >
                <span :class="[`${icon.icon}`, 'icon-preview']" />
                <p class="preview-icon-name">{{ icon.name }}</p>
              </li>
            </ul>
          </b-tab>
        </b-tabs>
        <div class="p-16">
          <b-button
            v-if="canLoadMore"
            variant="neutral"
            class="w-full relative z-1060"
            @click="loadMoreIcons"
          >
            Load more icons
          </b-button>
        </div>
      </div>
    </template>
  </ModalBasic>

  <!-- </b-popover> -->
</div>
