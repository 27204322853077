import { ensureArray } from 'ramda-adjunct'
import { alwaysNull } from 'ramda-extension'
import { inject, ref } from '@vue/composition-api'
import { serial } from '@/lib/promise'
import { useCreateNode } from '@/v2/services/documentNodes/documentNodesCompositions'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import { useStructure, useStructureRemoveNode } from '@/v2/services/documentStructures/documentStructuresCompositions'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { CATEGORY, CATEGORY_TO_LIST_TITLE, FS_STATE } from '@/v2/services/documents/documentsTypes'
import { getCurrentInstanceOrThrow } from './helpers'

/**
 * Requires a `provide('document', currentFolder)` in order to access data of the parent folder.
 * Exposes a set of utility functions for easily managing our custom "file system".
 */
export default function useCustomFileSystem() {
  const vm = getCurrentInstanceOrThrow()
  const { Document, DocumentNode, DocumentStructure, ContentBlock } = vm.$FeathersVuex.api
  const createNode = useCreateNode()
  const { create, open } = useDocuments()
  const currentFolderStructure = useStructure()
  const removeNodeFromStructure = useStructureRemoveNode()
  const currentFolder = inject('document')
  const confirmDelete = useMsgBoxConfirmDelete()

  const createFilesInCurrentFolder = async items => {
    const _items = ensureArray(items)

    const inheritedValues = {
      parentFolder: currentFolder.value._id, // Set parent
      // Same visibility as parent context
      sharingClientEnabled: currentFolder.value.sharingClientEnabled,
      project: currentFolder.value.project,
    }

    const structureClone = currentFolderStructure.value.clone()

    const _create = indexOffset => async (item, index) => {
      const { _id: newDocId } = await create({ ...inheritedValues, ...item }, false)
      // console.log(`offset=${indexOffset}`, `index=${indexOffset + index}`, `item=${item}`)

      await createNode({
        parentNodeId: 'root', // All files are placed in folders on the 'root' branch
        index: indexOffset + index, // Place this node last in folder's structure
        contentBlockData: { embeddedDocument: newDocId },
        structureClone,
        structureLocal: true,
      })
    }

    const indexOffset = structureClone.tree.root.length
    await serial(_items.map((item, index) => () => _create(indexOffset)(item, index)))

    await structureClone.save({ data: { branches: structureClone.branches } })
  }

  /**
   * Creates a new file from the provided `documentData`, inside the `targetFolderId`
   * @param {{
   *  category: 'Document' | 'File' | 'FileLink' | 'Folder';
   *  [otherProp: string]: any;
   * }} documentData
   * @param {String} targetFolderId
   */
  const createFileInFolder = async (documentData, targetFolderId) => {
    try {
      const targetFolder = await Document.grabOrFetch(targetFolderId)
      // Create new file (document). This will be an embedded document inside the parent folder
      const inheritedValues = {
        parentFolder: targetFolder._id, // Set parent
        sharingClientEnabled: targetFolder.sharingClientEnabled, // Same visibility as parent context
        project: targetFolder.project, // Same visibility as parent context
      }
      const newFile = await create({ ...documentData, ...inheritedValues }, false)

      // Create document node & content block for current folder. This will hold a reference to the
      // newly created file
      await createNode({
        parentNodeId: 'root', // All files are placed in folders on the 'root' branch
        targetDocumentRef: ref(targetFolder),
        contentBlockData: { embeddedDocument: newFile._id },
      })

      return newFile;
    } catch (err) {
      return console.error('Error creating file in folder:', err);
    }
  }

  /**
   * Duplicates file or folder, inside the `targetFolderId`
   * @param {{
     *  category: 'Document' | 'File' | 'FileLink' | 'Folder';
     *  [otherProp: string]: any;
     * }} documentData
     * @param {String} targetFolderId
     */
  const duplicateDocument = async (documentData, targetFolderId) => {
    try {
      const targetFolder = await Document.grabOrFetch(targetFolderId)
      // Create new file (document). This will be an embedded document inside the parent folder
      const inheritedValues = {
        parentFolder: targetFolder._id, // Set parent
        // sharingClientEnabled: targetFolder.sharingClientEnabled, // Same visibility as parent context
        project: targetFolder.project, // Same visibility as parent context
      }
      const newFile = await create({ ...documentData, ...inheritedValues }, false)
      return newFile;
    } catch (err) {
      return console.error('Error duplicating document:', err);
    }
  }


  /** Provided a `nodeId`, returns the `document` data from `node.contentBlock$.embeddedDocument` */
  const getDocumentFromNode = nodeId => {
    const documentId = DocumentNode.getFromStore(nodeId)?.contentBlock$?.embeddedDocument
    return Document.grabOrFetch(documentId)
  }

  /**
   * Moves a file from the current folder to another one.
   * **Caution!** This only works if the currently injected `document` is the current folder,
   * i.e. it doesn't work if viewing/editing a single file/document
   * @param {import('feathers-vuex/dist').Model} file FeathersVuex Document Model Instance
   * @param {String} fileNodeId `_id` of the `documentNode` where `file` is found in current
   * folder's structure
   * @param {import('@vue/composition-api').Ref} targetFolder Vue Ref for a document of type
   * `Folder`
   */
  const moveFileToFolder = async (fileNodeId, targetFolderId) => {
    const file = await getDocumentFromNode(fileNodeId)

    if (targetFolderId === file.parentFolder) {
      // TODO: Maybe show a message to the user to inform this us a no-op
      return console.warn('Attempted to move file to same folder.');
    }

    const targetFolder = await Document.grabOrFetch(targetFolderId)
    // const [,, newNode] = await Promise.all([
    //   // Remove file as document-node from current folder
    //   removeNodeFromStructure({ id: fileNodeId }),
    //   // Update file's `parentFolder` and `shared` status
    //   file.patch({
    //     data: {
    //       parentFolder: targetFolder._id,
    //       sharingClientEnabled: targetFolder.sharingClientEnabled,
    //     },
    //   }),
    //   // Add file as document-node to new folder
    //   createNode({
    //     parentNodeId: 'root', // All files are placed in folders on the 'root' branch
    //     contentBlockData: { embeddedDocument: file._id },
    //     targetDocumentRef: ref(targetFolder),
    //   }),
    // ])

    await removeNodeFromStructure({ id: fileNodeId });
    const newNode = await createNode({
      parentNodeId: 'root', // All files are placed in folders on the 'root' branch
      contentBlockData: { embeddedDocument: file._id },
      targetDocumentRef: ref(targetFolder),
    });

    return newNode
  }

  /**
   * Moves the file/folder/document which is "currently in scope" (marked by `provide('document')`)
   * to a different folder
   * @param {String} fileId `_id` of the file/folder/document to be moved
   * @param {String} targetFolderId `_id` of the folder where `fileId` is to be moved to
   */
  const moveCurrentToFolder = async targetFolderId => {
    const file = currentFolder.value

    if (targetFolderId === file.parentFolder) {
      // TODO: Maybe show a message to the user to inform this us a no-op
      return console.warn('Attempted to move file to same folder.');
    }

    const [targetFolder, embeddingContentBlock] = await Promise.all([
      // Get data for folder
      Document.grabOrFetch(targetFolderId),
      // Find node which embeds this file in parent folder's structure
      ContentBlock.findOne({ embeddedDocument: file._id, document: file.parentFolder }),
    ])

    const parentFolder = await Document.grabOrFetch(file.parentFolder)

    const [parentFolderStructure, embeddingNode] = await Promise.all([
      DocumentStructure.grabOrFetch(parentFolder.structure),
      DocumentNode.findOne({ contentBlock: embeddingContentBlock._id }),
    ])

    const [,, newNode] = await Promise.all([
      // Remove file as document-node from current folder
      removeNodeFromStructure({
        id: embeddingNode._id,
        targetStructureRef: ref(parentFolderStructure),
      }),
      // Update file's `parentFolder` and `shared` status
      file.patch({ data: { parentFolder: targetFolderId } }),
      // Add file as document-node to new folder
      createNode({
        parentNodeId: 'root', // All files are placed in folders on the 'root' branch
        contentBlockData: { embeddedDocument: file._id },
        targetDocumentRef: ref(targetFolder),
      }),
    ])

    return newNode
  }

  /**
   * Removes a file or folder from a parent folder by removing the node from the parent's
   * structure first, then deleting the document's data. If removal target is a folder,
   * all of its contents will be deleted as well
   * @param {String} fileNodeId `documentNode._id`
   */
  const removeFile = async fileNodeId => {
    const document = await getDocumentFromNode(fileNodeId).catch(alwaysNull)
    const title = `Are you sure you want to delete this ${CATEGORY_TO_LIST_TITLE[document?.category] ?? 'file'}?`
    let message = 'This action is irreversible.'

    if (document?.category === CATEGORY.Folder) {
      message = `All of the folder's contents will be deleted as well. ${message}`
    }
    // Show confirmation dialog
    const hasApproved = await confirmDelete({ title, message })

    if (!hasApproved) return // Canceled by user

    if (document) {
      await document.patch({ data: { fsState: FS_STATE.delete } })
    }

    await removeNodeFromStructure({ id: fileNodeId })

    // Remove file as document-node from current folder
    // await removeNodeFromStructure({ id: fileNodeId })
    // Delete document and all its data. If folder, contents will be deleted as well
    // await document.remove()
  }

  /**
   * Opens a file/folder/document viewer by providing the item's `fileNodeId` within the current
   * folder's structure
   * @param {String} fileNodeId `documentNode._id`
   */
  const openFile = async fileNodeId => {
    const document = await getDocumentFromNode(fileNodeId)
    open(document)
  }

  return {
    createFileInFolder,
    createFilesInCurrentFolder,
    moveFileToFolder,
    moveCurrentToFolder,
    removeFile,
    openFile,
    duplicateDocument,
  }
}
