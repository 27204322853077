
<div>
  <h2
    class="portal-heading-font drop-shadow-xl"
    :class="headerClass"
  >
    {{ greeting }},
    <p
      v-if="!isClientPortal"
      v-b-tooltip.hover.top.v-info.dh0.ds200="$t('greeting.tooltip')"
      class="relative inline hover:cursor-help"
    >
      <span
        class="underline decoration-wavy decoration-2 decoration-white/30 underline-offset-4"
      >
        {{ profile.firstName }}
      </span>
    </p>
    <p v-else class="inline">{{ isPortalPreview ? '[client name]' : profile.firstName }}</p>
  </h2>
</div>
