<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isBlankState"
    :root-class="$style['node-text']"
    :config-override="{
      stylingToolbar: {
        features: {
          textColor: true,
          fontFamily: true,
        },
      },
      styling: {
        size: 'S',
      },
    }"
  >
    <template #default="{ styling, textVariant }">
      <DocumentContentBlockText
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockText from './DocumentContentBlockText.vue'

export default {
  name: 'DocumentNodeText',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockText,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isBlankState = computed(() => pathIsNilOrEmpty(['contentBlock', 'content'], props))

    return {
      isBlankState,
    }
  },
}
</script>
<style lang="scss" module>
.node-text {
}
</style>
