<template>
  <div v-fragment>
    <ImageSearchUnsplashModal
      v-if="isUnsplashOpen"
      v-model="isUnsplashOpen"
      @select="onUnsplashImageSelect"
    />
    <FileUploader
      v-slot="{ selectFiles }"
      data-intercom-target="Image Block"
      accept="image/*"
      :multiple="false"
      custom-class="flex-col"
      @upload-start="onUploadStart"
      @file-progress="onFileProgress"
      @file-done="onFileDone"
      @file-error="onFileError"
    >
      <!-- If not empty -->
      <div
        v-if="!isBlankState || fileUploadState.isUploading"
        :class="[
          'block-image-body',
          'w-full',
          'items-center',
          {
            'justify-start': styling.objectAlign === 'left',
            'justify-center': styling.objectAlign === 'center',
            'justify-end': styling.objectAlign === 'right',
          },
          { uploading: fileUploadState.isUploading },
        ]"
      >
        <!-- Show image resize and Change Image button if in edit mode -->
        <template v-if="isEditable">
          <ObjectResize
            :width="isObjectAlignStretch ? null : contentBlock.width"
            :disabled="fileUploadState.isUploading"
            :show-selection="isHover"
            @change="onImageResize"
            @resize-start="onImageResizeStart"
            @resize-end="onImageResizeEnd"
          >
            <img
              :src="fileUrl"
              class="w-full block h-auto"
              @contextmenu.stop
            />
          </ObjectResize>
        </template>

        <!-- Else, show just the image -->
        <b-img
          v-else
          :src="fileUrl"
          :style="imageStyle"
          class="w-full block h-auto"
        />
      </div>

      <!-- Blank state placeholder -->
      <div
        v-if="isEditable && isBlankState && !hasBackgroundImage && !fileUploadState.isUploading"
        class="w-full py-88 relative empty-bg"
      >
        <div
          class="flex flex-col items-center justify-end"
        >
          <p
            :class="[
              'inline-flex items-center text-18 font-medium text-gray-600',
              $style['description']
            ]"
          >
            <span>Upload an image </span>
            <b-button
              v-b-tooltip.hover.bottom.v-info.dh0.ds200
              title="Upload from your device"
              variant="primary"
              :disabled="fileUploadState.isUploading"
              class="py-5 px-10 mx-8"
              @click="selectFiles"
            >
              <span class="w-20 icon-util-upload text-20 mr-4" />
              <span>Select</span>
            </b-button>
            <span>or pick one from</span>
            <b-button
              v-b-tooltip.hover.bottom.v-info
              title="Use an image from Unsplash"
              variant="black"
              :disabled="fileUploadState.isUploading"
              class="mx-8 py-5 px-10"
              @click="openUnsplash"
            >
              <span class="w-16 mr-4 icon-logo-unsplash text-16" />
              <span>Unsplash</span>
            </b-button>
          </p>
        </div>
      </div>
      <div
        v-if="isBlankState && hasBackgroundImage && !fileUploadState.isUploading"
        class="w-full py-64"
      />

      <!-- toolbar buttons -->
      <DocumentContentBlockImageToolbarPortal
        :node-id="nodeId"
        :is-blank-state="isBlankState"
        :file-upload-state="fileUploadState"
        @select-files="selectFiles"
        @delete-file="deleteFile"
        @open-unsplash="openUnsplash"
      />

      <!-- Animation if loading -->
      <div
        v-if="fileUploadState.isUploading"
        class="block-image-progress"
      >
        <PieProgressBar :ratio="fileUploadState.progress" />
      </div>

      <!-- Error Message -->
      <div
        v-if="fileUploadState.error"
        class="block-image-error"
      >
        {{ fileUploadState.error || error }}
      </div>
    </FileUploader>
  </div>
</template>

<script>
import { assoc, pathEq } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { computed, inject, ref } from '@vue/composition-api'
import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import { useFileUpload } from '@/v2/services/uploads/uploadsCompositions'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import ObjectResize from '@/components/ObjectResize.vue'
import ImageSearchUnsplashModal from '@/components/ImageSearchUnsplashModal.vue'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import DocumentContentBlockImageToolbarPortal from './DocumentContentBlockImageToolbarPortal.vue'

const { useMutations, useState } = createNamespacedHelpers('documentEditor')
const urlPath = ['contentBlock', 'url']

export default {
  name: 'DocumentContentBlockImage',
  components: {
    FileUploader,
    PieProgressBar,
    ObjectResize,
    DocumentContentBlockImageToolbarPortal,
    ImageSearchUnsplashModal,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isHover: Boolean,
  },
  setup(props, context) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const isUnsplashOpen = ref(false)

    const { focusDisabled } = useState(['focusDisabled'])
    const { setFocusDisabled } = useMutations(['setFocusDisabled'])

    const isEditable = computed(() => isEditor.value && !isLocked.value)
    const {
      state: fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart,
      onFileProgress,
      onFileError,
      onUploadEnd,
    } = useFileUpload(computed(() => props.contentBlock.url))

    const isBlankState = computed(() => pathIsNilOrEmpty(urlPath, props))

    const isObjectAlignStretch = computed(() => pathEq(['styling', 'objectAlign'], 'stretch', props))

    const imageStyle = computed(() => ({
      width:
        isNotNil(props.contentBlock.width)
        && props.contentBlock.width > 0
        && !isObjectAlignStretch.value
          ? `${props.contentBlock.width}px`
          : null,
    }))

    const hasBackgroundImage = computed(
      () => !pathIsNilOrEmpty(['styling', 'backgroundImage'], props)
    )

    const _onFileDone = (file, uploadedFile) => {
      context.emit('update', { url: uploadedFile.url })
      onUploadEnd(file, uploadedFile)
    }

    const _onUploadStart = (...args) => {
      context.emit('async-save-start')
      onUploadStart(...args)
    }

    const onImageResize = ({ width }) => {
      context.emit('update', { width })
    }

    const deleteFile = () => context.emit('update', { url: '' })

    const onImageResizeStart = () => {
      setFocusDisabled(true)
      if (isObjectAlignStretch.value) {
        context.emit('update-node', {
          styling: assoc('objectAlign', 'center', props.styling),
        })
        context.emit('update', { width: null })
      }
    }

    const onImageResizeEnd = () => setFocusDisabled(false)

    const onUnsplashImageSelect = ({ urls }) => {
      context.emit('update', { url: urls.raw })
      isUnsplashOpen.value = false
    }
    const openUnsplash = () => { (isUnsplashOpen.value = true) }

    return {
      isUnsplashOpen,
      isEditable,
      isBlankState,
      isObjectAlignStretch,
      fileUploadState,
      fileUrl,
      hasUrl,
      removeFile,
      onUploadStart: _onUploadStart,
      onFileProgress,
      onFileError,
      onFileDone: _onFileDone,
      onImageResize,
      onImageResizeStart,
      imageStyle,
      onUnsplashImageSelect,
      openUnsplash,
      hasBackgroundImage,
      deleteFile,
      focusDisabled,
      setFocusDisabled,
      onImageResizeEnd,
    }
  },
}
</script>

<style lang="scss" scoped>
.block-image-body {
  display: flex;
  //padding-bottom: 53%;
  position: relative;
  //height: 0;
  //width: 100%;
  //overflow: hidden;
  background-color: transparent;
}

.block-image-body.uploading {
  @apply opacity-50;
}

.block-image-progress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: -37px;
}

.block-image-error {
  position: absolute;
  left: 50%;
  top: $gutterx2;
  transform: translateX(-50%);
  background-color: $orange-300;
  color: white;
  font-size: $fs14;
  font-weight: $medium;
  text-align: center;
  max-width: 310px;
  padding: $gutterx2 24px;
  width: 100%;
  border-radius: $border-radius;
  @include box-shadow-light;
}

.placeholder-icon-wrapper {
  @apply px-8 h-32 bg-gray-400 text-white inline-flex items-center rounded-md mx-4;
}

.empty-bg {
  @apply relative z-1;

}

.empty-bg::before {
  @apply absolute inset-0;
  content: "";
  z-index: -1;
  opacity: 0.04;
  background-image: url('/assets/img/pattern-camera.png');
  background-size: 40%;
}

.column-mto .empty-bg::before {
    background-size: 80%;
}

</style>

<style lang="postcss" module>
:global(.node-group-columns-container) .description {
  @apply flex-col space-y-4;
}
</style>
