
<!-- eslint-disable vue/no-v-html -->
<div :class="['answer-longtext']">
  <!-- Badge showing an answer is needed or if an answer has been added -->
  <AnswerBadge
    v-if="['XL', 'XXL'].includes(nodeWidth)"
    :is-answerable="isAnswerable"
    :has-no-answer="!contentBlock.isDirty"
    position="middle"
  />
  <!-- Answer Editor -->
  <template v-if="isAnswerable">
    <div
      :class="[
        'transition-all',
        'rounded-md border',
        'hover:border-[color:var(--cp-accent)]',
        contentBlock.answer
          ? 'border-gray-200 bg-green bg-opacity-75 print:bg-transparent print:border-0'
          : 'border-gray-200 bg-gray',
        [isEditorFocused && 'border-[color:var(--cp-accent)] ring-4 print:ring-0 ring-[color:rgba(var(--cp-accent-rgb),0.3)] bg-white'],
      ]"
      @contextmenu.stop
    >
      <TextEditor
        :placeholder="$t('blocks.qa.long-placeholder')"
        :show-floating-menu="false"
        :value="contentBlock.answer"
        :autofocus="isEnabledTextEditor"
        :class="[
          $style[`text-variant-${textVariant}`],
          ' text-16 p-16 portal-paragraph-font',
          contentBlock.answer && 'print:p-0'
        ]"
        @blur="updateAnswer"
        @focus="editorHasFocus"
      />
    </div>

    <!-- OKAY button -->
    <div
      v-if="isEditorFocused"
      class="mt-8 -mb-8"
    >
      <b-button
        variant="branded"
        size="sm"
        class="w-auto px-16"
        @click="updateAnswer(contentBlock.answer)"
      >
        <span class="mr-8">Okay</span>
        <span class="w-16 -mr-8 icon_v2-so_tick text-16" />
      </b-button>
    </div>
  </template>

  <!-- Answer content if not editable -->
  <div
    v-else-if="contentBlock.answer"
    :class="[
      'editor-content editor-content-preview',
      $style[`text-variant-${textVariant}`],
      'rounded-md screen:border text-16 screen:p-16',
      'screen:border-gray-200 screen:bg-green bg-opacity-75',
      'portal-paragraph-font',
    ]"
    v-html="contentBlock.answer"
  />
</div>
