import { CATEGORY, LABEL } from '@/v2/services/documentNodes/documentNodesTypes'
import DocumentNodeGroupCaseStudy from '@/v2/features/document/documentNodeGroupImpl/groupCaseStudy/DocumentNodeGroupCaseStudy.vue'
import DocumentNodeGroupPackagedService from '@/v2/features/document/documentNodeGroupImpl/groupPackagedService/DocumentNodeGroupPackagedService.vue'
import DocumentNodeGroupTeamBios from '@/v2/features/document/documentNodeGroupImpl/groupTeamBios/DocumentNodeGroupTeamBios.vue'
import DocumentNodeGroupColumnsContainer from '@/v2/features/document/documentNodeGroupImpl/groupColumnsContainer/DocumentNodeGroupColumnsContainer.vue'
import DocumentNodeGroupColumn from '@/v2/features/document/documentNodeGroupImpl/groupColumn/DocumentNodeGroupColumn.vue'
import DocumentNodeGroupBasic from '@/v2/features/document/documentNodeGroupImpl/groupBasic/DocumentNodeGroupBasic.vue'

export default {
  [CATEGORY.NodeGroupCaseStudy]: {
    label: LABEL[CATEGORY.NodeGroupCaseStudy],
    component: DocumentNodeGroupCaseStudy,
  },
  [CATEGORY.NodeGroupPackagedService]: {
    label: LABEL[CATEGORY.NodeGroupPackagedService],
    component: DocumentNodeGroupPackagedService,
  },
  [CATEGORY.NodeGroupTeamBio]: {
    label: LABEL[CATEGORY.NodeGroupTeamBio],
    component: DocumentNodeGroupTeamBios,
  },
  [CATEGORY.NodeGroupColumnsContainer]: {
    label: 'Columns Container ',
    component: DocumentNodeGroupColumnsContainer,
  },
  [CATEGORY.NodeGroupColumn]: {
    label: 'Column',
    component: DocumentNodeGroupColumn,
  },
  [CATEGORY.NodeGroupBasic]: {
    label: 'Group',
    component: DocumentNodeGroupBasic,
  },
}
