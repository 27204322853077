import Vue from 'vue'
import { ref, unref } from '@vue/composition-api'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'
import { TYPE } from '@/v2/services/downloads/downloadsTypes'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'

const DOWNLOAD_TYPE = {
  [DOCUMENT_CATEGORY.Document]: TYPE.printDocument,
  [DOCUMENT_CATEGORY.DocumentCaseStudy]: TYPE.printDocument,
  [DOCUMENT_CATEGORY.DocumentPackagedService]: TYPE.printDocument,
  [DOCUMENT_CATEGORY.DocumentTeamBio]: TYPE.printDocument,
  [DOCUMENT_CATEGORY.DocumentReusableBlock]: TYPE.printDocument,
  [DOCUMENT_CATEGORY.Folder]: TYPE.zippedFolder,
  [DOCUMENT_CATEGORY.ProjectRootFolder]: TYPE.zippedFolder,
  [DOCUMENT_CATEGORY.ProjectPublicFolder]: TYPE.zippedFolder,
}

/**
 * @param {import('@vue/composition-api').Ref<Object> | Object} document
 */
export default function useDownload(document) {
  const { Download } = Vue.$FeathersVuex.api
  const currentDownload = ref(null)

  const taskDownload = async () => {
    const doc = unref(document)
    const type = DOWNLOAD_TYPE[doc.category]
    if (!type) {
      throw new Error('unsupported download');
    }

    const download = new Download({
      type,
      document: doc._id,
    })

    await download.save()
    currentDownload.value = download
  }

  const { isBusy, error, exec } = useAsyncTask(taskDownload)

  const initiateDownload = () => {
    currentDownload.value = null
    return exec()
  }

  return {
    isBusy,
    error,
    download: initiateDownload,
    currentDownload,
  }
}
