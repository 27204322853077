
<NotificationRenderer
  v-if="!isSingleton"
  :notification="notification"
  icon-variant="icon"
  icon="icon-util-upload-alt"
  icon-class="bg-blue-200 text-blue-500"
  badge-class="bg-blue-500"
  :badge-count="notification.events.length"
  @mark-as-read="notification => $emit('mark-as-read', notification)"
>
  <template #default="{ options }">
    <div>
      Project <span class="font-semibold">{{ notification.subject$.name }}</span>
      has {{ notification.events.length }}
      new {{ $tc('common.shares', notification.events.length) }}
    </div>
  </template>
  <template #events>
    <NotificationList :notifications="notification.events" />
  </template>
</NotificationRenderer>
<NotifDocumentsShared
  v-else
  :notification="singleton"
  :project="notification.project$"
/>
