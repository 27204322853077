<template>
  <div class="group-placeholder mb-24">
    <section>
      <span class="icon-portfolio-placeholder w-208 text-208"></span>
    </section>
    <section>
      <span class="icon-portfolio-placeholder w-208 text-208"></span>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeGroupPackagedServicePlaceholder',
}
</script>

<style lang="postcss" scoped>
.group-placeholder {
  @apply flex flex-row text-gray-100 space-x-20;
}

.group-placeholder section {
  @apply flex grow justify-center rounded-lg overflow-hidden;
  @apply items-center p-20 border-2 border-gray-100;
}

</style>
