<template>
  <div v-fragment>
    <ContentEditable
      tag="h5"
      placeholder="Add notes..."
      :contenteditable="isAnswerable"
      :class="[
        $style[`text-variant-${textVariant}`],
        'portal-paragraph-font',
        'mt-16 text-16 font-medium text-gray-600 leading-snug lg:leading-snug',
        'print:hidden'
      ]"
      :no-newline="false"
      :no-html="false"
      :value="value"
      lazy
      @focus.native="hasFocus = true"
      @input="val => updateNotes(val)"
      @blur.native="hasFocus = false"
      @contextmenu.stop
    />
    <div
      v-if="hasFocus"
      class="mt-8 -mb-8 print:hidden"
    >
      <b-button
        variant="neutral"
        size="sm"
        class="w-auto px-16 mr-4"
        @click="blurOrClick"
      >
        <span class="mr-8">Save note</span>
        <span class="w-12 -mr-8 icon_v2-so_tick text-12" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import ContentEditable from '@/components/ContentEditable.vue'


export default {
  name: 'Notes',
  components: {
    ContentEditable,
  },

  props: {
    isAnswerable: Boolean,

    textVariant: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const hasFocus = ref(false)
    const noteInput = ref('')
    const updateNotes = val => {
      emit('input', { notes: val, isDirty: true })
      noteInput.value = val
    }

    // this will probably never be triggered because on blur the button disappears
    const blurOrClick = () => {
      emit('input', { notes: noteInput.value, isDirty: true })
      hasFocus.value = false
    }
    //

    return {
      updateNotes,
      hasFocus,
      noteInput,
      blurOrClick,
    }
  },
}
</script>
<style lang="postcss" module>
.text-variant-light {
  color: #fff !important;
}
</style>
