<template>
  <span
    :class="[
      className,
      'text-gray-600 whitespace-nowrap',
      displayDate && displayDate.color,
    ]"
  >
    <slot v-bind="{ displayDate }">
      {{ displayDate && displayDate.message }}
    </slot>
  </span>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { isNil, path } from 'ramda'
import {
  parseISO,
  isToday,
  isTomorrow,
  isPast,
  differenceInDays,
} from 'date-fns'
import useLocalization from '@/v2/lib/composition/useLocalization'

export default defineComponent({
  name: 'ActionItemDateDisplay',
  components: {},
  props: {
    className: {
      type: [String, Array],
      default: 'text-14',
    },
    item: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { translate, translateDate } = useLocalization()

    const displayDate = computed(() => {
      if (isNil(path(['item', 'dueDate'], props))) {
        return null
      }
      // const parsedDate = parseISO(`${props.item.dueDate}T00:00:00.000`)
      const parsedDate = parseISO(props.item.dueDate)
      if (isToday(parsedDate)) {
        return {
          message: translate('action-items.due-today').value,
          color: 'text-red-600',
        }
      }
      if (isTomorrow(parsedDate)) {
        return {
          message: translate('action-items.due-tomorrow').value,
          color: 'text-violet-600',
        }
      }
      if (isPast(parsedDate)) {
        return {
          message: translate('action-items.due-past', {
            count: differenceInDays(new Date(), parsedDate),
          }).value,
          color: 'text-red-600',
        }
      }
      return {
        message: translateDate(parsedDate, 'short').value,
        color: 'text-gray-600 dark:text-darkGray-400 capitalize',
      }
    })
    return {
      displayDate,
    }
  },
})
</script>
