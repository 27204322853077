
<div>
  <DocumentFromTemplateCategory
    v-if="!templatePreview"
    :category="templatesCategory"
    :show-blank="showBlank"
    :excluded-categories="excludedCategories"
    :templates-category="templatesCategory"
    @use="onTemplateUse"
    @preview="onTemplatePreview"
  />
  <!-- :document-id="templatePreview.documentId" -->
  <GuestTemplateDocument
    v-else
    :document-id="templatePreview.documentId"
    :category="templatePreview.template_category.name"
  />
</div>
