
<ul :class="['flex items-center p-0 list-none leading-none']">
  <li class="mr-4 text-gray-600 text-12 inline-flex items-center">
    <span class="icon-sync-done text-20 text-gray-500 mr-2"></span>
    <span class="mr-4 capitalize">{{ $t('common.updated') }}</span>
    <LiveFilter
      filter="timeago"
      :value="document.updatedAt"
      :locale="project && project.locale ? project.locale : 'en-US'"
    />
    <span v-if="document.updatedBy && showAuthor">&nbsp;&bull;</span>
  </li>
  <li v-if="showAuthor" class="flex items-center text-gray-600">
    <AvatarUser
      :user-id="document.updatedBy"
      :show-avatar="showAvatar"
      font-size="12"
    />
  </li>
</ul>
