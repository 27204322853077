
<DocumentLink v-slot="{ href, navigate }" :document="document">
  <DocumentComputedData v-slot="{ title }" :document="document">
    <a
      :href="href"
      class="line-clamp-1"
      @click="navigate"
    >
      <WrappedTitle
        :text="title"
        :pre-size="34"
      />
    </a>
  </DocumentComputedData>
</DocumentLink>
