<template>
  <ListLightDropdownItem
    :id="id"
    :label="label"
    :icon="icon"
    @select="$emit('select')"
  >
    <Popover
      v-if="hasDefaultSlot"
      :target="id"
      triggers="hover"
      placement="right"
      :delay="{ show: 500, hide: 0 }"
      :no-fade="true"
      :no-padding="true"
      mode="rich"
    >
      <div
        v-if="image"
        :class="$style.image"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div>
      <div :class="$style.wrapper">
        <h3>{{ label }}</h3>
        <p>
          <slot />
        </p>
      </div>
    </Popover>
    <b-badge
      v-if="newBadge"
      variant="new"
      :class="$style['new-badge']"
    >
      NEW
    </b-badge>
  </ListLightDropdownItem>
</template>

<script>
import { computed } from '@vue/composition-api'
import Popover from '@/components/Popover.vue'
import ListLightDropdownItem from '@/components/List/ListLightDropdownItem.vue'
import { uuid } from '@/lib/helpers/util'

export default {
  name: 'DocumentBlockButtonCreateMenuItem',
  components: {
    Popover,
    ListLightDropdownItem,
  },
  props: {
    icon: String,
    label: String,
    image: String,
    newBadge: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const id = `menu-item-${uuid()}`
    const hasDefaultSlot = computed(() => Boolean(context.slots.default))
    return {
      id,
      hasDefaultSlot,
    }
  },

}
</script>

<style lang="scss" module>
.image {
  height: 136px;
  background-color: $gray-100;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-bottom: 1px solid $gray-300;
}
.wrapper {
  padding: 14px 12px;
  position: relative;
  h3 {
    font-size: $fs16;
    font-weight: $medium;
    color: $gray-700;
  }
  p {
    color: $gray-500;
    line-height: 1.6;
    font-size: $fs15;
    font-weight: $light;
    margin: 0;
  }
}
.new-badge {
  @apply absolute p-4 text-10 leading-none rounded-md top-4 right-12 uppercase;
}
</style>
