import { CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'

export default {
  [CATEGORY.ElementText]: 'Text',
  [CATEGORY.ElementImage]: 'Image',
  [CATEGORY.ElementHeading]: 'Heading',
  [CATEGORY.ElementEstimate]: 'Estimate',
  [CATEGORY.ElementBrief]: 'Brief',
  [CATEGORY.ElementTeamBio]: 'Bio',
  [CATEGORY.ElementCaseStudy]: 'Case Study',
  [CATEGORY.ElementPackagedService]: 'Packaged Service',
  [CATEGORY.ElementAttachments]: 'Attachments',
  [CATEGORY.ElementApproval]: 'Approval Button',
  [CATEGORY.ElementEmbeddedDocument]: 'Document',
  [CATEGORY.ElementCost]: 'Pricing Table',
  [CATEGORY.ElementCostBreakdown]: 'Price Breakdown',
  [CATEGORY.ElementQA]: 'Q&A',
  [CATEGORY.ElementReusableBlock]: 'Document',
}
