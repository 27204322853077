<template>
  <b-form-group :content-cols="contentCols">
    <template #label>
      <span class="inline-flex items-center justify-start">
        <span
          v-if="icon"
          :class="[
            `icon-${icon}`,
            'text-16 w-16 text-gray-700 mr-2',
          ]"
        />
        {{ label }}
      </span>
    </template>

    <b-form-input
      :id="componentID"
      :type="type"
      :placeholder="label"
      :value="value"
      trim
      size="sm"
      :class="inputClass"
      @input="handleInput"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'SettingsInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    contentCols: {
      type: Number,
      default: 12,
    },
    icon: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props, context) {
    const handleInput = val => {
      context.emit('input', val)
    }
    return {
      handleInput,
    }
  },
}
</script>

<style lang="scss" scoped></style>
