import { ref, watch } from '@vue/composition-api';
import { isNil } from 'ramda';
import paymentPlansService from '../paymentPlansService'

export default planId => {
  const item = ref(null)
  const isPending = ref(false)
  const error = ref(null)

  const load = async currentPlanId => {
    isPending.value = true
    error.value = null
    item.value = null

    if (isNil(planId)) {
      return
    }

    try {
      item.value = await paymentPlansService.get(currentPlanId)
    } catch (err) {
      error.value = err
    } finally {
      isPending.value = false
    }
  }

  watch(planId, load, { immediate: true })

  return {
    item,
    isPending,
    error,
  }
}
