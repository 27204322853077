export const ORGANIZATION = 'ORGANIZATION'
export const ORGANIZATION_HOME = 'ORGANIZATION_HOME'
export const ORGANIZATION_TASKS = 'ORGANIZATION_TASKS'

export const ORGANIZATION_INBOX = 'ORGANIZATION_INBOX'

// -- clients
export const ORGANIZATION_CLIENTS = 'ORGANIZATION_CLIENTS'
export const ORGANIZATION_CLIENTS_PLACEHOLDER = 'ORGANIZATION_CLIENTS_PLACEHOLDER'
export const ORGANIZATION_CLIENT = 'ORGANIZATION_CLIENT'
export const ORGANIZATION_CLIENT_DETAILS = 'ORGANIZATION_CLIENT_DETAILS'
export const ORGANIZATION_CLIENT_CONTACTS = 'ORGANIZATION_CLIENT_CONTACTS'

// -- templates
export const ORGANIZATION_TEMPLATES = 'ORGANIZATION_TEMPLATES'
export const ORGANIZATION_TEMPLATES_PROJECTS = 'ORGANIZATION_TEMPLATES_PROJECTS'
export const ORGANIZATION_TEMPLATES_DOCUMENTS = 'ORGANIZATION_TEMPLATES_DOCUMENTS'

// -- library
export const ORGANIZATION_LIBRARY = 'ORGANIZATION_LIBRARY'
export const ORGANIZATION_LIBRARY_PLACEHOLDER = 'ORGANIZATION_LIBRARY_PLACEHOLDER'
// -- library - reusable blocks
export const ORGANIZATION_LIBRARY_REUSABLE_BLOCKS = 'ORGANIZATION_LIBRARY_REUSABLE_BLOCKS'
export const ORGANIZATION_LIBRARY_REUSABLE_BLOCK = 'ORGANIZATION_LIBRARY_REUSABLE_BLOCK'
// -- library - team bios
export const ORGANIZATION_LIBRARY_TEAM_BIOS = 'ORGANIZATION_LIBRARY_TEAM_BIOS'
export const ORGANIZATION_LIBRARY_TEAM_BIO = 'ORGANIZATION_LIBRARY_TEAM_BIO'
// -- library - case studies
export const ORGANIZATION_LIBRARY_CASE_STUDIES = 'ORGANIZATION_LIBRARY_CASE_STUDIES'
export const ORGANIZATION_LIBRARY_CASE_STUDY = 'ORGANIZATION_LIBRARY_CASE_STUDY'
// -- library - packaged services
export const ORGANIZATION_LIBRARY_PACKAGED_SERVICES = 'ORGANIZATION_LIBRARY_PACKAGED_SERVICES'
export const ORGANIZATION_LIBRARY_PACKAGED_SERVICE = 'ORGANIZATION_LIBRARY_PACKAGED_SERVICE'
// -- library - pages
export const ORGANIZATION_LIBRARY_PAGES = 'ORGANIZATION_LIBRARY_PAGES'
export const ORGANIZATION_LIBRARY_PAGE = 'ORGANIZATION_LIBRARY_PAGE'
// -- library - billable services
export const ORGANIZATION_LIBRARY_BILLABLE_SERVICES = 'ORGANIZATION_LIBRARY_BILLABLE_SERVICES'

// -- settings
export const ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS'
export const ORGANIZATION_SETTINGS_MY_ORGANIZATION = 'ORGANIZATION_SETTINGS_MY_ORGANIZATION'
export const ORGANIZATION_SETTINGS_DOMAIN = 'ORGANIZATION_SETTINGS_DOMAIN'
export const ORGANIZATION_SETTINGS_EMAIL = 'ORGANIZATION_SETTINGS_EMAIL'
export const ORGANIZATION_SETTINGS_TEAM = 'ORGANIZATION_SETTINGS_TEAM'
export const ORGANIZATION_SETTINGS_SUBSCRIPTION = 'ORGANIZATION_SETTINGS_SUBSCRIPTION'
export const ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE = 'ORGANIZATION_SETTINGS_SUBSCRIPTION_MANAGE'
export const ORGANIZATION_SETTINGS_PORTAL = 'ORGANIZATION_SETTINGS_PORTAL'
export const ORGANIZATION_SETTINGS_PORTAL_SETTINGS = 'ORGANIZATION_SETTINGS_PORTAL_SETTINGS'
export const ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME = 'ORGANIZATION_SETTINGS_PORTAL_LOGIN_THEME'
export const ORGANIZATION_SETTINGS_PORTAL_SEO = 'ORGANIZATION_SETTINGS_PORTAL_SEO'

// -- payment
export const ORGANIZATION_SUBSCRIPTION = 'ORGANIZATION_SUBSCRIPTION'
export const ORGANIZATION_SUBSCRIPTION_PLANS = 'ORGANIZATION_SUBSCRIPTION_PLANS'
export const ORGANIZATION_SUBSCRIPTION_CHECKOUT = 'ORGANIZATION_SUBSCRIPTION_CHECKOUT'
export const ORGANIZATION_SUBSCRIPTION_SUCCESS = 'ORGANIZATION_SUBSCRIPTION_SUCCESS'
