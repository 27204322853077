<template>
  <span
    class="w-24 flex items-center justify-center h-32"
    :class="[ variant==='full' && 'self-end']"
  >
    <span
      class="block"
      :class="[
        'h-12',
        variant==='full' && ' w-3 bg-gray-700 focus-within:bg-gray-700 bg-opacity-70',
        variant==='small' && ' w-2 bg-gray-300',
      ]"
    ></span></span>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarSeparator',
  props: {
    variant: {
      type: String,
      default: 'full',
    },
  },
}
</script>
