<template>
  <svg class="icon" :class="[cssClass, $style[`size-${size}`]]">
    <use :xlink:href="icon" />
  </svg>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'SvgIcon',
  props: {
    name: String,
    className: [String, Object, Array],
    size: {
      type: String,
      default: 'xs',
    },
  },
  setup(props) {
    const icon = computed(() => `#${props.name}`)
    const cssClass = computed(() => [`icon-${props.name}`, props.className])

    return {
      icon,
      cssClass,
    }
  },
  // computed: {
  //   icon() {
  //     return `#${this.name}`
  //   },
  //   cssClass() {
  //     return [
  //       `icon-${this.name}`,
  //       this.className,
  //       this.$style[`size-${this.size}`],
  //     ]
  //   },
  // },
}
</script>
<style lang="scss" module>
.size-xxs {
  width: 18px;
  height: 18px;
}

.size-sm {
  width: 16px;
  height: 16px;
}

.size-xs {
  width: 24px;
  height: 24px;
}

.size-20 {
  width: 20px;
  height: 20px;
}

.size-28 {
  width: 28px;
  height: 28px;
}

.size-36 {
  width: 36px;
  height: 36px;
}
.size-32 {
  width: 32px;
  height: 32px;
}
.size-lg {
  width: 48px;
  height: 48px;
}

.size-56 {
  width: 56px;
  height: 56px;
}
.size-64 {
  width: 64px;
  height: 64px;
}
.size-72 {
  width: 72px;
  height: 72px;
}


.size-128 {
  width: 128px;
  height: 128px;
}

.size-tree {
  width: 12px;
  height: 12px;
}
</style>
