<template>
  <div v-fragment>
    <PageHeader
      :show-back="pageHeaderShowBack"
      @back="$emit('page-header-back')"
    >
      <template #default>
        <Provide :provide="{ document }">
          <div class="flex items-center gap-x-8">
            <slot name="breadcrumbs" />
          </div>
        </Provide>
      </template>
      <template #actions>
        <Provide :provide="{ document }">
          <div class="flex items-center gap-x-16 -mr-4">
            <slot name="actions" />
          </div>
        </Provide>
      </template>
    </PageHeader>
    <PageFooter v-if="hasFooter">
      <Provide :provide="{ document }">
        <div class="flex-1 flex justify-between flex-grow mr-48">
          <div
            v-if="Boolean($slots['footer-left'])"
            class="flex items-center gap-x-16"
            :class="{ 'w-full': !Boolean($slots['footer-right']) }"
          >
            <slot name="footer-left" />
          </div>
          <div
            v-if="Boolean($slots['footer-right'])"
            class="flex items-center gap-x-16"
          >
            <slot name="footer-right" />
          </div>
        </div>
      </Provide>
    </PageFooter>
    <!-- <div
      class="pl-16 md:pl-24 print:p-0 w-full flex flex-col"
      :class="[
        $isMobile ? 'pr-16' : 'pr-24',
        isLocked ? 'pb-0' : 'pb-32 print:pb-0',
      ]"
    > -->
    <div
      class="p-0 w-full flex flex-col"
    >
      <div class="flex items-center justify-between flex-none">
        <slot name="header" />
      </div>
      <div
        class="print:p-0 mb-72 lg:mb-0 flex-1 flex flex-col"
        :class="{
          'pt-12 lg:pt-24': Boolean($slots.header),
          'pt-0': !$slots.header
        }"
      >
        <slot name="content" />
      </div>
      <div>
        <slot name="info-panel" />
      </div>
    </div>


    <portal v-if="!$isMobile && Boolean($slots['sidebar-right'])" to="app-sidebar-right">
      <ProjectTheme
        :project="currentProject"
        wrapper-class="h-full"
        class-name="h-full"
      >
        <slot name="sidebar-right" />
      </ProjectTheme>
    </portal>
  </div>
</template>
<script>
import { computed, defineComponent, inject } from '@vue/composition-api'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import ProjectTheme from '@/components/ProjectTheme.vue'
import PageFooter from '@/components/PageFooter.vue'
import PageHeader from '@/components/PageHeader.vue'
import Provide from '@/components/Provide.vue'

export default defineComponent({
  name: 'DocRenderer',
  components: {
    ProjectTheme,
    PageHeader,
    PageFooter,
    Provide,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
    pageHeaderShowBack: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isClientPortal = inject('isClientPortal', false)
    const isLocked = inject('isLocked', false)

    const _currentProject = useCurrentProject()
    const currentProject = computed(() => props.project ?? _currentProject.value)

    const hasFooter = computed(
      () => Boolean(context.slots['footer-left']) || Boolean(context.slots['footer-right'])
    );

    return {
      currentProject,
      isClientPortal,
      isLocked,
      hasFooter,
    }
  },
})
</script>
