
<span
  :id="buttonId || componentID"
  v-bind="$attrs"
  :class="[
    $style['button-wrapper'],
    isActive && !disabled && $style['active-button'],
    disabled && $style['disabled-button'],
    variant === 'dark'
      ? $style['button-wrapper-dark']
      : $style['button-wrapper-light']
  ]"
  @click="$emit('click')"
>
  <slot />
  <span v-if="hasDropdown" :class="[$style['toolbar-dropdown']]"></span>
</span>
