<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useLocalStorageReactive } from '@/v2/lib/composition/useLocalStorage'
import { DOCUMENT_VIEW_DEFAULTS } from '@/v2/services/documents/documentsTypes'

export default defineComponent({
  name: 'DocumentViewContext',
  setup(props, context) {
    const viewContext = useLocalStorageReactive('documentViewContext', DOCUMENT_VIEW_DEFAULTS)

    const updateViewContext = update => {
      Object.assign(viewContext, update)
    }

    const sortField = computed(
      () => (viewContext.sortBy === 'unsorted' ? null : viewContext.sortBy)
    )

    return () => context.slots.default({
      viewContext,
      updateViewContext,
      sortField: sortField.value,
    })
  },
})
</script>
