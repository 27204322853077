
<li
  ref="element"
  class="kanban-card group/tasks"
  :class="{ 'selected': selected }"
>
  <slot />
  <div
    v-if="!drag && !isReadonly"
    class="
      bg-gray-200 absolute right-0 top-0 overflow-hidden rounded-bl-md
      dark:bg-white/10
      items-center justify-center h-24 w-24 hidden group-hover/tasks:flex
    "
  >
    <ButtonIcon
      icon="icon_v2-so_trash text-red-500"
      size="18"
      @click.stop="$emit('remove')"
    />
  </div>
</li>
