<template>
  <!-- ref="form" -->
  <form
    :class="[
      $style['form-uploader'],
      block && 'd-block',
      inlineBlock && 'd-inline-block',
      customClass,
    ]"
    @dragover.stop.prevent="dragOverHandler"
    @drop.stop.prevent="dropHandler"
    @dragleave="dragLeaveHandler"
    @submit.prevent
  >
    <input
      ref="input"
      class="file-input"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="onChange($event.target.files)"
    />
    <slot v-bind="{ selectFiles, isDragging }" />
  </form>
</template>

<script>
import { ref } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid'
import { uploads } from '@/v2/services'
import { useConfiguration } from '@/v2/services/configurations/configurationsCompositions'

export default {
  name: 'FileUploader',
  props: {
    accept: {
      type: String,
      default: null,
    },
    customClass: {
      type: [String, Object, Array],
      default: null,
    },
    multiple: Boolean,
    block: {
      type: Boolean,
      default: false,
    },
    inlineBlock: {
      type: Boolean,
      default: false,
    },
    disableAutoUpload: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isDragging = ref(false)
    const input = ref(null)
    let selectedFiles = []

    const { FILE_UPLOAD_MAX_SIZE_KB } = useConfiguration()
    // const form = ref(null)
    // const slots = useSlots()
    // const hasDropSlot = computed(() => Boolean(slots?.drop))

    const upload = async () => {
      const uploadCallback = uploadEvent => {
        const {
          status, file, data, error, ratio, progressEvent,
        } = uploadEvent

        switch (status) {
          case 'progress':
            context.emit('file-progress', file, ratio, progressEvent)
            break
          case 'done':
            context.emit('file-done', file, data)
            break
          case 'error':
            context.emit('file-error', file, error)
            break
          default:
            break;
        }
      }

      context.emit('upload-start', selectedFiles)

      const result = await uploads.create(
        selectedFiles,
        uploadCallback,
        { maxSizeKb: FILE_UPLOAD_MAX_SIZE_KB }
      )

      context.emit('upload-done', result)
      selectedFiles = [];
    }

    const onChange = async files => {
      selectedFiles = Array.from(files);

      selectedFiles.forEach(file => {
        // eslint-disable-next-line no-param-reassign
        file.uuid = uuidv4()
      })

      if (!props.disableAutoUpload) {
        upload()
      }

      context.emit('files-selected', selectedFiles)
    }

    const selectFiles = () => {
      // form.value.reset()
      input.value.value = ''
      input.value.click()
    }

    const dropHandler = event => {
      isDragging.value = false
      onChange(event?.dataTransfer?.files ?? [])
    }

    const dragOverHandler = () => {
      isDragging.value = true
    }

    const dragLeaveHandler = () => {
      isDragging.value = false
    }

    return {
      // form,
      input,
      isDragging,
      selectFiles,
      dropHandler,
      dragOverHandler,
      dragLeaveHandler,
      onChange,
      upload,
    }
  },
}
</script>
<style lang="scss" module>
.form-uploader {
  display: flex;
  //flex-direction: column;
  align-items: center;
  // width: 100%;
}
</style>

<style lang="scss" scoped>
.file-input {
  display: none;
}
</style>
