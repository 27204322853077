<template>
  <div>
    <div>
      <h2 class="text-gray-700 mb-4">PDF / Print Top Margin</h2>
      <p class="text-12 text-gray-400 mb-8">Visible only on PDF or Print</p>
    </div>
    <div class="flex items-center py-4">
      <label
        :for="componentID"
        class="mr-24 text-gray-600 text-14"
      >
        Margin
      </label>
      <RangeSlider
        v-model="printMarginTop"
        :min="minRange"
        :max="maxRange"
      />
      <div class="flex-none ml-8">
        <b-input
          :id="componentID"
          v-model="printMarginTop"
          type="number"
          step="5"
          :min="minRange"
          :max="maxRange"
          size="sm"
          class="form-control-number w-46"
          number
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { restrictValue } from '@/v2/lib/helpers/fp'
import RangeSlider from '@/components/RangeSlider.vue'

export default {
  name: 'DocumentNodePageBreakSettings',
  components: {
    RangeSlider,
  },
  props: {
    styling: {
      type: Object,
      default: () => ({}),
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const bindings = {
      steps: 5,
    }

    const maxRange = 120
    const minRange = 0
    const restrictStepsValue = restrictValue(minRange, maxRange)

    const range = computed(() => (props.value.steps * 100) / maxRange)

    const setSteps = val => {
      bindings.steps = restrictStepsValue(Number(val))
    }

    const printMarginTop = computed({
      get() {
        return props.styling.printMarginTop
      },
      set(value) {
        context.emit('update', { printMarginTop: value })
      },
    })

    return {
      maxRange,
      minRange,
      range,
      setSteps,
      printMarginTop,
    }
  },
}
</script>
