<template>
  <div
    class="flex items-center justify-center relative rounded-full"
    :class="[
      {
        'bg-gray-200 text-gray-500': variant === 'gray',
      },
      !size && {
        'w-32 h-32': slim,
        'w-40 h-40': !slim
      },
      size && `w-${size} h-${size}`,
    ]"
  >
    <span
      :class="[
        !size && {
          'w-16': slim,
          'w-18': !slim
        },
        !iconSize && {
          'text-16': slim,
          'text-18': !slim
        },
        iconSize && `text-${iconSize}`,
        icon,
      ]"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { flipIncludes } from 'ramda-extension';

const VARIANTS = {
  gray: 'gray',
};

export default defineComponent({
  name: 'AvatarIcon',
  props: {
    slim: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: null,
    },
    iconSize: {
      type: Number,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
      validator: flipIncludes(Object.keys(VARIANTS)),
    },
  },
});
</script>
