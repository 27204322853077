
<NotificationDocument v-slot="{ document, badgeIcon }" :notification="notification">
  <DocumentLink v-slot="{ navigate }" :document="document">
    <NotificationRenderer
      v-slot="{ options }"
      :notification="notification"
      icon-variant="icon"
      icon="icon-util-view"
      icon-class="bg-green-200 text-green-700"
      :badge-icon="badgeIcon"
      badge-class="bg-green-500"
      @click="navigate"
      @mark-as-read="notification => $emit('mark-as-read', notification)"
    >
      First public link view
      <template v-if="options.showDocument">
        on <NotificationLinkDocument :document="document" />
        <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
      </template>
    </NotificationRenderer>
  </DocumentLink>
</NotificationDocument>
