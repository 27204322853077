<template>
  <b-dropdown
    menu-class="pl-8 pr-8 pb-8 pt-8 min-w-0"
    toggle-class="p-0 editor-toolbar-button hover:bg-gray-500 hover:bg-opacity-10"
    variant="transparent"
    no-caret
  >
    <template #button-content>
      <div
        title="Heading"
        :class="[
          'h-32 w-32 rounded relative text-white  text-sm flex justify-center items-center',
          isActive && 'bg-green-500 hover:bg-green-600 hover:bg-opacity-100',
        ]"
      >
        <span
          v-if="isActive"
          :class="[
            'w-24 text-24',
            `icon-toolbar-heading-${activeHeading}`,
          ]"
        ></span>
        <span
          v-else
          class="w-24 text-24 icon-toolbar-heading text-white"
        ></span>
        <span :class="[$style['toolbar-dropdown']]"></span>
      </div>
    </template>

    <div class="flex flex-row items-center space-x-8 min-w-0">
      <button
        :class="[$style['heading-item']]"
        @click="selectHeading(activeHeading)"
      >
        <span class="w-24 text-24 icon-toolbar-heading-clear" />
      </button>
      <button
        v-for="heading in headings"
        :key="heading"
        :class="[
          $style['heading-item'],
          Boolean(activeHeading === heading) && $style['active-heading'],
        ]"
        @click="selectHeading(heading)"
      >
        <span
          :class="[
            'w-24 text-24',
            `icon-toolbar-heading-${heading}`,
          ]"
        ></span>
      </button>
    </div>
  </b-dropdown>
</template>

<script>
import { EDITOR_HEADINGS } from '@/types/editor'

export default {
  name: 'EditorToolbarHeading',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    activeHeading: {
      type: [Number, String],
      default: 2,
    },
  },

  setup(props, context) {
    const selectHeading = heading => {
      context.emit('select', heading)
    }
    return {
      headings: EDITOR_HEADINGS,
      selectHeading,
    }
  },
}
</script>

<style lang="postcss" module>
.heading-item {
  @apply h-32 w-32 inline-flex items-center justify-center cursor-pointer;
  @apply font-extrabold relative rounded text-gray-700;
  @apply bg-gray-400 bg-opacity-20 hover:bg-green-500 hover:bg-opacity-30 hover:text-green-900;
}

.active-heading {
  @apply bg-green-500 bg-opacity-30 text-green-900 border border-green-400;
}

.toolbar-dropdown {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255, 255, 255, 0.4) transparent;
  @apply absolute bottom-0 right-0;
}
</style>
