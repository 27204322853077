import { pipe, head, split } from 'ramda'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'

Vue.use(VueI18n)

const dateTimeFormats = {
  en: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  ro: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  es: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  fr: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  pt: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  de: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    short: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  nl: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  it: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  pl: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  vi: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  cs: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  bg: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  fi: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    short: {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
}
const getLocale = pipe(split('-'), head)

export function getDefaultLanguage(locales) {
  const defaultLocale = import.meta.env.VITE_I18N_LOCALE
  const browserLocale = getLocale(navigator.language ?? '')
  const locale = locales.includes(browserLocale) ? browserLocale : defaultLocale
  const language = localStorage.getItem('language') ?? locale
  return language
}

export default ({ locales }) => new VueI18n({
  locale: getDefaultLanguage(locales),
  silentTranslationWarn: true,
  dateTimeFormats,
  missing: (locale, key) => {
    console.log('missing translation', locale, key)
  },
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages: { en },
})
