<template>
  <div class="flex items-center gap-16">
    <DropdownSelect
      v-if="!noGroupBy"
      v-model="groupBy"
      :icon="groupByIcons[groupBy]"
      :options="optionsGroupBy"
      class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
    <DropdownSelect
      v-model="sortBy"
      :icon="sortByIcons[sortBy]"
      :options="optionsSortBy"
      class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
    <DropdownSelect
      v-model="viewAs"
      :icon="viewAsIcons[viewAs]"
      :options="optionsViewAs"
      class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
  </div>
</template>
<script>
import { defineComponent, computed, watch } from '@vue/composition-api'
import DropdownSelect from '@/components/DropdownSelect.vue'
import { pluck } from 'ramda'

const optionsSortBy = [
  {
    text: 'Name',
    icon: 'sort-az',
    value: 'alphabetical',
  },
  {
    text: 'Date Modified',
    icon: 'sort-arrow-down',
    value: 'updatedAt',
  },
  // {
  //   text: 'Client',
  //   icon: 'org-clients',
  //   value: 'client',
  // },
]
const optionsViewAs = [
  // {
  //   text: 'Large Grid',
  //   icon: 'grid',
  //   value: 'largeGrid',
  // },

  {
    text: 'Grid',
    icon: 'grid-small',
    value: 'smallGrid',

  },
  {
    text: 'List',
    icon: 'list-view',
    value: 'list',
  },
]

const optionsGroupBy = [
  {
    text: 'No grouping',
    icon: 'close',
    value: 'none',
  },
  {
    text: 'By client',
    icon: 'clients',
    value: 'client',
  },
]

const viewAsIcons = {
  // largeGrid: 'icon_v2-so_grid',
  smallGrid: 'icon_v2-so_grid-small',
  list: 'icon_v2-so_list-view',
}
const sortByIcons = {
  alphabetical: 'icon_v2-so_sort-az',
  updatedAt: 'icon_v2-so_sort-arrow-down',
  client: 'icon_v2-so_clients',
}
const groupByIcons = {
  none: 'icon_v2-so_close',
  client: 'icon_v2-so_clients',
}

export default defineComponent({
  name: 'ProjectViewOptions',
  components: {
    DropdownSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sortBy: 'updatedAt',
        viewAs: 'smallGrid',
        groupBy: 'none',
      }),
    },
    noGroupBy: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const update = (key, value) => {
      context.emit('input', { ...props.value, [key]: value })
    }

    const sortBy = computed({
      get() {
        return props.value.sortBy
      },
      set(value) {
        update('sortBy', value)
      },
    })

    const viewAs = computed({
      get() {
        return props.value.viewAs
      },
      set(value) {
        update('viewAs', value)
      },
    })

    const groupBy = computed({
      get() {
        return props.value.groupBy
      },
      set(value) {
        update('groupBy', value)
      },
    })

    const validateValueOrDefault = () => {
      const viewAsValues = pluck('value', optionsViewAs)

      if (!viewAsValues.includes(viewAs.value)) {
        update('viewAs', viewAsValues[0])
      }
    }

    validateValueOrDefault()

    return {
      sortBy,
      optionsSortBy,
      sortByIcons,

      viewAs,
      optionsViewAs,
      viewAsIcons,

      groupBy,
      optionsGroupBy,
      groupByIcons,
    }
  },
})
</script>
