
<div v-fragment>
  <ProjectDashboardTaskSection
    v-for="section in sections"
    :key="section.taskSection._id"
    :project="project"
    :section="section"
    :route-tasks="routeTasks"
  />
</div>
