
<div
  v-if="document"
  class="header-folder-icon-wrapper scale-75 flex-none"
>
  <!-- Document icon -->
  <div
    class="w-24 md:w-56 h-full"
  >
    <!-- If is Folder -->
    <span
      v-if="isFolder"
      class="inline-flex w-24 text-32 md:w-56 md:text-56 icon_v2-so_folder-solid"
      :style="{ color: document.color || theme.backgroundColor}"
    ></span>

    <!-- If is File -->
    <!-- {{ document }} -->


          <span
       v-if="isLink || isDoc || isImageFile || isOrgDoc"
      class="inline-flex w-24 text-32 md:w-56 md:text-56 icon_v2-so_file-full opacity-20"
      :style="{ color: document.color || theme.backgroundColor}"
    ></span>

    <FileIcon
      v-if="isFile && !isImageFile"
      :content-type="document?.file?.contentType"
      :size="$isMobile ? '32' : 'lg'"
    />

    <!-- <span v-if="isFile">.{{ customIconForFiles }}</span> -->
  </div>

  <span
    v-if="isFolder"
    :style="{
      color: isPublicFolder ? 'var(--cp-accent-most-readable) '
        : theme.mostReadableColor}"
    :class="[
      document.icon,
      'header-folder-icon',
    ]"
  />

  <!-- if is Doc or Link -->
  <span
    v-if="isLink || isDoc"
    :style="{ color: document.color || theme.backgroundColor}"
    :class="[
      document.icon,
      'header-folder-icon header-position',
    ]"
  />
  <!-- if is Org level doc -->
  <span
    v-if="isOrgDoc"
    :style="{ color: document.color || theme.backgroundColor}"
    :class="[
      DOCUMENT_ICON[document.category],
      'header-folder-icon',
    ]"
  />
</div>
