import { nanoid } from 'nanoid'
import { CATEGORY } from './contentBlocksTypes'

export default () => ({
  common: {
    organization: null,
    updatedBy: null,
    createdBy: null,
    createdAt: null,
    updatedAt: null,
  },
  [CATEGORY.ElementHeading]: {
    heading: 'Untitled Section',
  },

  [CATEGORY.ElementCost]: {
    billables: [
      {
        id: nanoid(),
        name: '',
        unit: 'hour',
        rate: 0,
        quantity: 0,
        isBillable: true,
      },
    ],
  },
  [CATEGORY.ElementEmbed]: {
    data: {
      html: null,
    },
    url: '',
  },
  [CATEGORY.ElementCostBreakdown]: {
    settings: {
      showTotalGraph: true,
    },
  },
  [CATEGORY.ElementAttachments]: {
    files: [],
  },
  [CATEGORY.ElementImage]: {
    url: '',
    width: 0,
  },
  [CATEGORY.ElementQA]: {
    defaultInputType: 'longText',
    common: {
      inputType: '',
      title: '',
      description: '',
      settings: {
        description: true,
        hasTitle: true,
      },
      isDirty: false,
    },
    longText: {
      answer: '',
    },

    multipleChoice: {
      answer: [],
      notes: '',
      settings: {
        hasNotes: false,
        hasMultipleAnswers: false,
        displayVertical: false,
        possibleAnswers: ['First Choice', 'Second Choice'],
      },
    },
    date: {
      answer: '',
      notes: '',
      settings: {
        hasNotes: false,
      },
    },
    fileUpload: {
      answer: [],
      notes: '',
      settings: {
        hasNotes: false,
      },
    },
    rating: {
      answer: 0,
      notes: '',
      settings: {
        types: [
          { text: 'Number', value: 'number' },
          { text: 'Icon', value: 'icon' },
        ],
        selectedType: 'icon',
        selectedIcon: 'star',
        steps: 5,
        showLabel: false,
        leftLabel: "It's ok",
        centerLabel: '',
        rightLabel: 'Awesome!',
        hasNotes: false,
      },
    },
    slider: {
      answer: 0.5,
      notes: '',
      settings: {
        leftLabel: 'Left label',
        rightLabel: 'Right label',
        hasSliderLabel: false,
        sliderLabel: '',
        hasNotes: false,
      },
    },
    imageChoice: {
      answer: [],
      notes: '',
      settings: {
        possibleAnswers: [],
        acceptsMultipleAnswers: true,
        hasNotes: false,
      },
    },
    '2dSlider': {
      answer: {
        x: 0.5,
        y: 0.5,
      },
      notes: '',
      settings: {
        leftLabel: 'Left Label',
        rightLabel: 'Right Label',
        topLabel: 'Top Label',
        bottomLabel: 'Bottom Label',
        hasNotes: false,
      },
    },
  },
  [CATEGORY.ElementTable]: {
    content: '<table><tbody><tr><th></th><th></th><th></th></tr><tr><td></td><td></td><td></td></tr><tr><td></td><td></td><td></td></tr><tr><td></td><td></td><td></td></tr><tr><td></td><td></td><td></td></tr></tbody></table>',
  },
  [CATEGORY.ElementButton]: {
    settings: {
      label: 'Customize me',
      backgroundColor: '#23B839',
      textColor: '#FFFFFF',
      size: 'L',
      url: 'https://www.superokay.com',
      newTab: true,
      border: 16,
    },
  },
  [CATEGORY.ElementSpacer]: {
    settings: {
      height: 32,
    },
  },
})

