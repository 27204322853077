<template>
  <div>
    <CheckMemberRole
      v-slot="{ hasRole: canDeleteAccount }"
      :roles="['admin', 'projectManager', 'member']"
    >
      <PageHeader :title="$t('settings.my-account')">
        <template v-if="canDeleteAccount" #actions>
          <b-button
            variant="neutral"
            size="md"
            @click="deleteAccount"
          >
            <span class="mr-4 -ml-4 icon_v2-so_trash text-red-600"></span>
            <span>Delete Account</span>
          </b-button>
        </template>
      </PageHeader>
    </CheckMemberRole>
    <ProfileGetByUser v-slot="{ profile, isGetPending }" :user-id="user._id">
      <UserSettingsProfile
        v-if="!isGetPending && profile"
        v-slot="{ submit, isLoading, hasChanges }"
        :profile="profile"
        :user="user"
      >
        <PageFooter>
          <Button
            :is-loading="isLoading"
            size="lg"
            variant="primary"
            type="submit"
            @click="submit"
          >
            {{ $t('common.save-changes') }}
          </Button>
          <template v-if="hasChanges" #right>
            <PageFooterMessage />
          </template>
        </PageFooter>
      </UserSettingsProfile>
    </ProfileGetByUser>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { useDeleteAccount } from '@/v2/services/users/usersCompositions'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Button from '@/components/Button.vue'
import ProfileGetByUser from '@/components/ProfileGetByUser.vue'
import UserSettingsProfile from '@/components/UserSettingsProfile.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'

export default defineComponent({
  name: 'ViewUserSettingsProfile',
  components: {
    PageHeader,
    PageFooter,
    Button,
    ProfileGetByUser,
    UserSettingsProfile,
    PageFooterMessage,
    CheckMemberRole,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { deleteAccount } = useDeleteAccount()

    return {
      deleteAccount,
    }
  },
})
</script>
