
<div>
  <MessageBox v-if="!items.length && !isPending" icon="icon_v2-so_info">
    {{ $t('project-updates.nothing-to-show') }}
  </MessageBox>
  <template v-else-if="haveLoaded">
    <div
      v-for="(day, index) in items"
      :key="day.time"
      class="px-8"
    >
      <span
        class="
          inline-block px-12 py-4 my-8 -ml-8
          font-medium text-[color:var(--cp-accent-most-readable)]
          rounded-lg text-16 portal-accent-background portal-heading-font
          capitalize
        "
      >
        {{ $d(new Date(day.time),'long') }}
      </span>
      <div class="flex">
        <div class="relative flex items-center justify-center flex-none w-24 mr-16">
          <span class="block w-2 h-full bg-gray-300 dark:bg-darkGray-800" />
          <span
            v-if="index === items.length - 1"
            class="
              absolute left-0 block w-24 h-24 bg-gray-300 rounded-full
              dark:bg-darkGray-800 top-full
            "
          />
        </div>
        <ul class="flex-1 pt-8">
          <li
            v-for="notification in day.notifications"
            :key="notification._id"
            class="my-8"
          >
            <ProjectTimelineItem :notification="notification" />
          </li>
        </ul>
      </div>
    </div>
    <b-button
      v-if="!showAll"
      :to="routeUpdates"
      variant="neutral"
      size="sm"
      class="mt-32 portal-paragraph-font"
    >
      {{ $t('project-updates.view-all-updates') }}
    </b-button>
    <b-button
      v-if="showAll && hasMore"
      variant="neutral"
      size="sm"
      class="mt-32 portal-paragraph-font"
      @click="loadMore"
    >
      {{ $t('common.load-more') }}
    </b-button>
  </template>
</div>
