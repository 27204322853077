<script>
import { provide, computed } from '@vue/composition-api'
import { isFunction } from 'ramda-adjunct'

export default {
  name: 'Provide',
  props: {
    provide: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    Object.keys(props.provide).forEach(key => {
      const value = props.provide[key]
      provide(
        key,
        isFunction(value) ? value : computed(() => props.provide[key])
      )
    })
    return () => context.slots.default()
  },
}
</script>
