<template>
  <ProjectTimelineRendererGroup :notification="notification" icon="icon_v2-so_action-items">
    <template #label>
      <span class="text-[color:var(--cp-accent)]">{{ author }}</span>
      {{ $t('project-updates.shared-new-docs-and-files') }}
    </template>
    <template #event="{ event }">
      <DocumentLink
        v-slot="{ navigate }"
        :document="event.subject$"
      >
        <FolderItemRow
          :document="event.subject$"
          read-only
          size="m"
          @select="navigate"
        />
      </DocumentLink>
    </template>
  </ProjectTimelineRendererGroup>
</template>
<script>
import { defineComponent, inject, computed } from '@vue/composition-api'
import FolderItemRow from '@/components/FolderItemRow.vue'
import DocumentLink from '@/components/DocumentLink.vue'
import ProjectTimelineRendererGroup from './ProjectTimelineRendererGroup.vue'

export default defineComponent({
  name: 'ItemGroupProjectsNewShares',
  components: {
    ProjectTimelineRendererGroup,
    FolderItemRow,
    DocumentLink,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // const isClientPortal = inject('isClientPortal', false);
    const organization = inject('organization');
    const project = inject('project', null);

    // NOT the same as notification.author property
    // this is either organization or client (depending on the viewer perspective)
    const author = computed(() => {
      switch (props.notification?.action) {
        case 'newSharesClient':
          return project.value.client$?.name ?? 'Client'
        case 'newSharesVendor':
          return organization.value.name
        default:
          return ''
      }
    })

    return {
      author,
      project,
    }
  },
})
</script>

