<script>
import { computed } from '@vue/composition-api'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FolderCard from '@/components/FolderCard.vue'
import FileDocCard from '@/components/FileDocCard.vue'
import FileCard from '@/components/FileCard.vue'

const categoryToComponent = {
  [CATEGORY.Folder]: FolderCard,
  [CATEGORY.ProjectPublicFolder]: FolderCard,
  [CATEGORY.Document]: FileDocCard,
  [CATEGORY.File]: FileCard,
  [CATEGORY.FileLink]: FileCard,
}

export default {
  name: 'DocumentResolver',
  props: {
    document: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'm',
    }
  },
  setup(props, context) {
    /** A UI component resolved according to a document's (file) category */
    const resolvedComponent = computed(() => categoryToComponent[props.document.category])
    return () => context.slots.default({
      component: resolvedComponent.value,
    })
  },
}
</script>
