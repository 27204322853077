
<ProfileGetByUser
  v-if="actionItem.assignedTo"
  v-slot="{ profile }"
  :user-id="actionItem.assignedTo"
>
  <span
    v-if="profile"
    v-b-tooltip.hover.right.v-info.dh0.ds200="
      `${profile.firstName} ${profile.lastName}`
    "
    class="
      inline-flex items-center text-gray-600
      dark:text-darkGray-500 whitespace-nowrap leading-none
    "
    v-bind="$attrs"
  >
    <Avatar
      :profile="profile"
      :size="20"
      font-size="10"
      :show-name="showName"
    />
  </span>
</ProfileGetByUser>
