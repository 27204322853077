import Vue from 'vue'
import { unref, watch, onMounted } from '@vue/composition-api';

const isElementInViewport = el => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default function useScrollIntoView(element) {
  const scrollToTarget = (_element = null) => Vue.nextTick(() => {
    const elem = unref(_element) ?? unref(element)

    if (elem && !isElementInViewport(elem)) {
      elem.scrollIntoView()
    }
  })

  return scrollToTarget
}

export function useScrollIntoViewIfActive(element, active) {
  const scrollIntoView = useScrollIntoView(element)

  const scrollToTarget = doScroll => {
    if (doScroll) {
      scrollIntoView()
    }
  }

  watch(active, scrollToTarget)
  onMounted(() => scrollToTarget(unref(active)))
}
