
<div>
  <!-- Enpty analytics -->
  <div
    v-if="!analytics.length"
    class="text-14 text-left text-gray-500 dark:text-darkGray-400"
  >
    <p>
      No events to show yet.
    </p>
  </div>
  <!-- Days / Event Groups -->
  <div class="pb-40 space-y-16">
    <!-- --- -->
    <!-- DAY -->
    <!-- --- -->
    <div
      v-for="group in analytics"
      :key="group.day"
      class="flex flex-row"
    >
      <!-- ----------------- -->
      <!-- DAY GROUP CONTENT -->
      <!-- ----------------- -->
      <div class="flex-1 min-w-0">
        <!-- TITLE -->
        <h4 class="mb-4 font-semibold text-14 text-gray-800 dark:text-darkGray-200">
          {{ group.time | dateFormat("eeee',' dd MMMMMMMM yyyy") }}
        </h4>
        <!-- ------------------------------ -->
        <!-- LIST OF EVENTS FROM THIS GROUP -->
        <!-- ------------------------------ -->
        <div class="flex flex-row items-stretch">
          <!-- EVENTS -->
          <ul class="grow min-w-0 space-y-8">
            <li
              v-for="event in group.items"
              :key="event._id"
              class="text-gray-700 dark:text-darkGray-300 text-14"
            >
              <!-- DOCUMENT VIEW -->
              <div v-if="event.isViewGuest">
                <div class="flex items-center">
                  <!-- ICON -->
                  <div class="flex items-center justify-center flex-shrink-0 w-24">
                    <span
                      :class="[
                        'text-gray-400 dark:text-darkGray-600',
                        {
                          'icon-util-view': !event.isPdfDownload,
                          'icon_v2-so_download': event.isPdfDownload,
                        }
                      ]"
                    />
                  </div>
                  <!-- NOTIFICATION BODY -->
                  <p class="grow leading-none font-normal text-gray-700 dark:text-darkGray-300">
                    <template v-if="event.isPdfDownload">Downloaded as PDF</template>
                    <template v-else-if="event.isFirstViewGuest">First View</template>
                    <template v-else>Viewed</template>
                  </p>
                  <!-- Date -->
                  <p class="flex-shrink-0 text-gray-500  dark:text-darkGray-600 text-12">
                    {{ event.time | dateFormat('hh:mm aaa') }}
                  </p>
                </div>
                <!-- meta -->
                <p
                  :id="`event-${event.time}`"
                  class="text-gray-400  dark:text-darkGray-600 text-12 pl-24"
                >
                  from {{ event.clientGeoIp | geoIp }}
                </p>
                <b-popover
                  :target="`event-${event.time}`"
                  triggers="hover"
                  placement="top"
                  :fallback-placement="['top']"
                  :custom-class="[
                    $style.popover,
                    'p-8 bg-gray-900',
                    'w-280'
                  ].join(' ')"
                >
                  <ul class="geo-list">
                    <li class="geo-item">
                      <span class="geo-label">IP</span>
                      <span class="geo-value">{{ event.clientGeoIp?.address || "Unknown" }}</span>
                    </li>
                    <li class="geo-item">
                      <span class="geo-label">Location</span>
                      <span>{{ event.clientGeoIp | geoIp }}</span>
                    </li>
                    <li class="geo-item">
                      <span class="geo-label">Device</span>
                      <span class="geo-value">{{ event.geoUserAgent | userAgent }}</span>
                    </li>
                  </ul>
                </b-popover>
              </div>

              <!-- QA ANSWER -->
              <div v-if="event.isQaAnswered">
                <div class="flex items-center">
                  <!-- ICON -->
                  <div class="flex items-center justify-center flex-shrink-0 w-24">
                    <span class="icon-object-QA-answer text-gray-400" />
                  </div>
                  <!-- NOTIFICATION BODY -->
                  <p class="flex flex-1 min-w-0 mr-16 truncate">
                    Answered "{{ event.subject$?.title ?? '?' }}"
                  </p>
                  <!-- Date -->
                  <p class="flex-shrink-0 text-gray-500 text-12">
                    {{ event.time | dateFormat('hh:mm aaa') }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
