
<DocumentNodeRenderer :node="node">
  <template #default="{ styling, textVariant, nodeWidth }">
    <DocumentContentBlockCostBreakdown
      :document="document"
      :content-block="contentBlock"
      :node-id="node._id"
      :styling="styling"
      :text-variant="textVariant"
      :node-width="nodeWidth"
      @update="(data, options) => $emit('update', data, options)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
