<template>
  <Error
    variant="danger"
    title="404"
    error="404 not found"
  >
    <div>Page not found</div>
  </Error>
</template>

<script>
import Error from '@/components/Error.vue'

export default {
  name: 'ViewErrorNotFound',
  components: {
    Error,
  },
}
</script>

<style>
</style>
