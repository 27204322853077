<template>
  <div
    v-if="hasNoAnswer && isAnswerable"
    :class="[$style['a-badge-wrapper'], $style[`${position}`]]"
  >
    <span
      :class="[$style['a-badge'], $style['unanswered']]"
      class="select-none"
    >
      Answer
    </span>
    <!-- <span
      v-else-if="!hasNoAnswer"
      :class="[$style['a-badge'], $style['answered']]"
      class="select-none"
    >
      <span class="icon_v2-so_tick text-12 w-12 text-green-700 mr-2 -ml-2" />
    </span> -->
  </div>
</template>

<script>
export default {
  props: {
    hasNoAnswer: {
      type: Boolean,
      default: false,
    },
    isAnswerable: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'top',
    },
  },
}
</script>

<style lang="postcss" module>
.a-badge-wrapper {
  @apply absolute h-20 flex justify-start items-center text-12 w-96;
}

.a-badge {
  @apply hidden md:inline-flex print:hidden items-center uppercase px-6 font-bold leading-none h-20 rounded-md md:rounded-tl-none md:rounded-bl-none;
}

.top {
  @apply md:top-8 md:-right-112;
}

.middle {
  @apply md:top-1/2 md:-right-112 md:-mt-10;
}

.a-badge::after {
  @apply absolute w-6 md:w-8 h-full top-full right-1/2 transform rotate-90 md:rotate-0 -translate-y-8 md:translate-y-0 translate-x-1/2 md:top-auto md:right-full md:transform-none;
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  content: '';
}

.unanswered,
.unanswered::after {
  @apply bg-yellow-50 text-gray-800;
}

.answered,
.answered::after {
  @apply bg-green-100 text-green-800;
}
</style>
