<template>
  <Button
    v-bind="$attrs"
    block
    size="md"
    variant="branded"
    class="text-15 font-semibold ring-1 rounded-md"

    v-on="$listeners"
  >
    <span
      v-if="icon"
      :class="icon"
      class="text-24 w-24 mr-10"
    /> {{ label }}
  </Button>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'CommentsFormButton',
  components: {
    Button,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    isNewGuest: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
