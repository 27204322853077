
<button
  class="
    border-none p-0 m-0
    disabled:opacity-50 inline-flex items-center justify-center
    hover:opacity-70 hover:disabled:opacity-50
  "
  :class="{
    'opacity-50': disabled,
    'bg-transparent': !active,
    'bg-green-500 text-white': active
  }"
  :disabled="disabled"
  :type="type"
  v-on="$listeners"
>
  <slot />
</button>
