import { computed } from '@vue/composition-api'
import { useUser } from '@/v2/services/users/usersCompositions'

export const useCurrentProfile = () => {
  const { user, isAuthenticated } = useUser()
  const profile = computed(() => (isAuthenticated.value ? user.value.profile$ : null))

  return {
    profile,
    isPending: false,
  }
}
