<template>
  <ul class="space-y-12 text-16 list-none">
    <li
      v-for="(item, index) in items"
      :key="item[idProp]"
      class="item"
      :class="{
        'item-checked': selection[item[idProp]],
        'item-default': !selection[item[idProp]]
      }"
      @click="toggle(item[idProp])"
    >
      <div
        class="w-24 h-24 flex items-center justify-center rounded-md mr-6 border"
        :class="{
          'border-green-600 bg-green-500 text-green-100': selection[item[idProp]],
          'border-gray-200 bg-white text-gray-200': !selection[item[idProp]]
        }"
      >
        <span class="icon_v2-so_tick text-16 w-16"></span>
      </div>
      <slot
        :item="item"
        :index="index"
        :selected="selection[item[idProp]]"
      />
    </li>
  </ul>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ListMultiSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    idProp: {
      type: String,
      default: '_id',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const selection = computed(
      () => Object.fromEntries(props.value.map(id => [id, true]))
    )

    const itemIds = computed(
      () => props.items.map(item => item[props.idProp])
    )

    const toggle = id => {
      const newValue = itemIds.value.filter(itemId => {
        const val = selection.value[itemId]
        return itemId === id ? !val : val
      })

      context.emit('input', newValue)
    }

    return {
      toggle,
      selection,
    }
  },
})
</script>
<style lang="postcss" scoped>
.item {
  @apply rounded-md border shadow px-8 py-8;
  @apply text-16 flex-1 flex flex-row items-center w-full;
  @apply hover:cursor-pointer hover:shadow transition-all relative;
}

.item-default {
  @apply border-gray-200 bg-white;
}

.item-checked {
  @apply bg-green-100 border-green-600 text-green-800;
}
</style>
