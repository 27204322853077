
<ProjectTheme
  :project="project"
  no-dark-mode
  class-name="bg-transparent relative flex flex-col flex-1 p-0 h-144"
>
  <div
    class="
      flex flex-col justify-between w-full h-full p-0 overflow-visible
      transition-all cursor-pointer group card-interactive hover:z-10 project-card
    "
    @click="$emit('select')"
  >
    <div
      class="relative flex flex-col items-stretch h-full p-16 rounded-md "
    >
      <div class="relative z-10 flex-1 flex-grow">
        <header class="flex-1">
          <div class="flex items-center justify-between">
            <h2
              class="
                inline-flex items-center flex-1 font-medium leading-tight
                text-gray-800 cursor-pointer text-16 group-hover:underline
              "
            >
              <a
                class="
                  hover:underline
                  group-hover:text-[color:var(--cp-link-color)]
                  project-link"
                @click.stop="$emit('select')"
              >
                {{ project.name }}
              </a>
            </h2>
            <slot name="menu" dark />
          </div>
          <div
            v-if="project.client$"
            class="inline-flex items-center leading-none text-gray-500 text-13"
          >
            <span class="mr-4 icon_v2-so_clients text-20" />
            <span>{{ project.client$.name }}</span>
          </div>
        </header>
      </div>

      <footer
        class="relative flex items-center justify-between pt-8 mt-auto z-1"
        :class="{ 'flex-row-reverse': readOnly || hideAmounts }"
      >
        <!-- COSTS -->
        <div v-if="!readOnly && !hideAmounts" class="flex text-12 gap-x-16">
          <!-- total cost -->
          <CurrencyFormatter
            v-if="project.statistics.cost"
            :value="project.statistics.cost"
            :currency="project.currency"
            class="text-gray-500"
          />

          <!-- approved cost -->
          <CurrencyFormatter
            v-if="project.statistics.approvedCost"
            :value="project.statistics.approvedCost"
            :currency="project.currency"
            class="text-green-500"
          />
        </div>
        <!-- end COSTS -->
        <div class="flex items-center justify-end gap-8 leading-none">
          <p class="inline-flex items-center text-gray-400 dark:text-white/40 text-13">
            <span class="w-20 mr-4 text-20 icon_v2-so_time-oclock" />
            <LiveFilter
              filter="timeago"
              :locale="project.locale"
              :value="project.updatedAt"
            />
          </p>
          <Avatar
            v-if="project.updatedBy$?.profile$"
            :profile="project.updatedBy$.profile$"
            :show-name="false"
          />
        </div>
        <section v-if="$slots.default" class="mt-8">
          <slot />
        </section>
      </footer>
    </div>
  </div>
</ProjectTheme>
