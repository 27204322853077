<template>
  <svg :style="{ width, height }" class="icon-graphic">
    <use :xlink:href="ref" />
  </svg>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'SvgGraphic',
  props: {
    name: String,
    width: String,
    height: String,
  },
  setup(props) {
    const ref = computed(() => {
      return `#${props.name}`
    })
    return {
      ref,
    }
  },
  // computed: {
  //   ref() {
  //     return `#${this.name}`
  //   },
  // },
}
</script>

<style lang="scss" scoped>
svg {
  width: 100%;
}
</style>
