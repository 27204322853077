
<div>
  <div class="relative">
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="top"
    />
    <Slider2D
      :disabled="!isAnswerable"
      :value="contentBlock.answer"
      :ping="!Boolean(contentBlock.isDirty)"
      @input="answer => $emit('update', { answer, isDirty: true })"
      @reset="$emit('reset')"
    />

    <div
      :class="[
        'z-2 absolute inset-0 w-full h-full',
        'flex flex-col justify-between items-center',
        'pointer-events-none',
        'portal-paragraph-font',
      ]"
    >
      <div class="text-center">
        <span class="slider2d-label">{{ topLabel }}</span>
      </div>
      <div
        class="w-full grow-1 flex flex-row justify-between items-center"
      >
        <div class="max-w-96 md:max-w-128 text-left">
          <span class="slider2d-label">{{ leftLabel }}</span>
        </div>
        <div class="max-w-96 md:max-w-128 text-right">
          <span class="slider2d-label">{{ rightLabel }}</span>
        </div>
      </div>
      <div class="text-center">
        <span class="slider2d-label">{{ bottomLabel }}</span>
      </div>
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
