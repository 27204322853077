
<div
  class="
    flex items-center gap-18
    mb-24
    py-16 text-gray-500
    max-w-[820px]
  "
>
  <span
    v-if="icon"
    class="text-64"
    :class="icon"
  />
  <div class="text-14">
    <h3 v-if="title" class="mb-4 font-semibold text-16">{{ title }}</h3>
    <p>
      <slot />
    </p>
  </div>
</div>
