
<ModalBasic
  :title="title"
  :no-close="state.isCreatingDocument"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="md"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <b-form ref="form" @submit.prevent="onSubmit">
      <FormMessage v-if="state.formMessage">
        {{ state.formMessage }}
      </FormMessage>
      <b-form-group v-if="currentProjectId">
        <FormField label="Destination Folder">
          <OrganizationFolderTree
            v-model="state.selectedFolderId"
            :organization-id="organizationId"
            :selected-project-id="currentProjectId"
          />
        </FormField>
      </b-form-group>
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          :disabled="state.isCreatingDocument"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="md"
          :is-loading="state.isCreatingDocument"
          :disabled="!state.selectedFolderId"
        >
          Duplicate
        </Button>
      </Buttons>
    </b-form>
  </template>
</ModalBasic>
