<template>
  <FeathersVuexGet
    :id="nodeId"
    v-slot="{ item: node, isGetPending }"
    service="document-nodes"
    local
  >
    <DocumentNodeResolverViewer
      v-if="!isGetPending"
      v-slot="{ component }"
      :node="node"
    >
      <component
        :is="component"
        :node="node"
        :width="width"
        :content-block="node.contentBlock$"
        @update="data => updateContentBlock(node.contentBlock$, data)"
        @async-save-start="$emit('async-save-start')"
      />
    </DocumentNodeResolverViewer>
  </FeathersVuexGet>
</template>
<script>
import isNil from 'ramda/src/isNil'
import { rejectNil } from 'ramda-extension'
import { inject } from '@vue/composition-api'
import debounce from '@/v2/lib/helpers/debounce'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import DocumentNodeResolverViewer from '@/v2/features/document/documentNode/DocumentNodeResolverViewer.vue'

export default {
  name: 'DocumentNode',
  components: {
    DocumentNodeResolverViewer,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },

    isFirst: Boolean,
    isLast: Boolean,
    width: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { sharingToken } = useRouteParams(['sharingToken'])
    let contentBlockClone = null
    const isSandbox = inject('isSandbox')

    const patch = debounce(
      (contentBlock, data) => contentBlock.patch(
        rejectNil({ data, sharingToken: sharingToken.value })
      ),
      500
    )

    const updateContentBlock = (contentBlock, data) => {
      if (isNil(contentBlockClone)) {
        contentBlockClone = contentBlock.clone()
      }

      Object.assign(contentBlockClone, data)
      contentBlockClone.commit()

      if (!isSandbox.value) {
        patch(contentBlock, data)
      }
    }

    return {
      updateContentBlock,
      isSandbox,
    }
  },
}
</script>
