
<DocumentGet
  :id="contentBlock.embeddedDocument"
  v-slot="{ document, isGetPending, isBlankState, hasImage, imageCssStyle }"
  :edit-scope="editScope"
  :params="{ embed: true }"
>
  <DocumentLink
    v-if="!isGetPending && document"
    v-slot="{ navigate }"
    :document="document"
  >
    <div
      class="overflow-hidden transition-all cursor-pointer block-portfolio-card group card-interactive"
      data-intercom-target="Case Study Block"
      @click="navigate"
    >
      <div
        v-if="standalone"
        class="absolute flex flex-col items-center invisible ml-10 top-8 right-8 group-hover:opacity-100 group-hover:visible"
      >
        <Button
          variant="delete"
          class="w-32 h-32 p-0"
          @click.stop="$emit('remove', document)"
        >
          <span class="w-20 icon_v2-so_trash text-20"></span>
        </Button>
      </div>

      <div v-if="isBlankState" class="empty">
        <div class="portfolio-placeholder">
          <span class="icon-portfolio-placeholder w-216 text-216"></span>
        </div>
        <div class="portfolio-card-inner">
          <b-button
            block
            variant="light"
            @click.stop="navigate"
          >
            Update Case Study
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="portfolio-image-wrap">
          <img
            v-if="hasImage"
            class="portfolio-image"
            :style="imageCssStyle"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
          <div v-else class="portfolio-image"></div>
        </div>

        <div class="portfolio-card-inner portal-paragraph-font">
          <h2 class="portfolio-title portal-heading-font">
            {{ document.title }}
          </h2>
          <small v-if="document.year" class="portfolio-subtitle">
            {{ document.year }}
          </small>
          <p v-if="document.description" class="portfolio-description">
            {{ document.description }}
          </p>
          <div class="portfolio-info">
            <b-button
              variant="slim"
              class="text-16 hover:text-[color:var(--cp-link-color)] hover:underline"
            >
              {{ $t('doc-types.view-doc',{doc:$tc('doc-types.case-study',1)}) }}
            </b-button>
            <div class="badge-wrap">
              <DocumentAnalytics v-if="showAnalytics" :document="document" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </DocumentLink>
</DocumentGet>
