<template>
  <DocumentPage :document="document" :hide-page-border="hidePageBorder">
    <main class="bg-white w-full">
      <DocumentNodeGroupRoot :is-read-only="true" />
    </main>
  </DocumentPage>
</template>
<script>
import { defineComponent, onMounted } from '@vue/composition-api'
import useGlobalFlag from '@/v2/lib/composition/useGlobalFlag'
import DocumentPage from '@/components/Document/Editor/DocumentPage.vue'
import DocumentNodeGroupRoot from '@/v2/features/document/documentNodeGroupImpl/groupRoot/DocumentNodeGroupRoot.vue'

export default defineComponent({
  name: 'DocumentViewerContent',
  components: {
    DocumentPage,
    DocumentNodeGroupRoot,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    hidePageBorder: Boolean,
  },
  setup() {
    const setPdfReady = useGlobalFlag('__PDF_READY')
    onMounted(() => {
      setPdfReady(true)
    })
  },
})
</script>
