
<ProjectCreate v-slot="{ create }">
  <div
    v-if="viewAs === 'card'"
    v-track="['Project Templates Modal Opened', {
      source: 'card',
    }]"
    class="
      group cursor-pointer
      w-full
      overflow-hidden
      rounded-lg
      min-h-144
      p-0 border-dashed border-gray-300 border-2
      flex flex-col md:h-full items-center justify-center
      transition-all
      hover:bg-black/[0.05] hover:border-gray-400
      text-gray-500 hover:text-gray-700
    "
    data-intercom-target="Dashboard New Project Button"
    @click="create"
  >
    <div class="relative w-full h-1/2">
      <div class="bg-preview-light inset-0 absolute transform rotate-6 opacity-60"></div>
      <div class="bg-preview-light inset-0 absolute transform -rotate-3 opacity-60"></div>
      <div class="bg-preview-light inset-0 absolute"></div>
    </div>
    <span class="font-semibold text-14 mt-16 inline-flex items-center leading-none">
      <span class="icon_v2-so_plus text-24"></span>
      Create a new project
    </span>
  </div>
  <b-button
    v-else-if="viewAs === 'button'"
    v-track="['Project Templates Modal Opened', {
      source: 'button',
    }]"
    :size="size"
    :variant="variant"
    class="mr-auto"
    @click="create"
  >
    <span :class="iconClass" />
    <span>New project</span>
  </b-button>
</ProjectCreate>
