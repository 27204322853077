
<ModalBasic
  title="Add billable service from your library"
  :hide-footer="true"
  dialog-class="modal-dialog-tertiary"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #body="{ close }">
    <FeathersVuexFind
      v-slot="{ items: billables, isFindPending }"
      service="billables"
      :params="findParams"
    >
      <div class="fade-to-white fade-from-bottom">
        <div
          v-if="!isFindPending"
          class="flex flex-col space-y-6 px-16 pb-88 overflow-y-auto max-h-vh-2/3 min-h-384"
        >
          <ListMultiSelectBillables
            :billables="billables"
            :currency="currency"
            @selected="updateSelection"
          />
        </div>
      </div>
    </FeathersVuexFind>
    <Buttons>
      <Button
        type="button"
        variant="neutral"
        size="md"
        @click="close"
      >
        Cancel
      </Button>
      <b-button
        type="submit"
        variant="primary"
        size="md"
        :disabled="!selectedItems.length"
        @click="addMultipleBillables()"
      >
        <span v-if="!selectedItems.length">
          Add billable services
        </span>
        <span v-else>
          Add {{ 'service' | pluralize(selectedItems.length, true) }}
        </span>
      </b-button>
    </Buttons>
  </template>
</ModalBasic>
