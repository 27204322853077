<script>
import { rejectNil, isNotNil } from 'ramda-extension'
import { isNonEmptyArray } from 'ramda-adjunct'
import { ref, onUnmounted } from '@vue/composition-api'
import debounce from '@/v2/lib/helpers/debounce'
import feathersClient from '@/v2/services/feathersClientService'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

export default {
  name: 'FeathersCount',
  props: {
    query: {
      type: Object,
      default: () => null,
    },
    model: {
      type: String,
      required: true,
    },
    events: {
      type: Array,
      default: () => ['created', 'patched'],
    },
    organization: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const Model = context.root.$FeathersVuex.api[props.model]
    const org = !props.organization && useCurrentOrganization()
    const count = ref(null)

    const fetchCount = async () => {
      const params = {
        query: rejectNil({
          ...props.query,
          organization: props.organization ? props.organization._id : (org.value?._id ?? null),
        }),
      }

      count.value = await Model.count(params)
    }

    const fetchCountDebounced = debounce(fetchCount, 1000)

    if (isNotNil(props.query) && isNonEmptyArray(props.events)) {
      const service = feathersClient.service(Model.servicePath)
      props.events.forEach(event => service.on(event, fetchCountDebounced))
      onUnmounted(() => {
        props.events.forEach(event => service.off(event, fetchCountDebounced))
      })

      fetchCount();
    }

    return () => context.slots.default({ count: count.value })
  },
}
</script>
