
<portal
  :to="`content-block-settings-${nodeId}`"
>
  <div class="flex flex-row">
    <DocumentNodeEditorToolbarSeparator />
    <DocumentNodeEditorToolbarGroup
      label="Settings"
    >
      <DocumentContentBlockCostBreakdownSettings
        :popover-target-id="popoverTargetId"
        :value="contentBlock.settings"
        @input="(settings, data) => $emit('update', { settings, ...data })"
      />
    </DocumentNodeEditorToolbarGroup>
  </div>
</portal>
