<template>
  <FileUploader
    v-slot="{ selectFiles, isDragging }"
    multiple
    block
    @upload-start="onUploadStart"
    @upload-done="onUploadDone"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <div
      v-if="!(isLocked && !hasFiles)"
      class="p-16 overflow-hidden border-2 border-gray-200 border-dashed rounded-xl portal-paragraph-font print:hidden"
    >
      <AnswerBadge
        v-if="['XL', 'XXL'].includes(nodeWidth)"
        :is-answerable="isAnswerable"
        :has-no-answer="!contentBlock.isDirty"
        position="top"
      />
      <!-- ------------- -->
      <!-- Error message -->
      <!-- ------------- -->
      <div
        v-if="filesErrors.length"
        class="attach-note"
      >
        <h4>
          <span class="w-16 mr-4 text-16 icon-alert"></span>
          <span>{{ $t('blocks.qa.files-error') }}</span>
        </h4>
        <div
          v-for="fileError in filesErrors"
          :key="fileError.uuid"
        >
          <strong>{{ fileError.name }}</strong>
          {{ fileError.local.error | error }}
        </div>
      </div>

      <!-- ------------ -->
      <!-- IS UPLOADING -->
      <!-- ------------ -->
      <section v-if="isUploading || uploadedBatch.files.length > 0">
        <portal to="right-drawer">
          <PendingUploads :pending-uploads="uploadedBatch.files" @close="onPendingUploadsDone" />
        </portal>
      </section>

      <!-- If we have files -->
      <div v-if="hasFiles">
        <LightboxGallery
          :files="filesMedia"
          :index="index"
          @close="index = null"
        />

        <section class="attach-images-wrap">
          <div
            v-for="(file, fileIndex) in filesMedia"
            :key="file.uuid"
            :class="['attach-item-wrap', file.local && 'uploading-image']"
          >
            <DocumentContentBlockAttachmentsMedia
              v-if="!file.local"
              :file="file"
              :read-only="isLocked"
              @remove="remove(file)"
              @click-thumbnail="index = fileIndex"
            />
          </div>
        </section>
        <section class="mb-16 attach-files-wrap">
          <div
            v-for="fileNonImage in filesNonMedia"
            :key="fileNonImage.uuid"
            :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
          >
            <DocumentContentBlockAttachmentsFile
              v-if="!fileNonImage.local"
              :file="fileNonImage"
              :read-only="isLocked"
              @remove="remove(fileNonImage)"
            />
          </div>
        </section>
      </div>


      <!-- UPLOAD FILES BUTTON -->
      <div
        v-else
        class="mb-16 font-medium text-gray-500 text-16"
      >
        {{ $t('blocks.qa.files-instructions') }}
      </div>
      <div
        v-if="!isLocked"
        :class="
          [
            isUploading && 'opacity-70 pointer-events-none',
            'flex justify-start items-center',
            'w-full',
            'px-16 py-8 my-4',
            'bg-green rounded-md border border-green-200',
            'cursor-pointer select-none transition-all transform-gpu',
            'group',
            'hover:ring-4 hover:ring-opacity-20 hover:ring-green-500 hover:border-green-500'
          ]
        "
        @click="selectFiles"
      >
        <span
          :class="[
            'icon-util-upload h-64 text-64',
            'text-green-900 opacity-20',
            'mr-16',
            'transition-all group-hover:text-green-600 group-hover:opacity-100'

          ]"
        />
        <div class="leading-4 text-left text-green-600 text-14">
          <span class="block font-semibold underline text-18">
            {{ $t('blocks.qa.files-choose') }}
          </span>
          <span
            class="font-medium text-green-900 opacity-30"
          > {{ $t('blocks.qa.files-limit') }}</span>
        </div>
      </div>

      <!-- Drop overlay -->
      <div
        v-if="isDragging"
        :class="[
          'attach-empty attach-hovered',
          'absolute inset-0 pointer-events-none z-1',
          'flex items-center justify-center flex-col',
        ]"
      >
        <span class="font-semibold text-green-900 opacity-75 text-42">Drop your files here</span>
      </div>
    </div>

    <Notes
      v-if="contentBlock.settings.hasNotes"
      :is-answerable="isAnswerable"
      :text-variant="textVariant"
      :value="contentBlock.notes"
      @input="data => $emit('update', data)"
    />
  </FileUploader>
</template>

<script>
import { computed, inject, ref, reactive } from '@vue/composition-api'
import { useFilesUpload } from '@/v2/services/uploads/uploadsCompositions'
import Notes from '@/components/Notes.vue'
import AnswerBadge from '@/components/Document/AnswerBadge.vue'
// File Uploader
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import LightboxGallery from '@/components/LightboxGallery.vue'
import DocumentContentBlockAttachmentsFile from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsFile.vue'
import DocumentContentBlockAttachmentsMedia from '@/v2/features/document/documentNodeImpl/attachments/DocumentContentBlockAttachmentsMedia.vue'
import PendingUploads from '@/components/PendingUploads.vue'

export default {
  name: 'DocumentContentBlockQAFileUpload',
  components: {
    // FILE UPLOADER
    FileUploader,
    LightboxGallery,
    DocumentContentBlockAttachmentsFile,
    DocumentContentBlockAttachmentsMedia,
    PendingUploads,
    // QA
    Notes,
    AnswerBadge,
  },
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isAnswerable: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const isLocked = inject('isLocked', false)
    const index = ref(null)

    const updateAnswer = newAnswer => {
      context.emit('update', { answer: newAnswer, isDirty: true })
    }
    const add = files => updateAnswer([...props.contentBlock.answer, ...files])
    const remove = file => updateAnswer(
      props.contentBlock.answer.filter(({ uuid }) => file.uuid !== uuid)
    )

    const {
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
    } = useFilesUpload(
      computed(() => props.contentBlock.answer),
      add
    )
    // TODO: Maybe we could pass a flag to `useFilesUpload` so that it can do the below behind the
    // scenes
    const pendingUploads = computed(() => files.value.filter(file => file.local))
    const uploadedBatch = reactive({
      files: [],
    })

    const onPendingUploadsDone = () => {
      uploadedBatch.files = []
    }

    const _onUploadStart = (...args) => {
      // Need to let `DocumentNodeEditorModel.vue` component know it should create a Feathers Vuex
      // clone to properly update the `contentBlock` by emitting the below event
      context.emit('async-save-start')
      onUploadStart(...args)
      uploadedBatch.files = [...pendingUploads.value]
    }

    return {
      // FLAGS
      isLocked,
      isUploading,
      hasFiles,
      files,
      filesMedia,
      filesNonMedia,
      filesErrors,
      index,
      pendingUploads,
      uploadedBatch,
      // Methods
      remove,
      onUploadStart: _onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileError,
      onFileDone,
      onPendingUploadsDone,
    }
  },
}
</script>

<style lang="scss" scoped>
.attach-empty {
  @apply border-dashed border-2 border-gray-400 bg-gray-100 bg-opacity-20;
  @apply p-24;
  @apply rounded-lg;
}

.attach-hovered {
  @apply border-dashed border-2 border-green-400 bg-green-200 bg-opacity-95;
  @apply p-24;
  @apply text-center rounded-lg;
}
.attach-images-wrap {
  @apply grid grid-cols-3 md:grid-cols-3 xl:grid-cols-4 gap-16 md:gap-24 auto-cols-fr;
  @media print {
    break-inside: avoid;
  }
}
.attach-item-wrap {
  @apply flex flex-col items-stretch w-full;
  @media print {
    width: 33.333%;
    break-inside: avoid;
  }
}
.attach-files-wrap {
  @apply flex flex-col mx-8 lg:mx-0 w-full;

  :global(.attach-item-wrap) {
    @apply mb-16;
  }
}
.attach-note {
  margin-bottom: $gutterx2;
  position: relative;
  padding-left: 32px;
  h4 {
    color: $error;
    font-weight: $medium;
    font-size: $fs18;
    display: inline-flex;
    align-items: center;
    margin-bottom: $gutter;
    :global(.icon) {
      position: absolute;
      left: 0;
      top: -1px;
    }
  }
}
</style>
