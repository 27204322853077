import {
  BaseModel,
  makeServicePlugin,
} from '@/v2/services/feathersClientService'
import usersService, { servicePath } from './usersService'

class User extends BaseModel {
  static modelName = 'User'

  static setupInstance(data, { models }) {
    const { Profile } = models.api
    const _data = { ...data }


    if (_data.profile$) {
      _data.profile$ = new Profile(_data.profile$)
    }

    return _data
  }

  static instanceDefaults() {
    return {
      hasPassword: false,
    }
  }
}


export default makeServicePlugin({
  Model: User,
  service: usersService,
  servicePath,
})
