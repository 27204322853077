import { pathOr } from 'ramda'
import memoizerific from 'memoizerific'
import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import documentStructuresService, { servicePath } from './documentStructuresService'

const contentBlockCategory = pathOr(null, ['contentBlock$', 'category']);

const computeOrderedNodeList = (branches, id) => branches.reduce((acc, branch) => {
  if (branch.node === id && branch.children.length) {
    return [...acc, ...branch.children.reduce(
      (accum, nodeId) => [...accum, nodeId, ...computeOrderedNodeList(branches, nodeId)],
      []
    )]
  }
  return acc
}, [])


const computeTree = branches => branches.reduce(
  (acc, branch) => ({
    ...acc,
    [branch.node]: branch.children,
  }),
  {}
)
const computeTreeMemoized = memoizerific(50)(computeTree)
// const computeOrderedNodeListMemoized = memoizerific(50)(computeOrderedNodeList)

class DocumentStructure extends BaseModel {
  static modelName = 'DocumentStructure'

  get tree() {
    return computeTreeMemoized(this.branches)
  }

  get meta() {
    const { DocumentNode } = DocumentStructure.models.api
    const nodes = this.nodes.map(nodeId => DocumentNode.getFromStore(nodeId))

    const indexByCategoryMap = new Map()

    return nodes.reduce((acc, node) => {
      const meta = {};

      // handle content block category
      const cbCategory = contentBlockCategory(node);
      if (cbCategory) {
        meta.indexByCategory = indexByCategoryMap.get(cbCategory) || 0
        indexByCategoryMap.set(cbCategory, meta.indexByCategory + 1)

        meta.category = cbCategory
      } else {
        meta.indexByCategory = null
      }

      // check if node is loaded so we can access node._id
      // document-structure can contain nodes that are not loaded yet
      return node ? { ...acc, [node._id]: meta } : { ...acc };
    }, {});
  }

  get nodes() {
    // return this.branches.map(propOr([], 'children')).flat()
    return computeOrderedNodeList(this.branches, 'root')
  }
}

export default makeServicePlugin({
  servicePath,
  Model: DocumentStructure,
  service: documentStructuresService,
})

