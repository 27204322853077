<template>
  <div v-fragment>
    <!-- billable list editor -->
    <SlickList
      v-model="billablesList"
      use-drag-handle
      lock-to-container-edges
      lock-axis="y"
      class="table-row-group px-16 portal-paragraph-font"
      helper-class="so-table-row-dragging portal-paragraph-font"
    >
      <SlickItem
        v-for="(billable, index) in value"
        :key="billable.id"
        :index="index"
        class="relative table-row transform scale-100 cost-editor-item group"
        :class="[
          !billable.isBillable && 'freebie',
          activeBillableIndex === index && 'active',
        ]"
        :style="{
          color: styling.textColor,
        }"
      >
        <DocumentContentBlockCostEditorItem
          :item="billable"
          :currency="currency"
          class="so-slick-table-row-inner"
          :styling="styling"
          @update="({ key, value }) => update({ index, key, value })"
          @remove="del(index)"
          @set-active-billable-item="val => setActiveBillable(index, val)"
        />
      </SlickItem>
    </SlickList>

    <!-- ------------------------------ -->
    <!-- ADD NEW ITEM ----------------- -->
    <!-- ------------------------------ -->
    <div class="flex w-full px-8 mt-16 mb-16 space-x-8 print:hidden">
      <b-button
        variant="primary"
        @click="createNewBillable"
      >
        <span class="w-24 mr-8 icon-add-rounded-s text-24"></span>
        Add
      </b-button>
      <CheckMemberRole v-slot="{ hasRole: isAdmin }" :roles="['admin']">
        <b-button
          v-if="isAdmin"
          variant="primary"
          @click="isAddModalOpen = true"
        >
          <span class="w-20 mr-8 icon-org-billable-services text-20"></span>
          Import from library
        </b-button>
      </CheckMemberRole>
      <DocumentContentBlockCostAddFromLibrary
        v-if="isAddModalOpen"
        v-model="isAddModalOpen"
        :currency="currency"
        :organization-id="organizationId"
        @save="billables => createFromLibrary(billables)"
      />
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { SlickList, SlickItem } from 'vue-slicksort'
import useFormList from '@/v2/lib/composition/useFormList'
import CheckMemberRole from '@/components/CheckMemberRole.vue'
import DocumentContentBlockCostEditorItem from './DocumentContentBlockCostEditorItem.vue'
import DocumentContentBlockCostAddFromLibrary from './DocumentContentBlockCostAddFromLibrary.vue'

const unitOptions = ['hour', 'day', 'week', 'month', 'year', 'item', 'flat fee']

export default defineComponent({
  name: 'DocumentContentBlockCostEditor',
  components: {
    DocumentContentBlockCostEditorItem,
    DocumentContentBlockCostAddFromLibrary,
    SlickList,
    SlickItem,
    CheckMemberRole,
  },
  props: {
    autofocus: Boolean,
    value: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { create, update, del, reorder } = useFormList()
    const isAddModalOpen = ref(false)

    // Creates an empty billable item
    const createNewBillable = () => {
      create({
        id: nanoid(),
        name: '',
        unit: unitOptions[0],
        rate: 0,
        quantity: 0,
        isBillable: true,
      })
    }

    // Creates one or more billable items based on
    // the user's selection in the modal
    const createFromLibrary = selection => {
      const newValues = [...props.value]
      selection.forEach(item => {
        newValues.push({
          id: nanoid(),
          name: item.name,
          unit: item.unit,
          rate: item.rate,
          quantity: 0,
          isBillable: item.isBillable,
          fromLibrary: item.fromLibrary, // to mark items imported from the library
        })
      })
      context.emit('input', newValues)
      isAddModalOpen.value = false
    }

    const billablesList = computed({
      set: val => reorder(val),
      get: () => props.value,
    })

    // Useful when we use the actions menu
    const activeBillableIndex = ref(null)
    const setActiveBillable = (index, val) => {
      activeBillableIndex.value = val ? index : null
    }

    return {
      createNewBillable,
      update,
      del,
      billablesList,
      isAddModalOpen,
      createFromLibrary,
      activeBillableIndex,
      setActiveBillable,
    }
  },
})
</script>

<style lang="postcss">

.so-table-row-dragging {
  @apply z-0 transform;
  @apply ring-2 bg-white bg-opacity-70 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.so-table-row-dragging .cost-editor-item {
  @apply border-b-0;
}

.cost-editor-item {
  @apply hover:bg-green-50;
}

</style>
