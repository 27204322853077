
<DocumentNodeEditorToolbarSettings
  :popover-target-id="popoverTargetId"
  :prevent-popover-close="preventPopoverClose"
>
  <b-form>
    <SettingsSlider
      v-model="height"
      :min="8"
      :max="512"
      :step="16"
      label="Height"
      tooltip="Set height in pixels"
    />
  </b-form>
</DocumentNodeEditorToolbarSettings>
