import { clone } from 'ramda'

const updateLocal = (modelInstance, data) => {
  const modelClone = modelInstance.clone()
  Object.assign(modelClone, clone(data))
  modelClone.commit()
  return modelClone
}

const updateServer = (modelClone, data) => modelClone.save({ data })

export default function useOptimisticUpdate(options = {}) {
  const { save = true } = options;

  const update = (modelInstance, data) => {
    const modelClone = updateLocal(modelInstance, data)
    if (save) {
      return updateServer(modelClone, data)
    }

    return null;
  }

  const updateMany = updates => {
    const modelClones = updates.map(
      ([modelInstance, data]) => [updateLocal(modelInstance, data), data]
    )

    return Promise.all(
      modelClones.map(([modelClone, data]) => updateServer(modelClone, data))
    )
  }

  return {
    update,
    updateMany,
  }
}
