
<div class="inline-flex items-center h-32 space-x-8 text-14">
  <div
    v-if="isMobile"
    class="flex items-center text-gray-500 md:ml-24 dark:text-darkGray-400"
  >
    <span class="w-20 mr-6 icon-arrow-left-util text-20"></span>
    <DocumentLinkTitle
      :document="document"
      class="
        text-gray-500 dark:text-darkGray-400
        hover:underline hover:text-[color:var(--cp-accent)]
      "
    />
  </div>
  <div v-else class="flex items-center">
    <router-link
      v-if="!isPortal"
      :to="{ name: ORGANIZATION_LIBRARY }"
      class="
        text-gray-500 dark:text-darkGray-400
        hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1
      "
    >
      Library
    </router-link>
    <div class="ml-22 so-breadcrumb-item flex items-center">
      <span
        class="
          so-breadcrumb-arrow w-12 mr-4 -ml-16
        text-gray-400 dark:text-darkGray-600
          text-12 icon-arrow-right
        "
      />
      <router-link
        v-if="!isPortal"
        :to="{ name: info.routeName }"
        class="
        text-gray-500 dark:text-darkGray-400
          hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1
        "
      >
        {{ info.label }}
      </router-link>
      <div v-else class="text-gray-500 dark:text-darkGray-400 line-clamp-1">
        {{ info.label }}
      </div>
    </div>
    <div class="ml-22 so-breadcrumb-item flex items-center">
      <span
        class="
          so-breadcrumb-arrow w-12 mr-4 -ml-16
        text-gray-400 dark:text-darkGray-600
          text-12 icon-arrow-right
        "
      />
      <DocumentComputedData
        v-slot="{ title }"
        :document="document"
      >
        <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
          <WrappedTitle
            :text="title"
            :pre-size="34"
          />
        </p>
      </DocumentComputedData>
    </div>
  </div>
</div>
