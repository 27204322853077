<template>
  <div class="bg-white dark:bg-darkGray-1000 -mx-16 px-16 -mb-16 pb-16 pt-16">
    <b-form @submit.prevent="onSubmit">
      <!-- GUEST DETAULS -->
      <template v-if="step === STEPS.GUEST_DETAILS">
        <div
          class="
            text-14 text-gray-700 dark:text-darkGray-400
            p-12 leading-tight border-b border-gray-200 dark:border-darkGray-700
          "
        >
          {{ $t('comments.message-need-email') }}
        </div>
        <b-form-group class="border-b border-gray-200 dark:border-darkGray-700 m-0">
          <FormField
            :v="$v.name"
            :messages="{
              required: $t('forms.name-required'),
            }"
            required
            error-class="ml-12 text-12"
          >
            <b-input
              v-model="commentModel.name"
              autofocus
              :placeholder="$t('forms.name-placeholder')"
              class="form-control-lg border-none px-12 rounded-none"
            />
          </FormField>
        </b-form-group>

        <b-form-group class="m-0">
          <FormField
            :v="$v.email"
            :messages="{
              email: $t('forms.email-invalid'),
              required: $t('forms.email-required'),
            }"
            required
            error-class="ml-12 text-12"
          >
            <b-input
              v-model.trim="commentModalEmail"
              :placeholder="$t('forms.email-placeholder')"
              class="form-control-lg border-none px-12 rounded-none"
            />
          </FormField>
        </b-form-group>
      </template>
      <!-- <div v-if="user" class="px-12 pt-12 pb-6 dark:text-darkGray-300">
        <AvatarUser
          :user-id="user._id"
          :size="32"
          font-size="14"
          :class="'font-semibold'"
        />
      </div> -->
      <div
        v-else-if="showGuestAvatar"
        class="flex items-center px-12 pt-12 pb-10"
      >
        <AvatarUser
          :fallback-name="commentModel ? commentModel.name : guestDetails.name"
          :show-name="false"
          :size="32"
          font-size="14"
        />
        <span class="flex justify-center flex-col flex-1 ml-6 whitespace-nowrap">
          <span class="text-14 font-semibold">
            {{ commentModel ? commentModel.name : guestDetails.name }}
          </span>
          <span class="text-14 text-gray-700 leading-tight">
            {{ commentModel ? commentModel.email : guestDetails.email }}
          </span>
        </span>
        <b-button
          v-if="step === STEPS.MESSAGE_FORM"
          variant="transparent"
          class="text-gray-500 px-0"
          @click="step = STEPS.GUEST_DETAILS"
        >
          <span class="icon_v2-so_pencil mr-8"></span>
        </b-button>
      </div>
      <template v-if="step === STEPS.MESSAGE_FORM">
        <b-form-group class="m-0">
          <div class="mb-16">
            <FormField
              :v="$v.message"
              :messages="{
                required: $t('forms.message-required'),
              }"
              error-class="text-12"
            >
              <b-form-textarea
                v-model="commentModel.message"
                autofocus
                :placeholder="$t('forms.message-placeholder')"
                rows="3"
                max-rows="6"
                class="bg-transparent"
                :class="$style['textarea']"
                @keyup.ctrl.enter="onSubmit"
                @keydown.meta.enter="onSubmit"
              />
            </FormField>
          </div>
        </b-form-group>
      </template>
      <!-- bottom buttons -->
      <div
        class="flex items-center flex-row-reverse justify-between"
      >
        <CommentsFormButton
          v-if="step === STEPS.NEW_MESSAGE_BTN"
          type="button"
          icon="icon_v2-so_chat-plus"
          :label="$t('comments.add-comment')"
          :is-new-guest="isNewGuest"
          @click.prevent="onNewMessage"
        />

        <CommentsFormButton
          v-else-if="step === STEPS.GUEST_DETAILS"
          type="submit"
          :label="$t('common.continue')"
          :disabled="$v.$invalid"
        />

        <CommentsFormButton
          v-if="step === STEPS.MESSAGE_FORM"
          type="submit"
          icon="icon_v2-so_chat-plus"
          :label="comment ? $t('common.save-changes') : $t('comments.post-comment')"
          :disabled="$v.$invalid"
        />
      </div>
    </b-form>
  </div>
</template>

<script>
import { pick } from 'ramda'
import { rejectNil, isNotEmpty } from 'ramda-extension'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useLocalStorageReactive } from '@/v2/lib/composition/useLocalStorage'
import debounce from '@/v2/lib/helpers/debounce'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import FormField from '@/components/FormField.vue'
import AvatarUser from '@/components/AvatarUser.vue'
import CommentsFormButton from '@/components/CommentsFormButton.vue'

const STEPS = {
  NEW_MESSAGE_BTN: 'NEW_MESSAGE_BTN',
  GUEST_DETAILS: 'GUEST_DETAILS',
  MESSAGE_FORM: 'MESSAGE_FORM',
}

const VALIDATION_RULES = {
  [STEPS.GUEST_DETAILS]: {
    name: { required, $autoDirty: true },
    email: { required, email },
  },
  [STEPS.MESSAGE_FORM]: {
    message: { required, $autoDirty: true },
  },
}

const pickUpdateProps = pick(['message'])

export default defineComponent({
  name: 'CommentsForm',
  components: {
    AvatarUser,
    CommentsFormButton,
    FormField,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    newCommentData: {
      type: Object,
      required: true,
    },
    comment: {
      type: Object,
      default: null,
    },
    commentsToggled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const { track } = useTracking()
    const { Comment } = context.root.$FeathersVuex.api
    const guestDetails = useLocalStorageReactive('guestDetails', { name: '', email: '' });

    const step = ref(STEPS.NEW_MESSAGE_BTN)
    const commentModel = ref(null);

    const showGuestAvatar = computed(() => {
      if (!props.user
      && (isNotEmpty(guestDetails.name) || commentModel.value)
      && step.value !== STEPS.GUEST_DETAILS) {
        return true
      }
      return false
    })

    const isUpdate = computed(() => Boolean(props.comment))

    const isNewGuest = computed(() => Boolean(!props.user && !isNotEmpty(guestDetails.name)))

    // form validations
    const validationRules = computed(() => VALIDATION_RULES[step.value])
    const $v = useVuelidate(validationRules, commentModel)

    // create / edit comment

    const setCommentModel = () => {
      commentModel.value = props.comment
        ? props.comment.clone()
        : new Comment({
          name: guestDetails.name,
          email: guestDetails.email,
          message: '',
          ...props.newCommentData,
        }).clone()
    }

    const commentModalEmail = computed({
      get: () => commentModel.value.email,
      set: debounce(value => {
        commentModel.value.email = emailSanitizer(value)
      }),
    })

    const onSubmit = async () => {
      $v.value.$touch()

      if ($v.value.$invalid) {
        return
      }

      if (step.value === STEPS.GUEST_DETAILS) {
        step.value = STEPS.MESSAGE_FORM
        return
      }

      commentModel.value.commit()

      if (isUpdate.value) {
        await commentModel.value.save(rejectNil({
          data: pickUpdateProps(commentModel.value),
        }))
        context.emit('updated');
      } else {
        await commentModel.value.save()
        context.emit('created');
      }

      $v.value.$reset()
      step.value = STEPS.NEW_MESSAGE_BTN

      // save guest details in localStorage
      if (!props.user) {
        Object.assign(guestDetails, {
          name: commentModel.value.name,
          email: commentModel.value.email,
        })
      }

      track('New Comment Created')
    }

    const onNewMessage = () => {
      track('New Comment Form Opened')

      setCommentModel()

      if (props.user || isNotEmpty(guestDetails.name)) {
        step.value = STEPS.MESSAGE_FORM
      } else {
        step.value = STEPS.GUEST_DETAILS
      }
    }

    watch(() => props.comment, newVal => {
      setCommentModel()

      // check existing comment (edit)
      if (newVal) {
        step.value = STEPS.MESSAGE_FORM
      }
    });

    // reset active step and validations when closing comments sidebar

    watch(() => props.commentsToggled, newVal => {
      if (newVal === true) {
        step.value = STEPS.NEW_MESSAGE_BTN
        $v.value.$reset()
      }
    })


    // keep the comment state if the user started
    // writing a message and then switches between nodes/all comments
    watch(() => props.newCommentData.documentNode, newVal => {
      if (!commentModel.value) return
      if (newVal && step.value === STEPS.MESSAGE_FORM) {
        $v.value.$reset()
        commentModel.value.documentNode = newVal
      } else if (!newVal) {
        commentModel.value.documentNode = undefined
      }
    })

    return {
      $v,
      commentModel,
      commentModalEmail,
      step,
      onSubmit,
      onNewMessage,
      STEPS,
      guestDetails,
      isNotEmpty,
      showGuestAvatar,
      isNewGuest,
    }
  },
})


</script>

<style lang="scss" module>
  .textarea {
    overflow-y: auto !important;
  }
</style>
