
<draggable
  :list="tasks"
  tag="div"
  class="grid"
  :animation="200"
  :group="lockTasksToSection ? `tasks-${sectionId}` : 'tasks'"
  :disabled="noTaskReordering"
  ghost-class="ghost"
  @start="drag = true"
  @end="drag = false"
  @change="event => $emit('drag-event', event)"
>
  <KanbanCard
    v-for="task in tasks"
    :key="task._id"
    :task="task"
    :selected="task._id === selectedTaskId"
    :drag="drag"
    :readonly-task="readonlyTask"
    @remove="$emit('remove', task)"
  >
    <slot name="task" v-bind="{ task }" />
  </KanbanCard>
</draggable>
