
<span
  :class="[
    'flex items-center w-full py-6 px-8 rounded-md cursor-pointer min-h-32 transition-all',
    'bg-opacity-50 hover:bg-opacity-50',
    'hover:bg-gray-200 hover:text-gray-1000',
    'dark:hover:bg-darkGray-800',
    active ?
      'bg-gray-200 text-gray-1000'
      : 'text-gray-700 dark:text-darkGray-300',
  ]"
  @click="$emit('select')"
>
  <span
    v-if="icon"
    :class="[`icon_v2-so_${icon}`]"
    class="inline-block mr-6 text-16"
  />
  <DocumentIcon
    v-else-if="documentCategory"
    :category="documentCategory"
    size="16"
    class="mr-8"
  />
  <span :class="hasBadge && 'mr-8'">{{ label }}</span>
  <span
    v-if="hasSubmenu"
    class="ml-auto"
  >
    <span class="icon_v2-so_direction-right text-18"></span>
  </span>
  <Badge
    v-if="hasBadge"
    variant="primary"
    class="ml-auto"
  >
    {{ badge }}
  </Badge>
</span>
