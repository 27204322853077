
<transition name="modal">
  <div
    v-if="state.mediaIndex !== null"
    class="vgs"
    @click="close"
  >
    <div class="vgs__header">
      <!-- Position / Count -->
      <div
        v-if="files"
        class="vgs__gallery__title opacity-75"
      >
        <template v-if="files.length > 1">
          {{ state.mediaIndex + 1 }} <span class="opacity-50">/ {{ files.length }}</span>
        </template>
      </div>
      <!-- Close -->
      <button
        type="button"
        class="vgs__close"
        @click="close"
      >
        <span class="icon_v2-so_close w-24 text-24" />
      </button>
    </div>
    <!-- Previous Button -->
    <button
      v-if="isMultiple"
      type="button"
      class="vgs__prev"
      @click.stop="onPrev"
    >
      <span class="icon-arrow-left w-24 text-24" />
    </button>
    <!-- --------------- -->
    <!-- Image Displayed -->
    <!-- --------------- -->
    <div
      v-if="files"
      class="vgs__container"
    >
      <transition
        name="fade"
        mode="out-in"
        appear
      >
        <img
          v-if="mediaType === 'image'"
          :key="mediaUrl"
          class="vgs__container__img"
          :src="mediaUrl"
          :alt="alt"
          @click.stop="onNext"
        />
        <!-- <div
          v-if="mediaType==='video'"
          :key="mediaUrl"
          class="relative w-[90vw] h-auto aspect-w-16 aspect-h-9
          flex-1 flex-grow p-0 m-0 bg-black"
          @click.stop=""
        >
          <b-embed
            type="video"
            controls
            allowfullscreen
          >
            <source :src="mediaUrl" />
          </b-embed>
        </div> -->

        <AudioPlayer
          v-if="mediaType==='audio'"
          :key="mediaUrl"
          :file="files[state.mediaIndex]"
        />
        <VideoPlayer
          v-if="mediaType==='video'"
          :key="mediaUrl"
          :file="files[state.mediaIndex]"
        />
        <div
          v-if="mediaType==='pdf'"
          :key="mediaUrl"
          class="relative w-[80vw] flex-1 flex-grow p-0 m-0 flex flex-col items-start justify-start
          overflow-hidden"
          @click.stop=""
        >
          <PDFViewer
            class="w-[80vw] h-auto"
            :source="mediaUrl"
          />
        </div>
        <slot />
      </transition>
    </div>
    <!-- --------------- -->
    <!-- Image Displayed -->
    <!-- --------------- -->
    <!-- Next Button -->
    <button
      v-if="isMultiple"
      type="button"
      class="vgs__next"
      @click.stop="onNext"
    >
      <span class="icon-arrow-right w-24 text-24" />
    </button>
    <!-- Footer Gallery -->
    <div
      v-if="isMultiple"
      ref="gallery"
      class="vgs__gallery"
      @click.stop
    >
      <!-- Position / Count -->
      <div class="vgs__gallery__mask" />
      <!-- Thumbnails Gallery -->
      <div
        v-if="filesWithTypes"
        class="vgs__gallery__container"
        :style="{
          transform: 'translate(' + state.galleryXPos + 'px, 0)',
        }"
      >
        <div
          v-for="(file, i) in filesWithTypes"
          :key="i"
          class="vgs__gallery__container__img relative"
          :class="{ 'vgs__gallery__container__img--active': i === state.mediaIndex }"
        >
          <img
            v-if="file.mediaType === 'image'"
            :src="typeof file === 'string' ? file : file.url"
            :alt="typeof file === 'string' ? '' : file.alt"
            @click.stop="onClickThumb(file, i)"
          />
          <div
            v-if="file.mediaType==='video'"
            class="media-thumbnail"
            @click.stop="onClickThumb(file, i)"
          >
            <span class="standard-camcorder text-32 w-32 opacity-70 text-white"></span>
            <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">Video File</span> -->
          </div>
          <div
            v-if="file.mediaType==='audio'"
            class="media-thumbnail"
            @click.stop="onClickThumb(file, i)"
          >
            <span class="standard-headset text-32 w-32 opacity-70 text-white"></span>
            <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">Audio File</span> -->
          </div>
          <div
            v-if="file.mediaType==='pdf'"
            class="media-thumbnail"
            @click.stop="onClickThumb(file, i)"
          >
            <span class="icon-document-contract text-32 w-32 opacity-70 text-white"></span>
            <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">PDF Document</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</transition>
