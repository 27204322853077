import { ref, computed } from '@vue/composition-api';
import templatesService from '../templatesService';

/** @typedef {import('../templates').TemplateData} TemplateData */

/**
 * @param {TemplateData['type']} type
 */
export default function useTemplates(type = 'project') {
  const items = ref([])
  const isLoading = ref(false)
  const error = ref(null)

  const hasCollections = computed(() => items.value.length > 1)

  const collections = computed(() => {
    const sorted = [...items.value].sort((a, b) => a.collection.localeCompare(b.collection))

    const result = sorted.map(item => ({
      id: item._id,
      label: item.collection,
      slug: item.slug,
      count: item.templates.length,
      templates: item.templates,
    }))

    const allTemplates = items.value.map(item => item.templates).flat()

    // add virtual 'All' collection
    result.unshift({
      id: 'all',
      label: 'All Projects',
      slug: 'all',
      count: allTemplates.length,
      templates: allTemplates,
    })

    // exclude 'Default'
    return result.filter(collection => collection.label !== 'Default')
  });

  const load = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      const response = await templatesService.find({
        query: { type },
        // paginate: false,
      })
      items.value = response.data
    } catch (err) {
      items.value = [];
      error.value = err.message;
      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    load,
    isLoading,
    error,
    collections,
    hasCollections,
  }
}
