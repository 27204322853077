
<b-dropdown
  menu-class="pl-8 pr-8 pb-8 pt-8 min-w-0"
  toggle-class="p-0 editor-toolbar-button hover:bg-gray-500 hover:bg-opacity-10"
  variant="transparent"
  no-caret
>
  <template #button-content>
    <div
      title="Highlight"
      :class="[
        'h-32 w-32 rounded relative text-white text-sm flex justify-center items-center',
        isActive && activeColor && $style[`${activeColor}`],
        isActive && !activeColor && 'bg-gray-500 hover:bg-gray-600 bg-opacity-50 hover:bg-opacity-100',
      ]"
    >
      <span class="w-24 text-24 icon-toolbar-highlight"></span>
      <span :class="[$style['toolbar-dropdown']]"></span>
    </div>
  </template>

  <div class="flex flex-row items-center space-x-8 min-w-0">
    <button
      v-for="color in colors"
      :key="color"
      :class="[
        'h-32 w-32 inline-flex items-center justify-center relative rounded-sm',
        'text-white font-semibold text-sm',
        $style[`${color}`],
      ]"
      @click="selectColor(color)"
    >
      <span
        v-if="Boolean(activeColor === color)"
        :class="$style[`active-color`]"
      >
        <span class="icon_v2-so_tick w-16 text-16"></span>
      </span>
      <span v-else class="w-24 text-14 font-semibold">Aa</span>
    </button>
  </div>
</b-dropdown>
