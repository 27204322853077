
<NotificationRenderer
  v-if="!isSingleton"
  :notification="notification"
  icon-variant="icon"
  icon="icon-document"
  icon-class="bg-green-200 text-green-700"
  badge-icon="icon-document"
  badge-class="bg-blue-500"
  @mark-as-read="notification => $emit('mark-as-read', notification)"
>
  <template #default="{ options }">
    <div>
      Organization library has {{ notification.events.length }}
      new {{ $tc('common.shares', notification.events.length) }}
    </div>
  </template>
  <template #events>
    <NotificationList :notifications="notification.events" />
  </template>
</NotificationRenderer>
<NotifDocumentsCreated v-else :notification="singleton" />
