
<div class="flex items-center">
  <DocIcon v-if="!hideIcon" :document="document" />
  <ContentEditable
    :value="document.title"
    :title-topbar="document.title"
    :lazy="true"
    placeholder="Title..."
    tag="p"
    :no-html="true"
    :contenteditable="!disabled"
    no-newline
    class="font-bold text-gray-800 dark:text-darkGray-300 text-24 portal-heading-font break-all"
    trim
    @input="title => $emit('update', title)"
  />
</div>
