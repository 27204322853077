<template>
  <ProfileGetByUser
    v-if="userId"
    v-slot="{ profile }"
    :user-id="userId"
  >
    <Avatar
      v-if="profile"
      :size="size"
      :profile="profile"
      :show-name="showName"
      :show-avatar="showAvatar"
      :font-size="fontSize"
      :timestamp="timestamp"
      v-bind="$attrs"
    />
  </ProfileGetByUser>
  <Avatar
    v-else-if="fallbackName"
    :size="size"
    :name="fallbackName"
    :show-name="showName"
    :show-avatar="showAvatar"
    :font-size="fontSize"
    :timestamp="timestamp"
    v-bind="$attrs"
  />
  <Avatar
    v-else-if="!fallbackName"
    :size="size"
    :name="'N/A'"
    :show-name="showName"
    :show-avatar="showAvatar"
    :font-size="fontSize"
    :timestamp="timestamp"
    v-bind="$attrs"
  />
</template>

<script>
import ProfileGetByUser from '@/components/ProfileGetByUser.vue'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'

export default {
  name: 'AvatarUser',
  components: {
    ProfileGetByUser,
    Avatar,
  },
  props: {
    userId: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 24,
    },
    fallbackName: {
      type: String,
      default: null,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    fontSize: {
      type: String,
      default: '13',
    },
    timestamp: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
