<script>
import { defineComponent, inject, computed } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import { ICON as DOCUMENT_ICON } from '@/types/document'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers';
import { useProjectModule } from '@/v2/services/projects/compositions';

export default defineComponent({
  name: 'DocumentComputedData',
  components: {

  },
  props: {
    document: {
      type: Object,
      // required: true,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { Project } = context.root.$FeathersVuex.api
    const vm = getCurrentInstanceOrThrow()
    const documentProject = computed(() => Project.getFromStore(props.document.project))
    const isClientPortal = inject('isClientPortal', false)
    const documentsModule = useProjectModule(documentProject, 'documents')

    // TODO: COMPUTED CATEGORY
    const category = computed(() => props.document?.category ?? '')

    // ########################
    // COMPUTED DOCUMENT TITLE
    // Used for PublicFolder / Root Folder
    // ########################
    const title = computed(() => {
      // When on the client portal:
      // The Public folder is considered the "Docs & Files" folder
      // in the sidebar navigation +&breadcrumbs
      if (isClientPortal && category.value === DOCUMENT_CATEGORY.ProjectPublicFolder) {
        return documentsModule.value.title || vm.$t('modules.docs-and-files')
      }
      // When logged in as a user
      // The Root folder is considered the "Docs & Files" folder
      // in the sidebar navigation and breadcrumbs
      if (category.value === DOCUMENT_CATEGORY.ProjectRootFolder) {
        return documentsModule.value.title || vm.$t('modules.docs-and-files')
      }
      // In all other cases, return the actual name of the doc
      return props.document?.title ?? ''
    })

    // ########################
    // COMPUTED DOCUMENT ICON
    // Used for PublicFolder / Root Folder
    // ########################
    const icon = computed(() => {
      // If old document categories
      if (DOCUMENT_ICON[category.value]) {
        return DOCUMENT_ICON[category.value]
      }

      // When on the client portal:
      // The Public folder is considered the "Docs & Files" folder
      // in the sidebar navigation +&breadcrumbs
      if (isClientPortal && category.value === DOCUMENT_CATEGORY.ProjectPublicFolder) {
        return documentsModule.value.icon || 'icon_v2-so_shared'
      }

      // When logged in as a user
      // The Root folder is considered the "Docs & Files" folder
      // in the sidebar navigation and breadcrumbs
      if (category.value === DOCUMENT_CATEGORY.ProjectRootFolder) {
        return documentsModule.value.icon || 'icon_v2-so_folder'
      }
      if (!isClientPortal && category.value === DOCUMENT_CATEGORY.ProjectPublicFolder) {
        return 'icon_v2-so_shared'
      }

      if (category.value === DOCUMENT_CATEGORY.Folder) {
        return props.isOpen ? 'icon_v2-so_folder-open' : 'icon_v2-so_folder'
      }

      // In all other cases, return the folder icon
      return props.document?.icon
    })

    return () => context.slots.default({
      title: title.value,
      icon: icon.value,
    })
  },
})
</script>
