import { mergeRight, complement } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { computed } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'
import { useMsgBoxConfirmDelete, useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
import { useRouter, useRouteParams } from '@/v2/lib/composition/useRouter'
// import useNotification from '@/v2/lib/composition/useNotification'
import {
  CATEGORY,
  DEFAULT_DOCUMENT_TITLE,
  ORGANIZATION_LIBRARY_CATEGORIES,
} from './documentsTypes'
import { useDocumentOpen } from './compositions/useDocumentLink'
import { useCurrentOrganization } from '../organizations/compositions'

/**
 * @typedef {import('vue-router').Location} RouterLocation
 */

export function useDocuments() {
  const vm = getCurrentInstanceOrThrow()
  const { Document } = vm.$FeathersVuex.api
  const confirmDelete = useMsgBoxConfirmDelete()
  const { projectId } = useRouteParams(['projectId'])
  const { routerPush } = useRouter()
  const { track } = useTracking()
  const currentOrganization = useCurrentOrganization()
  // const notification = useNotification()
  const msgBoxConfirm = useMsgBoxConfirm()
  const open = useDocumentOpen()

  const create = async (data, openDocument = true) => {
    const props = { ...data };

    if (!props.title && props.category) {
      props.title = DEFAULT_DOCUMENT_TITLE[props.category]
    }

    const document = new Document(
      rejectNil(
        mergeRight(
          {
            organization: currentOrganization.value?._id,
            project: projectId.value,
          },
          props
        )
      )
    )

    await document.save()

    track('Document Created', {
      level: data.project ? 'project' : 'organization',
      parentFolder: data.parentFolder,
      category: data.category,
      template: data.cloneOf ? data.title : null,
    })

    return openDocument ? open(document) : document
  }

  /**
   * Duplicate a organization level document
   * For project level document, use useCustomFileSystem
   * @param {Object} document
   * @param {Object} data
   * @param {boolean} openDocument
   * @returns {Promise<Object>}
   */
  const duplicate = async (document, data = {}, openDocument = false) => {
    if (document.isProjectLevel) {
      throw new Error('filesystem document, use useCustomFileSystem.duplicateDocument');
    }

    const props = {
      title: data.title ?? `Copy of ${document.title}`,
      category: document.category,
      cloneOf: document._id,
      ...data,
    }

    const result = await create(props, openDocument)

    // notification({
    //   title: 'Duplicated',
    //   message: 'Document duplicated succesfully',
    // })

    return result
  }

  const saveAsReusableBlock = async (document, data = {}, openDocument = true) => {
    const ok = await msgBoxConfirm({
      title: 'Save as reusable block',
      message:
        'Blocks with references to other documents (like Briefs or Estimates) will be omitted.',
      size: 'md',
    })

    if (!ok) {
      return null
    }

    const props = {
      title: document.title,
      category: CATEGORY.DocumentReusableBlock,
      project: null, // force null project
      cloneOf: document._id,
      ...data,
    }
    const result = await create(props, openDocument)

    // notification({
    //   title: 'Saved',
    //   message: 'Document saved as reusable block',
    // })

    return result
  }

  const remove = async (document, redirect = false, route = null) => {
    const ok = await confirmDelete({
      title: `Delete ${document.title}`,
      message: 'Are you sure you want to delete this document? This action is irreversible.',
    })

    if (!ok) {
      return
    }

    await document.remove()

    if (redirect) {
      const parentFolder = await Document.grabOrFetch(document.parentFolder)
      open(parentFolder)
      return
    }

    if (route) {
      routerPush(route)
    }
  }

  return {
    open,
    create,
    remove,
    duplicate,
    saveAsReusableBlock,
  }
}

export const isOrganizationLevel = category => ORGANIZATION_LIBRARY_CATEGORIES.includes(category)

// TODO: The below should be changed to `isClientLevel`
export const isProjectLevel = complement(isOrganizationLevel)

export const useDocumentsFindQuery = categoryRef => {
  const { organizationId, projectId } = useRouteParams([
    'organizationId',
    'projectId',
  ])

  return computed(() => {
    if (isOrganizationLevel(categoryRef.value)) {
      return { organization: organizationId.value }
    }
    // TODO: Check for `isClientLevel` and include a `client` prop in the query.
    // This can only be done after merging `feature/clients`
    if (isProjectLevel(categoryRef.value)) {
      return { organization: organizationId.value, project: projectId.value }
    }

    return {}
  })
}
