<template>
  <span
    :class="iconClass"
    class="inline-block"
    :size="size"
  ></span>
</template>

<script>
import { flipIncludes } from 'ramda-extension'
import { defineComponent } from '@vue/composition-api'
import { ICON as DOCUMENT_ICON } from '@/types/document'

export default defineComponent({
  name: 'DocumentIcon',
  props: {
    category: {
      type: String,
      default: '',
      required: true,
    },
    variant: {
      type: String,
      default: 'auto',
      validator: flipIncludes(['auto', 'dark', 'light']),
    },
    size: {
      type: String,
      default: '24',
    },
  },
  computed: {
    iconClass() {
      return [
        DOCUMENT_ICON[this.category],
        `w-${this.size} text-${this.size}`,
        this.variant !== 'auto' && this.$style[this.variant],
      ]
    },
  },
})
</script>
<style lang="scss" module>
.icon {
  background-color: transparent;
  margin-right: 0;
}
.dark {
  color: $gray-900;
  :global(.icon) {
    color: $gray-900;
  }
}
.light {
  color: $white;
  :global(.icon) {
    color: $white;
  }
}
</style>
