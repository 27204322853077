import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import downloadsService, { servicePath } from './downloadsService'

class Download extends BaseModel {
  static modelName = 'Download'

  static instanceDefaults() {
    return {
      type: '',
      url: '',
      status: '',
    }
  }
}

export default makeServicePlugin({
  Model: Download,
  service: downloadsService,
  servicePath,
})
