import rejectNil from 'ramda-extension/lib/rejectNil'
import { computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'

const {
  useGetters: useGettersAuth,
  useActions: useActionsAuth,
  useState: useStateAuth,
} = createNamespacedHelpers('auth')

export const useUser = () => {
  const { user, member, isAuthenticated } = useGettersAuth(['user', 'member', 'isAuthenticated'])
  const { authenticate, logout } = useActionsAuth(['authenticate', 'logout'])
  const { accessToken, payload } = useStateAuth(['accessToken', 'payload'])

  const authToken = computed(() => payload.value?.authToken ?? null)

  const authenticateLocal = ({ email, password }) => authenticate({
    email,
    password,
    strategy: 'local',
  })

  const authenticateToken = token => authenticate(
    rejectNil({
      token,
      strategy: 'token',
    })
  );

  const authenticateMember = memberId => authenticate({
    accessToken: accessToken.value,
    member: memberId,
    strategy: 'member',
  });

  // const isAuthenticated = computed(() => Boolean(user.value))

  return {
    user,
    member,
    authToken,
    authenticate,
    authenticateLocal,
    authenticateToken,
    authenticateMember,
    logout,
    isAuthenticated,
  }
}

export function useDeleteAccount() {
  const { user, logout } = useUser()
  const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
  const deleteAccount = async () => {
    const ok = await msgBoxConfirmDelete({
      size: 'lg',
      title: 'Permanently delete your account',
      message: 'This action is irreversible. Once you close your account, '
        + 'you will lose access to all your Superokay content and your data will be deleted.',
      okVariant: 'danger',
      okLabel: 'Delete my account',
      cancelVariant: 'neutral',
      cancelLabel: 'Keep',
    })

    //  patch user with deleteAccount: true
    if (ok) {
      await user.value.patch({
        data: {
          deleteAccount: true,
        },
      })
      // Log user out
      logout()
    }
  }

  return {
    deleteAccount,
  }
}
