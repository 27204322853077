<template>
  <div v-fragment>
    <ImageSearchUnsplashModal
      v-if="isUnsplashOpen"
      v-model="isUnsplashOpen"
      @select="onUnsplashImageSelect"
    />
    <FileUploader
      v-slot="{ selectFiles }"
      multiple
      block
      accept="image/*"
      @upload-start="onUploadStart"
      @upload-done="onUploadDone"
      @file-progress="onFileProgress"
      @file-done="onFileDone"
      @file-error="onFileError"
    >
      <DocumentContentBlockQASettings
        :description="description"
        :has-title="hasTitle"
        :prevent-popover-close="preventPopoverClose"
        :popover-target-id="popoverTargetId"
        @input="handleSettingsToggle"
      >
        <SettingsSwitch
          v-model="hasNotes"
          tooltip="Enable client notes"
          label="Notes"
        />

        <div class="mb-8">
          <span class="text-gray-600 text-14">Choices</span>
          <div
            v-if="filesErrors.length"
            class="attach-note"
          >
            <h4>
              <span class="w-16 text-16 icon-alert mr-4"></span>
              <span> The files below failed to upload. Please try again. </span>
            </h4>
            <div
              v-for="fileError in filesErrors"
              :key="fileError.uuid"
            >
              <strong>{{ fileError.name }}</strong>
              {{ fileError.local.error | error }}
            </div>
          </div>

          <div class="space-y-6 overflow-y-auto max-h-240 mt-8">
            <div
              v-for="(fileImage, index) in filesImages"
              :key="fileImage.uuid"
              class="flex items-start group"
            >
              <div
                class="flex-1 p-3 transition-all border border-green-200 rounded-lg bg-green hover:bg-green-500 hover:border-green-500 hover:shadow"
              >
                <!-- Image -->
                <div
                  :style="{
                    backgroundImage: `url(${fileImage.url})`,
                  }"
                  class="bg-center bg-cover rounded-md h-44 relative"
                >
                  <div
                    v-if="
                      fileImage.local && fileImage.local.status === 'progress'
                    "
                    class="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
                  >
                    <PieProgressBar :ratio="fileImage.local.ratio" />
                  </div>
                </div>
              </div>
              <!-- Actions -->
              <div
                class="flex flex-col justify-start invisible mr-6 text-gray-600 opacity-0 group-hover:opacity-100 group-hover:visible"
              >
                <span
                  class="block h-24 p-4 cursor-pointer hover:text-gray-700"
                ><span
                  class="w-16 icon-util-replace text-16"
                  @click="() => replaceImage(selectFiles, index)"
                /></span>
                <span
                  class="block h-24 p-4 cursor-pointer hover:text-gray-700"
                  @click="() => remove(fileImage)"
                ><span class="w-16 icon_v2-so_trash text-16" /></span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex items-center px-8 py-8 my-4 transition-all border border-gray-200 rounded-lg cursor-pointer select-none group transform-gpu hover:ring-4 hover:ring-opacity-20 hover:ring-green-500 hover:border-green-500 hover:transform hover:-translate-y-2"
          @click="() => (isUploading ? noop() : onFilesSelect(selectFiles))"
        >
          <span
            class="h-24 mr-8 text-gray-500 transition-all icon_v2-so_image text-24 group-hover:text-green-600"
          />
          <div class="leading-4 text-green-700 text-14">
            <span class="block">Choose Images</span>
            <span class="opacity-50">Size limit: 125MB</span>
          </div>
        </div>
        <b-button
          v-b-tooltip.hover.bottom.v-info
          title="Use an image from Unsplash"
          variant="black"
          :disabled="isUploading"
          class="w-full my-4"
          @click="openUnsplash"
        >
          <span class="w-16 mr-4 icon-logo-unsplash text-16" />
          from Unsplash
        </b-button>
        <SettingsSwitch
          v-model="acceptsMultipleAnswers"
          label="Allow multiple answers"
          tooltip="Allow multiple answers"
        />
      </DocumentContentBlockQASettings>
    </FileUploader>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { noop } from 'ramda-extension'
import { v4 as uuidv4 } from 'uuid'
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import { useFilesUpload } from '@/v2/services/uploads/uploadsCompositions'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import ImageSearchUnsplashModal from '@/components/ImageSearchUnsplashModal.vue'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQAImageChoiceSettings',
  components: {
    DocumentContentBlockQASettings,
    FileUploader,
    SettingsSwitch,
    PieProgressBar,
    ImageSearchUnsplashModal,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const isUnsplashOpen = ref(false)
    // Below values are refs
    const {
      description,
      hasTitle,
      possibleAnswers,
      acceptsMultipleAnswers,
      hasNotes,
    } = useContentBlockSettings(props, [
      'description',
      'hasTitle',
      'possibleAnswers',
      'acceptsMultipleAnswers',
      'hasNotes',
    ])
    /** `true` if the popver should not be closed on outside click, `false otherwise`.
     * Helps prevent halting unfinished file uploads */
    const preventPopoverClose = ref(false)
    /** The index of the image to be replaced, if such a request is performed */
    let replaceAtIndex = null

    const updatePossibleAnswers = newValues => {
      possibleAnswers.value = newValues
    }
    const add = files => {
      const newPossibleAnswers = [...props.value.possibleAnswers] // Init with current value
      if (replaceAtIndex !== null) {
        // Replace operation in progress. Remove image at target index and replace it with the new
        // selection
        newPossibleAnswers.splice(replaceAtIndex, 1, ...files)
        // Reset index
        replaceAtIndex = null
      } else {
        // Regular add files op. Push files at the end of the list
        newPossibleAnswers.push(...files)
      }
      updatePossibleAnswers(newPossibleAnswers)
    }
    const remove = file => updatePossibleAnswers(
      props.value.possibleAnswers.filter(({ uuid }) => file.uuid !== uuid)
    )
    const onFilesSelect = selectFilesCb => {
      preventPopoverClose.value = true
      context.root.$nextTick(async () => {
        await selectFilesCb()
        preventPopoverClose.value = false
      })
    }
    const replaceImage = (selectFilesCb, targetIndex) => {
      // Raise flag to let component know a replacement is in progress
      // isReplacingImage.value = true
      // Save the index of the image which is to be replaced
      replaceAtIndex = targetIndex
      // Wait to ensure flag is raised, then open file selector and begin upload process
      // context.root.$nextTick(onSelectFileCb)
      onFilesSelect(selectFilesCb)
    }
    /** Prevents the QA Settings popover from closing while upload in progress */
    const {
      isUploading,
      hasFiles,
      files,
      filesImages,
      // filesNonMedia,
      filesErrors,
      onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
    } = useFilesUpload(
      computed(() => props.value.possibleAnswers),
      add
    )

    const _onUploadStart = (...args) => {
      // Need to let `DocumentNodeEditorModel.vue` component know it should create a Feathers Vuex
      // clone to properly update the `contentBlock` by emitting the async-save-start event
      // update: async-save start gets emitted in DocumentContentBlockQA.vue
      context.emit('upload-start')
      onUploadStart(...args)
    }
    const _onUploadDone = (...args) => {
      context.emit('upload-done')
      onUploadDone(...args)
    }
    const handleSettingsToggle = object => {
      if (object.type === 'hasTitle') {
        hasTitle.value = object.value
      } else if (object.type === 'description') {
        description.value = object.value
      }
    }
    // unsplash
    const onUnsplashImageSelect = ({ urls }) => {
      const imageDetails = {
        url: urls.raw,
        uuid: uuidv4(),
        contentType: 'image/jpg',
      }
      add([imageDetails])
      isUnsplashOpen.value = false
      preventPopoverClose.value = false
    }
    const openUnsplash = () => {
      isUnsplashOpen.value = true
      preventPopoverClose.value = true
    }


    return {
      isUploading,
      hasFiles,
      files,
      filesImages,
      filesErrors,
      description,
      hasTitle,
      acceptsMultipleAnswers,
      preventPopoverClose,
      hasNotes,
      handleSettingsToggle,
      isUnsplashOpen,

      // Methods
      remove,
      onUploadStart: _onUploadStart,
      onUploadDone: _onUploadDone,
      onFileProgress,
      onFileError,
      onFileDone,
      noop,
      onFilesSelect,
      replaceImage,
      openUnsplash,
      onUnsplashImageSelect,
    }
  },
}
</script>
<style></style>
