<template>
  <DocumentLink v-slot="{ navigate, href }" :document="document">
    <slot v-bind="{ navigate, href }">
      <a
        class="font-semibold"
        :href="href"
        @click="navigate"
      >
        {{ document.title }}
      </a>
    </slot>
  </DocumentLink>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'

export default defineComponent({
  name: 'NotificationLinkDocument',
  components: {
    DocumentLink,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
})
</script>
