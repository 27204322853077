
<div>
  <NotificationList
    :notifications="items"
    :show-document="false"
    slim
  />
  <Button
    v-if="hasMore"
    size="sm"
    :is-loading="isPending"
    variant="neutral"
    class="w-full mt-16"
    @click="loadMore"
  >
    Load more
  </Button>
</div>
