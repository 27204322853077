
<ModalBasic
  size="xl"
  :no-close="isLoading"
  :hide-footer="true"
  content-class="modal-content-template-preview"
  body-class="modal-body-template-preview"
  footer-class="modal-footer-template-preview"
  v-bind="$attrs"
  close-text="Close Preview"
  v-on="$listeners"
>
  <template #body="{ close }">
    <!-- <div v-if="isProjectSelectVisible" > -->
    <div
      v-if="isProjectSelectVisible"
      class="absolute inset-0 z-10 flex
      items-center justify-center bg-black/40 filter backdrop-blur-md"
    >
      <div
        class="absolute top-8 right-8 card w-[420px]"
      >
        <b-form v-if="isProjectSelectVisible" @submit.prevent="$emit('use', state)">
          <OrganizationFolderTree
            v-model="state.selectedFolderId"
            :organization-id="organizationId"
            @project-selected="projectSelected"
          />
          <Button
            type="submit"
            size="sm"
            variant="primary"
            class="mt-24 w-full"
            :disabled="isDisabled"
          >
            Confirm &amp; Use Template
          </Button>
        </b-form>
      </div>
    </div>
    <GuestTemplateDocument
      :document-id="template.documentId"
      :category="template.template_category.name"
    />
  </template>
  <template #header>
    <Button
      class="whitespace-nowrap"
      size="xl"
      @click="useTemplate"
    >
      <span class="icon_v2-so_tick mr-4 text-24 w-24" />Use this template
    </Button>
  </template>
</ModalBasic>
