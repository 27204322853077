import { CATEGORY, PREVIEW_VIEW_AS } from './documentsTypes'

export default {
  common: {
    // TODO: Update these according to latest data model (e.g. `tokens` array)
    status: 'DRAFT', // we need status for when approved via embedded in an approved doc
    title: 'Untitled',
    icon: '',
    color: null,
    // status: null,
    token: null,
    updatedBy: null,
    createdBy: null,
    createdAt: null,
    updatedAt: null,
    documents: [],
    costMatrix: [],
    viewCount: 0,
    countComments: 0,
    tasks: [],
    countActionItemsOpenPublic: 0,
    countActionItemsOpenPrivate: 0,
    countActionItemsResolvedPublic: 0,
    countActionItemsResolvedPrivate: 0,
    files: [],
    countFiles: 0,
    countDocumentFiles: 0,
    countQAFiles: 0,
    parentFolder: null,

    // sharing
    sharingClientEnabled: false,
    sharingLinkEnabled: false,
    sharingLinkToken: null,

  },
  [CATEGORY.Folder]: {
    previewViewAs: PREVIEW_VIEW_AS.card,
  },
  [CATEGORY.Document]: {
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
  [CATEGORY.ProjectPublicFolder]: {
    previewViewAs: PREVIEW_VIEW_AS.card,
  },
  [CATEGORY.DocumentCaseStudy]: {
    status: 'DRAFT', // we need status for when approved via embedded in an approved doc
    year: null,
    description: '',
    imageUrl: '',
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
  [CATEGORY.DocumentPackagedService]: {
    status: 'DRAFT', // we need status for when approved via embedded in an approved doc
    description: '',
    imageUrl: '',
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
  [CATEGORY.DocumentPage]: {
    status: 'DRAFT', // we need status for when approved via embedded in an approved doc
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
  [CATEGORY.DocumentTeamBio]: {
    status: 'DRAFT', // we need status for when approved via embedded in an approved doc
    role: '',
    imageUrl: '',
    description: '',
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
  [CATEGORY.DocumentReusableBlock]: {
    // fonts
    brandingFontHeading: null,
    brandingFontParagraph: null,
  },
}
