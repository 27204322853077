<template>
  <div v-fragment>
    <FileUploader
      ref="fileUploaderRef"
      multiple
      block
      disable-auto-upload
      @files-selected="onFilesSelected"
      @upload-start="onUploadStart"
      @upload-done="onUploadDone"
      @file-progress="onFileProgress"
      @file-done="onFileDone"
      @file-error="onFileError"
    >
      <ListLightDropdown
        :disabled="disabled"
        size="md"
        variant="neutral"
        :lazy="true"
        position="right"
        menu-class="text-14"
        no-caret
      >
        <template #button-content>
          <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
          <span v-b-tooltip.hover.top.v-info.dh0.ds200="`${disabled ? disabledReason : ''}`">
            {{ $t('common.create') }}
          </span>
          <span class="flex items-center justify-center w-32 h-32 -mr-6">
            <span class="inline-flex">
              <span class="icon_v2-so_direction-down text-18 w-18" />
            </span>
          </span>
        </template>

        <template #default>
          <ListLightDropdownItem
            v-for="fileType in mappedFileTypes"
            :key="fileType.category"
            :label="fileType.label"
            :document-category="fileType.category"

            :icon="fileType.icon"
            @select="handleFileCreation(fileType.category)"
          />
          <template v-if="!isClientPortal">
            <b-dropdown-divider />
            <ListLightDropdownItem
              label="Use template"
              icon="templates-new"
              @select="openModal"
            />
          </template>
        </template>
      </ListLightDropdown>
    </FileUploader>
    <AddLinkedFileModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      @file-linked="createFileLink"
    />
    <DocumentFromTemplateModal
      v-if="isTemplateModalOpen"
      v-model="isTemplateModalOpen"
      :templates-category="templatesCategory"
      :excluded-categories="[CATEGORY.DocumentReusableBlock]"
      @select="createDocumentFromTemplate"
    />
    <!-- ------------ -->
    <!-- IS UPLOADING -->
    <!-- ------------ -->
    <portal
      v-if="isUploading || uploadedBatch?.files?.length > 0"
      to="right-drawer"
    >
      <PendingUploads
        :pending-uploads="uploadedBatch?.files ?? []"
        @close="uploadsDone"
      />
    </portal>
  </div>
</template>
<script>
import { defineComponent, ref, inject, computed, reactive } from '@vue/composition-api'
import { dissoc } from 'ramda';
import useCustomFileSystem from '@/v2/lib/composition/useCustomFileSystem';
import { useFilesUpload } from '@/v2/services/uploads/uploadsCompositions';
import { CATEGORY, DEFAULT_DOCUMENT_TITLE, DEFAULT_DOCUMENT_ICON } from '@/v2/services/documents/documentsTypes';
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import { useDocumentLimit } from '@/v2/services/documents/compositions';
import ListLightDropdown from '@/components/List/ListLightDropdown.vue'
import ListLightDropdownItem from '@/components/List/ListLightDropdownItem.vue';
import PendingUploads from '@/components/PendingUploads.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'
import DocumentFromTemplateModal from '@/components/DocumentFromTemplateModal.vue'
import AddLinkedFileModal from '@/components/AddLinkedFileModal.vue';
import FileUploader from '@/components/FileUploader/FileUploader.vue';

export default defineComponent({
  name: 'DocCreateButton',
  components: {
    ListLightDropdown,
    ListLightDropdownItem,
    FileUploader,
    AddLinkedFileModal,
    DocumentFromTemplateModal,
    PendingUploads,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledReason: {
      type: String,
      default: 'Creating disabled in this folder',
    },
    disableTracking: {
      type: Boolean,
      default: false,
    },
    templatesCategory: {
      type: String,
      default: null,
    },
  },
  setup() {
    const fileUploaderRef = ref(null);
    const isClientPortal = inject('isClientPortal', false)
    const currentFolder = inject('document')
    const isModalOpen = ref(false)
    const isTemplateModalOpen = ref(false)

    const { createFilesInCurrentFolder } = useCustomFileSystem()
    const { create } = useDocuments()
    const { checkDocumentLimit } = useDocumentLimit()

    // Translated strings
    const { translate } = useLocalization()
    const fileTypes = computed(() => ([
      {
        category: CATEGORY.Document,
        label: translate('fs.new-document').value,
        icon: 'file-add',
      },
      {
        category: CATEGORY.File,
        label: translate('fs.upload-file').value,
        icon: 'file-upload',
      },
      {
        category: CATEGORY.FileLink,
        label: translate('fs.link-to-file').value,
        icon: 'link',
      },
      {
        category: CATEGORY.Folder,
        label: translate('fs.new-folder').value,
        icon: 'folder-add',
      },
    ]))

    const mappedFileTypes = computed(() => (isClientPortal
      ? fileTypes.value.filter(file => file.category !== CATEGORY.Document)
      : fileTypes.value))


    const createFiles = async items => {
      const _items = items.map(item => ({
        title: DEFAULT_DOCUMENT_TITLE[item.category],
        icon: DEFAULT_DOCUMENT_ICON[item.category],
        ...item,
      }))

      await createFilesInCurrentFolder(_items)
    }

    const createFile = async documentData => {
      // TODO: The below should be changed if multiple files are uploaded
      // const openNewFile = documentData.category !== CATEGORY.Folder
      const title = DEFAULT_DOCUMENT_TITLE[documentData.category]
      const icon = DEFAULT_DOCUMENT_ICON[documentData.category]
      try {
        await createFilesInCurrentFolder({ title, icon, ...documentData }/* , openNewFile */)
      } catch (err) {
        console.error('Error creating file:', err);
      }
    }

    const openLinkFileModal = () => { isModalOpen.value = true }
    const closeLinkFileModal = () => { isModalOpen.value = false }
    const createFileLink = file => {
      closeLinkFileModal()
      createFile({ file, category: CATEGORY.FileLink, icon: DEFAULT_DOCUMENT_ICON.FileLink })
    }

    const handleFileCreation = category => {
      if (category !== CATEGORY.File && checkDocumentLimit(category)) {
        return
      }

      switch (category) {
        case CATEGORY.File:
          fileUploaderRef.value.selectFiles()
          break
        case CATEGORY.Document:
        case CATEGORY.Folder:
          createFile({ category })
          break
        case CATEGORY.FileLink:
          openLinkFileModal()
          break
        default:
          console.warn('Unhandled file category selected!')
          break
      }
    }

    const mapFilesToDocs = async fileList => {
      const files = fileList.map(file => ({
        category: CATEGORY.File,
        title: file.name,
        file: dissoc('uuid', file),
      }))

      await createFiles(files)
    }

    // const mapFilesToDocs = async fileList => {
    //   await Promise.all(fileList.map(
    //     file => createFile({
    //       category: CATEGORY.File,
    //       title: file.name,
    //       file: dissoc('uuid', file),
    //     })
    //   ))
    // }

    const openModal = () => {
      isTemplateModalOpen.value = true
    }

    const createDocumentFromTemplate = async ({ document, template }) => {
      if (!document || !template) {
        return createFile({ category: CATEGORY.Document })
      }
      try {
        await create({
          cloneOf: document,
          title: template.name,
          project: currentFolder.value.project,
          organization: currentFolder.value.organization,
          parentFolder: currentFolder.value._id,
          category: CATEGORY.Document,
        })
      } catch (err) {
        console.log(err)
      }
    }

    const {
      isUploading,
      hasFiles,
      files,
      // filesErrors,
      onUploadStart,
      onUploadDone,
      onFileProgress,
      onFileDone,
      onFileError,
    } = useFilesUpload(ref([]), mapFilesToDocs)


    // Handle pending uploads overlay
    const pendingUploads = computed(() => files.value.filter(file => file.local))
    const uploadedBatch = reactive({
      files: [],
    })

    const uploadsDone = () => {
      uploadedBatch.files = []
    }

    const _onUploadStart = selectedFiles => {
      // context.emit('async-save-start')
      onUploadStart(selectedFiles)
      uploadedBatch.files = [...pendingUploads.value]
    }

    const onFilesSelected = async selectedFiles => {
      if (!selectedFiles.length || checkDocumentLimit(CATEGORY.File, selectedFiles.length)) {
        return
      }

      fileUploaderRef.value.upload()
    }

    return {
      CATEGORY,
      // Static
      fileTypes,
      // State,
      isClientPortal,
      currentFolder,
      isModalOpen,
      isTemplateModalOpen,
      hasFiles,
      isUploading,
      files,
      mappedFileTypes,
      pendingUploads,
      uploadedBatch,
      // Methods
      handleFileCreation,
      openModal,
      onUploadStart: _onUploadStart,
      onUploadDone,
      uploadsDone,
      onFileProgress,
      onFileDone,
      onFileError,
      createFileLink,
      closeLinkFileModal,
      createDocumentFromTemplate,

      onFilesSelected,
      fileUploaderRef,
    }
  },
})
</script>
