
<div
  class="
    absolute
    flex
    justify-end
    border border-gray-300/40
    rounded-lg
    pointer-events-none
    select-none
    inset-0
    opacity-0
    group-hover:opacity-100
  "
>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
    custom
  >
    <div
      :href="href"
      class="
        relative
        flex
        items-center
        justify-center
        h-24
        px-8
        space-x-6
        text-gray-200
        bg-gray-800
        rounded-lg
        cursor-pointer
        pointer-events-auto
        top-full
        -mt-12
        right-1/2
        transform
        translate-x-1/2
        hover:text-gray-100
      "
      @click="navigate"
    >
      <span class="h-16 icon_v2-so_setting-solid text-16"></span>
      <span class="leading-none text-12 whitespace-nowrap">
        Manage Quick Links
      </span>
    </div>
  </router-link>
</div>
