<template>
  <draggable
    :list="tasks"
    tag="ul"
    class="task-list"
    :animation="200"
    :group="lockTasksToSection ? `tasks-${sectionId}` : 'tasks'"
    :disabled="noTaskReordering"
    ghost-class="ghost"
    @start="drag = true"
    @end="drag = false"
    @change="event => $emit('drag-event', event)"
  >
    <TaskListItem
      v-for="task in tasks"
      :key="task._id"
      :task="task"
      :selected="task._id === selectedTaskId"
      :drag="drag"
    >
      <slot name="task" v-bind="{ task }" />
    </TaskListItem>
  </draggable>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import draggable from 'vuedraggable'
import TaskListItem from '@/components/Tasks/TaskListItem.vue'

export default defineComponent({
  name: 'TaskList',
  components: {
    draggable,
    TaskListItem,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    sectionId: {
      type: String,
      required: true,
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const drag = ref(false)

    return {
      drag,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .task-list {
  }

  .ghost {
    @apply border border-gray-500;
  }
</style>
