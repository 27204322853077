
<FileUploader
  :multiple="false"
  accept="image/*"
  @upload-start="onUploadStart"
  @file-progress="onFileProgress"
  @file-done="onFileDone"
  @file-error="onFileError"
>
  <template #default="{ selectFiles }">
    <div class="w-full flex flex-col items-center justify-center">
      <ImageThumbnail
        v-if="hasUrl"
        :url="fileUrl"
        :width="width"
        :height="height"
        :class="variantClasses['imageClasses']"
      >
        <!-- Progress Bar -->
        <div
          v-if="fileUploadState.isUploading"
          class="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 bg-opacity-50 flex items-center justify-center"
        >
          <PieProgressBar :ratio="fileUploadState.progress" />
        </div>
      </ImageThumbnail>

      <!-- Placeholder -->
      <div v-else :class="variantClasses['placeholderClasses']">
        <span class="icon_v2-so_image icon-48 text-48"></span>
      </div>

      <!-- Buttons -->
      <div :class="variantClasses['buttonClasses']">
        <!-- Remove -->
        <Button
          v-if="!fileUploadState.isUploading && hasUrl"
          variant="danger"
          size="sm"
          @click.stop="removeFile"
        >
          <span class="icon_v2-so_trash"></span>
        </Button>
        <!-- Add -->
        <Button
          variant="primary"
          size="sm"
          @click.stop="selectFiles()"
        >
          <span class="icon_v2-so_image"></span>
          <span v-if="!hasUrl" class="ml-8">{{ $t('settings.upload') }}</span>
        </Button>
      </div>
    </div>
  </template>
</FileUploader>
