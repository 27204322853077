<template>
  <div class="progress">
    <div
      class="percentage transition-width duration-500"
      :class="{ full: percent >= 100 }"
      :style="{ width: percent + '%' }"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      type: Number,
      default: null,
    },
    ratio: {
      type: Number,
      default: null,
    },
  },
  computed: {
    percent() {
      return this.percentage ?? Math.round(this.ratio * 100)
    },
  },
}
</script>
<style lang="postcss" scoped>
.progress {
  @apply bg-gray-300 h-8 rounded-full;
}
.percentage {
  @apply bg-green-400 rounded-l-full h-8;
  &.full {
    @apply rounded-r-full;
  }
}

</style>
