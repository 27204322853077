
<p
  v-if="disabled"
  class="hover:underline hover:cursor-pointer portal-paragraph-font"
  @click="event => $emit('select', event)"
>
  <WrappedTitle
    :text="document.title"
    :pre-size="size==='m' ? 20 : 34"
  />
</p>
<div v-else v-fragment>
  <div v-if="isEditing" class="relative">
    <b-input
      v-model="state.title"
      :disabled="isBusy"
      autofocus
      variant=""
      trim
      type="text"
      class="inline-flex border
      bg-white border-gray-200
      dark:bg-darkGray-900 dark:border-darkGray-600
      rounded-md w-full items-center
      text-14 leading-snug pl-2 pr-24 h-24 shadow-none
      focus:shadow-none active:shadow-none portal-paragraph-font"
      placeholder="..."
      @click.stop
      @blur.native.stop="saveOnBlur"
      @keyup.enter.prevent="saveOnBlur"
    />
    <div
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Press Return to save`"
      class="absolute right-4 inset-y-px z-10
          bg-white dark:bg-darkGray-900
            rounded-md
            flex items-center justify-center"
    >
      <span
        class="icon-keyboard_return text-16
      text-gray-700 dark:text-darkGray-300 opacity-40"
      ></span>
    </div>
  </div>
  <div
    v-else
    class="relative group portal-paragraph-font"
    @click.stop
    @dblclick.stop="toggleEditing"
  >
    <p
      :class="[link
        ? 'hover:underline hover:cursor-pointer'
        : 'hover:no-underline']"
      @click="event => selectDocument(event)"
    >
      <WrappedTitle
        :text="document.title"
        :pre-size="size==='m' ? 20 : 34"
      />
      <button
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Change name'"
        variant="slim"
        size="xs"
        class="
          hidden group-hover:inline-flex items-center justify-center opacity-0
            text-gray-700/30 dark:text-darkGray-300/30
              group-hover:opacity-100
              absolute rounded-full m-0
              transform translate-x-4
              bg-white dark:bg-darkGray-600
              hover:text-gray-700/50 hover:dark:text-darkGray-300/50"
        @click.stop="toggleEditing"
      >
        <span class="icon_v2-so_pencil text-16"></span>
      </button>
    </p>
  </div>
</div>
