
<div class="flex items-end" :class="className">
  <b-dropdown
    v-if="!disableMenu"
    variant="link"
    left
    :no-caret="noCarret"
    :class="dropdownClass"
    :menu-class="menuClass"
    :toggle-class="[
      toggleClass,
      'flex items-center text-white rounded hover:bg-gray-500 hover:bg-opacity-10 hover:text-white',
    ]"
    class="h-32"
    @shown="$root.$emit('bv::hide::popover')"
  >
    <template slot="button-content">
      <slot name="button" />
    </template>
    <slot />
  </b-dropdown>
</div>
