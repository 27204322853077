<template>
  <b-dropdown
    menu-class="pl-8 pr-8 pb-8 pt-8 min-w-0"
    toggle-class="p-0 editor-toolbar-button hover:bg-gray-500 hover:bg-opacity-10"
    variant="transparent"
    no-caret
  >
    <template #button-content>
      <div
        title="Highlight"
        :class="[
          'h-32 w-32 rounded relative text-white text-sm flex justify-center items-center',
          isActive && activeColor && $style[`${activeColor}`],
          isActive && !activeColor && 'bg-gray-500 hover:bg-gray-600 bg-opacity-50 hover:bg-opacity-100',
        ]"
      >
        <span class="w-24 text-24 icon-toolbar-highlight"></span>
        <span :class="[$style['toolbar-dropdown']]"></span>
      </div>
    </template>

    <div class="flex flex-row items-center space-x-8 min-w-0">
      <button
        v-for="color in colors"
        :key="color"
        :class="[
          'h-32 w-32 inline-flex items-center justify-center relative rounded-sm',
          'text-white font-semibold text-sm',
          $style[`${color}`],
        ]"
        @click="selectColor(color)"
      >
        <span
          v-if="Boolean(activeColor === color)"
          :class="$style[`active-color`]"
        >
          <span class="icon_v2-so_tick w-16 text-16"></span>
        </span>
        <span v-else class="w-24 text-14 font-semibold">Aa</span>
      </button>
    </div>
  </b-dropdown>
</template>

<script>
import { HIGHLIGHT_COLORS } from '@/types/editor'

export default {
  name: 'EditorToolbarHighlight',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const selectColor = color => {
      context.emit('select', color)
    }
    return {
      colors: HIGHLIGHT_COLORS,
      selectColor,
    }
  },
}
</script>

<style lang="postcss" module>
.background-none {
  @apply bg-white text-gray-900 hover:ring-1 hover:ring-gray-100;
}
.background-green {
  @apply bg-green-150 hover:ring-1 hover:ring-green-500;
  color: #073F11;
}
.background-blue {
  @apply bg-blue-100 hover:ring-1 hover:ring-blue-200;
  color: #05334A;
}
.background-yellow {
  @apply bg-yellow-200 hover:ring-1 hover:ring-yellow-400;
  color: #484007;
}
.background-orange {
  @apply bg-orange-100 hover:ring-1 hover:ring-orange-400;
  color: #442802;
}
.background-red {
  @apply bg-red-100 hover:ring-1 hover:ring-red-400;
  color: #500808;
}
.background-violet {
  @apply bg-violet-100 hover:ring-1 hover:ring-violet-400;
  color: #130840;
}
.background-gray {
  @apply bg-gray-100 text-gray-900 hover:ring-1 hover:ring-gray-300;
}
.active-color {
  @apply absolute inset-0 z-1 w-full h-full flex items-center justify-center border border-gray-700;
}
.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>
