
<div v-if="document" class="flex justify-center">
  <div class="pt-56 pb-66 mb-32 px-24 w-full">
    <div v-if="isFile" class="flex flex-col items-center justify-center mb-24">
      <!-- -------------------- -->
      <!-- IMAGE -->
      <!-- -------------------- -->
      <div
        v-if="document.file && isImageFile"
        class="relative min-w-[280px] xl:min-w-[560px] md:max-w-[370px]
                xl:max-w-[720px] fs-card--doc-img mb-8 md:mb-16
                mx-auto w-full"
      >
        <div class="absolute flex items-center justify-center w-full h-full p-px">
          <img
            :src="document.file.url"
            class="object-contain max-h-full"
          />
        </div>
      </div>
      <!-- -------------------- -->
      <!-- FILE WITH MEDIA PLAYER -->
      <!-- -------------------- -->
      <div
        v-else
        class="relative min-w-[280px] mb-8 md:mb-16
      mx-auto w-full flex flex-col items-center min-h-320 justify-center"
      >
        <AudioPlayer
          v-if="mediaType==='audio'"
          :file="document.file"
        />
        <VideoPlayer
          v-if="mediaType==='video'"
          :file="document.file"
        />
        <div
          v-if="mediaType==='pdf'"
          class="flex-1 flex-grow p-0 m-0 flex flex-col items-start justify-start
            overflow-hidden bg-white border border-gray-300 rounded-lg w-full"
          @click.stop=""
        >
          <PDFViewer
            class="w-full h-auto max-h-[80vh]"
            :source="document?.file?.url"
          />
        </div>
        <!-- OTHERS -->
        <div
          v-if="!mediaType"
          class=" min-w-[280px] md:max-w-[370px]
                xl:max-w-[520px] border border-gray-300 min-h-320 mb-8 md:mb-16
                mx-auto w-full flex flex-col items-center justify-center gap-12"
        >
          <FileIcon
            :content-type="document?.file?.contentType"
            size="128"
          />
          <p class="text-gray-700">{{ document.title }}</p>
        </div>
      </div>
    </div>
    <!-- -------------------- -->
    <!-- LINKED FILE -->
    <!-- -------------------- -->
    <div v-else class="flex flex-col items-center justify-center mb-24">
      <a
        :href="document.file.url"
        target="_blank"
        class="
          flex items-center justify-center p-48
          border border-gray-200 rounded-xl
          dark:border-darkGray-600
          "
      >
        <span
          class="text-72 w-72 leading-none portal-accent-foreground"
          :class="document.icon"
          :style="{ color: document.color}"
        ></span>
      </a>

      <a
        class="my-16 text-center text-gray-500 w-510 text-14
      line-clamp-2 md:line-clamp-3 xl:line-clamp-none
      hover:text-gray-600 hover:underline"
        :href="document.file.url"
        target="_blank"
      >
        {{ document.file.url }}
      </a>
    </div>
    <div class="flex justify-center space-x-16">
      <FileDownload
        v-slot="{ proxyUrl }"
        :url="document.file.url"
      >
        <Button
          size="xl"
          :href="proxyUrl"
          class="portal-accent-background portal-accent-foreground px-14 hover:opacity-90"
          target="_blank"
          download
        >
          <span :class="['w-20 text-20 mr-4', mapIcon[document.category] ]">
          </span> {{ mapTitle[document.category] }}
        </Button>
      </FileDownload>
    <!-- :href="proxyUrl" -->
    <!-- <Button
      variant="neutral"
      size="xl"
      :href="document.file.url"
      class="px-14"
    >
      <span :class="['w-20 text-20 mr-4 icon-util-upload-alt' ]">
      </span> {{ $t('uploads.replace') }}
    </Button> -->
    <!-- </FileDownload> -->
    </div>
  </div>
</div>
