
<b-dropdown-item
  :data-intercom-target="intercom"
  :to="to"
  :active-class="activeClass"
  :link-class="linkClass"
  @click="$emit('dropdown-item-select')"
>
  <span
    class="relative inline-flex items-center justify-center w-24 mr-4"
  ><span :class="['w-24 text-24', icon]"></span></span>{{ label }}
</b-dropdown-item>
