<template>
  <ModalBasic
    :title="title"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="md"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <section class="pb-16">
        <p v-if="showSubtitle" class="text-gray-500 text-14">Where do you want to move this?</p>
        <ProjectFolderTree
          v-model="selectedFolderId"
          :project-id="projectId"
          :folder-id="folderId"
          :folder-category="folderCategory"
          :hidden-folder-ids="hiddenFolderIds"
          :hidden-folder-categories="hiddenFolderCategories"
        />
      </section>
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="branded"
          size="md"
          :disabled="!selectedFolderId"
          @click="onSelect"
        >
          {{ okLabel }}
        </Button>
      </Buttons>
    </template>
  </ModalBasic>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import ProjectFolderTree from '@/components/ProjectFolderTree.vue'


export default defineComponent({
  name: 'ProjectFolderSelectModal',
  components: {
    ModalBasic,
    Button,
    Buttons,
    ProjectFolderTree,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    folderId: {
      type: String,
      default: null,
    },
    folderCategory: {
      type: String,
      default: null,
    },
    hiddenFolderIds: {
      type: Array,
      default: () => [],
    },
    hiddenFolderCategories: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Select Folder',
    },
    okLabel: {
      type: String,
      default: 'Select',
    },
    showSubtitle: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const selectedFolderId = ref(null)

    const onSelect = () => {
      context.emit('select', selectedFolderId.value)
    }

    return {
      selectedFolderId,
      onSelect,
    }
  },
})
</script>
