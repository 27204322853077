<template>
  <b-form @submit.prevent="submit">
    <FormMessage v-if="error">
      {{ error.message }}
    </FormMessage>
    <slot
      v-bind="{
        state: formState,
        v: $v,
        isBusy,
        isInvalid,
        isDirty,
        isValidationPending,
        error,
        submit,
        reset,
        setError,
        clearError,
        hasChanges,
      }"
    />
  </b-form>
</template>
<script>
import { clone } from 'ramda'
import { isFunction, rejectNil } from 'ramda-extension';
import { defineComponent, toRef, reactive, watch } from '@vue/composition-api'
import useForm from '@/v2/lib/composition/useForm'
import useNotification from '@/v2/lib/composition/useNotification'
import FormMessage from '@/components/FormMessage.vue'

export default defineComponent({
  name: 'Form',
  components: {
    FormMessage,
  },
  props: {
    state: {
      type: Object,
      required: true,
    },
    validation: {
      type: Object,
      default: null,
    },
    validationScope: {
      type: String,
      default: null,
    },
    submitIfInvalid: {
      type: Boolean,
      default: false,
    },
    clone: {
      type: Boolean,
      default: false,
    },
    autoDirty: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: null,
    },
    successMessage: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const notification = useNotification()

    const formState = props.clone
      // form keeps a detached reactive state locally (initial state is cloned)
      ? reactive(clone(props.state))
      // uses the provided state directly (pass-thru)
      : toRef(props, 'state');

    const {
      formIsBusy,
      formValidationPending,
      formIsInvalid,
      formIsDirty,
      formError,
      formSubmit,
      formReset,
      formSetError,
      formClearError,
      formHasChanges,
      $v,
    } = useForm({
      state: formState,
      validation: toRef(props, 'validation'),
      submitIfInvalid: props.submitIfInvalid,
      vuelidateOptions: rejectNil({
        $autoDirty: props.autoDirty,
        $scope: props.validationScope,
      }),
      onSubmit: async () => {
        const result = isFunction(props.onSubmit)
          ? await props.onSubmit(formState)
          : null

        context.emit('submit', formState, result)

        if (props.successMessage) {
          notification({ message: props.successMessage })
        }
      },
      onTouch: () => {
        context.emit('touched', $v)
      },
    })

    watch([formIsInvalid, formIsDirty], ([isInvalid, isDirty]) => {
      context.emit('invalid', isInvalid && isDirty)
    })

    return {
      $v,
      formState,
      isBusy: formIsBusy,
      isValidationPending: formValidationPending,
      isInvalid: formIsInvalid,
      isDirty: formIsDirty,
      error: formError,
      submit: formSubmit,
      reset: formReset,
      setError: formSetError,
      clearError: formClearError,
      hasChanges: formHasChanges,
    }
  },
})
</script>
