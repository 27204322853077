
<PieProgressBar
  v-if="ratio"
  small
  :ratio="ratio"
  :variant="variant"
/>
<div
  v-else
  class="sbl-circ"
  :class="variant"
  :style="style"
></div>
