<template>
  <Form
    v-if="state"
    v-slot="{ v }"
    class="so-form portal-paragraph-font"
    :class="{
      'so-form-4': !hidePortalVisibility,
      'so-form-3': hidePortalVisibility
    }"
    :style="projectTheme"
    :state="state"
    :validation="validation"
    @submit="onSubmit"
  >
    <div class="so-form-header">
      <div class="flex-1">
        <ContentEditable
          v-model="state.title"
          :title-topbar="state.title"
          :placeholder="$t('tasks.titlePlaceholder')"
          tag="p"
          class-name="
            text-22 font-light smart-word-break
            flex-1 portal-heading-font dark:text-white/70
          "
          :no-html="true"
          no-newline
          :contenteditable="!isReadonly"
          trim
          autofocus
        />
        <span
          v-if="v.title.$dirty && v.title.$error"
          class="text-error mb-4"
        >Title is required</span>
      </div>
      <div class="flex items-center gap-8 mt-4">
        <label
          for="task-editor-resolved"
          class="text-14 select-none"
          :class="{
            'text-gray-400': !state.isResolved,
            'text-green-600': state.isResolved
          }"
        >{{ $t('tasks.taskEditor.resolved') }}</label>
        <b-form-checkbox
          id="task-editor-resolved"
          v-model="state.isResolved"
          class="-mr-[12px]"
          switch
          size="lg"
        />
      </div>
    </div>
    <div class="so-form-group">
      <label class="so-form-label">{{ $t('tasks.taskEditor.assignee') }}</label>
      <div class="so-form-input-container">
        <Multiselect
          v-if="!isReadonly"
          v-model="assigneeModel"
          :options="assigneeOptions"
          :placeholder="$t('tasks.taskEditor.assigneePlaceholder')"
          :custom-label="assigneeLabel"
          track-by="userId"
          :show-labels="false"
        >
          <template #singleLabel="props">
            <Avatar
              :profile="props.option.profile"
              show-name
              :nowrap="false"
            />
          </template>
          <template #option="props">
            <Avatar
              :profile="props.option.profile"
              show-name
              :nowrap="false"
            />
          </template>
        </Multiselect>
        <AvatarUser
          v-else-if="entity.assignedTo"
          class="dark:text-white/70"
          :user-id="entity.assignedTo"
          show-name
          :nowrap="false"
        />
        <div v-else>-</div>
        <ButtonClear v-if="!isReadonly" @click="assigneeModel = null" />
      </div>
    </div>
    <div class="so-form-group">
      <label class="so-form-label">{{ $t('tasks.taskEditor.dueDate') }}</label>
      <div class="so-form-input-container">
        <TaskDatePicker
          v-if="!isReadonly"
          v-model="state.dueDate"
          class="datepicker"
          :readonly="isReadonly"
        />
        <div v-else>
          <ActionItemDateDisplay
            v-if="entity.dueDate"
            v-slot="{ displayDate }"
            :item="entity"
          >
            <span class="icon-util-calendar text-16" />
            {{ displayDate.message }}
          </ActionItemDateDisplay>
          <div v-else>-</div>
        </div>
        <ButtonClear v-if="!isReadonly" @click="state.dueDate = null" />
      </div>
    </div>
    <div v-if="!hidePortalVisibility" class="so-form-group">
      <label for="task-editor-public" class="so-form-label">Portal visibility</label>
      <div class="so-form-input-container">
        <div class="so-form-input flex items-center gap-12">
          <b-form-checkbox
            id="task-editor-public"
            v-model="state.public"
            class="-mr-[12px]"
            switch
            size="lg"
          />
          <div class="text-14 text-gray-400">
            <span v-if="state.public">Client can see this</span>
            <span v-else>Private to your team</span>
          </div>
        </div>
      </div>
    </div>
    <div class="so-form-group-v">
      <label for="task-editor-description" class="so-form-label">
        {{ $t('tasks.taskEditor.description') }}
      </label>
      <b-textarea
        v-if="!isReadonly"
        id="task-editor-description"
        v-model="state.notes"
        class="so-form-input-v"
      />
      <div v-else class="form-control so-form-input-v">{{ state.notes }}</div>
    </div>
    <div class="col-span-2">
      <ProjectTaskEditorAttachments
        :task="state"
        :readonly="isReadonly"
        @update="update"
      />
    </div>
    <div class="col-span-2">
      <ProjectTaskEditorInfo
        v-if="!isNew"
        :task="entity"
      />
    </div>
    <div class="so-form-buttons">
      <div class="flex items-center gap-8">
        <Button
          size="md"
          variant="primary"
          type="submit"
          :is-loading="isSaving"
          class="portal-accent-background portal-accent-foreground"
        >
          <span class="mr-4 -ml-4 icon_v2-so_tick" />
          <span v-if="isNew">{{ $t('tasks.taskEditor.create') }}</span>
          <span v-else>{{ $t('tasks.taskEditor.update') }}</span>
        </Button>
        <Button
          size="md"
          variant="neutral"
          type="button"
          @click="$emit('close')"
        >
          <span class="mr-4 -ml-4 icon_v2-so_close text-gray-600 dark:text-darkGray-400" />
          <span>{{ $t('tasks.taskEditor.close') }}</span>
        </Button>
      </div>
      <Button
        v-if="!isNew && !isReadonly"
        size="md"
        variant="neutral"
        type="button"
        @click="$emit('remove', entity)"
      >
        <span class="mr-4 -ml-4 icon_v2-so_trash text-red-600" />
        <span>{{ $t('tasks.taskEditor.delete') }}</span>
      </Button>
    </div>
  </Form>
</template>
<script>
import Vue from 'vue'
import { reduce } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { isBoolean } from 'ramda-adjunct'
import { computed, defineComponent, toRef, watch } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { useUser } from '@/v2/services/users/usersCompositions'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import { useFindMembers } from '@/v2/services/members/compositions'
import { ROLES, STATUS } from '@/v2/services/members/membersTypes'
import { useTracking } from '@/v2/services/tracking/compositions'
import useProjectTheme from '@/v2/lib/composition/useProjectTheme'
import Multiselect from '@/components/Multiselect.vue'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import ContentEditable from '@/components/ContentEditable.vue'
import Form from '@/components/Form.vue'
import Button from '@/components/Button.vue'
import ButtonClear from '@/components/ButtonClear.vue'
import AvatarUser from '@/components/AvatarUser.vue'
import ActionItemDateDisplay from '@/components/ActionItemDateDisplay.vue'
import ProjectTaskEditorInfo from '@/components/ProjectTaskEditorInfo.vue'
import ProjectTaskEditorAttachments from '@/components/ProjectTaskEditorAttachments.vue'
import TaskDatePicker from '@/components/TaskDatePicker.vue'

const FIELDS = [
  'title',
  'notes',
  'dueDate',
  'public',
  'assignedTo',
  'isResolved',
  'files',
]

const toMap = reduce((acc, key) => ({ ...acc, [key]: true }), {})

export default defineComponent({
  name: 'ProjectTaskEditor',
  components: {
    Multiselect,
    Avatar,
    ContentEditable,
    Form,
    Button,
    ButtonClear,
    AvatarUser,
    ActionItemDateDisplay,
    ProjectTaskEditorInfo,
    ProjectTaskEditorAttachments,
    TaskDatePicker,
  },
  props: {
    sectionId: {
      type: String,
      default: null,
    },
    documentId: {
      type: String,
      default: null,
    },
    taskId: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      required: true,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    restrictToOwner: {
      type: Boolean,
      default: false,
    },
    hidePortalVisibility: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { track } = useTracking()
    const { user } = useUser()

    const projectTheme = useProjectTheme({
      project: computed(() => props.project),
    })

    const { TaskSection } = Vue.$FeathersVuex.api;
    const taskSection = computed(() => TaskSection.getFromStore(props.sectionId))

    const defaults = computed(() => rejectNil({
      organization: props.project.organization,
      project: props.project._id,
      document: props.documentId,
      taskSection: props.sectionId,
    }))

    const {
      state,
      entity,
      save,
      update,
      isNew,
      isSaving,
    } = useFormEntityEditor({
      model: 'ActionItem',
      id: toRef(props, 'taskId'),
      defaults,
      fields: FIELDS,
    })

    const statusOptions = [
      { value: null, text: 'No Status' },
      { value: 'In Progress', text: 'In Progress' },
      { value: 'Blocked', text: 'Blocked' },
      { value: 'Done', text: 'Done' },
    ]

    const visibilityOptions = [
      { value: false, text: 'Private to you and your team' },
      { value: true, text: 'Your client can see this' },
    ]

    // const onFormTouched = $v => {
    //   if ($v.value.title.$dirty && $v.value.title.$invalid) {
    //     titleRef.value.focus()
    //   }
    // }

    const validation = computed(() => ({
      title: {
        required,
      },
    }));

    // -- assignee

    const assigneeParams = computed(() => ({
      query: rejectNil({
        organization: props.project.organization,
        status: STATUS.ACTIVE,
        $or: [
          { role: { $in: [ROLES.admin, ROLES.projectManager] } },
          { _id: { $in: props.project.members } },
        ],
      }),
    }))

    const { items: assigneeItems } = useFindMembers({
      params: assigneeParams,
      joinUser: true,
      joinProjects: false,
    })

    const assigneeOptions = computed(() => assigneeItems.value
      .filter(item => Boolean(item?.user?.profile$))
      .map(({ user: { profile$: profile, ...user } }) => ({
        userId: user._id,
        profile,
      })));

    const assigneeLabel = ({ profile }) => profile.fullName

    const assigneeModel = computed({
      get() {
        return assigneeOptions.value.find(opt => opt.userId === state.value.assignedTo)
      },
      set(opt) {
        state.value.assignedTo = opt?.userId ?? null
      },
    })

    const disabledFieldsMap = computed(() => toMap(props.disabledFields))

    const isReadonly = computed(
      () => !isNew.value
        && props.restrictToOwner
        && entity.value.createdBy !== user.value._id
    )

    const onSubmit = async () => {
      await save(isReadonly.value ? ['isResolved'] : null)
      track('Action Item Created', { type: props.documentId ? 'document' : 'project' })
    }


    watch(() => entity.value?.isResolved, newVal => {
      if (isBoolean(newVal)) {
        state.value.isResolved = newVal
      }
    })

    return {
      // form
      state,
      entity,
      save,
      update,
      isNew,
      isSaving,
      onSubmit,

      // assignee
      assigneeOptions,
      assigneeLabel,
      assigneeModel,

      statusOptions,
      visibilityOptions,
      validation,
      disabledFieldsMap,
      taskSection,
      isReadonly,

      projectTheme,
    }
  },
})
</script>
<style lang="postcss" scoped>
.so-form {
  @apply grid gap-20;
  grid-template-columns: auto 1fr;
}

.so-form-4 {
  grid-template-rows: repeat(4, auto) 1fr auto;
}

.so-form-3 {
  grid-template-rows: repeat(3, auto) 1fr auto;
}

.so-form-header {
  grid-column: 1 / -1;
  @apply flex items-start;
}

.so-form-label {
  @apply text-gray-400 text-14 select-none;
}

.so-form-group {
  @apply contents;

  .so-form-label {
    @apply self-center;
  }
}

.so-form-input-container {
  @apply flex;
}

.so-form-group-v {
  grid-column: 1 / -1;
  @apply flex flex-col gap-10;
}

.so-form-input-v {
  @apply resize-none flex-grow;
}

.so-form-buttons {
  @apply flex justify-between;
  grid-column: 1 / -1;
}

.dark {
  .datepicker::v-deep {
    .btn {
      @apply text-white;
    }
  }
}
</style>
