
<div>
  <div>
    <h1
      class="
        mb-16 font-semibold leading-tight
        text-center text-gray-800
        text-18 md:text-24 md:leading-normal
        dark:text-white dark:opacity-95
        portal-heading-font
      "
    >
      <template v-if="portalCustomizations.heading">
        {{ portalCustomizations.heading }}
      </template>
      <!-- No custom heading. Revert to default -->
      <template v-else>
        {{ $t('login.signin-to', { organization: organization.name }) }} <br />
      </template>
    </h1>
  </div>
  <b-tabs
    v-if="loginOptions.length > 1"
    v-model="selectedLoginOptionIndex"
    class="mb-32"
    pills
    fill
  >
    <b-tab
      v-for="loginOption in loginOptions"
      :key="loginOption.id"
      :title="loginOption.name"
      :title-link-class="`${$style['tab-button']} portal-paragraph-font dark:text-white`"
      no-body
    />
  </b-tabs>

  <ClientPortalLoginMagicLink
    v-if="selectedLoginOption?.id === 'magicLink' "
    :organization="organization"
    :domain="domain"
    :portal-customizations="portalCustomizations"
  />
  <ClientPortalLoginPassword
    v-if="selectedLoginOption?.id === 'password'"
    :organization="organization"
    :domain="domain"
    :portal-customizations="portalCustomizations"
    @recover-password="
      $router.push({ name: PORTAL_RECOVER_PASSWORD_REQUEST })
    "
  />
  <!-- Custom Rich Text (HTML) content -->
  <div
    v-if="portalCustomizations.customContent"
    id="custom-rich-text-content"
    class="
          editor-content editor-content-preview editor-content-branded
          dark:text-white dark:opacity-70 mt-40
          portal-paragraph-font
        "
    v-html="portalCustomizations.customContent"
  >
  </div>
</div>
