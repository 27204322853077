<template>
  <div>
    <PageHeader :title="$t('settings.authentication')" />
    <UserSettingsPassword class="max-w-420">
      <template #buttons="{ submit, hasChanges }">
        <PageFooter>
          <Button
            size="lg"
            variant="primary"
            type="submit"
            @click="submit"
          >
            {{ $t('common.save-changes') }}
          </Button>
          <template v-if="hasChanges" #right>
            <PageFooterMessage />
          </template>
        </PageFooter>
      </template>
    </UserSettingsPassword>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'
import Button from '@/components/Button.vue'
import UserSettingsPassword from '@/components/UserSettingsPassword.vue'
import PageFooterMessage from '@/components/PageFooterMessage.vue'

export default defineComponent({
  name: 'ViewUserSettingsSecurity',
  components: {
    PageHeader,
    PageFooter,
    Button,
    UserSettingsPassword,
    PageFooterMessage,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
})
</script>
