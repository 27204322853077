<template>
  <DocumentNodeRenderer
    :node="node"
    :root-class="$style['node-reusable-block']"
    :section-class="$style['node-section-reusable-block']"
    :config-override="{}"
  >
    <template #default="{ styling, textVariant }">
      <DocumentContentBlockReusableBlock
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockReusableBlock from './DocumentContentBlockReusableBlock.vue'

export default {
  name: 'DocumentNodeReusableBlock',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockReusableBlock,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang="scss" module>
.node-reusable-block {
}
.node-section-reusable-block {
}
</style>
