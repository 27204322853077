<template>
  <div class="mb-24">
    <!-- --- EVENT BODY ------------ -->
    <DocumentLink v-slot="{ navigate }" :document="document">
      <div
        class="
        relative flex flex-col mb-12 text-gray-700 cursor-pointer
        dark:text-darkGray-400 text-14
      "
        @click="navigate"
      >
        <!-- AVATAR ON TIMELINE -->
        <div class="absolute transform -translate-x-8 right-full">
          <NotificationAvatar
            :notification="notification"
            :size="40"
            :icon-size="16"
            :font-size="14"
          />
        </div>
        <!-- DATE CREATED -->
        <span
          class="
            leading-none text-gray-600 dark:text-darkGray-500 text-14
            portal-paragraph-font
          "
        >
          {{ $d(new Date(notification.time), 'time') }}
        </span>
        <div class="flex items-center leading-none portal-paragraph-font">
          {{ authorName }}
          <slot name="action" />
        </div>
      </div>
    </DocumentLink>
    <!-- --- DOCUMENT ------------ -->
    <div class="w-full">
      <slot v-bind="{ document, profile: authorProfile, isClientPortal }" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, inject, toRef } from '@vue/composition-api'
import { useNotificationAuthor } from '@/v2/services/notifications/compositions'
import DocumentLink from '@/components/DocumentLink.vue'
import NotificationAvatar from '@/components/NotificationAvatar.vue'

export default defineComponent({
  name: 'ProjectTimelineRendererSingle',
  components: {
    DocumentLink,
    NotificationAvatar,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isClientPortal = inject('isClientPortal', false)

    const {
      authorProfile,
      authorName,
    } = useNotificationAuthor(toRef(props, 'notification'))


    const document = computed(() => props.notification.subject$);

    return {
      isClientPortal,
      document,
      authorProfile,
      authorName,

    }
  },
})
</script>
