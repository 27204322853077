
<FileUploader
  v-slot="{ selectFiles, isDragging }"
  data-intercom-target="Attachments Block"
  multiple
  block
  inline-block
  @upload-start="onUploadStart"
  @upload-done="onUploadDone"
  @file-progress="onFileProgress"
  @file-done="onFileDone"
  @file-error="onFileError"
>
  <div class="task-attachments">
    <portal to="body">
      <LightboxGallery
        :files="filesMedia"
        :index="index"
        @close="index = null"
      />
    </portal>
    <div
      v-if="batchRatio === null"
      class="uppercase text-10 flex justify-between items-center px-16 pt-16"
      :class="{ 'pb-16': !files.length }"
    >
      <div v-if="files.length">{{ $t('tasks.taskEditor.attachments') }}</div>
      <div v-else>{{ $t('tasks.taskEditor.attachmentsPlaceholder') }}</div>
      <ButtonIcon
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="$t('tasks.taskEditor.attachmentsUpload')"
        icon="icon-util-upload"
        :disabled="isUploading"
        @click="selectFiles"
      />
    </div>
    <div v-else class="px-16 pt-16">
      <ProgressBar
        class="flex-1"
        :ratio="batchRatio"
      />
    </div>
    <div v-if="files.length" class="attachments">
      <!-- Audio -->
      <section v-if="filesMedia.length" class="attach-media">
        <div
          v-for="(file, fileIndex) in filesMedia"
          :key="file.uuid"
          :class="file.local && 'uploading-image'"
          class="flex flex-col"
        >
          <DocumentContentBlockAttachmentsMedia
            v-if="!file.local"
            :file="file"
            :read-only="readonly"
            no-min-height
            @remove="remove(file)"
            @click-thumbnail="index = fileIndex"
          />
        </div>
      </section>
      <section
        v-if="filesNonMedia.length"
        class="attach-files mt-16 pt-8"
        :class="{'dark:border-t-darkGray-700 border-t': filesMedia.length }"
      >
        <div
          v-for="fileNonImage in filesNonMedia"
          :key="fileNonImage.uuid"
          :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
        >
          <DocumentContentBlockAttachmentsFile
            v-if="!fileNonImage.local"
            :file="fileNonImage"
            :read-only="readonly"
            @remove="remove(fileNonImage)"
          />
        </div>
      </section>

      <div v-if="filesErrors.length" class="attach-note">
        <h4 class="portal-paragraph-font">
          <span class="w-16 mr-4 text-16 icon-alert"></span>
          <span>{{ $t('tasks.taskEditor.attachmentsUploadError') }}</span>
        </h4>
        <div v-for="fileError in filesErrors" :key="fileError.uuid">
          <strong>{{ fileError.name }}</strong>
          {{ fileError.local.error | error }}
        </div>
      </div>
    </div>
  </div>
</FileUploader>
