export const docSidebarTypes = {
  COMMENTS: 'comments',
  NOTIFICATIONS: 'notifications',
  ACTION_ITEMS: 'actionItems',
  ANALYTICS: 'analytics',
  CONTENTS: 'contents',
}

export const docSidebarTitles = {
  comments: 'Comments',
  notifications: 'Activity',
  actionItems: 'Action Items',
  analytics: 'Analytics',
  contents: 'Contents',
}
