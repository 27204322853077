<script>
import { map } from 'ramda'
import { defineComponent, unref } from '@vue/composition-api'
import { useFeatureFlags } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'FeatureFlags',
  props: {
    flags: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const featureFlags = useFeatureFlags(props.flags)
    return () => context.slots.default(map(unref, featureFlags))
  },
})
</script>
