<template>
  <div
    v-if="document"
    class="absolute bg-black/80 rounded-md z-1
          filter backdrop-blur-md
          shadow-2xl transform translate-y-0
        "
    :class="[
      viewAs === 'smallGrid' && 'p-8 gap-8 inset-0 flex flex-row flex-wrap',
      viewAs === 'grid' && 'p-12 gap-12 inset-8 flex flex-col items-center justify-between'
    ]"
  >
    <div
      class="w-28 h-28 rounded-full bg-black/80 flex items-center justify-center
         outline-white/60 outline-2 outline
          absolute -right-14 -top-14 z-0
          hover:bg-black hover:outline-white hover:cursor-pointer
        "
      @click="$emit('discard')"
    >
      <span class="icon_v2-so_close text-18 text-white/80"></span>
    </div>
    <!-- {{ contentBlock.embeddedDocument$.color }} -->
    <!-- {{ contentBlock.embeddedDocument$.icon }} -->
    <div
      class="bg-white/20 rounded-md"
      :class="[
        viewAs === 'smallGrid' && 'flex-1 py-2 px-6 flex flex-col items-center',
        viewAs === 'grid' && 'w-full py-4 px-12 flex items-center'
      ]"
    >
      <!-- color -->
      <span class="text-12 text-white/80 text-left leading-none flex-1">Color:</span>
      <ColorPaletteItem
        v-model="state.color"
        v-b-tooltip.hover.top.v-info.dh0.ds200
        :presets="colorPresets"
        variant="pick"
        popover-placement="right"
        title="Change color"
        class="flex items-center included"
        :size="32"
        @input="color => (state.color = color)"
      />
    </div>
    <div
      class="bg-white/20 rounded-md"
      :class="[
        viewAs === 'smallGrid' && 'flex-1 py-2 px-6 flex flex-col items-center',
        viewAs === 'grid' && 'w-full py-4 px-12 flex items-center'
      ]"
    >
      <!-- icon -->
      <span class="text-12 text-white/80 text-left leading-none flex-1">Icon:</span>
      <DropdownIcons
        :target-id="`doc-icon-${document._id}`"
        :current-icon="state.icon"
        @pick-icon="val => state.icon = val"
      >
        <template #button="{open}">
          <button
            v-if="state.icon"
            :id="`doc-icon-${document._id}`"
            v-b-tooltip.hover.top.v-info.dh0.ds200
            type="button"
            class="edit-button-wrapper"
            title="Change icon"
            @click="open"
          >
            <span class="text-30 text-white" :class="state.icon || 'icon_v2-so_pencil'"></span>
          </button>
          <button
            v-else
            :id="`doc-icon-${document._id}`"
            v-b-tooltip.hover.top.v-info.dh0.ds200
            type="button"
            :class="[
              'w-32 h-32',
              'text-white bg-darkGray-800/60',
              'outline outline-2 outline-darkGray-800/80',
              'rounded-full relative inline-flex items-center',
              'justify-center transition-all',
            ]"
            title="Pick an icon"
            @click="open"
          >
            <span class="text-20 w-20 text-white material-add"></span>
          </button>
        </template>
      </DropdownIcons>
    </div>
    <div class="flex flex-col justify-end gap-12 w-full flex-grow basis-full">
      <b-button
        variant="neutral"
        size="sm"
        :disabled="isBusy"
        @click="saveTheme"
      >
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from '@vue/composition-api'
import { rejectNil } from 'ramda-extension'
import DropdownIcons from '@/components/DropdownIcons.vue'
import ColorPaletteItem from '@/components/ColorPaletteItem.vue'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useCurrentProject } from '@/v2/services/projects/compositions'
import useAsyncTask from '@/v2/lib/composition/useAsyncTask'

export default defineComponent({
  name: 'DocumentThemeEditor',
  components: {
    DropdownIcons,
    ColorPaletteItem,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    viewAs: {
      type: String,
      default: 'smallgrid',
    },
  },
  setup(props, context) {
    const organization = useCurrentOrganization()
    const project = useCurrentProject()
    const state = reactive({
      icon: props.document.icon,
      // color: props.document.color || props.document?.sharingClientEnabled
      //   ? project.value.portalAccentColor
      //   : project.value.portalSecondaryColor,
      color: props.document?.color ?? null,
    })

    const { isBusy, error, exec: saveDocument } = useAsyncTask(
      () => props.document.patch({ data: state })
    )
    const colorPresets = computed(() => rejectNil([
      project.value && (project.value?.client$?.mainColor ?? null) ? {
        label: 'Client color',
        colors: [{
          color: project.value?.client$?.mainColor,
          id: `client-color-${project.value?.client}`,
        }],
      } : null,
      project.value && project.value.brandingColors.length ? {
        label: 'Project Colors',
        colors: [
          project.value.portalAccentColor && { color: project.value.portalAccentColor },
          project.value.portalSecondaryColor && { color: project.value.portalSecondaryColor },
          project.value.portalHeaderBackgroundColor && {
            color: project.value.portalHeaderBackgroundColor,
          },
          ...project.value.brandingColors,
        ],
      } : null,
      organization.value.brandingColors.length ? {
        label: 'Organization Colors',
        colors: organization.value.brandingColors,
      } : null,
    ]))


    const saveTheme = async () => {
      await saveDocument()
      context.emit('save')
    }


    return {
      project,
      colorPresets,
      state,
      saveTheme,
      isBusy,
      error,
    }
  },
})
</script>

