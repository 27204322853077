<template>
  <VueMultiselect
    v-bind="$attrs"
    class="multiselect"
    v-on="$listeners"
  >
    <template #singleLabel="props">
      <slot name="singleLabel" v-bind="props" />
    </template>
    <template #option="props">
      <slot name="option" v-bind="props" />
    </template>
  </VueMultiselect>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
  name: 'Multiselect',
  components: {
    VueMultiselect,
  },
  setup(props, context) {
    return {

    }
  },
})
</script>
<style lang="postcss" scoped>
  .multiselect::v-deep {
    /* @apply min-h-38;

    .multiselect__tags {
      @apply min-h-38 p-8;
    }

    .multiselect__placeholder {
      @apply mb-0;
    } */

    .multiselect__single {
      @apply bg-transparent;
    }

    .multiselect__input {
      @apply bg-transparent;
    }

    &.multiselect--active {
      /* border: 1px solid var(--cp-accent);
      box-shadow:
        rgb(255, 255, 255) 0px 0px 0px 0px,
        rgb(35, 184, 57) 0px 0px 0px 1px,
        rgba(23, 148, 42, 0.3) 0px 0px 0px 4px; */
      @apply rounded-sm;
    }

    .multiselect__input {
      border-radius: 0;
      border: none;
      box-shadow: none;
    }

    .dark & {
      .multiselect__tags {
        @apply bg-darkGray-1000 border-darkGray-700;
      }
    }
  }
</style>
