
<div :class="[{ error: err, valid }, wrapperClass]">
  <label
    v-if="label"
    :for="labelFor"
    class="items-center mb-4 text-14 text-gray-500 dark:text-darkGray-400"
    :class="[labelClass, aside ? 'flex' : 'inline-flex']"
  >
    {{ label }}
    <span
      v-if="infoLabel"
      v-b-tooltip.hover.top.v-info.dh0.ds200="infoLabel"
      class="icon_v2-so_info-solid text-14 ml-4 text-gray-400 bg-transparent"
    ></span>
    <sup
      v-if="required"
      class="font-medium leading-none uppercase opacity-30 -top-2 text-12"
    >*</sup>
    <span v-if="aside" class="ml-auto opacity-50">{{ aside }}</span>
  </label>
  <slot v-bind="{ error: err, valid, pending, state }" />
  <span v-if="err" :class="[errorClass, 'text-error mb-4']">{{ message }}</span>
  <div
    v-if="pending && validationPendingMessage"
    :class="[validationPendingClass, 'text-14 mt-4 flex items-center text-green-600']"
  >
    <Spinner :size="14" /><span class="ml-8">{{ validationPendingMessage }}</span>
  </div>
  <b-form-text v-if="description" :class="descriptionClass">
    <template v-if="Array.isArray(description)">
      <p v-for="line in description" :key="line">{{ line }}</p>
    </template>
    <template v-else>{{ description }}</template>
  </b-form-text>
</div>
