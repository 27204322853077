
<transition-group
  v-show="notifications.length"
  class="space-y-2"
  name="notifications-list"
  tag="ul"
>
  <template v-for="(notification, index) in notifications">
    <li
      :key="notification._id"
      class="
        group transition-all
        dark:bg-opacity-10
      "
      :item-class="itemClass"
    >
      <!-- <div class="flex items-center"> -->
      <!-- <h1 class="text-32 mr-8">{{ index + 1 }}</h1> -->
      <NotificationListItem
        :notification="notification"
        @mark-as-read="notification => $emit('mark-as-read', notification)"
      />
      <!-- </div> -->
    </li>
  </template>
</transition-group>
