
<div>
  <button
    v-if="hasColor"
    ref="buttonRef"
    v-b-hover="handleHover"
    type="button"
    :class="[
      'btn-color',
      sizeMap[size].buttonSize,
      'rounded-full relative inline-flex items-center',
      'justify-center transition-all outline-none focus:outline-none',
    ]"
    :style="cssVars"
    @click="$emit('color-palette-select')"
  >
    <!-- v-b-tooltip.hover.bottom.v-info.dh0.ds200 -->
    <span
      :class="[
        'icon_v2-so_pencil transition-all invisible opacity-0',
        sizeMap[size].iconSize,
      ]"
    />
  </button>
  <!-- IF NO COLOR -->
  <button
    v-else
    ref="buttonRef"
    v-b-hover="handleHover"
    type="button"
    :class="[
      sizeMap[size].buttonSize,
      'text-white bg-darkGray-800/60',
      'outline outline-2 outline-darkGray-800/80',
      'rounded-full relative inline-flex items-center',
      'justify-center transition-all',
    ]"
    :style="cssVars"
    @click="$emit('color-palette-select')"
  >
    <!-- v-b-tooltip.hover.bottom.v-info.dh0.ds200 -->
    <span
      :class="[
        'material-add transition-all',
        sizeMap[size].iconSize,
      ]"
    />
  </button>
  <b-popover
    v-if="buttonRef"
    ref="popoverRef"
    :placement="popoverPlacement"
    :no-fade="noFade"
    :target="buttonRef"
    :triggers="triggers"
    :custom-class="
      [
        'popover-light popover-color-picker',
        'p-0 mt-12 w-280',
        'bg-gray border border-gray-200 rounded-md shadow-xl max-w-none',
      ].join(' ')
    "
  >
    <ColorPicker
      :value="value"
      click-outside
      @input="handleColorPick"
      @remove-color="$emit('remove-color')"
    />
    <!-- EDIT MODE -->
    <div
      v-if="variant === 'edit'"
      :class="[
        'flex justify-between p-16 border-t border-gray-100',
        'bg-white overflow-hidden rounded-b-md items-center',
      ]"
    >
      <b-link
        class="text-gray-700 underline hover:no-underline hover:text-gray-900"
        @click.stop="$emit('remove-color')"
      >
        Remove
      </b-link>
      <Button
        type="submit"
        variant="primary"
        class="w-116"
        @click="closePopover"
      >
        Save
      </Button>
    </div>

    <!-- PICK MODE -->
    <section v-if="variant === 'pick'" class="flex flex-col pb-16">
      <ColorPickerPresets
        :selected-value="value"
        :presets="presets"
        @pick-color-from-presets="color => $emit('input', color)"
      />
    </section>
  </b-popover>
</div>
