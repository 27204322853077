import Vue from 'vue'

const formatterFloat = new Intl.NumberFormat(navigator.language, {
  style: 'percent',
  minimumFractionDigits: 2,
})

const formatterInteger = new Intl.NumberFormat(navigator.language, {
  style: 'percent',
})

Vue.filter('percent', (value, integer = false) => (integer ? formatterInteger.format(value) : formatterFloat.format(value)))
