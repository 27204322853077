<template>
  <span>
    <time class="print:hidden">{{ displayValue }}</time>
    <time class="hidden print:inline">{{ value | dateTime }}</time>
  </span>
</template>

<script>
import config from '@/config'

export default {
  name: 'LiveFilter',
  props: {
    filter: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    interval: {
      type: Number,
      default: 60,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      timer: null,
      displayValue: '',
    }
  },
  watch: {
    value(newVal) {
      this.updateValue(newVal)
    },
  },
  created() {
    this.updateValue()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    stopTimer() {
      this.timer && clearTimeout(this.timer)
    },
    updateValue(val) {
      const i18n = this.$root.$i18n
      const filterLocale = this.locale || config.localesTimeago[i18n.locale]
      this.stopTimer()
      this.displayValue = this.$options.filters[this.filter](val || this.value, filterLocale)
      this.timer = setTimeout(this.updateValue.bind(this), this.interval * 1000)
    },
  },
}
</script>
