
<CheckMemberRole
  v-slot="{ hasRole: isProjectAdmin }"
  :roles="['admin', 'projectManager']"
>
  <LoaderWebFont
    :families="[
      project.brandingFontHeading || 'Inter',
      project.brandingFontParagraph || 'Inter',
    ]"
  >
    <div class="flex-1 flex flex-col">
      <ProjectTheme
        :project="project"
        no-dark-mode
        wrapper-class="flex-none"
      >
        <!-- ---------------------------------------------------------------------- -->
        <!-- Header -->
        <!-- ---------------------------------------------------------------------- -->
        <section
          class="relative header-background flex flex-col"
          :class="project.portalHeaderTheme"
        >
          <!-- Trigger for collapsing the top navigation -->
          <div
            class="absolute h-40 w-full top-0 z-1060 pointer-events-none"
          ></div>
          <div class="header-background-image"></div>
          <div
            class="
              container
              relative
              z-20
              flex flex-col
              items-center
              justify-center
              text-center
              pt-64
              flex-1
              grow
            "
          >
            <Greeting
              :is-portal-preview="isPortalPreview"
              :font-family="project.brandingFontHeading"
              header-class="
                portal-link-foreground
                px-24 font-bold leading-none text-24 2xl:text-42
                dark:opacity-95 mb-32
              "
            />
          </div>

          <!-- ---------------------------------------------------------------------- -->
          <!-- Quick Links -->
          <!-- ---------------------------------------------------------------------- -->
          <div
            v-if="projectModules.quickLinks.enabled"
            class="lg:mx-64 max-w-screen-lg self-center"
          >
            <ProjectQuickLinks
              :project="project"
              :can-manage="isProjectAdmin"
              :routes="{ settingsQuickLinks: routes.settingsQuickLinks }"
              @create="$emit('create-quick-link')"
            />
          </div>
          <div v-else class="mb-32" />
        </section>
      </ProjectTheme>

      <ProjectTheme
        :project="project"
        :wrapper-class="[
          'flex-1 pb-32',
          project?.portalTheme === 'dark'
            ? 'dark bg-darkGray-1000'
            : 'bg-page',
        ]"
      >
        <!-- ---------------------------------------------------------------------- -->
        <!-- Cards -->
        <!-- ---------------------------------------------------------------------- -->
        <DashboardCards>
          <!-- Docs -->
          <DocumentGet
            v-if="projectModules.documents.enabled"
            :id="project.publicFolder"
            v-slot="{
              document: publicFolder,
              isGetPending: isPublicFolderLoading,
            }"
            force
          >
            <DashboardFolderCard
              v-if="!isPublicFolderLoading"
              :document="publicFolder"
              :label="projectModules.documents.title || $t('modules.docs-and-files')"
              :tooltip="!isClientPortal ? 'This is what your client sees' : ''"
            />
            <!-- :hidden-children-ids="project.pinnedFolders" -->
            <DashboardAppCard v-else />
          </DocumentGet>

          <ProjectDashboardTaskSections
            :project="project"
            :route-tasks="routes.tasks"
          />

          <!-- Pinned Folders -->
          <Provide
            v-if="projectModules.pinnedFolders.enabled && project.pinnedFolders.length"
            :provide="{ isPinnedFolder: true }"
          >
            <FindByIds
              v-slot="{
                items: pinnedFolders,
                isFindPending: isPinnedFoldersPending,
              }"
              :ids="project.pinnedFolders"
              model="Document"
            >
              <template v-if="!isPinnedFoldersPending">
                <div v-fragment>
                  <DashboardFolderCard
                    v-for="folder in pinnedFolders"
                    :key="folder._id"
                    :document="folder"
                  />
                </div>
              </template>
            </FindByIds>
          </Provide>

          <!-- Packaged Services -->
          <DashboardAppCard
            v-if="projectModules.packagedServices.enabled"
            :label="projectModules.packagedServices.title || $t('modules.packaged-services')"
            :icon="projectModules.packagedServices.icon"
            :to="routes.packagedServices"
          >
            <FindByIds
              v-slot="{
                items: packagedServices,
                isFindPending: isPackagedServicesPending,
              }"
              :ids="project.packagedServices ?? []"
              model="Document"
            >
              <template v-if="!isPackagedServicesPending">
                <ul
                  v-if="packagedServices.length"
                  class="grid grid-cols-2 gap-8 mb-42"
                >
                  <li
                    v-for="packagedService in packagedServices"
                    :key="packagedService._id"
                    class="
                      flex
                      rounded-md
                      outline-1 outline outline-transparent
                      p-8
                      dark:bg-transparent
                      hover:outline-gray-300 hover:dark:bg-darkGray-900
                      dark:hover:outline-darkGray-700
                    "
                  >
                    <PackagedServiceCard
                      :document="packagedService"
                      width="w-full"
                    />
                  </li>
                </ul>
                <div
                  v-else
                  class="flex items-center justify-center flex-1 h-full grow"
                >
                  <p
                    class="
                      px-40
                      -mt-32
                      leading-relaxed
                      text-center text-gray-500 text-16
                      dark:text-darkGray-400
                      portal-paragraph-font
                    "
                  >
                    {{ $t('dashboard-cards.packaged-services.placeholder') }}
                  </p>
                </div>
              </template>
            </FindByIds>
          </DashboardAppCard>

          <!-- Extensions -->
          <template v-if="projectModules.embeddedApps.enabled">
            <DashboardToolCard
              v-for="app in project.portalLinkedApps"
              :key="app.id"
              :label="app.title"
              :icon="app.icon || 'icon_v2-so_apps'"
              :description="app.description"
              :to="{
                ...routes.linkedApp,
                params: { linkedAppId: app._id },
              }"
            />
          </template>
        </DashboardCards>
        <!-- Link to app settings - add card (not in portal) -->
        <div
          v-if="isProjectAdmin"
          class="
            transition-al
            mt-20
            mx-16
            sm:mx-auto
            max-w-screen-sm
            md:max-w-[90%]
            lg:max-w-[80%]
            xl:max-w-editor
            2xl:max-w-screen-lg
          "
        >
          <router-link
            v-slot="{ href, navigate }"
            :to="{ ...routes.settingsApps }"
            custom
          >
            <button
              v-b-tooltip.hover.top.v-info.dh0.ds200="
                `Add or edit embedded apps`
              "
              :href="href"
              class="
                w-auto
                flex
                items-center
                font-medium
                text-gray-500
                cursor-pointer
                text-14
                dark:text-darkGray-400
                portal-heading-font
                py-16
                md:py-0
                hover:text-gray-800
                dark:hover:text-darkGray-300
              "
              @click="navigate"
            >
              <span class="w-20 mr-4 text-20 icon_v2-so_setting" />
              Manage Embedded Apps
            </button>
          </router-link>
        </div>
        <!-- PROJECT UPDATES -->
        <div
          v-if="projectModules.timeline.enabled"
          id="timeline"
          class="
            mx-16
            sm:mx-auto
            mt-56
            max-w-screen-sm
            md:max-w-screen-md
            lg:max-w-page
            xl:max-w-editor
            2xl:max-w-screen-lg
          "
          data-intercom-target="Project Timeline"
        >
          <h2
            class="
              font-bold
              md:text-center
              text-gray-700 text-24
              md:text-42
              dark:text-darkGray-100
              portal-heading-font
            "
          >
            {{ projectModules.timeline.title || $t('modules.timeline') }}
          </h2>
          <ProjectTimeline :project-id="project._id" :page-size="25" />
        </div>
      </ProjectTheme>
    </div>
  </LoaderWebFont>
</CheckMemberRole>
