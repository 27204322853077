
<DocumentLink v-slot="{ navigate }" :document="notification.document$">
  <NotificationRenderer
    v-slot="{ options }"
    :notification="notification"
    icon-variant="author"
    badge-icon="icon_v2-so_tick"
    badge-class="bg-green-500"
    @click="navigate"
    @mark-as-read="notification => $emit('mark-as-read', notification)"
  >
    Comment <span class="font-semibold">{{ notification.subject$.message }}</span> resolved
    <template v-if="options.showDocument">
      on <NotificationLinkDocument :document="notification.document$" />
    </template>
  </NotificationRenderer>
</DocumentLink>
