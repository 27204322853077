
<div v-if="!(isEmpty && isLocked)">
  <!-- ^^^ Don't show if locked and empty -->
  <div
    class="relative"
  >
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="middle"
    />
    <div
      class="
        capitalize p-16 font-semibold text-18 inline-block
      text-gray-900 rounded-md bg-green hover:bg-green-100 hover:text-gray-900
        ring-1 ring-opacity-100 ring-green-200 focus:ring-1 focus:ring-green-200
        focus:ring-opacity-100 hover:ring-2 hover:ring-green-600 hover:ring-opacity-40
        hover:cursor-pointer portal-paragraph-font
      "
    >
      <TaskDatePicker
        v-model="model"
        clearable
        :placeholder="$t('blocks.qa.date-choose')"
        no-input
        :readonly="isLocked"
      />
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
