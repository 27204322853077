<template>
  <div class="flex items-center gap-x-16">
    <DropdownSelect
      v-model="sortBy"
      :icon="sortByIcons[sortBy]"
      :options="optionsSortBy"
      class-name="dark:border-darkGray-700 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
    <DropdownSelect
      v-model="viewAs"
      :icon="viewAsIcons[viewAs]"
      :options="optionsViewAs"
      class-name="dark:border-darkGray-700 dark:text-darkGray-400 dark:hover:text-darkGray-300"
    />
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import useLocalization from '@/v2/lib/composition/useLocalization'
import DropdownSelect from '@/components/DropdownSelect.vue'

const viewAsIcons = {
  grid: 'icon_v2-so_grid',
  smallGrid: 'icon_v2-so_grid-small',
  list: 'icon_v2-so_list-view',
}

const sortByIcons = {
  unsorted: 'icon_v2-so_unsort',
  title: 'icon_v2-so_sort-az',
  updatedAt: 'icon_v2-so_sort-arrow-down',
}

export default defineComponent({
  name: 'DocFolderViewOptions',
  components: {
    DropdownSelect,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        sortBy: 'unsorted',
        viewAs: 'grid',
      }),
    },
  },
  setup(props, context) {
    // TRANSLATED STRINGS
    const { translate } = useLocalization()

    const optionsSortBy = [
      {
        text: translate('sorting.unsorted').value,
        icon: 'unsort',
        value: 'unsorted',
      },
      {
        text: translate('sorting.name').value,
        icon: 'sort-az',
        value: 'title',
      },
      {
        text: translate('sorting.date-modified').value,
        icon: 'sort-arrow-down',
        value: 'updatedAt',
      },
    ]

    const optionsViewAs = [
      {
        text: translate('sorting.large-grid').value,
        icon: 'grid',
        value: 'grid',
      },

      {
        text: translate('sorting.small-grid').value,
        icon: 'grid-small',
        value: 'smallGrid',

      },
      {
        text: translate('sorting.list').value,
        icon: 'list-view',
        value: 'list',

      },
    ]

    const update = (key, value) => {
      context.emit('input', { ...props.value, [key]: value })
    }

    const sortBy = computed({
      get() {
        return props.value.sortBy
      },
      set(value) {
        update('sortBy', value)
      },
    })

    const viewAs = computed({
      get() {
        return props.value.viewAs
      },
      set(value) {
        update('viewAs', value)
      },
    })

    return {
      sortBy,
      optionsSortBy,
      sortByIcons,

      viewAs,
      optionsViewAs,
      viewAsIcons,

    }
  },
})
</script>
