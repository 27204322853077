import { CATEGORY, STATUS as DOCUMENT_STATUS } from '@/v2/services/documents/documentsTypes'

export default {
  [DOCUMENT_STATUS.NO_STATUS]: 'No Status',
  [DOCUMENT_STATUS.DRAFT]: 'No Status',
  [DOCUMENT_STATUS.IN_PROGRESS]: 'In Progress',
  [DOCUMENT_STATUS.IN_REVIEW]: 'In Review',
  [DOCUMENT_STATUS.APPROVED]: 'Approved',
  [DOCUMENT_STATUS.POSTPONED]: 'Postponed',
  [DOCUMENT_STATUS.DELIVERY_ACCEPTED]: 'Delivery Accepted',

  [CATEGORY.DocumentCustom]: 'Freeform Document',
  [CATEGORY.DocumentBrief]: 'Brief',
  [CATEGORY.DocumentEstimate]: 'Estimate',
  [CATEGORY.DocumentProposal]: 'Proposal',
  [CATEGORY.DocumentCaseStudy]: 'Case Study',
  [CATEGORY.DocumentPackagedService]: 'Packaged Service',
  [CATEGORY.DocumentPage]: 'Page',
  [CATEGORY.DocumentTeamBio]: 'Team Bio',
  [CATEGORY.DocumentReusableBlock]: 'Reusable Block',
  [CATEGORY.DocumentContract]: 'Contract',
  [CATEGORY.DocumentStatementOfWork]: 'Statement Of Work',
  [CATEGORY.DocumentChangeRequest]: 'Change Request',
  [CATEGORY.DocumentClientSignOff]: 'Client Sign-Off',
  [CATEGORY.DocumentProgressUpdate]: 'Progress Update',
  [CATEGORY.DocumentKnowledgeBase]: 'Knowledge Base',
}
