export const COMMAND = {
  REGISTER: 'REGISTER',
  /** Sign up using 3rd party OAuth provider, e.g. Google */
  REGISTER_OAUTH: 'REGISTER_OAUTH',
  REGISTER_INVITED_MEMBER: 'REGISTER_INVITED_MEMBER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  /** Creates a request for registering a clinet-contact-type user */
  REGISTER_CLIENT: 'REGISTER_CLIENT_CONTACT',
  /** Creates a request for re-sending an email with a magic link for a client contact to login
   * to the Client Portal without a password */
  RESEND_LINK: 'RESEND_MAGIC_AUTH_LINK',
  /** Registers a new user which obtained a license through AppSumo
   * @type {'REGISTER_SUMOLING'} */
  REGISTER_SUMOLING: 'REGISTER_SUMOLING',
  /** Initiates an account deletion process. If the user owns any organizations, they will be
   * completely removed along with all other members & content.
   * @type {'DELETE_ACCOUNT'} */
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  CREATE_AUTH_CLIENT: 'CREATE_AUTH_CLIENT',
};
