<template>
  <SidebarTree
    :node="projectTreeModel"
    item-class="so-sidebar-item"
    active-item-class="so-sidebar-item-active"
    :selected="value"
    :hide-empty-nodes="false"
    :expand-root="expandRoot"
  >
    <template #folder="{ node }">
      <ButtonTransparent @click="selectNode(node)">
        <DocumentComputedData v-slot="{ title, icon }" :document="node.data.document">
          <SidebarItemBasic :icon="icon" :label="title" />
        </DocumentComputedData>
      </ButtonTransparent>
    </template>
  </SidebarTree>
</template>
<script>
import { defineComponent, toRefs } from '@vue/composition-api'
import useTreeProjectFolders from '@/v2/lib/composition/useTreeProjectFolders'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import SidebarTree from '@/components/Sidebar/SidebarTree.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'ProjectFolderTree',
  components: {
    SidebarTree,
    SidebarItemBasic,
    ButtonTransparent,
    DocumentComputedData,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    folderId: {
      type: String,
      default: null,
    },
    folderCategory: {
      type: String,
      default: null,
    },
    hiddenFolderIds: {
      type: Array,
      default: () => [],
    },
    hiddenFolderCategories: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    overwritesByCategory: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    expandRoot: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const {
      projectId,
      folderId,
      folderCategory,
      hiddenFolderIds,
      hiddenFolderCategories,
      overwritesByCategory,
    } = toRefs(props)

    const projectTreeModel = useTreeProjectFolders({
      projectId,
      folderId,
      folderCategory,
      hiddenFolderIds,
      hiddenFolderCategories,
      overwritesByCategory,
    });

    const selectNode = node => {
      context.emit('input', node.id)
    }

    return {
      projectTreeModel,
      selectNode,
    }
  },
})
</script>
