import { rejectNil } from 'ramda-extension'
import { computed, inject, unref } from '@vue/composition-api'
import { reqCtxNs } from '@/v2/lib/hooks/requestContextForServer'
import { useRouter, useRouteParams, useRouteQuery } from '@/v2/lib/composition/useRouter'
import { GUEST_EMBEDDED_DOCUMENT } from '@/router/guest/type'
import routeBuildersEditor from './routeBuildersEditor'
import routeBuildersPortal from './routeBuildersPortal'

/**
 * @typedef {import('vue-router').Location} RouterLocation
 */

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

// editor route builder
export const documentRouteParams = ({ document, isPinnedFolder, isPage }) => {
  const builder = routeBuildersEditor[document.category]
  return builder({ document, isPinnedFolder, isPage })
}

// portal route builder
export const documentClientPortalRouteParams = ({
  document,
  requestContext,
  folderId,
  isPinnedFolder,
  isPage,
}) => {
  const builder = routeBuildersPortal[document.category]
  return builder({ document, requestContext, folderId, isPinnedFolder, isPage });
}

const buildRequestContext = ({ document, grant, reqCtxToken, isClientPortal }) => {
  if (!isClientPortal || !document) {
    return null
  }

  const reqCtxDocument = document.sharingClientEnabled && !document.isFolder
    // if the linked doc is shared, use it as requestContext.document
    // as the nearest shared ancestor for the subsequent private embbeded docs
    ? document._id

    // if the linked doc is private (and accessed through a shared ancestor),
    // use the current route param for requestContext.document
    : grant

  return rejectNil({
    token: reqCtxToken,
    document: reqCtxDocument,
  })
};

const buildTo = ({
  document,
  grant,
  reqCtxToken,
  isClientPortal,
  isPublicLink,
  isPinnedFolder,
  isPage,
  folderId,
}) => {
  if (!document) {
    return null
  }

  if (isPublicLink) {
    // Navigating to any doc from a public link whould be performed by keeping initial token
    // in the URL so that it can be sent via `requestContext` to the backend
    return {
      name: GUEST_EMBEDDED_DOCUMENT,
      params: {
        documentId: document._id,
      },
    }
  }

  if (isClientPortal) {
    const requestContext = buildRequestContext({
      document,
      isClientPortal,
      grant,
      reqCtxToken,
    })

    return documentClientPortalRouteParams({
      document,
      requestContext,
      folderId,
      isPinnedFolder,
      isPage
    })
  }

  return documentRouteParams({ document, isPinnedFolder, isPage })
};

export function useDocumentOpen() {
  const isClientPortal = inject('isClientPortal', false)
  const isPublicLink = inject('isPublicLink', false)
  const isPinnedFolder = inject('isPinnedFolder', false)

  const { routerPush } = useRouter()

  const { [reqCtxNs('token')]: reqCtxToken, folderId } = useRouteParams([
    [reqCtxNs('token')],
    'folderId',
  ])

  const { grant } = useRouteQuery(['grant'])

  return document => routerPush(buildTo({
    document: unref(document),
    grant: unref(grant),
    reqCtxToken: unref(reqCtxToken),
    isClientPortal,
    isPublicLink,
    isPinnedFolder: unref(isPinnedFolder),
    folderId: unref(folderId),
  }))
}

/**
 * @typedef {Object} Options
 * @property {boolean} isPinnedFolder
 */

/**
 * Constructs a Router router location object
 * @param {Ref<Object>} document
 * @param {Options} options
 * @returns {Ref<RouterLocation>} Router location object
 */
function useDocumentLink(document, options = {}) {
  const isPage = inject('isPage', false)
  const isClientPortal = inject('isClientPortal', false)
  const isPublicLink = inject('isPublicLink', false)
  const isPinnedFolder = inject('isPinnedFolder', false)

  const { [reqCtxNs('token')]: reqCtxToken, folderId } = useRouteParams([
    [reqCtxNs('token')],
    'folderId',
  ])

  const { grant } = useRouteQuery(['grant'])

  const to = computed(() => buildTo({
    document: document.value,
    grant: grant.value,
    reqCtxToken: reqCtxToken.value,
    isClientPortal,
    isPublicLink,
    isPage,
    isPinnedFolder: options.isPinnedFolder ?? isPinnedFolder.value,
    folderId: folderId.value,
  }))

  return to
}

export default useDocumentLink
