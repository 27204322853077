
<div class="flex items-center justify-between py-4 mb-4">
  <label
    v-b-tooltip.hover.left.v-info.dh0.ds200="tooltip ? `${tooltip}` : ''"
    :for="componentID"
    class="flex-1 block text-gray-600 cursor-pointer text-14 hover:text-gray-800"
  >{{ label }}</label>
  <b-form-checkbox
    :id="componentID"
    :checked="value"
    switch
    size="lg"
    class="flex custom-switch-qa"
    v-bind="$attrs"
    @input="value => $emit('input', value)"
  />
</div>
