<template>
  <LoaderWebFonts
    :families="[
      organization?.brandingFontHeading ?? 'Inter',
      organization?.brandingFontParagraph ?? 'Inter'
    ]"
  >
    <div :style="cssVars">
      <slot v-bind="{ portalCustomizations }" />
    </div>
  </LoaderWebFonts>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import tinycolor from 'tinycolor2'
import { optimizedImageUrl } from '@/v2/services/uploads/uploadsCompositions'
import LoaderWebFonts from '@/components/LoaderWebFonts.vue'

export default defineComponent({
  name: 'ClientPortalLoginTheme',
  components: {
    LoaderWebFonts,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    /** Used to overwrite any portal customization set on the `organization` prop. */
    overrides: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const portalCustomizations = computed(
      () => props.overrides ?? props.organization.portalLoginCustomizations
    )

    const cssVars = computed(() => ({
      '--cp-background': portalCustomizations.value.backgroundColor,
      '--cp-background-image-url': `url(${optimizedImageUrl(portalCustomizations.value.backgroundImage)})`,
      '--cp-background-image-opacity': portalCustomizations.value.backgroundImageOpacity / 100,
      '--cp-button-color': portalCustomizations.value.loginButtonColor,
      '--cp-text-color': portalCustomizations.value.loginButtonTextColor,
      '--cp-accent': tinycolor(portalCustomizations.value.loginButtonColor).toRgbString(),
      '--cp-accent-rgb': `
      ${tinycolor(portalCustomizations.value.loginButtonColor).toRgb().r},
      ${tinycolor(portalCustomizations.value.loginButtonColor).toRgb().g},
      ${tinycolor(portalCustomizations.value.loginButtonColor).toRgb().b}`,
      '--cp-heading-font': props.organization.brandingFontHeading || 'Inter',
      '--cp-paragraph-font': props.organization.brandingFontParagraph || 'Inter',
    }))

    return {
      cssVars,
      portalCustomizations,
    }
  },
})
</script>
