<template>
  <router-link
    v-slot="{ href, navigate, isExactActive, isActive }"
    :to="to"
    custom
  >
    <li
      class="group"
      :class="
        ((activateOnExactRoute && isExactActive)
          || (activateOnChildRoute && isActive)
          || isActiveOtherRoutes
        ) ? activeItemClass : itemClass
      "
    >
      <a
        class="no-hover block w-full"
        :href="href"
        @click="navigate"
      >
        <slot />
      </a>
    </li>
  </router-link>
</template>
<script>
import { useRoute } from '@/v2/lib/composition/useRouter'
import { computed, defineComponent, inject } from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarMenuLink',
  props: {
    to: {
      type: Object,
      default: null,
    },
    activateOnExactRoute: {
      type: Boolean,
      default: true,
    },
    activateOnChildRoute: {
      type: Boolean,
      default: false,
    },
    activateOnRoutes: {
      type: Array,
      default: null,
    },
    activateOnParentRoutes: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const itemClass = inject('itemClass', null)
    const activeItemClass = inject('activeItemClass', null)
    const iconClass = inject('iconClass', null)
    const { routeIs, routeIsChildOf } = useRoute()

    const isActiveOtherRoutes = computed(
      () => props.activateOnRoutes?.some(r => routeIs(r))
        || props.activateOnParentRoutes?.some(r => routeIsChildOf(r))
    )

    return {
      itemClass,
      activeItemClass,
      iconClass,
      isActiveOtherRoutes,
    }
  },
})
</script>
<style scoped lang="postcss">
  .no-hover:hover {
    color: inherit;
  }
</style>
