<template>
  <span
    :id="buttonId || componentID"
    v-bind="$attrs"
    :class="[
      $style['button-wrapper'],
      isActive && !disabled && $style['active-button'],
      disabled && $style['disabled-button'],
      variant === 'dark'
        ? $style['button-wrapper-dark']
        : $style['button-wrapper-light']
    ]"
    @click="$emit('click')"
  >
    <slot />
    <span v-if="hasDropdown" :class="[$style['toolbar-dropdown']]"></span>
  </span>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasDropdown: {
      type: Boolean,
      default: false,
    },
    buttonId: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'dark',
    },
  },
}
</script>
<style lang="postcss" module>
.button-wrapper {
  @apply h-32 w-32 rounded relative text-white;
  @apply flex justify-center items-center;
  @apply hover:bg-gray-500 hover:bg-opacity-10 cursor-pointer;
}

.button-wrapper-dark {
  @apply text-white hover:bg-gray-500 hover:bg-opacity-10;
}

.button-wrapper-light {
  @apply text-gray-600 hover:bg-green-100 hover:text-green-900;
}

.active-button {
  @apply bg-green-500 hover:bg-green-600 hover:bg-opacity-100;
}

.disabled-button {
  @apply cursor-not-allowed opacity-25 hover:bg-transparent;
}

.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}

</style>
