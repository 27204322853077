import { isNil } from 'ramda'
import { computed } from '@vue/composition-api'
import { usePaymentPlan } from '../../paymentPlans/compositions'
import { SUBSCRIPTION_STATUS as STATUS, LICENSING_PROVIDER } from '../organizationsTypes'
import useCurrentOrganization from './useCurrentOrganization'

const checkRunningSubscription = status => [
  STATUS.active,
  STATUS.inTrial,
  STATUS.nonRenewing,
].includes(status)

const flags = {
  [LICENSING_PROVIDER.chargebee]: ({ status, planId }) => ({
    isTrial: status === STATUS.inTrial,
    isNonRenewing: status === STATUS.nonRenewing,
    isCancelled: status === STATUS.cancelled,
    isActive: status === STATUS.active,
    isRunning: checkRunningSubscription(status),
    isFree: planId.includes('freemium'),
    // isManageable: true,
  }),
  [LICENSING_PROVIDER.appsumo]: () => ({
    // isManageable: true,
    isTrial: false,
    isNonRenewing: false,
    isCancelled: false,
    isActive: true,
    isRunning: true,
    isFree: false,
  }),
  [LICENSING_PROVIDER.superokay]: () => ({
    // isManageable: false,
    isTrial: false,
    isNonRenewing: false,
    isCancelled: false,
    isActive: true,
    isRunning: true,
    isFree: false,
  }),
}

export default function useLicensing() {
  const organization = useCurrentOrganization()

  const licensing = computed(() => {
    const { licensing: orgLicensing } = organization.value ?? {}

    if (!orgLicensing?.provider) {
      return null
    }

    const makeFlags = flags[orgLicensing.provider]
    return { ...orgLicensing, ...makeFlags(orgLicensing) }
  })

  const planId = computed(() => licensing.value?.planId ?? null)
  const { item: plan, isPending: isPlanPending } = usePaymentPlan(planId)
  const isPending = computed(() => isPlanPending.value || isNil(licensing.value))

  return { licensing, plan, isPending }
}
