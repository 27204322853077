<template>
  <DocumentNodeRenderer
    :node="node"
    :config-override="{
      stylingToolbar: {
        features: {
          objectAlign: true,
          fontFamily: false,
          textColor: false,
        },
      },
      styling: {
        size: 'S',
        objectAlign: 'center',
      },
    }"
  >
    <template #default="{ styling, textVariant, isHover }">
      <DocumentContentBlockButton
        :content-block="contentBlock"
        :node-id="node._id"
        :styling="styling"
        :is-focus="isFocus"
        :is-hover="isHover"
        :text-variant="textVariant"
        @update="(data, options) => $emit('update', data, options)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot
        name="editor"
        v-bind="{ config, styling, textVariant }"
      />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockButton from './DocumentContentBlockButton.vue'

export default {
  name: 'DocumentNodeButton',
  components: {
    DocumentContentBlockButton,
    DocumentNodeRenderer,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    isFocus: Boolean,
  },
}
</script>
