<template>
  <DocumentNodeRenderer :node="node">
    <template #default="{ styling, textVariant, nodeWidth }">
      <DocumentContentBlockCostBreakdown
        :document="document"
        :content-block="contentBlock"
        :node-id="node._id"
        :styling="styling"
        :text-variant="textVariant"
        :node-width="nodeWidth"
        @update="(data, options) => $emit('update', data, options)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { inject } from '@vue/composition-api'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockCostBreakdown from './DocumentContentBlockCostBreakdown.vue'

export default {
  name: 'DocumentNodeCostBreakdown',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockCostBreakdown,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },

  },
  setup() {
    const document = inject('document')
    return {
      document,
    }
  },
}
</script>
