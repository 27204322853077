import { test } from 'ramda'
import { headOr } from '@/v2/lib/helpers/fp'

const isIP = test(/^\d{1,3}\.\d{1,3}.\d{1,3}.\d{1,3}$/)

export default host => {
  const parts = host.split('.')

  if (parts.length === 1 || isIP(host)) {
    return {
      subdomain: null,
      subdomains: [],
      domain: host,
      tld: null,
      rootDomain: host,
    }
  }

  const tld = parts.pop()
  const domain = parts.pop()
  const subdomains = parts
  const subdomain = headOr(null, subdomains)

  return {
    subdomain,
    subdomains,
    tld,
    domain,
    rootDomain: `${domain}.${tld}`,
  }
}
