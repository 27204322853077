<script>
import { useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'DocumentContentLoader',
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { DocumentStructure, DocumentNode } = context.root.$FeathersVuex.api

    // -- nodes
    const findNodesParams = computed(() => ({
      temps: true,
      query: { document: props.document._id },
    }))

    const { isPending: isFindNodesPending } = useFind({
      model: DocumentNode,
      params: findNodesParams,
    })

    // -- structure
    const structureId = computed(() => props.document.structure)

    const { isPending: isGetStructurePending } = useGet({
      id: structureId,
      model: DocumentStructure,
    })

    const isLoading = computed(
      () => isFindNodesPending.value || isGetStructurePending.value
    )

    return () => context.slots.default({ isLoading: isLoading.value })
  },
}
</script>
