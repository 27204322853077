export const ERROR_CODES = {
  GENERIC: 'GENERIC',
  INVALID_TOKEN: 'INVALID_TOKEN',
}

export const MESSAGES = {
  [ERROR_CODES.GENERIC]: 'An error has occurred',
  [ERROR_CODES.INVALID_TOKEN]: 'Invalid token',
}

export class ErrorEx extends Error {
  constructor(code = ERROR_CODES.GENERIC, message) {
    super(message || MESSAGES[code])

    this.name = 'ErrorEx'
    this.code = code
  }

  toObject() {
    return {
      name: this.name,
      code: this.code,
      message: this.message,
    }
  }

  toString() {
    return `Error: ${this.message} Code: ${this.code}`
  }
}
