<template>
  <FeathersVuexGet
    :id="id"
    v-slot="{ item: organization, isGetPending }"
    watch="id"
    :query-when="force || notInStore"
    service="organizations"
    v-bind="$attrs"
  >
    <slot v-bind="{ organization, isGetPending }" />
  </FeathersVuexGet>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OrganizationGet',
  props: {
    id: {
      type: String,
      required: true,
    },
    force: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { Organization } = context.root.$FeathersVuex.api
    const notInStore = computed(() => !Organization.getFromStore(props.id))

    return {
      notInStore,
    }
  },
})
</script>
