<template>
  <!-- ------------------------------ -->
  <!-- META INFO -------------------- -->
  <!-- ------------------------------ -->
  <div
    class="
      text-gray-700 leading-none dark:text-darkGray-500
      inline-flex items-center gap-4
    "
  >
    <!-- Author of notification -->
    <span v-if="authorCount" class="capitalize">
      {{ authorCount }} {{ $tc('common.authors', authorCount) }} •
    </span>
    <span v-else-if="authorName">{{ authorName }} • </span>

    <!-- Project -->
    <template v-if="showProject && project$">
      <router-link
        :to="{
          name: PROJECT,
          params: {
            organizationId: notification.organization,
            projectId: notification.project,
          },
        }"
        @click.native="$event.stopImmediatePropagation()"
      >
        {{ project$.name }}
      </router-link>
      <span>•</span>
    </template>

    <!-- Date of notification -->
    <span
      v-b-tooltip="{
        placement: 'top',
        trigger: 'hover',
        variant: 'info',
        delay: { show: 200, hide: 0 },
        title: $options.filters.dateTime(notification.time),
      }"
    >
      <LiveFilter
        filter="timeago"
        locale="en-US"
        :value="notification.time"
      />
    </span>
    <b-badge
      v-if="showClientInfo && clientInfo"
      variant="secondary"
      class="font-light capitalize"
    >
      {{ clientInfo }}
    </b-badge>
  </div>
</template>

<script>
import { defineComponent, computed, toRef } from '@vue/composition-api'
import { pipe, prop, filter, join, juxt } from 'ramda'
import { isNonEmptyString, isNotNil } from 'ramda-adjunct'
import { PROJECT } from '@/router/project/type'
import { useNotificationAuthor } from '@/v2/services/notifications/compositions'
import LiveFilter from '@/components/LiveFilter.vue'

const joinClientInfo = pipe(
  prop('clientGeoIp'),
  juxt([prop('city'), prop('country')]),
  filter(isNonEmptyString),
  join(', ')
)

export default defineComponent({
  name: 'NotificationRendererMetaInfo',
  components: {
    LiveFilter,
  },
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
    project: {
      type: Object,
      default: () => {},
    },
    showProject: {
      type: Boolean,
      default: true,
    },
    showClientInfo: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { authorName } = useNotificationAuthor(toRef(props, 'notification'))
    const isGroup = computed(() => Array.isArray(props.notification.events))

    const authorCount = computed(() => {
      if (!isGroup.value) {
        return 0;
      }

      const authorIds = props.notification.events.map(({ author }) => author) ?? []
      return new Set(authorIds.filter(isNotNil)).size
    })

    const project$ = computed(() => props.notification.project$ ?? props.project)

    const clientInfo = computed(() => joinClientInfo(props.notification))

    return {
      authorName,
      authorCount,
      isGroup,
      clientInfo,
      project$,
      PROJECT,
    }
  },
})
</script>
