<template>
  <NotificationDocument v-slot="{ document }" :notification="notification">
    <DocumentLink v-slot="{ navigate }" :document="document">
      <NotificationRenderer
        v-slot="{ options }"
        :notification="notification"
        icon-variant="author"
        badge-icon="icon_v2-so_tick"
        badge-class="bg-green-500"
        @click="navigate"
        @mark-as-read="notification => $emit('mark-as-read', notification)"
      >
        Approved <NotificationLinkDocument :document="document" />
        <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
      </NotificationRenderer>
    </DocumentLink>
  </NotificationDocument>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationDocument from './NotificationDocument.vue'

export default defineComponent({
  name: 'NotifDocumentsApproved',
  components: {
    DocumentLink,
    NotificationRenderer,
    NotificationLinkDocument,
    NotificationDocument,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
})
</script>
