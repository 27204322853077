import { remove, append, adjust, set, lensProp } from 'ramda'
import { getCurrentInstance } from '@vue/composition-api'
import { alwaysEmptyObject } from 'ramda-extension'

export default (makeNewEntry = alwaysEmptyObject) => {
  const vm = getCurrentInstance().proxy

  const create = (data = null) => {
    const newVal = append(data || makeNewEntry(), vm.value)
    vm.$emit('input', newVal)
  }

  const del = index => {
    const newVal = remove(index, 1, vm.value)
    vm.$emit('input', newVal)
  }

  const update = ({ index, key, value }) => {
    const newVal = adjust(index, set(lensProp(key), value), vm.value)
    vm.$emit('input', newVal)
  }

  const reorder = newVal => {
    vm.$emit('input', newVal)
  }

  return {
    create,
    update,
    del,
    reorder,
  }
}
