import { useMsgBoxError } from '@/v2/lib/composition/useMsgBox'

export default function useClipboard() {
  const msgBoxErr = useMsgBoxError()

  const checkPermissions = async () => {
    const result = await navigator.permissions.query({ name: 'clipboard-write' })
      .catch(() => ({ state: 'granted' }));

    return (result.state === 'granted' || result.state === 'prompt');
  }

  const writeText = async text => {
    const hasPermissions = await checkPermissions();

    if (hasPermissions) {
      await navigator.clipboard.writeText(text);
      return true;
    }

    await msgBoxErr({
      title: 'Clipboard Write Permissions Required',
      message: "Please grant your browser clipboard write permissions to proceed. This is necessary for the application to perform copy operations. You can usually enable this by adjusting your browser settings or granting the necessary permissions when prompted. If you need assistance, refer to your browser's documentation or contact support for further guidance.",
    })

    return false;
  }

  return {
    writeText,
  }
}
