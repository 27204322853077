<template>
  <router-link
    v-slot="{ navigate, href }"
    custom
    :to="to"
  >
    <slot v-bind="{ navigate, href }" />
  </router-link>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useActionItemLink } from '@/v2/services/actionItems/compositions'

export default defineComponent({
  name: 'NotificationLinkActionItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const to = useActionItemLink(computed(() => props.notification?.subject$))

    return {
      to,
    }
  },
})
</script>
