import Vue from 'vue'
import { prop, indexBy, move, pluck, pick } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { computed, unref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import useOptimisticUpdate from '@/v2/lib/composition/useOptimisticUpdate'
import { useUser } from '@/v2/services/users/usersCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'
import { filterTasks, groupTasksByStatus, sortByDueDate } from './useProjectTasks'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {Object} Options
 * @property {Record<string, string>} [viewContext]
 * @property {string} [keyword]
 * @property {Object} [documentsQuery]
 */

const propId = prop('_id')
const indexById = indexBy(propId)
const pluckId = pluck('_id')
const documentProps = pick(['_id', 'title'])

/**
 * @param {Ref<import('../projects').ProjectData} project
 * @param {Ref<Options>} [options]
 */
export default function useDocumentsTasks(project, options) {
  const { t } = useLocalization()
  const { update: optimisticUpdate } = useOptimisticUpdate()
  const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
  const { user } = useUser()

  const { ActionItem, Document } = Vue.$FeathersVuex.api

  // docs with action items
  const paramsDocuments = computed(() => ({
    query: {
      $sort: { title: 1 },
      project: unref(project)._id,
      ...unref(options)?.documentsQuery,
    },
  }))

  const { items: documents, isPending } = useFind({
    model: Document,
    params: paramsDocuments,
  })

  const taskIds = computed(
    () => documents.value.flatMap(({ tasks }) => tasks)
  )

  // action items
  const paramsTasks = computed(() => ({
    query: {
      project: unref(project)._id,
      _id: { $in: taskIds.value },
    },
  }))

  const { items: tasks } = useFind({
    model: ActionItem,
    params: paramsTasks,
  })

  const filteredTasks = computed(() => {
    const { viewContext = null, keyword = '' } = unref(options) ?? {}
    return filterTasks(tasks.value, {
      user: user.value,
      filterBy: viewContext?.filterBy,
      keyword,
    })
  });

  const indexedTasks = computed(() => indexById(filteredTasks.value))

  const sections = computed(
    () => documents.value.map(document => {
      const _tasks = rejectNil(document.tasks.map(
        taskId => indexedTasks.value?.[taskId] ?? null
      ))

      const taskSection = {
        ...documentProps(document),
        tasks: document.tasks,
      }

      return { taskSection, tasks: _tasks, document }
    })
  )

  const removeTask = async (section, task) => {
    const ok = await msgBoxConfirmDelete({
      title: t('tasks.dialogs.deleteTask.title', { title: task.title }).value,
      message: t('tasks.dialogs.deleteTask.message').value,
      size: 'md',
    })

    if (!ok) {
      return
    }

    await task.remove()
  }


  const updateTask = (taskId, data) => {
    const actionItem = ActionItem.getFromStore(taskId)
    optimisticUpdate(actionItem, data)
  }

  const moveTask = params => {
    const { /* task,  */oldSection, oldIndex, newIndex } = params

    const { document } = oldSection

    const updatedTaskIds = move(oldIndex, newIndex, document.tasks);
    optimisticUpdate(document, { tasks: updatedTaskIds })
  }

  const reorderTasks = (section, reorderedTasks) => {
    const { document } = section
    optimisticUpdate(document, { tasks: pluckId(reorderedTasks) })
  }

  const moveResolvedTasksToBottom = section => {
    const { document } = section
    const { open, resolved } = groupTasksByStatus(section)

    const updatedTaskIds = [...pluckId(open), ...pluckId(resolved)]

    optimisticUpdate(document, { tasks: updatedTaskIds })
  }

  const movePastDueTasksToTop = section => {
    const { document } = section
    const { due, notDue } = groupTasksByStatus(section)

    const orderedDue = sortByDueDate(due)
    const updatedTaskIds = [...pluckId(orderedDue), ...pluckId(notDue)]

    optimisticUpdate(document, { tasks: updatedTaskIds })
  }


  const deleteResolvedTasks = async section => {
    const { document } = section
    const { resolved } = groupTasksByStatus(section)

    const ok = await msgBoxConfirmDelete({
      // title: translate('action-items.delete').value,
      // message: translate('action-items.delete-confirm').value,
      title: `Delete resolved tasks from "${document.title}" ?`,
      message: 'Are you sure you want to delete all resolved tasks? This action is irreversible.',
      size: 'md',
    })

    if (!ok) {
      return
    }

    Promise.all(resolved.map(task => task.remove()))
  }

  const handleSectionMenuAction = (section, action) => {
    switch (action) {
      case 'moveResolvedToBottom':
        moveResolvedTasksToBottom(section)
        break
      case 'movePastDueToTop':
        movePastDueTasksToTop(section)
        break
      case 'deleteResolved':
        deleteResolvedTasks(section)
        break
      default:
        break
    }
  }

  return {
    sections,
    isPending,
    handleSectionMenuAction,

    // -- tasks
    // createTask,
    removeTask,
    updateTask,
    moveTask,
    reorderTasks,
    moveResolvedTasksToBottom,
    movePastDueTasksToTop,
    deleteResolvedTasks,
  }
}
