import { CATEGORY } from './contentBlocksTypes'

export default () => ({
  common: [
    'category',
    'document',
  ],
  [CATEGORY.ElementText]: [
    'content',
  ],
  [CATEGORY.ElementImage]: [
    'url',
    'width',
  ],
  [CATEGORY.ElementHeading]: [
    'heading',
  ],
  [CATEGORY.ElementTeamBio]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementCaseStudy]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementPackagedService]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementAttachments]: [
    'files',
  ],
  [CATEGORY.ElementApproval]: [

  ],
  [CATEGORY.ElementEmbeddedDocument]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementCost]: [
    'billables',
  ],
  [CATEGORY.ElementCostBreakdown]: [
    'settings',
  ],
  [CATEGORY.ElementQA]: {
    subcategoryField: 'inputType',
    common: [
      'settings',
      'inputType',
      'answer',
      'title',
      'description',
      'isDirty',
    ],

    longText: [

    ],

    multipleChoice: [
      'notes',
    ],

    date: [
      'notes',
    ],

    fileUpload: [
      'notes',
    ],

    rating: [
      'notes',
    ],

    slider: [
      'notes',
    ],

    imageChoice: [
      'notes',
    ],

    '2dSlider': [
      'notes',
    ],
  },
  [CATEGORY.ElementReusableBlock]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementEmbed]: [
    'url',
    'iframeHeight',
  ],
  [CATEGORY.ElementTable]: [
    'content',
  ],
  [CATEGORY.ElementButton]: [
    'settings',
  ],
  [CATEGORY.ElementEstimate]: [
    'embeddedDocument',
  ],
  [CATEGORY.ElementBrief]: [
    'embeddedDocument',
  ],
})

