<template>
  <div class="flex flex-col px-24 xl:flex-row">
    <div v-if="!templatesCategory" class="flex flex-col flex-none gap-8 mr-48 min-w-180">
      <label class="mb-6 font-medium text-gray-500 uppercase text-11 hover:text-gray-500">
        Categories:
      </label>
      <ul class="filter-list">
        <li
          v-for="category in categories"
          :key="category.id"
        >
          <div
            class="flex items-center leading-none cursor-pointer text-14"
            :class="(activeCategory === category.name) && 'filter-link-active'"
            @click="setCategory(category)"
          >
            <span
              :class="[
                DOCUMENT_ICON[category.name] ?? 'icon_v2-so_file',
                'transition-all mr-4 opacity-50 text-16']"
            />
            <span class="mr-8 whitespace-nowrap">{{ category.public_name }}</span>
            <b-badge
              class="mt-px badge-xs"
              :variant="(activeCategory === category.name)
                ? 'count-active'
                : 'count'"
            >
              {{ category.templates.length }}
            </b-badge>
          </div>
        </li>
      </ul>
    </div>
    <div class="fade-from-bottom-gray">
      <h2 class="mb-16 title-h2">Select a template</h2>
      <div v-if="templates" class="min-h-64 max-h-[640px] overflow-y-auto pb-88">
        <div
          :class="[
            'grid grid-flow-row-dense grid-cols-2',
            'gap-24 auto-cols-fr',
          ]"
        >
          <DocumentTemplatePreview
            v-for="template in templates"
            :key="template.id"
            :template="template"
            :category="template.category"
            :no-modal="true"
            @preview="$emit('preview', template)"
            @use="$emit('use', template)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alwaysEmptyArray from 'ramda-extension/lib/alwaysEmptyArray'
import { computed, ref, defineComponent } from '@vue/composition-api'
import strapi from '@/v2/services/strapiService'
import { ICON as DOCUMENT_ICON } from '@/types/document'
import DocumentTemplatePreview from '@/components/Template/DocumentTemplatePreview.vue'

const mapCategory = category => ({
  ...category,
  templates: category.templates.map(template => ({
    ...template,
    category: category.name,
  })),
})

export default defineComponent({
  name: 'DocumentFromTemplateCategory',
  components: {
    DocumentTemplatePreview,
  },
  props: {
    excludedCategories: {
      type: Array,
      default: () => [],
    },
    templatesCategory: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const activeCategory = ref(props.templatesCategory ?? 'all-templates')
    const allCategories = ref([])

    const allTemplates = computed(
      () => allCategories.value.map(mapCategory).flatMap(({ templates }) => templates)
    )

    const categories = computed(
      () => [
        {
          id: 'all-templates',
          name: 'all-templates',
          public_name: 'All',
          icon: 'document-custom',
          template_category: { name: 'all-templates' },
          templates: [...allTemplates.value],
        },
        ...allCategories.value
          .filter(
            ({ name }) => !props.excludedCategories.includes(name)
          ).map(mapCategory),
      ]
    )

    const templates = computed(
      () => categories.value.find(
        category => category.name === activeCategory.value
      )?.templates ?? []
    )

    const setCategory = category => {
      activeCategory.value = category.name
    }

    const loadCategories = async () => {
      const { data } = await strapi.templateCategories.find().catch(alwaysEmptyArray)
      allCategories.value = data
    }

    loadCategories()

    return {
      templates,
      categories,
      activeCategory,
      setCategory,
      DOCUMENT_ICON,
    }
  },
})
</script>
