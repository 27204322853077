<template>
  <NotificationDocument
    v-if="!isSingleton"
    v-slot="{ document }"
    :notification="notification"
  >
    <DocumentLink v-slot="{ navigate }" :document="document">
      <NotificationRenderer
        :notification="notification"
        icon-variant="icon"
        :icon="document.icon || 'icon_v2-so_folder'"
        icon-class="bg-blue-200 text-blue-500"
        badge-class="bg-blue-500"
        :badge-count="notification.events.length"
        @click="navigate"
        @mark-as-read="notification => $emit('mark-as-read', notification)"
      >
        <template #default="{ options }">
          <div>
            {{ $tc(`doc-category.${document.category}`, 1) }}
            <NotificationLinkDocument :document="document" />
            has {{ notification.events.length }}
            new {{ $tc('blocks.qa.answer', notification.events.length) }}
          </div>
        </template>
        <template #events>
          <NotificationList :notifications="notification.events" />
        </template>
      </NotificationRenderer>
    </DocumentLink>
  </NotificationDocument>
  <NotifContentBlocksQaAnswered
    v-else
    :notification="singleton"
    :project="notification.project$"
  />
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useNotificationGroup } from '@/v2/services/notifications/compositions'
import DocumentLink from '@/components/DocumentLink.vue'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationList from './NotificationList.vue'
import NotificationDocument from './NotificationDocument.vue'
import NotifContentBlocksQaAnswered from './NotifContentBlocksQaAnswered.vue'

export default defineComponent({
  name: 'NotifDocumentsNewQaAnswers',
  components: {
    DocumentLink,
    NotificationRenderer,
    NotificationLinkDocument,
    NotificationDocument,
    NotifContentBlocksQaAnswered,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    useNestedComponents({ NotificationList })
    const { singleton, isSingleton } = useNotificationGroup(toRef(props, 'notification'))

    return {
      singleton,
      isSingleton,
    }
  },
})
</script>
