<template>
  <div
    class="space-y-24"
    @contextmenu.stop
  >
    <!-- input -->
    <b-form
      v-if="isEditor && !isLocked"
      class="relative max-w-prose mx-auto"
      @submit.prevent="onSubmit"
    >
      <span
        :class="[
          'icon-object-embed w-24 text-24 text-gray-500',
          'absolute left-10 top-10',
        ]"
      />
      <b-form-input
        :id="componentID"
        v-model="localUrl"
        type="url"
        size="lg"
        placeholder="Paste your https:// link here..."
        class="pl-40"
      />
      <b-button
        :variant="isBlankState ? 'primary' : 'secondary'"
        :disabled="!localUrl"
        type="submit"
        class="absolute right-5 top-5"
      >
        {{ isBlankState ? 'Embed' : 'Refresh' }}
      </b-button>
    </b-form>

    <FormMessage v-if="error">
      {{ error }}
    </FormMessage>

    <!-- response -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div
      v-if="!isBlankState"
      ref="iframeContainer"
      class="iframely-wrapper w-full flex"
      v-html="htmlSanitized"
    />
    <!-- url not supported -->
  </div>
</template>

<script>
import { pathOr, path } from 'ramda'
import { ref, computed, inject, defineComponent, onMounted } from '@vue/composition-api'
import { pathIsNilOrEmpty, pathNotNilOrEmpty } from '@/v2/lib/helpers/fp'
import FormMessage from '@/components/FormMessage.vue'

function loadScript(fileUrl, elem) {
  const scriptElem = document.createElement('script');

  scriptElem.setAttribute('src', fileUrl);
  scriptElem.setAttribute('type', 'text/javascript');
  scriptElem.setAttribute('async', true);

  elem.appendChild(scriptElem);
}

const sanitizeHtml = html => html.replaceAll(/<script.*?<\/script>/gi, '')

const findScriptSrc = (html = '') => {
  const re = /<script.*?src="([^"]+)"/gi
  const [result] = Array.from(html.matchAll(re))
  return result?.[1] ?? null
}

export default defineComponent({
  name: 'DocumentContentBlockEmbed',
  components: {
    FormMessage,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },

  },
  setup(props, context) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')

    const iframeContainer = ref(null)

    const url = computed(() => pathOr('', ['contentBlock', 'data', 'url'], props))
    const localUrl = ref(props.contentBlock.url || '')

    const isBlankState = computed(() => pathIsNilOrEmpty(['contentBlock', 'data', 'html'], props))
    const html = computed(() => path(['contentBlock', 'data', 'html'], props))
    const htmlSanitized = computed(() => sanitizeHtml(html.value))

    const error = computed(() => (pathNotNilOrEmpty(['contentBlock', 'data', 'error'], props)
      ? 'Media expectations not met'
      : null))

    const settingsPopoverTargetId = computed(
      () => `settings-popover-${props.nodeId}`
    )
    // TODO: Add `iframeHeight` prop here in order to request specific iframe height from Iframely
    const onSubmit = async () => {
      context.emit('update', { url: localUrl.value }, { $sync: true })
    }

    onMounted(() => {
      const scriptSrc = findScriptSrc(html.value)

      if (scriptSrc) {
        loadScript(scriptSrc, iframeContainer.value)
      }
    })

    return {
      isEditor,
      isLocked,
      url,
      localUrl,
      onSubmit,
      isBlankState,
      settingsPopoverTargetId,
      // html,
      htmlSanitized,
      error,
      iframeContainer,
    }
  },
})
</script>

<style lang="scss">
.iframely-responsive {
    top: 0; left: 0; width: 100%; height: 0;
    position: relative;
    padding-bottom: 56%;
}
.iframely-responsive>* {
    top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;
}

.iframely-embed {
  @apply mx-auto flex-1;
  max-width: none!important;
}

.iframely-wrapper {
  // @apply mx-auto;
}

.iframely-wrapper>* {
  @apply mx-auto;
}
</style>
