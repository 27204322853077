
<router-link
  v-slot="{ navigate, href }"
  :to="{
    name: PROJECT_DASHBOARD,
    params: { projectId: notification.project }
  }"
  custom
>
  <slot v-bind="{ navigate, href }">
    <a
      class="font-semibold"
      :href="href"
      @click="navigate"
    >
      {{ notification.project$.name }}
    </a>
  </slot>
</router-link>
