
<b-dropdown
  no-caret
  dropright
  toggle-class="
    text-gray-600 bg-transparent border
    border-gray-200 dark:border-darkGray-700 btn btn-icon-sm
  "
  menu-class="w-235 text-14"
  variant="neutral"
  size="md"
>
  <template #button-content>
    <span class="w-24 icon_v2-so_menu-circle-vertical text-24"></span>
  </template>
  <b-dropdown-item
    v-if="activeItems.approval"
    @click.stop="$emit('approve', !document.isApproved)"
  >
    <span
      class="mr-8 dropdown-menu-icon"
      :class="{
        'icon_v2-so_tick text-green-500': !document.isApproved,
        'icon_v2-so_close text-red-500': document.isApproved,
      }"
    />
    <span v-if="!document.isApproved" class="text-green-500">Approve</span>
    <span v-else class="text-red-600">Unapprove</span>
  </b-dropdown-item>
  <!-- Edit -->
  <b-dropdown-item
    v-if="activeItems.changeFonts"
    @click.stop="$emit('change-fonts')"
  >
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_font-size"
    />
    <span>Document Fonts</span>
  </b-dropdown-item>
  <b-dropdown-divider v-if="activeItems.changeFonts" />

  <!-- Share -->
  <b-dropdown-item
    v-if="activeItems.share"
    @click.stop="$emit('share')"
  >
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_shared"
    />
    <span>Share</span>
  </b-dropdown-item>
  <!-- Duplicate -->
  <b-dropdown-item
    v-if="activeItems.duplicate"
    @click.stop="$emit('duplicate')"
  >
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_copy-done"
    />
    <span>Duplicate</span>
  </b-dropdown-item>
  <!-- Move -->
  <b-dropdown-item
    v-if="activeItems.move"
    @click.stop="$emit('move')"
  >
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_folder-move"
    />
    <span>Move</span>
  </b-dropdown-item>

  <b-dropdown-item
    v-if="activeItems.saveAsReusableBlock"
    @click.stop="$emit('save-as-reusable-block')"
  >
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_reusable-block"
    />
    <span>Save to Reusable Blocks</span>
  </b-dropdown-item>

  <FileDownload
    v-slot="{ proxyUrl }"
    :url="document?.file?.url"
    :passthru="document.isFileLink"
  >
    <b-dropdown-item
      v-if="activeItems.download"
      :href="proxyUrl"
      target="_blank"
      @click.stop="() => !proxyUrl && $emit('download')"
    >
      <span
        class="mr-8 dropdown-menu-icon icon_v2-so_download"
      />
      <span>Download</span>
    </b-dropdown-item>
  </FileDownload>

  <template v-if="activeItems.remove && document.status !== DOCUMENT_STATUS.APPROVED">
    <b-dropdown-divider />
    <!-- Remove -->
    <b-dropdown-item
      v-if="activeItems.remove"
      @click.stop="$emit('remove')"
    >
      <span
        class="mr-8 dropdown-menu-icon icon_v2-so_trash"
      />
      <span>Delete</span>
    </b-dropdown-item>
  </template>
</b-dropdown>
