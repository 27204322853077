
<div
  :class="[
    ' text-gray-800 dark:text-darkGray-300 leading-tight max-w-list',
    isDisabled && 'text-gray-900 dark:text-darkGray-700',
  ]"
>
  <LoaderWebFont :families="families" preview>
    <p
      :style="{
        fontFamily: font,
        fontSize: `${fontSize}px`,
      }"
    >
      {{ previewTextMap[variant] }}
    </p>
  </LoaderWebFont>
</div>
