export const TYPE = /** @type {const} */ ({
  printDocument: 'printDocument',
  zippedFolder: 'zippedFolder',
});

export const STATUS = /** @type {const} */ ({
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
});
