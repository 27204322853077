
<div class="relative editor-content table-editor-content">
  <bubble-menu
    :editor="editor"
    :tippy-options="{
      moveTransition: 'transform 0.1s ease-out',
      maxWidth: 'none',
      hideOnClick: true,
      onShow: onBubbleMenuShow,
      onHide: onBubbleMenuHide,
    }"
    :class="[
      'flex items-center space-x-2',
      'p-4 mb-4',
      'text-white rounded-md bg-gray-800 leading-none shadow'
    ]"
  >
    <!-- Highlight -->
    <EditorToolbarHighlight
      v-if="editorFeatures.includes(features.highlight)"
      :is-active="
        editor.isActive('colorHighlight')
      "
      :active-color="
        checkNestedMarkAttributes('colorHighlight', 'backgroundColor')
      "
      @select="item => setColorHighlight(item)"
    />
    <DocumentNodeEditorToolbarSeparator
      v-if="editorFeatures.includes(features.highlight)"
    />

    <!-- Headings -->
    <EditorToolbarHeading
      v-if="editorFeatures.includes(features.headings)"
      :is-active="editor.isActive('heading')"
      :active-heading="checkNestedNodeAttributes('heading', 'level')"
      @select="item => editor.chain().toggleHeading({ level: item }).focus().run()"
    />
    <!-- Bullet List -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.bulletList)"
      :is-active="editor.isActive('bulletList')"
      @click="editor.chain().toggleBulletList().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
    </EditorToolbarButton>

    <!-- Ordered List -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.orderedList)"
      :is-active="editor.isActive('orderedList')"
      @click="editor.chain().toggleOrderedList().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-number-list" />
    </EditorToolbarButton>

    <!-- Blockquote -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.blockquote)"
      :is-active="editor.isActive('blockquote')"
      @click="editor.chain().toggleBlockquote().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-quote" />
    </EditorToolbarButton>

    <!-- Code -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.code)"
      :is-active="editor.isActive('code')"

      @click="editor.chain().toggleCode().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-code" />
    </EditorToolbarButton>

    <!-- Link -->
    <DocumentNodeEditorToolbarPopoverLight
      v-if="editorFeatures.includes(features.link) && bubbleMenuShown"
      :width="420"
      placement="bottom"
      :target-id="componentID"
      triggers="click blur"
      slim
    >
      <template #button-content>
        <!-- Button before link menu is set -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.link)"
          :is-active="editor.isActive('link')"
          has-dropdown
          @click="showLinkMenu(editor.getAttributes('link'))"
        >
          <span class="relative icon_v2-so_link" />
        </EditorToolbarButton>
      </template>
      <template #default>
        <form
          class="flex items-center px-8 py-6 space-x-8"
          @mouseenter="toggleIsPopoverActive(true)"
          @mouseleave="toggleIsPopoverActive(false)"
          @submit.prevent="setLink(linkUrl)"
        >
          <b-input
            ref="linkInput"
            v-model="linkUrl"
            :class="[
              'form-control-sm',
              'flex-1'
            ]"
            type="text"
            placeholder="https://"
            @keydown.esc="hideLinkMenu"
          />
          <b-button
            v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Confirm link`"
            :disabled="!linkUrl"
            variant="primary"
            :class="[
              'inline-flex items-center justify-center p-0 w-28 h-26',
              'text-green-600 rounded-full',
            ]"
            type="submit"
          >
            <span class="icon_v2-so_tick" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Remove link`"
            :disabled="!linkUrl"
            variant="icon-isolated"
            :class="[
              'inline-flex items-center justify-center p-0 w-28 h-26',
              'text-gray-600 rounded-full',
            ]"
            type="button"
            @click="editor.chain().focus().unsetLink().run()"
          >
            <span class="icon_v2-so_link-unlink" />
          </b-button>
        </form>
      </template>
    </DocumentNodeEditorToolbarPopoverLight>


    <DocumentNodeEditorToolbarSeparator />

    <!-- Bold -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.bold)"
      :is-active="editor.isActive('bold')"

      @click="editor.chain().toggleBold().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-bold" />
    </EditorToolbarButton>

    <!-- Italic -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.italic)"
      :is-active="editor.isActive('italic')"

      @click="editor.chain().toggleItalic().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-italic" />
    </EditorToolbarButton>

    <!-- Strike -->
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.strike)"
      :is-active="editor.isActive('strike')"

      @click="editor.chain().toggleStrike().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-strike" />
    </EditorToolbarButton>

    <!-- Align -->
    <DocumentNodeEditorToolbarSeparator />
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.textAlign)"
      :is-active="editor.isActive({textAlign: 'left'})"

      @click="editor.chain().setTextAlign('left').focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-align-left" />
    </EditorToolbarButton>
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.textAlign)"
      :is-active="editor.isActive({textAlign: 'center'})"

      @click="editor.chain().setTextAlign('center').focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-align-center" />
    </EditorToolbarButton>
    <EditorToolbarButton
      v-if="editorFeatures.includes(features.textAlign)"
      :is-active="editor.isActive({textAlign: 'right'})"

      @click="editor.chain().setTextAlign('right').focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-align-right" />
    </EditorToolbarButton>
  </bubble-menu>
  <!-- Editor Content -->
  <section
    class="flex justify-between h-40 mb-16 print:hidden"
  >
    <section
      :class="[
        !isFocus && 'opacity-30',
        'p-4 bg-white border border-gray-200 shadow rounded-lg',
        'inline-flex items-center space-x-4',
        'leading-none'
      ]"
    >
      <!-- NEW TABLE -->
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add column before'"
        variant="light"
        :disabled="!editor.can().addColumnBefore()"
        @click="editor.chain().focus().addColumnBefore().run()"
      >
        <span class="w-20 text-20 icon-table-column-plus-before"></span>
      </EditorToolbarButton>
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add column after'"
        variant="light"
        :disabled="!editor.can().addColumnAfter()"
        @click="editor.chain().focus().addColumnAfter().run()"
      >
        <span class="w-20 text-20 icon-table-column-plus-after"></span>
      </EditorToolbarButton>
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add row before'"
        variant="light"
        :disabled="!editor.can().addRowBefore()"
        @click="editor.chain().focus().addRowBefore().run()"
      >
        <span class="w-20 text-20 icon-table-row-plus-before"></span>
      </EditorToolbarButton>

      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Add row after'"
        variant="light"
        :disabled="!editor.can().addRowAfter()"
        @click="editor.chain().focus().addRowAfter().run()"
      >
        <span class="w-20 text-20 icon-table-row-plus-after"></span>
      </EditorToolbarButton>
      <DocumentNodeEditorToolbarSeparator variant="small" />
      <!-- REMOVE COLUMN -->
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete column'"
        variant="light"
        :disabled="!editor.can().deleteColumn()"
        @click="editor.chain().focus().deleteColumn().run()"
      >
        <span class="w-20 text-20 icon-table-column-remove"></span>
      </EditorToolbarButton>

      <!-- REMOVE ROW -->
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete row'"
        variant="light"
        :disabled="!editor.can().deleteRow()"
        @click="editor.chain().focus().deleteRow().run()"
      >
        <span class="w-20 text-20 icon-table-row-remove"></span>
      </EditorToolbarButton>

      <DocumentNodeEditorToolbarSeparator variant="small" />
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Merge cells'"
        variant="light"
        :disabled="!editor.can().mergeCells()"
        @click="editor.chain().focus().mergeCells().run()"
      >
        <span class="w-20 text-20 icon-table-merge-cells"></span>
      </EditorToolbarButton>

      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Split cell'"
        variant="light"
        :disabled="!editor.can().splitCell()"
        @click="editor.chain().focus().splitCell().run()"
      >
        <span class="w-20 text-20 icon-table-split-cell"></span>
      </EditorToolbarButton>
      <DocumentNodeEditorToolbarSeparator variant="small" />
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Toggle header column'"
        variant="light"
        :disabled="!editor.can().toggleHeaderColumn()"
        @click="editor.chain().focus().toggleHeaderColumn().run()"
      >
        <span class="w-20 text-20 icon-table-column-toggle-header"></span>
      </EditorToolbarButton>
      <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Toggle header row'"
        variant="light"
        :disabled="!editor.can().toggleHeaderRow()"
        @click="editor.chain().focus().toggleHeaderRow().run()"
      >
        <span class="w-20 text-20 icon-table-row-toggle-header"></span>
      </EditorToolbarButton>

      <!-- <EditorToolbarButton
        v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Delete table'"
        variant="light"
        :disabled="!editor.can().deleteTable()"
        @click="editor.chain().focus().deleteTable().run()"
      >
        <span class="w-20 text-20 icon-table-large-remove"></span>
      </EditorToolbarButton> -->
      <!-- <EditorToolbarButton
        :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')"
        @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"
      >
        setCellAttribute
      </EditorToolbarButton> -->
      <!-- <EditorToolbarButton
        :disabled="!editor.can().fixTables()"
        @click="editor.chain().focus().fixTables().run()"
      >
        fixTables
      </EditorToolbarButton> -->
    </section>
    <!-- <b-button
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="'New Table'"
      :class="[
        !isFocus && 'opacity-30',
        'h-auto inline-flex items-center leading-none rounded-lg'
      ]"
      variant="neutral"
      @click="editor.chain().focus().insertTable(
        {
          rows: 3,
          cols: 3,
          withHeaderRow: true
        }
      ).run()"
    >
      Add table
      <span class="w-20 ml-4 text-20 icon-table-large-plus"></span>
    </b-button> -->
  </section>
  <!-- CONTENT -->
  <editor-content :editor="editor" />
  <!-- CONTENT -->
</div>
