<template>
  <section>
    <!-- GROUP HEADER -->
    <div class="flex items-center justify-between mb-8 px-4">
      <h4
        v-if="label"
        class="text-gray-400 font-semibold text-11 uppercase"
      >
        {{ label }}
      </h4>
      <slot name="right" />
    </div>
    <slot />
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarGroup',
  props: {
    label: {
      type: String,
      default: null,
    },
  },
})
</script>
<style lang="postcss" scoped>
  /* .sidebar-group:not(:first-child) {
    @apply mt-8;
  } */
</style>
