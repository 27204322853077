<template>
  <li
    :href="`#node-${node._id}`"
    :class="[
      'group toc-item qa',
      (node._id === selectedNodeId) && `active`,
    ]"
    @click="$emit('go-to-node')"
  >
    <i class="active-indicator" />
    <span v-if="isAnswered" class="icon_v2-so_tick text-14 text-green-600 mr-5"></span>
    <span
      v-else
      :class="['toc-checkbox']"
    />
    <p class="truncate pr-8 md:pr-0">
      {{ node.strippedTitle || 'Question' }}
    </p>
  </li>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'TocItemQa',
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    selectedNodeId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const isAnswered = computed(() => props.node.contentBlock$.isDirty)

    return {
      isAnswered,
    }
  },
})
</script>
