<template>
  <ScrollContainer name="appScrollContainer" scroll-on-body>
    <div
      class="layout"
      :class="{
        'mobile-sticky-footer': mobileStickyFooter,
        'is-mobile': $isMobile,
        'dark-theme dark': darkTheme,
        'light-theme': !darkTheme,
        'hide-sidebar': !hasSidebar || $isMobile,
        'hide-toolbar': !hasToolbar || $isMobile,
        'hide-sidebar-right': !hasSidebarRight,
        'hide-page-header': !hasPageHeader,
        'hide-footer': !hasPageFooter,
      }"
    >
      <!-- header -->
      <header>
        <ButtonIcon
          v-if="hasSidebar && $isMobile"
          v-b-toggle.mobile-menu-sidebar
          icon="icon-hamburger"
          class="mr-16"
        />
        <slot name="header" />
      </header>

      <!-- toolbar + sidebar -->
      <aside
        v-if="(hasToolbar || hasSidebar) && !$isMobile"
        class="aside-left"
      >
        <!-- toolbar -->
        <nav v-if="hasToolbar" class="toolbar">
          <slot name="nav" />
        </nav>

        <!-- sidebar -->
        <nav v-if="hasSidebar" class="sidebar">
          <!-- sidebar header -->
          <div class="sidebar-header">
            <portal-target name="app-sidebar-header" slim />
            <slot name="sidebar-header" />
          </div>
          <!-- sidebar content -->
          <div class="sidebar-content">
            <portal-target name="app-sidebar" slim />
            <slot name="sidebar" />
          </div>
        </nav>
      </aside>

      <!-- main content -->
      <main>
        <div v-if="hasPageHeader" class="page-header">
          <portal-target name="page-header" slim />
        </div>
        <div class="page-content grid">
          <slot name="main" />
        </div>
      </main>

      <aside v-if="hasSidebarRight" class="aside-right">
        <portal-target name="app-sidebar-right" slim />
      </aside>

      <!-- footer -->
      <footer v-if="hasPageFooter">
        <div class="footer-content">
          <portal-target name="page-footer" slim />
        </div>
      </footer>
    </div>

    <!-- mobile drawer -->
    <b-sidebar
      v-if="hasSidebar && $isMobile"
      id="mobile-menu-sidebar"
      sidebar-class="bg-gray-100/90 backdrop-blur-md"
      body-class="px-8"
      header-class="!p-0"
      backdrop
      backdrop-variant="dark"
      shadow
    >
      <template #header="{ hide }">
        <div
          class="
            flex items-center justify-between w-full mb-8 bg-gray-200   px-16 py-8
          "
        >
          <portal-target name="app-sidebar-header" slim />
          <ButtonIcon icon="icon_v2-so_close text-36" @click="hide" />
        </div>
      </template>
      <template #default>
        <portal-target name="app-sidebar" slim />
      </template>
    </b-sidebar>
    <div
      v-if="$isMobile && hasToolbar"
      class="
        fixed top-auto bottom-0 left-0 right-0
        bg-gray-100/30 p-8 z-1000 backdrop-blur-md
      "
    >
      <slot name="nav" />
    </div>
    <slot name="modals" />
    <portal-target name="right-drawer" slim />
    <portal-target name="body" slim />
  </ScrollContainer>
</template>

<script>
import { isNotNil } from 'ramda-adjunct'
import { computed, defineComponent } from '@vue/composition-api'
import { Wormhole } from 'portal-vue'
import ScrollContainer from '@/components/ScrollContainer.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'

export default defineComponent({
  name: 'LayoutApp',
  components: {
    ScrollContainer,
    ButtonIcon,
  },
  props: {
    darkTheme: {
      type: Boolean,
      default: false,
    },
    mobileStickyFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const hasSidebar = computed(
      () => Wormhole.hasContentFor('app-sidebar') || isNotNil(context.slots.sidebar)
    )

    const hasSidebarHeader = computed(
      () => Wormhole.hasContentFor('app-sidebar-header')
        || isNotNil(context.slots['sidebar-header'])
    )

    const hasSidebarRight = computed(() => Wormhole.hasContentFor('app-sidebar-right'))
    const hasPageHeader = computed(() => Wormhole.hasContentFor('page-header'))
    const hasPageFooter = computed(() => Wormhole.hasContentFor('page-footer'))
    const hasToolbar = computed(() => isNotNil(context.slots.nav))

    return {
      hasSidebar,
      hasSidebarHeader,
      hasSidebarRight,
      hasPageHeader,
      hasPageFooter,
      hasToolbar,
    }
  },
})
</script>

<style lang="postcss" scoped>
.layout {
  --toolbar-width: 80px;
  --sidebar-width: 245px;
  --sidebar-right-width: 70px;
  --sidebar-header-height: 60px;
  --header-height: 60px;
  --footer-height: 80px;
  --page-header-height: 60px;
  --layout-padding: 16px;
  --main-left: 0px;

  min-height: 100vh;
  min-height: 100dvh;

  padding-right: var(--layout-padding);

  &.light-theme {
    @apply bg-gray-100;
  }

  &.dark-theme {
    @apply bg-darkGray-700;
  }
}

.layout.hide-toolbar {
  --toolbar-width: 0px;
}

/** toolbar visible + sidebar visible */
.layout:not(.hide-toolbar):not(.hide-sidebar) {
  --aside-width: calc(var(--toolbar-width) + var(--sidebar-width));
  --main-left: calc(var(--aside-width) + var(--layout-padding));
}

/** toolbar visible + sidebar hidden */
.layout:not(.hide-toolbar).hide-sidebar {
  --aside-width: var(--toolbar-width);
  --main-left: var(--toolbar-width);
}

/** toolbar hidden + sidebar visible */
.layout:not(.hide-sidebar).hide-toolbar {
  --toolbar-width: 0px;
  --aside-width: var(--sidebar-width);
  --main-left: calc(var(--sidebar-width) + var(--layout-padding))
}

/** page header hidden */
.layout.hide-page-header {
  --page-header-height: 0px;
}

/** footer hidden */
.layout.hide-footer {
  --footer-height: 0px;
}


header {
  height: var(--header-height);
  left: 0;
  padding-left: max(var(--toolbar-width), 16px);

  @apply fixed top-0 right-0 flex items-center py-8 pr-16 z-200;

  .light-theme & {
    @apply bg-gray-100;
  }

  .dark-theme & {
    @apply !bg-darkGray-700 text-darkGray-400;
  }
}

.aside-left {
  width: var(--aside-width);
  top: 0;
  z-index: 201;

  @apply fixed left-0 bottom-0 flex items-stretch;

  .toolbar {
    width: var(--toolbar-width);
    @apply bg-gray-100;
  }

  .sidebar {
    margin-top: var(--header-height);
    @apply min-w-0 flex-1 flex flex-col border/*  max-w-full */;

    .dark-theme & {
      @apply border-darkGray-900;
    }
  }

  .sidebar-header {
    height: var(--sidebar-header-height);
    @apply flex items-center px-16;

    .light-theme & {
      /* @apply bg-gradient-to-b from-gray-100 to-gray-160 border-b; */
      @apply bg-gray-200/90 border-b;
    }

    .dark-theme & {
      @apply bg-darkGray-800 text-darkGray-400;
    }
  }

  .sidebar-content {
    @apply overflow-y-auto flex-grow px-8 py-4;

    .light-theme & {
      @apply bg-white;
    }

    .dark-theme & {
      @apply bg-darkGray-1000;
    }
  }
}

.aside-right {
  @apply fixed border-l dark:border-darkGray-700 z-20;
  right: var(--layout-padding);
  top: calc(var(--header-height) + var(--page-header-height));
  bottom: var(--footer-height);
}

main {
  padding-top: var(--header-height);
  padding-left: var(--main-left);
  padding-bottom: var(--footer-height);

  min-height: calc(
    100dvh
    - var(--header-height)
    - var(--page-header-height)
    - var(--footer-height)
  );

  .page-header {
    top: var(--header-height);
    height: 60px;
    @apply sticky z-100 px-16 backdrop-blur-sm;
    @apply border;

    .light-theme & {
      /* @apply bg-gradient-to-b from-gray-100/90 to-gray-160/90; */
      @apply bg-gray-200/90;
    }

    .dark-theme & {
      @apply bg-darkGray-800 text-darkGray-400;
      @apply border-darkGray-900;
    }
  }

  .page-content {
    min-height: inherit;
    display: grid;
    @apply p-16 pb-64 border-l border-r;

    .hide-footer & {
      @apply border-b pb-16;
    }

    .light-theme & {
      @apply bg-white;
    }

    .dark-theme & {
      @apply bg-darkGray-1000;
      @apply border-darkGray-900;
    }

    .layout:not(.hide-sidebar-right) & {
      padding-right: 80px;
    }
  }

}

/** footer */
footer {
  left: var(--main-left);
  right: var(--layout-padding);
  height: calc(var(--footer-height) + var(--layout-padding));
  padding-bottom: var(--layout-padding);

  @apply fixed bottom-0 z-200;

  .light-theme & {
    @apply bg-gray-100/70 backdrop-blur-sm;
  }

  .dark-theme & {
    @apply bg-darkGray-700;
  }

  .footer-content {
    @apply flex items-center p-16 h-full;

    .light-theme & {
      @apply bg-gradient-to-b from-gray-160/90 to-transparent border;
    }

    .dark-theme & {
      @apply bg-gradient-to-b from-darkGray-800 to-transparent;
    }
  }
}

/** mobile version */
.layout.is-mobile {
  --layout-padding: 0px;

  main {
    padding-right: 0;

    .main-content {
      max-width: 100vw;
      max-width: 100dvw;
      overflow-x: hidden;
    }
  }

  &:not(.mobile-sticky-footer) {
    footer {
      padding-bottom: 0;
      position: initial;
      margin-top: -130px;
    }
  }
}
</style>
