
<ModalBasic
  :no-close="false"
  :hide-footer="true"
  :show-back="Boolean(template)"
  :content-class="['modal-content-template-preview', $style['modal-content-template']]"
  :header-class="$style['modal-header-template']"
  :body-class="$style['modal-body-template']"
  :dialog-class="$style['modal-dialog-template']"
  close-text="Close"
  size="xl"
  v-bind="$attrs"
  v-on="$listeners"
  @hide="$tracking.track('Project Templates Modal Closed')"
  @back="onTemplatePreview(null)"
>
  <!-- <template #header>
    <div class="text-18 text-gray-900 font-semibold w-full">
      Project Templates
    </div>
  </template> -->
  <template #header-buttons>
    <Button
      v-if="template"
      type="button"
      variant="primary"
      class="whitespace-nowrap"
      size="md"
      @click="useTemplate"
    >
      <span class="w-20 mr-4 icon_v2-so_tick text-20"></span>Use Template
    </Button>
  </template>
  <template #body>
    <ProjectTemplatePreview
      v-if="template"
      :template="template"
      :pending="pending"
      @use="useTemplate"
    />
    <ProjectTemplates
      v-else
      @preview="onTemplatePreview"
      @create="onCreate"
    />
  </template>
</ModalBasic>
