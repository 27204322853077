<template>
  <ModalBasic
    :no-close="false"
    :hide-footer="true"
    :show-back="Boolean(template)"
    :content-class="['modal-content-template-preview', $style['modal-content-template']]"
    :header-class="$style['modal-header-template']"
    :body-class="$style['modal-body-template']"
    :dialog-class="$style['modal-dialog-template']"
    close-text="Close"
    size="xl"
    v-bind="$attrs"
    v-on="$listeners"
    @hide="$tracking.track('Project Templates Modal Closed')"
    @back="onTemplatePreview(null)"
  >
    <!-- <template #header>
      <div class="text-18 text-gray-900 font-semibold w-full">
        Project Templates
      </div>
    </template> -->
    <template #header-buttons>
      <Button
        v-if="template"
        type="button"
        variant="primary"
        class="whitespace-nowrap"
        size="md"
        @click="useTemplate"
      >
        <span class="w-20 mr-4 icon_v2-so_tick text-20"></span>Use Template
      </Button>
    </template>
    <template #body>
      <ProjectTemplatePreview
        v-if="template"
        :template="template"
        :pending="pending"
        @use="useTemplate"
      />
      <ProjectTemplates
        v-else
        @preview="onTemplatePreview"
        @create="onCreate"
      />
    </template>
  </ModalBasic>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import Button from '@/components/Button.vue'
import ProjectTemplates from '@/components/ProjectTemplates.vue'
import ProjectTemplatePreview from '@/components/ProjectTemplatePreview.vue'

export default defineComponent({
  name: 'ProjectTemplatesModal',
  components: {
    ModalBasic,
    Button,
    ProjectTemplates,
    ProjectTemplatePreview,
  },
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const template = ref(false)
    const { track } = useTracking()

    const onTemplatePreview = selectedTemplate => {
      if (selectedTemplate) {
        track('Project Template Previewed', {
          template: selectedTemplate.project$.name,
        })
      }
      template.value = selectedTemplate
    }

    const useTemplate = () => {
      track('Project Template Used', {
        template: template.value.project$.name,
      })
      context.emit('use', template.value)
    }

    const onCreate = selectedTemplate => {
      track('Project Template Used', {
        template: selectedTemplate.project$.name,
      })
      context.emit('use', selectedTemplate)
    }

    return {
      onTemplatePreview,
      onCreate,
      template,
      useTemplate,
    }
  },
})
</script>
<style lang="postcss" module>
.modal-dialog-template {
  /* max-width: 960px; */
}

.modal-content-template {
  /* background-color: theme('colors.gray.DEFAULT'); */
  padding: 0;
}

.modal-header-template {
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  /* @apply bg-gray border border-b border-gray-300; */
  margin-bottom: 0;
  :global(.modal-back) {
    position: relative;
    padding: 4px 8px;
    margin-right: 4px;
  }
}
.modal-body-template {
  max-width: none;
  min-height: 320px;
  padding: 0 16px;
  :global(.document-page) {
    overflow-x: hidden;
  }
}
</style>
