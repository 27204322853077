
<b-dropdown
  no-caret
  dropright
  variant="transparent"
  toggle-class="w-16"
  size="xs"
>
  <template #button-content>
    <span class="text-16 icon_v2-so_menu-circle-vertical"></span>
  </template>

  <!-- edit -->
  <b-dropdown-item @click.stop="$emit('edit')">
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_edit"
    />
    <span>Edit</span>
  </b-dropdown-item>

  <!-- remove -->
  <b-dropdown-item @click.stop="$emit('remove')">
    <span
      class="mr-8 dropdown-menu-icon icon_v2-so_remove"
    />
    <span>Remove</span>
  </b-dropdown-item>
</b-dropdown>
