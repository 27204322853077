
<DocumentGet
  :id="contentBlock.embeddedDocument"
  v-slot="{ document, isGetPending, isBlankState, hasImage, imageCssStyle }"
  :edit-scope="editScope"
  :params="{ embed: true }"
>
  <DocumentLink
    v-if="!isGetPending && document"
    v-slot="{ navigate }"
    :document="document"
  >
    <div
      class="
        transition-all
        cursor-pointer
        block-bio-card
        group
        card-interactive
      "
      data-intercom-target="Team Bio Block"
      @click="navigate"
    >
      <template v-if="document && !isBlankState">
        <!-- HEADER -->
        <header class="block-bio-card-header border-b border-gray-200 mb-16">
          <!-- Photo -->
          <b-link
            class="
              relative
              flex-shrink-0
              mr-16
              text-gray-700
              hover:text-gray-700
              z-1
              -mb-24
            "
          >
            <img
              v-if="hasImage"
              class="bio-image"
              :style="imageCssStyle"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            />
            <span v-else class="relative bio-image-empty">
              <span
                :class="[
                  'icon-camera text-32 h-32 opacity-30',
                  'absolute left-1/2 top-1/2 -translate-x-1/2 transform -translate-y-1/2',
                ]"
              />
            </span>
          </b-link>
          <!-- Name and job title -->
          <div>
            <h2 class="bio-title portal-heading-font">
              {{ document.title }}
            </h2>
            <small
              v-if="document.role"
              class="
                bio-subtitle
                portal-paragraph-font
              "
            >
              {{ document.role }}
            </small>
          </div>
          <!-- <div
            :class="[
              'absolute bg-white border-t',
              'border-gray-200 h-24 z-0 bottom-0',
              'left-0 right-0',
            ]"
          ></div> -->
        </header>

        <!-- BIO -->
        <div class="bio-card-inner portal-paragraph-font">
          <p v-if="document.description" class="bio-description">
            {{ document.description }}
            <!-- <span v-if="isBlankState"></span> -->
          </p>
          <div class="bio-info">
            <b-button
              variant="slim"
              class="
                text-16
                hover:text-[color:var(--cp-link-color)] hover:underline
              "
            >
              {{
                $t('doc-types.view-doc', {
                  doc: $tc('doc-types.team-bio-profile', 1),
                })
              }}
            </b-button>
            <div class="badge-wrap">
              <DocumentAnalytics v-if="showAnalytics" :document="document" />
            </div>
          </div>
          <div
            v-if="standalone"
            :class="[
              'absolute flex flex-col items-center',
              'invisible group-hover:opacity-100 group-hover:visible',
              'ml-10 top-8 right-8',
            ]"
          >
            <Button
              variant="delete"
              class="w-32 h-32 p-0"
              @click.stop="$emit('remove', document)"
            >
              <span class="w-20 icon_v2-so_trash text-20"></span>
            </Button>
          </div>
        </div>
      </template>
      <div v-else>
        <div class="bio-placeholder">
          <span class="icon-bio-placeholder w-128 text-128"></span>
        </div>
      </div>
    </div>
  </DocumentLink>
</DocumentGet>
