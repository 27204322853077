<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="hideOnPrint"
    :content-class="['mx-auto max-w-prose']"
    :config-override="{
      styling: {
        size: 'L',
        paddingTop: 32,
        paddingBottom: 40,
      },
    }"
  >
    <template #default="{ styling, textVariant, nodeWidth }">
      <DocumentContentBlockApproval
        :document="document"
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        :node-width="nodeWidth"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
// import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockApproval from './DocumentContentBlockApproval.vue'

export default {
  name: 'DocumentNodeApproval',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockApproval,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const document = inject('document')

    // If in DRAFT, hide on print
    const hideOnPrint = computed(() => Boolean(document.value.status === 'DRAFT'))

    return {
      hideOnPrint,
      document,
    }
  },
}
</script>
