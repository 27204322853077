import { either, test } from 'ramda';

/** `true` if a string looks like a `uuid4`-generated code, `false` otherwise */
const isValidUUID4 = test(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)
/** `true` if a string looks like a `nanoid`-generated code, `false` otherwise */
const isValidNanoID = test(/[A-Za-z0-9_-]{21}/)
/** Tests if a given string resembles a `uuid4` or `nanoid` */
export const isCodeValid = either(isValidNanoID, isValidUUID4);

const imageMIMETypes = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
]

/**
 * Checks if a provided file is an image
 * @param {{contentType: String}} file The file to check
 */
export const isFileTypeImage = file => imageMIMETypes.includes(file?.contentType)
