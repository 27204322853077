
<div class="flex flex-col gap-8 mr-24">
  <label
    v-if="title"
    class="mb-6 font-medium text-gray-500 uppercase text-11 hover:text-gray-500"
  >
    {{ title }}
  </label>
  <ul class="filter-list">
    <li
      v-for="item in sortedItems"
      :key="item.id"
      @click="$emit('select', item)"
    >
      <router-link
        v-if="item.to"
        v-slot="{ href, navigate, isActive }"
        :to="item.to"
        custom
      >
        <a :href="href" @click="navigate">
          <CategoryListItem
            :item="item"
            :active="isActive"
          />
        </a>
      </router-link>
      <CategoryListItem
        v-else
        :item="item"
        :active="item.id === activeId"
        @click="$emit('select', item)"
      />
    </li>
  </ul>
</div>
