import { useRouter } from '@/v2/lib/composition/useRouter'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { ORGANIZATION_HOME } from '@/router/organization/type'

/**
 * @typedef {Object} Options
 * @property {boolean} redirectToHome
 */

/**
 *
 * @param {Options} options
 * @returns
 */
export default function useRemoveProject(options = {}) {
  const {
    redirectToHome = false,
  } = options

  const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
  const { router } = useRouter()

  const remove = async project => {
    const ok = await msgBoxConfirmDelete({
      title: `Delete ${project.name}`,
      message: 'Are you sure you want to delete this project? This action is irreversible.',
    })

    if (ok) {
      await project.remove()
      if (redirectToHome) {
        router.push({ name: ORGANIZATION_HOME })
      }
    }
  }

  return {
    remove,
  }
}
