
<b-form-rating
  :value="value"
  class="p-0 space-x-4 bg-transparent b-rating-decimal"
  no-border
  :stars="steps"
  :disabled="isLocked"
  size="lg"
  number
  @change="value => $emit('update', { answer: value, isDirty: true })"
>
  <template slot="icon-full"><i></i></template>
  <template slot="icon-empty"><i></i></template>
</b-form-rating>
