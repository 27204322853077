
<!-- ref="form" -->
<form
  :class="[
    $style['form-uploader'],
    block && 'd-block',
    inlineBlock && 'd-inline-block',
    customClass,
  ]"
  @dragover.stop.prevent="dragOverHandler"
  @drop.stop.prevent="dropHandler"
  @dragleave="dragLeaveHandler"
  @submit.prevent
>
  <input
    ref="input"
    class="file-input"
    type="file"
    :multiple="multiple"
    :accept="accept"
    @change="onChange($event.target.files)"
  />
  <slot v-bind="{ selectFiles, isDragging }" />
</form>
