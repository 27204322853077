<template>
  <div v-fragment>
    <ProjectTemplatesModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :pending="cloneProjectPending"
      :error="cloneProjectError"
      @use="onUseTemplate"
    />
    <FeatureLimit
      v-slot="{ checkFeatureLimit }"
      flag="limitProjects"
      label="projects"
      @allow="openModal"
    >
      <slot v-bind="{ create: checkFeatureLimit }" />
    </FeatureLimit>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useCloneProject } from '@/v2/services/projects/compositions'
import FeatureLimit from '@/components/FeatureLimit.vue'
import ProjectTemplatesModal from '@/components/ProjectTemplatesModal.vue'

export default defineComponent({
  name: 'ProjectCreate',
  components: {
    ProjectTemplatesModal,
    FeatureLimit,
  },
  setup(props, context) {
    const {
      clone: cloneProject,
      reset: cloneProjectReset,
      error: cloneProjectError,
      isPending: cloneProjectPending,
    } = useCloneProject()

    const isModalOpen = ref(false)

    const openModal = () => {
      isModalOpen.value = true
    }

    const onUseTemplate = async template => {
      await cloneProject(template.project$)
      isModalOpen.value = false
      cloneProjectReset()
    }

    return {
      isModalOpen,
      openModal,
      onUseTemplate,
      cloneProjectPending,
      cloneProjectError,
    }
  },
})
</script>
