
<section>
  <!-- GROUP HEADER -->
  <div class="flex items-center justify-between mb-8 px-4">
    <h4
      v-if="label"
      class="text-gray-400 font-semibold text-11 uppercase"
    >
      {{ label }}
    </h4>
    <slot name="right" />
  </div>
  <slot />
</section>
