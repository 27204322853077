import Vue from 'vue'

const listeners = new WeakMap()

const isChild = (elem, parent) => {
  if (!elem) {
    return false
  }

  return elem === parent || isChild(elem.parentNode, parent)
}

const makeClickListener = fn => (el, { capture }) => {
  if (listeners.has(el)) {
    fn('click', listeners.get(el), Boolean(capture))
  }
}

const addClickListener = makeClickListener(
  document.addEventListener.bind(document)
)
const removeClickListener = makeClickListener(
  document.removeEventListener.bind(document)
)

Vue.directive('click-outside', {
  bind(el, { value, modifiers }) {
    if (typeof value !== 'function') {
      return
    }

    listeners.set(el, event => {
      if (modifiers.stop) {
        event.stopPropagation()
      }

      if (!isChild(event.target, el)) {
        value()
      }
    })

    addClickListener(el, modifiers)
  },
  unbind(el, { modifiers }) {
    removeClickListener(el, modifiers)
  },
})
