<template>
  <div class="cursor-pointer hover:cursor-pointer" @click="$emit('click', $event)">
    <div
      class="relative flex items-center p-16 rounded-lg hover:bg-yellow-10"
      :class="!options.showBullet && 'pl-0'"
    >
      <!-- ------------------------------ -->
      <!-- BULLET ----------------------- -->
      <!-- ------------------------------ -->
      <span
        v-if="options.showBullet"
        class="absolute top-8 flex-none block w-6 h-6 bg-orange-600 rounded-full left-8"
      />

      <!-- Collapse / expand button -->
      <ButtonTransparent
        v-if="hasEvents"
        class="flex items-center w-16 h-20 pl-0 pr-6 -ml-2 text-gray-400  hover:text-gray-900"
        @click.stop="toggleGroup"
      >
        <span
          class="inline-block transition duration-200 ease-in-out transform  text-10 icon-arrow-right"
          :class="{ 'rotate-90': isGroupExpanded }"
        />
      </ButtonTransparent>

      <NotificationRendererIcon
        :class="options.slim ? 'mr-10' : 'mr-16'"
        :notification="notification"
        :slim="options.slim"
        :variant="iconVariant"
        :icon="icon"
        :icon-class="iconClass"
        :avatar-show-name="avatarShowName"
        :avatar-font-size="avatarFontSize"
        :badge-class="badgeClass"
        :badge-icon="badgeIcon"
        :badge-count="badgeCount"
      />

      <!-- ------------------------------ -->
      <!-- DETAILS ---------------------- -->
      <!-- ------------------------------ -->
      <div class="space-y-4">
        <div
          class="flex items-center flex-1 gap-4"
          :class="options.slim ? 'text-13' : 'text-14'"
        >
          <div class="leading-tight text-gray-900 dark:text-darkGray-300">
            <!-- ----------------------------------------------- -->
            <!-- NOTIFICATION IMPLEMENTATION (Notif* components) -->
            <!-- ----------------------------------------------- -->
            <slot v-bind="{ options }" />
          </div>
        </div>

        <NotificationRendererMetaInfo
          :class="options.slim ? 'text-12' : 'text-13'"
          :notification="notification"
          :project="project"
          :show-project="options.showProject"
          :show-client-info="!options.slim"
        />
      </div>
      <NotificationRendererButtonRead v-if="options.showMarkAsRead" @click="markAsRead" />
    </div>
    <div
      v-if="hasEvents && isGroupExpanded"
      class="pl-40"
    >
      <slot name="events" />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, inject, ref } from '@vue/composition-api'
import { isNonEmptyArray } from 'ramda-adjunct'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import NotificationRendererIcon from './NotificationRendererIcon.vue'
import NotificationRendererButtonRead from './NotificationRendererButtonRead.vue'
import NotificationRendererMetaInfo from './NotificationRendererMetaInfo.vue'

export default defineComponent({
  name: 'NotificationRenderer',
  components: {
    ButtonTransparent,
    NotificationRendererIcon,
    NotificationRendererButtonRead,
    NotificationRendererMetaInfo,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
    iconVariant: {
      type: String,
      default: 'author',
    },
    icon: {
      type: [String, Object, Array],
      default: null,
    },
    iconClass: {
      type: [String, Object, Array],
      default: null,
    },
    avatarShowName: {
      type: Boolean,
      default: false,
    },
    avatarFontSize: {
      type: String,
      default: '17px',
    },
    badgeIcon: {
      type: String,
      default: null,
    },
    badgeClass: {
      type: [String, Object, Array],
      default: 'bg-green-500',
    },
    badgeCount: {
      type: Number,
      default: null,
    },
  },
  setup(props, context) {
    const isGroupExpanded = ref(false)
    const toggleGroup = () => {
      isGroupExpanded.value = !isGroupExpanded.value
    }

    /** @type {import('./NotificationList.vue').Options} */
    const options = inject('options')

    const hasEvents = computed(() => isNonEmptyArray(props.notification.events))

    const markAsRead = () => {
      context.emit('mark-as-read', props.notification)
    }

    return {
      markAsRead,
      options,
      isGroupExpanded,
      toggleGroup,
      hasEvents,
    }
  },
})
</script>
