<template>
  <div class="mb-24">
    <component
      :is="component"
      v-if="component"
      :notification="notification"
    />
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { SUBJECT, ACTION } from '@/v2/services/notifications/notificationsTypes'
import {
  vGroupKeyActionItemsCreated,
  vGroupKeyActionItemsResolved,
  vGroupKeyCommentsCreated,
  vGroupKeyCommentsResolved,
} from '@/v2/services/projectEvents/compositions/useProjectEvents'

import ItemVGroupActionItemsCreated from './ItemVGroupActionItemsCreated.vue'
import ItemVGroupActionItemsResolved from './ItemVGroupActionItemsResolved.vue'
import ItemVGroupCommentsCreated from './ItemVGroupCommentsCreated.vue'
import ItemVGroupCommentsResolved from './ItemVGroupCommentsResolved.vue'
import ItemDocumentsDrafted from './ItemDocumentsDrafted.vue'
import ItemDocumentsApproved from './ItemDocumentsApproved.vue'
import ItemGroupProjectsNewShares from './ItemGroupProjectsNewShares.vue'

const COMPONENT_MAP = {
  vGroup: {
    [vGroupKeyActionItemsCreated]: ItemVGroupActionItemsCreated,
    [vGroupKeyActionItemsResolved]: ItemVGroupActionItemsResolved,
    [vGroupKeyCommentsCreated]: ItemVGroupCommentsCreated,
    [vGroupKeyCommentsResolved]: ItemVGroupCommentsResolved,
  },
  [SUBJECT.documents]: {
    // -- single
    [ACTION[SUBJECT.documents].drafted]: ItemDocumentsDrafted,
    [ACTION[SUBJECT.documents].approved]: ItemDocumentsApproved,
  },
  [SUBJECT.projects]: {
    // ProjectTimelineoups
    [ACTION[SUBJECT.projects].newSharesVendor]: ItemGroupProjectsNewShares,
    [ACTION[SUBJECT.projects].newSharesClient]: ItemGroupProjectsNewShares,
  },
}

export default defineComponent({
  name: 'ProjectTimelineItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => COMPONENT_MAP?.[props.notification.subjectType]?.[props.notification.action]
    )

    return {
      component,
    }
  },
})
</script>
