<template>
  <!-- If on large screens, we'll show the table -->
  <div
    v-if="isLargeScreen"
    class="table-row"
    :class="{ freebie: !billable.isBillable }"
  >
    <div class="table-cell name " data-cell="Name">
      <span class="mr-16 table-text">{{ billable.name }}</span>
      <b-badge
        v-if="!billable.isBillable"
        variant="neutral"
        class="ml-6"
      >
        {{ $t('blocks.cost.not-billable') }}
      </b-badge>
    </div>
    <!-- <div class="table-cell unit" data-cell="Unit">
      <span class="table-text">{{ billable.unit }}</span>
    </div> -->
    <div class="table-cell" data-cell="Qty">
      <span class="mr-16 table-text">
        {{ billable.quantity }}
        {{ unitMap[billable.unit] }}
      </span>
    </div>
    <div class="table-cell" data-cell="Item">
      <span class="mr-16">
        <DocumentCurrency
          :value="Number(billable.rate)"
          :document-id="documentId"
        />
      </span>
    </div>
    <div class="table-cell cost " data-cell="Cost">
      <DocumentCurrency
        :value="Number(billable.rate) * Number(billable.quantity)"
        :document-id="documentId"
      />
    </div>
  </div>
  <!-- If on mobile / smaller devices -->
  <div
    v-else
    :class="[
      'flex flex-col relative',
      'py-12 px-12 bg-gray-100 bg-opacity-40 rounded-md space-y-8',
      'text-16 mb-8 text-gray-900',
      'leading-none'
    ]"
  >
    <b-badge
      v-if="!billable.isBillable"
      variant="neutral"
      :class="[
        'absolute top-0 right-0',
        'rounded-tl-none rounded-br-none',
        'rounded-bl-md rounded-tr-md',
        'ring-0 bg-gray-200 bg-opacity-50 text-gray-700',
        'px-6'
      ]"
    >
      {{ $t('blocks.cost.not-billable') }}
    </b-badge>
    <!-- Name -->
    <span class="flex flex-1 font-semibold table-text">{{ billable.name }}</span>

    <!-- Cost -->
    <div class="flex flex-row items-center space-between">
      <!-- Cost math -->
      <div class="text-gray-600">
        <DocumentCurrency :value="Number(billable.rate)" :document-id="documentId" />
        &times;
        {{ billable.quantity }}
        {{ unitMap[billable.unit] }}
      </div>
      <!-- Total for item -->
      <span
        :class="[
          'grow text-right ',
          !billable.isBillable ? 'font-normal line-through text-gray-600' : 'font-semibold',
        ]"
      >
        <DocumentCurrency
          :value="Number(billable.rate) * Number(billable.quantity)"
          :document-id="documentId"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import DocumentCurrency from '@/components/DocumentCurrency.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'

export default defineComponent({
  name: 'DocumentContentBlockCostItem',
  components: {
    DocumentCurrency,
  },
  props: {
    billable: {
      type: Object,
      required: true,
    },
    isEditing: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const isLargeScreen = computed(() => context.root.$mq === 'xxl' || ['XXL', 'XL', 'L'].includes(props.nodeWidth))
    const { pluralization } = useLocalization()

    const unitMap = computed(() => ({
      hour: pluralization('blocks.cost.hour', props.billable.quantity).value,
      day: pluralization('blocks.cost.day', props.billable.quantity).value,
      week: pluralization('blocks.cost.week', props.billable.quantity).value,
      month: pluralization('blocks.cost.month', props.billable.quantity).value,
      year: pluralization('blocks.cost.year', props.billable.quantity).value,
      item: pluralization('blocks.cost.item', props.billable.quantity).value,
      'flat fee': pluralization('blocks.cost.flat-fee', props.billable.quantity).value,
    }))
    return {
      isLargeScreen,
      document,
      unitMap,
    }
  },
})
</script>
