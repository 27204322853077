<template>
  <DocumentNodeEditorToolbarPopoverLight
    :target-id="popoverTargetId"
    class-name="rounded hover:bg-gray-500 hover:bg-opacity-10 w-32 editor-toolbar-button"
    triggers="click"
    tooltip="Customize block"
    :close-on-click-outside="!preventPopoverClose"
  >
    <template #button-content>
      <span class="w-24 text-24 icon-toolbar-settings" />
      <span :class="[$style['toolbar-dropdown']]" />
    </template>
    <template #default>
      <slot />
    </template>
  </DocumentNodeEditorToolbarPopoverLight>
</template>

<script>
import DocumentNodeEditorToolbarPopoverLight from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopoverLight.vue'

export default {
  name: 'DocumentNodeEditorToolbarSettings',
  components: {
    DocumentNodeEditorToolbarPopoverLight,
  },
  props: {
    popoverTargetId: {
      type: String,
      default: null,
    },
    preventPopoverClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" module>
.toolbar-dropdown{
  border-width: 0 0 6px 6px;
  border-color: transparent transparent rgba(255,255,255,0.4) transparent;
  @apply w-0 h-0 border-solid absolute bottom-0 right-0;
}
</style>
