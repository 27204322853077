
<div
  class="relative group"
>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
    custom
  >
    <div
      :href="href"
      class="h-full col-span-1 row-span-1 p-0
      cursor-pointer card-interactive transition-all pt-24 pb-16
      min-h-[160px]"
      @click="navigate"
    >
      <div class="h-full flex flex-col grow items-center justify-center px-16 space-y-4">
        <span :class="icon" class="mb-16 portal-accent-foreground text-32 md:text-24"></span>
        <h4
          class="font-semibold leading-none text-center text-18 portal-heading-font dark:text-darkGray-300"
        >
          {{ label }}
        </h4>
        <span
          v-if="description"
          class="font-normal text-center text-12 opacity-40 dark:text-darkGray-400"
        >
          {{ description }}
        </span>
      </div>
    </div>
  </router-link>
</div>
