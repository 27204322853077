import { path, F } from 'ramda'
import validatorsService from './validatorsService'
import { VALIDATOR } from './validatorsTypes'

const isValid = path(['result', 'isValid'])

const v = (validator, payload) => validatorsService
  .create({ command: validator, payload })
  .then(result => {
    if (!isValid(result)) {
      throw new Error('validation failed')
    }

    return true
  })
  .catch(F)

export default () => ({
  emailAvailable: email => v(VALIDATOR['users.email'], { email }),
  subdomainAvailable: subdomain => v(VALIDATOR['organizations.subdomain'], { subdomain }),
  domainAvailable: domain => v(VALIDATOR['organizations.domain'], { domain }),
  oembedUrlSupported: url => v(VALIDATOR['oembeds.url'], { url }),
  organizationEmailAvailable: email => v(VALIDATOR['organizations.email'], { email }),
})

