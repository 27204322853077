<template>
  <div
    v-if="list"
    class="flex flex-1 flex-grow w-full babababa"
  >
    <div
      role="link"
      class="fs-row-wrapper l"
    >
      <div class="flex items-center justify-start flex-1">
        <div class="flex items-center">
          <!----><!----><!---->
          <div class="fs-row-icon-wrapper flex items-center justify-center">
            <span class="text-24 text-gray-400">?</span>
          </div>
          <div class="fs-card-title-list">
            <div class="relative group portal-paragraph-font">
              <p class="hover:underline hover:cursor-pointer text-gray-400">
                <span>unkown</span>
              </p>
            </div>
          </div>
        </div>
        <!----><!---->
      </div>
      <div
        class="relative flex items-center justify-start space-x-8 z-1"
      >
        <!----><!----><!----><!----><!---->
        <div
          class="flex items-center justify-center w-26 h-26 text-gray-400 dark:text-darkGray-600"
        >
          <span
            class="icon_v2-so_shared text-16 md:text-24 w-16 md:w-24"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="fs-card-width-l">
    <div class="relative z-20 flex justify-center w-full h-full group">
      <div class="fs-card-wrapper" :class="size">
        <div class="fs-card-container">
          <div role="link" class="fs-card-body fs-card--doc">
            <div class="fs-card--doc-body text-gray-400">
              <div class="flex flex-col items-center justify-center flex-1">
                <div
                  class="fs-card--doc-icon-wrapper"
                  :class="{ 'text-56': size === 'l', 'text-24': size === 'm' }"
                >
                  ?
                </div>
                <div
                  class="mt-8"
                  :class="{ 'text-12': size === 'l', 'text-10': size === 'm' }"
                >
                  unkown
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
        <div class="fs-card-title-card">
          <div class="relative group portal-paragraph-font">
            <p class="hover:no-underline text-gray-400">???</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FileNoLongerAvailable',
  props: {
    size: {
      type: String,
      default: 'm',
    },
    list: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
