<template>
  <div>
    <p
      class="text-14 text-gray-700 dark:text-darkGray-400 leading-snug mb-16"
    >
      <span>Showing comments on</span>
      <transition
        name="fade"
        class="inline-flex"
        mode="out-in"
      >
        <span
          class=" bg-yellow-50 font-semibold text-gray-800 px-2 mx-2"
        >
          {{ contentPreview }}
        </span>
      </transition>
      block
    </p>
    <div class="mb-16">
      <a
        class="text-13 text-gray-700 dark:text-darkGray-400 underline mt-4 mb-12
            hover:text-gray-600 dark:hover:text-darkGray-300 hover:no-underline cursor-pointer"
        @click="$emit('show-all-comments')"
      >
        View all document comments
      </a>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { CATEGORY as CONTENT_BLOCK_CATEGORY, LABEL as CONTENT_BLOCK_LABEL } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { LABEL as NODE_LABEL } from '@/v2/services/documentNodes/documentNodesTypes'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'
import htmlTextContent from '@/helpers/html-text-content'

function getDocData(docId) {
  const vm = getCurrentInstanceOrThrow()
  const { Document } = vm.$FeathersVuex.api
  return Document.getFromStore(docId)
}

function getContentFromElement(contentBlock) {
  let result = ''
  switch (contentBlock.category) {
    case CONTENT_BLOCK_CATEGORY.ElementHeading:
      result = htmlTextContent(contentBlock.heading, 32)
      // check that the result string is not just whitespaces
      return (isNotNilOrEmpty(result.trim())) ? result : 'Heading'

    case CONTENT_BLOCK_CATEGORY.ElementText:
      result = htmlTextContent(contentBlock.content, 32)
      return (isNotNilOrEmpty(result.trim())) ? result : 'Text'

    case CONTENT_BLOCK_CATEGORY.ElementButton:
      return `${contentBlock.settings.label} Button`

    case CONTENT_BLOCK_CATEGORY.ElementEmbed:
      return `${contentBlock.data.provider_name} Embed`

    case CONTENT_BLOCK_CATEGORY.ElementQA:
      return `${contentBlock.title} Q&A`

    case CONTENT_BLOCK_CATEGORY.ElementEstimate:
    case CONTENT_BLOCK_CATEGORY.ElementBrief:
    case CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument:
      return getDocData(contentBlock.embeddedDocument).title

    default:
      return CONTENT_BLOCK_LABEL[contentBlock.category]
  }
}

function nodeLabel(node) {
  if (node.isGroup) {
    return NODE_LABEL[node.category]
  }
  return getContentFromElement(node.contentBlock$)
}

export default defineComponent({
  name: 'CommentsHeader',
  props: {
    nodeId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const node = computed(() => {
      if (props.nodeId) {
        const vm = getCurrentInstanceOrThrow();
        const { DocumentNode } = vm.$FeathersVuex.api
        return DocumentNode.getFromStore(props.nodeId)
      }
      return null
    })
    const contentPreview = computed(() => node.value && nodeLabel(node.value))
    return {
      contentPreview,
      node,
    }
  },

})
</script>

<style lang="scss" scoped>

</style>
