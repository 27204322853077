
<div>
  <PageHeader :title="$t('settings.authentication')" />
  <UserSettingsPassword class="max-w-420">
    <template #buttons="{ submit, hasChanges }">
      <PageFooter>
        <Button
          size="lg"
          variant="primary"
          type="submit"
          @click="submit"
        >
          {{ $t('common.save-changes') }}
        </Button>
        <template v-if="hasChanges" #right>
          <PageFooterMessage />
        </template>
      </PageFooter>
    </template>
  </UserSettingsPassword>
</div>
