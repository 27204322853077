<template>
  <div>
    <div class="relative">
      <AnswerBadge
        v-if="['XL', 'XXL'].includes(nodeWidth)"
        :is-answerable="isAnswerable"
        :has-no-answer="!contentBlock.isDirty"
        position="middle"
      />
      <b-form-rating
        v-if="contentBlock.settings.selectedType === 'icon'"
        class="p-0 space-x-4 bg-transparent"
        no-border
        size="lg"
        :value="contentBlock.answer"
        :stars="steps"
        :disabled="isLocked"
        number
        @change="value => $emit('update', { answer: value, isDirty: true })"
      >
        <template
          slot="icon-full"
        >
          <span :class="fullRatingIcon" />
        </template>
        <template
          slot="icon-empty"
        >
          <span :class="outlineRatingIcon" />
        </template>
      </b-form-rating>
      <DocumentContentBlockQARatingNumber
        v-else
        :value="contentBlock.answer"
        :steps="steps"
        @update="data => $emit('update', data)"
      />
    </div>
    <div
      v-if="showLabel"
      class="flex mt-4 mb-8"
    >
      <div
        v-if="leftLabel"
        class="flex items-center justify-start flex-1"
      >
        <span
          :class="['inline-block px-5 py-2 rounded',
                   'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
        >{{ leftLabel }}</span>
      </div>
      <div
        v-if="centerLabel"
        class="flex items-center justify-center flex-1 text-center"
      >
        <span
          :class="['inline-block px-5 py-2 rounded',
                   'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
        >{{ centerLabel }}</span>
      </div>
      <div
        v-if="rightLabel"
        class="flex items-center justify-end flex-1"
      >
        <span
          :class="['inline-block px-5 py-2 rounded',
                   'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
        >{{ rightLabel }}</span>
      </div>
    </div>
    <Notes
      v-if="contentBlock.settings.hasNotes"
      :is-answerable="isAnswerable"
      :text-variant="textVariant"
      :value="contentBlock.notes"
      @input="data => $emit('update', data)"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import { computed, inject } from '@vue/composition-api'
import Notes from '@/components/Notes.vue'
import AnswerBadge from '@/components/Document/AnswerBadge.vue'
import DocumentContentBlockQARatingNumber from './DocumentContentBlockQARatingNumber.vue'

const icons = [
  {
    full: 'h-16 md:h-24 icon-util-star-full text-16 md:text-24',
    outline: 'h-16 md:h-24 icon-util-star-outline text-16 md:text-24',
    value: 'star',
  },
  {
    full: 'h-16 md:h-24 icon-heart text-16 md:text-24',
    outline: 'h-16 md:h-24 icon-util-heart-outline text-16 md:text-24',
    value: 'heart',
  },
  {
    full: 'h-16 md:h-24 icon-util-person-full text-16 md:text-24',
    outline: 'h-16 md:h-24 icon-util-person-outline text-16 md:text-24',
    value: 'user',
  },
  {
    full: 'h-16 md:h-24 icon-util-price text-16 md:text-24',
    outline: 'h-16 md:h-24 icon-util-price text-16 md:text-24',
    value: 'price',
  },
]

export default {
  name: 'DocumentContentBlockQARating',
  components: {
    DocumentContentBlockQARatingNumber,
    Notes,
    AnswerBadge,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isAnswerable: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },

  setup(props) {
    const isLocked = inject('isLocked')

    // icons
    const ratingIcon = computed(() => R.find(R.propEq('value', props.contentBlock.settings.selectedIcon))(icons))
    const fullRatingIcon = computed(() => ratingIcon.value.full)
    const outlineRatingIcon = computed(() => ratingIcon.value.outline)
    // labels
    const showLabel = computed(() => props.contentBlock.settings.showLabel)
    const leftLabel = computed(() => props.contentBlock.settings.leftLabel)
    const centerLabel = computed(() => props.contentBlock.settings.centerLabel)
    const rightLabel = computed(() => props.contentBlock.settings.rightLabel)
    // steps
    const steps = computed(() => props.contentBlock.settings.steps)

    const answerBadgePortalName = computed(() => `answerBadgePortalName-${props.nodeId}`)

    return {
      isLocked,
      icons,
      ratingIcon,
      fullRatingIcon,
      outlineRatingIcon,
      showLabel,
      centerLabel,
      leftLabel,
      rightLabel,
      steps,
      answerBadgePortalName,
    }
  },
}
</script>
<style lang="scss" module></style>
