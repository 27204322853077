
<!-- <div>{{ notification.subjectType }} - {{ notification.action }} - {{ component.name }}</div> -->
<component
  :is="component"
  v-if="component"
  :notification="notification"
  @mark-as-read="notification => $emit('mark-as-read', notification)"
/>
<b-alert v-else variant="danger">
  Unable to display {{ notification.subjectType }} / {{ notification.action }}
</b-alert>
