<template>
  <router-link
    v-slot="{ navigate, href }"
    :to="{
      name: PROJECT_DASHBOARD,
      params: { projectId: notification.project }
    }"
    custom
  >
    <slot v-bind="{ navigate, href }">
      <a
        class="font-semibold"
        :href="href"
        @click="navigate"
      >
        {{ notification.project$.name }}
      </a>
    </slot>
  </router-link>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { PROJECT_DASHBOARD } from '@/router/project/type'

export default defineComponent({
  name: 'NotificationLinkProjectActionItems',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      PROJECT_DASHBOARD,
    }
  },
})
</script>
