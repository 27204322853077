
<FormEntityEditor
  :id="actionItemId"
  :key="Date.now()"
  v-slot="{ state, save, entity }"
  :defaults="formDefaults"
  :fields="['title', 'notes', 'dueDate', 'public', 'assignedTo']"
  model="ActionItem"
  @save="$emit('save')"
>
  <FeatureLimit
    v-slot="{ checkFeatureLimit }"
    flag="limitActionItems"
    label="action items"
    @allow="saveAndTrack(save)"
  >
    <Form
      v-if="state"
      v-slot="{ submit, v }"
      :state="state"
      :validation="validation"
      :on-submit="() => checkFeatureLimit()"
      @submit="titleRef.focus()"
      @touched="onFormTouched"
    >
      <div
        class="
        flex flex-col items-stretch
        bg-gray border border-gray-200 rounded-md
        dark:bg-darkGray-800 dark:border-darkGray-600
        mt-8 mb-8
      "
        :class="[
          itemClass.formSpacing,
          itemClass.itemFontSize,
        ]"
      >
        <!-- TITLE -->
        <div
          class="
          inline-flex items-center w-full pb-8 leading-none
          text-gray-800 border-b border-gray-200 dark:text-darkGray-300
          dark:border-darkGray-600
        "
          :class="[
            v.title.$dirty && v.title.$invalid
              && 'border border-dotted border-red-500 p-4 rounded-md mt-4',
            itemClass.itemTitleMargin,
          ]"
        >
          <b-form-checkbox
            disabled
            class="mr-4 custom-checkbox-list"
          />
          <ContentEditable
            ref="titleRef"
            v-model="state.title"
            :placeholder="$t('action-items.title-placeholder')"
            tag="span"
            lazy
            :no-newline="true"
            :no-html="true"
            class="mr-20"
            autofocus
            @returned="() => submit()"
          />
        </div>
        <!-- META DATA -->
        <div>
          <!-- Due date -->
          <TaskDatePicker
            :id="`due-date-${actionItemId}`"
            v-model="state.dueDate"
            class="mr-2"
            clearable
          />
          <!-- Assign to -->
          <label class="relative block mt-8 grow">
            <p
              v-b-tooltip.hover.left.v-info.dh0.ds200="$t('action-items.assign-tooltip')"
              class="leading-none hover:cursor-pointer inline-flex items-center"
              @click="toggleAssignTo"
            >
              <span
                class="
                mr-4 text-gray-600 dark:text-darkGray-400
                icon_v2-so_users text-24
              "
              />
              <ProfileGetByUser
                v-if="state.assignedTo"
                v-slot="{ profile }"
                :user-id="state.assignedTo"
              >
                <div
                  class="text-gray-600 dark:text-darkGray-300 flex items-center"
                  :class="itemClass.metaFontSize"
                >
                  {{ $t('action-items.assign-placeholder') }}
                  <span class="inline-flex h-24 ml-4 text-11">
                    <Avatar
                      :profile="profile"
                      :size="24"
                      font-size="12"
                    />
                  </span>
                </div>
              </ProfileGetByUser>
              <span
                v-else
                class="text-gray-600 dark:text-darkGray-400"
                :class="itemClass.metaFontSize"
              >
                {{ $t('action-items.assign-action') }}
              </span>
            </p>
            <div class="mt-4">
              <b-form-select
                v-if="showAssignTo"
                v-model="state.assignedTo"
                :options="memberOptions"
                class="w-full"
              >
                <b-form-select-option :value="null" disabled>
                  Select a person to assign this action item to them
                </b-form-select-option>
              </b-form-select>
            </div>

          </label>
          <!-- Notes -->
          <label class="relative block mt-8 grow">
            <p
              v-b-tooltip.hover.left.v-info.dh0.ds200="$t('action-items.note-tooltip')"
              class="leading-none hover:cursor-pointer inline-flex items-center"
              @click="toggleEditNote"
            >
              <span
                class="
                mr-4 text-gray-600 icon_v2-so_file text-24
                dark:text-darkGray-400
              "
              />
              <span
                class="text-gray-600 dark:text-darkGray-400"
                :class="[
                  itemClass.metaFontSize,
                  state.notes && 'truncate max-w-112',
                ]"
              >
                {{ state.notes ? state.notes : $t('action-items.add-note') }}
              </span>
            </p>
            <b-textarea
              v-if="showEditNote"
              v-model="state.notes"
              v-focus
              size="lg"
              :max-rows="8"
              :placeholder="$t('action-items.note-placeholder')"
              class="
              flex-1 grow placeholder-gray-800 border-none rounded-md overflow-hidden
              bg-yellow-50 focus:bg-yellow-50 placeholder-opacity-30 dark:bg-darkGray-1000
              dark:focus:bg-darkGray-1000 px-12 py-8 mt-4
            "
              :class="itemClass.itemFontSize"
            />
          </label>
          <!-- Client will see ACTIVE -->
          <div v-if="!isClientContact" :class="['mt-8', itemClass.metaFontSize]">
            <ClientVisibilityBadge
              variant="full"
              :visible="state.public"
              interactive
              @toggle-visibility="state.public = !state.public"
            />
          </div>
          <div class="mt-12 flex justify-between">
            <div class="flex gap-x-8">
              <b-button
                type="submit"
                :size="itemClass.buttonSize"
                variant="primary"
              >
                <span class="mr-4 -ml-4 icon_v2-so_tick text-18 text-gree-600"></span>
                <span>{{ $t('common.save') }}</span>
              </b-button>
              <b-button
                type="button"
                :size="itemClass.buttonSize"
                variant="neutral"
                class="capitalize"
                @click="!(void $tracking.track('New Action Item Form Closed')) && $emit('cancel')"
              >
                <span class="mr-4 -ml-4 icon_v2-so_close text-18 text-gray-600 dark:text-darkGray-400"></span>
                <span>{{ $t('common.cancel') }}</span>
              </b-button>
            </div>
            <b-button
              v-if="actionItemId !== 'new'"
              type="button"
              :size="itemClass.buttonSize"
              variant="neutral"
              class="shadow capitalize"
              @click="$emit('remove', entity)"
            >
              <span class="mr-4 -ml-4 icon_v2-so_trash text-18 text-red-600"></span>
              <span>Delete</span>
            </b-button>
          </div>
        </div>
      </div>
    </Form>
  </FeatureLimit>
</FormEntityEditor>
