
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  :root-class="$style['node-text']"
  :config-override="{
    stylingToolbar: {
      features: {
        textColor: true,
        fontFamily: true,
      },
    },
    styling: {
      size: 'S',
    },
  }"
>
  <template #default="{ styling, textVariant }">
    <DocumentContentBlockText
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
