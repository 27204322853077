<template>
  <span
    :style="{ backgroundColor: labelBackgroundColor, color: textColor }"
    class="inline-flex items-center justify-center flex-none font-extrabold leading-none"
    :class="[
      !fullLabel && fontSize,
      !fullLabel && labelSize,
      fullLabel && 'w-full p-6 font-medium text-11 rounded-full'
    ]"
  >
    <span v-if="!noText">{{ displayLabel }}</span>
  </span>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import tinycolor from 'tinycolor2'

export default {
  name: 'LabelAvatar',
  props: {
    label: {
      type: String,
      default: 'P',
    },
    size: {
      type: String,
      default: '24',
    },
    backgroundColor: {
      type: String,
      default: '#6B7280',
    },
    noText: {
      type: Boolean,
      default: false,
    },
    fullLabel: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const colorMap = ref([
      {
        backgroundColor: '#ef4444',
        textColor: '#ffe4e6',
      },
      {
        backgroundColor: '#65a30d',
        textColor: '#ecfccb',
      },
      {
        backgroundColor: '#facc15',
        textColor: '#713f12',
      },
      {
        backgroundColor: '#ea580c',
        textColor: '#ffedd5',
      },
      {
        backgroundColor: '#16a34a',
        textColor: '#dcfce7',
      },
      {
        backgroundColor: '#7c3aed',
        textColor: '#ede9fe',
      },
      {
        backgroundColor: '#0d9488',
        textColor: '#ccfbf1',
      },
      {
        backgroundColor: '#0284c7',
        textColor: '#e0f2fe',
      },
      {
        backgroundColor: '#c026d3',
        textColor: '#fae8ff',
      },
      {
        backgroundColor: '#e11d48',
        textColor: '#ffe4e6',
      },
    ])
    // Dict for the size prop
    const sizes = {
      8: {
        fontSize: 'text-10',
        labelSize: 'w-8 h-8 rounded-full',
      },
      12: {
        fontSize: 'text-10',
        labelSize: 'w-12 h-12 rounded-full',
      },
      16: {
        fontSize: 'text-12',
        labelSize: 'w-16 h-16 rounded-full',
      },
      20: {
        fontSize: 'text-13',
        labelSize: 'w-20 h-20 rounded',
      },
      24: {
        fontSize: 'text-14',
        labelSize: 'w-24 h-24 rounded',
      },
      32: {
        fontSize: 'text-20',
        labelSize: 'w-32 h-32 rounded-md',
      },
      40: {
        fontSize: 'text-30',
        labelSize: 'w-40 h-40 rounded-md',
      },
      48: {
        fontSize: 'text-36',
        labelSize: 'w-48 h-48 rounded-lg',
      },
    }

    const numberFromText = () => parseInt(props.label.trim().charCodeAt(0), 10)

    const labelBackgroundColor = computed(
      () => (props.backgroundColor
        ? props.backgroundColor
        : colorMap.value[numberFromText() % colorMap.value.length].backgroundColor)
    )

    const accentColorSpace = computed(() => tinycolor(props.backgroundColor).monochromatic(3))
    const mostReadable = computed(() => tinycolor.mostReadable(
      props.backgroundColor,
      [
        ...accentColorSpace.value,
        '#FFFFFF',
      ],
      {
        level: 'AA',
        size: 'large',
      }
    ).toHexString())

    const textColor = computed(
      () => (props.backgroundColor
        ? mostReadable.value
        : colorMap.value[numberFromText() % colorMap.value.length].textColor)
    )

    const fontSize = computed(() => sizes[props.size].fontSize)
    // => 'text-16'

    const labelSize = computed(() => sizes[props.size].labelSize)
    // => 'w-24 h-24'

    const displayLabel = computed(
      () => (props.fullLabel ? props.label : props.label?.trim().slice(0, 1))
    )

    return {
      colorMap,
      sizes,
      labelBackgroundColor,
      textColor,
      fontSize,
      labelSize,
      displayLabel,
    }
  },
}
</script>
