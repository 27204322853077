
<b-alert
  v-if="announcement"
  class="flex items-center mb-0 print:hidden"
  :variant="announcement.variant || 'info'"
  show
>
  <span class="w-20 mr-8 standard-lightbulb_outline text-20"></span>
  <div class="flex-1 text-14">{{ announcement.message }}</div>
  <Button
    v-if="announcement.type === ANNOUNCEMENT_TYPE.newAppVersion"
    :disabled="reloadRequested"
    @click="reload"
  >
    Update Now
  </Button>
</b-alert>
