<template>
  <span
    class="
      inline-flex items-center justify-center
      flex-none border rounded-100
    "
    :class="[
      `tick-icon-wrapper-${size}`,
      {
        'text-white bg-green-500 border-green-500':
          checked,
        'text-gray-400 bg-white border-gray-300':
          !checked,
      }]"
  >
    <span
      class="icon_v2-so_tick"
      :class="`tick-icon-${size}`"
    />
  </span>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TickIcon',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validate: val => ['sm', 'md', 'lg'].includes(val),
    },
  },
})
</script>
<style lang="postcss" scoped>
  .tick-icon-wrapper-sm {
    @apply w-16 h-16;

    .tick-icon-sm {
      @apply text-14;
    }
  }

  .tick-icon-wrapper-md {
    @apply w-24 h-24;

    /* .tick-icon-md {
      @apply text-16;
    } */
  }
</style>
