import {
  pipe, when, defaultTo, head, uncurryN, applySpec, path, prop, complement, eqProps, converge,
  equals, sortBy, identity, keys, lift, mergeRight, reduce, init, last, reject, isEmpty, isNil,
  either, find, assocPath, hasPath,
} from 'ramda'

import { rejectNil, isNilOrEmpty, isFalsy } from 'ramda-extension'

export const applySpecRejectNil = spec => pipe(applySpec(spec), rejectNil)

export const headOr = uncurryN(2, defaultVal => pipe(when(Array.isArray, head), defaultTo(defaultVal)))

export const pathIsNilOrEmpty = uncurryN(2, a => pipe(path(a), isNilOrEmpty))
export const pathNotNilOrEmpty = complement(pathIsNilOrEmpty)

export const propIsNilOrEmpty = uncurryN(2, a => pipe(prop(a), isNilOrEmpty))
export const propNotNilOrEmpty = complement(propIsNilOrEmpty)
export const notEqProps = complement(eqProps)
export const restrictValue = (min, max) => value => Math.max(min, Math.min(value, max))

export const keysEq = keyList => converge(equals, [
  pipe(keys, sortBy(identity)),
  () => sortBy(identity, keyList),
])

export const mergeSpec = spec => lift(mergeRight)(identity, applySpec(spec));

export const rejectNilOrEmpty = reject(either(isNil, isEmpty));
export const rejectFalsy = reject(isFalsy)

export const splitChunks = condFn => reduce((acc, node) => {
  if (condFn(node)) {
    return [...acc, [node]]
  }

  const chunk = last(acc)
  return Array.isArray(chunk)
    ? [...init(acc), [...chunk, node]]
    : acc
}, [])


export const omitCond = predicate => obj => Object.keys(obj)
  .reduce((acc, key) => (predicate(key) ? { ...acc } : { ...acc, [key]: obj[key] }), {})

export const switchCond = pipe(
  find(([cond]) => Boolean(cond)),
  ([, value] = []) => value
);

export const switchCondOr = uncurryN(2, defaultValue => pipe(
  find(([cond]) => Boolean(cond)),
  ([, value] = []) => value,
  defaultTo(defaultValue)
));

export const pickPath = (paths, obj) => paths
  .filter(p => hasPath(p, obj))
  .reduce((acc, p) => assocPath(p, path(p, obj), acc), {})

export const pickDotPath = (dotPaths, obj) => pickPath(
  dotPaths.map(p => p.split('.')),
  obj
)

