
<b-alert
  show
  :variant="variant"
  :class="$style.alert"
>
  <div v-if="!supressGenericMessage && (variant === 'danger')" class="text-11">
    There was an error submitting the form
  </div>
  <slot />
</b-alert>
