<template>
  <DocumentLink
    v-if="document"
    v-slot="{ navigate }"
    :document="document"
  >
    <DocumentResolver
      v-slot="{ component }"
      :document="document"
    >
      <!-- Component from doc category -->
      <component
        :is="component"
        :document="document"
        :size="size"
        :read-only="readOnly"
        @select="navigate"
      />
    </DocumentResolver>
  </DocumentLink>
</template>
<script>
import { rejectNil } from 'ramda-extension'
import { defineComponent, computed, inject } from '@vue/composition-api'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentLink from '@/components/DocumentLink.vue'
import DocumentResolver from '@/v2/features/document/documentNodeFolder/DocumentResolver.vue'


export default defineComponent({
  name: 'DocumentPreviewCard',
  components: {
    DocumentLink,
    DocumentResolver,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
    },
  },
  setup(props) {
    const isClientPortal = inject('isClientPortal', false)
    const isFolder = computed(() => props.document.category === DOCUMENT_CATEGORY.Folder)

    const routerParams = computed(() => (isClientPortal ? rejectNil({
      projectId: props.document.project,
      token: !isFolder.value ? props.document.token : null,
      documentId: isFolder.value ? props.document._id : null,
    }) : rejectNil({
      projectId: props.document.project,
      documentId: props.document._id,
      organizationId: props.document.organization,
    })))

    return {
      routerParams,
      isClientPortal,
    }
  },
})
</script>
