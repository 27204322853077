
<div class="relative flex flex-col w-full">
  <slot />
  <!-- <pre>selectedNodeId={{ selectedNodeId }}</pre>
  <div>isAutoScrolling={{ isAutoScrolling }}</div>
  <div>isScrolling={{ isScrolling }}</div> -->
  <ul class="w-full max-h-screen p-0 list-none grow">
    <TocItem
      v-for="node in tocNodesList"
      :key="node._id"
      :node="node"
      :category="node.contentBlock$?.category"
      :selected-node-id="tocActiveNodeId"
      @go-to-node="goToNode(node)"
    />
  </ul>
  <!-- <div
    :class="[
      'absolute left-0 right-0 bottom-0',
      'h-128 z-1 pointer-events-none',
      'bg-gradient-to-t from-gray-100',
    ]"
  >
  </div> -->
</div>
