import { CATEGORY } from '../documentsTypes'
import { useFeatureLimit } from '../../organizations/compositions'

const CATEGORY_FLAG = {
  [CATEGORY.DocumentPackagedService]: 'limitPackagedServices',
  [CATEGORY.DocumentPage]: 'limitPages',
  default: 'limitDocuments',
}

const CATEGORY_LABEL = {
  [CATEGORY.DocumentPackagedService]: 'packaged services',
  [CATEGORY.DocumentPage]: 'pages',
  default: 'documents',
}

export default function useDocumentLimit() {
  const { checkFeatureLimit } = useFeatureLimit()

  const checkDocumentLimit = (category, added = 1) => checkFeatureLimit(
    CATEGORY_LABEL[category] ?? CATEGORY_LABEL.default,
    CATEGORY_FLAG[category] ?? CATEGORY_FLAG.default,
    added
  )

  return {
    checkDocumentLimit,
  }
}
