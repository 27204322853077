
<LoaderWebFonts
  :families="[
    organization?.brandingFontHeading ?? 'Inter',
    organization?.brandingFontParagraph ?? 'Inter'
  ]"
>
  <div :style="cssVars">
    <slot v-bind="{ portalCustomizations }" />
  </div>
</LoaderWebFonts>
