<template>
  <draggable
    v-model="modelSections"
    tag="ul"
    class="kanban-columns"
    draggable=".column-item"
    :animation="200"
    group="columns"
    :disabled="readonlySections"
    ghost-class="ghost"
    direction="horizontal"
  >
    <KanbanColumn
      v-for="section in sections"
      :id="`section-${section.taskSection._id}`"
      :key="section.taskSection._id"
      class="column-item"
      :section="section"
      :selected-task-id="selectedTaskId"
      :lock-tasks-to-section="lockTasksToSection"
      :readonly-sections="readonlySections"
      :readonly-task="readonlyTask"
      :no-task-reordering="noTaskReordering"
      :no-section-menu="noSectionMenu"
      @remove="$emit('remove', section)"
      @update="data => $emit('update', section, data)"
      @menu-action="action => $emit('menu-action', section, action)"
      @create-task="index => $emit('create-task', section, index)"
      @remove-task="task => $emit('remove-task', section, task)"
      @drag-event-tasks="event => $emit('drag-event-tasks', section, event)"
    >
      <template #task="{ task }">
        <slot name="task" v-bind="{ section, task }" />
      </template>
    </KanbanColumn>
  </draggable>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import draggable from 'vuedraggable';
import KanbanColumn from '@/components/Kanban/KanbanColumn.vue'

export default defineComponent({
  name: 'KanbanColumns',
  components: {
    draggable,
    KanbanColumn,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    readonlySections: {
      type: Boolean,
      default: false,
    },
    readonlyTask: {
      type: [Boolean, Function],
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    noSectionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const modelSections = computed({
      get() {
        return props.sections
      },
      set(reorderedSections) {
        context.emit('reorder', reorderedSections)
      },
    })

    return {
      modelSections,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .kanban-columns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 240px;
    gap: 10px;
    list-style-type: none;
  }

  @media (max-width: 640px) {
    .kanban-columns {
      grid-auto-columns: calc(100vw - 30px);
    }
  }

  .ghost {
    @apply border border-gray-500;
  }
</style>
