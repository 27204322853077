<template>
  <ButtonTransparent @click="$emit('input', !value)">
    <span
      class="
          text-gray-400 inline-block transition duration-200 text-24
          ease-in-out transform icon_v2-so_direction-down
        "
      :class="{ '-rotate-180': value }"
    />
  </ButtonTransparent>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from './ButtonTransparent.vue'

export default defineComponent({
  name: 'ButtonCollapse',
  components: {
    ButtonTransparent,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
