
<div>
  <div v-if="hasProjects">
    <div>
      <FormField label="Select a project:">
        <!-- v-model="projectId" -->
        <b-select
          :value="projectId"
          size="lg"
          :options="projectOptions"
          @change="projectSelected"
        />
      </FormField>
    </div>
    <div v-if="projectId" class="mt-8">
      <FormField label="Select a folder:">
        <ProjectFolderTree
          :value="value"
          :project-id="projectId"
          :folder-category="folderCategory"
          :hidden-folder-categories="hiddenFolderCategories"
          @input="val => $emit('input', val)"
        />
      </FormField>
    </div>
  </div>
  <div v-else>No projects available</div>
</div>
