
<div
  v-bind="$attrs"
  class="w-full"
  :class="[ { dark }, wrapperClass]"
  :style="theme"
>
  <div
    :class="['page-background' && !noDarkMode, className]"
  >
    <slot />
  </div>
</div>
