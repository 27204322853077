<template>
  <DocumentGet
    :id="contentBlock.embeddedDocument"
    v-slot="{ document, isGetPending }"
    :params="{ embed: true }"
  >
    <div
      :class="['XXS','XS','S','M'].includes(nodeWidth) && 'sm:px-24'"
    >
      <FileDocList
        v-if="!isGetPending && document"
        :document="document"
        :standalone="standalone"
        :show-analytics="showAnalytics"
        :node-width="nodeWidth"
        v-bind="$attrs"
        v-on="$listeners"
        @click.stop="enableFullBlockClick && openDocument(document)"
      />
    </div>
  </DocumentGet>
</template>

<script>
import { inject, ref, defineComponent } from '@vue/composition-api'
import DocumentGet from '@/components/DocumentGet.vue'
import FileDocList from '@/components/FileDocList.vue'


export default defineComponent({
  name: 'DocumentContentBlockEmbeddedDoc',
  components: {
    DocumentGet,
    FileDocList,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    standalone: Boolean,
    showAnalytics: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const isExpanded = ref(false)
    const enableFullBlockClick = props.standalone ? false : inject('isViewer').value
    const openDocument = doc => context.emit('open', doc)

    return {
      enableFullBlockClick,
      openDocument,
      isExpanded,
    }
  },
})
</script>
