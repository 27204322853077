import { watch, provide, inject, computed, unref, getCurrentInstance } from '@vue/composition-api'

/**
 * @param {import('@vue/composition-api').Ref<string>} title
 */
const useBrowserTitle = (title, append = true) => {
  if (useBrowserTitle.debug) {
    const vm = getCurrentInstance().proxy
    // console.log('useBrowserTitle', vm.$options.name)
  }

  const injectedParentTitle = inject('title', '')
  const parentTitle = computed(() => unref(injectedParentTitle))

  const computedTitle = computed(
    () => (append ? [parentTitle.value, title.value].filter(Boolean).join(' - ') : title.value)
  )

  provide('title', computedTitle)

  watch(computedTitle, newTitle => {
    window.document.title = newTitle

    if (useBrowserTitle.debug) {
      // console.log('window title changed', newTitle)
    }
  },
  { immediate: true })
}

export default useBrowserTitle;
