
<div class="flex flex-col">
  <small class="flex-1 inline-block text-gray-500 text-12 relative"
    >{{ label }}

    <b-badge v-if="newBadge" variant="new" :class="$style['new-badge']"
      >NEW</b-badge
    >
  </small>
  <div class="flex items-center h-32"><slot /></div>
</div>
