<template>
  <div>
    <DocumentFromTemplateCategory
      v-if="!templatePreview"
      :category="templatesCategory"
      :show-blank="showBlank"
      :excluded-categories="excludedCategories"
      :templates-category="templatesCategory"
      @use="onTemplateUse"
      @preview="onTemplatePreview"
    />
    <!-- :document-id="templatePreview.documentId" -->
    <GuestTemplateDocument
      v-else
      :document-id="templatePreview.documentId"
      :category="templatePreview.template_category.name"
    />
  </div>
</template>

<script>
import DocumentFromTemplateCategory from '@/components/Document/DocumentFromTemplateCategory.vue'
import GuestTemplateDocument from '@/components/GuestTemplateDocument.vue'

export default {
  name: 'DocumentFromTemplate',
  components: {
    DocumentFromTemplateCategory,
    GuestTemplateDocument,
  },
  props: {
    templatePreview: {
      type: Object,
      default: null,
    },
    showBlank: {
      type: Boolean,
      default: false,
    },
    excludedCategories: {
      type: Array,
      default: () => [],
    },
    templatesCategory: {
      type: String,
      default: null,
    },
  },
  methods: {
    onTemplateUse(template) {
      this.$emit('select', template)
    },
    onTemplatePreview(template) {
      this.$emit('preview', template)
    },
  },
}
</script>
