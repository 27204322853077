import Vue from 'vue';
import { indexBy, mergeDeepRight, T } from 'ramda'
import { computed, watch } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {Object} MemberItem
 * @property {any} member
 * @property {any} user
 */


/**
 * @typedef {Object} Options
 * @property {Ref<Record<string, any>} params
 * @property {(item: MemberItem) => boolean} [filterFn]
 * @property {boolean} [joinUser]
 * @property {boolean} [joinProjects]
 */


/**
 * @param {Options} options
 * @returns {Ref<MemberItem[]>}
 */
function useFindMembers(options) {
  const organization = useCurrentOrganization()

  const {
    params,
    joinUser = false,
    joinProjects = false,
  } = options


  const { Member, User, Project } = Vue.$FeathersVuex.api

  // -- all projects
  const paramsProjects = computed(() => ((joinProjects && params.value) ? {
    query: {
      organization: organization.value._id,
    },
  } : null))

  const { items: projects, isPending: projectsPending } = useFind({
    model: Project,
    params: paramsProjects,
    local: true,
  })

  const indexedProjects = computed(() => indexBy(p => p._id, projects.value));

  // -- all members
  const paramsMembers = computed(() => (params.value ? mergeDeepRight(
    { query: { organization: organization.value._id } },
    params.value
  ) : null))

  const { items: members, isPending: membersPending } = useFind({
    model: Member,
    params: paramsMembers,
  })

  // -- users
  const paramsUser = computed(() => ((joinUser && members.value.length) ? {
    query: {
      _id: {
        $in: members.value.map(m => m.user),
      },
    },
  } : null))

  // -- users
  const { items: users, isPending: usersPending } = useFind({
    model: User,
    params: paramsUser,
  })

  const indexedUsers = computed(() => indexBy(u => u._id, users.value))

  // -- join everything
  const items = computed(() => members.value.map(member => ({
    member,
    user: indexedUsers.value[member.user] ?? null,
    projects: (member.projects ?? [])
      .map(projectId => indexedProjects.value[projectId])
      .filter(Boolean),
  })))

  const isPending = computed(() => [
    membersPending.value,
    projectsPending.value,
    usersPending.value,
  ].some(Boolean))

  return {
    items,
    isPending,
  };
}

export default useFindMembers
