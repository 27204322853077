
<ModalBasic
  v-bind="$attrs"
  title="Document Analytics"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  :dialog-class="$style['analytics-modal-dialog']"
  v-on="$listeners"
>
  <template #body>
    <DocumentAnalyticsTimeline :document-id="documentId" />
  </template>
</ModalBasic>
