/**
 * @deprecated Please use the dictionary in `src/v2/services/documents/documentsTypes.js`.
 * It's difficult and pointless to maintain 2 dictionaries with the same purpose
 */
export const CATEGORY = {
  DocumentCaseStudy: 'DocumentCaseStudy',
  DocumentTeamBio: 'DocumentTeamBio',
  DocumentReusableBlock: 'DocumentReusableBlock',
  DocumentPackagedService: 'DocumentPackagedService',
  DocumentPage: 'DocumentPage',
  /** The most comonly used document type. Old briefs, proposals and estimates have been replaced
   * by it */
  Document: 'Document',
  /** Similar to `Folder`, but more specific. Each `project` must have **exactly 1, non-removable**
   * 'root folder' where the front-end file navigation starts */
  ProjectRootFolder: 'ProjectRootFolder',
  /** A special kind of document used to contain (embed) files (special documents) and other
   * folders */
  Folder: 'Folder',
  /** A special kind of document used to to repesent the contents of folders (special documents).
   * It contains **1 uploaded file**. */
  File: 'File',
  /** A special kind of document used to to repesent the contents of folders (special documents).
   * It contains **1 URL to a file hosted somewhere in the cloud** */
  FileLink: 'FileLink',

  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentBrief: 'DocumentBrief',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentEstimate: 'DocumentEstimate',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentProposal: 'DocumentProposal',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentContract: 'DocumentContract',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentStatementOfWork: 'DocumentStatementOfWork',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentChangeRequest: 'DocumentChangeRequest',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentClientSignOff: 'DocumentClientSignOff',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentProgressUpdate: 'DocumentProgressUpdate',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentCustom: 'DocumentCustom',
  /** @deprecated replaced by `CATEGORY.Document` */
  DocumentKnowledgeBase: 'DocumentKnowledgeBase',
};

export const PROJECT_CATEGORY = {
  DocumentCustom: 'DocumentCustom',
  DocumentBrief: 'DocumentBrief',
  DocumentProposal: 'DocumentProposal',
  DocumentProgressUpdate: 'DocumentProgressUpdate',
  DocumentStatementOfWork: 'DocumentStatementOfWork',
  DocumentContract: 'DocumentContract',
  DocumentEstimate: 'DocumentEstimate',
  DocumentChangeRequest: 'DocumentChangeRequest',
  DocumentClientSignOff: 'DocumentClientSignOff',
  DocumentKnowledgeBase: 'DocumentKnowledgeBase',
}
// TODO: Is the below still needed?
export const ICON = {
  [CATEGORY.DocumentReusableBlock]: 'icon_v2-so_reusable-block',
  [CATEGORY.DocumentCaseStudy]: 'icon_v2-so_case-study',
  [CATEGORY.DocumentTeamBio]: 'icon_v2-so_team-bios',
  [CATEGORY.DocumentPackagedService]: 'icon_v2-so_open-box',
  [CATEGORY.DocumentPage]: 'icon_v2-so_docs-files',

  [CATEGORY.DocumentBrief]: 'icon-document-brief',
  [CATEGORY.DocumentProposal]: 'icon-document-proposal',
  [CATEGORY.DocumentEstimate]: 'icon-document-estimate',
  [CATEGORY.DocumentContract]: 'icon-document-contract',
  [CATEGORY.DocumentStatementOfWork]: 'icon-document-sow',
  [CATEGORY.DocumentChangeRequest]: 'icon-document-change-request',
  [CATEGORY.DocumentClientSignOff]: 'icon-document-signoff',
  [CATEGORY.DocumentProgressUpdate]: 'icomn-document-progress-update',
  [CATEGORY.DocumentCustom]: 'icon-document-custom',
  [CATEGORY.DocumentKnowledgeBase]: 'icon-document-knowledge-base',
}

export const STATUS = {
  // NO_STATUS: 'NO_STATUS',
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  // IN_REVIEW: 'IN_REVIEW',
  // POSTPONED: 'POSTPONED',
  // IN_PROGRESS: 'IN_PROGRESS',
  // DELIVERY_ACCEPTED: 'DELIVERY_ACCEPTED',
}

export const PROPOSAL_APPROVAL_METHOD = {
  APPROVE_ALL: 'APPROVE_ALL',
  REMOVE_UNAPPROVED: 'REMOVE_UNAPPROVED',
}

/**
 * @deprecated Please use the dictionary in `src/v2/services/documents/documentsTypes.js`.
 * It's difficult and pointless to maintain 2 dictionaries with the same purpose
 */
export const DEFAULT_DOCUMENT_TITLE = {
  [CATEGORY.DocumentCaseStudy]: 'Untitled Project',
  [CATEGORY.DocumentTeamBio]: 'John Appleseed',
  [CATEGORY.DocumentPackagedService]: 'Untitled Packaged Service',
  [CATEGORY.DocumentPage]: 'Untitled Page',
  [CATEGORY.DocumentReusableBlock]: 'Untitled Document',
  [CATEGORY.Document]: 'Untitled Document',
  [CATEGORY.ProjectRootFolder]: 'Docs & Files', // This might not be needed
  [CATEGORY.Folder]: 'Folder',
  [CATEGORY.File]: 'File',
  [CATEGORY.FileLink]: 'Linked File',

  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentCustom]: 'Untitled Document',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentBrief]: 'Untitled Brief',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentProposal]: 'Untitled Proposal',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentEstimate]: 'Untitled Estimate',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentContract]: 'Untitled Contract',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentStatementOfWork]: 'Untitled Statement of Work',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentChangeRequest]: 'Untitled Change Request',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentClientSignOff]: 'Untitled Client Sign-Off',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentProgressUpdate]: 'Untitled Progress Update',
  /** @deprecated replaced by `DEFAULT_DOCUMENT_TITLE.Document` */
  [CATEGORY.DocumentKnowledgeBase]: 'Untitled Knowledge Base',
}
