<template>
  <li class="task-section">
    <div
      class="
        flex items-center group/section w-full
        gap-8 border-b dark:border-b-darkGray-900 p-8
      "
    >
      <ButtonCollapse v-model="collapsedSections[section.taskSection._id]" />
      <div class="flex-1">
        <slot v-if="$slots['section-title']" name="section-title" />
        <ContentEditable
          v-else
          :value="section.taskSection.title"
          lazy
          placeholder="Title..."
          tag="p"
          :no-html="true"
          no-newline
          trim
          class-name="smart-word-break text-16 portal-heading-font dark:text-white/70"
          :contenteditable="!readonlySections"
          @input="updateTitle"
        />
        <ProgressBarCompleted
          class="w-128"
          :count="taskCount"
          :completed-count="resolvedTaskCount"
        />
      </div>
      <div class="items-center gap-8 flex" :class="{'mr-4': noSectionMenu }">
        <b-form-checkbox
          v-if="!noSectionPinToDashboard"
          v-b-popover.hover.topleft="
            'If enabled, this section is shown as a card on the project\'s dashboard.'
          "
          :checked="section.taskSection.showOnProjectDashboard"
          class="flex items-center mr-8 scale-90"
          size="sm"
          switch
          @change="val => update({ showOnProjectDashboard: val })"
        >
          <span
            class="icon_v2-so_home text-gray-400"
            :class="{ 'text-green-600': section.taskSection.showOnProjectDashboard }"
          />
        </b-form-checkbox>
        <ButtonIcon
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="$t('tasks.addTask')"
          icon="icon_v2-so_plus"
          size="18"
          @click="$emit('create-task', 0)"
        />
        <ButtonIcon
          v-if="!readonlySections"
          v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Delete section`"
          icon="icon_v2-so_trash"
          size="18"
          @click="$emit('remove')"
        />
      </div>
      <TaskSectionMenu
        v-if="!noSectionMenu"
        @select="action => $emit('menu-action', action)"
      />
    </div>
    <div v-show="!collapsedSections[section.taskSection._id]" class="pt-8">
      <TaskList
        :tasks="section.tasks"
        :section-id="section.taskSection._id"
        :selected-task-id="selectedTaskId"
        :lock-tasks-to-section="lockTasksToSection"
        :no-task-reordering="noTaskReordering"
        @remove="task => $emit('remove-task', task)"
        @drag-event="event => $emit('drag-event-tasks', event)"
      >
        <template #task="{ task }">
          <slot name="task" v-bind="{ task }" />
        </template>
      </TaskList>
      <div class="p-8">
        <b-button
          type="button"
          size="sm"
          variant="neutral"
          class="capitalize portal-paragraph-font"
          @click="$emit('create-task', section.tasks.length)"
        >
          {{ $t('tasks.addTask') }}
        </b-button>
      </div>
    </div>
  </li>
</template>
<script>
import { take } from 'ramda'
import { computed, defineComponent } from '@vue/composition-api'
import { useLocalStorageReactive } from '@/v2/lib/composition/useLocalStorage'
import TaskList from '@/components/Tasks/TaskList.vue'
import ContentEditable from '@/components/ContentEditable.vue'
import ButtonIcon from '@/components/ButtonIcon.vue'
import ProgressBarCompleted from '@/components/ProgressBarCompleted.vue'
import ButtonCollapse from '@/components/ButtonCollapse.vue'
import TaskSectionMenu from '@/components/Tasks/TaskSectionMenu.vue'

export default defineComponent({
  name: 'TaskSection',
  components: {
    TaskList,
    ContentEditable,
    ButtonIcon,
    ProgressBarCompleted,
    TaskSectionMenu,
    ButtonCollapse,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    noSectionPinToDashboard: {
      type: Boolean,
      default: false,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    readonlySections: {
      type: Boolean,
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    noSectionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const collapsedSections = useLocalStorageReactive('collapsedTaskSections', {})

    const update = data => {
      context.emit('update', data)
    }

    const updateTitle = newTitle => {
      update({ title: take(100, newTitle) })
    }

    const resolvedTaskCount = computed(
      () => props.section.tasks.filter(task => task.isResolved).length
    );

    const taskCount = computed(() => props.section.tasks.length)

    return {
      update,
      updateTitle,
      taskCount,
      resolvedTaskCount,
      collapsedSections,
    }
  },
})
</script>
<style lang="postcss" scoped>
.task-section {
  @apply bg-gray-100/50 rounded-sm border;
  @apply dark:bg-darkGray-700 dark:border-darkGray-900;
}

.dashboard-checkbox {
  height: auto;
  line-height: normal; /* Ensure line height is normal to avoid extra spacing */
  padding-bottom: 0; /* Adjust padding as needed */
  margin-bottom: 0; /* Adjust margin as needed */
}
</style>
