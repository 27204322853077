
<div v-fragment>
  <DocumentContentBlockCostBreakdownToolbarPortal
    :popover-target-id="settingsPopoverTargetId"
    :content-block="contentBlock"
    :node-id="nodeId"
    @update="(data, options) => $emit('update', data, options)"
  />

  <div
    :class="[
      'block-element-cost-summary',

    ]"
    data-intercom-target="Price Breakdown Block"
  >
    <CostBreakdownTable
      :items="sortedBillables"
      :is-editable="isEditable"
      :document-id="document._id"
      :node-width="nodeWidth"
    />
    <!-- Table Total -->
    <TableTotal
      :total="document.cost || 0"
      :document-id="document._id"
    />

    <!-- Estimate Progress -->
    <template v-if="showTotalGraph">
      <progress-bar :ratio="approvedRatio" />

      <div class="cb-cols portal-paragraph-font">
        <div class="cb-col is-approved">
          <h3 class="cb-heading capitalize">
            {{ $t('common.approved') }}
          </h3>
          <div class="cb-cost portal-heading-font">
            <DocumentCurrency :document-id="document._id" :value="document.approvedCost" />
          </div>
          <small class="cb-progress portal-paragraph-font">
            {{ approvedRatio | percent }}
          </small>
        </div>
        <div class="cb-sep" />
        <div class="cb-col">
          <h3 class="cb-heading portal-paragraph-font">
            {{ $t('blocks.cost.remaining') }}
          </h3>
          <div class="cb-cost portal-heading-font">
            <DocumentCurrency :document-id="document._id" :value="document.notApprovedCost" />
          </div>
          <small class="'cb-progress portal-paragraph-font">
            {{ notApprovedRatio | percent }}
          </small>
        </div>
      </div>
    </template>
  </div>
</div>
