<template>
  <SidebarMenuLink
    :to="to"
    activate-on-child-route
  >
    <DocumentComputedData v-slot="{ title, icon: docIcon }" :document="document">
      <SidebarItemBasic :icon="icon ?? docIcon" :label="title" />
    </DocumentComputedData>
  </SidebarMenuLink>
</template>

<script>
import { defineComponent, toRef } from '@vue/composition-api'
import { useDocumentLink } from '@/v2/services/documents/compositions'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import SidebarMenuLink from './SidebarMenuLink.vue'
import SidebarItemBasic from './SidebarItemBasic.vue'

export default defineComponent({
  name: 'SidebarItemFolder',
  components: {
    SidebarMenuLink,
    SidebarItemBasic,
    DocumentComputedData,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    pinned: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const to = useDocumentLink(toRef(props, 'document'), {
      isPinnedFolder: props.pinned,
    })

    return {
      to,
    }
  },
})
</script>
