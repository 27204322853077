
<div>
  <button
    :id="targetId"
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="tooltip"
    :class="[
      'flex items-center justify-center h-32 relative rounded',
      'outline-none focus:outline-none',
      className,
    ]"
    @click.stop="popoverShow = !popoverShow"
  >
    <slot name="button-content" />
  </button>
  <b-popover
    ref="popoverRef"
    :target="targetId"
    :triggers="triggers"
    :show="popoverShow"
    :placement="placement"
    :fallback-placement="fallbackPlacement"
    :custom-class="[
      `${$style.popover}`,
      'popover-light popover-color-picker',
      'p-0 mt-12',
      'bg-gray border border-gray-200 rounded-md shadow-xl'
    ].join(' ')"
    :no-fade="true"
    delay="0"
    boundary="main"
    @show="onPopoverOpen"
  >
    <div
      v-click-outside.capture="
        !triggers.includes('blur')
          ? () => closeOnClickOutside && close()
          : null
      "
      :class="[!slim && 'p-12']"
      @click.stop
    >
      <div
        class="text-gray-700 w-235 text-14"
        :style="{ width: `${width}px` }"
      >
        <slot />
      </div>
    </div>
  </b-popover>
</div>
