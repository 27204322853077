<template>
  <ul class="menu space-y-8 mb-16">
    <slot :active-id="activeId" />
  </ul>
</template>

<script>
import {
  computed, defineComponent, provide, ref, useCssModule,
} from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarMenu',
  props: {
    itemClass: {
      type: [String, Object, Array],
      default: null,
    },
    activeItemClass: {
      type: [String, Object, Array],
      default: null,
    },
    iconClass: {
      type: [String, Object, Array],
      default: null,
    },
  },
  setup(props) {
    const activeId = ref(null)
    const $style = useCssModule()

    const setActive = id => {
      activeId.value = id
    }

    const itemClass = computed(() => props.itemClass ?? $style.item)
    const activeItemClass = computed(() => props.activeItemClass ?? $style['active-item'])
    const iconClass = computed(() => props.iconClass ?? $style.icon);

    provide('setActive', setActive)
    provide('activeId', activeId)
    provide('itemClass', itemClass)
    provide('activeItemClass', activeItemClass)
    provide('iconClass', iconClass)

    return {
      activeId,
    }
  },
})
</script>
<style lang="postcss" module>
.item {
  @apply text-13 p-4 font-normal h-36 text-gray-600 rounded hover:bg-gray-100/70;
  @apply dark:text-darkGray-400 dark:hover:text-darkGray-300 dark:hover:bg-darkGray-900;
  @apply relative flex items-center;
}

.active-item {
  @apply text-13 px-8 py-4 -mx-4 h-36 text-white font-semibold rounded;
  /* @apply bg-green-500 dark:bg-darkGray-900; */
  background-color: var(--cp-accent, theme('colors.green.500'));
  text: var(--cp-accent-most-readable, white);
  @apply relative flex items-center;
}
.active-item button {
  @apply text-white;
}
.active-item button:hover {
  @apply text-white opacity-50;
}

.active-item :global(.count) {
  @apply bg-white text-gray-400;
}

.icon {
  @apply text-24;
}
</style>
<style scoped>

</style>
<style lang="postcss" scoped>
  .menu > li:not(:last-child) {
    @apply mb-4;
  }
</style>
