import Vue from 'vue'
// import requireAll from './lib/require-all'
import SvgIcon from './components/SvgIcon.vue'
import SvgGraphic from './components/SvgGraphic.vue'

// requireAll(require.context('./assets/icons/', true, /\.svg$/))
// requireAll(require.context('./assets/icons/tiptap', true, /\.svg$/))
// requireAll(require.context('./assets/svg/', true, /\.svg$/))

Vue.component(SvgIcon.name, SvgIcon)
Vue.component(SvgGraphic.name, SvgGraphic)
