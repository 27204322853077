import { isNotNil } from 'ramda-adjunct'
import { isNotEmpty } from 'ramda-extension'
import container from '@/container'
import paramsForServer from './paramsForServer'

export const REQUEST_CONTEXT_NAMESPACE = 'requestContext__'

export const reqCtxNs = value => `${REQUEST_CONTEXT_NAMESPACE}${value}`
export const withoutContextRequestNs = value => value.replace(REQUEST_CONTEXT_NAMESPACE, '')

export const requestContextToRouteParams = requestContext => {
  const mapEntries = ([key, value]) => [reqCtxNs(key), value]
  const entries = Object.entries(requestContext ?? {}).map(mapEntries)
  return Object.fromEntries(entries)
}

const getFromCurrentRoute = (queryParams = {}) => {
  const router = container.get('router');
  const { params, query } = router?.currentRoute ?? {}

  if (JSON.parse(query.rc ?? null) === false) {
    return {}
  }

  const filterEntries = ([key]) => key.startsWith(REQUEST_CONTEXT_NAMESPACE)
  const mapEntries = ([key, value]) => [withoutContextRequestNs(key), value]
  const entries = Object.entries(params).filter(filterEntries).map(mapEntries)

  const queryEntries = Object.entries(queryParams)
    .filter(([queryKey]) => isNotNil(query[queryKey]))
    .map(([queryKey, ctxKey]) => [ctxKey, query[queryKey]])

  return Object.fromEntries([...entries, ...queryEntries])
}

// for use with feathers hooks (needs a context)
const requestContextForServer = (queryParams = {}) => context => {
  const requestContext = getFromCurrentRoute(queryParams)
  if (isNotEmpty(requestContext)) {
    Object.assign(context.params, { requestContext })
    context.params = paramsForServer(context.params, 'requestContext')
  }
}

// more generic version, just return the params
export const requestContextParams = (queryParams = {}) => ({
  requestContext: getFromCurrentRoute(queryParams),
})

/**
 * Maps route params prefixed with 'requestContext__' to component props, by removing the namespace
 * from each prop name.
 * @param {import('vue-router').Route} route
 */
export const mapParamsToProps = route => Object.entries(route.params ?? {}).reduce(
  (acc, [paramName, value]) => {
    acc[withoutContextRequestNs(paramName)] = value
    return acc
  },
  {}
)

export default requestContextForServer
