
<DocumentComputedData
  v-if="document"
  v-slot="{ title, icon }"
  :document="document"
>
  <section
    class="card-interactive transition-all embedded-document-block group"
    :class="enableFullBlockClick ? 'cursor-pointer' : 'cursor-default'"
  >
    <DocumentLink
      v-if="document"
      v-slot="{ navigate }"
      :document="document"
    >
      <div
        class="flex"
        :class="
          ['XXS','XS','S','M'].includes(nodeWidth)
            ? 'flex-col justify-start items-start space-y-4'
            : (
              'items-start md:items-center flex-col md:flex-row ' +
              'justify-start space-y-12 md:space-y-0'
            )
        "
        @click="navigate"
      >
        <!-- ICON -->
        <span
          v-if="['XXL','XL','L'].includes(nodeWidth)"
          class="text-24 md:text-32 md:ml-8 md:mr-16"
          :class="icon"
          :style="{
            color: document.color || 'var(--cp-accent)'
          }"
        />
        <!-- Document Name -->
        <div
          class="flex flex-col grow"
        >
          <h4
            class="mb-8 title group md:mb-0"
            :title="`Open ${document.title}`"
          >
            <span class="mr-4 group-hover:underline portal-heading-font">
              {{ document.title }}
            </span>
            <!-- IS SHARED OR NOT -->
            <div class="inline-flex items-center gap-6 mt-8 md:mt-0">
              <div
                v-if="document.sharingClientEnabled && !isClientPortal"
                v-b-tooltip.hover.top.v-info.dh0.ds200="`Shared to the client portal`"
                class="visibility-badge portal-paragraph-font"
              >
                <span class="icon_v2-so_shared text-16 opacity-70"></span>
                <span class="hidden md:inline ml-2 mr-2 font-medium leading-none text-12 opacity-70">
                  {{ $t('common.shared') }}
                </span>
              </div>
              <div
                v-if="document.sharingLinkEnabled && !isClientPortal"
                v-b-tooltip.hover.top.v-info.dh0.ds200="`Public link enabled`"
                class="visibility-badge portal-paragraph-font"
              >
                <span class="icon-link text-16 opacity-70"></span>
                <span class="hidden md:inline ml-2 mr-2 font-medium leading-none text-12 opacity-70">
                  Public Link
                </span>
              </div>
            </div>
          </h4>
          <!-- META -->
          <div class="flex flex-row items-center space-x-8">
            <span
              class="
              font-normal text-gray-400 text-14 dark:text-darkGray-500
              portal-paragraph-font
            "
            >
              {{ $t('common.updated') }}
              <LiveFilter
                filter="timeago"
                :value="document.updatedAt"
              />
            </span>

            <!-- Cost -->
            <small
              v-if="document.cost > 0"
              class="
              flex-none text-right text-gray-700 dark:text-darkGray-400
              text-14 hover:cursor-pointer portal-paragraph-font
            "
              :class="nodeWidth !== 'XXS' && 'mr-4'"
            >
              <button
                v-b-tooltip.hover.top.v-info.dh0.ds200="
                  isExpanded ? 'Hide Breakdown' : 'Show Cost Breakdown'
                "
                v-b-toggle="`collapse-estimate-${document._id}-${componentID}`"
                class="
                inline-flex items-center
                focus:outline-none text-center mr-8 hover:text-violet-700
                dark:hover:text-darkGray-300
              "
                :disabled="'XXS' === nodeWidth"
                @click.stop="isExpanded = !isExpanded"
              >
                <DocumentCurrency :value="document.cost" :document-id="document._id" />
                <span
                  v-if="['XXL','XL','L','M'].includes(nodeWidth)"
                  :class="isExpanded && 'rotate-chevron'"
                  class="
                  w-16 icon-arrow-expanded text-16 inline-block
                  transition-transform
                "
                />
              </button>
            </small>
          </div>
        </div>

        <!-- <div class="dark:text-darkGray-400">

      </div> -->


        <div
          class="flex flex-row items-center space-x-8 lg:space-x-12 portal-paragraph-font"
        >
          <!-- Comments -->
          <DocumentCount
            v-if="document.countComments"
            variant="comments"
            :count="document.countComments"
            :tooltip="$tc('common.comment', document.countComments, { count: document.countComments })"
            @count-clicked="openDocument(document)"
          />
          <!-- Action Items -->
          <DocumentCount
            v-if="showActionItemsCount"
            variant="actionItems"
            :count="showActionItemsCount"
            :tooltip="$tc('modules.action-items', showActionItemsCount, { count: showActionItemsCount })"
            @count-clicked="openDocument(document)"
          />
          <!-- Files -->
          <DocumentCount
            v-if="document.countFiles"
            variant="files"
            :count="document.countFiles"
            :tooltip="$tc('common.file', document.countFiles, { count: document.countFiles })"
            @count-clicked="openDocument(document)"
          />
          <!-- QA PROGRESS -->
          <DocumentViewerProgress
            :document="document"
            variant="card"
            @progress-clicked="openDocument(document)"
          />

          <!-- Analytics -->
          <div v-if="showAnalytics && !isClientPortal" class="text-right text-14">
            <DocumentAnalytics :document="document" />
          </div>
          <!-- STATUS -->
          <div
            v-if="!isClientPortal && document.status==='APPROVED'"
            class="text-right print:hidden"
            @click.stop="openDocument(document)"
          >
            <DocumentStatusBadge
              :status="document.status"
              :dropdown="false"
            />
          </div>
        </div>
      </div>
    </DocumentLink>

    <div
      v-if="standalone && !isClientPortal && showDelete"
      class="
        absolute top-16 right-16 flex flex-col items-center invisible
        pl-4 group-hover:opacity-100 group-hover:visible z-1
      "
    >
      <Button
        variant="danger"
        size="xs"
        class="w-24 h-24 p-0 border-none"
        @click.stop="$emit('remove', document)"
      >
        <span class="w-16 icon_v2-so_trash text-16" />
      </Button>
    </div>
    <!-- Collapse -->
    <div
      :class="[
        'text-14 flex items-center space-x-8 justify-end',
        'min-w-160',
        'text-gray-700 dark:text-darkGray-400 px-8',
        'portal-paragraph-font'
      ]"
    >
      <b-collapse
        :id="`collapse-estimate-${document._id}-${componentID}`"
        :class="[
          'mt-16 border-t border-gray-100 grow',
          'dark:border-darkGray-700'
        ]"
      >
        <CostBreakdownTable
          :node-width="nodeWidth"
          :items="document.costMatrix"
          :document-id="document._id"
        />
      </b-collapse>
    </div>
  </section>
</DocumentComputedData>
