
<div
  class="card-carousel"
  @mouseover="stopTimer"
  @mouseleave="restartTimer"
>
  <div v-if="autoSlideInterval && showProgressBar" class="progressbar">
    <div :style="{width: progressBar + '%' }"></div>
  </div>
  <div class="overflow-hidden card-img rounded-xl">
    <img :src="currentImage" alt="" />
    <div class="actions">
      <span class="prev" @click="prevImage">
        &#8249;
      </span>
      <span class="next" @click="nextImage">
        &#8250;
      </span>
    </div>
  </div>
  <div class="flex gap-16 thumbnails justify-center">
    <div
      v-for="(image, index) in images"
      :key="image.id"
      class="thumbnail-image pt-full relative overflow-hidden rounded-xl border-2 border-gray-100"
      :class="{ 'active border-green-500': activeImage === index }"
      @click="activateImage(index)"
    >
      <img
        :src="image.thumb"
        class="absolute left-0 object-cover w-full h-full"
      />
    </div>
  </div>
</div>
