import { computed, unref, inject, watch } from '@vue/composition-api'
import { allPass, T } from 'ramda'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import {
  CATEGORY as DOCUMENT_CATEGORY,
} from '@/v2/services/documents/documentsTypes'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {{ folder: any, children: any[] }} FolderNode
 */

const { useActions, useGetters } = createNamespacedHelpers('documents')

/**
 * @param {FolderNode} folderNode
 * @param {(node: FolderNode) => boolean} filterFn
 */
export const mapFolderToSidebarItem = (
  { folder, children },
  filterFn = T,
  overwritesByCategory = {}
) => ({
  id: folder._id,
  // label: folder.fsName,
  slot: 'folder',
  projectId: folder.project,
  data: {
    document: folder,
    projectId: folder.project,
  },
  ...(overwritesByCategory[folder.category] ?? {}),
  children: children
    .filter(filterFn)
    .map(node => mapFolderToSidebarItem(node, filterFn, overwritesByCategory)),
})

/**
 * @param {FolderNode} folderNode
 * @param {(node: FolderNode) => boolean} predicateFn
 */
const getFolderNode = (root, predicateFn = T) => {
  const stack = [root]

  while (stack.length) {
    const node = stack.pop()
    if (predicateFn(node)) {
      return node
    }

    node && stack.push(...node.children)
  }

  return null
}

/**
 * @param {Object} options
 * @param {Ref<string>} options.projectId
 * @param {Ref<string>} options.folderId
 * @param {Ref<string>} options.folderCategory
 * @param {Ref<string[]>} options.hiddenFolderIds
 * @param {Ref<string[]>} options.hiddenFolderCategories
 * @param {Ref<Object>} options.overwritesByCategory A dictionary containing info to be overwritten,
 * grouped by `DOCUMENT_CATEGORY`. e.g. `{'ProjectRootFolder': { label: 'All Documents',
 * icon: 'some-icon-name' }}`
 */
export default function useTreeProjectsFolders({
  projectId,
  folderId,
  folderCategory,
  hiddenFolderIds,
  hiddenFolderCategories,
  overwritesByCategory = {},
}) {
  const { loadFolders } = useActions(['loadFolders'])
  const { folderTreeByProject } = useGetters(['folderTreeByProject'])
  const root = computed(() => folderTreeByProject.value[projectId.value])
  const isClientPortal = inject('isClientPortal', false)
  const organization = useCurrentOrganization()

  const folder = computed(() => {
    const id = unref(folderId)
    const category = unref(folderCategory)

    const predicateFn = allPass([
      id ? node => node?.folder._id === id : T,
      category ? node => node?.folder.category === category : T,
    ]);

    return getFolderNode(root.value, predicateFn);
  })

  const loadProjectFolders = () => {
    loadFolders({
      projectId: projectId.value,
      organizationId: organization.value._id,
      folderTreeRootCategory: isClientPortal
        ? DOCUMENT_CATEGORY.ProjectPublicFolder
        : DOCUMENT_CATEGORY.ProjectRootFolder,
    })
  }

  watch(projectId, loadProjectFolders, { immediate: true })

  return computed(() => {
    const ids = new Set(unref(hiddenFolderIds) ?? [])
    const categories = new Set(unref(hiddenFolderCategories) ?? [])

    const filterFn = allPass([
      ids.size ? node => !ids.has(node.folder._id) : T,
      categories.size ? node => !categories.has(node.folder.category) : T,
    ]);

    return {
      id: 'root',
      children: folder.value
        ? [mapFolderToSidebarItem(folder.value, filterFn, overwritesByCategory.value)]
        : [],
    }
  })
}
