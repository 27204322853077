const configDefaults = {
  objectGroup: {
    componentPlaceholder: 'DocumentObjectGroupPlaceholder',
  },
  ignoreNodePropStyling: false,
  styling: {
    backgroundColor: '',
    size: 'L',
    textAlign: 'left',
    objectAlign: 'stretch',
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 0,
    marginBottom: 0,
    headingSize: 'h2',
    backgroundImage: null,
    backgroundImageOpacity: 100,
    font: null,
    verticalAlign: 'start',
    printMarginTop: 45,
  },
  stylingToolbar: {
    features: {
      headingSizes: false,
      backgroundColor: true,
      textColor: false,
      sizes: true,
      spacing: true,
      textAlign: false,
      objectAlign: false,
      fontFamily: false,
      settings: false,
      verticalAlign: false,
    },
    headingSizes: [
      {
        value: 'h1',
        label: 'Heading 1',
        cssClass: 'heading-fs-1',
      },
      {
        value: 'h2',
        label: 'Heading 2',
        cssClass: 'heading-fs-2',
      },
      {
        value: 'h3',
        label: 'Heading 3',
        cssClass: 'heading-fs-3',
      },
      {
        value: 'h4',
        label: 'Heading 4',
        cssClass: 'heading-fs-4',
      },
    ],
    colorPalette: [
      // reds
      { bgColor: '#FFEBEE', textVariant: 'dark' },
      { bgColor: '#FFCDD2', textVariant: 'dark' },
      { bgColor: '#FF8A80', textVariant: 'dark' },
      { bgColor: '#FF5252', textVariant: 'light' },
      { bgColor: '#FF1744', textVariant: 'light' },
      { bgColor: '#D50000', textVariant: 'light' },

      // yellows
      { bgColor: '#FFFDE7', textVariant: 'dark' },
      { bgColor: '#FFF9C4', textVariant: 'dark' },
      { bgColor: '#FFFF00', textVariant: 'dark' },
      { bgColor: '#FFEA00', textVariant: 'dark' },
      { bgColor: '#FFD600', textVariant: 'dark' },
      { bgColor: '#FBC02D', textVariant: 'dark' },

      // greens
      { bgColor: '#F9FBE7', textVariant: 'dark' },
      { bgColor: '#F0F4C3', textVariant: 'dark' },
      { bgColor: '#F4FF81', textVariant: 'dark' },
      { bgColor: '#EEFF41', textVariant: 'dark' },
      { bgColor: '#C6FF00', textVariant: 'dark' },
      { bgColor: '#AEEA00', textVariant: 'dark' },

      // blues
      { bgColor: '#E1F5FE', textVariant: 'dark' },
      { bgColor: '#B3E5FC', textVariant: 'dark' },
      { bgColor: '#80D8FF', textVariant: 'dark' },
      { bgColor: '#40C4FF', textVariant: 'light' },
      { bgColor: '#00B0FF', textVariant: 'light' },
      { bgColor: '#0091EA', textVariant: 'light' },

      // purples
      { bgColor: '#EDE7F6', textVariant: 'dark' },
      { bgColor: '#D1C4E9', textVariant: 'dark' },
      { bgColor: '#B388FF', textVariant: 'light' },
      { bgColor: '#7C4DFF', textVariant: 'light' },
      { bgColor: '#651FFF', textVariant: 'light' },
      { bgColor: '#6200EA', textVariant: 'light' },

      // grays
      { bgColor: '#FAFAFA', textVariant: 'dark' },
      { bgColor: '#F5F5F5', textVariant: 'dark' },
      { bgColor: '#BDBDBD', textVariant: 'dark' },
      { bgColor: '#9E9E9E', textVariant: 'light' },
      { bgColor: '#616161', textVariant: 'light' },
      { bgColor: '#212121', textVariant: 'light' },
    ],
    sizes: [
      { value: 'S', text: 'S' },
      { value: 'M', text: 'M' },
      { value: 'L', text: 'L' },
      { value: 'F', text: 'Full' },
    ],
    verticalAlign: [
      { value: 'start', text: 'S' },
      { value: 'center', text: 'C' },
      { value: 'end', text: 'E' },
    ],
    // textAlign: [
    //   { value: 'left', icon: 'toolbar-align-left' },
    //   { value: 'center', icon: 'toolbar-align-center' },
    //   { value: 'right', icon: 'toolbar-align-right' },
    // ],
    // objectAlign: [
    //   { value: 'left', icon: 'toolbar-align-left' },
    //   { value: 'center', icon: 'toolbar-align-center' },
    //   { value: 'right', icon: 'toolbar-align-right' },
    //   { value: 'stretch', icon: 'toolbar-align-right' },
    // ],
  },
  block: {
    enableHover: true,
    enableFocus: true,
    enableMove: true,
    enableCreateBefore: true,
    enableStylingToolbar: true,
    restrictBlockTypeBefore: null,
    restrictBlockTypeChild: null,
    enableBlockLabel: true,
  },
  menu: {
    enabled: true,
    moveUp: true,
    moveDown: true,
    bubbleUp: true,
    bubbleDown: true,
    remove: true,
    createChild: true,
    duplicate: true,
  },
  events: {
    syncStatus: false,
  },
}

configDefaults.stylingToolbar.colorPalette = configDefaults.stylingToolbar.colorPalette.reduce(
  (acc, { bgColor, textVariant }) => {
    acc.colors.push(bgColor)
    acc.textVariants[bgColor] = textVariant
    return acc
  },
  {
    colors: [],
    textVariants: {
      default: 'dark',
      transparent: 'dark',
    },
  }
)

export default configDefaults
