<template>
  <LoaderWebFont :families="[styling.font || '']">
    <component
      :is="styling.headingSize"
      v-if="isEditable"
      role="content-block-heading"
      data-intercom-target="Heading Block"
      @contextmenu.stop
    >
      <TextEditor
        v-if="isEditable"
        placeholder="Type something..."
        :lazy="true"
        :class="[
          $style[`text-variant-${textVariant}`],
          $style['heading'],
          $style[`is-${styling.headingSize}`],
          `text-${styling.textAlign}`,
        ]"
        :style="{
          fontFamily: styling.font || 'var(--cp-heading-font)',
          color: styling.textColor,
        }"
        :value="contentBlock.heading"
        :editor-features="textEditorFeatures"
        :show-floating-menu="false"
        @input="val => $emit('update', { heading: val })"
      />
    </component>

    <component
      :is="styling.headingSize"
      v-else
      role="content-block-heading"
      data-placeholder="Type something..."
      :class="[
        $style[`text-variant-${textVariant}`],
        $style['heading'],
        $style[`is-${styling.headingSize}`],
        `text-${styling.textAlign}`,
        'editor-content-preview',
        'editor-content',
        isEditable && 'editor-content-editable',
      ]"
      :style="{
        fontFamily: styling.font || 'var(--cp-heading-font)',
        color: styling.textColor,
      }"
      v-html="contentBlock.heading"
    />
  </LoaderWebFont>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import ContentEditable from '@/components/ContentEditable.vue'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { EDITOR_FEATURES } from '@/types/editor'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';

export default {
  name: 'DocumentContentBlockHeading',
  components: {
    ContentEditable,
    TextEditor,
    LoaderWebFont,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const document = inject('document')

    const organization = useCurrentOrganization()

    const textEditorFeatures = [
      EDITOR_FEATURES.highlight,
      EDITOR_FEATURES.link,
      EDITOR_FEATURES.italic,
      EDITOR_FEATURES.strike,
      EDITOR_FEATURES.textAlign,
      EDITOR_FEATURES.bold,
    ]
    const isEditable = computed(() => !isLocked.value && isEditor.value)

    return {
      isEditable,
      textEditorFeatures,
      organization,
      document,
    }
  },
}
</script>

<style lang="scss" module>
.text-variant-light {
  color: #fff;
}

.heading {
  @apply font-bold;

  :global(p.is-empty:first-child) {
    &::before {
      @apply text-gray-600;
    }
  }
}
.is-h1 {
  @apply text-32 md:text-42 lg:text-56 leading-snug font-semibold;
}

.is-h2 {
  @apply text-24 md:text-32 lg:text-42 leading-snug font-semibold;
}

.is-h3 {
  @apply text-20 md:text-24 lg:text-32 leading-snug font-semibold;
}

.is-h4 {
  @apply text-18 md:text-20 lg:text-24 leading-snug font-semibold;
}
</style>
