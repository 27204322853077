
<VueMultiselect
  v-bind="$attrs"
  class="multiselect"
  v-on="$listeners"
>
  <template #singleLabel="props">
    <slot name="singleLabel" v-bind="props" />
  </template>
  <template #option="props">
    <slot name="option" v-bind="props" />
  </template>
</VueMultiselect>
