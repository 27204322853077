<template>
  <Form
    v-if="state"
    v-slot="{ isBusy, submit, hasChanges }"
    :state="state"
    :on-submit="() => save()"
  >
    <div
      v-for="item in model"
      :key="item.category"
      class="border rounded-md mt-16 px-16 py-8 hover:bg-gray-100"
    >
      <div class="capitalize font-semibold mb-4">
        <template v-if="item.translationKey">
          {{ $tc(item.translationKey, 2, { count: '' }) }}
        </template>
        <template v-else>
          {{ item.category }}
        </template>
      </div>
      <div v-for="notification in item.notifications" :key="notification">
        <SettingsSwitch
          :value="state.settings.emailNotifications[item.category].includes(notification)"
          :label="$t(`notifications.${item.category}.${notification}`)"
          class="!p-0"
          @input="val => update(item.category, notification, val)"
        />
      </div>
    </div>
    <slot name="button" v-bind="{ isBusy, submit, hasChanges }" />
  </Form>
</template>
<script>
import { uniq } from 'ramda'
import { defineComponent, computed } from '@vue/composition-api'
import { useCurrentMember } from '@/v2/services/myMembers/compositions'
import useFormEntityEditor from '@/v2/lib/composition/useFormEntityEditor'
import Form from '@/components/Form.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'

export default defineComponent({
  name: 'UserSettingsNotificationsForm',
  components: {
    Form,
    SettingsSwitch,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { member } = useCurrentMember()

    const {
      state,
      save,
    } = useFormEntityEditor({
      model: 'Member',
      id: computed(() => member.value._id),
      fields: computed(() => [
        'settings.emailNotifications',
      ]),
    })

    const update = (category, notification, checked) => {
      const { emailNotifications } = state.value.settings
      const notifications = emailNotifications[category]

      emailNotifications[category] = checked
        ? uniq([...notifications, notification])
        : notifications.filter(n => n !== notification)
    }

    return {
      state,
      save,
      update,
    }
  },
})
</script>
