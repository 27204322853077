<template>
  <component
    :is="mediaThumbnail"
    v-bind="$attrs"
    :file="file"
    :url="file.url"
    :alt="file.name"
    :no-min-height="noMinHeight"
    v-on="$listeners"
  />
</template>

<script>
import {
  startsWith,
} from 'ramda'
import { computed } from '@vue/composition-api'
import ImageThumbnail from '@/components/Blocks/Image/ImageThumbnail.vue'
import AudioThumbnail from '@/components/Blocks/Media/AudioThumbnail.vue'
import VideoThumbnail from '@/components/Blocks/Media/VideoThumbnail.vue'
import PDFThumbnail from '@/components/Blocks/Media/PDFThumbnail.vue'

const mediaTypes = {
  image: {
    component: ImageThumbnail,
  },
  audio: {
    component: AudioThumbnail,
  },
  video: {
    component: VideoThumbnail,
  },
  pdf: {
    component: PDFThumbnail,
  },
}
export default {
  name: 'MediaThumbnail',
  components: {
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    noMinHeight: Boolean,
  },
  setup(props) {
    const mediaType = computed(() => {
      if (startsWith('image/', props.file.contentType)) return 'image'
      if (startsWith('audio/', props.file.contentType)) return 'audio'
      if (startsWith('video/', props.file.contentType)) return 'video'
      if (startsWith('application/pdf', props.file.contentType)) return 'pdf'
      return ''
    })

    const mediaThumbnail = computed(() => (mediaTypes[mediaType.value].component))
    return {
      mediaThumbnail,
    }
  },

}
</script>
