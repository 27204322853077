<template>
  <DocumentNodeEditorModel
    v-slot="{ contentBlock }"
    :node-id="nodeId"
    :use-clone="true"
  >
    <DocumentLink
      v-if="contentBlock.embeddedDocument$"
      v-slot="{ navigate }"
      :document="contentBlock.embeddedDocument$"
    >
      <div v-fragment>
        <!-- SLOT FOR EDITING -->
        <slot name="edit" />
        <DocumentResolver
          v-if="isLargeGrid || isSmallGrid"
          v-slot="{ component }"
          :document="contentBlock.embeddedDocument$"
        >
          <!-- Component from doc category -->
          <component
            :is="component"
            :document="contentBlock.embeddedDocument$"
            :read-only="
              contentBlock.embeddedDocument$.category === CATEGORY.ProjectPublicFolder
                || readOnly
            "
            :size="isLargeGrid ? 'l':'m'"
            @select="navigate"
          />
        </DocumentResolver>
        <FolderItemRow
          v-else-if="isList"
          :document="contentBlock.embeddedDocument$"
          :read-only="
            contentBlock.embeddedDocument$.category === CATEGORY.ProjectPublicFolder
              || readOnly
          "
          @select="navigate"
        />
      </div>
    </DocumentLink>
    <FileNoLongerAvailable
      v-else
      :list="isList"
      :size="isLargeGrid ? 'l':'m'"
    />
  </DocumentNodeEditorModel>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useStructureMoveNode } from '@/v2/services/documentStructures/documentStructuresCompositions'
import { HandleDirective } from 'vue-slicksort'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import DocumentNodeEditorModel from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorModel.vue'
import DocumentGet from '@/components/DocumentGet.vue'
import FolderItemRow from '@/components/FolderItemRow.vue'
import DocumentLink from '@/components/DocumentLink.vue'
import FileNoLongerAvailable from '@/components/FileNoLongerAvailable.vue'
import DocumentResolver from './DocumentResolver.vue'

export default defineComponent({
  name: 'DocumentNodeFolderEditable',
  components: {
    DocumentNodeEditorModel,
    DocumentGet,
    DocumentResolver,
    FolderItemRow,
    DocumentLink,
    FileNoLongerAvailable,
  },
  directives: { handle: HandleDirective },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    roundedSelection: {
      type: Boolean,
      default: false,
    },
    viewAs: {
      type: String,
      default: 'grid',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const structureMoveNode = useStructureMoveNode()

    const moveNode = offset => {
      structureMoveNode({
        id: props.nodeId,
        index: props.index + offset,
      })
    }
    const isList = computed(() => props.viewAs === 'list')
    const isLargeGrid = computed(() => props.viewAs === 'grid')
    const isSmallGrid = computed(() => props.viewAs === 'smallGrid')

    return {
      CATEGORY,
      moveNode,
      isLargeGrid,
      isSmallGrid,
      isList,
    }
  },
})
</script>
