
<div v-fragment>
  <div
    :class="[
      'flex flex-col ',
      {
        'items-start': styling.objectAlign === 'left',
        'items-center': styling.objectAlign === 'center',
        'items-end': styling.objectAlign === 'right',
        'items-stretch': styling.objectAlign === 'stretch',
      },
    ]"
  >
    <b-link
      v-b-tooltip.hover.top.v-info.dh0.ds200="urlPreview"
      :href="buttonURL"
      :target="contentBlock.settings.newTab ? '_blank':'_self'"
      :style="{
        ...cssVars,
      }"
      :title="contentBlock.settings.label"
      :class="[
        'so-custom-button',
        'portal-paragraph-font',
        styling.objectAlign === 'stretch' && 'w-full',
      ]"
    >
      {{ contentBlock.settings.label }}
    </b-link>

    <DocumentContentBlockButtonToolbarPortal
      :popover-target-id="settingsPopoverTargetId"
      :content-block="contentBlock"
      :node-id="nodeId"
      @update="(data, options) => $emit('update', data, options)"
    />
    <!-- -------------------- -->
    <!-- TOGGLE NODE SETTINGS -->
    <!-- -------------------- -->
    <div
      v-if="isEditable"
      :class="[
        'mt-16 space-x-4 bg-gray-100 p-4 rounded-md inline-flex transition-opacity print:hidden',
        isHover || isFocus ? 'opacity-100' : 'opacity-30',
      ]"
    >
      <b-button
        v-if="isEditable"
        variant="neutral"
        size="xs"
        class="grow"
        @click="openSettings"
      >
        <span class="mr-6 icon-toolbar-settings" />
        Edit Button
      </b-button>
    </div>
  </div>
</div>
