import { ref, watch, reactive, getCurrentInstance, onUnmounted } from '@vue/composition-api'
import { nanoid } from 'nanoid'
import { pick, mergeDeepRight } from 'ramda'

export const useLocalStorageRef = (key, defaultValue = null) => {
  const init = window.localStorage.getItem(key)
  const dataRef = ref(init ? JSON.parse(init) : defaultValue)

  watch(dataRef, value => {
    window.localStorage.setItem(key, JSON.stringify(value))
  })

  return dataRef
}

export const useLocalStorageReactive = (key, defaultProps, syncPropNames = null) => {
  const INSTANCE_ID = nanoid()
  const eventName = 'useLocalStorageReactive.update'

  const init = window.localStorage.getItem(key)
  const vm = getCurrentInstance().proxy
  const eventBus = vm.$eventBus

  const reactiveObj = reactive(init
    ? mergeDeepRight(defaultProps, JSON.parse(init))
    : defaultProps)

  const getNewState = newVal => (syncPropNames ? pick(syncPropNames, newVal) : newVal)

  const updateLocalStorage = newVal => {
    const newState = getNewState(newVal)
    window.localStorage.setItem(key, JSON.stringify(newState))
  }

  const syncInstances = ({ instanceId, data }) => {
    if (instanceId === INSTANCE_ID) {
      return
    }

    const newState = getNewState(data)
    Object.assign(reactiveObj, newState)
  }

  watch(reactiveObj, newVal => {
    updateLocalStorage(newVal)
    eventBus.$emit(eventName, { instanceId: INSTANCE_ID, data: newVal })
  })

  eventBus.$on(eventName, syncInstances)

  onUnmounted(() => {
    eventBus.$off(eventName, syncInstances)
  })

  return reactiveObj;
}
