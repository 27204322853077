
<div
  ref="container"
  :class="[
    'resize-container relative',
    { 'is-resizing': isResizing },
    { 'show-selection': showSelection },
  ]"
  :style="containerStyle"
>
  <div
    :class="[
      'transition-opacity',
      isResizing && 'opacity-75 transition-opacity',
    ]"
  >
    <slot />
  </div>
  <div
    v-if="isResizing"
    :class="[
      'absolute px-4 py-4 leading-none z-10',
      'bg-gray-900 bg-opacity-80 text-white font-regular',
      'transform',
      width > 320
        ? 'text-14 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md'
        : 'left-8 top-8 text-12 whitespace-nowrap rounded'
    ]"
  >
    {{ containerSize.width }} x {{ containerSize.height }}
  </div>
  <template v-if="!disabled">
    <div
      class="handler handler-lt"
      @mousedown="event => onDragStart(event, 'lt')"
    />
    <div
      class="handler handler-rt"
      @mousedown="event => onDragStart(event, 'rt')"
    />
    <div
      class="handler handler-rb"
      @mousedown="event => onDragStart(event, 'rb')"
    />
    <div
      class="handler handler-lb"
      @mousedown="event => onDragStart(event, 'lb')"
    />
  </template>
</div>
