<template>
  <transition-group
    v-show="notifications.length"
    class="space-y-2"
    name="notifications-list"
    tag="ul"
  >
    <template v-for="(notification, index) in notifications">
      <li
        :key="notification._id"
        class="
          group transition-all
          dark:bg-opacity-10
        "
        :item-class="itemClass"
      >
        <!-- <div class="flex items-center"> -->
        <!-- <h1 class="text-32 mr-8">{{ index + 1 }}</h1> -->
        <NotificationListItem
          :notification="notification"
          @mark-as-read="notification => $emit('mark-as-read', notification)"
        />
        <!-- </div> -->
      </li>
    </template>
  </transition-group>
</template>

<script>
import { pick } from 'ramda'
import { defineComponent, provide, toRefs, reactive } from '@vue/composition-api'
import NotificationListItem from './NotificationListItem.vue'

/**
 * Notification list options
 * @typedef {Object} Options
 * @property {boolean} slim Slim mode
 * @property {boolean} showProject Show related project
 * @property {boolean} showDocument Show related document
 * @property {boolean} showClose Show close button (mark as read)
 * @property {boolean} showBullet Bullet list style
 * @property {boolean} showMarkAsRead Show mark as read button
 */

const pickOptions = pick([
  'slim',
  'showProject',
  'showDocument',
  'showClose',
  'showBullet',
  'showMarkAsRead',
])

export default defineComponent({
  name: 'NotificationList',
  components: {
    NotificationListItem,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    itemClass: {
      type: [String, Array, Object],
      default: null,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    showProject: {
      type: Boolean,
      default: false,
    },
    showDocument: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    showBullet: {
      type: Boolean,
      default: false,
    },
    showMarkAsRead: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // to prevent prop drilling, inner components can inject 'options' and get reactive object for:
    // example: `const { slim, showProject, ... } = inject('options')`

    const options = reactive(pickOptions(toRefs(props)));
    provide('options', options)
  },
})
</script>

<style lang="postcss" scoped>
  .notifications-list-enter-active,
  .notifications-list-leave-active {
    transition: all .5s;
  }
  .notifications-list-enter,
  .notifications-list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translatex(-220px);
  }
  .notifications-list-move {
    transition: all .5s;
  }
</style>
