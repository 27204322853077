<template>
  <div class="table w-full is-preview portal-paragraph-font">
    <!-- HEADER -->
    <div class="hidden w-full mb-0 font-semibold xl:table-row is-preview table-row-header">
      <div class="table-cell-header name">
        {{ $t('blocks.cost.name') }}
      </div>
      <div class="table-cell-header qty">
        {{ $t('blocks.cost.quantity') }}
      </div>
      <div class="table-cell-header rate capitalize">
        {{ $tc('blocks.cost.item',1) }}
      </div>
      <div class="table-cell-header cost">
        {{ $t('blocks.cost.cost') }}
      </div>
    </div>
    <!-- END HEADER -->

    <!-- CONTENTS -->
    <DocumentContentBlockCostItem
      v-for="billable in items"
      :key="`${billable.id}-${billable.quantity}-${billable.unit}`"
      :billable="billable"
      :document-id="documentId"
      :node-width="nodeWidth"
    />
  </div>
</template>

<script>
import DocumentContentBlockCostItem from '@/v2/features/document/documentNodeImpl/cost/DocumentContentBlockCostItem.vue'

export default {
  name: 'CostBreakdownTable',
  components: {
    DocumentContentBlockCostItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: '$',
    },
    documentId: {
      type: String,
      required: true,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
