<template>
  <section class="space-y-12">
    <h2 class="title-h2 text-center mb-16">{{ $t('login.password-recover') }}</h2>
    <FormMessage v-if="formError">
      {{ formError.message }}
    </FormMessage>
    <b-form class="space-y-12" @submit.prevent="formSubmit">
      <b-form-group class="mb-0">
        <FormField
          :v="$v.email"
          required
          :messages="{
            required: $t('login.email-required'),
            email: $t('login.email-not-valid'),
          }"
        >
          <b-input
            autofocus
            :placeholder="$t('login.email')"
            :value="formState.email"
            autocomplete="username"
            class="form-control-lg"
            @input="setEmail"
          />
        </FormField>
      </b-form-group>
      <Button
        type="submit"
        size="lg"
        variant="primary"
        :block="true"
        :is-loading="formIsBusy"
        :class="buttonClass"
      >
        {{ $t('login.password-recover-send-link') }}
      </Button>
    </b-form>
  </section>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'
import { required, email as emailValidator } from '@vuelidate/validators'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import useForm from '@/v2/lib/composition/useForm'
import useNotification from '@/v2/lib/composition/useNotification'
import { useRequestPasswordReset } from '@/v2/services/accounts/accountsCompositions'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'


export default defineComponent({
  name: 'RecoverPasswordFormRequest',
  components: {
    FormField,
    Button,
    FormMessage,
  },
  props: {
    buttonClass: {
      type: [String, Object, Array],
      default: null,
    },
  },
  setup(props, context) {
    const formState = reactive({ email: '' })
    const notification = useNotification()
    const requestPasswordReset = useRequestPasswordReset()

    const handleError = err => {
      notification({
        message: String(err),
        variant: 'danger',
      })

      context.emit('error', err)
    }

    const submit = async () => {
      try {
        await requestPasswordReset(formState.email)
        context.emit('success')
      } catch (err) {
        // Swallow 404s due to security implications
        if (err.code === 404) {
          // NOTE: An error message is not shown to the user on purpose as revealing if an email
          // address is or isn't associated with a SuperOkay account might be a security issue
        } else {
          // Show other errors as they might be helpful
          handleError(err)
        }
      }
    }

    const { formSubmit, formError, $v, formIsBusy } = useForm({
      onSubmit: submit,
      state: formState,
      validation: {
        email: {
          required,
          email: emailValidator,
        },
      },
    })

    function setEmail(email) {
      formState.email = emailSanitizer(email)
    }

    return {
      formState,
      formError,
      formSubmit,
      $v,
      setEmail,
      formIsBusy,
    }
  },
})
</script>
