
<div v-fragment>
  <div
    :class="[
      'group relative flex flex-col justify-between overflow-hidden',
      'card-interactive transition-all cursor-pointer',
    ]"
    @click="openModal"
  >
    <TemplatePreviewCard
      :template="template"
      :category="category"
    />
  </div>
  <DocumentTemplateModal
    v-if="!noModal"
    v-model="showTemplateModal"
    :template="template"
    :is-loaded="isLoaded"
    @use="onTemplateSelect"
    @hide="$tracking.track('Document Template Preview Modal Closed')"
  />
</div>
