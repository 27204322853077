
<div v-fragment>
  <!-- overlay -->
  <div
    class="blocker-overlay cursor-default"
    @mouseover.prevent.stop="() => null"
    @click.stop="() => null"
  />

  <!-- drawer -->
  <div class="drawer-overlay">
    <div class="drawer-card">
      <div
        class="
          flex items-center bg-white dark:bg-darkGray-900
          p-24 justify-between border-b border-gray-200 dark:border-darkGray-700
        "
      >
        <h4 class="text-22 font-normal">
          {{ title }}
        </h4>
        <b-button
          size="sm"
          variant="slim"
          @click="$emit('close')"
        >
          <span class="icon_v2-so_close w-24 text-24" />
        </b-button>
      </div>
      <slot />
      <div
        v-if="!hideCloseButton"
        class="
          bg-white dark:bg-darkGray-900 flex items-center p-16
          border-t border-gray-200 dark:border-darkGray-700
        "
      >
        <b-button
          size="md"
          variant="primary"
          class="w-full"
          @click="$emit('close')"
        >
          {{ closeLabel }}
        </b-button>
      </div>
    </div>
  </div>
</div>
