<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isBlankState"
    :root-class="[
      'node-image',
      noPaddingTop && $style['no-top-border-radius'],
    ]"
    :section-class="['node-image-section']"
    :config-override="{
      stylingToolbar: {
        features: {
          objectAlign: true,
          sizes: true,
        },
      },
      styling: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    }"
  >
    <template #default="{ styling, textVariant, isHover }">
      <DocumentContentBlockImage
        :content-block="contentBlock"
        :node-id="node._id"
        :styling="styling"
        :text-variant="textVariant"
        :is-hover="isHover"
        @update="data => $emit('update', data)"
        @update-node="data => $emit('update-node', data)"
        @async-save-start="$emit('async-save-start')"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { equals, pathSatisfies } from 'ramda'
import { computed } from '@vue/composition-api'
import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockImage from './DocumentContentBlockImage.vue'

const isZeroPaddingTop = pathSatisfies(equals(0), ['styling', 'paddingTop'])

export default {
  name: 'DocumentNodeImage',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockImage,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const noPaddingTop = computed(() => isZeroPaddingTop(props))
    const isBlankState = computed(() => pathIsNilOrEmpty(['contentBlock', 'url'], props))

    return {
      noPaddingTop,
      isBlankState,
    }
  },
}
</script>
<style lang="scss">
.node-image-section {
  max-width: 100%;
}
</style>
