import { when, is, pipe, replace, toLower } from 'ramda'

/**
 * Transforms a string written in PascalCase into kebab-case.
 * e.g.: 'DocumentContentBlockImage' to 'document-content-block-image'
 * @param {String} pascalCasedText
 * @returns {String}
 */
// eslint-disable-next-line import/prefer-default-export
export const pascalToKebab = when(
  is(String),
  pipe(
    replace(/([a-z])([A-Z])/g, '$1-$2'),
    toLower
  )
)

/**
 * Calculates and returns the first 2 initials of a person's name
 * @param {String} fullName A person's full name. E.g.: 'John Doe', 'Bill Tom Burgundy', 'Kevin',
 * 'G'
 * @returns {String} Computed initials based on provided name
 */
// eslint-disable-next-line import/prefer-default-export
export function getInitials(fullName = '') {
  const [firstName, middleOrLastName] = fullName
    .toUpperCase() // Ensure all letters are uppercased
    .trim() // No extra whitespaces
    .split(' '); // Split full name into components

  if (!firstName) return ''; // Nothing to process

  if (!middleOrLastName) {
    // Identifiable only via a first name
    return firstName.slice(0, 2); // First 2 letters of first name
  }
  // Full name contains at least 2 names. Get initials of first 2 names
  return firstName.charAt(0).concat(middleOrLastName.charAt(0));
}

/** Transforms a keyword into a Regular Expression for easy matching against some target strings.
 * If `text` contains only one letter, `^` is prepended to it, in the resulting regex, in
 * order to match strings beginning with said letter and not try to match one letter anywhere
 * in target strings. Case insensitive */
export const keywordToRegex = text => new RegExp(`${(text.length === 1 ? '^' : '')}${text}`, 'i')
