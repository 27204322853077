
<!-- <portal :to="`context-menu-node-${node._id}`">
    <div v-fragment>
      <b-dropdown-divider />
      <b-dropdown-item>Heading Action 1</b-dropdown-item>
      <b-dropdown-item>Heading Action 2</b-dropdown-item>
    </div>
  </portal> -->
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  :root-class="$style['node-heading']"
  :section-class="$style['node-heading-section']"
  :config-override="{
    stylingToolbar: {
      features: {
        headingSizes: true,
        textColor: true,
      },
    },
    styling: {
      size: 'S',
    },
  }"
>
  <template #default="{ styling, textVariant }">
    <DocumentContentBlockHeading
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot
      name="editor"
      v-bind="{ config, styling, textVariant }"
    />
  </template>
</DocumentNodeRenderer>
