import { isBefore, parseISO } from 'date-fns'
import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import actionItemsService, { servicePath } from './actionItemsService'

class ActionItem extends BaseModel {
  static modelName = 'ActionItem'

  static instanceDefaults() {
    return {
      title: '',
      notes: '',
      isResolved: false,
      public: true,
      dueDate: null,
      assignedTo: null,
      taskSection: null,
      files: [],
    }
  }

  get isDue() {
    if (!this.dueDate) {
      return false;
    }

    const due = parseISO(this.dueDate);
    const now = new Date()
    return isBefore(due, now)
  }
}

export default makeServicePlugin({
  Model: ActionItem,
  service: actionItemsService,
  servicePath,
})
