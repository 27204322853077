
<div class="flex items-center gap-16">
  <DropdownSelect
    v-if="!noGroupBy"
    v-model="groupBy"
    :icon="groupByIcons[groupBy]"
    :options="optionsGroupBy"
    class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
  />
  <DropdownSelect
    v-model="sortBy"
    :icon="sortByIcons[sortBy]"
    :options="optionsSortBy"
    class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
  />
  <DropdownSelect
    v-model="viewAs"
    :icon="viewAsIcons[viewAs]"
    :options="optionsViewAs"
    class-name="dark:border-darkGray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
  />
</div>
