<script>
import { defineComponent } from '@vue/composition-api'
import { useLocalStorageReactive } from '@/v2/lib/composition/useLocalStorage'
import { PROJECT_VIEW_DEFAULTS } from '@/v2/services/projects/projectsTypes'

export default defineComponent({
  name: 'ProjectViewContext',
  setup(props, context) {
    const viewContext = useLocalStorageReactive('projectViewContext', PROJECT_VIEW_DEFAULTS)

    const updateViewContext = update => {
      Object.assign(viewContext, update)
    }

    return () => context.slots.default({
      viewContext,
      updateViewContext,
    })
  },
})
</script>
