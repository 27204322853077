<template>
  <div class="text-yellow-600 text-14 font-semibold flex items-center gap-x-8">
    <!-- <span class="icon_v2-so_info animate-pulse" /> -->
    <span class="flex h-12 w-12 relative">
      <span
        class="
        animate-ping absolute inline-flex h-full w-full
        rounded-full bg-yellow-600 opacity-75
      "
      />
      <span class="relative inline-flex rounded-full h-12 w-12 bg-yellow-600" />
    </span>
    {{ message }}
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PageFooterMessage',
  components: {

  },
  props: {
    message: {
      type: String,
      default: 'You have unsaved changes',
    },
  },
})
</script>
