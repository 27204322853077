<template>
  <div v-fragment>
    <!-- ------------------- -->
    <!-- WELCOME MESSAGE -->
    <!-- ------------------- -->
    <p
      class="
        text-center text-gray-500
      dark:text-white dark:opacity-70 text-14
        portal-paragraph-font font-normal mb-40
      "
    >
      {{ portalCustomizations.welcomeMessage }}
    </p>
    <FormMessage v-if="formError">
      {{ formError.message }}
    </FormMessage>
    <FormMessage v-if="success" variant="success">
      {{ $t('login.magic-link-sent') }} <strong>{{ formState.email }}</strong>
    </FormMessage>
    <b-form v-else @submit.prevent="formSubmit">
      <FormField
        :v="$v.email"
        :label="$t('login.email')"
        :messages="{
          required: $t('login.email-required'),
          email: $t('login.email-not-valid'),
        }"
        wrapper-class="portal-paragraph-font"
      >
        <b-input
          autofocus
          size="lg"
          autocomplete="username"
          placeholder="example@domain.com"
          class="form-control-lg portal-paragraph-font"
          :value="formState.email"
          @input="onEmailInput"
        />
      </FormField>

      <Button
        class="mt-16 portal-paragraph-font portal-button"
        type="submit"
        size="lg"
        variant="primary"
        :block="true"
        :is-loading="formIsBusy"
      >
        {{ portalCustomizations.loginButtonLabel || $t('login.magic-link-login') }}
      </Button>
    </b-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { required, email } from '@vuelidate/validators'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import useForm from '@/v2/lib/composition/useForm'
import { useAccountsResendMagicLink } from '@/v2/services/accounts/accountsCompositions'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'

export default defineComponent({
  name: 'ClientPortalLoginMagicLink',
  components: {
    FormField,
    Button,
    FormMessage,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
    portalCustomizations: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const success = ref(false)
    const formState = reactive({ email: '' })
    const resendMagicLink = useAccountsResendMagicLink()

    const login = async () => {
      // reset success for each login attempt
      success.value = false
      // there's no need to wrap the call in try-catch block
      // the API call will throw and then caught by useForm hook automatically => formError is set
      await resendMagicLink(formState.email, props.domain._id)
      // the API call didn't throw => set success = true
      success.value = true
    }

    const { formSubmit, formError, formIsBusy, $v } = useForm({
      onSubmit: login,
      state: formState,
      validation: {
        email: {
          required,
          email,
        },
      },
      vuelidateOptions: { $scope: 'login' },
    })

    const onEmailInput = value => {
      formState.email = emailSanitizer(value)
    }

    return {
      // Static
      // State
      formIsBusy,
      formState,
      formSubmit,
      formError,
      $v,
      success,
      // Methods
      onEmailInput,
    }
  },
})
</script>
