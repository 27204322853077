<template>
  <div class="-m-16">
    <DocumentGet
      :id="documentId"
      v-slot="{ document, isGetPending: isPending }"
    >
      <Provide v-if="!isPending" :provide="{ document }">
        <ProjectTheme
          :project="project"
          no-dark-mode
          :wrapper-class="[
            project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page'
          ]"
        >
          <DocumentViewerClientPortal
            :document="document"
            :organization="organization"
            is-client-portal
            :project="project"
          />
        </ProjectTheme>
      </Provide>
    </DocumentGet>
  </div>
</template>
<script>
import { defineComponent, provide } from '@vue/composition-api'
import DocumentGet from '@/components/DocumentGet.vue'
import Provide from '@/components/Provide.vue'
import DocumentViewerClientPortal from '@/components/Document/Viewer/DocumentViewerClientPortal.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'

export default defineComponent({
  name: 'ViewPage',
  components: {
    DocumentGet,
    Provide,
    DocumentViewerClientPortal,
    ProjectTheme,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  setup() {
    provide('isPage', true);
  },
})
</script>
