
<Form
  v-if="state"
  v-slot="{ v }"
  class="so-form portal-paragraph-font"
  :class="{
    'so-form-4': !hidePortalVisibility,
    'so-form-3': hidePortalVisibility
  }"
  :style="projectTheme"
  :state="state"
  :validation="validation"
  @submit="onSubmit"
>
  <div class="so-form-header">
    <div class="flex-1">
      <ContentEditable
        v-model="state.title"
        :title-topbar="state.title"
        :placeholder="$t('tasks.titlePlaceholder')"
        tag="p"
        class-name="
          text-22 font-light smart-word-break
          flex-1 portal-heading-font dark:text-white/70
        "
        :no-html="true"
        no-newline
        :contenteditable="!isReadonly"
        trim
        autofocus
      />
      <span
        v-if="v.title.$dirty && v.title.$error"
        class="text-error mb-4"
      >Title is required</span>
    </div>
    <div class="flex items-center gap-8 mt-4">
      <label
        for="task-editor-resolved"
        class="text-14 select-none"
        :class="{
          'text-gray-400': !state.isResolved,
          'text-green-600': state.isResolved
        }"
      >{{ $t('tasks.taskEditor.resolved') }}</label>
      <b-form-checkbox
        id="task-editor-resolved"
        v-model="state.isResolved"
        class="-mr-[12px]"
        switch
        size="lg"
      />
    </div>
  </div>
  <div class="so-form-group">
    <label class="so-form-label">{{ $t('tasks.taskEditor.assignee') }}</label>
    <div class="so-form-input-container">
      <Multiselect
        v-if="!isReadonly"
        v-model="assigneeModel"
        :options="assigneeOptions"
        :placeholder="$t('tasks.taskEditor.assigneePlaceholder')"
        :custom-label="assigneeLabel"
        track-by="userId"
        :show-labels="false"
      >
        <template #singleLabel="props">
          <Avatar
            :profile="props.option.profile"
            show-name
            :nowrap="false"
          />
        </template>
        <template #option="props">
          <Avatar
            :profile="props.option.profile"
            show-name
            :nowrap="false"
          />
        </template>
      </Multiselect>
      <AvatarUser
        v-else-if="entity.assignedTo"
        class="dark:text-white/70"
        :user-id="entity.assignedTo"
        show-name
        :nowrap="false"
      />
      <div v-else>-</div>
      <ButtonClear v-if="!isReadonly" @click="assigneeModel = null" />
    </div>
  </div>
  <div class="so-form-group">
    <label class="so-form-label">{{ $t('tasks.taskEditor.dueDate') }}</label>
    <div class="so-form-input-container">
      <TaskDatePicker
        v-if="!isReadonly"
        v-model="state.dueDate"
        class="datepicker"
        :readonly="isReadonly"
      />
      <div v-else>
        <ActionItemDateDisplay
          v-if="entity.dueDate"
          v-slot="{ displayDate }"
          :item="entity"
        >
          <span class="icon-util-calendar text-16" />
          {{ displayDate.message }}
        </ActionItemDateDisplay>
        <div v-else>-</div>
      </div>
      <ButtonClear v-if="!isReadonly" @click="state.dueDate = null" />
    </div>
  </div>
  <div v-if="!hidePortalVisibility" class="so-form-group">
    <label for="task-editor-public" class="so-form-label">Portal visibility</label>
    <div class="so-form-input-container">
      <div class="so-form-input flex items-center gap-12">
        <b-form-checkbox
          id="task-editor-public"
          v-model="state.public"
          class="-mr-[12px]"
          switch
          size="lg"
        />
        <div class="text-14 text-gray-400">
          <span v-if="state.public">Client can see this</span>
          <span v-else>Private to your team</span>
        </div>
      </div>
    </div>
  </div>
  <div class="so-form-group-v">
    <label for="task-editor-description" class="so-form-label">
      {{ $t('tasks.taskEditor.description') }}
    </label>
    <b-textarea
      v-if="!isReadonly"
      id="task-editor-description"
      v-model="state.notes"
      class="so-form-input-v"
    />
    <div v-else class="form-control so-form-input-v">{{ state.notes }}</div>
  </div>
  <div class="col-span-2">
    <ProjectTaskEditorAttachments
      :task="state"
      :readonly="isReadonly"
      @update="update"
    />
  </div>
  <div class="col-span-2">
    <ProjectTaskEditorInfo
      v-if="!isNew"
      :task="entity"
    />
  </div>
  <div class="so-form-buttons">
    <div class="flex items-center gap-8">
      <Button
        size="md"
        variant="primary"
        type="submit"
        :is-loading="isSaving"
        class="portal-accent-background portal-accent-foreground"
      >
        <span class="mr-4 -ml-4 icon_v2-so_tick" />
        <span v-if="isNew">{{ $t('tasks.taskEditor.create') }}</span>
        <span v-else>{{ $t('tasks.taskEditor.update') }}</span>
      </Button>
      <Button
        size="md"
        variant="neutral"
        type="button"
        @click="$emit('close')"
      >
        <span class="mr-4 -ml-4 icon_v2-so_close text-gray-600 dark:text-darkGray-400" />
        <span>{{ $t('tasks.taskEditor.close') }}</span>
      </Button>
    </div>
    <Button
      v-if="!isNew && !isReadonly"
      size="md"
      variant="neutral"
      type="button"
      @click="$emit('remove', entity)"
    >
      <span class="mr-4 -ml-4 icon_v2-so_trash text-red-600" />
      <span>{{ $t('tasks.taskEditor.delete') }}</span>
    </Button>
  </div>
</Form>
