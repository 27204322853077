/* eslint-disable class-methods-use-this */
import { rejectNil } from 'ramda-extension'
import { rejectNilOrEmpty } from '@/v2/lib/helpers/fp'
import debounce from '@/v2/lib/helpers/debounce'

export default class Tracking {
  constructor({ config, service, store, router } = {}) {
    this.config = rejectNilOrEmpty(config)
    this.service = service
    this.store = store
    this.router = router

    this.enable = config.enable
    this.debug = config.debug

    // 'auth/setUser' mutation is called twice by feathersvuex
    // thus we need to debounce it
    this.setUserDebounced = debounce(() => this.setUser())

    // always init listeners for debugging/logging purposes
    this.store.subscribe(this.storeSubscriber.bind(this))

    this.store.subscribeAction({
      before: this.storeActionBeforeSubscriber.bind(this),
      // after: this.storeActionAfterSubscriber.bind(this),
    })

    this.router.afterEach(this.afterEachRoute.bind(this))
  }

  log(...args) {
    if (this.debug) {
      console.log('[tracking]', ...args);
    }
  }

  /** @private */
  async _track(data, options = {}) {
    const { requiresAuth = true } = options;
    if (requiresAuth && !this.store.getters['auth/isAuthenticated']) {
      this.log('tracking disabled: not authenticated')
      return null
    }

    return this.service.create(data)
  }

  isAuthenticated() {
    return this.store.getters['auth/isAuthenticated']
  }

  /** @private */
  getRouteData() {
    const { currentRoute: route } = this.router

    return {
      page: route.name,
      organization: route.params.organizationId,
      project: route.params.projectId,
      document: route.params.documentId,
    }
  }

  /** @private */
  afterEachRoute(to, from, failure = null) {
    this.trackPageView(to.name, {
      url: to.fullPath,
      params: to.params,
      origin: from.name,
      failure,
    })
  }

  /** @private */
  storeSubscriber(mutation) {
    const { type } = mutation

    switch (type) {
      case 'auth/setUser':
        this.setUserDebounced()
        break
      default:
        break
    }
  }

  storeActionBeforeSubscriber(action, state) {
    switch (action.type) {
      case 'auth/logout':
        this.track('Logout')
        break;
      default:
        break;
    }
  }

  // storeActionAfterSubscriber(action, state) {
  //   console.log('storeActionAfterSubscriber', action)
  // }

  getAdditionalData() {
    if (!this.isAuthenticated()) {
      return {}
    }

    const { user = {} } = this.store.state.auth

    return rejectNil({
      email: user.email,
    })
  }

  /**
   * @param {string} event
   * @param {Object} [data]
   */
  async track(event, data = {}, options = {}) {
    const _data = rejectNil({
      ...data,
      ...this.getRouteData(),
      ...this.getAdditionalData(),
    })

    this.log('tracking.track', event, _data)

    if (!this.enable) {
      return
    }

    try {
      const payload = { event, data: _data }
      await this._track({ type: 'event', payload }, options)
    } catch (err) {
      this.log(err)
    }
  }

  async trackGuest(event, date = {}, options = {}) {
    return this.track(event, date, { ...options, requiresAuth: false })
  }

  async trackPageView(page, data = {}) {
    const _data = rejectNil({ ...data, ...this.getRouteData() })
    this.log('tracking.trackPageView', page, _data)

    if (!this.enable) {
      return
    }

    try {
      await this._track({
        type: 'pageView',
        payload: { page, data: _data },
      }, { requiresAuth: false })
    } catch (err) {
      this.log(err)
    }
  }

  async setUser() {
    const { user } = this.store.state.auth
    const { profile$: profile } = user

    const data = {
      user: user._id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: user.email,
    }

    this.log('tracking.setUser', data)

    if (!this.enable) {
      return
    }

    try {
      await this._track({
        type: 'setUser',
        payload: data,
      })
    } catch (err) {
      this.log(err)
    }
  }
}
