
<div
  :class="{ interactive: !disabled }"
  style="text-align: left"
>
  <div
    ref="container"
    class="slider1d-container relative"
    @dblclick.stop="$emit('reset')"
  >
    <!-- <div class="slider1d-gradient-wrapper">
      <div
        class="slider1d-gradient"
        :style="
          `background: radial-gradient(circle ` +
            `at ${(xPosition + 0.5) * 100}% 50%, ` +
            `rgba(35, 184, 57, 0.3) 0%, ` +
            `rgba(35, 184, 57, 0.1) 15%, ` +
            `rgba(35, 184, 57, 0) 55%, ` +
            `rgba(35, 184, 57, 0) 100%)`
        "
      />
    </div> -->
    <span class="slider-container-line" />
    <div
      ref="draggable"
      v-b-tooltip="{
        placement: 'bottom',
        trigger: 'hover',
        variant: 'info',
        delay: {'show':200,'hide':0},
        title: showTooltip ? 'Drag me to answer' : '',
      }"
      class="slider1d-draggable"
      :class="[shouldPing && 'ping']"
      :style="draggableStyle"
      @mousedown="onDragStart"
      @touchstart="onDragStart"
    >
      <span
        v-if="!disabled"
        :class="[
          'icon_v2-so_drag',
          'transition-colors pointer-events-none',
        ]"
      />
    </div>
  </div>
</div>
