<template>
  <ModalBasic
    title="Document Fonts"
    :hide-footer="true"
    dialog-class="w-full"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="lg"
    v-bind="$attrs"
    :show-back="openToSelectFont"
    v-on="$listeners"
    @back="backToForm"
  >
    <template #body="{ close }">
      <b-form
        v-if="!openToSelectFont"
        @submit.prevent="formSubmit"
      >
        <FormMessage v-if="formError">
          {{ formError.message }}
        </FormMessage>
        <FormPickFont
          :model="model"
          @change-font="changeFont"
        />
        <Buttons>
          <Button
            type="button"
            variant="neutral"
            size="md"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="md"
          >
            Save
          </Button>
        </Buttons>
      </b-form>
      <BrandingFonts
        v-if="openToSelectFont"
        :apply-font-on="applyFontOn"
        :model="model"
        @select="selectFont"
      />
    </template>
  </ModalBasic>
</template>

<script>

import { defineComponent, ref } from '@vue/composition-api'
import useFormFeathers from '@/v2/lib/composition/useFormFeathers'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import FormMessage from '@/components/FormMessage.vue'
import FormPickFont from '@/components/FormPickFont.vue'
import BrandingFonts from '@/components/BrandingFonts.vue'
import Buttons from '@/components/Buttons.vue'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'DocumentFormModal',
  components: {
    FormMessage,
    ModalBasic,
    FormPickFont,
    BrandingFonts,
    Buttons,
    Button,
  },
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const model = props.document.clone()
    const openToSelectFont = ref(false)
    const applyFontOn = ref(null)

    const selectFont = (value, applyOn) => {
      model[applyOn] = value
      openToSelectFont.value = false
    }
    const changeFont = applyOn => {
      applyFontOn.value = applyOn
      openToSelectFont.value = true
    }
    const backToForm = () => { openToSelectFont.value = false }

    const { formError, formSubmit } = useFormFeathers({
      model,
      fields: ['brandingFontHeading', 'brandingFontParagraph'],
      onSave: () => context.emit('save'),
    })

    return {
      model,
      formError,
      formSubmit,
      selectFont,
      openToSelectFont,
      applyFontOn,
      changeFont,
      backToForm,

    }
  },
})
</script>

<style></style>
