<template>
  <ListMultiSelect
    v-slot="{ item: billable }"
    v-model="selectedBillables"
    :items="items"
    id-prop="rateId"
  >
    <p class="grow inline-flex items-center">
      {{ billable.name }}
      <b-badge
        v-if="!billable.isBillable"
        variant="neutral"
        class="mx-4"
      >
        Not billable
      </b-badge>
    </p>
    <div class="text-right mr-auto font-semibold">
      <DocumentCurrency
        :value="Number(billable.rate)"
        :document-id="document._id"
      /> / {{ billable.unit }}
    </div>
  </ListMultiSelect>
</template>

<script>
import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import DocumentCurrency from './DocumentCurrency.vue'
import ListMultiSelect from './ListMultiSelect.vue'

export default defineComponent({
  name: 'ListMultiSelectBillables',
  components: {
    ListMultiSelect,
    DocumentCurrency,
  },
  props: {
    billables: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    // All compatible billables to be used in the modal
    const document = inject('document')

    const items = computed(
      () => props.billables
        // Only consider Billables that have at least one rate
        .filter(billable => billable.rates && billable.rates.length)
        // For each billable with at least one rate
        .map(billable => billable.rates.map(rate => ({ // Add each rate as an item in the array
          rateId: rate.id,
          name: billable.name,
          unit: rate.unit,
          currency: rate.currency,
          rate: rate.rate,
          checked: false,
          isBillable: billable.isBillable,
          fromLibrary: true, // so we can identify them later on
        })))
        .flat()
    )

    const selection = ref([])

    const selectedBillables = computed({
      get() {
        return selection.value
      },
      set(newSelection) {
        selection.value = newSelection
        context.emit('selected', items.value.filter(item => newSelection.includes(item.rateId)))
      },
    })

    return {
      items,
      document,
      selectedBillables,
      selection,
    }
  },
})
</script>
