<template>
  <div v-if="status && displayStatus" class="inline-flex flex-row items-center">
    <!-- Display full status badge -->
    <div
      v-if="displayFull"
      :class="[
        variant.default,
        dropdown && variant.hover,
        dropdown && 'badge-dropdown',
        'transition-all font-semibold text-14 rounded-md h-32',
        ' flex items-center justify-between px-10 leading-none',
      ]"
    >
      <span v-if="isApproved" class="icon_v2-so_tick mr-4 opacity-60" />
      {{ status | translate }}
      <span v-if="dropdown" class="relative icon_v2-so_direction-down !ml-8"></span>
    </div>
    <!-- Display small status badge -->
    <span
      v-if="displaySmall"
      :class="[
        variant.default,
        dropdown && variant.hover,
        dropdown && 'badge-dropdown',
        'transition-all font-semibold text-11 rounded-[4px] h-16',
        ' flex items-center justify-between px-4 leading-none',
      ]"
    >
      <span v-if="isApproved" class="w-12 text-12 icon_v2-so_lock" />
      {{ status | translate }}
      <span v-if="dropdown" class="relative icon_v2-so_direction-down ml-6"></span>
    </span>
    <!-- Display minimized badge with lock icon -->
    <span
      v-else-if="displayLock"
      class="inline-flex items-center text-green-600 ml-12"
    >
      <span class="icon_v2-so_lock w-12 text-12"></span>
      <span class="text-12">{{ status | translate }}</span>
    </span>
    <span
      v-else-if="displayText"
      :class="[
        'inline-flex items-center',
        isApproved ? 'text-green-600' : 'text-gray-500',
      ]"
    >
      <span
        :class="
          [
            'w-8 h-8 rounded-full',
            isApproved ? 'bg-green-600' : 'bg-gray-500',
          ]
        "
      ></span>
      <span class="text-12 ml-4">{{ status | translate }}</span>
    </span>
  </div>
</template>

<script>
import { STATUS as DOCUMENT_STATUS } from '@/types/document'

const variantMap = {
  [DOCUMENT_STATUS.APPROVED]: {
    default:
      'bg-green-500 text-green-100',
    hover: 'hover:ring-1 hover:ring-green-900 hover:ring-opacity-5 hover:text-white hover:bg-green-700 hover:shadow',
  },
  [DOCUMENT_STATUS.DRAFT]: {
    default: 'ring-1 bg-gray-100 text-gray-500 ring-gray-300 dark:bg-darkGray-700 dark:text-darkGray-300 dark:ring-darkGray-600',
    hover: 'hover:ring-gray-400 hover:text-gray-800 hover:shadow dark:hover:ring-darkGray-500 dark:hover:bg-darkGray-600',
  },
  [DOCUMENT_STATUS.NO_STATUS]: {
    default: 'ring-1 bg-gray-100 text-gray-500 ring-gray-300 dark:bg-darkGray-700 dark:text-darkGray-300 dark:ring-darkGray-600',
    hover: 'hover:ring-gray-400 hover:text-gray-800 hover:shadow dark:hover:ring-darkGray-500 dark:hover:bg-darkGray-600',
  },
  [DOCUMENT_STATUS.IN_REVIEW]: {
    default:
      'bg-yellow-400 text-darkGray-700',
    hover: 'hover:ring-1 hover:ring-gray-900 hover:ring-opacity-10 hover:text-gray-800 hover:shadow',
  },
  [DOCUMENT_STATUS.POSTPONED]: {
    default:
      'bg-gray-100 text-gray-700',
    hover: 'hover:ring-1 hover:ring-gray-900 hover:ring-opacity-10 hover:text-gray-800 hover:shadow',
  },
  [DOCUMENT_STATUS.IN_PROGRESS]: {
    default:
      'bg-blue-100 text-blue-800',
    hover: 'hover:ring-1 hover:ring-gray-900 hover:ring-opacity-10 hover:text-gray-800 hover:shadow',
  },
  [DOCUMENT_STATUS.DELIVERY_ACCEPTED]: {
    default:
      'bg-green-100 text-green-700',
    hover: 'hover:ring-1 hover:ring-gray-900 hover:ring-opacity-10 hover:text-gray-800 hover:shadow',
  },
  // [DOCUMENT_STATUS.POSTPONED]: 'badge-status-gray',
  // [PROPOSAL_STATUS.IN_PROGRESS]: 'badge-status-purple',
  // [PROPOSAL_STATUS.DELIVERY_ACCEPTED]: 'badge-status-blue',
  // [BRIEF_STATUS.SENT]: 'badge-status-blue'
}

export default {
  name: 'DocumentStatusBadge',
  props: {
    status: {
      type: String,
      default: '',
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    displayAs: {
      type: String,
      default: 'full',
    },
    onlyApproved: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variant() {
      return variantMap[this.status]
    },
    displayFull() {
      return this.displayAs === 'full'
    },
    displayLock() {
      return this.displayAs === 'lock'
    },
    displayText() {
      return this.displayAs === 'text'
    },
    displaySmall() {
      return this.displayAs === 'small'
    },
    isApproved() {
      return this.status === DOCUMENT_STATUS.APPROVED
    },
    displayStatus() {
      if (this.onlyApproved && this.status !== DOCUMENT_STATUS.APPROVED) return false
      return true
    },
  },
}
</script>
