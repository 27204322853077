import {
  propEq, has, map, pipe, find, pathEq,
  reverse, prop, propOr, isNil,
} from 'ramda'
import { noop } from 'ramda-adjunct'
import { computed, getCurrentInstance, toRef, watch, ref, onMounted } from '@vue/composition-api'
import { switchCondOr } from '../helpers/fp'

// -- helpers

const mapMeta = pipe(
  propOr([], 'matched'),
  reverse,
  map(prop('meta'))
)

const getMeta = key => pipe(
  mapMeta,
  find(has(key)),
  prop(key)
)

const metaEq = (key, value) => pipe(
  mapMeta,
  // any(propEq(key, value)),
  entries => entries.some((entry = {}) => entry[key] === value)
)

const ownMetaEq = (key, value) => pathEq(['meta', key], value)

// -- end helpers

export function useRouter() {
  const vm = getCurrentInstance().proxy
  const router = vm.$router;

  const _catch = err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  }

  /** @param  {import('vue-router').RawLocation} location */
  const routerPush = location => router.push(location).catch(_catch)

  /** @param  {import('vue-router').RawLocation} location */
  const routerReplace = location => router.replace(location).catch(_catch)

  return {
    router,
    routerPush,
    routerReplace,
  }
}

const checkRouteInit = (routerMode, route) => {
  const { fullPath } = route
  const { pathname, hash } = window.location

  // vue router is always matching the root route first then the specific route (if any)
  // thus a low level check against window.location is needed
  return switchCondOr(true, [
    [isNil(route), false],
    [fullPath === '/' && routerMode === 'history' && pathname.trim() !== '/', false],
    [fullPath === '/' && routerMode === 'hash' && hash.trim() !== '#/', false],
  ])
}

/**
 * Route helpers
 * @param {Object} [options]
 * @param {boolean} [options.watchRouteReady] When true, enable route init watcher and set routeReady
 * @param {Function} [options.onRouteReady] Callback to call when the route is ready
 * @returns
 */
export function useRoute(options = {}) {
  const { watchRouteReady = false, onRouteReady = noop } = options
  const vm = getCurrentInstance().proxy

  const routeReady = ref(false)
  const $route = toRef(vm, '$route')
  const { $router } = vm;

  const routeIsChildOf = name => $route.value.matched.some(propEq('name', name))
  const routeIs = name => $route.value.name === name
  const routeMeta = (key, route = null) => getMeta(key)(route ?? $route.value)
  const routeMetaEq = (key, value, route = null) => metaEq(key, value)(route ?? $route.value)
  const routeOwnMetaEq = (key, value, route = null) => ownMetaEq(key, value)(route ?? $route.value)
  const routeIsRoot = computed(() => $route.value?.fullPath === '/')

  if (watchRouteReady) {
    const watcher = () => {
      const unwatch = watch($route, newRoute => {
        if (checkRouteInit($router.mode, newRoute)) {
          vm.$nextTick(() => unwatch())
          routeReady.value = true
          onRouteReady()
        }
      }, { immediate: true })
    };

    onMounted(() => {
      watcher()
    })
  }


  return {
    route: $route,
    routeIsChildOf,
    routeMeta,
    routeMetaEq,
    routeOwnMetaEq,
    routeIs,
    routeIsRoot,
    routeReady,
  }
}

export function useRouteParams(params = []) {
  const vm = getCurrentInstance().proxy
  return Object.fromEntries(
    params.map(param => [
      param,
      computed(() => vm.$route.params[param]),
    ])
  )
}

export function useRouteQuery(params = []) {
  const vm = getCurrentInstance().proxy
  return Object.fromEntries(
    params.map(param => [
      param,
      computed(() => vm.$route.query[param]),
    ])
  )
}

// export function useRouteMatch() {
//   const vm = getCurrentInstance().proxy
//   return (route, exact = true) => {
//     return computed(() => ({
//       name: vm.$route.name,
//       params: vm.$route.params,
//     }))
//   }
// }
