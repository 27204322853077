<!-- eslint-disable max-len -->
<template>
  <!-- <div>{{ notification.subjectType }} - {{ notification.action }} - {{ component.name }}</div> -->
  <component
    :is="component"
    v-if="component"
    :notification="notification"
    @mark-as-read="notification => $emit('mark-as-read', notification)"
  />
  <b-alert v-else variant="danger">
    Unable to display {{ notification.subjectType }} / {{ notification.action }}
  </b-alert>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { SUBJECT, ACTION } from '@/v2/services/notifications/notificationsTypes'
// -- action items
import NotifActionItemsCreated from './NotifActionItemsCreated.vue'
import NotifActionItemsResolved from './NotifActionItemsResolved.vue'
import NotifActionItemsReopened from './NotifActionItemsReopened.vue'
import NotifActionItemsAssigned from './NotifActionItemsAssigned.vue'
// -- comments
import NotifCommentsCreated from './NotifCommentsCreated.vue'
import NotifCommentsResolved from './NotifCommentsResolved.vue'
import NotifCommentsReopened from './NotifCommentsReopened.vue'
// -- content blocks
import NotifContentBlocksQaAnswered from './NotifContentBlocksQaAnswered.vue'
// -- documents
import NotifDocumentsDrafted from './NotifDocumentsDrafted.vue'
import NotifDocumentsApproved from './NotifDocumentsApproved.vue'
import NotifDocumentsNotify from './NotifDocumentsNotify.vue'
import NotifDocumentsShared from './NotifDocumentsShared.vue'
import NotifDocumentsViewGuest from './NotifDocumentsViewGuest.vue'
import NotifDocumentsFirstViewGuest from './NotifDocumentsFirstViewGuest.vue'
import NotifDocumentsCreated from './NotifDocumentsCreated.vue'
import NotifDocumentsNewViewsGuest from './NotifDocumentsNewViewsGuest.vue'
import NotifDocumentsNewQaAnswers from './NotifDocumentsNewQaAnswers.vue'
// -- organizations
import NotifOrganizationsLibraryDocumentAdded from './NotifOrganizationsLibraryDocumentAdded.vue'
// -- projects
import NotifProjectsNewShares from './NotifProjectsNewShares.vue'

const COMPONENT_MAP = {
  [SUBJECT.actionItems]: {
    [ACTION[SUBJECT.actionItems].created]: NotifActionItemsCreated,
    [ACTION[SUBJECT.actionItems].resolved]: NotifActionItemsResolved,
    [ACTION[SUBJECT.actionItems].reopened]: NotifActionItemsReopened,
    [ACTION[SUBJECT.actionItems].assigned]: NotifActionItemsAssigned,
  },
  [SUBJECT.comments]: {
    [ACTION[SUBJECT.comments].created]: NotifCommentsCreated,
    [ACTION[SUBJECT.comments].resolved]: NotifCommentsResolved,
    [ACTION[SUBJECT.comments].reopened]: NotifCommentsReopened,
  },
  [SUBJECT.contentBlocks]: {
    [ACTION[SUBJECT.contentBlocks].qaAnswered]: NotifContentBlocksQaAnswered,
  },
  [SUBJECT.documents]: {
    // -- single
    [ACTION[SUBJECT.documents].created]: NotifDocumentsCreated,
    [ACTION[SUBJECT.documents].drafted]: NotifDocumentsDrafted,
    [ACTION[SUBJECT.documents].approved]: NotifDocumentsApproved,
    [ACTION[SUBJECT.documents].notify]: NotifDocumentsNotify,
    [ACTION[SUBJECT.documents].sharedByClient]: NotifDocumentsShared,
    [ACTION[SUBJECT.documents].sharedByVendor]: NotifDocumentsShared,
    [ACTION[SUBJECT.documents].firstViewGuest]: NotifDocumentsFirstViewGuest,
    [ACTION[SUBJECT.documents].viewGuest]: NotifDocumentsViewGuest,
    // -- groups
    [ACTION[SUBJECT.documents].newViewsGuest]: NotifDocumentsNewViewsGuest,
    [ACTION[SUBJECT.documents].newQaAnswers]: NotifDocumentsNewQaAnswers,
  },
  [SUBJECT.organizations]: {
    // -- groups
    // eslint-disable-next-line max-len
    [ACTION[SUBJECT.organizations].libraryDocumentAdded]: NotifOrganizationsLibraryDocumentAdded,
  },
  [SUBJECT.projects]: {
    // -- groups
    [ACTION[SUBJECT.projects].newSharesVendor]: NotifProjectsNewShares,
    [ACTION[SUBJECT.projects].newSharesClient]: NotifProjectsNewShares,
  },
}

export default defineComponent({
  name: 'NotificationListItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => COMPONENT_MAP?.[props.notification.subjectType]?.[props.notification.action]
    )

    return {
      component,
    }
  },
})
</script>
