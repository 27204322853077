
<div class="flex items-center py-4">
  <label
    :for="componentID"
    class="mr-24 text-gray-600 text-14"
  >{{ label }}</label>
  <RangeSlider
    :value="value"
    :min="min"
    :max="max"
    @input="val => $emit('input', val)"
  />
  <div class="flex-none ml-8">
    <b-input
      :id="componentID"
      :value="value"
      type="number"
      step="1"
      :min="min"
      :max="max"
      size="sm"
      class="form-control-number w-48 text-center"
      number
      required
      @input="val => $emit('input', val)"
    />
  </div>
</div>
