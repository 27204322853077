import { has, mergeDeepRight } from 'ramda'
import {
  BaseModel,
  makeServicePlugin,
} from '@/v2/services/feathersClientService'
import documentsService, { servicePath } from './documentsService'
import defaults from './documentsDefaults'
import documentsStoreEx from './documentsStoreEx'
import { CATEGORY, STATUS } from './documentsTypes'
import { isOrganizationLevel, isProjectLevel } from './documentsCompositions'

class Document extends BaseModel {
  static modelName = 'Document'

  static instanceDefaults(data) {
    if (!has('category', data)) {
      return mergeDeepRight(defaults.common, data)
    }

    return mergeDeepRight(defaults.common, defaults[data.category] || {}, data)
  }

  static fieldsCreate() {
    return [
      'category',
      'organization',
      'project',
      'cloneOf',
      'cloneNodes',
      'title',
      'parentFolder',
      'file',
      'icon',


      'imageUrl',
      'description',

      // sharing
      'sharingClientEnabled',
      // 'sharingLinkEnabled',
    ]
  }

  get isApproved() {
    return this.status === STATUS.APPROVED
  }

  get isFolder() {
    return [
      CATEGORY.Folder,
      CATEGORY.ProjectRootFolder,
      CATEGORY.ProjectPublicFolder,
    ].includes(this.category)
  }

  get isDocument() {
    return [
      CATEGORY.Document,
      CATEGORY.DocumentCaseStudy,
      CATEGORY.DocumentTeamBio,
      CATEGORY.DocumentReusableBlock,
      CATEGORY.DocumentPackagedService,
      CATEGORY.DocumentPage,
    ].includes(this.category)
  }

  get isFile() {
    return [
      CATEGORY.File,
      CATEGORY.FileLink,
    ].includes(this.category)
  }

  get isOrganizationLevel() {
    return isOrganizationLevel(this.category);
  }

  get isProjectLevel() {
    return isProjectLevel(this.category);
  }

  /**
   * Returns the name (`title`) of a document which is to be used in our custom file system.
   * e.g.: `ProjectRootFolder`'s title is 'Root', but we would like to show 'Docs & Files' to users
   */
  // get fsName() {
  //   const parentProject = BaseModel.models.api.Project?.getFromStore(this.project)

  //   if (this.category === CATEGORY.ProjectRootFolder) {
  //     // Return custom section title from project or the default 'Docs & Files'
  //     return parentProject?.titleDocumentsOrDefault ?? 'n/a'
  //   }

  //   return this.title
  // }
}

export default makeServicePlugin({
  servicePath,
  Model: Document,
  service: documentsService,
  extend: documentsStoreEx,
})
