
<div
  class="
    grid gap-24 grid-cols-1 sm:grid-cols-2
    xl:grid-cols-3 2xl:grid-cols-4 auto-cols-min auto-rows-min w-full
  "
>
  <ProjectTemplateListItem
    v-for="template in templates"
    :key="template._id"
    :template="template"
    @preview="$emit('preview', template)"
    @create="$emit('create', template)"
  />
</div>
