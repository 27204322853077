
<div
  v-b-tooltip.hover.top.v-info.dh0.ds200="tooltip"
  :class="[
    'inline-flex items-center rounded',
    'font-normal whitespace-nowrap leading-none',
    'h-24 px-6 text-12',
    countMap[variant].theme,
  ]"
  @click.stop="$emit('count-clicked')"
>
  <span class="inline-block mr-4 text-12 opacity-75" :class="countMap[variant].icon" />
  <span>{{ count }}</span>
</div>
