
<div
  class="relative w-full max-w-510 h-88 flex-1 flex-grow
    p-0 m-0 flex flex-col items-center justify-center"
  @click.stop=""
>
  <audio
    controls
    class="w-full"
  >
    <source :src="file.url" />
  </audio>
</div>
