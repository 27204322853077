import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import taskSectionsService, { servicePath } from './taskSectionsService'

class TaskSection extends BaseModel {
  static modelName = 'TaskSection'

  static instanceDefaults() {
    return {
      title: '',
      showOnProjectDashboard: false,
      tasks: [],
    }
  }
}

export default makeServicePlugin({
  Model: TaskSection,
  service: taskSectionsService,
  servicePath,
})
