
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isBlankState"
  :config-override="{
    stylingToolbar: {
      features: {
        fontFamily: false,
        textColor: false,
      },
    },
    styling: {
      size: 'M',
    },
  }"
>
  <!-- objectAlign: false, -->
  <template #default="{ styling, textVariant }">
    <DocumentContentBlockEmbed
      :content-block="contentBlock"
      :node-id="node._id"
      :styling="styling"
      :text-variant="textVariant"
      @update="(data, options) => $emit('update', data, options)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot
      name="editor"
      v-bind="{ config, styling, textVariant }"
    />
  </template>
</DocumentNodeRenderer>
