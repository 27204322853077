import { ref } from '@vue/composition-api'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useMsgBoxConfirm } from '@/v2/lib/composition/useMsgBox'
// import { useFeatureLimit } from '@/v2/services/organizations/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { ORGANIZATION_HOME } from '@/router/organization/type'
import { PROJECT_DASHBOARD } from '@/router/project/type'

export default function useArchiveProject() {
  const error = ref(null)
  const isPending = ref(false)

  const { track } = useTracking()
  const msgBoxConfirm = useMsgBoxConfirm()
  const { routerPush } = useRouter()

  // const { checkFeatureLimit } = useFeatureLimit()

  // -- ARCHIVE
  const archive = async project => {
    if (isPending.value) {
      return
    }

    const ok = await msgBoxConfirm({
      title: `Archive ${project.name}`,
      message: 'Are you sure you want to archive this project? '
        + 'Please note that archiving the project will not restrict client access to it via the client portal. '
        + 'Additionally, be aware that an archived project still counts towards the number of projects allowed by your plan.',
      size: 'md',
    })

    if (!ok) {
      return
    }

    track('Project Archived')

    isPending.value = true
    try {
      await project.patch({ data: { archived: true } })
      routerPush({ name: ORGANIZATION_HOME })
    } catch (err) {
      error.value = err.message
      throw err
    } finally {
      isPending.value = false
    }
  }

  // -- UNARCHIVE
  const unarchive = async project => {
    if (isPending.value/*  || checkFeatureLimit('projects', 'limitProjects') */) {
      return
    }

    const ok = await msgBoxConfirm({
      title: `Unarchive ${project.name}`,
      message: 'Are you sure you want to unarchive this project?',
      size: 'md',
    })

    if (!ok) {
      return
    }

    track('Project Unarchived')

    isPending.value = true
    try {
      await project.patch({ data: { archived: false } })
      routerPush({
        name: PROJECT_DASHBOARD,
        params: {
          projectId: project._id,
        },
      })
    } catch (err) {
      error.value = err.message
      throw err
    } finally {
      isPending.value = false
    }
  }

  return {
    archive,
    unarchive,
    error,
    isPending,
  }
}
