
<div
  v-b-hover="handleHover"
  class="fs-card-wrapper"
  :class="[
    size,
    isImageFile && 'is-image',
  ]"
  @click="event => $emit('select', event)"
>
  <div class="">
    <!-- IF IMAGE -->
    <div v-if="isImageFile" role="link">
      <template v-if="document">
        <div
          class="fs-card-body fs-card--doc is-file"
        >
          <div
            class="flex flex-col items-center justify-center h-full"
          >
            <div class="max-h-full">
              <img
                :src="document.file.url"
                class="fs-card--doc-image"
              />

              <div class="fs-card-status-badge">
              </div>
              <div class="h-px w-full relative">
                <div
                  v-if="document.sharingClientEnabled && !isClientPortal"
                  class="fs-card-status-wrapper"
                >
                  <span
                    :class="[
                      'icon_v2-so_shared fs-card-status-icon'
                    ]"
                    :style="{ color: 'var(--cp-accent)'}"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      v-else
      class="fs-card-body fs-card--doc is-file"
      role="link"
    >
      <div class="relative fs-card--file-body">
        <template v-if="document">
          <div class="flex flex-col items-center justify-center">
            <!-- ICON -->
            <FileIcon
              v-if="isFile"
              :content-type="contentType"
              :size="size==='l' ? '56' : '24'"
            />
            <span
              v-else
              :class="[
                document.icon,
                size==='l' ? 'w-56 text-56' : 'w-24 text-24']
              "
              :style="{ color: document.color || theme.textColor}"
            ></span>
            <div
              v-if="document.sharingClientEnabled && !isClientPortal"
              class="absolute flex items-center justify-center rounded-lg bottom-0 right-4"
            >
              <span
                :class="[
                  'icon_v2-so_shared fs-card-status-icon'
                ]"
                :style="{ color: 'var(--cp-accent)'}"
              ></span>
            </div>
          </div>
          <!-- {{ document }} -->
        </template>
      </div>
    </div>

    <!-- IF NOT IMAGE -->
    <!-- MORE INFO -->
    <div
      v-if="totalActiveActionItems > 0 || showCommentsCount > 0 || showCommentsCount > 0"
      class="absolute flex items-center justify-center flex-none divide-x divide-gray-300 rounded-md shadow -bottom-16 left-8 bg-gray/70 dark:bg-darkGray-1000 outline outline-1 outline-gray-300 dark:outline-darkGray-700 filter backdrop-blur-sm "
      :class="isHovered ? 'visible' : 'invisible'"
    >
      <!-- Action Items Count -->
      <div
        v-if="totalActiveActionItems > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Action items'"
        class="inline-flex items-center px-4 px-8 py-4 text-gray-500 rounded text-13 dark:text-darkGray-300"
      >
        <span class="w-16 mr-4 icon_v2-so_action-items text-16"></span>
        {{ totalActiveActionItems }}
      </div>
      <!-- Comments Count -->
      <div
        v-if="showCommentsCount > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Comments'"
        class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13 dark:text-darkGray-300"
      >
        <span class="w-16 mr-4 icon_v2-so_chat text-16"></span> {{ showCommentsCount }}
      </div>
      <!-- Comments Count -->
      <div
        v-if="document.viewCount > 0"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Views'"
        class="inline-flex items-center px-8 py-4 text-gray-500 px-4rounded text-13 dark:text-darkGray-300"
      >
        <span class="w-16 mr-4 icon-util-view text-16"></span> {{ document.viewCount }}
      </div>
    </div>
  </div>

  <div class="fs-card-title-card">
    <DocumentTitleEditable
      :size="size"
      :document="document"
      :disabled="readOnly"
    />
    <DocumentStatusBadge
      :status="document.status"
      display-as="small"
      only-approved
    />
  </div>
</div>
