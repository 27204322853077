
<FileUploader
  v-slot="{ selectFiles, isDragging }"
  data-intercom-target="Attachments Block"
  multiple
  block
  inline-block
  @upload-start="onUploadStart"
  @upload-done="onUploadDone"
  @file-progress="onFileProgress"
  @file-done="onFileDone"
  @file-error="onFileError"
>
  <!-- If we have files -->
  <div
    :class="[
      ['XXS','XS','S','M'].includes(nodeWidth) && 'sm:px-24',
      'portal-paragraph-font',
    ]"
    @contextmenu.stop
  >
    <LightboxGallery
      :files="filesMedia"
      :index="index"
      @close="index = null"
    />

    <!-- Audio -->
    <section class="attach-images-wrap">
      <div
        v-for="(file, fileIndex) in filesMedia"
        :key="file.uuid"
        :class="['attach-item-wrap', file.local && 'uploading-image']"
      >
        <DocumentContentBlockAttachmentsMedia
          v-if="!file.local"
          :file="file"
          :read-only="!isEditable"
          @remove="remove(file)"
          @click-thumbnail="index = fileIndex"
        />
      </div>
    </section>
    <section class="mb-16 attach-files-wrap">
      <div
        v-for="fileNonImage in filesNonMedia"
        :key="fileNonImage.uuid"
        :class="['attach-item-wrap', fileNonImage.local && 'uploading-file']"
      >
        <DocumentContentBlockAttachmentsFile
          v-if="!fileNonImage.local"
          :file="fileNonImage"
          :read-only="!isEditable"
          @remove="remove(fileNonImage)"
        />
      </div>
    </section>
  </div>

  <div
    v-if="isEditable"
    :class="[ 'attach-empty','portal-paragraph-font']"
  >
    <!-- ------------- -->
    <!-- Error message -->
    <!-- ------------- -->
    <div v-if="filesErrors.length" class="attach-note">
      <h4 class="portal-paragraph-font">
        <span class="w-16 mr-4 text-16 icon-alert"></span>
        <span> The files below failed to upload. Please try again. </span>
      </h4>
      <div v-for="fileError in filesErrors" :key="fileError.uuid">
        <strong>{{ fileError.name }}</strong>
        {{ fileError.local.error | error }}
      </div>
    </div>
    <!-- ------------ -->
    <!-- IS UPLOADING -->
    <!-- ------------ -->
    <section v-if="isUploading || uploadedBatch.files.length > 0">
      <portal to="right-drawer">
        <PendingUploads :pending-uploads="uploadedBatch.files" @close="onPendingUploadsDone" />
      </portal>
    </section>

    <!-- ------------- -->
    <!-- UPLOAD BUTTON -->
    <!-- ------------- -->
    <div class="flex flex-row justify-center print:hidden">
      <b-button
        variant="primary"
        :disabled="isUploading"
        @click="selectFiles"
      >
        <span class="w-20 mr-6 icon-util-upload text-20" />
        Select files
      </b-button>
    </div>
  </div>
  <!-- Drop overlay -->
  <div
    v-if="isDragging"
    :class="[
      'attach-empty attach-hovered',
      'absolute inset-0 pointer-events-none z-1',
      'flex items-center justify-center flex-col',
      'portal-paragraph-font'
    ]"
  >
    <span class="font-semibold text-green-900 opacity-75 text-42">Drop your files here</span>
  </div>
</FileUploader>
