
<div>
  <div class="relative mb-10">
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="middle"
    />
    <Slider1D
      :disabled="!isAnswerable"
      :value="contentBlock.answer"
      :ping="!Boolean(contentBlock.isDirty)"
      @input="answer => $emit('update', { answer, isDirty: true })"
      @reset="$emit('reset')"
    />
  </div>
  <div
    class="w-full grow-1 flex flex-row justify-between items-start -mt-20 portal-paragraph-font"
  >
    <div
      v-if="leftLabel"
      class="max-w-96 md:max-w-128 text-left"
    >
      <span class="slider1d-label">{{ leftLabel }}</span>
    </div>
    <div
      v-if="rightLabel"
      class="max-w-96 md:max-w-128 text-right"
    >
      <span class="slider1d-label">{{ rightLabel }}</span>
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
