
<div class="relative">
  <b-input
    :value="value"
    :placeholder="placeholder"
    :type="hidePassword ? 'password' : 'text'"
    :autocomplete="hidePassword ? autocomplete : null"
    class="pr-32 form-control-lg"
    :class="inputClass"
    :autofocus="autofocus"
    @input="val => $emit('input', val)"
  />
  <div class="absolute bottom-0 flex right-2 top-4">
    <ButtonTransparent
      class="items-center justify-center w-32 h-32 flex-inline"
      @click.stop="hidePassword = !hidePassword"
    >
      <span
        class="absolute z-10 text-gray-500 hover:text-gray-600"
        :class="{
          'icon_v2-so_eye': !hidePassword,
          'icon_v2-so_eye-disabled': hidePassword
        }"
      />
    </ButtonTransparent>
  </div>
</div>
