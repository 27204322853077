<template>
  <div class="inline-flex items-center h-32 space-x-8 text-14">
    <div
      v-if="isMobile"
      class="flex items-center text-gray-500 md:ml-24 dark:text-darkGray-400"
    >
      <span class="w-20 mr-6 icon-arrow-left-util text-20"></span>
      <DocumentLinkTitle
        :document="document"
        class="
          text-gray-500 dark:text-darkGray-400
          hover:underline hover:text-[color:var(--cp-accent)]
        "
      />
    </div>
    <div v-else class="flex items-center">
      <router-link
        v-if="!isPortal"
        :to="{ name: ORGANIZATION_LIBRARY }"
        class="
          text-gray-500 dark:text-darkGray-400
          hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1
        "
      >
        Library
      </router-link>
      <div class="ml-22 so-breadcrumb-item flex items-center">
        <span
          class="
            so-breadcrumb-arrow w-12 mr-4 -ml-16
          text-gray-400 dark:text-darkGray-600
            text-12 icon-arrow-right
          "
        />
        <router-link
          v-if="!isPortal"
          :to="{ name: info.routeName }"
          class="
          text-gray-500 dark:text-darkGray-400
            hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1
          "
        >
          {{ info.label }}
        </router-link>
        <div v-else class="text-gray-500 dark:text-darkGray-400 line-clamp-1">
          {{ info.label }}
        </div>
      </div>
      <div class="ml-22 so-breadcrumb-item flex items-center">
        <span
          class="
            so-breadcrumb-arrow w-12 mr-4 -ml-16
          text-gray-400 dark:text-darkGray-600
            text-12 icon-arrow-right
          "
        />
        <DocumentComputedData
          v-slot="{ title }"
          :document="document"
        >
          <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
            <WrappedTitle
              :text="title"
              :pre-size="34"
            />
          </p>
        </DocumentComputedData>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import {
  ORGANIZATION_LIBRARY,
  ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
  ORGANIZATION_LIBRARY_TEAM_BIOS,
  ORGANIZATION_LIBRARY_CASE_STUDIES,
  ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
  ORGANIZATION_LIBRARY_PAGES,
} from '@/router/organization/type'
import DocumentLinkTitle from '@/components/DocumentLinkTitle.vue'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import WrappedTitle from '@/components/WrappedTitle.vue'

const categoryMap = {
  [CATEGORY.DocumentReusableBlock]: {
    label: 'Reusable Blocks',
    routeName: ORGANIZATION_LIBRARY_REUSABLE_BLOCKS,
  },
  [CATEGORY.DocumentTeamBio]: {
    label: 'Team Bios',
    routeName: ORGANIZATION_LIBRARY_TEAM_BIOS,
  },
  [CATEGORY.DocumentCaseStudy]: {
    label: 'Case Studies',
    routeName: ORGANIZATION_LIBRARY_CASE_STUDIES,
  },
  [CATEGORY.DocumentPackagedService]: {
    label: 'Packaged Services',
    routeName: ORGANIZATION_LIBRARY_PACKAGED_SERVICES,
  },
  [CATEGORY.DocumentPage]: {
    label: 'Pages',
    routeName: ORGANIZATION_LIBRARY_PAGES,
  },
}

export default defineComponent({
  name: 'DocLibraryBreadcrumbs',
  components: {
    DocumentLinkTitle,
    DocumentComputedData,
    WrappedTitle,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    isPortal: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const isMobile = computed(() => context.root.$mq === 'sm')
    const info = computed(() => categoryMap[props.category])

    return {
      isMobile,
      info,
      ORGANIZATION_LIBRARY,
    }
  },
})
</script>


<style lang="postcss" scoped>
.so-breadcrumb-item:first-child .so-breadcrumb-arrow {
  @apply hidden;
}

.so-breadcrumb-item:first-child {
  @apply ml-0;
}

.so-breadcrumb-item:last-child {
  @apply font-bold;
}
</style>
