<template>
  <div>
    <div class="relative mb-10">
      <AnswerBadge
        v-if="['XL', 'XXL'].includes(nodeWidth)"
        :is-answerable="isAnswerable"
        :has-no-answer="!contentBlock.isDirty"
        position="middle"
      />
      <Slider1D
        :disabled="!isAnswerable"
        :value="contentBlock.answer"
        :ping="!Boolean(contentBlock.isDirty)"
        @input="answer => $emit('update', { answer, isDirty: true })"
        @reset="$emit('reset')"
      />
    </div>
    <div
      class="w-full grow-1 flex flex-row justify-between items-start -mt-20 portal-paragraph-font"
    >
      <div
        v-if="leftLabel"
        class="max-w-96 md:max-w-128 text-left"
      >
        <span class="slider1d-label">{{ leftLabel }}</span>
      </div>
      <div
        v-if="rightLabel"
        class="max-w-96 md:max-w-128 text-right"
      >
        <span class="slider1d-label">{{ rightLabel }}</span>
      </div>
    </div>
    <Notes
      v-if="contentBlock.settings.hasNotes"
      :is-answerable="isAnswerable"
      :text-variant="textVariant"
      :value="contentBlock.notes"
      @input="data => $emit('update', data)"
    />
  </div>
</template>
<script>
import { inject, computed, reactive } from '@vue/composition-api'
import Slider1D from '@/components/Slider1D.vue'
import Notes from '@/components/Notes.vue'
import AnswerBadge from '@/components/Document/AnswerBadge.vue'

export default {
  name: 'DocumentContentBlockQASlider',
  components: { Slider1D, Notes, AnswerBadge },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isAnswerable: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const isLocked = inject('isLocked')
    const leftLabel = computed(() => props.contentBlock.settings.leftLabel)
    const rightLabel = computed(() => props.contentBlock.settings.rightLabel)
    const sliderLabel = computed(() => props.contentBlock.settings.sliderLabel)
    const hasSliderLabel = computed(
      () => props.contentBlock.settings.hasSliderLabel
    )
    const state = reactive({
      answer: props.contentBlock.answer,
    })

    const updateAnswer = () => context.emit('update', { answer: state.answer, isDirty: true })

    return {
      isLocked,
      leftLabel,
      rightLabel,
      state,
      updateAnswer,
      hasSliderLabel,
      sliderLabel,
    }
  },
}
</script>

<style lang="postcss" scoped>
.slider1d-label {
  @apply bg-yellow-400 text-gray-900 text-16 font-semibold;
  display: inline;
  padding: 0.18rem 0.28rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
</style>
