
<div v-fragment>
  <!-- ------------------- -->
  <!-- WELCOME MESSAGE -->
  <!-- ------------------- -->
  <p
    class="
      text-center text-gray-500
    dark:text-white dark:opacity-70 text-14
      portal-paragraph-font font-normal mb-40
    "
  >
    {{ portalCustomizations.welcomeMessage }}
  </p>
  <FormMessage v-if="formError">
    {{ formError.message }}
  </FormMessage>
  <FormMessage v-if="success" variant="success">
    {{ $t('login.magic-link-sent') }} <strong>{{ formState.email }}</strong>
  </FormMessage>
  <b-form v-else @submit.prevent="formSubmit">
    <FormField
      :v="$v.email"
      :label="$t('login.email')"
      :messages="{
        required: $t('login.email-required'),
        email: $t('login.email-not-valid'),
      }"
      wrapper-class="portal-paragraph-font"
    >
      <b-input
        autofocus
        size="lg"
        autocomplete="username"
        placeholder="example@domain.com"
        class="form-control-lg portal-paragraph-font"
        :value="formState.email"
        @input="onEmailInput"
      />
    </FormField>

    <Button
      class="mt-16 portal-paragraph-font portal-button"
      type="submit"
      size="lg"
      variant="primary"
      :block="true"
      :is-loading="formIsBusy"
    >
      {{ portalCustomizations.loginButtonLabel || $t('login.magic-link-login') }}
    </Button>
  </b-form>
</div>
