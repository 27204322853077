
<b-form @submit.prevent="submit">
  <FormMessage v-if="error">
    {{ error.message }}
  </FormMessage>
  <slot
    v-bind="{
      state: formState,
      v: $v,
      isBusy,
      isInvalid,
      isDirty,
      isValidationPending,
      error,
      submit,
      reset,
      setError,
      clearError,
      hasChanges,
    }"
  />
</b-form>
