
<div v-fragment>
  <ImageSearchUnsplashModal
    v-if="isUnsplashOpen"
    v-model="isUnsplashOpen"
    @select="onUnsplashImageSelect"
  />
  <FileUploader
    v-slot="{ selectFiles }"
    data-intercom-target="Image Block"
    accept="image/*"
    :multiple="false"
    custom-class="flex-col"
    @upload-start="onUploadStart"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <!-- If not empty -->
    <div
      v-if="!isBlankState || fileUploadState.isUploading"
      :class="[
        'block-image-body',
        'w-full',
        'items-center',
        {
          'justify-start': styling.objectAlign === 'left',
          'justify-center': styling.objectAlign === 'center',
          'justify-end': styling.objectAlign === 'right',
        },
        { uploading: fileUploadState.isUploading },
      ]"
    >
      <!-- Show image resize and Change Image button if in edit mode -->
      <template v-if="isEditable">
        <ObjectResize
          :width="isObjectAlignStretch ? null : contentBlock.width"
          :disabled="fileUploadState.isUploading"
          :show-selection="isHover"
          @change="onImageResize"
          @resize-start="onImageResizeStart"
          @resize-end="onImageResizeEnd"
        >
          <img
            :src="fileUrl"
            class="w-full block h-auto"
            @contextmenu.stop
          />
        </ObjectResize>
      </template>

      <!-- Else, show just the image -->
      <b-img
        v-else
        :src="fileUrl"
        :style="imageStyle"
        class="w-full block h-auto"
      />
    </div>

    <!-- Blank state placeholder -->
    <div
      v-if="isEditable && isBlankState && !hasBackgroundImage && !fileUploadState.isUploading"
      class="w-full py-88 relative empty-bg"
    >
      <div
        class="flex flex-col items-center justify-end"
      >
        <p
          :class="[
            'inline-flex items-center text-18 font-medium text-gray-600',
            $style['description']
          ]"
        >
          <span>Upload an image </span>
          <b-button
            v-b-tooltip.hover.bottom.v-info.dh0.ds200
            title="Upload from your device"
            variant="primary"
            :disabled="fileUploadState.isUploading"
            class="py-5 px-10 mx-8"
            @click="selectFiles"
          >
            <span class="w-20 icon-util-upload text-20 mr-4" />
            <span>Select</span>
          </b-button>
          <span>or pick one from</span>
          <b-button
            v-b-tooltip.hover.bottom.v-info
            title="Use an image from Unsplash"
            variant="black"
            :disabled="fileUploadState.isUploading"
            class="mx-8 py-5 px-10"
            @click="openUnsplash"
          >
            <span class="w-16 mr-4 icon-logo-unsplash text-16" />
            <span>Unsplash</span>
          </b-button>
        </p>
      </div>
    </div>
    <div
      v-if="isBlankState && hasBackgroundImage && !fileUploadState.isUploading"
      class="w-full py-64"
    />

    <!-- toolbar buttons -->
    <DocumentContentBlockImageToolbarPortal
      :node-id="nodeId"
      :is-blank-state="isBlankState"
      :file-upload-state="fileUploadState"
      @select-files="selectFiles"
      @delete-file="deleteFile"
      @open-unsplash="openUnsplash"
    />

    <!-- Animation if loading -->
    <div
      v-if="fileUploadState.isUploading"
      class="block-image-progress"
    >
      <PieProgressBar :ratio="fileUploadState.progress" />
    </div>

    <!-- Error Message -->
    <div
      v-if="fileUploadState.error"
      class="block-image-error"
    >
      {{ fileUploadState.error || error }}
    </div>
  </FileUploader>
</div>
