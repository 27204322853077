<template>
  <div>
    <div>
      <h1
        class="
          mb-16 font-semibold leading-tight
          text-center text-gray-800
          text-18 md:text-24 md:leading-normal
          dark:text-white dark:opacity-95
          portal-heading-font
        "
      >
        <template v-if="portalCustomizations.heading">
          {{ portalCustomizations.heading }}
        </template>
        <!-- No custom heading. Revert to default -->
        <template v-else>
          {{ $t('login.signin-to', { organization: organization.name }) }} <br />
        </template>
      </h1>
    </div>
    <b-tabs
      v-if="loginOptions.length > 1"
      v-model="selectedLoginOptionIndex"
      class="mb-32"
      pills
      fill
    >
      <b-tab
        v-for="loginOption in loginOptions"
        :key="loginOption.id"
        :title="loginOption.name"
        :title-link-class="`${$style['tab-button']} portal-paragraph-font dark:text-white`"
        no-body
      />
    </b-tabs>

    <ClientPortalLoginMagicLink
      v-if="selectedLoginOption?.id === 'magicLink' "
      :organization="organization"
      :domain="domain"
      :portal-customizations="portalCustomizations"
    />
    <ClientPortalLoginPassword
      v-if="selectedLoginOption?.id === 'password'"
      :organization="organization"
      :domain="domain"
      :portal-customizations="portalCustomizations"
      @recover-password="
        $router.push({ name: PORTAL_RECOVER_PASSWORD_REQUEST })
      "
    />
    <!-- Custom Rich Text (HTML) content -->
    <div
      v-if="portalCustomizations.customContent"
      id="custom-rich-text-content"
      class="
            editor-content editor-content-preview editor-content-branded
            dark:text-white dark:opacity-70 mt-40
            portal-paragraph-font
          "
      v-html="portalCustomizations.customContent"
    >
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, watch, ref } from '@vue/composition-api'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import { PORTAL_RECOVER_PASSWORD_REQUEST } from '@/client-portal/router/types'
import ClientPortalLoginMagicLink from '@/components/ClientPortalLoginMagicLink.vue'
import ClientPortalLoginPassword from '@/components/ClientPortalLoginPassword.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'

export default defineComponent({
  name: 'ViewClientPortalLogin',
  components: {
    ClientPortalLoginMagicLink,
    ClientPortalLoginPassword,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
    /** Used to overwrite any portal customization set on the `organization` prop. */
    pageCustomization: {
      type: Object,
      required: false,
      default: null,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { translate } = useLocalization()
    const organizationName = computed(() => props.organization?.name)
    const hasWhiteLabel = computed(() => props.organization?.featureFlags?.fullWhiteLabel)

    const portalCustomizations = computed(() => props.pageCustomization
      ?? props.organization.portalLoginCustomizations)

    const selectedLoginOptionIndex = ref(0)

    const loginOptions = computed(() => [
      {
        id: 'magicLink',
        name: translate('login.magic-link').value,
        enabled: props.organization.portalLoginMagicLinkEnabled,
      },
      {
        id: 'password',
        name: translate('login.password').value,
        enabled: props.organization.portalLoginPasswordEnabled,
      },
    ].filter(option => option.enabled))

    const selectedLoginOption = computed(() => loginOptions.value[selectedLoginOptionIndex.value])

    // Set page title to organization name, if it has `fullWhiteLabel`
    watch(
      hasWhiteLabel,
      newValue => {
        if (newValue && !props.preview) {
          useBrowserTitle(organizationName)
        }
      },
      { immediate: true }
    )

    return {
      portalCustomizations,
      loginOptions,
      selectedLoginOptionIndex,
      selectedLoginOption,
      PORTAL_RECOVER_PASSWORD_REQUEST,
    }
  },
})
</script>

<style lang="postcss" module>
.tab-button[aria-selected="true"] {
  /* background-color: var(--cp-button-color) !important; */

  border: 1px solid var(--cp-button-color);
  /* border-radius: unset; */
  color: var(--cp-button-color) !important;
  background-color: transparent !important;
}

.tab-button:not([aria-selected="true"]):hover {
  color: var(--cp-button-color) !important;
}
</style>
