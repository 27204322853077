
<div
  :class="[
    'block-element-cost',
    ['XXS','XS','S','M'].includes(nodeWidth)
      ? 'md:px-24'
      : '',]"
  data-intercom-target="Pricing Table Block"
>
  <!-- ------------------------------ -->
  <!-- TABLE HEADER ----------------- -->
  <!-- ------------------------------ -->
  <div
    :class="[
      'w-full mb-0 table',
      !isEditable && 'is-preview'
    ]"
  >
    <!-- Table Header -->
    <div class="hidden xl:table-header-group portal-paragraph-font print:table-header-group">
      <div
        class="
          table-row w-full font-semibold table-row-header
        "
      >
        <div
          class="table-cell-header name"
          :style="{
            color: styling.textColor,
          }"
        >
          {{ $t('blocks.cost.name') }}
        </div>
        <div
          class="table-cell-header qty"
          :style="{
            color: styling.textColor,
          }"
        >
          {{ $t('blocks.cost.quantity') }}
        </div>
        <div
          class="capitalize table-cell-header rate"
          :style="{
            color: styling.textColor,
          }"
        >
          {{ $tc('blocks.cost.item',1) }}
        </div>
        <div
          class="text-right table-cell-header cost"
          :style="{
            color: styling.textColor,
          }"
        >
          {{ $t('blocks.cost.cost') }}
        </div>
        <div v-if="isEditable" class="table-cell-header actions"></div>
      </div>
    </div>
    <!-- ------------------------------ -->
    <!-- ITEMS ------------------------ -->
    <!-- ------------------------------ -->

    <!-- Edit mode -->
    <DocumentContentBlockCostEditor
      v-if="isEditable"
      :currency="document.currency"
      :value="contentBlock.billables"
      :autofocus="isFocus"
      :text-color="textColor"
      :styling="styling"
      :organization-id="document.organization"
      @input="val => $emit('update', { billables: val })"
    />

    <!-- Ready Only Mode -->
    <div
      v-else
      class="table-row-group portal-paragraph-font"
      :class="[
        !isEditable && 'is-preview',
      ]"
    >
      <DocumentContentBlockCostItem
        v-for="billable in contentBlock.billables"
        :key="billable.id"
        :billable="billable"
        :document-id="document._id"
        :node-width="nodeWidth"
      />
    </div>
  </div>


  <!-- ------------------------------ -->
  <!-- TOTAL ------------------------ -->
  <!-- ------------------------------ -->
  <div
    :class="['mt-16', isEditable && 'px-8']"
  >
    <TableTotal
      :total="cost"
      :document-id="document._id"
      :style="{
        color: styling.textColor,
      }"
    />
  </div>
</div>
