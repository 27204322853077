<script>
import { indexBy, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { computed } from '@vue/composition-api'
import { useGet, useFind } from 'feathers-vuex'
import useFindByIds from '@/v2/lib/composition/useFindByIds'

export default {
  name: 'FolderContentLoader',
  props: {
    folder: {
      type: Object,
      required: true,
    },
    hiddenChildrenIds: {
      type: Array,
      default: () => [],
    },
    sortField: {
      type: String,
      default: null,
    },
    sortDirection: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    const { DocumentStructure, DocumentNode } = context.root.$FeathersVuex.api

    // -- structure
    const { item: structure, isPending: isStructurePending } = useGet({
      id: computed(() => props.folder.structure),
      model: DocumentStructure,
    })

    const rootNodes = computed(() => structure.value?.tree.root ?? null);

    // -- nodes
    const { items: nodes, isPending: isNodesPending } = useFind({
      model: DocumentNode,
      params: computed(() => {
        if (!rootNodes.value) {
          return null;
        }

        return {
          query: {
            _id: {
              $in: rootNodes.value,
            },
          },
        }
      }),
    })

    const nodesIndexed = computed(() => new Map(nodes.value.map(n => [n._id, n])));

    const documents = computed(
      () => (rootNodes.value ?? [])
        .map(nodeId => nodesIndexed.value.get(nodeId)?.contentBlock$.embeddedDocument$ ?? null)
        .filter(d => isNotNil(d) && !props.hiddenChildrenIds.includes(d._id))
    )

    const sortedDocuments = computed(() => {
      if (!props.sortField) {
        return documents.value
      }

      return [...documents.value].sort((docA, docB) => {
        let a = docA[props.sortField] ?? ''
        let b = docB[props.sortField] ?? ''

        // desc
        if (props.sortDirection === -1) {
          [a, b] = [b, a];
        }

        return a.localeCompare(b)
      })
    });

    const isLoading = computed(() => isStructurePending.value || isNodesPending.value)

    return () => context.slots.default({
      isLoading: isLoading.value,
      items: sortedDocuments.value,
    })
  },
}
</script>
