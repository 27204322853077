<template>
  <DocumentNodeEditorToolbarSettings
    :popover-target-id="popoverTargetId"
    :prevent-popover-close="preventPopoverClose"
  >
    <b-form>
      <SettingsSlider
        v-model="height"
        :min="8"
        :max="512"
        :step="16"
        label="Height"
        tooltip="Set height in pixels"
      />
    </b-form>
  </DocumentNodeEditorToolbarSettings>
</template>

<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import DocumentNodeEditorToolbarSettings from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSettings.vue'
import SettingsSlider from '@/components/SettingsSlider.vue'

export default {
  name: 'DocumentContentBlockSpacerSettings',
  components: {
    DocumentNodeEditorToolbarSettings,
    SettingsSlider,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    preventPopoverClose: {
      type: Boolean,
      default: false,
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { height } = useContentBlockSettings(props, [
      'height',
    ])
    return {
      height,
    }
  },

}
</script>
