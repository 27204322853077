import Vue from 'vue'
import { isNonEmptyString } from 'ramda-adjunct'
import { computed } from '@vue/composition-api'
import { useGet } from 'feathers-vuex'
import { switchCond } from '@/v2/lib/helpers/fp'
import { interpolate } from '@/v2/lib/helpers/interpolate'
import parseHost from './parseHost'
import { DOMAIN_STATUS } from '@/v2/services/organizations/organizationsTypes'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/** @typedef {import('feathers-vuex').Model} Model */

export default function useDomain({ fetch = true } = {}) {
  const { Domain } = Vue.$FeathersVuex.api

  /** @type {'replace' | 'append' | 'disabled'} */
  const subdomainMode = import.meta.env.VITE_SUBDOMAIN_MODE

  // parse configured app url from .env
  const appUrl = new URL(import.meta.env.VITE_URL)
  const { subdomain: appSubdomain, rootDomain: appRootDomain } = parseHost(appUrl.hostname)

  // parse current browser url
  const urlHostname = window.location.hostname
  const { subdomain: urlSubdomain, rootDomain: urlRootDomain } = parseHost(urlHostname)

  const domainId = switchCond([
    [urlRootDomain !== appRootDomain, urlHostname], // if custom domain
    [urlSubdomain !== appSubdomain, urlSubdomain], // if custom subdomain
    [true, null], // otherwise null to skip useGet API request
  ])

  const queryWhen = computed(
    () => fetch
      && subdomainMode !== 'disabled'
      && !Domain.getFromStore(domainId)
  )

  const { item, isPending, error } = useGet({
    model: Domain,
    id: domainId,
    params: { supressErrorMessage: true },
    queryWhen,
  })

  /**
   * Appends a path to organization custom url (subdomain or custom domain)
   * @param {RefModel} organization Organization model ref
   * @param {string} path Relative path to append
   * @returns {RefString} Absolute url
   */
  const withOrganizationUrl = (organization, path) => computed(() => {
    const { subdomain, domain, domainStatus } = organization.value ?? {}
    const port = appUrl.port ? `:${appUrl.port}` : ''

    return switchCond([
      [
        subdomainMode === 'disabled',
        `${appUrl}${path}`,
      ],
      [
        isPending.value || error.value,
        null,
      ],
      [
        (domain && domainStatus === DOMAIN_STATUS.active),
        `${appUrl.protocol}//${domain}${port}/${path}`,
      ],
      [
        subdomain && subdomainMode === 'append',
        `${appUrl.protocol}//${subdomain}.${appUrl.host}${port}/${path}`,
      ],
      [
        subdomain && subdomainMode === 'replace',
        `${appUrl.protocol}//${appUrl.host.replace(appSubdomain, subdomain)}/${path}`,
      ],

      // default
      [
        true,
        `${appUrl}${path}`,
      ],
    ])
  })

  // get configured client portal url from .env
  const portalDomainUrlTpl = interpolate(import.meta.env.VITE_PORTAL_DOMAIN_URL)
  const portalSubdomainUrlTpl = interpolate(import.meta.env.VITE_PORTAL_SUBDOMAIN_URL)

  /**
    * Appends a path to portal custom url (subdomain or custom domain)
    * @param {Ref<Model>} organization Organization model ref
    * @param {string} path Relative path to append
    * @returns {Ref<string>} Absolute url
    */
  const withClientPortalUrl = (organization, path) => computed(() => {
    const { subdomain, domain, domainStatus } = organization.value ?? {}
    const hasSubdomain = isNonEmptyString(subdomain);
    const hasDomain = isNonEmptyString(domain) && domainStatus === DOMAIN_STATUS.active;

    if (!hasSubdomain && !hasDomain) {
      return null;
    }

    const portalUrl = hasDomain
      ? portalDomainUrlTpl({ domain })
      : portalSubdomainUrlTpl({ subdomain })

    return `${portalUrl}/${path}`
  })

  return {
    isPending,
    error,
    domain: item,
    withOrganizationUrl,
    withClientPortalUrl,
  }
}
