<template>
  <ClientPortalLoginTheme
    v-slot="{ portalCustomizations }"
    :organization="organization"
    :overrides="pageCustomization"
  >
    <div
      id="loginContainer"
      class="login-background"
      :class="[
        'page-shared portal-paragraph-font',
        portalCustomizations.appearance,
        `page-${portalCustomizations.pageLayout}`,
      ]"
    >
      <!-- Background Image -->
      <div class="login-background-image"></div>
      <!-- FORM -->
      <div
        class="form-shared"
        :class="[
          `form-${portalCustomizations.pageLayout}`,
          portalCustomizations.showFormBackground
            && `form-background-${portalCustomizations.pageLayout}`
        ]"
      >
        <div class="flex flex-col items-center justify-center mb-20">
          <!-- --------------- -->
          <!-- LOGO -->
          <!-- --------------- -->
          <div
            v-if="organization.logoUrl || organization.logoDarkUrl"
            class="
              mb-32 lg:mb-48 w-[196px] lg:w-[228px]
              h-96 relative flex items-center justify-center
            "
          >
            <Logo
              :dark="organization.logoDarkUrl"
              :light="organization.logoUrl"
              :theme="portalCustomizations.appearance"
              align="center"
            />
          </div>
        </div>
        <slot v-if="$scopedSlots.default" />
        <router-view
          v-else
          :organization="organization"
          :domain="domain"
        />
      </div>
      <FeatureFlags
        v-slot="{ portalShowPoweredBySuperOkay }"
        :flags="['portalShowPoweredBySuperOkay']"
      >
        <div class="fixed bottom-8 left-8">
          <PoweredBySuperokay v-if="portalShowPoweredBySuperOkay" />
        </div>
      </FeatureFlags>
    </div>
  </ClientPortalLoginTheme>
</template>
<script>
import { computed, defineComponent, watch, ref } from '@vue/composition-api'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import PoweredBySuperokay from '@/components/PoweredBySuperokay.vue'
import FeatureFlags from '@/components/FeatureFlags.vue'
import Logo from '@/components/Logo.vue'
import ClientPortalLoginTheme from '@/components/ClientPortalLoginTheme.vue'

export default defineComponent({
  name: 'ViewClientPortalLogin',
  components: {
    PoweredBySuperokay,
    FeatureFlags,
    Logo,
    ClientPortalLoginTheme,
  },
  inheritAttrs: false,
  props: {
    organization: {
      type: Object,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
    /** Used to overwrite any portal customization set on the `organization` prop. */
    pageCustomization: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const magicLink = ref(false)
    const organizationName = computed(() => props.organization?.name)
    const hasWhiteLabel = computed(() => props.organization?.featureFlags?.fullWhiteLabel)

    // Set page title to organization name, if it has `fullWhiteLabel`
    watch(
      hasWhiteLabel,
      newValue => newValue && useBrowserTitle(organizationName),
      { immediate: true }
    )

    return {
      magicLink,
    }
  },
})
</script>

<style lang="postcss" scoped>
/* .login-button {
  background-color: var(--cp-button-color);
  color: var(--cp-text-color)
} */

.login-background {
  background-color: var(--cp-background);
  @apply md:absolute md:inset-0 z-0;
}

.login-background-image {
  background-image: var(--cp-background-image-url);
  background-size: cover;
  background-position: center center;
  opacity: var(--cp-background-image-opacity);
  @apply fixed inset-0 z-1;
}

/* ##########  */
/* PAGE CLASSES */
/* ##########  */
.page-shared {
  @apply flex h-screen overflow-x-hidden;
  @apply items-center;
  @apply sm:overflow-y-auto p-12 sm:p-0;
}

.page-floating {
  @apply items-stretch sm:items-center justify-center;
}
.page-right {
  @apply justify-end;
}
.page-left {
  @apply justify-start;
}


.form-shared {
  @apply relative flex flex-col sm:overflow-hidden;
  @apply z-20 rounded-3xl sm:rounded-none;
}

/* ##########  */
/* FLOATING */
/* ##########  */

.form-floating {
  @apply sm:col-span-1;
  @apply p-32 pb-32 pt-48 sm:py-64 sm:p-56;
  @apply flex-1 h-full sm:h-auto;
  @apply max-w-510 self-center ;
  @apply flex flex-col justify-center;
}

.form-background-floating{
  @apply dark:shadow-none dark:border-none;
  @apply bg-white dark:bg-darkGray-900;
  @apply border border-gray-200 dark:border-darkGray-800;
  @apply shadow-medium rounded-3xl;
  @apply backdrop-blur-xl bg-opacity-90 dark:bg-opacity-90;
  @apply sm:dark:bg-opacity-90 sm:bg-opacity-90;
}

/* ##########  */
/* RIGHT */
/* ##########  */
.form-background-right {
  @apply bg-white;
  @apply dark:bg-darkGray-900;
  @apply backdrop-blur-xl bg-opacity-90 dark:bg-opacity-90;
  @apply sm:dark:bg-opacity-90 sm:bg-opacity-90;
}
.form-right {
  @apply h-full justify-center;
  @apply p-32 sm:max-w-510 lg:min-w-[33%] md:p-56;
}

/* ##########  */
/* LEFT */
/* ##########  */
.form-background-left {
  @apply bg-white;
  @apply dark:bg-darkGray-900;
  @apply backdrop-blur-xl bg-opacity-90 dark:bg-opacity-90;
  @apply sm:dark:bg-opacity-90 sm:bg-opacity-90;
}
.form-left {
  @apply h-full justify-center;
  @apply p-32 sm:max-w-510 lg:min-w-[33%] md:p-56;
}
</style>
