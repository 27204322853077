<template>
  <div v-if="document && isVisible">
    <button
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Public link views`"
      :class="[
        'inline-flex items-center transition-all rounded-md h-26',
        'font-semibold whitespace-nowrap leading-none',
        'outline-none hover:outline-none focus:outline-none active:outline-none',
        {
          'px-4 text-gray-700 dark:text-darkGray-400': slim,
          // eslint-disable-next-line max-len
          'text-green-700 dark:text-green-100 dark:bg-opacity-30 bg-green-100 dark:bg-green-600 px-8 hover:bg-green-200 hover:text-green-900': !slim && isSharingLinkEnabled,
          'text-gray-700 bg-gray-100 px-8 hover:bg-gray-200': !slim && !isSharingLinkEnabled
        }
      ]"
      @click="openAnalytics"
    >
      <span v-if="slim" class="inline-block w-16 mr-2 icon-util-analytics text-14" />
      <span
        v-else
        :class="[
          'inline-block w-16 mr-2 text-14',
          isSharingLinkEnabled ? 'icon-util-analytics' : 'icon_v2-so_link-unlink'
        ]"
      />
      <span>{{ analyticsCount }}</span>
      <!-- <span v-if="!slim" class="hidden xl:inline xl:ml-2">
        {{ 'view' | pluralize(analyticsCount) }}
      </span> -->
    </button>
    <DocumentAnalyticsModal
      v-if="modalOpen"
      v-model="modalOpen"
      :document-id="document._id"
      :sharing-enabled="isSharingLinkEnabled"
    />
  </div>
</template>

<script>
import { propEq } from 'ramda'
import { defineComponent, ref, computed } from '@vue/composition-api'
import DocumentAnalyticsModal from '@/components/DocumentAnalyticsModal.vue'

export default defineComponent({
  name: 'DocumentAnalytics',
  components: {
    DocumentAnalyticsModal,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const modalOpen = ref(false)
    const isSharingLinkEnabled = computed(() => propEq('sharingLinkEnabled', true, props.document))
    const isVisible = computed(() => isSharingLinkEnabled.value || props.document.viewCount > 0)

    const openAnalytics = () => {
      modalOpen.value = true
      // vm.$container.get('ga').send({
      //   category: 'AnalyticsDocument',
      //   action: 'view',
      // })
    }

    const analyticsCount = computed(
      () => (props.document?.viewCount ?? 0)
        + (props.document?.countQAAnswered ?? 0)
    )

    return {
      modalOpen,
      isSharingLinkEnabled,
      isVisible,
      openAnalytics,
      analyticsCount,
    }
  },
})
</script>
