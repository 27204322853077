<template>
  <draggable
    :list="tasks"
    tag="div"
    class="grid"
    :animation="200"
    :group="lockTasksToSection ? `tasks-${sectionId}` : 'tasks'"
    :disabled="noTaskReordering"
    ghost-class="ghost"
    @start="drag = true"
    @end="drag = false"
    @change="event => $emit('drag-event', event)"
  >
    <KanbanCard
      v-for="task in tasks"
      :key="task._id"
      :task="task"
      :selected="task._id === selectedTaskId"
      :drag="drag"
      :readonly-task="readonlyTask"
      @remove="$emit('remove', task)"
    >
      <slot name="task" v-bind="{ task }" />
    </KanbanCard>
  </draggable>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import draggable from 'vuedraggable'
import KanbanCard from '@/components/Kanban/KanbanCard.vue'

export default defineComponent({
  name: 'KanbanCards',
  components: {
    draggable,
    KanbanCard,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    sectionId: {
      type: String,
      required: true,
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    readonlyTask: {
      type: [Boolean, Function],
      default: false,
    },
  },
  setup() {
    const drag = ref(false)

    return {
      drag,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .ghost {
    @apply border border-gray-500;
  }
</style>
