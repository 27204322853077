
<div
  class="flex items-center"
  :title="label"
>
  <span
    v-if="icon"
    :class="[icon, 'text-24 mr-4']"
  />
  <slot name="icon" />
  <span class="text-left grow line-clamp-1">
    {{ label }}
  </span>
  <span
    v-if="count"
    class="
      text-white text-11 font-bold leading-none rounded-md
      flex items-center h-18 px-6 sidebar-counter flex-shrink-0
      count
    "
    :class="[
      {
        'bg-orange-600': countVariant === VARIANT.contrast,
        'bg-gray-400': countVariant === VARIANT.muted,
      },
      countClass
    ]"
  >{{ count }}</span>
  <span v-if="$slots.right">
    <slot name="right" />
  </span>
</div>
