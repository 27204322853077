export const PROJECT_VIEW_DEFAULTS = /** @type {const} */({
  sortBy: 'updatedAt',
  viewAs: 'smallGrid',
  groupBy: 'none',
  activeCollapsed: false,
  archivedCollapsed: false,
})

export const PROJECT_TASKS_VIEW_DEFAULTS = /** @type {const} */({
  filterBy: 'allTasks',
})

export const PROJECT_MODULE_ICON = /** @type {const} */({
  iconDocuments: 'icon_v2-so_docs-files',
  iconActionItems: 'icon_v2-so_action-items',
  iconKnowledgeBase: 'icon-document-knowledge-base',
  iconTimeline: 'icon_v2-so_timeline',
  iconPackagedServices: 'icon_v2-so_open-box',
})

export const PERMISSIONS = /** @type {const} */({
  // read: 'read',
  create: 'create',
  update: 'update',
  remove: 'remove',
});
