
<DocumentLink v-slot="{ to }" :document="document">
  <DashboardAppCard
    :icon="document.icon || 'icon_v2-so_folder'"
    :label="label ?? document.title"
    :tooltip="tooltip"
    :to="to"
  >
    <template #default>
      <DocumentViewContext v-slot="{ sortField }">
        <FolderContentLoader
          v-slot="{ items }"
          :folder="document"
          :hidden-children-ids="hiddenChildrenIds"
          :sort-field="sortField"
        >
          <div v-if="items.length" class="w-full">
            <!-- <FolderViewOptions :value="viewContext" @input="updateViewContext" />
          <div class="text-10">{{ viewContext }}</div>
          <div class="grid w-full grid-cols-3 gap-14"> -->
            <!-- CARD -->
            <div
              v-if="document.previewViewAs === PREVIEW_VIEW_AS.card"
              class="grid w-full grid-cols-3 gap-16 md:gap-0 pb-32"
            >
              <DocumentPreviewCard
                v-for="item in items"
                :key="item._id"
                read-only
                :document="item"
              />
            </div>
            <!-- LIST -->
            <div
              v-if="document.previewViewAs === PREVIEW_VIEW_AS.list"
              class="flex flex-col items-center flex-1 grow w-full"
            >
              <div
                class="flex flex-col gap-8 w-full pb-48"
              >
                <DocumentLink
                  v-for="item in items"
                  v-slot="{ navigate: navigateToDoc }"
                  :key="item._id"
                  :document="item"
                >
                  <FolderItemList
                    :document="item"
                    size="m"
                    @select="navigateToDoc"
                  />
                </DocumentLink>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-center flex-1 h-full grow">
            <p
              class="
            px-40 -mt-32 leading-relaxed text-center
            text-gray-500 text-16 dark:text-darkGray-400
            portal-paragraph-font
          "
            >
              {{ $t('dashboard-cards.docs.placeholder') }}
            </p>
          </div>
        <!-- </div> -->
        </FolderContentLoader>
      </DocumentViewContext>
    </template>

    <template #options>
      <!-- Hover actions -->
      <div
        v-if="!isClientPortal"
        class="absolute top-4 right-4 overflow-hidden
        opacity-0 transform translate-x-[200px] group-hover:opacity-100
        group-hover:translate-x-0 transition-all
        flex
        p-4 z-1060
      bg-darkGray-900/80
        filter backdrop-blur-xs
        h-32 font-medium text-14
        leading-none
        items-center justify-center
        rounded-lg
        hover:ring-opacity-40 hover:backdrop-blur-xl
      "
      >
        <button
          v-b-tooltip.hover.top.v-info.dh0.ds200="'View as cards'"
          class="folder-preview-option"
          :class="[document.previewViewAs === 'card' && 'active']"
          @click.stop="setPreviewMode('card')"
        >
          <span class="text-16" :class="[viewAsIcons.card]"></span>
        </button>
        <button
          v-b-tooltip.hover.top.v-info.dh0.ds200="'View as list'"
          class="folder-preview-option"
          :class="[document.previewViewAs === 'list' && 'active']"
          @click.stop="setPreviewMode('list')"
        >
          <span class="text-16" :class="[viewAsIcons.list]"></span>
        </button>
      </div>
    </template>
  </DashboardAppCard>
</DocumentLink>
