
<DashboardAppCard
  :label="`Tasks / ${section.taskSection.title}`"
  button-label="Manage Tasks"
  :icon="tasksModule.icon"
  :to="routeTasks"
>
  <ul class="flex flex-col w-full space-y-4">
    <li v-for="task in section.tasks" :key="task._id">
      <ActionItemLink v-slot="{ navigate }" :action-item="task">
        <ActionItemPreview :item="task" @click="navigate" />
      </ActionItemLink>
    </li>
  </ul>
  <div
    v-if="!section.tasks.length"
    class="flex items-center justify-center flex-1 h-full grow"
  >
    <p
      class="
        px-40
        -mt-32
        leading-relaxed
        text-center text-gray-500 text-16
        dark:text-darkGray-400
        portal-paragraph-font
      "
    >
      {{ $t('dashboard-cards.action-items.placeholder') }}
    </p>
  </div>
</DashboardAppCard>
