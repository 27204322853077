<template>
  <div
    v-if="presets.length"
    class="px-16 space-y-16 text-14"
  >
    <div
      v-for="(preset, index) in presets"
      :key="preset.label"
    >
      <h2 class="text-gray-700 font-medium">
        {{ preset.label }}
      </h2>
      <div class="flex flex-wrap">
        <!--  Reset Button -->
        <!-- Display this always in Current colors -->
        <button
          v-if="preset.showReset === true"
          type="button"
          :class="['icon_v2-so_close h-20 w-20 rounded-full outline-none focus:outline-none',
                   'mr-6 mt-8 ring-1 ring-gray-200 hover:opacity-80']"
          :style="{backgroundColor:'#FFFFFF' }"
          @click.stop="$emit('pick-color-from-presets', '')"
        >
        </button>

        <button
          v-for="color in preset.colors"
          :key="color.id"
          type="button"
          :class="[ isColorInPresets(color)
                      && 'ring-offset-2 ring-offset-gray ring-gray-300 ring-1',
                    'h-20 w-20 rounded-full outline-none focus:outline-none',
                    'mr-6 mt-8 ring-1 ring-gray-200 hover:opacity-80',]"
          :style="{backgroundColor: color.color}"
          @click.stop="$emit('pick-color-from-presets', color.color)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPickerPresets',
  props: {
    presets: {
      type: Array,
      default: () => [],
    },
    selectedValue: {
      type: String,
      default: '',
    },

  },
  setup(props) {
    const isColorInPresets = color => {
      if (props.selectedValue === color.color || props.selectedValue === color.color.slice(0, 7)) {
        return true
      }
      return false
    }
    return {
      isColorInPresets,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
