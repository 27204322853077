
<ClientPortalLoginTheme
  v-slot="{ portalCustomizations }"
  :organization="organization"
  :overrides="pageCustomization"
>
  <div
    id="loginContainer"
    class="login-background"
    :class="[
      'page-shared portal-paragraph-font',
      portalCustomizations.appearance,
      `page-${portalCustomizations.pageLayout}`,
    ]"
  >
    <!-- Background Image -->
    <div class="login-background-image"></div>
    <!-- FORM -->
    <div
      class="form-shared"
      :class="[
        `form-${portalCustomizations.pageLayout}`,
        portalCustomizations.showFormBackground
          && `form-background-${portalCustomizations.pageLayout}`
      ]"
    >
      <div class="flex flex-col items-center justify-center mb-20">
        <!-- --------------- -->
        <!-- LOGO -->
        <!-- --------------- -->
        <div
          v-if="organization.logoUrl || organization.logoDarkUrl"
          class="
            mb-32 lg:mb-48 w-[196px] lg:w-[228px]
            h-96 relative flex items-center justify-center
          "
        >
          <Logo
            :dark="organization.logoDarkUrl"
            :light="organization.logoUrl"
            :theme="portalCustomizations.appearance"
            align="center"
          />
        </div>
      </div>
      <slot v-if="$scopedSlots.default" />
      <router-view
        v-else
        :organization="organization"
        :domain="domain"
      />
    </div>
    <FeatureFlags
      v-slot="{ portalShowPoweredBySuperOkay }"
      :flags="['portalShowPoweredBySuperOkay']"
    >
      <div class="fixed bottom-8 left-8">
        <PoweredBySuperokay v-if="portalShowPoweredBySuperOkay" />
      </div>
    </FeatureFlags>
  </div>
</ClientPortalLoginTheme>
