
<div v-if="status && displayStatus" class="inline-flex flex-row items-center">
  <!-- Display full status badge -->
  <div
    v-if="displayFull"
    :class="[
      variant.default,
      dropdown && variant.hover,
      dropdown && 'badge-dropdown',
      'transition-all font-semibold text-14 rounded-md h-32',
      ' flex items-center justify-between px-10 leading-none',
    ]"
  >
    <span v-if="isApproved" class="icon_v2-so_tick mr-4 opacity-60" />
    {{ status | translate }}
    <span v-if="dropdown" class="relative icon_v2-so_direction-down !ml-8"></span>
  </div>
  <!-- Display small status badge -->
  <span
    v-if="displaySmall"
    :class="[
      variant.default,
      dropdown && variant.hover,
      dropdown && 'badge-dropdown',
      'transition-all font-semibold text-11 rounded-[4px] h-16',
      ' flex items-center justify-between px-4 leading-none',
    ]"
  >
    <span v-if="isApproved" class="w-12 text-12 icon_v2-so_lock" />
    {{ status | translate }}
    <span v-if="dropdown" class="relative icon_v2-so_direction-down ml-6"></span>
  </span>
  <!-- Display minimized badge with lock icon -->
  <span
    v-else-if="displayLock"
    class="inline-flex items-center text-green-600 ml-12"
  >
    <span class="icon_v2-so_lock w-12 text-12"></span>
    <span class="text-12">{{ status | translate }}</span>
  </span>
  <span
    v-else-if="displayText"
    :class="[
      'inline-flex items-center',
      isApproved ? 'text-green-600' : 'text-gray-500',
    ]"
  >
    <span
      :class="
        [
          'w-8 h-8 rounded-full',
          isApproved ? 'bg-green-600' : 'bg-gray-500',
        ]
      "
    ></span>
    <span class="text-12 ml-4">{{ status | translate }}</span>
  </span>
</div>
