
<router-link
  v-slot="{ href, navigate, isExactActive, isActive }"
  :to="to"
  custom
>
  <li
    class="group"
    :class="
      ((activateOnExactRoute && isExactActive)
        || (activateOnChildRoute && isActive)
        || isActiveOtherRoutes
      ) ? activeItemClass : itemClass
    "
  >
    <a
      class="no-hover block w-full"
      :href="href"
      @click="navigate"
    >
      <slot />
    </a>
  </li>
</router-link>
