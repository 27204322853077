<template>
  <li class="kanban-column">
    <div class="flex items-start group/section w-full gap-8">
      <ContentEditable
        :value="section.taskSection.title"
        lazy
        placeholder="Title..."
        tag="p"
        :no-html="true"
        no-newline
        trim
        class-name="smart-word-break flex-1 portal-heading-font dark:text-white/70"
        :contenteditable="!readonlySections"
        @input="updateTitle"
      />
      <div class="flex items-center">
        <div
          class="items-center gap-8 hidden group-hover/section:flex"
          :class=" {'mr-8': !noSectionMenu }"
        >
          <ButtonIcon
            v-b-tooltip.hover.bottom.v-info.dh0.ds200="$t('tasks.addTask')"
            icon="icon_v2-so_plus"
            size="18"
            @click="$emit('create-task', 0)"
          />
          <ButtonIcon
            v-if="!readonlySections"
            v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Delete section`"
            icon="icon_v2-so_trash"
            size="18"
            @click="$emit('remove')"
          />
        </div>
        <TaskSectionMenu
          v-if="!noSectionMenu"
          class="-mr-8"
          @select="action => $emit('menu-action', action)"
        />
      </div>
    </div>
    <ProgressBarCompleted
      class="mb-8"
      :count="taskCount"
      :completed-count="resolvedTaskCount"
    />
    <KanbanCards
      :tasks="section.tasks"
      :section-id="section.taskSection._id"
      :selected-task-id="selectedTaskId"
      :lock-tasks-to-section="lockTasksToSection"
      :no-task-reordering="noTaskReordering"
      :readonly-task="readonlyTask"
      @remove="task => $emit('remove-task', task)"
      @drag-event="event => $emit('drag-event-tasks', event)"
    >
      <template #task="{ task }">
        <slot name="task" v-bind="{ task }" />
      </template>
    </KanbanCards>
    <b-button
      type="button"
      size="xs"
      variant="neutral"
      class="capitalize opacity-60 hover:opacity-100 portal-paragraph-font"
      block
      @click="$emit('create-task', section.tasks.length)"
    >
      {{ $t('tasks.addTask') }}
    </b-button>
  </li>
</template>
<script>
import { take } from 'ramda';
import { computed, defineComponent } from '@vue/composition-api'
import ButtonIcon from '@/components/ButtonIcon.vue';
import KanbanCards from '@/components/Kanban/KanbanCards.vue'
import ContentEditable from '@/components/ContentEditable.vue';
import ProgressBarCompleted from '@/components/ProgressBarCompleted.vue'
import TaskSectionMenu from '../Tasks/TaskSectionMenu.vue';

export default defineComponent({
  name: 'KanbanColumn',
  components: {
    ButtonIcon,
    KanbanCards,
    ContentEditable,
    ProgressBarCompleted,
    TaskSectionMenu,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    readonlySections: {
      type: Boolean,
      default: false,
    },
    readonlyTask: {
      type: [Boolean, Function],
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    noSectionMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const updateTitle = newTitle => {
      context.emit('update', { title: take(100, newTitle) })
    }

    const resolvedTaskCount = computed(
      () => props.section.tasks.filter(task => task.isResolved).length
    );

    const taskCount = computed(() => props.section.tasks.length)

    return {
      updateTitle,
      taskCount,
      resolvedTaskCount,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .kanban-column {
    @apply bg-gray-100/50 p-8 rounded-sm;
    @apply dark:bg-darkGray-700;
  }
</style>
