// Normalize and lowercase a string
const normalizeAndLowercase = str => str.normalize('NFD').toLowerCase();

// Remove diacritics from a string
const removeDiacritics = str => str.replace(/[\u0300-\u036f]/g, '');

// Check if normalizedStr includes normalizedSearchStr
const includes = (normalizedStr, normalizedSearchStr) => removeDiacritics(normalizedStr)
  .includes(removeDiacritics(normalizedSearchStr));

// Main comparison function
const compareStrings = (str, searchStr, isStartCheck = false) => {
  const normalizedStr = normalizeAndLowercase(str);
  const normalizedSearchStr = normalizeAndLowercase(searchStr);

  if (isStartCheck) {
    return normalizedStr.startsWith(removeDiacritics(normalizedSearchStr));
  }
  return includes(normalizedStr, normalizedSearchStr);
};

// Exported function that creates a comparison function based on a keyword
export default (keyword = '') => (word = '') => {
  if (!keyword) return true;
  const isShortKeyword = keyword.length < 3;
  return compareStrings(word, keyword, isShortKeyword);
};
