
<div class="-m-16">
  <DocumentGet
    :id="documentId"
    v-slot="{ document, isGetPending: isPending }"
  >
    <Provide v-if="!isPending" :provide="{ document }">
      <ProjectTheme
        :project="project"
        no-dark-mode
        :wrapper-class="[
          project?.portalTheme === 'dark' ? 'bg-darkGray-1000':'bg-page'
        ]"
      >
        <DocumentViewerClientPortal
          :document="document"
          :organization="organization"
          is-client-portal
          :project="project"
        />
      </ProjectTheme>
    </Provide>
  </DocumentGet>
</div>
