
<div v-fragment>
  <ProjectTemplatesModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :pending="cloneProjectPending"
    :error="cloneProjectError"
    @use="onUseTemplate"
  />
  <FeatureLimit
    v-slot="{ checkFeatureLimit }"
    flag="limitProjects"
    label="projects"
    @allow="openModal"
  >
    <slot v-bind="{ create: checkFeatureLimit }" />
  </FeatureLimit>
</div>
