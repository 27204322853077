
<div v-fragment>
  <ContentEditable
    tag="h5"
    placeholder="Add notes..."
    :contenteditable="isAnswerable"
    :class="[
      $style[`text-variant-${textVariant}`],
      'portal-paragraph-font',
      'mt-16 text-16 font-medium text-gray-600 leading-snug lg:leading-snug',
      'print:hidden'
    ]"
    :no-newline="false"
    :no-html="false"
    :value="value"
    lazy
    @focus.native="hasFocus = true"
    @input="val => updateNotes(val)"
    @blur.native="hasFocus = false"
    @contextmenu.stop
  />
  <div
    v-if="hasFocus"
    class="mt-8 -mb-8 print:hidden"
  >
    <b-button
      variant="neutral"
      size="sm"
      class="w-auto px-16 mr-4"
      @click="blurOrClick"
    >
      <span class="mr-8">Save note</span>
      <span class="w-12 -mr-8 icon_v2-so_tick text-12" />
    </b-button>
  </div>
</div>
