<template>
  <ButtonTransparent
    class="text-gray-500 dark:text-darkGray-400 cursor-pointer hover:text-gray-900 transition-all"
    :class="
      variant === 'rounded' && [
        '!border-solid border-[1.5px]',
        'border-gray-500 dark:border-darkGray-400 rounded-full',
        'hover:border-gray-900 hover:dark:border-darkGray-300 hover:shadow-md'
      ]"
    v-on="$listeners"
  >
    <span
      :class="[
        icon,
        `text-${size}`
      ]"
    />
  </ButtonTransparent>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'

export default defineComponent({
  name: 'ButtonIcon',
  components: {
    ButtonTransparent,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    buttonRef: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: '24',
    },
    variant: {
      type: String,
      default: 'transparent',
      validator: v => ['transparent', 'rounded'].includes(v),
    },
  },
})
</script>
