<template>
  <component
    :is="tag"
    ref="element"
    v-focus="autofocus"
    :class="['editable', className]"
    :contenteditable="contenteditable"
    :placeholder="placeholder"
    @input="onInput"
    @blur="onBlur"
    @focus="onFocus"
    @paste="onPaste"
    @keypress="onKeypress"
    @keydown="fwdEv"
    @keyup="fwdEv"
    @mouseenter="fwdEv"
    @mouseover="fwdEv"
    @mousemove="fwdEv"
    @mousedown="fwdEv"
    @mouseup="fwdEv"
    @auxclick="fwdEv"
    @click="fwdEv"
    @dblclick="fwdEv"
    @contextmenu="fwdEv"
    @wheel="fwdEv"
    @mouseleave="fwdEv"
    @mouseout="fwdEv"
    @select="fwdEv"
    @pointerlockchange="fwdEv"
    @pointerlockerror="fwdEv"
    @dragstart="fwdEv"
    @drag="fwdEv"
    @dragend="fwdEv"
    @dragenter="fwdEv"
    @dragover="fwdEv"
    @dragleave="fwdEv"
    @drop="fwdEv"
    @transitionstart="fwdEv"
    @transitioncancel="fwdEv"
    @transitionend="fwdEv"
    @transitionrun="fwdEv"
    @compositionstart="fwdEv"
    @compositionupdate="fwdEv"
    @compositionend="fwdEv"
    @cut="fwdEv"
    @copy="fwdEv"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'

const replaceNewline = (str, replacement) => (str || '').replace(/\r?\n|\r/g, replacement)

export default defineComponent({
  name: 'ContentEditable',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    contenteditable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    className: {
      type: [String, Array, Object],
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    noHtml: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    noNewline: {
      type: Boolean,
      default: false,
    },
    trim: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.currentContent()) {
        this.updateContent(newVal)
      }
    },
    tag(newVal, oldVal) {
      if (oldVal) {
        this.$nextTick(() => this.updateContent(this.value))
      }
    },
  },
  mounted() {
    this.updateContent(this.value)
  },
  methods: {
    focus() {
      this.$refs.element.focus()
    },
    currentContent() {
      const elem = this.$refs.element
      return this.noHtml
        ? this.trim
          ? elem.innerText.trim()
          : elem.innerText
        : elem.innerHTML
    },
    updateContent(val) {
      if (!this.$refs.element) {
        console.warn('ContentEditable: $refs.element is nil')
        return
      }
      const newContent = replaceNewline(val, ' ') || ''
      if (this.noHtml) {
        this.$refs.element.innerText = newContent
      } else {
        this.$refs.element.innerHTML = newContent
      }
    },
    update() {
      this.$emit('input', this.currentContent())
    },
    onPaste(event) {
      event.preventDefault()
      let text = (event.originalEvent || event).clipboardData.getData(
        'text/plain'
      )

      if (this.noNewline) {
        text = replaceNewline(text, ' ')
      }

      if (this.trim) {
        text = text.trim()
      }

      window.document.execCommand('insertText', false, text)
      this.fwdEv(event)
    },
    onKeypress(event) {
      if (event.key === 'Enter' && this.noNewline) {
        event.preventDefault()
        this.update()
        this.$emit('returned', this.currentContent)
      }
      this.fwdEv(event)
    },
    onFocus(e) {
      this.$emit('focus', e)
    },
    onBlur(e) {
      this.lazy && this.update()
      this.$emit('blur', e)
    },
    onInput() {
      !this.lazy && this.update()
    },
    fwdEv(event) {
      this.$emit(event.type, event)
    },
  },
})
</script>

<style scoped lang="postcss">
.editable {
  position: relative;
  &[contenteditable='true']:empty:before {
    opacity: 0.6;
    font-style: normal;
  }

  &[contenteditable='true'] {
    &:hover {
      @apply bg-yellow-200 bg-opacity-40 dark:bg-darkGray-1000;
      background-blend-mode: overlay;

      /* &::after {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        width: 18px;
        height: 18px;
        transform: translateY(-50%);
        margin-left: 10px;
        opacity: 0.5;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 21.75a.75.75 0 0 1-.53-.22.764.764 0 0 1-.21-.67l.7-3.67c.18-.93.62-1.78 1.29-2.44L15.71 3.29c1.34-1.34 3.67-1.34 5 0 .67.67 1.04 1.56 1.04 2.5s-.37 1.83-1.04 2.5L9.25 19.75a4.64 4.64 0 0 1-2.45 1.29l-3.67.7s-.09.01-.14.01ZM14.31 6.81 5.32 15.8c-.46.46-.76 1.03-.88 1.66l-.49 2.59 2.59-.49c.63-.12 1.21-.42 1.67-.88l8.99-8.99-2.88-2.88Zm1.06-1.06 2.88 2.88 1.41-1.41c.38-.38.6-.9.6-1.44s-.21-1.06-.6-1.44c-.77-.77-2.11-.77-2.88 0l-1.4 1.41Z" style="stroke-width:0"/></svg>');
        @apply dark:invert;
      } */
    }

    &:focus {
      //background: #FFFF0020;
      background: transparent;
      //background-blend-mode: overlay;
    }
  }
}
</style>

