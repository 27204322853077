import { omit, propSatisfies, propOr, pipe, endsWith, not } from 'ramda'
import { flipIncludes, rejectNil, isNilOrEmpty } from 'ramda-extension'
import { inject, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useTracking } from '@/v2/services/tracking/compositions'
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import { CATEGORY as NODE_CATEGORY } from '@/v2/services/documentNodes/documentNodesTypes'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'
import { omitCond } from '@/v2/lib/helpers/fp'
import {
  useStructureInsertNode,
  useStructureNode,
  useStrucureCutNodes,
  findBranchByNodeId,
  findBranchByParent,
  useStructure,
} from '@/v2/services/documentStructures/documentStructuresCompositions'
import { CB_ACTIONS } from '@/types/clipboard'
import { useMsgBoxError } from '@/v2/lib/composition/useMsgBox'
import { containsEmbeddedDoc } from '@/v2/lib/helpers/document'
import { asyncSome } from '@/lib/promise'
import { useDocuments } from '../documents/documentsCompositions'

const omitDuplicateProps = pipe(
  // omit fields
  omit([
    '_id',
    'contentBlock',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'statistics',
  ]),
  // omit joins
  omitCond(endsWith('$'))
)

const {
  useMutations: useMutationsDocumentEditor,
  useState: useDocEditorState,
  useActions: useDocEditorActions,
} = createNamespacedHelpers('documentEditor')


export const isNodeGroup = propSatisfies(
  flipIncludes([
    NODE_CATEGORY.NodeGroupTeamBio,
    NODE_CATEGORY.NodeGroupCaseStudy,
    NODE_CATEGORY.NodeGroupPackagedService,
    NODE_CATEGORY.NodeGroupBasic,
    NODE_CATEGORY.NodeGroupColumnsContainer,
    NODE_CATEGORY.NodeGroupColumn,
  ]),
  'category'
)

const documentToContentBlockMap = {
  [DOCUMENT_CATEGORY.DocumentTeamBio]: CONTENT_BLOCK_CATEGORY.ElementTeamBio,
  [DOCUMENT_CATEGORY.DocumentCaseStudy]: CONTENT_BLOCK_CATEGORY.ElementCaseStudy,
  [DOCUMENT_CATEGORY.DocumentPackagedService]: CONTENT_BLOCK_CATEGORY.ElementPackagedService,
  // NOTE: 'ReusableBlock' categories for content blocks & nodes should only be a temporary
  // occurence as they will be replaced by the actual nodes in the reusable block after they're
  // cloned server-side
  [DOCUMENT_CATEGORY.DocumentReusableBlock]: CONTENT_BLOCK_CATEGORY.ElementReusableBlock,
  [DOCUMENT_CATEGORY.Document]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.Folder]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.File]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.FileLink]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentBrief]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentEstimate]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentContract]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentStatementOfWork]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentChangeRequest]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentClientSignOff]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentProgressUpdate]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentCustom]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentProposal]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
  [DOCUMENT_CATEGORY.DocumentKnowledgeBase]: CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
}

const elemToNodeGroupMap = {
  [CONTENT_BLOCK_CATEGORY.ElementTeamBio]: NODE_CATEGORY.NodeGroupTeamBio,
  [CONTENT_BLOCK_CATEGORY.ElementCaseStudy]: NODE_CATEGORY.NodeGroupCaseStudy,
  [CONTENT_BLOCK_CATEGORY.ElementPackagedService]: NODE_CATEGORY.NodeGroupPackagedService,
  [CONTENT_BLOCK_CATEGORY.ElementEstimate]: NODE_CATEGORY.NodeGroupBasic,
  [CONTENT_BLOCK_CATEGORY.ElementBrief]: NODE_CATEGORY.NodeGroupBasic,
  [CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument]: NODE_CATEGORY.NodeContentBlock,
}

const nodeDefaults = {
  category: NODE_CATEGORY.NodeContentBlock,
}

// /**
//  * Can the `documentToEmbed` be included in the parent document?
//  * If the parent document is already embedded anywhere in the `documentToEmbed` or in any of its
//  * embedded-children, the function returns `false` and it returns `true` otherwise
//  * @requires ComponentInternalInstance Can only be used inside a composition function as it requires
//  * access to a VueJS component instance
//  * @param {Object} param0
//  * @param {String} param0.parentDocId The ID of the parent document, where we want to embed another
//  * document
//  * @param {{_id: String; documents: Array<{document: String}>}} param0.documentToEmbed The
//  * document we want to embed
//  */
// const useIsInclusionAllowed = () => {
//   const vm = getCurrentInstanceOrThrow()
//   const { Document } = vm.$FeathersVuex.api

//   const isInclusionAllowed = async ({ parentDocId, documentToEmbed }) => {
//     // Base condition
//     if (documentToEmbed._id === parentDocId) return false; // Cannot embed document within itself
//     // Sanity check. No embedded documents => inclusion allowed
//     if (!Array.isArray(documentToEmbed.documents) || !documentToEmbed.documents.length) return true
//     // Recursively check if any embedded-children contain the parent doc
//     // Is there any embedded document fow which inclusion is not allowed?
//     return asyncSome(
//       documentToEmbed.documents,
//       async ({ document }) => isInclusionAllowed({
//         parentDocId,
//         documentToEmbed: await Document.grabOrFetch(document),
//       })
//         .then(not)
//     )
//       .then(not) // If such a document found, then inclusion not allowed
//   }

//   return isInclusionAllowed
// }

export const useCreateNode = () => {
  const { track } = useTracking()
  const vm = getCurrentInstanceOrThrow()
  /** Current (parent) document where node is about to be created */
  const document = inject('document')
  const structureInsertNode = useStructureInsertNode()
  const { setLastCreated } = useMutationsDocumentEditor(['setLastCreated'])
  const { setFocus } = useMutationsDocumentEditor(['setFocus'])
  const msgBoxError = useMsgBoxError()
  // const isInclusionAllowed = useIsInclusionAllowed()

  const { ContentBlock, DocumentNode, Document, DocumentStructure } = vm.$FeathersVuex.api

  const create = async ({
    parentNodeId,
    /** Node will be inserted last in `parentNodeId`'s branch if not provided */
    index = null,
    nodeData = {},
    /** Node will be created in this document, if provided. If not, current document will be used
     * (`inject('document')`) */
    targetDocumentRef = null,
    contentBlockData = null,
    flashNode = true,
    duplicateNode = false,
    structureClone,
    structureLocal = false, // if true, disable structure.save API call
  }) => {
    let _parentNodeId = parentNodeId
    let _index = index
    const targetDocument = targetDocumentRef // Custom target document
      ?? document // (current, injected doc)

    const _nodeData = {
      ...nodeDefaults,
      ...nodeData,
      document: targetDocument.value._id,
    }
    const parentNode = parentNodeId === 'root' ? 'root' : DocumentNode.getFromStore(parentNodeId)

    // setFocus to null before setFocus to the new created node if
    // the node parent is not root || is duplicate node
    // NOTE: If the parent of the node is not root (ex: is group) and the focus was set on the
    // parent, then when the child node is created and set focus on it, we have a bootstrap warning.
    if (parentNodeId !== 'root' || duplicateNode) setFocus(null)

    if (contentBlockData) {
      const _contentBlockData = {
        ...contentBlockData,
        document: targetDocument.value._id,
      }
      const embeddedDocumentId = propOr(null, 'embeddedDocument', _contentBlockData)

      if (embeddedDocumentId) {
        // An embedded document is about to be inserted
        const embeddedDocument = await Document.grabOrFetch(embeddedDocumentId)
        _contentBlockData.category = documentToContentBlockMap[embeddedDocument.category]
        // Check if document is already embedded
        if (containsEmbeddedDoc(targetDocument.value, embeddedDocumentId)) {
          return msgBoxError({
            title: 'Operation not allowed',
            message: 'This document was already embedded in the parent document',
          })
        }
        // Check for circular references to this document anywhere along the embedded documents'
        // chain - any document can be embedded in any other document
        // if (!(await isInclusionAllowed({
        //   parentDocId: targetDocument.value._id,
        //   documentToEmbed: embeddedDocument,
        // }))) {
        //   return msgBoxError({
        //     title: 'Operation not allowed',
        //     message: 'The document you wish to embed, or one of its children, already contains the'
        //       + ' parent document.',
        //   })
        // }

        if (embeddedDocument.category === DOCUMENT_CATEGORY.DocumentReusableBlock) {
          _nodeData.category = NODE_CATEGORY.NodeReusableBlock
        } else {
          const requiredParentNodeCategory = elemToNodeGroupMap[_contentBlockData.category]
          if (
            ![
              CONTENT_BLOCK_CATEGORY.ElementEstimate,
              CONTENT_BLOCK_CATEGORY.ElementBrief,
              CONTENT_BLOCK_CATEGORY.ElementEmbeddedDocument,
            ].includes(_contentBlockData.category)
            && requiredParentNodeCategory !== parentNode.category
          ) {
            const newParentNode = await create({
              parentNodeId,
              index,
              nodeData: { category: requiredParentNodeCategory },
              flashNode: false,
              targetDocumentRef,
            })

            _parentNodeId = newParentNode._id
            _index = 0
          }
        }
      }

      const contentBlock = new ContentBlock(_contentBlockData)
      await contentBlock.save()

      track('Document Block Created', {
        category: contentBlock.category,
        isDuplicate: duplicateNode,
      })

      _nodeData.contentBlock = contentBlock._id
      _nodeData.contentBlock$ = contentBlock
    }

    const node = new DocumentNode(_nodeData)
    await node.save()

    flashNode && setLastCreated(node._id)
    // set focuse on the created node if it is not duplicate (copy/paste || duplicate)
    !duplicateNode && setFocus(node._id)
    // Check if node is to be inserted in current document or another
    let targetStructureRef = null
    if (targetDocumentRef) {
      targetStructureRef = ref(await DocumentStructure.get(targetDocumentRef.value.structure))
    }

    await structureInsertNode({
      id: node._id,
      parentNode: _parentNodeId,
      index: _index, // Can be null so node inserted last
      isNodeGroup: isNodeGroup(node),
      clone: structureClone,
      local: structureLocal,
      targetStructureRef, // In case node is to be inserted in another doc
    })

    return node
  }

  return create
}

export const useDuplicateNode = () => {
  const vm = getCurrentInstanceOrThrow()
  const { DocumentNode, DocumentStructure, Document } = vm.$FeathersVuex.api

  const createNode = useCreateNode()
  const structureNode = useStructureNode()

  const duplicate = async ({
    node,
    parentId = null,
    flashNode = true,
    index = 0,
  }) => {
    const { parentId: nodeParentId, index: nodeIndex } = structureNode(node)
    const { contentBlock$ } = node
    const contentBlockData = contentBlock$
      ? omitDuplicateProps(contentBlock$.toPlainObject())
      : null

    const nodeData = omitDuplicateProps(node.toPlainObject())

    return createNode(
      rejectNil({
        index: index ?? nodeIndex,
        parentNodeId: parentId ?? nodeParentId,
        nodeData,
        contentBlockData,
        flashNode,
        duplicateNode: true,
      })
    )
  }

  const manageDuplicate = async (node, index = null, parentId = null, isCopy = false) => {
    //  [02/08/21] Nodes that have embedded doc type Estimate and Brief cannot be duplicated.
    if (node.isEstimateOrBrief) return null
    // NOTE: for copy (duplicate) groups in OTHER documents (cut currently not supported)
    // Find the document id from where the group is copied and the structure branch
    // in order to copy their content. This would work with useStructureBranch ONLY
    // if the group nodes are going to be paste in the same document
    // because we use => document = inject('document') (2021-05-28)
    const documentId = DocumentNode.getFromStore(node._id).document
    const { structure: structureId } = await Document.grabOrFetch(documentId)
    const structure = DocumentStructure.getFromStore(structureId)
    const branch = findBranchByParent(structure, node._id)

    const newNode = await duplicate({ node, parentId, index, flashNode: false })
    // If is copy/paste then return the newNode
    if (isCopy) return newNode
    // Check that is NOT copy/paste  && is group
    if (isNodeGroup(node) && !isCopy) {
      if (!isNilOrEmpty(branch.children)) {
        const childNodes = branch.children.map(nodeId => DocumentNode.getFromStore(nodeId))
        // Take out the nodes that have embedded documents because they cannot be duplicated.
        const compatibleNodes = childNodes.filter(childNode => (!childNode.isEstimateOrBrief))
        // If childNode is group then we need to duplicate its content as well
        // Use reduce to run promises in sequence.
        return compatibleNodes.reduce((p, childNode, i) => p.then(() => {
          if (isNodeGroup(childNode)) {
            return manageDuplicate(childNode, i, newNode._id)
          }
          return duplicate({
            node: childNode,
            parentId: newNode._id,
            flashNode: false,
            index: i,
          })
        }), Promise.resolve());
      }
    }

    return Promise.resolve();
  }
  return manageDuplicate
}


export function usePasteNodesFromClipboard() {
  const vm = getCurrentInstanceOrThrow()
  const duplicateNode = useDuplicateNode()
  const cutNodes = useStrucureCutNodes()
  const { DocumentNode, DocumentStructure, Document } = vm.$FeathersVuex.api
  const { clipboard } = useDocEditorState(['clipboard'])
  const { clearClipboard, clearNodeSelection } = useDocEditorActions(['clearClipboard', 'clearNodeSelection'])
  const document = inject('document')

  const checkNodesCompatibility = nodes => nodes.filter(node => !node.isEstimateOrBrief)
  const checkNodeGroupCompatibility = (groupNode, structure) => {
    const branchGroup = findBranchByParent(structure, groupNode._id)
    const nodes = branchGroup.children.map(nodeId => DocumentNode.getFromStore(nodeId))
    return checkNodesCompatibility(nodes)
  }

  return async function pasteNodesFromClipboard({ targetIndex = 0, targetBranch = 'root' }) {
    const { content: nodeIds, action: causalAction } = clipboard.value
    if (!nodeIds.length) return null // Nothing to process
    const {
      _id: sourceNodeId,
      document: sourceDocumentId,
    } = DocumentNode.getFromStore(nodeIds[0])
    const { structure: sourceStructureId } = await Document.grabOrFetch(sourceDocumentId)
    const sourceStructure = DocumentStructure.getFromStore(sourceStructureId)
    // CUT(from/to group)
    // Find the source branch to check if it is different from the target
    const sourceBranch = findBranchByNodeId(sourceStructure, sourceNodeId)
    const sourceBranchParent = sourceBranch && sourceBranch.node
    /** Is the node in the clipboard going to be pasted in the same document, or another one? */
    const isWithinSameDoc = sourceDocumentId === document.value._id
    const allNodeIds = DocumentStructure
      .getFromStore(sourceStructureId)
      .tree[sourceBranchParent] || []
    // Iterate through current structure to ensure nodes in clipboard follow the same order
    /** The list of nodes in the clipboard sorted according to their order in the doc structure */
    const sortedNodeIds = allNodeIds.filter(nodeId => nodeIds.includes(nodeId))
    // Get data for nodes which are about to be copied
    const nodesData = sortedNodeIds.map(nodeId => DocumentNode.getFromStore(nodeId))
    // Remove unsuported nodes from the list (embedded docs )
    const copyCompatibleNodes = checkNodesCompatibility(nodesData)
    if (causalAction === CB_ACTIONS.copy) {
      if (!copyCompatibleNodes.length) return null // Nothing to process
      // Use reduce to run promises in sequence.
      // Check if is group and its children through the compatibility
      // If it group - create group and find its ID and call copyNodes for
      // the group children with parent the group ID
      // If it is  not group, duplicate node
      const copyNodes = (nodes, target, index = 0) => nodes.reduce((p, node, i) => p.then(async () => {
        if (isNodeGroup(node)) {
          const groupChildren = checkNodeGroupCompatibility(node, sourceStructure)
          const group = await duplicateNode(
            node,
            index + i,
            target,
            true // isCopy
          )
          await copyNodes(groupChildren, group._id)
          return group
        }
        // If nodes are copied, they should just be duplicated.
        // Paste nodes at specified index, in the the specified document (target id).
        return duplicateNode(
          node,
          index + i,
          target,
          true // isCopy
        )
      }), Promise.resolve());

      await copyNodes(copyCompatibleNodes, targetBranch, targetIndex)
      return clearNodeSelection()
    }
    // If this point was reached, cut/paste needs to be handled
    // NOTE: Cut/Paste between docs currently not supported.
    // Embedded docs can be cut/pasted (reordered) - not using `copyCompatibleNodes`
    if (!isWithinSameDoc) return null
    // CUT/PASTE from/to group nodes ** supports selections
    // Check if the source is different from the target
    // Remove nodes from source branch and add nodes to target branch
    cutNodes({ nodeIds: sortedNodeIds, targetParent: targetBranch, index: targetIndex })
    clearNodeSelection()
    return clearClipboard()
  }
}

/**
 * Composition hook which saves the selected nodes as a new reusable block.
 * No arguments are required - `documentEditor.state.selectedNodes` is used directly
 */
export function useSaveAsReusableBlock() {
  const vm = getCurrentInstanceOrThrow()
  const { create } = useDocuments()
  const { selectedNodes } = useDocEditorState(['selectedNodes'])
  const { DocumentNode, DocumentStructure, Document } = vm.$FeathersVuex.api

  const flattenNodeIds = (nodes, accum) => nodes.reduce((acc, nodeId) => {
    const node = DocumentNode.getFromStore(nodeId)
    acc.push(node._id) // Add this node to the list
    if (node.isGroup) {
      // Document should be in Store as selection is made on its nodes. No reason to fetch
      const { structure: structureId } = Document.getFromStore(node.document)
      const children = DocumentStructure.getFromStore(structureId).tree[nodeId]
      children.length && flattenNodeIds(children, acc) // Add child nodes to list
    }
    return acc
  }, accum || [])


  return function saveAsReusableBlock() {
    const sourceDocumentId = DocumentNode.getFromStore(selectedNodes.value[0]).document
    // Document should be in Store as selection is made on its nodes. No reason to fetch
    const { title } = Document.getFromStore(sourceDocumentId)
    // Check if any of the selected nodes is a node group and include any child nodes/groups in the
    // list so they may also be cloned
    const nodesToClone = flattenNodeIds(selectedNodes.value)
    // Get source document title
    return create({
      category: DOCUMENT_CATEGORY.DocumentReusableBlock,
      cloneNodes: nodesToClone,
      title: `Saved selection from ${title}`,
      project: null, // Force null project as reusable blocks don't belong to any
    })
  }
}
