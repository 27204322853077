import Vue from 'vue'
import { when, is, omit } from 'ramda'
import { parseISO, intervalToDuration, formatDuration } from 'date-fns'

const ensureDate = when(is(String), parseISO)
const omitProps = omit(['seconds']);

Vue.filter('dateDuration', value => formatDuration(
  omitProps(
    intervalToDuration({
      start: new Date(),
      end: ensureDate(value),
    })
  ),
  { delimiter: ', ' }
));

