<template>
  <div class="flex items-center gap-8">
    <ProgressBar class="flex-1" :ratio="completedCount / count" />
    <div class="text-10 text-gray-400">{{ completedCount }} / {{ count }}</div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ProgressBar from './Blocks/ProgressBar/ProgressBar.vue'

export default defineComponent({
  name: 'ProgressBarCompleted',
  components: {
    ProgressBar,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    completedCount: {
      type: Number,
      required: true,
    },
  },
})
</script>
