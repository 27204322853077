import { head } from 'ramda'
import { rejectNil } from 'ramda-extension'
import { makeServicePlugin } from '@/v2/services/feathersClientService'
import { Member } from '../members/membersStore'
import myMembersService, { servicePath } from './myMembersService'

export class MyMember extends Member {
  static modelName = 'MyMember'

  static setupInstance = Member.setupInstance

  static instanceDefaults = Member.instanceDefaults
}

export default makeServicePlugin({
  namespace: 'myMembers',
  Model: MyMember,
  service: myMembersService,
  servicePath,
  extend() {
    return {
      actions: {
        /**
         * Loads all members corresponding to the logged in user
         * @param {import('vuex').ActionContext} actionContext
         * @param {'admin' | 'client'} [role]
         */
        loadMembers({ dispatch, rootState }, query = {}) {
          const { _id: userId } = rootState.auth.user
          const _query = rejectNil({ user: userId, ...query })
          return dispatch('find', { query: _query })
        },
      },
      getters: {
        /** @type {import('vuex').Getter} */
        firstMember: (_, getters) => head(
          getters.find({ query: { $limit: 1 } }).data
        ),
      },
    }
  },
})
