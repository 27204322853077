<template>
  <div
    :id="name"
    ref="scrollContainer"
    v-scroll-direction:[scrollDirectionOptions]="setScrollDirection"
  >
    <slot
      v-bind="{
        scrollTo,
        scrollToElementId,
        scrollDirection,
        isAutoScrolling,
        isScrolling
      }"
    />
  </div>
</template>

<script>
import { alwaysEmptyObject } from 'ramda-extension'
import {
  defineComponent,
  getCurrentInstance,
  onUnmounted,
  provide,
  ref,
} from '@vue/composition-api'
import EventBus from '@/event-bus/event-bus'

export default defineComponent({
  name: 'ScrollContainer',
  props: {
    scrollDirectionOptions: {
      type: Object,
      default: alwaysEmptyObject,
    },
    name: {
      type: String,
      default: 'scrollcont',
    },
    // Force scroll on body when needed
    scrollOnBody: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const scrollDirection = ref('')
    const scrollContainer = ref(null)

    const isScrolling = ref(false)
    const isAutoScrolling = ref(false)

    const { $scrollTo } = getCurrentInstance().proxy

    const setScrollDirection = ({ direction, isScrolling: _isScrolling }) => {
      scrollDirection.value = direction
      isScrolling.value = _isScrolling
    }

    const scrollTo = (el, offset = 0, duration = 1000) => {
      const { matches: isMobile } = window.matchMedia('(max-width: 992px)')
      const container = isMobile || props.scrollOnBody ? 'body' : scrollContainer.value

      $scrollTo(el, duration, {
        container,
        offset,
        onStart: () => {
          context.emit('scroll-start')
          isAutoScrolling.value = true
        },
        onDone: () => {
          context.emit('scroll-end')
          isAutoScrolling.value = false
        },
        onCancel: () => {
          context.emit('scroll-cancel')
          isAutoScrolling.value = false
        },
      })
    }
    const scrollToElementId = (id, offset = 0, duration = 1000) => {
      scrollTo(
        document.getElementById(id),
        offset,
        duration
      )
    }

    provide('scrollTo', scrollTo)
    provide('scrollToElementId', scrollToElementId)

    EventBus.$on(`scrollTo:${props.name}`, scrollTo)
    EventBus.$on(`scrollToElementId:${props.name}`, scrollToElementId)

    onUnmounted(() => {
      EventBus.$off(`scrollTo:${props.name}`, scrollTo)
      EventBus.$off(`scrollToElementId:${props.name}`, scrollToElementId)
    })


    return {
      scrollContainer,
      scrollDirection,
      setScrollDirection,
      scrollTo,
      scrollToElementId,
      isAutoScrolling,
      isScrolling,
    }
  },
})
</script>
