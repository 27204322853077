import { computed } from '@vue/composition-api';
import useFindPagination from '@/v2/lib/composition/useFindPagination';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {Ref<string>} params.documentId
 * @param {number} [params.pageSize]
 */

export default function useDocumentEvents({
  documentId,
  pageSize = 500,
}) {
  const query = computed(() => ({
    // subject: documentId.value,
    document: documentId.value,
    $sort: { time: 1 },
  }));

  const {
    items,
    ...rest
  } = useFindPagination({
    modelName: 'DocumentEvent',
    query,
    pageSize,
  });

  return {
    ...rest,
    items,
  };
}
