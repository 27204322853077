
<FeathersVuexGet
  :id="id"
  v-slot="{ item: project, isGetPending }"
  watch="id"
  :query-when="force || notInStore"
  service="projects"
  v-bind="$attrs"
>
  <slot v-bind="{ project, isGetPending }" />
</FeathersVuexGet>
