import Vue from 'vue'
import { useGet } from 'feathers-vuex'
import { isNotNil } from 'ramda-adjunct'
import { inject, computed } from '@vue/composition-api'
import { useRouteParams } from '@/v2/lib/composition/useRouter'

export default function useCurrentOrganization() {
  const { Organization } = Vue.$FeathersVuex.api

  // try to inject the organization - client portal
  const providedOrganization = inject('organization', null)

  // try to fetch the org based on the url param `organizationId` - editor
  const { organizationId } = useRouteParams(['organizationId'])

  const queryWhen = computed(
    () => isNotNil(organizationId)
      && !Organization.getFromStore(organizationId)
  )

  const { item } = useGet({
    model: Organization,
    id: organizationId,
    queryWhen,
  })

  return computed(
    () => (providedOrganization ? providedOrganization.value : item.value)
  );
}
