import {
  reject,
  pipe,
  prop,
  isNil,
  juxt,
  anyPass,
  equals,
  join,
  when,
  isEmpty,
  always,
} from 'ramda'
import Vue from 'vue'

const format = pipe(
  juxt([prop('city'), prop('country')]),
  reject(anyPass([isNil, isEmpty, equals('n/a')])),
  join(', '),
  when(isEmpty, always('Unknown location'))
)

Vue.filter('geoIp', format)
