<template>
  <div class="h-screen">
    <SpinnerOverlay
      v-if="connectionStatus === CONNECTION_STATUS.reconnecting"
      variant="transparent"
      label="Connection lost, reconnecting..."
    />
    <slot />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import EventBus from '@/event-bus/event-bus'
import SpinnerOverlay from '@/components/SpinnerOverlay.vue'

const CONNECTION_STATUS = {
  connected: 'connected',
  reconnecting: 'reconnecting',
}

export default defineComponent({
  name: 'ConnectionStatus',
  components: {
    SpinnerOverlay,
  },
  setup() {
    const connectionStatus = ref(null)

    const setConnectionStatus = status => () => {
      connectionStatus.value = status
    }

    EventBus.$on('socketio:connect', setConnectionStatus(CONNECTION_STATUS.connected))
    EventBus.$on('socketio:reconnecting', setConnectionStatus(CONNECTION_STATUS.reconnecting))

    return {
      CONNECTION_STATUS,
      connectionStatus,
    }
  },
})
</script>
