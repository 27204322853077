<template>
  <!-- Document structure -->
  <DocumentContentLoader
    v-slot="{ isLoading }"
    :document="document"
  >
    <!-- Nodes from structure -->
    <DocumentNodeFolder
      v-if="!isLoading && document"
      :view-context="viewContext"
      :permissions="permissions"
    />
  </DocumentContentLoader>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentContentLoader from '@/components/DocumentContentLoader.vue'
import DocumentNodeFolder from '@/v2/features/document/documentNodeFolder/DocumentNodeFolder.vue'

export default defineComponent({
  name: 'FolderContentList',
  components: {
    DocumentContentLoader,
    DocumentNodeFolder,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    viewContext: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
<style lang="postcss" scoped>
.node-list-move {
  @apply transform transition-transform duration-300 bg-white shadow-3xl z-50;
}
.drag-btn {
  /* visibility: hidden; */
  opacity: 0;
  position: relative;
  transition: opacity 0.2s linear;
  right: 20px;
  cursor: pointer;
}
.drag-btn:hover {
  /* visibility: visible; */
  opacity: 1;
}

.so-node-dragging {
}

.so-node-dragging section {
  margin: 0!important;
}


</style>

<style lang="postcss">

.so-node-dragging .block-create-menu-button,
.so-node-dragging .node-highlight,
.so-node-dragging .editor-toolbar,
.so-node-dragging .block-actions-right,
.so-node-dragging .block-actions-move,
.so-node-dragging .resize-container .handler {
  display: none;
}

.so-node-dragging .resize-container::after {
  opacity: 0;
}

.so-slick-inner {
  @apply shadow-none;
}

.so-node-dragging .so-slick-inner{
  @apply z-0 transform -rotate-1 scale-105;
  @apply m-0 ring-2 bg-white bg-opacity-50 ring-blue-500 rounded-lg overflow-hidden;
  box-shadow: 0 10px 40px rgba(0,0,0, 0.10), 0 0px 60px rgba(0,0,0, 0.04);
}

.so-node-dragging .so-slick-inner .block-actions,
.so-node-dragging .so-slick-inner .block-add {
  display: none;
}

.so-node-dragging .so-slick-inner .block-section-wrapper {
  @apply opacity-90 bg-white bg-opacity-50;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
</style>
