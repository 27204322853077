<script>
import { defineComponent, computed, inject, watch, set } from '@vue/composition-api'
import { isBoolean } from 'ramda-adjunct'

export default defineComponent({
  name: 'SidebarTreeItem',
  props: {
    node: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    expandOnClick: {
      type: Boolean,
      required: true,
    },
    expandRoot: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const isNode = computed(() => Array.isArray(props.node?.children))
    const isLeaf = computed(() => !isNode.value)
    const isEmpty = computed(() => isLeaf.value || !props.node.children.length)

    const activeNodes = inject('activeNodes')
    const isActive = computed(() => Boolean(activeNodes.value[props.node?.id]))
    // const setActive = inject('setActive')

    const expanded = inject('expanded')
    const isExpanded = computed(() => Boolean(expanded.value[props.node?.id]))

    // TODO: maybe rename to toggleExpanded
    const toggleCollapse = (bool = null) => {
      // value is either the provided bool param (set expanded)
      // or the inversed value of the current state (toggle current expanded state)
      const value = isBoolean(bool) ? bool : !expanded.value[props.node.id];
      set(expanded.value, props.node.id, value)
    }

    watch(isActive, newVal => {
      if (newVal) {
        context.emit('open-parent')
      }
    })

    if (props.expand) {
      toggleCollapse(true)
    }

    const onItemClick = () => {
      // setActive(props.node.id, true)
      if (props.expandOnClick) {
        toggleCollapse(true)
      }
    }

    if (props.expandRoot && props.level === 0 && props.index === 0) {
      toggleCollapse(true)
    }

    return () => context.slots.default({
      isNode: isNode.value,
      isEmpty: isEmpty.value,
      isLeaf: isLeaf.value,
      isExpanded: isExpanded.value,
      isActive: isActive.value,
      toggleCollapse,
      onItemClick,
    })
  },
})
</script>
