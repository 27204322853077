
<div class="group-placeholder mb-24">
  <section>
    <span class="icon-bio-placeholder w-128 text-128"></span>
  </section>
  <section>
    <span class="icon-bio-placeholder w-128 text-128"></span>
  </section>
  <section>
    <span class="icon-bio-placeholder w-128 text-128"></span>
  </section>
  <section>
    <span class="icon-bio-placeholder w-128 text-128"></span>
  </section>
</div>
