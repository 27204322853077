<template>
  <button
    v-bind="$attrs"
    :disabled="disabled || isLoading"
    :class="['so-button-progress bounce']"
    v-on="$listeners"
  >
    <div
      :class="[
        'absolute inset-0 z-0 bg-green-900 bg-opacity-50',
      ]"
    >
      <span
        class="progress-bar"
        :style="`width: ${progress}%`"
      >
      </span>
    </div>
    <div
      :class="[
        'relative flex items-center justify-center z-1'
      ]"
    >
      <slot />
    </div>
    <Spinner
      v-if="isLoading"
      :size="16"
      variant="light"
      class="ml-8"
    />
  </button>
</template>
<script>
import Spinner from '@/components/Spinner.vue'
import { computed } from '@vue/composition-api'

export default {
  name: 'ButtonProgress',
  components: {
    Spinner,
  },
  props: {
    isLoading: Boolean,
    disabled: Boolean,
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const progress = computed(() => Math.round((props.value / props.max) * 100))

    return {
      progress,
    }
  },
}
</script>

<style lang="postcss" scoped>
.so-button-progress {
  @apply rounded-md relative overflow-hidden;
  @apply text-white;
  @apply pl-6 pr-8 py-6 leading-none text-13 font-medium;
  @apply transition-all;
  /* hover */
  @apply hover:shadow;
  @apply hover:ring hover:ring-green-500 hover:ring-opacity-25;
  transform-origin: bottom;

}

.progress-bar {
  @apply bg-green-600 h-full flex;
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.bounce {
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-12px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-4px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}
</style>
