
<ProjectTheme
  :project="project"
  no-dark-mode
  class-name="bg-transparent relative flex flex-row flex-1 p-0 h-full"
>
  <div
    class="
      group hover:cursor-pointer transition-all w-full overflow-visible
      p-0 px-16 py-8 flex flex-row h-full items-center hover:bg-white
    "
    @click="$emit('select')"
  >
    <div
      class="
        w-16 h-16 rounded-full inline-block portal-accent-background mr-8
      "
    >
    </div>
    <h2
      class="
        inline-flex items-center font-medium leading-tight
        cursor-pointer text-16 group-hover:underline
        text-gray-800 dark:text-darkGray-100
        flex-1
      "
    >
      <a class="hover:underline dark:hover:text-darkGray" @click.stop="$emit('select')">
        {{ project.name }}
      </a>
    </h2>
    <div
      v-if="project.client$"
      class="
        inline-flex items-center leading-none text-13 flex-1
        text-gray-800 dark:text-darkGray-100 opacity-40
      "
    >
      <span class="icon-org-clients text-16 mr-4" />
      <p class="flex-1 flex-grow">{{ project.client$.name }}</p>
    </div>

    <p class="text-13 ml-8 flex-1">
      Last update
      <LiveFilter
        filter="timeago"
        :locale="project.locale"
        :value="project.updatedAt"
      />
    </p>
    <slot name="menu" />
  </div>
</ProjectTheme>
