<template>
  <div class="table-total">
    <div class="table-total-cell portal-heading-font">
      <span class="mr-auto">{{ $t('common.total') }}:</span>
      <DocumentCurrency :value="total" :document-id="documentId" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentCurrency from '@/components/DocumentCurrency.vue'

export default defineComponent({
  name: 'TableTotal',
  components: {
    DocumentCurrency,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    documentId: {
      type: String,
      default: null,
    },
  },
})
</script>
<style scoped lang="scss">
.table-total {
  @apply pt-8 pb-16 px-12 xl:pt-0 xl:pb-8 xl:px-0;
  @apply print:py-8 print:px-0;
}

.table-total-cell {
  @apply flex font-semibold;
  @apply text-24 print:text-24;

  :global(.block-section .light) & {
      color: white;
  }
}

</style>
