
<section data-intercom-target="Approval Button Block">
  <!-- If not approved -->
  <div v-if="!document.isApproved" class="relative approve portal-paragraph-font">
    <!-- FORM -->
    <b-form
      :class="[
        'w-full',
        isEditor && 'opacity-80'
      ]"
      @submit.prevent="formSubmit"
    >
      <div class="sign">
        <!-- SIGN HERE BADGE -->
        <span
          v-if="!['XXS','XS','S','M'].includes(nodeWidth) && !isEditor"
          :class="[
            'badge',
            isEditor && 'opacity-20',
          ]"
        >
          {{ $t('blocks.approval-button.sign-and-approve') }}
        </span>
        <div
          :class="[
            'flex flex-col md:flex-row md:items-center self-stretch flex-1',
            'space-y-8 md:space-y-0',
          ]"
        >
          <b-form-input
            v-if="!isProfileLoading"
            v-model="formState.name"
            aria-describedby="input-live-feedback"
            :disabled="nameInputDisabled"
            :state="nameValidationState"
            :placeholder="placeholder"
            class="input"
            size="md"
          />
          <b-form-invalid-feedback
            v-if="nameValidationState === false"
            id="input-live-feedback"
            class="error"
          >
            {{ $t('blocks.approval-button.please-type') }}
          </b-form-invalid-feedback>
          <b-button
            type="submit"
            variant="success"
            size="md"
            :class="[isEditor && 'opacity-60']"
          >
            <span class="w-24 mr-4 icon_v2-so_tick text-24"></span>
            {{ $t('blocks.approval-button.label') }}
          </b-button>
        </div>
      </div>
      <div class="mt-8 leading-normal text-center text-gray-600 text-16">
        <OrganizationGet
          :id="document.organization"
          v-slot="{ organization }"
        >
          {{ $t('blocks.approval-button.description') }}
        </OrganizationGet>
      </div>
    </b-form>
    <!-- EDITOR MODE OVERLAY -->
    <div
      v-if="isEditor"
      class="absolute inset-0 flex flex-col justify-end border border-gray-200 border-dashed rounded-md rounded-br-none top preview-overlay"
    >
      <div
        class="relative flex flex-row items-stretch justify-end mt-1 h-26 -mb-28 top-px -right-px"
      >
        <span
          class="flex items-center px-6 font-medium text-gray-900 bg-opacity-50 border-b border-l border-r border-gray-200 border-dashed bg-yellow-50 text-14 leading-0 rounded-br-md rounded-bl-md"
        >
          <span
            class="flex items-center px-4 py-2 mr-4 font-bold leading-none uppercase bg-yellow-400 rounded-md text-gray-1000 text-12"
          >{{ $t('blocks.approval-button.preview') }}</span>
          {{ $t('blocks.approval-button.what-client-sees') }}</span>
      </div>
    </div>
  </div>
  <!-- If approved -->

  <DocApprovalBadge
    v-else
    :document="document"
  />
</section>
