
<Vue2DatePicker
  v-model="model"
  class="so-datepicker"
  type="date"
  prefix-class="xmx"
  :formatter="dateFormatter"
  :show-animation="false"
  :clearable="clearable && !readonly"
  :append-to-body="false"
  :editable="!readonly"
  :disabled="readonly"
>
  <template #input="{ props, events }">
    <b-input
      v-if="!noInput"
      :value="props.value"
      :readonly="readonly"
      :disabled="readonly"
      class="so-datepicker-input capitalize text-14 cursor-pointer"
      :placeholder="placeholder"
      v-on="events"
    />
    <span v-else-if="props.value" class="pr-32">{{ props.value }}</span>
    <span v-else class="text-gray-400 pr-32">{{ placeholder }}</span>
  </template>
</Vue2DatePicker>
