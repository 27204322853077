<template>
  <div class="relative group flex justify-center p-8">
    <b-link
      ref="linkRef"
      :href="link.url"
      :target="link.openInNewTab ? '_blank' : null"
      class="
        text-center font-semibold
        flex flex-col items-center
        text-gray-800 dark:text-darkGray-300
        hover:text-gray-900 dark:hover:text-darkGray-300
        transition-all transform-gpu hover:-translate-y-2
      "
      :title="link.url"
    >
      <div
        class="
          w-56 h-56 md:w-44 md:h-44 flex rounded-xl md:rounded-md justify-center items-center
          portal-accent-background
          mb-8
        "
      >
        <span
          class="text-30 md:text-24 text-[color:var(--cp-accent-most-readable)]"
          :class="link.icon"
        />
      </div>
      <span
        class="
          portal-link-foreground
          font-medium leading-tight lg:font-medium
          text-13 lg:leading-tight
          portal-paragraph-font
          max-w-full break-words drop-shadow-xl
        "
      >{{ link.label }}</span>
    </b-link>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'ProjectQuickLink',
  props: {
    icon: {
      type: String,
      default: 'icon-util-link',
    },
    link: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const linkRef = ref(null)

    return {
      linkRef,
    }
  },
})
</script>
