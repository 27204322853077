
<FeathersVuexFind
  v-slot="{ items, isFindPending }"
  service="profiles"
  :params="findParams"
  :query-when="notInStore"
  v-bind="$attrs"
>
  <slot v-bind="{ profile: items[0] ?? null, isGetPending: isFindPending }" />
</FeathersVuexFind>
