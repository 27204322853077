
<div>
  <div class="relative">
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="middle"
    />
    <b-form-rating
      v-if="contentBlock.settings.selectedType === 'icon'"
      class="p-0 space-x-4 bg-transparent"
      no-border
      size="lg"
      :value="contentBlock.answer"
      :stars="steps"
      :disabled="isLocked"
      number
      @change="value => $emit('update', { answer: value, isDirty: true })"
    >
      <template
        slot="icon-full"
      >
        <span :class="fullRatingIcon" />
      </template>
      <template
        slot="icon-empty"
      >
        <span :class="outlineRatingIcon" />
      </template>
    </b-form-rating>
    <DocumentContentBlockQARatingNumber
      v-else
      :value="contentBlock.answer"
      :steps="steps"
      @update="data => $emit('update', data)"
    />
  </div>
  <div
    v-if="showLabel"
    class="flex mt-4 mb-8"
  >
    <div
      v-if="leftLabel"
      class="flex items-center justify-start flex-1"
    >
      <span
        :class="['inline-block px-5 py-2 rounded',
                 'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
      >{{ leftLabel }}</span>
    </div>
    <div
      v-if="centerLabel"
      class="flex items-center justify-center flex-1 text-center"
    >
      <span
        :class="['inline-block px-5 py-2 rounded',
                 'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
      >{{ centerLabel }}</span>
    </div>
    <div
      v-if="rightLabel"
      class="flex items-center justify-end flex-1"
    >
      <span
        :class="['inline-block px-5 py-2 rounded',
                 'leading-1 text-12 lg:text-15 bg-yellow-50 text-gray-900']"
      >{{ rightLabel }}</span>
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
