// Icons of popular apps and services
// This will be used on Project Apps and/or Quick Links

export const standardIcons = [
  { name: 'Docs & Files', icon: 'icon_v2-so_docs-files' },
  { name: 'Knowledge Base', icon: 'icon-document-knowledge-base' },
  { name: 'Document', icon: 'icon-document' },
  { name: 'Signoff', icon: 'icon-document-signoff' },
  { name: 'Progress Update', icon: 'icon-document-progress-update' },
  { name: 'Change Request', icon: 'icon-document-change-request' },
  { name: 'Contract', icon: 'icon-document-contract' },
  { name: 'Questionnaire', icon: 'icon-document-questionnair' },
  { name: 'Scope of work', icon: 'icon-document-sow' },
  { name: 'Reusable Blocks', icon: 'icon_v2-so_reusable-block' },
  { name: 'Proposal', icon: 'icon-document-proposal' },
  { name: 'Estimate', icon: 'icon-document-estimate' },
  { name: 'Action Items', icon: 'icon_v2-so_action-items' },
  {
    name: 'Account Badge Horizontal',
    icon: 'standard-account_badge_horizontal',
  },
  { name: 'Alert', icon: 'standard-alert' },
  { name: 'Alien', icon: 'standard-alien' },
  { name: 'Archive', icon: 'standard-archive' },
  { name: 'Auto fix', icon: 'standard-auto_fix' },
  { name: 'Bank', icon: 'standard-bank' },
  { name: 'Barcode', icon: 'standard-barcode' },
  { name: 'Book open variant', icon: 'standard-book_open_variant' },
  { name: 'Book variant multiple', icon: 'standard-book_variant_multiple' },
  { name: 'Briefcase', icon: 'standard-briefcase' },
  { name: 'Brush', icon: 'standard-brush' },
  { name: 'Bugle', icon: 'standard-bugle' },
  { name: 'Bulletin board', icon: 'standard-bulletin_board' },
  { name: 'Bullhorn', icon: 'standard-bullhorn' },
  { name: 'Business center', icon: 'standard-business_center' },
  { name: 'Camcorder', icon: 'standard-camcorder' },
  { name: 'Camera', icon: 'standard-camera' },
  { name: 'Cart', icon: 'standard-cart' },
  { name: 'Cash_usd', icon: 'standard-cash_usd' },
  { name: 'Cellphone link', icon: 'standard-cellphone_link' },
  { name: 'Chart arc', icon: 'standard-chart_arc' },
  { name: 'Chart bar', icon: 'standard-chart_bar' },
  { name: 'Chart line', icon: 'standard-chart_line' },
  { name: 'Clock', icon: 'standard-clock' },
  { name: 'Clock outline', icon: 'standard-clock_outline' },
  { name: 'Cloud', icon: 'standard-cloud' },
  { name: 'Comma box', icon: 'standard-comma_box' },
  { name: 'Console line', icon: 'standard-console_line' },
  { name: 'Contacts', icon: 'standard-contacts' },
  { name: 'Creation', icon: 'standard-creation' },
  { name: 'Crown', icon: 'standard-crown' },
  { name: 'Cursor click', icon: 'standard-cursor_default_click' },
  { name: 'Database', icon: 'standard-database' },
  { name: 'Death star variant', icon: 'standard-death_star_variant' },
  { name: 'Diamond_stone', icon: 'standard-diamond_stone' },
  { name: 'Directions', icon: 'standard-directions' },
  { name: 'Domain', icon: 'standard-domain' },
  { name: 'Draw', icon: 'standard-draw' },
  { name: 'Earth', icon: 'standard-earth' },
  { name: 'Email_multiple', icon: 'standard-email_multiple' },
  { name: 'Emoticon', icon: 'standard-emoticon' },
  { name: 'Emoticon_frown', icon: 'standard-emoticon_frown' },
  { name: 'Error', icon: 'standard-error' },
  { name: 'Error_outline', icon: 'standard-error_outline' },
  { name: 'Event_note', icon: 'standard-event_note' },
  { name: 'Extension', icon: 'standard-extension' },
  { name: 'Eye_circle', icon: 'standard-eye_circle' },
  { name: 'Favorite', icon: 'standard-favorite' },
  { name: 'Fiber_new', icon: 'standard-fiber_new' },
  { name: 'Fire', icon: 'standard-fire' },
  { name: 'Flag', icon: 'standard-flag' },
  { name: 'Flash', icon: 'standard-flash' },
  { name: 'Flight', icon: 'standard-flight' },
  { name: 'Format_color_fill', icon: 'standard-format_color_fill' },
  { name: 'Gauge_full', icon: 'standard-gauge_full' },
  { name: 'Gift_outline', icon: 'standard-gift_outline' },
  { name: 'Hand_heart', icon: 'standard-hand_heart' },
  { name: 'Hand_left', icon: 'standard-hand_left' },
  { name: 'Hand_pointing_up', icon: 'standard-hand_pointing_up' },
  { name: 'Headset', icon: 'standard-headset' },
  { name: 'Heart', icon: 'standard-heart' },
  { name: 'Home', icon: 'standard-home' },
  { name: 'Human_greeting', icon: 'standard-human_greeting' },
  { name: 'Information_variant', icon: 'standard-information_variant' },
  { name: 'key', icon: 'standard-key' },
  { name: 'keyboard_voice', icon: 'standard-keyboard_voice' },
  { name: 'language', icon: 'standard-language' },
  { name: 'layers', icon: 'standard-layers' },
  { name: 'library', icon: 'standard-library' },
  { name: 'lightbulb_on', icon: 'standard-lightbulb_on' },
  { name: 'lightbulb_outline', icon: 'standard-lightbulb_outline' },
  { name: 'link_variant', icon: 'standard-link_variant' },
  { name: 'local_activity', icon: 'standard-local_activity' },
  { name: 'local_atm', icon: 'standard-local_atm' },
  { name: 'local_offer', icon: 'standard-local_offer' },
  { name: 'local_phone', icon: 'standard-local_phone' },
  { name: 'lock', icon: 'standard-lock' },
  { name: 'lock_open', icon: 'standard-lock_open' },
  { name: 'map', icon: 'standard-map' },
  { name: 'movie', icon: 'standard-movie' },
  {
    name: 'package_variant_closed',
    icon: 'standard-package_variant_closed',
  },
  { name: 'phone_android', icon: 'standard-phone_android' },
  { name: 'poll', icon: 'standard-poll' },
  { name: 'publ24px', icon: 'standard-publ24px' },
  { name: 'receipt', icon: 'standard-receipt' },
  { name: 'security_48px', icon: 'standard-security_48px' },
  { name: 'shopping_cart', icon: 'standard-shopping_cart' },
  { name: 'star', icon: 'standard-star' },
  { name: 'store_mall_directory', icon: 'standard-store_mall_directory' },
  { name: 'subscriptions', icon: 'standard-subscriptions' },
  { name: 'thumb_up', icon: 'standard-thumb_up' },
  { name: 'timeline', icon: 'standard-timeline' },
  { name: 'touch_app', icon: 'standard-touch_app' },
  { name: 'trending_up', icon: 'standard-trending_up' },
  { name: 'videocam', icon: 'standard-videocam' },
  { name: 'visibility', icon: 'standard-visibility' },
  { name: 'warning', icon: 'standard-warning' },
  { name: 'widgets', icon: 'standard-widgets' },
  { name: 'work', icon: 'standard-work' },
]

