
<ul
  v-if="hasChildren"
  :key="node.id"
>
  <SidebarTreeItem
    v-for="(child, index) in node.children"
    v-slot="{ isNode, isEmpty, isLeaf, isExpanded, isActive, toggleCollapse, onItemClick }"
    :key="child.id"
    :node="child"
    :level="level"
    :index="index"
    :expand-on-click="expandOnClick"
    :expand-root="expandRoot"
    @open-parent="open"
  >
    <li
      v-if="isLeaf || !isEmpty || !hideEmptyNodes"
    >
      <div
        class="group mb-4"
        :class="[
          (((isLeaf || !isEmpty || !disableEmptyNodes) && isActive) || child.id === selected)
            ? cssClasses.activeItemClass
            : cssClasses.itemClass,
          { 'opacity-50 pointer-events-none': isNode && isEmpty && disableEmptyNodes },
        ]"
        :style="level > 0 ? `padding-left: ${level}rem` : null"
      >
        <!-- ITEM CONTENT -->
        <div
          class="grow min-w-0 flex items-center"
          @click="onItemClick"
        >
          <!-- :class="{ '': isLeaf && parentContainsNodes }" -->
          <slot
            :name="child.slot"
            v-bind="{
              isNode,
              isEmpty,
              isLeaf,
              isActive,
              level,
              index,
              isExpanded: isActive || (isNode && !isEmpty && isExpanded),
              node: child,
            }"
          />
        </div>
        <!-- COLLAPSE / EXPAND BUTTON -->
        <ButtonTransparent
          v-if="isNode"
          class="text-gray-400 w-12 h-20 flex items-center pl-0 pr-6"
          :class="{
            'hover:text-gray-900': !isEmpty,
            'invisible': isEmpty,
          }"
          @click="toggleCollapse"
        >
          <span
            class="
                inline-block transition duration-200 text-24
                ease-in-out transform icon_v2-so_direction-down
              "
            :class="{ '-rotate-180': isExpanded }"
          />
        </ButtonTransparent>
        <!-- OR EMPTY SPACE -->
        <div v-else class="w-16 shrink-0"></div>
      </div>
      <!-- <b-collapse v-if="!isEmpty" :visible="isExpanded"> -->
      <!-- SHOW CHILDREN IF THEY EXIST -->
      <SidebarTree
        v-if="!isEmpty"
        v-show="isExpanded"
        :is-root="false"
        :node="child"
        :level="level + 1"
        :item-class="cssClasses.itemClass"
        :active-item-class="cssClasses.activeItemClass"
        :selected="selected"
        :hide-empty-nodes="hideEmptyNodes"
        :disable-empty-nodes="disableEmptyNodes"
        :expand-on-click="expandOnClick"
        @open="open"
      >
        <template
          v-for="slot in Object.keys($scopedSlots)"
          #[slot]="scope"
        >
          <slot :name="slot" v-bind="scope" />
        </template>
      </SidebarTree>
      <!-- </b-collapse> -->
    </li>
  </SidebarTreeItem>
</ul>
