<template>
  <div
    class="
    text-gray-400 text-13
    bg-page p-16 -ml-16 -mr-16
      print:bg-transparent
      print:p-0
      print:ml-0
      print:mr-0
    "
    :class="$style['printMarginTop']"
    :style="cssVars"
  >
    <div class="print:hidden">
      This content block will force a page break in the PDF or print version
      of the document with a top margin of <span class="text-green-600 font-semibold">{{ styling.printMarginTop }}px</span> or <span class="text-green-600 font-semibold">{{ pxTocm }}</span>
    </div>
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api'

export default {
  name: 'DocumentContentBlockPageBreak',
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const document = inject('document')

    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const cssVars = computed(() => ({
      '--printMarginTop': `${props.styling.printMarginTop}px`,
    }))

    const pxTocm = computed(() => `${parseFloat(props.styling.printMarginTop * 0.0264583333).toFixed(2)}cm`)

    // const pxTocm = () => `${props.styling.printMarginTop * 0.0264583333}cm`

    return {
      isEditable,
      document,
      cssVars,
      pxTocm,
    }
  },
}
</script>

<style lang="postcss" module>
@media print {
 .printMarginTop {
    height: var(--printMarginTop);
  }
}

</style>
