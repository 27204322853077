
<b-dropdown
  menu-class="pl-8 pr-8 pb-8 pt-8 min-w-0"
  toggle-class="p-0 editor-toolbar-button hover:bg-gray-500 hover:bg-opacity-10"
  variant="transparent"
  no-caret
>
  <template #button-content>
    <div
      title="Heading"
      :class="[
        'h-32 w-32 rounded relative text-white  text-sm flex justify-center items-center',
        isActive && 'bg-green-500 hover:bg-green-600 hover:bg-opacity-100',
      ]"
    >
      <span
        v-if="isActive"
        :class="[
          'w-24 text-24',
          `icon-toolbar-heading-${activeHeading}`,
        ]"
      ></span>
      <span
        v-else
        class="w-24 text-24 icon-toolbar-heading text-white"
      ></span>
      <span :class="[$style['toolbar-dropdown']]"></span>
    </div>
  </template>

  <div class="flex flex-row items-center space-x-8 min-w-0">
    <button
      :class="[$style['heading-item']]"
      @click="selectHeading(activeHeading)"
    >
      <span class="w-24 text-24 icon-toolbar-heading-clear" />
    </button>
    <button
      v-for="heading in headings"
      :key="heading"
      :class="[
        $style['heading-item'],
        Boolean(activeHeading === heading) && $style['active-heading'],
      ]"
      @click="selectHeading(heading)"
    >
      <span
        :class="[
          'w-24 text-24',
          `icon-toolbar-heading-${heading}`,
        ]"
      ></span>
    </button>
  </div>
</b-dropdown>
