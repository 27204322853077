<template>
  <SidebarTreeLink
    :id="node.id"
    :to="to"
    activate-on-child-route
  >
    <DocumentComputedData
      v-slot="{ title, icon }"
      :document="node.data.document"
      :is-open="isOpen"
    >
      <SidebarItemBasic :icon="icon" :label="title" />
    </DocumentComputedData>
  </SidebarTreeLink>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useDocumentLink } from '@/v2/services/documents/compositions'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import SidebarTreeLink from '@/components/Sidebar/SidebarTreeLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'

export default defineComponent({
  name: 'SidebarTreeItemFolder',
  components: {
    SidebarTreeLink,
    SidebarItemBasic,
    DocumentComputedData,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const document = computed(() => props.node?.data.document)
    const isPinnedFolder = computed(() => Boolean(props.node?.data.isPinnedFolder))
    const to = useDocumentLink(document)

    return {
      to,
      isPinnedFolder,
    }
  },
})
</script>
