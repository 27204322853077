<template>
  <transition name="modal">
    <div
      v-if="state.mediaIndex !== null"
      class="vgs"
      @click="close"
    >
      <div class="vgs__header">
        <!-- Position / Count -->
        <div
          v-if="files"
          class="vgs__gallery__title opacity-75"
        >
          <template v-if="files.length > 1">
            {{ state.mediaIndex + 1 }} <span class="opacity-50">/ {{ files.length }}</span>
          </template>
        </div>
        <!-- Close -->
        <button
          type="button"
          class="vgs__close"
          @click="close"
        >
          <span class="icon_v2-so_close w-24 text-24" />
        </button>
      </div>
      <!-- Previous Button -->
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__prev"
        @click.stop="onPrev"
      >
        <span class="icon-arrow-left w-24 text-24" />
      </button>
      <!-- --------------- -->
      <!-- Image Displayed -->
      <!-- --------------- -->
      <div
        v-if="files"
        class="vgs__container"
      >
        <transition
          name="fade"
          mode="out-in"
          appear
        >
          <img
            v-if="mediaType === 'image'"
            :key="mediaUrl"
            class="vgs__container__img"
            :src="mediaUrl"
            :alt="alt"
            @click.stop="onNext"
          />
          <!-- <div
            v-if="mediaType==='video'"
            :key="mediaUrl"
            class="relative w-[90vw] h-auto aspect-w-16 aspect-h-9
            flex-1 flex-grow p-0 m-0 bg-black"
            @click.stop=""
          >
            <b-embed
              type="video"
              controls
              allowfullscreen
            >
              <source :src="mediaUrl" />
            </b-embed>
          </div> -->

          <AudioPlayer
            v-if="mediaType==='audio'"
            :key="mediaUrl"
            :file="files[state.mediaIndex]"
          />
          <VideoPlayer
            v-if="mediaType==='video'"
            :key="mediaUrl"
            :file="files[state.mediaIndex]"
          />
          <div
            v-if="mediaType==='pdf'"
            :key="mediaUrl"
            class="relative w-[80vw] flex-1 flex-grow p-0 m-0 flex flex-col items-start justify-start
            overflow-hidden"
            @click.stop=""
          >
            <PDFViewer
              class="w-[80vw] h-auto"
              :source="mediaUrl"
            />
          </div>
          <slot />
        </transition>
      </div>
      <!-- --------------- -->
      <!-- Image Displayed -->
      <!-- --------------- -->
      <!-- Next Button -->
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__next"
        @click.stop="onNext"
      >
        <span class="icon-arrow-right w-24 text-24" />
      </button>
      <!-- Footer Gallery -->
      <div
        v-if="isMultiple"
        ref="gallery"
        class="vgs__gallery"
        @click.stop
      >
        <!-- Position / Count -->
        <div class="vgs__gallery__mask" />
        <!-- Thumbnails Gallery -->
        <div
          v-if="filesWithTypes"
          class="vgs__gallery__container"
          :style="{
            transform: 'translate(' + state.galleryXPos + 'px, 0)',
          }"
        >
          <div
            v-for="(file, i) in filesWithTypes"
            :key="i"
            class="vgs__gallery__container__img relative"
            :class="{ 'vgs__gallery__container__img--active': i === state.mediaIndex }"
          >
            <img
              v-if="file.mediaType === 'image'"
              :src="typeof file === 'string' ? file : file.url"
              :alt="typeof file === 'string' ? '' : file.alt"
              @click.stop="onClickThumb(file, i)"
            />
            <div
              v-if="file.mediaType==='video'"
              class="media-thumbnail"
              @click.stop="onClickThumb(file, i)"
            >
              <span class="standard-camcorder text-32 w-32 opacity-70 text-white"></span>
              <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">Video File</span> -->
            </div>
            <div
              v-if="file.mediaType==='audio'"
              class="media-thumbnail"
              @click.stop="onClickThumb(file, i)"
            >
              <span class="standard-headset text-32 w-32 opacity-70 text-white"></span>
              <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">Audio File</span> -->
            </div>
            <div
              v-if="file.mediaType==='pdf'"
              class="media-thumbnail"
              @click.stop="onClickThumb(file, i)"
            >
              <span class="icon-document-contract text-32 w-32 opacity-70 text-white"></span>
              <!-- <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">PDF Document</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'
import { computed, onMounted, onUnmounted, reactive, ref, toRef, watch } from '@vue/composition-api'
import PDFViewer from '@/components/Blocks/Media/PDFViewer.vue'
import AudioPlayer from '@/components/Blocks/Media/AudioPlayer.vue'
import VideoPlayer from '@/components/Blocks/Media/VideoPlayer.vue'

const getMediaType = file => {
  if (typeof file === 'string') {
    return '';
  }

  const contentType = file?.contentType ?? '';

  switch (true) {
    case contentType.startsWith('image/'):
      return 'image';
    case contentType.startsWith('audio/'):
      return 'audio';
    case contentType.startsWith('video/'):
      return 'video';
    case contentType.startsWith('application/pdf'):
      return 'pdf';
    default:
      return '';
  }
}

export default {
  name: 'LightboxGallery',
  components: {
    PDFViewer,
    AudioPlayer,
    VideoPlayer,
  },
  props: {
    files: {
      type: Array,
      required: true,
      default: () => [],
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    // -- state
    const gallery = ref(null)

    const state = reactive({
      mediaIndex: props.index,
      image: null,
      galleryXPos: 0,
      thumbnailWidth: 124,
    })

    // -- computed
    const mediaType = computed(() => {
      const file = props.files[state.mediaIndex]
      return getMediaType(file)
    })

    const mediaUrl = computed(() => {
      const file = props.files[state.mediaIndex]
      return typeof file === 'string' ? file : file.url
    })

    const filesWithTypes = computed(() => props.files.map(file => ({
      ...file,
      mediaType: getMediaType(file),
    })))

    const fileName = computed(() => {
      const file = props.files[state.mediaIndex]
      return typeof file === 'string' ? file : (file?.name ?? 'file');
    })

    const alt = computed(() => {
      const file = props.files[state.mediaIndex]
      return typeof file === 'string' ? 'media' : (file?.alt ?? 'media');
    })

    const isMultiple = computed(() => props.files.length > 1)

    // -- methods
    const close = () => {
      state.mediaIndex = null
      context.emit('close', { mediaIndex: state.mediaIndex })
    }

    const updateThumbails = () => {
      if (!gallery.value) {
        return
      }
      const galleryWidth = gallery.value.clientWidth
      const currThumbsWidth = state.mediaIndex * state.thumbnailWidth
      const maxThumbsWidth = props.files.length * state.thumbnailWidth
      const centerPos = Math.floor(galleryWidth / (state.thumbnailWidth * 2))
        * state.thumbnailWidth
      // Prevent scrolling of files if not needed

      if (maxThumbsWidth < galleryWidth) {
        return
      }
      if (currThumbsWidth < centerPos) {
        state.galleryXPos = 0
      } else if (currThumbsWidth > maxThumbsWidth - galleryWidth + centerPos) {
        state.galleryXPos = -(
          maxThumbsWidth
          - galleryWidth
          - 20
        )
      } else {
        state.galleryXPos = -(state.mediaIndex * state.thumbnailWidth) + centerPos
      }
    }

    const onPrev = () => {
      if (state.mediaIndex === null) {
        return
      }

      if (state.mediaIndex > 0) {
        state.mediaIndex -= 1
      } else {
        state.mediaIndex = props.files.length - 1
      }

      updateThumbails()
    }

    const onNext = () => {
      if (state.mediaIndex === null) {
        return
      }

      if (state.mediaIndex < props.files.length - 1) {
        state.mediaIndex += 1
      } else {
        state.mediaIndex = 0
      }

      updateThumbails()
    }

    const onClickThumb = (image, index) => {
      state.mediaIndex = index
      updateThumbails()
    }

    const onKeyDown = e => {
      if (!isMultiple.value) {
        return
      }

      if (e.code === 'ArrowLeft') {
        onPrev()
      } else if (e.code === 'ArrowRight') {
        onNext()
      } else if (e.code === 'Escape') {
        close()
      }
    }

    onMounted(() => {
      window.addEventListener('keydown', onKeyDown)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', onKeyDown)
    })

    watch(toRef(props, 'index'), (newVal, oldVal) => {
      state.mediaIndex = newVal
      // updateThumbails when popup
      if (oldVal == null && newVal != null) {
        Vue.nextTick(() => {
          updateThumbails()
        })
      }
    })

    return {
      // -- state
      state,
      gallery,

      // -- computed
      mediaType,
      mediaUrl,
      filesWithTypes,
      fileName,
      alt,
      isMultiple,

      // -- methods
      close,
      onPrev,
      onNext,
      onClickThumb,
    }
  },
}
</script>

<style lang="scss">
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black: #000;
$white: #fff;
$radius-medium: 8px;
$radius-large: 12px;
// Breakpoints
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
@mixin respond-to($media) {
  @if $media==xs {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $media==sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media==md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media==lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
}
@mixin modal-base() {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 3147483003;
}
@mixin modal-mask() {
  @include modal-base();
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: $black-alpha-80;
  display: table;
}
.vgs {
  @include modal-mask();
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

  }

  &__close {
    color: #fff;
    background-color: transparent;
    border: none;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 1000;
    margin-left: -48px;
    &:focus {
      outline: 0;
    }
  }
  &__prev,
  &__next {
    position: absolute;
    top: 0;
    bottom: auto;
    height: 100%;
    width: 50px;
    z-index: 999;
    cursor: pointer;
    font-size: 40px;
    color: #fff;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    padding-bottom: 88px;
    &:focus {
      outline: 0;
    }
    &:active {
      opacity: .6;
      transition: all 200ms ease-in-out;
    }
    @include respond-to(xs) {
      height: 60px;
      width: 60px;
      top: auto;
      bottom: 8px;
      background: rgba(0,0,0,0.44);
      border-radius: 50%;
      padding-bottom: 0;
    }
  }
  &__prev {
    left: 0;
    &:hover {
      transform: translateX(-3px);
    }
    &:active {
      transform: translateX(-6px);
    }
    @include respond-to(xs) {
      left: 8px;
    }
  }
  &__next {
    right: 0;

    &:hover {
      transform: translateX(3px);
    }
    &:active {

      transform: translateX(6px);
    }
    @include respond-to(xs) {
      right: 8px;
    }
  }
  &__container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    overflow: hidden;
    // max-width: 100vh;
    margin: 0 auto;
    height: auto;
    width: 100%;
    // max-height: 800px;
    border-radius: $radius-large;
    background-color: transparent;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include respond-to(xs) {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
    &__img {
      object-fit: contain;
      // max-width: 100%;
      // max-height: 100%;
      max-width: 80%;
      max-height: 80%;
      &.fade-enter-active,
      &.fade-leave-active {
        transition: opacity .4s;
      }
      &.fade-enter,
      &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transition: opacity .2s;
      }
    }
  }
}
.vgs__gallery {
  @include respond-to(xs) {
    display: none;
  }
  overflow-x: visible;
  position: relative;
  margin: auto;
  width: 100vw;
  white-space: nowrap;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 1000;
  margin-top: 16px;
  flex-shrink: 0;
  box-shadow: 0px -3px 40px 11px rgba(0,0,0,0.33);
  background: theme('colors.gray.900');
  &__title {
    color: $white;
    text-align: center;
    flex-grow: 1;
  }
  &__mask {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index: 2;
    pointer-events: none;
    background: theme('colors.gray.900');
    mask-image: linear-gradient(
      90deg,
      black 0%,
      transparent 3%,
      transparent 97%,
      black 100%
    );
  }
  &__container {
    overflow-x: visible;
    position: relative;
    display: block;
    height: auto;
    white-space: nowrap;
    transition: all 200ms ease-in-out;
    padding: 16px;
    width: 100%;
    &__img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      display: inline-block;
      float: none;
      margin-right: 20px;
      cursor: pointer;
      opacity: 1;
      flex: 0 0 auto;
      border-radius: $radius-medium;
      transition: all 200ms ease-in-out;
      &:hover {
        box-shadow: 0 0 0 4px theme('colors.green.500');
      }
    }
    &__img--active {
      width: 100px;
      display: inline-block;
      float: none;
      opacity: 0.6;
      box-shadow: 0 0 0 2px theme('colors.green.500');
    }
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.media-thumbnail {
  @apply absolute inset-0 flex flex-col justify-center items-center flex-1 flex-grow;
  @apply bg-darkGray-600;
  @apply rounded-lg overflow-hidden;
}
</style>
