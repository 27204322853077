
<div class="relative group-event mb-24">
  <!-- HEADER -->
  <div
    class="
      mb-8 font-medium
      text-gray-800 dark:text-darkGray-400
      portal-paragraph-font
    "
  >
    <slot name="label" />
    <p class="event-timeline-circle">
      <span :class="icon" />
    </p>
  </div>
  <ul class="flex flex-col portal-paragraph-font space-y-12 md:space-y-6 text-gray-700">
    <li v-for="event in notification.events" :key="event._id">
      <div class="cursor-pointer" @click="$emit('event-click', event)">
        <slot name="event" v-bind="{ event, document: event.document$, isClientPortal }" />
      </div>
    </li>
  </ul>
</div>
