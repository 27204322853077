<template>
  <div
    class="p-32 flex flex-col items-center rounded-lg"
  >
    <h2
      class="text-64 pr-16 inline-flex rounded-md px-16 py-8
      font-bold text-white "
      :class="{
        'bg-green-500': variant === 'info',
        'bg-orange-500': variant === 'warning',
        'bg-red-500': variant === 'danger',
      }"
    >
      {{ title }}
    </h2>
    <div class="text-18 text-center mt-16 opacity-80">
      <slot />
      <div v-if="error" class="mt-8 opacity-20">{ {{ error.message || error }} }</div>
    </div>
  </div>
</template>

<script>
import { flipIncludes } from 'ramda-extension'

export default {
  props: {
    title: {
      type: String,
      default: 'Whoops!',
    },
    error: {
      type: [Object, String, Error],
      default: null,
    },
    variant: {
      type: String,
      default: 'info',
      validator: flipIncludes(['info', 'warning', 'danger']),
    },
  },
}
</script>
