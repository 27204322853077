
<FileUploader
  v-slot="{ selectFiles }"
  accept="image/*"
  :multiple="false"
  @file-done="onImageUploadDone"
  @file-error="onImageUploadError"
>
  <div class="relative editor-content">
    <bubble-menu
      :editor="editor"
      :tippy-options="{
        maxWidth: 'none',
        hideOnClick: true,
        onShow: onBubbleMenuShow,
        onHide: onBubbleMenuHide,
      }"
      class="flex items-center p-4 mb-4 space-x-4 leading-none text-white bg-gray-800 rounded-md shadow max-w-none print:hidden"
      :should-show="bubbleMenuShouldShow"
    >
      <!-- Bold -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.bold)"
        :is-active="editor.isActive('bold')"
        @click="editor.chain().toggleBold().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-bold" />
      </EditorToolbarButton>

      <!-- Italic -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.italic)"
        :is-active="editor.isActive('italic')"
        @click="editor.chain().toggleItalic().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-italic" />
      </EditorToolbarButton>

      <!-- Highlight -->
      <EditorToolbarHighlight
        v-if="editorFeatures.includes(features.highlight)"
        :is-active="editor.isActive('colorHighlight')"
        :active-color="
          checkNestedMarkAttributes('colorHighlight', 'backgroundColor')
        "
        @select="item => setColorHighlight(item)"
      />

      <!-- Link -->
      <DocumentNodeEditorToolbarPopoverLight
        v-if="editorFeatures.includes(features.link) && bubbleMenuShown"
        :width="420"
        placement="bottom"
        :target-id="componentID"
        triggers="click blur"
        class-name="editor-toolbar-button"
        slim
      >
        <template #button-content>
          <!-- Button before link menu is set -->
          <EditorToolbarButton
            v-if="editorFeatures.includes(features.link)"
            :is-active="editor.isActive('link')"
            has-dropdown
            @click="showLinkMenu(editor.getAttributes('link'))"
          >
            <span class="relative w-24 text-24 icon_v2-so_link" />
          </EditorToolbarButton>
        </template>
        <template #default>
          <form
            class="flex items-center px-8 py-6 space-x-8"
            @mouseenter="toggleIsPopoverActive(true)"
            @mouseleave="toggleIsPopoverActive(false)"
            @submit.prevent="setLink(linkUrl)"
          >
            <b-input
              ref="linkInput"
              v-model="linkUrl"
              :class="['form-control-sm', 'flex-1']"
              type="text"
              placeholder="https://"
              @keydown.esc="hideLinkMenu"
            />
            <b-button
              v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Confirm link`"
              :disabled="!linkUrl"
              variant="primary"
              class="inline-flex items-center justify-center p-0 text-green-600 rounded-full w-28 h-26"
              type="submit"
            >
              <span class="icon_v2-so_tick" />
            </b-button>
            <b-button
              v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Remove link`"
              :disabled="!linkUrl"
              variant="icon-isolated"
              class="inline-flex items-center justify-center p-0 text-gray-600 rounded-full w-28 h-26"
              type="button"
              @click="editor.chain().focus().unsetLink().run()"
            >
              <span class="icon_v2-so_link-unlink" />
            </b-button>
          </form>
        </template>
      </DocumentNodeEditorToolbarPopoverLight>

      <DocumentNodeEditorToolbarSeparator
        v-if="editorFeatures.includes(features.headings)"
      />

      <!-- Headings -->
      <EditorToolbarHeading
        v-if="editorFeatures.includes(features.headings)"
        :is-active="editor.isActive('heading')"
        :active-heading="checkNestedNodeAttributes('heading', 'level')"
        @select="
          item => editor.chain().toggleHeading({ level: item }).focus().run()
        "
      />
      <!-- Task List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.taskList)"
        :is-active="editor.isActive('taskList')"
        @click="editor.chain().toggleTaskList().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-check" />
      </EditorToolbarButton>

      <!-- Code -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.code)"
        :is-active="editor.isActive('code')"
        @click="editor.chain().toggleCode().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-code" />
      </EditorToolbarButton>

      <!-- Blockquote -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.blockquote)"
        :is-active="editor.isActive('blockquote')"
        @click="editor.chain().toggleBlockquote().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-quote" />
      </EditorToolbarButton>

      <!-- Bullet List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.bulletList)"
        :is-active="editor.isActive('bulletList')"
        @click="editor.chain().toggleBulletList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
      </EditorToolbarButton>

      <!-- Ordered List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.orderedList)"
        :is-active="editor.isActive('orderedList')"
        @click="editor.chain().toggleOrderedList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-number-list" />
      </EditorToolbarButton>

      <!-- Strike -->
      <!-- <EditorToolbarButton
      v-if="editorFeatures.includes(features.strike)"
      :is-active="editor.isActive('strike')"

      @click="editor.chain().toggleStrike().focus().run()"
    >
      <span class="relative w-24 text-24 icon-toolbar-strike" />
    </EditorToolbarButton> -->

      <DocumentNodeEditorToolbarSeparator
        v-if="editorFeatures.includes(features.textAlign)"
      />
      <!-- Align -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({ textAlign: 'left' })"
        @click="editor.chain().setTextAlign('left').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-left" />
      </EditorToolbarButton>
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({ textAlign: 'center' })"
        @click="editor.chain().setTextAlign('center').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-center" />
      </EditorToolbarButton>
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.textAlign)"
        :is-active="editor.isActive({ textAlign: 'right' })"
        @click="editor.chain().setTextAlign('right').focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-align-right" />
      </EditorToolbarButton>
    </bubble-menu>

    <!-- Editor Content -->
    <editor-content class="text-editor" :editor="editor" />

    <floating-menu
      v-if="editor && showFloatingMenu"
      :tippy-options="{
        maxWidth: 'none',
        hideOnClick: false,
      }"
      :editor="editor"
      class="flex items-center p-4 ml-8 space-x-4 leading-none text-white bg-gray-800 rounded-md shadow max-w-none"
    >
      <div
        v-if="editorFeatures.includes(features.headings)"
        class="flex flex-row items-center space-x-4"
      >
        <EditorToolbarButton
          v-for="heading in [2, 3, 4, 5]"
          :key="`add-heading-${heading}`"
          :is-active="editor.isActive('heading', { level: heading })"
          @click="
            editor.chain().toggleHeading({ level: heading }).focus().run()
          "
        >
          <span class="w-24 font-extrabold text-16">H{{ heading }}</span>
        </EditorToolbarButton>
      </div>
      <!-- Bullet List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.bulletList)"
        :is-active="editor.isActive('bulletList')"
        @click="editor.chain().toggleBulletList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
      </EditorToolbarButton>

      <!-- Ordered List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.orderedList)"
        :is-active="editor.isActive('orderedList')"
        @click="editor.chain().toggleOrderedList().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-number-list" />
      </EditorToolbarButton>

      <!-- Task List -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.taskList)"
        :is-active="editor.isActive('taskList')"
        @click="editor.chain().toggleTaskList().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-check" />
      </EditorToolbarButton>

      <!-- Page Break -->
      <EditorToolbarButton
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Add Page Break'"
        @click="editor.chain().setPageBreak().focus().run()"
      >
        <span class="relative w-18 text-18 icon-page-break" />
      </EditorToolbarButton>

      <DocumentNodeEditorToolbarSeparator
        v-if="editorFeatures.includes(features.image)"
      />
      <!-- Image -->
      <EditorToolbarButton
        v-if="editorFeatures.includes(features.image)"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Add Image'"
        :is-active="editor.isActive('image')"
        @click="selectFiles"
      >
        <!-- @click="editor.chain().focus().setImage({ src: 'https://placeholder.com/wp-content/uploads/2019/06/flickr-license-search.webp' }).run()" -->
        <span class="relative icon_v2-so_image" />
      </EditorToolbarButton>
    </floating-menu>
  </div>
</FileUploader>
