
<DocumentGet
  :id="contentBlock.embeddedDocument"
  v-slot="{ document, isGetPending }"
  :params="{ embed: true }"
>
  <div
    :class="['XXS','XS','S','M'].includes(nodeWidth) && 'sm:px-24'"
  >
    <FileDocList
      v-if="!isGetPending && document"
      :document="document"
      :standalone="standalone"
      :show-analytics="showAnalytics"
      :node-width="nodeWidth"
      v-bind="$attrs"
      v-on="$listeners"
      @click.stop="enableFullBlockClick && openDocument(document)"
    />
  </div>
</DocumentGet>
