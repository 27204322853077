
<span
    :class="[
        $style['icon-graphic'],
        $style['icon-graphic-' + className],
        $style[type],
    ]"
>
    <SvgIcon :name="icon" :class="$style.icon" />
</span>
