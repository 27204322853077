<template>
  <div class="flex">
    <div v-if="hasCollections">
      <CategoryList
        title="Collections"
        :items="collections"
        :active-id="collection?.id"
        @select="onCollectionSelect"
      />
    </div>
    <div class="flex-1">
      <div
        :class="!noScroll && `
          lg:overflow-y-auto lg:flex lg:overscroll-contain lg:h-[calc(100vh-128px)]
          w-full
        `"
      >
        <ProjectTemplateList
          :templates="collection?.templates ?? []"
          @preview="template => $emit('preview', template)"
          @create="template => $emit('create', template)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useTemplates } from '@/v2/services/templates/compositions'
import CategoryList from '@/components/CategoryList.vue'
import ProjectTemplateList from '@/components/ProjectTemplateList.vue';

export default defineComponent({
  name: 'ProjectTemplates',
  components: {
    CategoryList,
    ProjectTemplateList,
  },
  props: {
    noScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const collection = ref(null)

    const { load, collections, hasCollections } = useTemplates()

    const loadCollections = async () => {
      await load()
      collection.value = collections.value[0] ?? null
    }

    const onCollectionSelect = selectedCollection => {
      collection.value = selectedCollection
    }

    loadCollections()

    return {
      collections,
      collection,
      onCollectionSelect,
      hasCollections,
    }
  },
})
</script>
