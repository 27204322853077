import { noop } from 'ramda-adjunct'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'

export default function useWindowSize() {
  const width = ref(null)
  const initialWidth = window.innerWidth

  const resize = () => {
    width.value = window.innerWidth
  }

  onMounted(() => {
    window.addEventListener('resize', resize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', resize)
  })

  return {
    width,
    resize,
    resizeEventListener: noop,
    removeResizeListener: noop,
    initialWidth,
  }
}
