
<DocumentNodeEditorToolbarPopoverLight
  :target-id="popoverTargetId"
  class-name="rounded hover:bg-gray-500 hover:bg-opacity-10 w-32 editor-toolbar-button"
  triggers="click"
  tooltip="Customize block"
  :close-on-click-outside="!preventPopoverClose"
>
  <template #button-content>
    <span class="w-24 text-24 icon-toolbar-settings" />
    <span :class="[$style['toolbar-dropdown']]" />
  </template>
  <template #default>
    <slot />
  </template>
</DocumentNodeEditorToolbarPopoverLight>
