<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="[
      'node-group-column',
    ]"
    :width="width"
    :content-class="node.styling.verticalAlign && `flex flex-col justify-${node.styling.verticalAlign}`"
    :inner-class="['px-0 lg:px-24 xl:px-32 print:h-full print:flex']"
  >
    <template v-if="!isEmpty">
      <div>
        <DocumentNode
          v-for="(nodeId, index) in children"
          :key="nodeId"
          :node-id="nodeId"
          :parent="node._id"
          :index="index"
          :is-first="index === 0"
          :is-last="index === children.length - 1"
        />
      </div>
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNode from '@/v2/features/document/documentNode/DocumentNode.vue'

export default {
  name: 'DocumentNodeGroupColumn',
  components: {
    DocumentNodeRenderer,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNode })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)

    return {
      children,
      isEmpty,
    }
  },
}
</script>
