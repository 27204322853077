<template>
  <FileUploader
    v-slot="{ selectFiles }"
    accept="image/*"
    :multiple="false"
    @file-done="onImageUploadDone"
    @file-error="onImageUploadError"
  >
    <div class="relative editor-content">
      <bubble-menu
        :editor="editor"
        :tippy-options="{
          maxWidth: 'none',
          hideOnClick: true,
          onShow: onBubbleMenuShow,
          onHide: onBubbleMenuHide,
        }"
        class="flex items-center p-4 mb-4 space-x-4 leading-none text-white bg-gray-800 rounded-md shadow max-w-none print:hidden"
        :should-show="bubbleMenuShouldShow"
      >
        <!-- Bold -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.bold)"
          :is-active="editor.isActive('bold')"
          @click="editor.chain().toggleBold().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-bold" />
        </EditorToolbarButton>

        <!-- Italic -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.italic)"
          :is-active="editor.isActive('italic')"
          @click="editor.chain().toggleItalic().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-italic" />
        </EditorToolbarButton>

        <!-- Highlight -->
        <EditorToolbarHighlight
          v-if="editorFeatures.includes(features.highlight)"
          :is-active="editor.isActive('colorHighlight')"
          :active-color="
            checkNestedMarkAttributes('colorHighlight', 'backgroundColor')
          "
          @select="item => setColorHighlight(item)"
        />

        <!-- Link -->
        <DocumentNodeEditorToolbarPopoverLight
          v-if="editorFeatures.includes(features.link) && bubbleMenuShown"
          :width="420"
          placement="bottom"
          :target-id="componentID"
          triggers="click blur"
          class-name="editor-toolbar-button"
          slim
        >
          <template #button-content>
            <!-- Button before link menu is set -->
            <EditorToolbarButton
              v-if="editorFeatures.includes(features.link)"
              :is-active="editor.isActive('link')"
              has-dropdown
              @click="showLinkMenu(editor.getAttributes('link'))"
            >
              <span class="relative w-24 text-24 icon_v2-so_link" />
            </EditorToolbarButton>
          </template>
          <template #default>
            <form
              class="flex items-center px-8 py-6 space-x-8"
              @mouseenter="toggleIsPopoverActive(true)"
              @mouseleave="toggleIsPopoverActive(false)"
              @submit.prevent="setLink(linkUrl)"
            >
              <b-input
                ref="linkInput"
                v-model="linkUrl"
                :class="['form-control-sm', 'flex-1']"
                type="text"
                placeholder="https://"
                @keydown.esc="hideLinkMenu"
              />
              <b-button
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Confirm link`"
                :disabled="!linkUrl"
                variant="primary"
                class="inline-flex items-center justify-center p-0 text-green-600 rounded-full w-28 h-26"
                type="submit"
              >
                <span class="icon_v2-so_tick" />
              </b-button>
              <b-button
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Remove link`"
                :disabled="!linkUrl"
                variant="icon-isolated"
                class="inline-flex items-center justify-center p-0 text-gray-600 rounded-full w-28 h-26"
                type="button"
                @click="editor.chain().focus().unsetLink().run()"
              >
                <span class="icon_v2-so_link-unlink" />
              </b-button>
            </form>
          </template>
        </DocumentNodeEditorToolbarPopoverLight>

        <DocumentNodeEditorToolbarSeparator
          v-if="editorFeatures.includes(features.headings)"
        />

        <!-- Headings -->
        <EditorToolbarHeading
          v-if="editorFeatures.includes(features.headings)"
          :is-active="editor.isActive('heading')"
          :active-heading="checkNestedNodeAttributes('heading', 'level')"
          @select="
            item => editor.chain().toggleHeading({ level: item }).focus().run()
          "
        />
        <!-- Task List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.taskList)"
          :is-active="editor.isActive('taskList')"
          @click="editor.chain().toggleTaskList().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-check" />
        </EditorToolbarButton>

        <!-- Code -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.code)"
          :is-active="editor.isActive('code')"
          @click="editor.chain().toggleCode().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-code" />
        </EditorToolbarButton>

        <!-- Blockquote -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.blockquote)"
          :is-active="editor.isActive('blockquote')"
          @click="editor.chain().toggleBlockquote().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-quote" />
        </EditorToolbarButton>

        <!-- Bullet List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.bulletList)"
          :is-active="editor.isActive('bulletList')"
          @click="editor.chain().toggleBulletList().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
        </EditorToolbarButton>

        <!-- Ordered List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.orderedList)"
          :is-active="editor.isActive('orderedList')"
          @click="editor.chain().toggleOrderedList().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-number-list" />
        </EditorToolbarButton>

        <!-- Strike -->
        <!-- <EditorToolbarButton
        v-if="editorFeatures.includes(features.strike)"
        :is-active="editor.isActive('strike')"

        @click="editor.chain().toggleStrike().focus().run()"
      >
        <span class="relative w-24 text-24 icon-toolbar-strike" />
      </EditorToolbarButton> -->

        <DocumentNodeEditorToolbarSeparator
          v-if="editorFeatures.includes(features.textAlign)"
        />
        <!-- Align -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.textAlign)"
          :is-active="editor.isActive({ textAlign: 'left' })"
          @click="editor.chain().setTextAlign('left').focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-align-left" />
        </EditorToolbarButton>
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.textAlign)"
          :is-active="editor.isActive({ textAlign: 'center' })"
          @click="editor.chain().setTextAlign('center').focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-align-center" />
        </EditorToolbarButton>
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.textAlign)"
          :is-active="editor.isActive({ textAlign: 'right' })"
          @click="editor.chain().setTextAlign('right').focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-align-right" />
        </EditorToolbarButton>
      </bubble-menu>

      <!-- Editor Content -->
      <editor-content class="text-editor" :editor="editor" />

      <floating-menu
        v-if="editor && showFloatingMenu"
        :tippy-options="{
          maxWidth: 'none',
          hideOnClick: false,
        }"
        :editor="editor"
        class="flex items-center p-4 ml-8 space-x-4 leading-none text-white bg-gray-800 rounded-md shadow max-w-none"
      >
        <div
          v-if="editorFeatures.includes(features.headings)"
          class="flex flex-row items-center space-x-4"
        >
          <EditorToolbarButton
            v-for="heading in [2, 3, 4, 5]"
            :key="`add-heading-${heading}`"
            :is-active="editor.isActive('heading', { level: heading })"
            @click="
              editor.chain().toggleHeading({ level: heading }).focus().run()
            "
          >
            <span class="w-24 font-extrabold text-16">H{{ heading }}</span>
          </EditorToolbarButton>
        </div>
        <!-- Bullet List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.bulletList)"
          :is-active="editor.isActive('bulletList')"
          @click="editor.chain().toggleBulletList().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-bullet-list" />
        </EditorToolbarButton>

        <!-- Ordered List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.orderedList)"
          :is-active="editor.isActive('orderedList')"
          @click="editor.chain().toggleOrderedList().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-number-list" />
        </EditorToolbarButton>

        <!-- Task List -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.taskList)"
          :is-active="editor.isActive('taskList')"
          @click="editor.chain().toggleTaskList().focus().run()"
        >
          <span class="relative w-24 text-24 icon-toolbar-check" />
        </EditorToolbarButton>

        <!-- Page Break -->
        <EditorToolbarButton
          v-b-tooltip.hover.top.v-info.dh0.ds200="'Add Page Break'"
          @click="editor.chain().setPageBreak().focus().run()"
        >
          <span class="relative w-18 text-18 icon-page-break" />
        </EditorToolbarButton>

        <DocumentNodeEditorToolbarSeparator
          v-if="editorFeatures.includes(features.image)"
        />
        <!-- Image -->
        <EditorToolbarButton
          v-if="editorFeatures.includes(features.image)"
          v-b-tooltip.hover.top.v-info.dh0.ds200="'Add Image'"
          :is-active="editor.isActive('image')"
          @click="selectFiles"
        >
          <!-- @click="editor.chain().focus().setImage({ src: 'https://placeholder.com/wp-content/uploads/2019/06/flickr-license-search.webp' }).run()" -->
          <span class="relative icon_v2-so_image" />
        </EditorToolbarButton>
      </floating-menu>
    </div>
  </FileUploader>
</template>

<script>
// --------------------------------------------------
// TYPES & HELPERS
// --------------------------------------------------
import { defineComponent } from '@vue/composition-api'
// --------------------------------------------------
// --------------------------------------------------
// ------------------- TIPTAP -----------------------
// --------------------------------------------------
// --------------------------------------------------
import { Editor, EditorContent, BubbleMenu, FloatingMenu, isTextSelection } from '@tiptap/vue-2'
// -----STANDARD EXTENSIONS-------------------------------
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
// -----IMAGES---------------------------------
import Image from '@tiptap/extension-image'
// -----TABLES--------------------------------------------
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
// -----CUSTOM EXTENSIONS---------------------------------
import { mapState, mapMutations } from 'vuex'
import { ColorHighlight } from '@/components/TextEditorExtensions/ColorHighlightV2'
import PageBreak from '@/components/TextEditorExtensions/PageBreak'

// VUEX STORE
import { EDITOR_FEATURES } from '@/types/editor'
import { useMsgBoxError } from '@/v2/lib/composition/useMsgBox'
// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import DocumentNodeEditorToolbarSeparator from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSeparator.vue'
import DocumentNodeEditorToolbarPopoverLight from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopoverLight.vue'
import FileUploader from '@/components/FileUploader/FileUploader.vue'
import EditorToolbarHighlight from './EditorToolbarHighlight.vue'
import EditorToolbarHeading from './EditorToolbarHeading.vue'
import EditorToolbarButton from './EditorToolbarButton.vue'
// --------------------------------------------------
// EDITOR FEATURES
// --------------------------------------------------
const defaultEditorFeatures = [
  EDITOR_FEATURES.highlight,
  EDITOR_FEATURES.headings,
  EDITOR_FEATURES.bulletList,
  EDITOR_FEATURES.image,
  EDITOR_FEATURES.orderedList,
  EDITOR_FEATURES.blockquote,
  EDITOR_FEATURES.code,
  EDITOR_FEATURES.link,
  EDITOR_FEATURES.bold,
  EDITOR_FEATURES.italic,
  EDITOR_FEATURES.strike,
  EDITOR_FEATURES.textAlign,
  EDITOR_FEATURES.taskList,
]

const BUBBLE_MENU_BLACKLIST = [
  'pageBreak',
];

export default defineComponent({
  name: 'TextEditor',
  components: {
    EditorContent,
    BubbleMenu,
    DocumentNodeEditorToolbarSeparator,
    EditorToolbarHighlight,
    EditorToolbarButton,
    EditorToolbarHeading,
    DocumentNodeEditorToolbarPopoverLight,
    FloatingMenu,
    FileUploader,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Write something ...',
    },
    autofocus: Boolean,
    showFloatingMenu: {
      type: Boolean,
      default: true,
    },
    editorFeatures: {
      type: Array,
      default: () => defaultEditorFeatures,
    },
  },
  setup() {
    const msgBoxError = useMsgBoxError()

    const onImageUploadError = (_, error) => {
      msgBoxError({
        title: 'File upload error',
        message: error.message,
      })
    }

    return {
      onImageUploadError,
    }
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          StarterKit.configure({
            heading: {
              levels: [1, 2, 3, 4, 5],
            },
          }),
          Link.configure({
            openOnClick: false,
          }),
          // new Underline(),
          Image,
          TableRow,
          TableHeader,
          TableCell,
          Placeholder.configure({
            emptyNodeClass: 'is-empty',
            placeholder: this.placeholder,
            showOnlyWhenEditable: true,
          }),
          Table.configure({
            resizable: true,
          }),
          ColorHighlight,
          PageBreak,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          TaskList,
          TaskItem,
        ],
        content: this.value,
        injectCSS: false,
        onUpdate: context => this.onUpdate(context),
        onBlur: context => this.onBlur(context),
        onFocus: context => this.onFocus(context),
        parseOptions: {
          preserveWhitespace: 'full',
        },
      }),
      linkUrl: null,
      bubbleMenuShown: false,
      isPopoverActive: false,
      features: EDITOR_FEATURES,
    }
  },
  computed: mapState('documentEditor', ['isTextEditorToolbarVisible']),
  watch: {
    value(newVal) {
      // TODO: //https://github.com/scrumpy/tiptap/issues/110
      if (newVal !== this.editor.getHTML()) {
        // for when text editor is emptied
        if (!newVal) {
          this.editor.commands.clearContent({
            emitUpdate: true,
          })
        } else {
          this.editor.commands.setContent(newVal)
        }
      }
      // so cursor doesn't jump to start on typing
      if (this.editor && newVal !== this.value) {
        this.editor.commands.setContent(newVal, true)
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => this.editor.focus())
    }
  },
  unMounted() {
    this.editor.destroy()
  },
  methods: {
    // modified version of BubbbleMenuView.shouldShow
    bubbleMenuShouldShow({ editor, view, state, from, to }) {
      const { doc, selection } = state
      const { empty } = selection

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

      // When clicking on a element inside the bubble menu the editor "blur" event
      // is called and the bubble menu item is focussed. In this case we should
      // consider the menu as part of the editor and keep showing the menu
      const bubbleMenuView = view.pluginViews.find(v => v.constructor.name === 'BubbleMenuView')
      const isChildOfMenu = bubbleMenuView?.element.contains(document.activeElement)
      // const isChildOfMenu = this.element.element.contains(document.activeElement)

      const hasEditorFocus = view.hasFocus() || isChildOfMenu

      if (!hasEditorFocus || empty || isEmptyTextBlock || !editor.isEditable) {
        return false
      }

      return !BUBBLE_MENU_BLACKLIST.some(name => editor.isActive(name));
    },
    cleanContent() {
      // Remove emtpy paragraphs
      // https://github.com/ueberdosis/tiptap/issues/154
      let content = this.editor.getHTML()
      const json = this.editor.getJSON().content
      if (
        Array.isArray(json)
        && json.length === 1
        && !Object.prototype.hasOwnProperty.call(json[0], 'content')
      ) {
        content = '' // or any other default value
      }
      return content
    },
    onUpdate() {
      !this.lazy && this.$emit('input', this.cleanContent())
    },
    onFocus(event) {
      this.$emit('focus', event)
    },
    onBlur() {
      const html = this.cleanContent()
      this.$emit('blur', html)
      this.lazy && this.$emit('input', html)
    },
    onImageUploadDone(_, { url }) {
      this.editor.chain().focus().setImage({ src: url }).run()
    },

    // Set and unset LINKS
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
    },
    hideLinkMenu() {
      this.linkUrl = null
    },
    setLink(url) {
      this.editor.chain().focus().setLink({ href: url }).run()
      this.hideLinkMenu()
    },

    onBubbleMenuShow() {
      this.bubbleMenuShown = true
      this.setTextEditorToolbarVisibility(true)
    },

    toggleIsPopoverActive(state) {
      // so the Tippy doesn't hide when you click within the Link popover
      this.isPopoverActive = state
    },

    // eslint-disable-next-line consistent-return
    onBubbleMenuHide() {
      if (this.isPopoverActive) return false

      this.bubbleMenuShown = false
      this.setTextEditorToolbarVisibility(false)
    },

    // Expose Mark attributes to determnine active attribute in component
    checkNestedMarkAttributes(marker, attr) {
      const attrValue = this.editor.getAttributes(marker)[attr]
      if (attrValue) {
        const parsedObj = JSON.parse(JSON.stringify(attrValue))
        // This returns an observer unless the mark just got updated
        return typeof parsedObj === 'string' ? parsedObj : parsedObj[0]
      }
      return ''
    },

    checkNestedNodeAttributes(node, attr) {
      const attrValue = this.editor.getAttributes(node)[attr]
      if (attrValue) {
        const parsedObj = JSON.parse(JSON.stringify(attrValue))
        // TODO: clean this up?
        // This returns an observer unless the mark just got updated
        return typeof parsedObj === 'number' ? parsedObj : parsedObj[0]
      }
      return ''
    },

    setColorHighlight(color) {
      if (color === 'background-none') {
        this.editor.chain().unsetColorHighlight().focus().run()
      } else {
        this.editor
          .chain()
          .focus()
          .setColorHighlight({ backgroundColor: color })
          .focus()
          .run()
      }
    },

    ...mapMutations('documentEditor', ['setTextEditorToolbarVisibility']),
  },
})
</script>
