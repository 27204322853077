<template>
  <div
    :class="{ interactive: !disabled }"
    style="text-align: left"
  >
    <!-- <div>q: {{ activeQuadrant }}</div>
    <div>x: {{ coordinates.x }}</div>
    <div>y: {{ coordinates.y }}</div>
    <div>angle: {{ angle }}</div>
    <div>vector: {{ vector }}</div> -->
    <div
      ref="container"
      class="slider2d-container"
      @dblclick="$emit('reset')"
    >
      <div
        class="slider2d-quadrant quadrant-1"
        :class="activeQuadrant === 1 && 'active-quadrant'"
        :style="
          `background: linear-gradient(${quadrantAngles.q1}deg, ` +
            `rgba(35, 184, 57, 0.2) 0%, ` +
            `rgba(35, 184, 57, 0) ${quadrantVectors.q1}%)`
        "
      />
      <div
        class="slider2d-quadrant quadrant-2"
        :class="activeQuadrant === 2 && 'active-quadrant'"
        :style="
          `background: linear-gradient(${quadrantAngles.q2}deg, ` +
            `rgba(35, 184, 57, 0.2) 0%, ` +
            `rgba(35, 184, 57, 0) ${quadrantVectors.q2}%)`
        "
      />
      <div
        class="slider2d-quadrant quadrant-3"
        :class="activeQuadrant === 3 && 'active-quadrant'"
        :style="
          `background: linear-gradient(${quadrantAngles.q3}deg, ` +
            `rgba(35, 184, 57, 0.2) 0%, ` +
            `rgba(35, 184, 57, 0) ${quadrantVectors.q3}%)`
        "
      />
      <div
        class="slider2d-quadrant quadrant-4"
        :class="activeQuadrant === 4 && 'active-quadrant'"
        :style="
          `background: linear-gradient(${quadrantAngles.q4}deg, ` +
            `rgba(35, 184, 57, 0.2) 0%, ` +
            `rgba(35, 184, 57, 0) ${quadrantVectors.q4}%)`
        "
      />
      <div
        ref="draggable"
        v-b-tooltip="{
          placement: 'bottom',
          trigger: 'hover',
          variant: 'info',
          delay: {'show':200,'hide':0},
          title: showTooltip ? 'Drag me to answer' : '',
        }"
        class="slider2d-draggable"
        :class="[shouldPing && 'ping']"
        :style="draggableStyle"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
      >
        <span
          v-if="!disabled"
          :class="[
            'icon_v2-so_drag',
            'transition-colors pointer-events-none transform rotate-90',
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'ramda'
import {
  ref,
  computed,
  onUnmounted,
  onMounted,
  reactive,
} from '@vue/composition-api'
import { restrictValue } from '@/v2/lib/helpers/fp'

const pickSize = pick(['width', 'height'])

const round = (value, digits = 2) => {
  const c = 10 ** digits
  return Math.round(value * c) / c
}

const computeVectorAngle = (x, y) => (Math.atan2(y, x) * 180) / Math.PI
// const computeRectDiagonal = (w, h) => Math.sqrt(w ** 2 + h ** 2)

const eventOffset = (event, target) => {
  const { x, y } = (target || event.target).getBoundingClientRect()

  return {
    x: (event.clientX || event.touches[0].clientX) - x,
    y: (event.clientY || event.touches[0].clientY) - y,
  }
}

const restrictPosition = restrictValue(0, 1)

export default {
  name: 'Slider2D',
  props: {
    value: {
      type: Object,
      default: () => ({ x: 0.5, y: 0.5 }),
    },
    disabled: Boolean,
    ping: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const container = ref(null)
    const containerRect = reactive({ width: 0, height: 0 })

    const draggable = ref(null)
    const draggableRect = reactive({ width: 0, height: 0 })
    const draggableClickOffset = { x: 0, y: 0 }
    const shouldPing = computed(() => props.ping && !props.disabled)
    const showTooltip = ref(true)

    const setRects = () => {
      Object.assign(
        containerRect,
        pickSize(container.value.getBoundingClientRect())
      )
      Object.assign(
        draggableRect,
        pickSize(draggable.value.getBoundingClientRect())
      )
    }

    const onDragStart = event => {
      if (props.disabled) {
        return
      }

      event.preventDefault()
      Object.assign(draggableClickOffset, eventOffset(event))
      setRects()

      document.addEventListener('mouseup', onDragEnd, { once: true })
      document.addEventListener('touchend', onDragEnd, { once: true })
      document.addEventListener('mousemove', onDrag)
      document.addEventListener('touchmove', onDrag)

      // Disable the tooltip while dragging
      showTooltip.value = false
    }

    const onDrag = event => {
      event.preventDefault()
      const { x: offsetX, y: offsetY } = eventOffset(event, container.value)

      const newX = (offsetX - draggableClickOffset.x + draggableRect.width / 2)
        / containerRect.width

      const newY = (offsetY - draggableClickOffset.y + draggableRect.height / 2)
        / containerRect.height

      context.emit('input', {
        x: restrictPosition(newX),
        y: restrictPosition(newY),
      })
    }

    const onDragEnd = () => {
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('touchmove', onDrag)

      // Re-enable the tooltip when dragging is done
      showTooltip.value = true
    }

    const draggableStyle = computed(() => ({
      left: `${round(props.value.x * 100)}%`,
      top: `${round(props.value.y * 100)}%`,
    }))

    const coordinates = computed(() => ({
      x: props.value.x - 0.5,
      y: (props.value.y - 0.5) * -1,
    }))

    const angle = computed(() => computeVectorAngle(coordinates.value.x, coordinates.value.y))

    const quadrantAngles = computed(() => {
      const angle$ = Math.round(angle.value)
      return {
        q1: activeQuadrant.value === 1 ? angle$ - 180 : -45,
        q2: activeQuadrant.value === 2 ? angle$ : 45,
        q3: activeQuadrant.value === 3 ? angle$ + 180 : 135,
        q4: activeQuadrant.value === 4 ? angle$ : -135,
      }
    })

    const quadrantVectors = computed(() => {
      // const defaultVal = 100
      // const vector$ =
      //   defaultVal *
      //   (Math.round(vector.value) /
      //     computeRectDiagonal(
      //       containerRect.width / 2,
      //       containerRect.height / 2
      //     ))

      const defaultVal = 40
      const vector$ = defaultVal * (Math.round(vector.value) / 200)

      return {
        q1: activeQuadrant.value === 1 ? vector$ : defaultVal,
        q2: activeQuadrant.value === 2 ? vector$ : defaultVal,
        q3: activeQuadrant.value === 3 ? vector$ : defaultVal,
        q4: activeQuadrant.value === 4 ? vector$ : defaultVal,
      }
    })

    const vector = computed(() => Math.sqrt(
      (coordinates.value.x * containerRect.width) ** 2
          + (coordinates.value.y * containerRect.height) ** 2
    ))

    const activeQuadrant = computed(() => {
      if (props.value.x < 0.5 && props.value.y < 0.5) return 1
      if (props.value.x > 0.5 && props.value.y < 0.5) return 2
      if (props.value.x > 0.5 && props.value.y > 0.5) return 3
      if (props.value.x < 0.5 && props.value.y > 0.5) return 4
    })

    onMounted(() => {
      setRects()
    })

    onUnmounted(() => {
      onDragEnd()
      document.removeEventListener('mouseup', onDragEnd)
      document.removeEventListener('touchend', onDragEnd)
    })

    return {
      container,
      draggable,
      onDragStart,
      onDrag,
      draggableStyle,
      coordinates,
      angle,
      vector,
      activeQuadrant,
      quadrantAngles,
      quadrantVectors,
      shouldPing,
      showTooltip,
    }
  },
}
</script>

<style lang="postcss" scoped>
.slider2d-container {
  width: 100%;
  max-width: 800px;
  height: 400px;
  border: 1px dotted #b5e6bc;
}

.slider2d-container::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 50%;
  height: 2px;
  background-color: #b5e6bc;
  z-index: auto;
}

.slider2d-container::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #b5e6bc;
  z-index: auto;
}

.slider2d-quadrant {
  width: 50%;
  height: 200px;
  position: absolute;
  background: #ffffff;
  z-index: auto;
  pointer-events: none;
  transition: all 600ms ease-in-out;
  opacity: 0;
}

.quadrant-1 {
  top: 0;
  left: 0;
}

.quadrant-2 {
  top: 0;
  right: 0;
}

.quadrant-3 {
  bottom: -2px;
  right: 0;
}

.quadrant-4 {
  bottom: -2px;
  left: 0;
}

.active-quadrant {
  transition: all 200ms ease-out;
  opacity: 1;
}

.slider2d-draggable {
  @apply bg-green-500 rounded-full absolute flex items-center justify-center text-green-600 ring-4 ring-green-500 ring-opacity-0  ring-offset-green-100 transition-shadow;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  z-index: 1;
}

.interactive .slider2d-draggable {
  @apply hover:cursor-move hover:text-green-700 ring-opacity-20 hover:ring-opacity-10 hover:shadow-2xl active:ring-opacity-20 active:ring-8 active:ring-offset-4 active:shadow-xl;
}

.ping::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  border-radius: 50%;
  animation: ping-border 2s ease-in-out infinite;
  z-index: auto;
  @apply border-8 border-green-400 border-opacity-30;
}

</style>
