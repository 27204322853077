
<router-link
  v-if="actionItem"
  v-slot="scope"
  :to="to"
  :custom="custom"
  v-bind="$attrs"
  v-on="$listeners"
>
  <slot v-bind="{ ...scope, to }" />
</router-link>
