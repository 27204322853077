
<DocumentNodeRenderer
  :node="node"

  :section-class="$style['node-section-embedded-doc']"
  :config-override="{
    stylingToolbar: {
      features: {
        sizes: true,
      },
    },
  }"
>
  <template #default="{styling, nodeWidth}">
    <DocumentContentBlockEmbeddedDoc
      :content-block="contentBlock"
      :styling="styling"
      standalone
      :show-analytics="isEditor"
      :node-width="nodeWidth"
      @open="open"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
