
<div>
  <div>
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="top"
    />
    <div
      v-if="!possibleAnswers.length"
      role="radiogroup"
      class="grid grid-cols-1 gap-16 p-0 m-0 checkbox-image empty md:grid-cols-2 opacity-30"
    >
      <label class="disabled" /><label class="disabled" />
    </div>
    <LightboxGallery
      v-if="image"
      :files="[image]"
      :index="index"
      @close="index = null"
    />
    <div class="grid grid-cols-1 gap-16 p-0 m-0 checkbox-image print:grid-cols-2 md:grid-cols-2">
      <template v-if="contentBlock.settings.acceptsMultipleAnswers">
        <b-form-checkbox
          v-for="answer in possibleAnswers"
          :key="answer.uuid"
          :value="answer.uuid"
          :checked="contentBlock.answer"
          :disabled="isLocked"
          class="relative group"
          button
          button-variant="transparent"
          @change="onSelectionChange"
        >
          <span :style="`background-image: url(${answer.url})`"></span>

          <div
            v-if="answer.url"
            :class="['image-zoom-container']"
            @click.prevent="openImageGallery(answer.url)"
          >
            <i
              v-b-tooltip.hover.right.v-info.dh0.ds200="'Zoom in Image'"
              :class="[
                'icon-util-zoom-in text-24 transform-gpu text-white',
                'hover:opacity-80 hover:scale-110'
              ]"
            />
          </div>
        </b-form-checkbox>
      </template>
      <template v-else>
        <b-form-radio
          v-for="answer in possibleAnswers"
          :key="answer.uuid"
          :name="`qa-image-choice-radio-${contentBlock._id}`"
          :value="answer.uuid"
          :checked="contentBlock.answer[0]"
          :disabled="isLocked"
          class="relative group"
          button
          button-variant="transparent"
          @change="onSelectionChange"
        >
          <span :style="`background-image: url(${answer.url})`"></span>
          <div
            v-if="answer.url"
            :class="['image-zoom-container']"
            @click.prevent="openImageGallery(answer.url)"
          >
            <i
              v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Zoom in Image'"
              :class="[
                'icon-util-zoom-in text-24 transform-gpu text-white',
                'hover:opacity-80 hover:scale-110',
              ]"
            />
          </div>
        </b-form-radio>
      </template>
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
