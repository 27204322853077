<template>
  <ModalBasic
    :title="title"
    :no-close="state.isCreatingDocument"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="md"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <b-form ref="form" @submit.prevent="onSubmit">
        <FormMessage v-if="state.formMessage">
          {{ state.formMessage }}
        </FormMessage>
        <b-form-group v-if="currentProjectId">
          <FormField label="Destination Folder">
            <OrganizationFolderTree
              v-model="state.selectedFolderId"
              :organization-id="organizationId"
              :selected-project-id="currentProjectId"
            />
          </FormField>
        </b-form-group>
        <Buttons>
          <Button
            type="button"
            variant="neutral"
            :disabled="state.isCreatingDocument"
            size="md"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="md"
            :is-loading="state.isCreatingDocument"
            :disabled="!state.selectedFolderId"
          >
            Duplicate
          </Button>
        </Buttons>
      </b-form>
    </template>
  </ModalBasic>
</template>

<script>
import { computed, getCurrentInstance, reactive, defineComponent } from '@vue/composition-api'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import useNotification from '@/v2/lib/composition/useNotification'
import Button from '@/components/Button.vue'
import Buttons from '@/components/Buttons.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'
import FormMessage from '@/components/FormMessage.vue'
import FormField from '@/components/FormField.vue'
import OrganizationFolderTree from '@/components/OrganizationFolderTree.vue'
import useCustomFileSystem from '@/v2/lib/composition/useCustomFileSystem'

export default defineComponent({
  name: 'DocumentCopyModal',
  components: {
    Button,
    Buttons,
    ModalBasic,
    FormMessage,
    FormField,
    OrganizationFolderTree,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const notification = useNotification()
    const { duplicateDocument } = useCustomFileSystem()

    const state = reactive({
      selectedFolderId: null,
      isCreatingDocument: false,
      formMessage: '',
    })

    const { organizationId, projectId: currentProjectId } = useRouteParams([
      'organizationId',
      'projectId',
    ])

    const title = computed(
      () => `Duplicate ${vm.$options.filters.translate(props.document.category)}`
    )

    const onSubmit = async () => {
      state.isCreatingDocument = true
      try {
        const document = await duplicateDocument(
          {
            title: `Copy of ${props.document.title}`,
            category: props.document.category,
            cloneOf: props.document._id,
          },
          state.selectedFolderId
        )
        notification({
          title: 'Duplicated',
          message: 'Document duplicated succesfully',
        })

        context.emit('success', document)
      } catch (err) {
        console.error(err)
        state.formMessage = err
      } finally {
        state.isCreatingDocument = false
      }
    }

    return {
      state,
      currentProjectId,
      organizationId,
      title,
      // Methods
      onSubmit,
    }
  },
})
</script>
