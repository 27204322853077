import { keep } from 'feathers-hooks-common'
import feathersClient from '@/v2/services/feathersClientService'

export const servicePath = 'document-nodes'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      keep('document', 'category', 'contentBlock', 'styling'),
    ],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      // optimistic update, discard server response
      context => {
        context.result = null
        return context
      },
    ],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service
