import { computed } from '@vue/composition-api'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {Ref<import('feathers-vuex/dist').ModelStatic} notification
 */
export default function useNotificationGroup(notification) {
  const isSingleton = computed(() => notification.value.events.length === 1)

  const singleton = computed(
    () => (isSingleton.value ? notification.value.events[0] : notification.value)
  )

  return {
    isSingleton,
    singleton,
  }
}
