import { path, has, equals } from 'ramda'
// import { isObject } from 'ramda-extensiosn'
import {
  BaseModel,
  makeServicePlugin,
} from '@/v2/services/feathersClientService'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { CATEGORY as NODE_CATEGORY } from './documentNodesTypes'
import documentNodesService, { servicePath } from './documentNodesService'

class DocumentNode extends BaseModel {
  static modelName = 'DocumentNode'

  static setupInstance(data, { models }) {
    const { ContentBlock } = models.api
    const _data = { ...data };

    if (_data.createdAt) {
      _data.createdAt = new Date(data.createdAt)
    }

    if (has(['contentBlock$'], _data)) {
      _data.contentBlock$ = new ContentBlock(data.contentBlock$)
    }

    return _data;
  }

  get isGroup() {
    return !this.contentBlock
  }

  /** `true` if the node's content block refers to an embedded document, `false` otherwise */
  get hasEmbeddedDocument() {
    return Boolean(this.contentBlock$ && this.contentBlock$.isEmbeddedDocument)
  }

  /** Is this node rendering a Case Study or Team Bio type of Content Block? */
  get isSpecificEmbed() {
    return [
      CONTENT_BLOCK_CATEGORY.ElementTeamBio,
      CONTENT_BLOCK_CATEGORY.ElementCaseStudy,
      CONTENT_BLOCK_CATEGORY.ElementPackagedService,
    ].includes(path(['contentBlock$', 'category'], this))
  }

  get isLibraryDocument() {
    return !this.project;
  }

  get isEstimateOrBrief() {
    return [
      CONTENT_BLOCK_CATEGORY.ElementBrief,
      CONTENT_BLOCK_CATEGORY.ElementEstimate,
    ].includes(path(['contentBlock$', 'category'], this))
  }

  get isGroupColumn() {
    return equals(NODE_CATEGORY.NodeGroupColumn, path(['category'], this))
  }


  static instanceDefaults() {
    return {
      statistics: {
        commentsCount: 0,
        actionItemsCount: 0,
        actionItemsPublicCount: 0,
      },
      // contentBlock$: {},
      styling: {
        // backgroundColor: null,
        // backgroundImage: null,
        // backgroundImageOpacity: null,
        // textColor: null,
        // paddingTop: null,
        // paddingBottom: null,
        // marginTop: null,
        // marginBottom: null,
        // size: null,
        // headingSize: null,
        // textAlign: null,
      },
    }
  }
}

export default makeServicePlugin({
  servicePath,
  Model: DocumentNode,
  service: documentNodesService,
})
