
<div v-fragment>
  <FileUploader
    ref="fileUploaderRef"
    multiple
    block
    disable-auto-upload
    @files-selected="onFilesSelected"
    @upload-start="onUploadStart"
    @upload-done="onUploadDone"
    @file-progress="onFileProgress"
    @file-done="onFileDone"
    @file-error="onFileError"
  >
    <ListLightDropdown
      :disabled="disabled"
      size="md"
      variant="neutral"
      :lazy="true"
      position="right"
      menu-class="text-14"
      no-caret
    >
      <template #button-content>
        <span class="mr-4 -ml-4 icon_v2-so_plus text-green-600"></span>
        <span v-b-tooltip.hover.top.v-info.dh0.ds200="`${disabled ? disabledReason : ''}`">
          {{ $t('common.create') }}
        </span>
        <span class="flex items-center justify-center w-32 h-32 -mr-6">
          <span class="inline-flex">
            <span class="icon_v2-so_direction-down text-18 w-18" />
          </span>
        </span>
      </template>

      <template #default>
        <ListLightDropdownItem
          v-for="fileType in mappedFileTypes"
          :key="fileType.category"
          :label="fileType.label"
          :document-category="fileType.category"

          :icon="fileType.icon"
          @select="handleFileCreation(fileType.category)"
        />
        <template v-if="!isClientPortal">
          <b-dropdown-divider />
          <ListLightDropdownItem
            label="Use template"
            icon="templates-new"
            @select="openModal"
          />
        </template>
      </template>
    </ListLightDropdown>
  </FileUploader>
  <AddLinkedFileModal
    v-if="isModalOpen"
    v-model="isModalOpen"
    @file-linked="createFileLink"
  />
  <DocumentFromTemplateModal
    v-if="isTemplateModalOpen"
    v-model="isTemplateModalOpen"
    :templates-category="templatesCategory"
    :excluded-categories="[CATEGORY.DocumentReusableBlock]"
    @select="createDocumentFromTemplate"
  />
  <!-- ------------ -->
  <!-- IS UPLOADING -->
  <!-- ------------ -->
  <portal
    v-if="isUploading || uploadedBatch?.files?.length > 0"
    to="right-drawer"
  >
    <PendingUploads
      :pending-uploads="uploadedBatch?.files ?? []"
      @close="uploadsDone"
    />
  </portal>
</div>
