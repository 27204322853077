
<img
  v-if="logoUrl"
  :src="logoUrl"
  class="object-contain w-auto h-full"
  :class="[alignMap[align]]"
/>
<LabelAvatar
  v-else
  size="24"
  :label="fallbackName"
  :background-color="color"
/>
