<template>
  <Form
    v-slot="{ state, v, submit, hasChanges }"
    :state="formState"
    :on-submit="onSubmit"
    :validation="validation"
    success-message="Your password has been changed"
  >
    <div>
      <div v-if="user.hasPassword" class="form-group">
        <FormField
          :v="v.currentPassword"
          :label="$t('login.password-current-placeholder')"
          :messages="{
            required: $t('login.password-current-validation-required'),
            minLength: $t('login.password-validation-min-length'),
          }"
        >
          <FormInputPassword v-model="state.currentPassword" />
        </FormField>
      </div>
      <div class="form-group">
        <FormField
          :v="v.newPassword"
          :label="$t('login.password-new-placeholder')"
          :description="$t('login.password-validation-min-length')"
          :messages="{
            required: $t('login.password-validation-required'),
            minLength: $t('login.password-validation-min-length'),
          }"
        >
          <FormInputPassword
            v-model="state.newPassword"
            autocomplete="new-password"
          />
        </FormField>
      </div>
      <div class="form-group">
        <FormField
          :v="v.newPasswordConfirm"
          :messages="{
            required: $t('login.password-confirm-validation-required'),
            sameAs: $t('login.password-confirm-validation-match'),
          }"
          :label="$t('login.password-confirm-placeholder')"
        >
          <FormInputPassword
            v-model="state.newPasswordConfirm"
            autocomplete="new-password"
          />
        </FormField>
      </div>
    </div>
    <slot name="buttons" v-bind="{ submit, hasChanges }" />
  </Form>
</template>
<script>
import rejectNil from 'ramda-extension/lib/rejectNil'
import { computed, defineComponent, reactive, toRef } from '@vue/composition-api'
import { required, minLength, sameAs } from '@vuelidate/validators'
import { useAccountsChangePassword } from '@/v2/services/accounts/accountsCompositions'
import { useUser } from '@/v2/services/users/usersCompositions'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'
import FormInputPassword from '@/components/FormInputPassword.vue'
import Form from '@/components/Form.vue'

export default defineComponent({
  name: 'UserSettingsPassword',
  components: {
    FormField,
    Button,
    FormMessage,
    FormInputPassword,
    Form,
  },
  props: {
    labelPassword: {
      type: String,
      default: 'New Password',
    },
    labelConfirmPassword: {
      type: String,
      default: 'Confirm New Password',
    },
  },
  setup(props, context) {
    const { user } = useUser()

    const formState = reactive({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    })

    const changePassword = useAccountsChangePassword()

    const onSubmit = async () => {
      await changePassword({
        user: user.value._id,
        currentPassword: formState.currentPassword,
        newPassword: formState.newPassword,
      })

      context.emit('success')
    }

    const validation = computed(() => rejectNil({
      currentPassword: user.value.hasPassword ? {
        required,
        minLength: minLength(8),
      } : null,
      newPassword: {
        required,
        minLength: minLength(8),
      },
      newPasswordConfirm: {
        required,
        sameAs: sameAs(toRef(formState, 'newPassword')),
      },
    }))

    return {
      formState,
      user,
      validation,
      onSubmit,
    }
  },
})
</script>
