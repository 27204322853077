<template>
  <b-alert show variant="danger">
    Not Implemented: {{ node.category }} | {{ node.subcategory }} |
    {{ node.contentBlock$ && node.contentBlock$.category }}
  </b-alert>
</template>
<script>
export default {
  name: 'DocumentNodeNotImplemented',
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      default: null,
    },
  },
}
</script>
d
