<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
  >
    <DocumentMasonry v-if="!isEmpty">
      <DocumentNode
        v-for="(nodeId, index) in children"
        :key="nodeId"
        :node-id="nodeId"
        :parent="node._id"
        :index="index"
        :is-first="index === 0"
        :is-last="index === children.length - 1"
        rounded-selection
        grid
      />
    </DocumentMasonry>
    <DocumentNodeGroupPackagedServicePlaceholder v-else />
  </DocumentNodeRenderer>
</template>

<script>
import { CATEGORY as DOCUMENT_CATEGORY } from '@/v2/services/documents/documentsTypes'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentMasonry from '@/components/Document/DocumentMasonry.vue'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNode from '@/v2/features/document/documentNode/DocumentNode.vue'
import DocumentNodeGroupPackagedServicePlaceholder from './DocumentNodeGroupPackagedServicePlaceholder.vue'

export default {
  name: 'DocumentNodeGroupPackagedService',
  components: {
    DocumentNodeRenderer,
    DocumentMasonry,
    DocumentNodeGroupPackagedServicePlaceholder,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    useNestedComponents({ DocumentNode })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)

    return {
      children,
      isEmpty,
      accepts: [DOCUMENT_CATEGORY.DocumentPackagedService],
    }
  },
}
</script>
