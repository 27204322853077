import {
  reject,
  pipe,
  isNil,
  juxt,
  join,
  when,
  isEmpty,
  path,
  complement,
  converge,
  concat,
} from 'ramda'
import Vue from 'vue'

const SEPARATOR_BROWSER_OS = ', '
const SEPARATOR_VENDOR_MODEL = ' '

const juxtJoinNonNil = (fns, separator) => pipe(
  juxt(fns),
  reject(isNil),
  join(separator)
)

const strBrowserOs = juxtJoinNonNil(
  [path(['browser', 'name']), path(['os', 'name'])],
  SEPARATOR_BROWSER_OS
)

const strVendorModel = pipe(
  juxtJoinNonNil(
    [path(['device', 'vendor']), path(['device', 'model'])],
    SEPARATOR_VENDOR_MODEL
  ),
  when(complement(isEmpty), v => ` (${v})`)
)

const format = converge(concat, [
  strBrowserOs,
  strVendorModel,
])

Vue.filter('userAgent', value => (value ? format(value) : 'Unknown device'))
