
<ProjectTimelineRendererGroup :notification="notification" icon="icon_v2-so_action-items">
  <template #label>
    <span class="text-[color:var(--cp-accent)]">{{ author }}</span>
    {{ $t('project-updates.shared-new-docs-and-files') }}
  </template>
  <template #event="{ event }">
    <DocumentLink
      v-slot="{ navigate }"
      :document="event.subject$"
    >
      <FolderItemRow
        :document="event.subject$"
        read-only
        size="m"
        @select="navigate"
      />
    </DocumentLink>
  </template>
</ProjectTimelineRendererGroup>
