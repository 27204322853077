import {
  applySpec,
  pipe,
  prop,
  map,
  always,
  indexBy,
  propOr,
  path,
  isNil,
  dissoc,
  complement,
  filter,
  startsWith,
  without,
  anyPass,
  sum,
} from 'ramda'
// import { noop } from 'ramda-adjunct'
import { reactive, computed, toRef } from '@vue/composition-api'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'

// For Unsplash images, we can add query params to optimize size
export const optimizedImageUrl = url => {
  const isUnsplash = url && url.includes('images.unsplash.com')
  if (isUnsplash) {
    return `${url}&w=1600&h=1600&q=80`
  }
  return url
}
export function useFileUpload(fileRef) {
  const vm = getCurrentInstanceOrThrow()

  const state = reactive({
    error: null,
    isUploading: false,
    progress: 0,
    localUrl: null,
  })

  const fileUrl = computed(() => (
    state.isUploading
      ? state.localUrl
      : optimizedImageUrl(fileRef.value)))

  const hasUrl = computed(() => Boolean(fileUrl.value))

  const removeFile = () => vm.$emit('input', '')
  const onUploadStart = files => {
    const localUrl = URL.createObjectURL(files[0])
    Object.assign(state, {
      localUrl,
      progress: 0,
      error: null,
      isUploading: true,
    })

    vm.$emit('upload-start', localUrl)
  }

  const onFileProgress = (file, ratio /* , progressEvent */) => {
    state.progress = ratio
    vm.$emit('file-progress', ratio)
  }

  const onUploadEnd = () => {
    URL.revokeObjectURL(fileUrl.value)
    Object.assign(state, {
      isUploading: false,
      fileUrl: null,
    })
  }

  const onFileDone = (file, uploadedFile) => {
    vm.$emit('input', uploadedFile.url)
    vm.$emit('file-done', uploadedFile.url)
    onUploadEnd()
  }

  const onFileError = (file, error) => {
    state.error = error
    onUploadEnd()
    vm.$emit('file-error', error)
  }


  return {
    state,

    // computed
    fileUrl,
    hasUrl,

    // methods
    removeFile,
    onUploadStart,
    onFileProgress,
    onFileDone,
    onFileError,
    onUploadEnd,
  }
}

const batchItemId = prop('uuid')
const batchItem = applySpec({
  uuid: prop('uuid'),
  // createdAt: Date.now,
  url: URL.createObjectURL,
  length: prop('size'),
  contentType: prop('type'),
  name: prop('name'),
  local: applySpec({
    ratio: always(0),
    error: always(null),
    status: 'progress',
  }),
})

const batchItems = map(batchItem)
const batchItemsIndexed = indexBy(batchItemId)
const batchIds = map(batchItemId)
const batchItemIsSuccess = pipe(path(['local', 'error']), isNil)

const batchItemsUploaded = pipe(
  filter(batchItemIsSuccess),
  map(dissoc('local'))
)

const batchItemsErrors = filter(complement(batchItemIsSuccess))
const itemIsMedia = pipe(
  propOr('', 'contentType'),
  anyPass([
    startsWith('image/'),
    startsWith('audio/'),
    startsWith('video/'),
    startsWith('application/pdf'),
  ])
)
const itemsMedia = filter(itemIsMedia)
// const itemsNonImages = filter(itemIsFile)

export function useFilesUpload(filesRef, saveFiles = () => Promise.resolve()) {
  const state = reactive({
    errors: [],
    batch: [],
    batchEntities: {},
    isUploading: false,
  })

  const batchRatio = computed(
    () => {
      const uuids = Object.keys(state.batchEntities)
      if (!uuids.length || !state.isUploading) {
        return null
      }

      const allRatios = uuids.map(uuid => state.batchEntities[uuid].local.ratio)
      return sum(allRatios) / uuids.length
    }
  )

  const isUploading = toRef(state, 'isUploading')
  const batchPopulated = computed(() => state.batch.map(uuid => state.batchEntities[uuid]))

  const onUploadStart = files => {
    const batch = batchItems(files)
    state.batch = batchIds(batch)
    state.batchEntities = batchItemsIndexed(batch)
    state.isUploading = true
  }

  const onFileProgress = (file, ratio /* , progressEvent */) => {
    state.batchEntities[file.uuid].local.status = 'progress'
    state.batchEntities[file.uuid].local.ratio = ratio
  }

  const onFileDone = (file, { url }) => {
    const localUrl = state.batchEntities[file.uuid].url
    state.batchEntities[file.uuid].local.status = 'done'
    state.batchEntities[file.uuid].url = url
    URL.revokeObjectURL(localUrl)
  }

  const onFileError = (file, error) => {
    const localUrl = state.batchEntities[file.uuid].url
    state.batchEntities[file.uuid].local.status = 'error'
    state.batchEntities[file.uuid].local.error = String(error?.message ?? error)
    URL.revokeObjectURL(localUrl)
  }

  const onUploadDone = async () => {
    state.isUploading = false
    state.errors = [...state.errors, ...batchItemsErrors(batchPopulated.value)]

    try {
      await saveFiles(batchItemsUploaded(batchPopulated.value))
    } catch (err) {
      console.error(err)
    } finally {
      state.batch = []
      state.batchEntities = {}
      state.isUploading = false
    }
  }

  const files = computed(() => [
    ...(filesRef.value || []).filter(({ uuid }) => !state.batchEntities[uuid]),
    ...batchPopulated.value,
  ])

  const filesMedia = computed(() => itemsMedia(files.value))
  const filesNonMedia = computed(() => without(
    [...filesMedia.value],
    files.value
  ))
  const hasFiles = computed(() => Boolean(files.value.length))

  return {
    // methods
    onUploadStart,
    onUploadDone,
    onFileProgress,
    onFileDone,
    onFileError,

    // state
    isUploading,
    hasFiles,
    files,
    filesMedia,
    filesNonMedia,
    filesErrors: toRef(state, 'errors'),
    batchRatio,
  }
}
