import { head, slice, reverse, pluck, indexBy, prop } from 'ramda'
import { computed, getCurrentInstance } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useFind } from 'feathers-vuex'
import { headOr, splitChunks } from '@/v2/lib/helpers/fp'
import useDocumentNodes from './useDocumentNodes'

// const isContentBlockCategory = pathEq(['contentBlock$', 'category'])

const isContentBlockCategory = categories => node => categories.includes(
  node?.contentBlock$?.category
)

const { useState } = createNamespacedHelpers('documentEditor')
const pluckId = pluck('_id')
const indexById = indexBy(prop('_id'))

export default function useDocumentNav(documentId, contentBlockCategories) {
  const nodeFilterFn = isContentBlockCategory(contentBlockCategories)
  const splitSectionsFn = splitChunks(nodeFilterFn)
  const nodes = useDocumentNodes(documentId)
  const vm = getCurrentInstance().proxy
  const { ContentBlock } = vm.$FeathersVuex.api

  // blocks
  const blocksParams = computed(() => ({
    query: {
      _id: { $in: nodes.value.filter(Boolean).map(n => n.contentBlock) },
    },
  }))

  const { items: blocks } = useFind({
    model: ContentBlock,
    params: blocksParams,
    local: true,
  })

  const blocksMap = computed(() => indexById(blocks.value))

  // toc
  const tocNodes = computed(() => nodes.value
    .map(n => ({
      node: n,
      cbCategory: blocksMap.value[n?.contentBlock]?.category ?? null,
    }))
    .filter(({ cbCategory }) => contentBlockCategories.includes(cbCategory))
    .map(prop('node')))

  const sections = computed(() => splitSectionsFn(nodes.value).map(pluckId))

  // visible nodes
  const { visibleNodes: visibleNodesMap } = useState(['visibleNodes'])
  const visibleNodeIds = computed(() => pluckId(
    nodes.value.filter(node => visibleNodesMap.value[node._id])
  ))

  const activeNodeId = computed(() => {
    const visibleNodeIdsSet = new Set(visibleNodeIds.value)
    const activeSection = sections.value
      .find(section => section.some(nodeId => visibleNodeIdsSet.has(nodeId)))

    return headOr(null, activeSection)
  })

  // prev
  const prevNode = computed(() => {
    if (!visibleNodeIds.value || !visibleNodeIds.value.length) {
      return null;
    }

    const cueIndex = nodes.value.findIndex(({ _id }) => _id === head(visibleNodeIds.value))
    const stack = reverse(slice(0, cueIndex, nodes.value))
    return stack.find(node => nodeFilterFn(node))
  });

  // next
  const nextNode = computed(() => {
    if (!visibleNodeIds.value || !visibleNodeIds.value.length) {
      return null;
    }

    const cueIndex = nodes.value.findIndex(({ _id }) => _id === head(visibleNodeIds.value))
    const stack = slice(cueIndex + 1, Infinity, nodes.value)
    return stack.find(node => nodeFilterFn(node))
  });

  return {
    // toc
    nodes: tocNodes,
    activeNodeId,

    // navigation
    nextNode,
    prevNode,
  }
}
