<template>
  <div
    class="relative flex flex-col flex-1 h-full p-0 "
  >
    <div class="relative pb-2/3">
      <img class="absolute object-cover w-full h-full" :src="template.preview_image.url" />
      <div
        :class="[
          'absolute left-18 -bottom-12 z-1',
          'bg-white px-6 pt-4 rounded'
        ]"
      >
        <div
          :class="[
            'inline-flex items-center',
            'rounded',
            'bg-gray-700 text-white py-4',
          ]"
        >
          <span
            :class="[
              'inline-flex px-6 items-center justify-center',
              'text-14 font-medium leading-none'
            ]"
          >{{ DOCUMENT_TITLE[category] }}</span>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col items-start flex-1 p-24">
      <div class="mb-24">
        <h2
          class="mb-6 font-semibold text-18"
        >
          {{ template.name }}
        </h2>

        <p class="text-gray-600 text-14 opacity-80">{{ template.description }}</p>
      </div>
      <Button
        variant="neutral"
        size="md"
        class="pl-16 pr-12 mt-auto"
      >
        Preview Template <span class="w-24 ml-8 icon-util-view text-24" />
      </Button>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import { CATEGORY_TO_LIST_TITLE as DOCUMENT_TITLE } from '@/v2/services/documents/documentsTypes'
import Button from '@/components/Button.vue'

export default {
  name: 'TemplatePreviewCard',
  components: { Button },

  props: {
    template: {
      type: Object,
      default: () => {},
    },
    category: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const publicId = computed(
      () => props.template.preview_image.provider_metadata.public_id
    )

    return {
      publicId,
      DOCUMENT_TITLE,
    }
  },
}
</script>
