
<ul
  ref="menuRef"
  v-click-outside.capture="() => $emit('close')"
  :style="menuPosition"
  class="dropdown-menu dropdown-cm show z-1010"
  role="menu"
  @click.stop="$emit('close')"
>
  <b-dropdown-text>
    <b-badge
      v-if="label"
      variant="neutral"
      class="rounded-md normal-case"
    >
      {{ label }}
    </b-badge>
  </b-dropdown-text>
  <slot />
</ul>
