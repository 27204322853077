<template>
  <div
    v-if="!isReadOnly"
    class="flex empty-bg flex-1"
    @contextmenu.stop.prevent="onContextMenuOpen"
  >
    <div class="pt-60 pb-240 px-32 text-center w-full">
      <span class="icon_v2-so_file text-64 w-64 text-gray-800 opacity-20"></span>
      <h3 class="text-32 text-gray-700 font-semibold mt-24 portal-heading-font">
        Build your document
      </h3>
      <p class="text-gray-600 inline-flex items-center text-18 mt-12 portal-paragraph-font">
        Use the button below to start adding blocks in this document.
      </p>
      <div class="pt-32">
        <DocumentBlockButtonCreate
          parent-node-id="root"
          mode="root"
          :index="0"
          :show="true"
          class=""
          data-intercom-target="Footer Create Button"
        />
      </div>
    </div>

    <ContextMenu
      v-if="contextMenu.nodeId === placeholderNodeId"
      label="New Document"
      :left="contextMenu.left"
      :top="contextMenu.top"
      @close="onContextMenuClose"
    >
      <b-dropdown-item
        :disabled="isClipboardEmpty"
        @click="pasteNodes"
      >
        <span class="cm-icon icon-util-paste-before inline-block mr-8 w-16 text-16"></span>
        <span>Paste</span>
      </b-dropdown-item>
    </ContextMenu>
  </div>
  <div
    v-else
    class="flex flex-col items-center justify-center empty-bg"
  >
    <div class="pt-60 pb-240 px-32 text-center">
      <span class="icon_v2-so_file text-64 w-64 text-gray-800 opacity-20"></span>
      <h3 class="text-32 text-gray-700 font-semibold mt-24 portal-heading-font">
        Nothing here yet...
      </h3>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import ContextMenu from '@/components/ContextMenu.vue'
import DocumentBlockButtonCreate from '@/components/Document/DocumentBlockButtonCreate.vue'

import { usePasteNodesFromClipboard } from '@/v2/services/documentNodes/documentNodesCompositions'

const { useGetters, useState, useMutations, useActions } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocumentNodeGroupRootPlaceholder',
  components: { ContextMenu, DocumentBlockButtonCreate },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const placeholderNodeId = 'NodePlaceholderUniqueIdentifier'
    const { contextMenu } = useState(['contextMenu', 'clipboard'])
    const { isClipboardEmpty } = useGetters(['isClipboardEmpty'])

    const { setContextMenuData } = useMutations(['setContextMenuData'])
    const { clearContextMenuData } = useActions(['clearContextMenuData'])
    const pasteNodes = usePasteNodesFromClipboard()

    const onContextMenuOpen = event => setContextMenuData({
      nodeId: placeholderNodeId,
      left: event.clientX,
      top: event.clientY,
    })
    const onContextMenuClose = clearContextMenuData
    return {
      // State
      placeholderNodeId,
      contextMenu,
      isClipboardEmpty,
      // Methods
      onContextMenuOpen,
      onContextMenuClose,
      pasteNodes,
    }
  },
})
</script>

<style lang="postcss" scoped>
.empty-bg {
  @apply bg-green bg-opacity-50;
  background-image: url('/assets/img/pattern.svg');
}
</style>
