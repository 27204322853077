import Vue from 'vue'
import { computed, ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {string} model
 * @param {Ref<object>} [params.query]
 * @param {number} [params.pageSize]
 * @param {string} [params.queryId]
 */
export default function useFindPagination({
  modelName,
  query,
  pageSize = 50,
  queryId,
}) {
  const Model = Vue.$FeathersVuex.api[modelName]

  const skip = ref(0)

  const params = computed(() => ({
    query: query.value,
  }))

  const fetchParams = computed(() => ({
    paginate: true,
    query: {
      ...params.value.query,
      $limit: pageSize,
      $skip: skip.value,
    },
    qid: queryId,
  }))

  const {
    items,
    // paginationData,
    isPending,
    haveLoaded,
    error,
  } = useFind({
    model: Model,
    fetchParams,
    params,
  })

  // const hasMore = computed(
  //   () => haveLoaded.value && items.value.length < count.value
  // )

  const hasMore = true

  const loadMore = () => {
    const newSkipValue = skip.value + pageSize
    const currentCount = items.value.length

    if (newSkipValue !== currentCount) {
      skip.value = currentCount
    } else {
      skip.value += pageSize
    }
  }

  // let lastCount = null
  // watch(isPending, newVal => {
  //   const currentCount = Notification.countInStore({ query: query.value })
  //   if (
  //     !newVal
  //       && isNotNil(lastCount)
  //       && currentCount <= lastCount
  //   ) {
  //     onNoNewNotifications()
  //   }

  //   lastCount = currentCount
  // })

  // -- count notifications
  // const count = ref(0)
  // watch(haveLoaded, newVal => {
  //   if (newVal) {
  //     count.value = paginationData.value[queryId]?.mostRecent?.total ?? 0
  //   }
  // })

  // const countOnServer = async () => {
  //   const updatedCount = await Notification.count(params.value)
  //   count.value = updatedCount
  // }

  // Notification.on('patched', countOnServer)

  // onUnmounted(() => {
  //   Notification.off('patched', countOnServer)
  // })

  return {
    // count,
    items,
    isPending,
    haveLoaded,
    error,
    hasMore,
    loadMore,
  }
}
