
<!-- eslint-disable vue/no-v-html -->
<div v-fragment>
  <!-- Custom Toolbar -->
  <!-- QA Input Type settings -->
  <DocumentContentBlockQAToolbarPortal
    :is-editable="isEditable"
    :node-id="nodeId"
    :input-type="inputType"
    :input-types="inputTypes"
    @select="onInputTypeSelect"
  >
    <component
      :is="inputType.settingsComponent"
      :value="contentBlock.settings"
      :popover-target-id="settingsPopoverTargetId"
      @input="(settings, data) => $emit('update', { settings, ...data })"
      @upload-start="handleUploadStart"
      @upload-done="handleUploadDone"
    />
  </DocumentContentBlockQAToolbarPortal>

  <!-- QA Block Container -->
  <div
    :class="[
      $style['qa-container'],

    ]"
    data-intercom-target="Q&amp;A Block"
  >
    <!-- Answer saved notification -->
    <transition
      name="synced"
      appear
    >
      <div
        v-if="isRecentlySaved"
        :class="[
          'absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-0',
          'flex items-center text-green-700 text-13',
          'bg-green-200 bg-opacity-60 rounded-t-md py-2 pl-4 pr-6'
        ]"
      >
        <span class="mr-4 text-green-700 icon-sync-done text-18 w-18"></span>
        {{ $t('blocks.qa.answer-saved') }}
      </div>
    </transition>

    <!-- Title & Description -->
    <div :class="$style['q-container']" @contextmenu.stop>
      <!-- Title if available -->
      <div
        class="relative flex flex-row items-center justify-start"
      >
        <span
          :class="[
            $style[`text-variant-${textVariant}`],
            $style['q-index'],
            !contentBlock.isDirty
              ? 'bg-yellow-50 text-gray-800'
              : 'bg-green-100 text-green-700 ring-2 ring-green-200 ',
            ['XXS','XS','S'].includes(nodeWidth) && 'q-index-mobile'
          ]"
        >
          {{ indexByCategory + 1 }}
          <span
            v-if="contentBlock.isDirty"
            :class="[
              'absolute w-14 h-14 -bottom-6 right-6',
              'flex items-center justify-center rounded-full',
              'bg-green-200 text-green-700 text-12 font-semibold',
            ]"
          >
            <span class="icon_v2-so_tick text-10" />
          </span>
        </span>

        <!-- Question Title -->
        <ContentEditable
          tag="h5"
          :autofocus="isFocus"
          placeholder="Untitled question..."
          :lazy="true"
          :no-newline="true"
          :no-html="true"
          :contenteditable="isEditable"
          :class="[$style['q-title'], $style[`text-variant-${textVariant}`], 'portal-heading-font']"
          :value="contentBlock.title"
          @input="val => $emit('update', { title: val })"
        />
      </div>
      <!-- Question Description Editable -->
      <TextEditor
        v-if="isEditable && settings.description"
        placeholder="Add instructions..."
        :lazy="true"
        :class="[
          $style['q-instructions'],
          $style[`text-variant-${textVariant}`],
          'editor-content',
          'portal-paragraph-font',
          ['XXS','XS','S'].includes(nodeWidth) && 'q-instructions-mobile'
        ]"
        :show-floating-menu="false"
        :value="contentBlock.description"
        :editor-features="textEditorFeatures"
        @input="val => $emit('update', { description: val })"
      />
      <!-- Question Description non-editable -->
      <div
        v-if="!isEditable && settings.description"
        :class="[
          $style['q-instructions'],
          $style[`text-variant-${textVariant}`],
          'editor-content',
          'editor-content-preview',
          'portal-paragraph-font',
        ]"
        v-html="contentBlock.description"
      />
    </div>
    <!-- Input Type -->
    <div
      :class=" [$style['a-container'],
                ['XXS','XS','S'].includes(nodeWidth) && 'a-container-mobile']"
    >
      <span
        v-if="!contentBlock.settings.hasTitle"
        :class="[
          $style[`text-variant-${textVariant}`],
          $style['q-index'],
          !contentBlock.isDirty
            ? 'bg-yellow-50 text-gray-800'
            : 'bg-green-100 text-green-700',
        ]"
      >
        {{ indexByCategory + 1 }}
        <span
          v-if="contentBlock.isDirty"
          :class="[
            'absolute w-50 h-20 -top-8 -right-8',
            'flex items-center justify-center rounded-full',
            'bg-green-200 text-green-700 text-12 print:hidden',
          ]"
        >
          <span class="w-12 icon_v2-so_tick text-12" />
          SAVED
        </span>
      </span>

      <component
        :is="inputType.component"
        :content-block="contentBlock"
        :is-answerable="isAnswerable"
        :text-variant="textVariant"
        :is-empty="!contentBlock.isDirty"
        :node-id="nodeId"
        :node-width="nodeWidth"
        @update="updateAnswer"
        @reset="resetAnswer"
        @async-save-start="$emit('async-save-start')"
      />

      <!-- Show a generic placeholder if there is no answer
          and the document is locked / approved -->
      <div
        v-if="!contentBlock.isDirty && isLocked"
        :class="[
          'inline-flex flex-col items-center justify-center px-8 py-2',
          'mt-8 text-gray-600 bg-gray-100 rounded-md',
          'portal-paragraph-font'
        ]"
      >
        No answer
      </div>
    </div>
  </div>
  <!-- -------------------- -->
  <!-- TOGGLE NODE SETTINGS -->
  <!-- -------------------- -->
  <div
    v-if="isEditable"
    :class="[
      'mt-16 space-x-4 bg-gray-100 p-4 rounded-md inline-flex transition-opacity print:hidden',
      isHover || isFocus ? 'opacity-100' : 'opacity-30',
      $style['i-index']
    ]"
  >
    <b-button
      variant="neutral"
      size="xs"
      @click="openSettings"
    >
      <span class="mr-6 icon-toolbar-settings" />
      Edit Question
    </b-button>
    <b-button
      variant="neutral"
      size="xs"
      @click.stop="resetAnswer"
    >
      <span class="mr-4 icon_v2-so_close" />
      Reset Answer
    </b-button>
  </div>
</div>
