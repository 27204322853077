<template>
  <div v-fragment>
    <!-- block -->
    <div :style="{ height: height + 'px' }"></div>

    <!-- toolbar settings -->
    <DocumentContentBlockSpacerToolbarPortal
      :popover-target-id="`settings-popover-${nodeId}`"
      :content-block="contentBlock"
      :node-id="nodeId"
      @update="(data, options) => $emit('update', data, options)"
    />
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import DocumentContentBlockSpacerToolbarPortal from './DocumentContentBlockSpacerToolbarPortal.vue';

export default {
  name: 'DocumentContentBlockSpacer',
  components: {
    DocumentContentBlockSpacerToolbarPortal,
  },
  inheritAttrs: false,
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const document = inject('document')

    const isEditable = computed(() => isEditor.value && !isLocked.value)

    const height = computed(() => props.contentBlock.settings.height)


    return {
      isEditable,
      document,
      height,
    }
  },
}
</script>
