/** @typedef {keyof typeof ROLES} Role */

export const STATUS = /** @type {const} */ ({
  ACTIVE: 'active',
  INVITED: 'invited',
  /** Members with their account locked due to payment issues */
  LOCKED: 'locked',
})

export const ROLES = /** @type {const} */ ({
  admin: 'admin', // Team Members with full access (agency)
  projectManager: 'projectManager',
  member: 'member',
  client: 'client', // Client Contacts with access to shared docs on the Client Portal
  tokenDocumentShare: 'tokenDocumentShare',
})
