<template>
  <Form
    v-slot="{ state, v, submit, hasChanges }"
    :state="formState"
    :on-submit="onFormSubmit"
    :validation="formValidation"
    success-message="Profile saved"
  >
    <div class="lg:grid grid-cols-12 gap-32 pb-64 max-w-screen-md">
      <div class="col-span-3 flex flex-col items-center space-y-8 border rounded-md p-12">
        <span class="text-14 mb-8 text-gray-500">{{ $t('settings.profile-photo') }}</span>
        <ImageThumbnailUpload
          v-model="state.profilePhotoUrl"
          variant="round"
          :width="128"
          :height="128"
          @uploading="uploadProfilePicture"
        >
          <template #buttons="{ selectFiles, removeFile }">
            <Button
              v-if="!state.profilePhotoUrl"
              variant="primary"
              size="sm"
              @click="selectFiles"
            >
              Upload Profile Photo
            </Button>
            <template v-else>
              <Button
                variant="primary"
                size="sm"
                @click="selectFiles"
              >
                Replace
              </Button>
              <Button
                variant="neutral"
                size="sm"
                @click="removeFile"
              >
                Remove
              </Button>
            </template>
          </template>
        </ImageThumbnailUpload>
      </div>
      <div class="col-span-9">
        <div>
          <div class="grid grid-cols-2 gap-24 mb-16 mt-16 lg:mt-0">
            <div class="col-span-1">
              <FormField :v="v.firstName" :label="$t('settings.first-name')">
                <b-input
                  v-model="state.firstName"
                  class="form-control-lg"
                  autocomplete="given-name"
                />
              </FormField>
            </div>
            <div class="col-span-1">
              <FormField :v="v.lastName" :label="$t('settings.last-name')">
                <b-input
                  v-model="state.lastName"
                  class="form-control-lg"
                  autocomplete="family-name"
                />
              </FormField>
            </div>
          </div>
          <div class="lg:grid grid-cols-2 gap-24">
            <CheckMemberRole
              v-slot="{ hasRole }"
              :roles="['admin', 'projectManager']"
            >
              <div v-if="hasRole" class="col-span-1">
                <FormField :v="v.role" label="Role">
                  <b-select
                    v-model="state.role"
                    :options="optionsRole"
                    class="form-control-lg"
                  />
                </FormField>
              </div>
            </CheckMemberRole>
            <div class="col-span-1">
              <FormField
                :v="v.email"
                :label="$t('settings.email')"
                :messages="{ available: 'This email is not available' }"
                validation-pending-message="Checking availability..."
              >
                <b-input
                  v-model.trim="formStateEmail"
                  class="form-control-lg"
                />
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot v-bind="{ submit, isLoading: isUploading, hasChanges }" />
  </Form>
</template>

<script>
import { defineComponent, reactive, ref, computed } from '@vue/composition-api'
import { required, email, helpers } from '@vuelidate/validators'
import { pick } from 'ramda'
import useValidators from '@/v2/services/validators/validatorsCompositions'
import debounce from '@/v2/lib/helpers/debounce'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import ImageThumbnailUpload from '@/components/Blocks/Image/ImageThumbnailUpload.vue'
import FormField from '@/components/FormField.vue'
import options from '@/types/organization-options'
import Form from '@/components/Form.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'

const { withAsync } = helpers

export default defineComponent({
  name: 'UserSettingsProfile',
  components: {
    ImageThumbnailUpload,
    FormField,
    Form,
    CheckMemberRole,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const profileModel = props.profile.clone()
    const userModel = props.user.clone()
    const isUploading = ref(false)

    const formState = reactive({
      firstName: profileModel.firstName,
      lastName: profileModel.lastName,
      profilePhotoUrl: profileModel.profilePhotoUrl,
      role: profileModel.role,
      email: userModel.email,
    })

    const formStateEmail = computed({
      get: () => formState.email,
      set: debounce(value => {
        formState.email = emailSanitizer(value)
      }),
    })
    const { emailAvailable } = useValidators();
    const isChangeValid = newEmail => (newEmail !== userModel.email // Email changed
      ? emailAvailable(newEmail) // Has email been claimed by another user?
      : true) // Email not changed.

    const formValidation = {
      firstName: { required },
      lastName: { required },
      email: {
        required,
        email,
        available: withAsync(isChangeValid),
        $lazy: true,
      },
    }

    const updateEmailAddress = newEmail => userModel.patch({ data: { email: newEmail } })

    const uploadProfilePicture = status => { isUploading.value = status }

    const onFormSubmit = async formDataRef => {
      // Update profile
      await profileModel.save({
        data: {
          ...pick(
            ['firstName', 'lastName', 'role', 'profilePhotoUrl'],
            formDataRef.value
          ),
        },
      })
      // Update email address if it changed
      if (formDataRef.value.email !== userModel.email) {
        await updateEmailAddress(formDataRef.value.email)
      }
    }

    return {
      formState,
      formStateEmail,
      formValidation,
      isUploading,
      uploadProfilePicture,
      onFormSubmit,
      optionsRole: options.role,
    }
  },
})
</script>
