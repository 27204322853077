import { Mark, mergeAttributes } from '@tiptap/core'

import { intersection } from 'ramda'
import { HIGHLIGHT_COLORS } from '@/types/editor'

const backgroundColors = Object.values(HIGHLIGHT_COLORS)

export const ColorHighlight = Mark.create({
  name: 'colorHighlight',
  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },
  addAttributes() {
    return {
      backgroundColor: {
        default: 'background-none',

        // parseHTML: element => ({
        //   backgroundColor: intersection(backgroundColors, element.classList),
        // }),
        parseHTML: element => intersection(backgroundColors, element.classList),
        renderHTML: attributes => {
          if (!attributes.backgroundColor) {
            return {}
          }

          return {
            class: `so-highlight ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setColorHighlight: attributes => ({ commands }) => commands.setMark('colorHighlight', attributes),
      unsetColorHighlight: () => ({ commands }) => commands.unsetMark('colorHighlight'),
      toggleColorHighlight: attributes => ({ commands }) => commands.toggleMark('colorHighlight', attributes),
    }
  },
})
