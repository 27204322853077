import { either, test, isNil } from 'ramda';
import { ref, watch, unref } from '@vue/composition-api'
import { ErrorEx, ERROR_CODES } from '@/lib/errors'
import { ORGANIZATION_SETTINGS_DOMAIN } from '@/router/organization/type';
import { PROJECT_SETTINGS_MANAGE_ACCESS } from '@/router/project/type';
import { tokens } from '@/v2/services'
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'
import { useMsgBoxConfirm, useMsgBoxError, useMsgBoxOK } from '@/v2/lib/composition/useMsgBox';
import { useRouter } from '@/v2/lib/composition/useRouter';
import useDomain from '@/v2/services/domains/useDomain'
import { useCurrentMember } from '@/v2/services/myMembers/compositions';
import { useAccountsCreateAuthClient } from '@/v2/services/accounts/accountsCompositions';
import { ROLES } from '@/v2/services/members/membersTypes';

/** `true` if a string looks like a `uuid4`-generated code, `false` otherwise */
const isValidUUID4 = test(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i)

/** `true` if a string looks like a `nanoid`-generated code, `false` otherwise */
const isValidNanoID = test(/^[A-Z0-9_-]{21}$/i)

/** Tests if a given string resembles a `uuid4` or `nanoid` */
const validateFormat = either(isValidNanoID, isValidUUID4);

/**
 * @param {import('@vue/composition-api').Ref<string>} tokenId
 */
export function useToken(tokenId) {
  const token = ref(null)
  const error = ref(null)
  const isPending = ref(true)

  watch(tokenId, async newId => {
    token.value = null
    error.value = null


    if (isNil(newId)) {
      isPending.value = false
      return
    }

    isPending.value = true
    try {
      if (!validateFormat(newId)) {
        throw new ErrorEx(ERROR_CODES.INVALID_TOKEN)
      }
      token.value = await tokens.get(newId)
    } catch (err) {
      error.value = err
      console.error('Error fetching token:', err)
    } finally {
      isPending.value = false
    }
  }, { immediate: true })

  return {
    error,
    token,
    isPending,
  }
}

export function useClientPortalPreviewUrl(project) {
  const isLoading = ref(false)
  const error = ref(null)

  const msgBoxConfirm = useMsgBoxConfirm()
  const msgBoxError = useMsgBoxError()
  const msgBoxOK = useMsgBoxOK()

  const { member } = useCurrentMember()
  const organization = useCurrentOrganization()
  const createAuthClient = useAccountsCreateAuthClient()

  const { routerPush } = useRouter()
  const { withClientPortalUrl } = useDomain()

  const createUrl = async (_project = null) => {
    if (isLoading.value) {
      return null
    }

    isLoading.value = true
    error.value = null

    let url
    try {
      const __project = _project ?? project.value
      const { result: { token } } = await createAuthClient({
        memberId: member.value._id,
        clientId: __project.client,
        role: ROLES.client,
        isInvite: false,
      });

      url = [
        withClientPortalUrl(organization, `auth/${token}`).value,
        `?project=${__project._id}&preview=true`,
      ].join('')
    } catch (err) {
      error.value = err
      throw err
    } finally {
      isLoading.value = false
    }

    return url
  }

  const checkSubdomainPresence = async () => {
    if (organization.value.subdomain) {
      return true
    }

    if (member.value.role !== ROLES.admin) {
      msgBoxOK({
        title: 'No subdomain',
        size: 'md',
        message: 'To use the client portal you need to have a subdomain. '
          + 'Please ask one of the organization admins to set up a domain, then retry.',
      })
    } else {
      const ok = await msgBoxConfirm({
        title: 'No subdomain',
        size: 'md',
        message: 'To use the client portal you need to have a subdomain. '
          + 'Would you like to set one now ?',
        okLabel: 'Set one up now',
        okVariant: 'tertiary',
        cancelLabel: 'No',
      })

      if (ok) {
        routerPush({ name: ORGANIZATION_SETTINGS_DOMAIN })
      }
    }

    return false
  }

  const checkAssignedClientPresence = async _project => {
    const { client, _id: projectId } = unref(_project ?? project)

    if (client) {
      return true
    }

    if (member.value.role !== ROLES.admin) {
      msgBoxOK({
        title: 'No client',
        size: 'md',
        message: 'To use the client portal you need to assign a client to this project.'
          + 'Please ask one of the organization admins to assign a client, then retry.',
      })
    } else {
      const ok = await msgBoxConfirm({
        title: 'No client',
        size: 'md',
        message: 'To use the client portal you need to assign a client to this project.'
          + 'Would you like to assign one now ?',
        okLabel: 'Assign one now',
        okVariant: 'tertiary',
        cancelLabel: 'No',
      })

      if (ok) {
        routerPush({
          name: PROJECT_SETTINGS_MANAGE_ACCESS,
          params: { projectId },
        })
      }
    }

    return false
  }

  const createPortalPreviewUrl = async (_project = null) => {
    if (!(await checkSubdomainPresence())) {
      return null
    }

    if (!(await checkAssignedClientPresence(_project))) {
      return null
    }

    try {
      const url = await createUrl(_project)
      return url
    } catch (err) {
      msgBoxError({
        title: 'Error',
        message: 'Unable to preview the client portal.',
      })

      return null
    }
  };

  return {
    createUrl,
    createPortalPreviewUrl,
    isLoading,
    error,
  }
}
