import Vue from 'vue'
import { rejectNil } from 'ramda-extension'
import accountsService from './accountsService'
import { COMMAND } from './accountsTypes'

/**
 * @typedef {Object} AccountRegister
 * @property {Object} user
 * @property {Object} profile
 * @property {Object} organization
 */
/** @type {function(): function(AccountRegister): Promise<Object>} */
export const useAccountsRegister = () => payload => accountsService.create({
  command: COMMAND.REGISTER,
  ...payload,
})

/**
 * @typedef {Object} AccountRegisterInvitedMember
 * @property {Object} user
 * @property {Object} profile
 * @property {string} token
 */
/** @type {function(): function(AccountRegisterInvitedMember): Promise<Object>} */
export const useAccountsRegisterInvitedMember = () => payload => accountsService.create({
  command: COMMAND.REGISTER_INVITED_MEMBER,
  ...payload,
})

export const useAccountsChangePassword = () => {
  const { User } = Vue.$FeathersVuex.api

  const setHasPassword = userId => {
    const model = User.getFromStore(userId)
    const clone = model.clone()
    clone.hasPassword = true
    clone.commit()
  }

  return async ({ currentPassword, newPassword, user: userId }) => {
    const result = await accountsService
      .create({
        command: COMMAND.CHANGE_PASSWORD,
        user: userId,
        currentPassword,
        newPassword,
      });

    setHasPassword(userId)

    return result
  }
}


/**
 * Initiates a password reset request for a given email
 * @returns {function(String): Promise<Boolean>}
 */
export function useRequestPasswordReset() {
  // const isClientPortal = inject('isClientPortal', false)

  return email => accountsService.create({
    command: COMMAND.RESET_PASSWORD,
    email,
    // source: isClientPortal ? 'portal' : 'editor',
  });
}

export const useConfirmPasswordReset = () => ({
  accountsRequestId,
  newPassword,
  token,
}) => accountsService.patch(accountsRequestId, { newPassword }, { token });

export const useAccountsResendMagicLink = () => (email, subdomain) => accountsService.create({
  command: COMMAND.RESEND_LINK,
  email,
  subdomain,
})

export const useAccountsCreateAuthClient = () => ({
  memberId,
  clientId = null,
  role = null,
  isInvite = false,
}) => accountsService.create({
  command: COMMAND.CREATE_AUTH_CLIENT,
  member: memberId,
  ...rejectNil({
    client: clientId,
    role,
    isInvite,
  }),
})

/** @type {function(): function(AccountRegister): Promise<Object>} */
export const useAccountsRegisterSumoling = () => payload => accountsService.create({
  command: COMMAND.REGISTER_SUMOLING,
  ...payload,
})

/** @type {function(): function(String): Promise<User>} */
// export const useAccountsDeleteAccount = () => () => accountsService.create({
//   command: COMMAND.DELETE_ACCOUNT,
// })

