<template>
  <span class="inline-flex flex-wrap items-center">
    <span
      v-b-tooltip="{
        placement: 'right',
        trigger: 'hover',
        variant: 'info' ,
        delay: { show: 200, hide: 0 },
        title: isClientVisible
          ? 'The client sees this'
          : 'The client does not see this'
      }"
      class="visibility-badge"
      :class="{
        'visibility-badge-off': !isClientVisible,
        'visibility-badge-on': isClientVisible,
        'visibility-badge-interactive': interactive,
        'visibility-badge-full': variant === 'full',
        'visibility-badge-icon': variant !== 'full',
      }"
    >
      <span
        class="mx-auto text-18 opacity-70 shrink-0"
        :class="{
          'icon_v2-so_eye-disabled': !isClientVisible,
          'icon_v2-so_eye': isClientVisible,
        }"
      />
      <span v-if="variant==='full'" class="ml-4 mr-2 font-medium leading-none opacity-70">
        <template v-if="isClientVisible">The client and your team will see this</template>
        <template v-else>Private to your team</template>
      </span>
    </span>
    <i
      v-if="interactive"
      v-b-tooltip="{
        placement: 'bottom',
        trigger: 'hover',
        variant: 'info' ,
        delay: { show: 200, hide:0 },
        title: 'Toggle visibility'
      }"
      class="ml-6 not-italic text-gray-800 underline cursor-pointer text-14 hover:no-underline"
      @click="toggleClientVisibility"
    >
      <template v-if="isClientVisible">Make Private</template>
      <template v-else>Make Public</template>
    </i>
  </span>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'ClientVisibilityBadge',
  props: {
    variant: {
      type: String,
      default: 'full',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const isClientVisible = computed(() => props.visible)

    const toggleClientVisibility = () => {
      if (!props.interactive) return
      context.emit('toggle-visibility')
    }

    return {
      isClientVisible,
      toggleClientVisibility,
    }
  },
})
</script>

<style lang="postcss" scoped>

.visibility-badge {
  @apply inline-flex items-center justify-center;
  @apply z-1 relative ;
}
.visibility-badge-full {
  @apply px-6 py-4 rounded-md ;
}
.visibility-badge-icon {
  @apply px-4 py-2 rounded-full shrink-0;
}
.visibility-badge-on {
  /* @apply bg-green-200 text-green-900; */
}

.visibility-badge-off {
  /* @apply bg-gray-200 text-gray-900; */
}

.visibility-badge-interactive {
  @apply z-1 relative;
}

</style>

