<template>
  <div class="inline-flex items-center h-32 space-x-8 text-14">
    <div
      v-if="isMobile && ancestors.length > 1"
      class="flex items-center text-gray-500 md:ml-24 dark:text-darkGray-400"
    >
      <span class="w-20 mr-6 icon-arrow-left-util text-20"></span>
      <DocumentLinkTitle
        v-if="ancestors.length > 1"
        :document="ancestors[ancestors.length - 2]"
        class="text-gray-500 dark:text-darkGray-400
           hover:underline hover:text-[color:var(--cp-accent)]"
      />
    </div>
    <div v-if="!isMobile" class="flex">
      <router-link
        v-if="!isClientPortal"
        :to="{
          name: pathName,
          params: { projectId: project._id }
        }"
        class="text-gray-500 dark:text-darkGray-400
        hover:underline hover:text-[color:var(--cp-accent)] line-clamp-1"
      >
        {{ project.name }}
      </router-link>
      <div
        v-for="(doc, index) in splitAncestors.pre"
        :key="doc._id"
        class="ml-22 so-breadcrumb-item flex items-center"
      >
        <span
          class="so-breadcrumb-arrow
          w-12 mr-4 -ml-16
          text-gray-400 dark:text-darkGray-600
          text-12 icon-arrow-right"
        />
        <DocumentLinkTitle
          v-if="index < ancestors.length - 1"
          :document="doc"
          class="text-gray-500 dark:text-darkGray-400
           hover:underline hover:text-[color:var(--cp-accent)]"
        />
        <DocumentComputedData
          v-else
          v-slot="{ title }"
          :document="document"
        >
          <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
            <WrappedTitle
              :text="title"
              :pre-size="34"
            />
          </p>
        </DocumentComputedData>
      </div>
      <!-- IF MORE ANCESTORS THAN WOULD FIT -->
      <div
        v-if="splitAncestors.after.length"
        class="ml-12 text-gray-500 dark:text-darkGray-300"
      >
        ...
      </div>
      <div v-if="splitAncestors.after.length" v-fragment>
        <div
          v-for="(doc, index) in splitAncestors.after"
          :key="doc._id"
          class="ml-22 so-breadcrumb-item flex items-center"
        >
          <span
            class="so-breadcrumb-arrow
            w-12 mr-4 -ml-16
            text-gray-400 dark:text-darkGray-600
            text-12 icon-arrow-right"
          />
          <DocumentLinkTitle
            v-if="index < splitAncestors.after.length - 1"
            :document="doc"
            class="text-gray-500 dark:text-darkGray-400
             hover:underline hover:text-[color:var(--cp-accent)]"
          />
          <DocumentComputedData
            v-else
            v-slot="{ title }"
            :document="document"
          >
            <p class="text-gray-500 dark:text-darkGray-300 line-clamp-1">
              <WrappedTitle
                :text="title"
                :pre-size="34"
              />
            </p>
          </DocumentComputedData>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { take, takeLast } from 'ramda'
import { defineComponent, computed, toRef, inject } from '@vue/composition-api'
import { isNilOrEmpty } from 'ramda-adjunct'
import useFSAncestors from '@/v2/lib/composition/useFSAncestors'
import { useProjectModule } from '@/v2/services/projects/compositions'
import { PROJECT_DASHBOARD } from '@/router/project/type'
import { PORTAL_DASHBOARD } from '@/client-portal/router/types'
import DocumentLinkTitle from '@/components/DocumentLinkTitle.vue'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import WrappedTitle from '@/components/WrappedTitle.vue'

// -- SPLIT CONFIG
// IF THE BREADCRUMB GETS bigger than preSize + afterSize + 1,
// split the list of ancestors in two groups
const SPLIT_PRE_SIZE = 3 // first 3 items
const SPLIT_AFTER_SIZE = 2 // last 2 items

// -- HELPERS
const makeSplitter = (preSize, afterSize) => (list = []) => {
  const cond = list.length > (preSize + afterSize + 1)
  return {
    pre: cond ? take(preSize, list) : list,
    after: cond ? takeLast(afterSize, list) : [],
  }
}

const splitter = makeSplitter(SPLIT_PRE_SIZE, SPLIT_AFTER_SIZE)

export default defineComponent({
  name: 'DocBreadcrumbs',
  components: {
    DocumentLinkTitle,
    DocumentComputedData,
    WrappedTitle,
  },
  props: {
    document: {
      type: Object,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
    isPortal: {
      type: Boolean,
      default: false,
    },
    // force a folder id (for portal lib documents)
    folderId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const fsAncestors = useFSAncestors(toRef(props, 'document'), toRef(props, 'folderId'))
    const isMobile = computed(() => context.root.$mq === 'sm')
    const pathName = props.isPortal ? PORTAL_DASHBOARD : PROJECT_DASHBOARD
    const isClientPortal = inject('isClientPortal', false)
    const documentsModule = useProjectModule(computed(() => props.project), 'documents')

    const ancestors = computed(() => {
      const { pinnedFolders } = props.project

      if (documentsModule.value.enabled || isNilOrEmpty(pinnedFolders)) {
        return fsAncestors.value
      }

      const ancestorIndex = fsAncestors.value.findIndex(doc => pinnedFolders.includes(doc._id));
      return fsAncestors.value.slice(ancestorIndex)
    })

    const splitAncestors = computed(() => splitter(ancestors.value))

    return {
      pathName,
      isMobile,
      ancestors,
      isClientPortal,
      splitAncestors,
    }
  },
})
</script>


<style lang="postcss" scoped>
.so-breadcrumb-item:first-child .so-breadcrumb-arrow {
  @apply hidden;
}

.so-breadcrumb-item:first-child {
  @apply ml-0;
}

.so-breadcrumb-item:last-child {
  @apply font-bold;
}
</style>
