import { ref } from '@vue/composition-api'

/**
 *
 * @param {() => Promise} promiseFactory
 */
export default function useAsyncTask(promiseFactory, rethrow = true) {
  const isBusy = ref(false)
  const error = ref(null)

  const exec = async (...args) => {
    if (isBusy.value) {
      return null
    }

    error.value = null
    isBusy.value = true

    try {
      const result = await promiseFactory(...args)
      return result
    } catch (err) {
      error.value = err
      if (rethrow) {
        throw err
      }

      return null
    } finally {
      isBusy.value = false
    }
  }

  const reset = () => {
    isBusy.value = false
    error.value = null
  }

  return {
    isBusy,
    error,
    exec,
    reset,
  }
}
