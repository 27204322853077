<template>
  <div class="document-masonry">
    <masonry :cols="cols" :gutter="gutter">
      <slot />
    </masonry>
  </div>
</template>

<script>
export default {
  name: 'DocumentMasonry',
  props: {
    cols: {
      type: Object,
      default: () => ({ default: 2, 700: 1 }),
    },
    gutter: {
      type: Object,
      default: () => ({ default: 24 }),
    },
  },
}
</script>

<style lang="scss" scoped>
.document-masonry {
  display: block;
  /* padding: 0 16px;
  @media (min-width: 701px) {
    padding: 0;
  }*/
  ::v-deep .masonry-item {
    width: 100% !important;
    margin: 0 0 16px 0 !important;
    @media (min-width: 701px) {
      margin: 0 0 25px 0 !important;
    }
  }
  /*:global(.block-portfolio) {
    margin: 0 0 16px;
    @media (min-width: 701px) {
      margin: 0 0 24px;
    }
  } */
  > div {
    justify-content: flex-start;
  }
}
</style>
