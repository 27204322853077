
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="[
    'node-group-basic',
  ]"
>
  <template v-if="!isEmpty">
    <DocumentNode
      v-for="(nodeId, index) in children"
      :key="nodeId"
      :node-id="nodeId"
      :parent="node._id"
      :index="index"
      :is-first="index === 0"
      :is-last="index === children.length - 1"
    />
  </template>
  <DocumentNodeGroupBasicPlaceholder v-else />
</DocumentNodeRenderer>
