
<b-button
  v-bind="$attrs"
  :disabled="disabled || isLoading"
  :variant="variant"
  :size="size"
  v-on="$listeners"
>
  <slot />
  <Spinner
    v-if="isLoading"
    :size="16"
    variant="light"
    class="ml-8"
  />
</b-button>
