<template>
  <section
    :id="`node-${node._id}`"
    ref="nodeRef"
    v-observe-visibility="{
      callback: onVisibilityChanged,
      intersection: {
        rootMargin: '-120px 0px 0px 0px',
        threshold: 0,
      }
    }"
    v-click-outside.stop="onBlur"
    :class="[
      !width && 'flex-1',
      cssClass,
      rootClass,
      pulse && 'pulse-me',
      'node',
      isNodeBeingCut && 'so-node-cut',
    ]"
    :style="{
      width,
      ...style,
      //...cssVars
    }"
    @click.stop="onToggleFocus"
    @mouseover.stop="onMouseOver"
    @mouseleave="onMouseLeave"
    @contextmenu="handleContextmenuAction"
  >
    <portal v-if="$scopedSlots['styling-settings']" :to="`node-settings-${node._id}`">
      <div slot-scope="settingsScope">
        <slot name="styling-settings" v-bind="settingsScope" />
      </div>
    </portal>

    <slot
      name="plugin"
      v-bind="{
        config,
        styling,
        textVariant,
        isFocus,
        isHover,
      }"
    />
    <div
      class="relative flex-col items-center screen:flex screen:h-full block-section-wrapper"
      :class="[`text-variant-${textVariant}`, innerClass]"
      :style="styleBgColor"
    >
      <div
        v-if="hasBgImage"
        class="background-image-overlay"
        :style="styleBgImage"
      />
      <div
        class="relative flex-1 w-full print:mx-auto block-section"
        :class="sectionClass"
        :style="styleSection"
      >
        <div
          class="h-full block-section-content"
          :class="contentClass"
          @click.stop="onSetFocus"
        >
          <slot v-bind="{ config, styling, textVariant, isFocus, isHover, nodeWidth }" />
        </div>
      </div>
    </div>
    <!-- Selection highlight -->
    <div
      v-if="isSelected"
      :class="'absolute inset-0 bg-green-500 bg-opacity-25 border border-dashed '
        + 'border-green-500 select-none pointer-events-none -mt-px z-1'"
    />
    <!-- Focus Selection highlight -->
    <div
      v-if="isFocus && !isViewer && !isSandbox"
      :class="'absolute inset-0 border border-dashed print:hidden '
        + 'border-green-500 select-none pointer-events-none -mt-px z-10'"
    />
    <!-- Comments Icon Portal to Block Actions -->
    <portal :to="`comments-${node._id}`">
      <div
        v-if="!hideNodeComments && !isGroupBasic && !isSandbox"
        :class="[
          'w-32 h-32 flex items-end justify-start relative'
        ]"
      >
        <b-button
          v-b-tooltip.hover.top.v-info.dh0.ds200
          variant="transparent"
          :class="[
            'w-32 h-32 flex items-center justify-center outline-none',
            'hover:bg-gray-500 hover:bg-opacity-10 focus:outline-none',
            'active:outline-none',
            nodeCommentsCount ?
              'text-green-500 hover:text-green-300'
              : 'text-gray-100 hover:text-gray-100'
          ]"
          title="Comments"
          @click.stop="toggleCommentsWithNode(node._id)"
        >
          <span
            class="relative w-20 text-16"
            :class="[
              'icon_v2-so_chat',
            ]"
          ></span>
          <span
            v-if="nodeCommentsCount"
            :class="[
              'comment-count',
              commentsSidebarStatus.nodeId === node._id ? 'bg-green-500' : 'bg-gray-700',
            ]"
          >{{ nodeCommentsCount }}</span>
        </b-button>
      </div>
    </portal>
  </section>
</template>
<script>
import {
  prop,
  when,
  pipe,
  propSatisfies,
  mergeDeepRight,
  path,
} from 'ramda'
import { isNotNil, isNumber } from 'ramda-adjunct'
import { alwaysEmptyObject } from 'ramda-extension'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import {
  computed,
  ref,
  onMounted,
  inject,
  defineComponent,
} from '@vue/composition-api'
import { applySpecRejectNil } from '@/v2/lib/helpers/fp'
import { pascalToKebab } from '@/v2/lib/helpers/string'
import { useNodeWidth, widthSize } from '@/v2/lib/composition/useNodeWidth'
import { CB_ACTIONS } from '@/types/clipboard'
import { throttle } from '@/v2/lib/helpers/throttle'
import debounce from '@/v2/lib/helpers/debounce'
import configDefaults from './documentNodeRendererConfig'


const { useMutations, useState, useActions } = createNamespacedHelpers('documentEditor')
const specCond = (propName, condFn, fn) => pipe(prop(propName), when(condFn, fn))
const toPx = propName => specCond(propName, isNotNil, v => `${v}px`)
const styleSpec = applySpecRejectNil({
  marginTop: toPx('marginTop'),
  marginBottom: toPx('marginBottom'),

})
const styleSpecBg = applySpecRejectNil({
  backgroundColor: prop('backgroundColor'),
})
// For Unsplash images, we can add query params to optimize size
const optimizedImageUrl = url => {
  const isUnsplash = url && url.includes('images.unsplash.com')
  if (isUnsplash) {
    return `${url}&w=1600&h=1600&q=80`
  }
  return url
}
const styleBgImageSpec = applySpecRejectNil({
  backgroundImage: specCond('backgroundImage', isNotNil, url => `url(${optimizedImageUrl(url)})`),
  opacity: specCond('backgroundImageOpacity', isNumber, v => v / 100),
})
const styleSectionSpec = applySpecRejectNil({
  paddingTop: toPx('paddingTop'),
  paddingBottom: toPx('paddingBottom'),
})
const getKebabCasedType = pipe(path(['contentBlock$', 'category']), pascalToKebab)

export default defineComponent({
  name: 'DocumentNodeRenderer',
  props: {
    node: {
      type: Object,
      required: true,
    },
    configOverride: {
      type: Object,
      default: alwaysEmptyObject,
    },
    rootClass: {
      type: [String, Object, Array],
      default: null,
    },
    sectionClass: {
      type: [String, Object, Array],
      default: null,
    },
    contentClass: {
      type: [String, Object, Array],
      default: null,
    },
    innerClass: {
      type: [String, Object, Array],
      default: null,
    },
    mediaPrintHide: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const scrollTo = inject('scrollTo')
    const isEditor = inject('isEditor')
    const isSandbox = inject('isSandbox')
    const hideNodeComments = inject('hideNodeComments', false)
    const { load } = useNodeWidth()
    const nodeRef = ref(null)
    const pulse = ref(false)
    const isGroupBasic = computed(() => props.node.category === 'NodeGroupBasic')

    const config = computed(() => mergeDeepRight(configDefaults, props.configOverride))

    const styling = computed(() => (!config.value.ignoreNodePropStyling
      ? mergeDeepRight(config.value.styling, props.node.styling)
      : config.value.styling))

    const textVariant = computed(() => {
      const { textVariants } = config.value.stylingToolbar.colorPalette

      if (config.value.ignoreNodePropStyling) {
        return textVariants.default
      }

      return textVariants[styling.value.backgroundColor] || textVariants.default
    })

    const {
      focusNodeId,
      hoverNodeId,
      lastCreatedNodeId,
      contextMenu,
      clipboard,
      selectedNodes,
      commentsSidebarStatus,
      documentSidebarStatus,
      nodesWidth,
    } = useState([
      'focusNodeId',
      'hoverNodeId',
      'lastCreatedNodeId',
      'contextMenu',
      'selectedNodes',
      'clipboard',
      'commentsSidebarStatus',
      'documentSidebarStatus',
      'nodesWidth',
    ])


    const {
      setHover,
      setFocus,
      setLastCreated,
      setNodeVisibility,
      setContextMenuData,
    } = useMutations([
      'setHover',
      'setFocus',
      'setLastCreated',
      'setNodeVisibility',
      'setContextMenuData',
    ])
    const {
      toggleComments,
      toggleCommentsWithNode,
      addNodeWidth,
    } = useActions([
      'toggleComments',
      'toggleCommentsWithNode',
      'addNodeWidth',
    ])


    const isViewer = inject('isViewer')
    const isFocus = computed(() => props.node._id === focusNodeId.value)
    const isHover = computed(() => props.node._id === hoverNodeId.value)
    const isSelected = computed(() => selectedNodes.value.includes(props.node._id))
    const isNodeBeingCut = computed(() => clipboard.value.action === CB_ACTIONS.cut
      && clipboard.value.content.includes(props.node._id))

    const hasBgImage = computed(() => propSatisfies(isNotNil, 'backgroundImage', styling.value))

    const nodeCommentsCount = computed(() => path(['statistics', 'commentsCount'], props.node))

    const style = computed(() => styleSpec(styling.value))
    const styleBgColor = computed(() => styleSpecBg(styling.value))
    const styleBgImage = computed(() => styleBgImageSpec(styling.value))
    const styleSection = computed(() => styleSectionSpec(styling.value))
    // const cssVars = computed(() => ({
    //   '--so-block-background': styling.value.backgroundColor || '#FFFFFF',
    //   '--so-block-foreground': tinycolor.mostReadable(
    //     styling.value.backgroundColor || '#FFFFFF',
    //     ['#FFF', '#000'],
    //     {
    //       includeFallbackColors: true,
    //       level: 'AAA',
    //       size: 'small',
    //     }
    //   ).toHexString(),
    // }))
    const cssClass = computed(() => [
      {
        [`is-size-${styling.value.size}`]: config.value.styling.size,
        'background-image': hasBgImage.value,

      },
      getKebabCasedType(props.node),
    ])

    // Width of current node
    const nodeWidth = computed(() => nodesWidth.value[props.node._id])

    // Clicking in the content block should always set focus to the node
    const onSetFocus = () => {
      // We don't want focus to be available for columns
      if (!props.node.isGroupColumn) {
        // If the current node is not currently focused, focus it
        if (!isFocus.value) {
          setFocus(props.node._id)
        }
      } else {
        // if a group column, remove focus when clicking outside the content
        // in the column
        setFocus(null)
      }
    }

    // Clicking outside the content block shoudl toggle focus on/off
    const onToggleFocus = () => {
      // If not a group
      if (!props.node.isGroupColumn) {
        // If the current node is not currently focused, focus it
        if (!isFocus.value) {
          setFocus(props.node._id)
        } else {
          // otherwise remove the focus
          setFocus(null)
        }
      } else {
        // if a group column, remove focus when clicking outside the content
        // in the column
        setFocus(null)
      }
    }

    const onBlur = () => isFocus.value && setFocus(null)
    const onMouseOver = () => !isHover.value
        && isHover.value !== props.node._id
        && setHover(props.node._id)
    const onMouseLeave = () => isHover.value && setHover(null)

    const onVisibilityChanged = throttle(isVisible => {
      setNodeVisibility({
        nodeId: props.node._id,
        isVisible,
      })
    })

    const scrollIntoView = () => {
      const offset = Math.round(window.innerHeight / 3)
      scrollTo(nodeRef.value, -offset)
    }

    const onContextMenuOpen = event => setContextMenuData({
      nodeId: props.node._id,
      left: event.clientX,
      top: event.clientY,
    })

    const loadNodeWidth = debounce(async () => {
      const _nodeWidth = await load(props.node._id)
      addNodeWidth(widthSize({ [props.node._id]: _nodeWidth }))
    }, 10)

    onMounted(() => {
      loadNodeWidth()
      if (lastCreatedNodeId.value === props.node._id) {
        setLastCreated(null)
        context.root.$nextTick(() => {
          scrollIntoView()
          pulse.value = true
          setTimeout(() => {
            pulse.value = false
          }, 3 * 800)
        })
      }
    })

    const handleContextmenuAction = event => {
      // Restore default contextmenu behaviour when viewing a
      // shared document
      if (isViewer.value) return
      event.stopPropagation()
      event.preventDefault()
      onContextMenuOpen(event)
    }

    return {
      nodeRef,
      pulse,
      contextMenu,
      commentsSidebarStatus,
      documentSidebarStatus,

      // config
      config,
      styling,
      textVariant,

      // css style
      style,
      styleBgColor,
      styleBgImage,
      styleSection,

      // css classes
      cssClass,
      // cssVars,

      // flags
      hasBgImage,
      isFocus,
      isHover,
      isSelected,
      isNodeBeingCut,
      nodeCommentsCount,
      isEditor,
      hideNodeComments,
      isViewer,
      isGroupBasic,
      isSandbox,

      // callbacks
      onSetFocus,
      onToggleFocus,
      onBlur,
      onMouseOver,
      onMouseLeave,
      onVisibilityChanged,
      onContextMenuOpen,
      // toggleCommentsSidebar,
      toggleComments,
      toggleCommentsWithNode,
      handleContextmenuAction,
      // test
      nodesWidth,
      nodeWidth,
    }
  },
})
</script>

<style lang="scss" scoped>
.node {
  position: relative;
}

.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background-image-overlay {
  position: absolute;
  //z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.pulse-me {
  animation: pulse 0.8s 1 ease-in;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 199, 73, 1);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(52, 199, 73, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 199, 73, 0);
  }
}
</style>

<style lang="postcss" scoped>
.so-node-cut {
}

.so-node-cut .block-section-wrapper::after {
  content: "";
  @apply absolute inset-0 bg-opacity-25 z-1000 opacity-30;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(0,0,0,.2) 10px,
    rgba(0,0,0,.2) 20px
  );
}

.comment-count {
  @apply text-green-600 text-11 font-bold leading-none;
  @apply items-center absolute py-px text-center z-10;
  @apply select-none pointer-events-none;
  @apply top-2 right-0 px-3 rounded-sm;
  @apply bg-green ring-1 ring-gray-800;
}
.comment-count-on-node {
  @apply text-white text-10 font-bold leading-none;
  @apply items-center absolute text-center z-10;
  @apply select-none pointer-events-none;
  @apply top-4 right-2;
  @apply bg-green-500 rounded-full h-14 px-4 inline-flex items-center justify-center;
}
</style>
