<template>
  <b-alert
    show
    :variant="variant"
    :class="$style.alert"
  >
    <div v-if="!supressGenericMessage && (variant === 'danger')" class="text-11">
      There was an error submitting the form
    </div>
    <slot />
  </b-alert>
</template>
<script>
export default {
  name: 'FormMessage',
  props: {
    variant: {
      type: String,
      default: 'danger',
    },
    supressGenericMessage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" module>
.alert {
  //color: white;
  border: none;
  margin: $gutter 0;
  font-size: $fs16;
  line-height: 1.4;
  &:global(.alert-warning) {
    background-color: $warning;
  }
  &:global(.alert-danger) {
    //background-color: $danger;
    margin-bottom: $gutterx2;
  }
  a {
    //color: white;
    text-decoration: underline;
    font-size: $fs14;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    margin: 0 0 $gutter;
  }
}
</style>
