<template>
  <div
    class="iframely-wrapper w-full overflow-hidden rounded-lg mt-24 bg-white"
    v-html="app.data.html"
  />
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ProjectEmbeddedApp',
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
})
</script>
<style lang="postcss" scss>
.iframely-responsive {
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56%;
}
.iframely-responsive > * {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
}

.iframely-embed {
  @apply mx-auto flex-1;
  max-width: none !important;
}

.iframely-wrapper {
}

.iframely-wrapper > * {
  @apply mx-auto;
}
</style>
