<template>
  <div
    class="w-full relative h-full"
    :style="widthStyle"
  >
    <img
      :style="cssStyle"
      class="h-full w-full"
      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ImageThumbnail',
  props: {
    url: String,
    alt: String,
    width: Number,
    height: Number,
    maxWidth: Number,
    maxHeight: Number,
    variant: String,
    noMinHeight: Boolean,
  },
  computed: {
    widthStyle() {
      return {
        width: this.width && `${this.width}px`,
      }
    },
    cssClass() {
      return {
        [`variant-${this.variant}`]: this.variant,
      }
    },
    cssStyle() {
      return {
        backgroundImage: `url('${this._url}')`,
        width: this.width && `${this.width}px`,
        height: this.height && `${this.height}px`,
      }
    },
    _url() {
      if (this.url.startsWith('blob:')) {
        return this.url
      }

      const width = this.width || this.maxWidth
      const height = this.height || this.maxHeight

      const qs = [width && `width=${width}`, height && `height=${height}`]
        .filter(Boolean)
        .join('&')

      return qs ? `${this.url}?${qs}` : this.url
    },
  },
}
</script>
<style lang="scss" scoped>
.variant-circle {
  border-radius: 50%;
}
</style>
