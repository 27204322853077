<template>
  <div>
    <button
      v-if="hasColor"
      ref="buttonRef"
      v-b-hover="handleHover"
      type="button"
      :class="[
        'btn-color',
        sizeMap[size].buttonSize,
        'rounded-full relative inline-flex items-center',
        'justify-center transition-all outline-none focus:outline-none',
      ]"
      :style="cssVars"
      @click="$emit('color-palette-select')"
    >
      <!-- v-b-tooltip.hover.bottom.v-info.dh0.ds200 -->
      <span
        :class="[
          'icon_v2-so_pencil transition-all invisible opacity-0',
          sizeMap[size].iconSize,
        ]"
      />
    </button>
    <!-- IF NO COLOR -->
    <button
      v-else
      ref="buttonRef"
      v-b-hover="handleHover"
      type="button"
      :class="[
        sizeMap[size].buttonSize,
        'text-white bg-darkGray-800/60',
        'outline outline-2 outline-darkGray-800/80',
        'rounded-full relative inline-flex items-center',
        'justify-center transition-all',
      ]"
      :style="cssVars"
      @click="$emit('color-palette-select')"
    >
      <!-- v-b-tooltip.hover.bottom.v-info.dh0.ds200 -->
      <span
        :class="[
          'material-add transition-all',
          sizeMap[size].iconSize,
        ]"
      />
    </button>
    <b-popover
      v-if="buttonRef"
      ref="popoverRef"
      :placement="popoverPlacement"
      :no-fade="noFade"
      :target="buttonRef"
      :triggers="triggers"
      :custom-class="
        [
          'popover-light popover-color-picker',
          'p-0 mt-12 w-280',
          'bg-gray border border-gray-200 rounded-md shadow-xl max-w-none',
        ].join(' ')
      "
    >
      <ColorPicker
        :value="value"
        click-outside
        @input="handleColorPick"
        @remove-color="$emit('remove-color')"
      />
      <!-- EDIT MODE -->
      <div
        v-if="variant === 'edit'"
        :class="[
          'flex justify-between p-16 border-t border-gray-100',
          'bg-white overflow-hidden rounded-b-md items-center',
        ]"
      >
        <b-link
          class="text-gray-700 underline hover:no-underline hover:text-gray-900"
          @click.stop="$emit('remove-color')"
        >
          Remove
        </b-link>
        <Button
          type="submit"
          variant="primary"
          class="w-116"
          @click="closePopover"
        >
          Save
        </Button>
      </div>

      <!-- PICK MODE -->
      <section v-if="variant === 'pick'" class="flex flex-col pb-16">
        <ColorPickerPresets
          :selected-value="value"
          :presets="presets"
          @pick-color-from-presets="color => $emit('input', color)"
        />
      </section>
    </b-popover>
  </div>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { isNilOrEmpty } from 'ramda-adjunct'
import ColorPicker from '@/components/ColorPicker.vue'
import ColorPickerPresets from '@/components/ColorPickerPresets.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'ColorPaletteItem',
  components: {
    ColorPicker,
    ColorPickerPresets,
    Button,
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    noFade: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'edit',
    },
    presets: {
      type: Array,
      default: () => [],
    },
    popoverPlacement: {
      type: String,
      default: 'top',
    },
    size: {
      type: Number,
      default: 36,
    },
    triggers: {
      type: String,
      default: 'click blur',
    },

  },
  setup(props, { emit }) {
    const sizeMap = {
      56: {
        buttonSize: 'w-56 h-56',
        iconSize: 'text-24 w-24',
      },
      42: {
        buttonSize: 'w-42 h-42',
        iconSize: 'text-24 w-24',
      },
      36: {
        buttonSize: 'w-36 h-36',
        iconSize: 'text-24 w-24',
      },
      32: {
        buttonSize: 'w-32 h-32',
        iconSize: 'text-20 w-20',
      },
      24: {
        buttonSize: 'w-24 h-24',
        iconSize: 'text-12 w-12',
      },
    }

    const popoverRef = ref(null)
    const buttonRef = ref(null)
    const isHovered = ref(false)

    const handleHover = () => {
      isHovered.value = !isHovered.value
    }

    const handleColorPick = color => {
      emit('input', color)
    }

    const hasColor = computed(() => !isNilOrEmpty(props.value))

    const cssVars = computed(() => ({
      '--bg-color': props.value || '#E5E7EB',
    }))

    const closePopover = () => {
      popoverRef.value.$emit('close')
    }

    const openPopover = () => {
      buttonRef.value.click()
    }

    onMounted(() => {
      if (props.isNew) {
        setTimeout(openPopover)
      }
    })

    return {
      // STATIC
      sizeMap,
      popoverRef,
      buttonRef,

      // FLAGS
      isHovered,
      hasColor,

      // COMPUTED
      cssVars,

      // METHODS
      handleColorPick,
      handleHover,
      closePopover,
      openPopover,
    }
  },
}
</script>
<style lang="scss" scoped>
.btn-color {
  background-color: var(--bg-color);
  position: relative;
  span {
    color: theme('colors.gray.700');
    mix-blend-mode: difference;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    mix-blend-mode: difference;
    border: 2px solid theme('colors.gray.800');
    border-radius: 100%;
    pointer-events: none;
  }
}
.btn-color[aria-describedby^='__bv_popover_'],
.btn-color:hover {
  //box-shadow: inset 0 0 0 2px var(--box-shadow);
  span {
    opacity: 1;
    visibility: visible;
  }
  &::after {
    border-width: 2px;
  }
}
</style>
