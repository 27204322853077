<template>
  <div
    class="h-full flex flex-col justify-center items-center flex-1 flex-grow bg-violet-100"
    :class="{ 'min-h-[197px]': !noMinHeight }"
  >
    <span class="standard-camcorder text-64 w-64 opacity-70"></span>
    <span class="text-12 uppercase text-center font-semibold opacity-50 mt-4">Video File</span>
  </div>
</template>

<script>
export default {
  name: 'VideoThumbnail',
  props: {
    file: {
      type: Object,
      required: true,
    },
    noMinHeight: Boolean,
  },
  setup(props) {
    return {

    }
  },

}
</script>
<style lang="postcss" scoped>

</style>
