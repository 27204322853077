<template>
  <div class="space-y-12">
    <h2 class="title-h2 text-center">
      {{ $t('login.password-create') }}
    </h2>
    <p class="text-center">
      {{ $t('login.password-requirement') }}
    </p>
    <FormMessage v-if="formError">
      {{ formError.message }}
    </FormMessage>
    <b-form class="space-y-12" @submit.prevent="formSubmit">
      <b-form-group class="mb-0">
        <FormField
          :v="$v.password"
          required
          :messages="{
            required: $t('login.password-validation-required'),
            minLength: $t('login.password-validation-min-length'),
          }"
        >
          <FormInputPassword
            v-model="formState.password"
            autofocus
            :placeholder="$t('login.password-new-placeholder')"
          />
        </FormField>
      </b-form-group>
      <b-form-group class="mb-0">
        <FormField
          :v="$v.passwordConfirm"
          required
          :messages="{
            required: $t('login.password-confirm-validation-required'),
            sameAs: $t('login.password-confirm-validation-match'),
          }"
        >
          <FormInputPassword
            v-model="formState.passwordConfirm"
            :placeholder="$t('login.password-confirm-placeholder')"
          />
        </FormField>
      </b-form-group>
      <Button
        type="submit"
        size="lg"
        variant="primary"
        :block="true"
        :is-loading="formIsBusy"
        :class="buttonClass"
      >
        {{ $t('login.password-save') }}
      </Button>
    </b-form>
  </div>
</template>

<script>
import { reactive, toRef } from '@vue/composition-api'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { tokens } from '@/v2/services'
import useNotification from '@/v2/lib/composition/useNotification'
import useForm from '@/v2/lib/composition/useForm'
import { useConfirmPasswordReset } from '@/v2/services/accounts/accountsCompositions'
import { isCodeValid } from '@/v2/lib/helpers/validators'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'
import FormInputPassword from '@/components/FormInputPassword.vue'

export const STATE = /** @type {const} */ ({
  TOKEN_ERROR: 'TOKEN_ERROR',
  TOKEN_VALID: 'TOKEN_VALID',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  PASSWORD_ERROR: 'PASSWORD_ERROR',
})

export default {
  name: 'RecoverPasswordFormReset',
  components: {
    FormField,
    Button,
    FormMessage,
    FormInputPassword,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    buttonClass: {
      type: [String, Object, Array],
      default: null,
    },
  },
  setup(props, context) {
    const notification = useNotification()
    const confirmPasswordReset = useConfirmPasswordReset()
    let token;

    const handleError = err => {
      notification({
        message: String(err),
        variant: 'danger',
      })
    }

    const stateChange = newState => context.emit('state-change', newState)

    const fetchPasswordRequest = async () => {
      try {
        if (!isCodeValid(props.token)) {
          throw new Error('Invalid reset code')
        }
        // Fetch data regarding the password reset token
        token = await tokens.get(props.token)

        if (!token) {
          throw new Error('Recover Password Request Not Found')
        }
        stateChange(STATE.TOKEN_VALID)
      } catch (err) {
        handleError(err)
        stateChange(STATE.TOKEN_ERROR)
      }
    }

    const formState = reactive({
      password: '',
      passwordConfirm: '',
    })

    const submit = async () => {
      try {
        await confirmPasswordReset({
          accountsRequestId: token.accounts,
          newPassword: formState.password,
          token: props.token,
        })

        stateChange(STATE.PASSWORD_CHANGED)
      } catch (err) {
        handleError(err)
        stateChange(STATE.PASSWORD_ERROR)
      }
    }

    const { formSubmit, formError, formIsBusy, $v } = useForm({
      onSubmit: submit,
      state: formState,
      validation: {
        password: {
          required,
          minLength: minLength(8),
        },
        passwordConfirm: {
          sameAs: sameAs(toRef(formState, 'password')),
        },
      },
    })

    fetchPasswordRequest()

    return {
      formIsBusy,
      formState,
      formSubmit,
      formError,
      $v,
    }
  },
}
</script>
