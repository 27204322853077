/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    sidebarIsExpanded: true,
    sidebarIsFloating: false,
    showAnnouncementCard: true,
    isAppSumoRegistration: false,
    showQuickStart: false,
    taskEditorNoClose: false,
  },
  mutations: {
    setSidebarIsExpanded(state, value) {
      state.sidebarIsExpanded = value
    },
    setSidebarIsFloating(state, value) {
      state.sidebarIsFloating = value
    },
    setShowAnnouncementCard(state, value) {
      state.showAnnouncementCard = value
    },
    setIsAppSumoRegistration(state, value) {
      state.isAppSumoRegistration = value
    },
    setShowQuickStart(state, value) {
      state.showQuickStart = value
    },
    setTaskEditorNoClose(state, value) {
      state.taskEditorNoClose = value
    },
  },
}
