
<div
  class="group p-8 flex flex-col gap-8 rounded-md"
  :class="{
    'border border-yellow-50': showNote,
    'px-10 py-6': !dense,
    'hover:bg-gray-100 dark:hover:bg-darkGray-500': !$isMobile,
  }"
>
  <div
    class="flex items-start gap-8 "
    @dblclick="!isViewer && $emit('edit-item')"
  >
    <button
      v-if="!disableDrag"
      v-handle
      class="
        w-16 flex items-center justify-center
        text-gray-600 hover:text-gray-700 opacity-25 group-hover:opacity-100
        dark:text-darkGray-400 dark:hover:text-darkGray-400
      "
      title="Drag to move"
      @click.stop
    >
      <span class="icon_v2-so_drag" />
    </button>
    <div>
      <b-form-checkbox
        :checked="item.isResolved"
        class="custom-checkbox-list"
        @change="val => $emit('checkbox-change', val)"
      />
    </div>
    <div
      class="flex-1 w-128"
      :class="{ 'flex items-center justify-between': !dense }"
    >
      <div
        class="text-gray-800 dark:text-white flex gap-8"
        :class="{ 'flex-wrap': !dense }"
      >
        <div
          class="text-14"
          :class="{
            'resolved': item.isResolved,
            'pending': !item.isResolved,
            'due': !item.isResolved && item.isDue
          }"
        >
          {{ item.title }}
        </div>
        <div
          v-if="!dense && (isItemAuthor || !isClientContact)"
          class="items-center gap-8"
          :class="{
            'hidden group-hover:flex opacity-25 group-hover:opacity-100': !$isMobile,
            'flex': $isMobile
          }"
        >
          <ButtonIcon
            icon="icon_v2-so_pencil"
            class="dark:text-darkGray-400"
            size="18"
            @click="$emit('edit-item')"
          />
          <ButtonIcon
            icon="icon_v2-so_trash"
            class="dark:text-darkGray-400"
            size="18"
            @click="$emit('delete-item')"
          />
        </div>
      </div>
      <div
        class="flex items-center gap-8"
        :class="{ 'mt-4 self-start': !dense }"
      >
        <ActionItemDateDisplay
          v-if="item.dueDate && !item.isResolved"
          v-slot="{ displayDate }"
          :item="item"
        >
          <span class="icon-util-calendar text-16" />
          {{ displayDate.message }}
        </ActionItemDateDisplay>
        <ActionItemAssignedTo :action-item="item" />
        <ActionItemNoteButton
          v-if="item.notes"
          :active="showNote"
          @click="showNote = !showNote"
        />
        <ActionItemNode
          v-if="item.documentNode && !disableDrag"
          @click="$emit('action-item-scroll')"
        />
        <ClientVisibilityBadge
          v-if="!isViewer && !dense"
          :visible="item.public"
          variant="icon"
        />
      </div>
    </div>
    <div>
      <ActionItemMenu
        v-if="dense && !hideMenu"
        class="opacity-25 group-hover:opacity-100"
        @edit="$emit('edit-item')"
        @remove="$emit('delete-item')"
      />
    </div>
  </div>
  <ActionItemNote
    v-if="showNote"
    :action-item="item"
  />
</div>
