<template>
  <b-form-rating
    :value="value"
    class="p-0 space-x-4 bg-transparent b-rating-decimal"
    no-border
    :stars="steps"
    :disabled="isLocked"
    size="lg"
    number
    @change="value => $emit('update', { answer: value, isDirty: true })"
  >
    <template slot="icon-full"><i></i></template>
    <template slot="icon-empty"><i></i></template>
  </b-form-rating>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  name: 'DocumentContentBlockQARatingNumber',
  components: {},
  props: {
    value: {
      type: Number,
    },
    steps: {
      type: Number,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup() {
    const isLocked = inject('isLocked')
    return {
      isLocked,
    }
  },
}
</script>
