
<LoaderWebFont :families="[styling.font || paragraphFont]">
  <div
    :class="[
      'block-element-text',
      `text-variant-${textVariant}`,
      `text-${styling.textAlign}`,
      'portal-paragraph-font',
    ]"
    :style="{
      color: styling.textColor,
      fontFamily: styling.font || paragraphFont,
      ...cssVars,
    }"
    data-intercom-target="Rich Text Block"
    @contextmenu.stop
  >
    <TextEditor
      v-if="isEditable"
      :lazy="true"
      :value="contentBlock.content"
      @input="val => $emit('update', { content: val })"
    />
    <div
      v-else
      class="text-viewer editor-content editor-content-preview"
      v-html="contentBlock.content"
    />
  </div>
</LoaderWebFont>
