import feathersClient from '@/v2/services/feathersClientService'
import { keepModelFields, paramsForServer } from '@/v2/lib/hooks'

export const servicePath = 'documents'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [
      ctx => {
        ctx.params = paramsForServer(ctx.params, 'embed')
      },
    ],
    create: [
      keepModelFields('fieldsCreate'),
    ],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service
