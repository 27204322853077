import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import organizationsService, { servicePath } from './organizationsService'
import { DOMAIN_STATUS, EMAIL_STATUS } from './organizationsTypes';

class Organization extends BaseModel {
  static modelName = 'Organization'

  static instanceDefaults() {
    return {
      statistics: {
        countDocuments: 0,
      },
      counters: {},
      subdomain: '',
      domain: '',
      domainStatus: null,
      domainError: null,
      email: '',
      emailDKIMHost: '',
      emailDKIMTextValue: '',
      emailSenderName: '',
      emailStatus: null,
      emailError: null,
      brandingFontHeading: 'Inter',
      brandingFontParagraph: 'Inter',
      brandingColors: [],
      featureFlags: {},
      logoUrl: '',
      logoDarkUrl: '',
      locale: 'en',
      portalLoginCustomizations: {
        appearance: 'light',
        pageLayout: 'floating',
        backgroundColor: '#f6f6f6',
        backgroundImage: null,
        backgroundImageOpacity: 100,
        showFormBackground: true,
        heading: '',
        welcomeMessage: 'We\'ll email you a magic link for password-free sign in.',
        loginButtonColor: '#23b839',
        loginButtonTextColor: '#FFFFFF',
        loginButtonLabel: 'Sign In',
        customContent: '',
      },
      trialExtendAllow: false,
      trialExtendRequested: false,
      portalLoginMagicLinkEnabled: false,
      portalLoginMagicLinkNoExpire: false,
      portalLoginPasswordEnabled: false,
    }
  }

  get isDomainActive() {
    return this.domainStatus === DOMAIN_STATUS.active
  }

  get isEmailActive() {
    return this.emailStatus === EMAIL_STATUS.active
  }
}

export default makeServicePlugin({
  Model: Organization,
  service: organizationsService,
  servicePath,
})
