
<ProjectTimelineRendererGroup :notification="notification" icon="icon_v2-so_chat">
  <template #label>{{ $t('project-updates.comments-resolved') }}</template>
  <template #event="{ event, document }">
    <DocumentLink v-slot="{ navigate }" :document="document">
      <div>
        <div class="flex items-center mb-2" @click="navigate">
          <span
            class="
          text-gray-400 text-24 material-check_circle_outline
          dark:text-darkGray-600
        "
          />
          <span class="dark:text-darkGray-300">{{ event.subject$?.message ?? '?' }}</span>
        </div>
        <ProjectTimelineItemAuthor
          :notification="event"
          :document="event.document$"
        />
      </div>
    </DocumentLink>
  </template>
</ProjectTimelineRendererGroup>
