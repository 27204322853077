import { computed } from '@vue/composition-api'
import { isNonEmptyString } from 'ramda-adjunct'
import { getCurrentInstanceOrThrow } from './helpers'

export default function useLocalization() {
  const vm = getCurrentInstanceOrThrow()

  /**
   * @param {String} messageKey Name of the key (path) to message
   * @param {import('vue-i18n').Values} interpolatedVariables dynamic variables to be interpolated
   * in translation
   */
  function translate(messageKey, interpolatedVariables = {}) {
    return computed(() => vm.$i18n.t(messageKey, interpolatedVariables))
  }

  function translateDate(messageKey, dateFormat = {}) {
    return computed(() => vm.$i18n.d(messageKey, dateFormat))
  }

  function pluralization(messageKey, count, interpolatedVariables = {}) {
    return computed(() => vm.$i18n.tc(messageKey, count, interpolatedVariables))
  }

  async function setLocale(language = null) {
    const i18n = vm.$root.$i18n
    const lang = isNonEmptyString(language) ? language : i18n.locale
    const { default: messages } = await import(`../../../locales/${lang}.json`)
    i18n.setLocaleMessage(lang, messages)
    i18n.locale = lang
  }

  return {
    translate,
    t: translate,
    pluralization,
    translateDate,
    setLocale,
  }
}
