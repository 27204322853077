
<component
  :is="mediaThumbnail"
  v-bind="$attrs"
  :file="file"
  :url="file.url"
  :alt="file.name"
  :no-min-height="noMinHeight"
  v-on="$listeners"
/>
