import feathersClient from '@/v2/services/feathersClientService'

export const servicePath = 'document-structures'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      // optimistic update, discard server response
      context => {
        context.result = null
        return context
      },
    ],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service
