
<FeathersVuexFind
  v-slot="{ items: members }"
  :params="findMembersParams"
  service="myMembers"
  local
>
  <div v-if="members?.length > 1" class="flex items-center">
    <ButtonIcon
      ref="button"
      v-b-tooltip.hover
      icon="icon_v2-so_switch"
      title="Switch Organization"
    />
    <b-popover
      v-if="button"
      :target="button"
      triggers="click blur"
      placement="bottomright"
      custom-class="w-320 shadow-2xl border-gray-300"
      boundary="window"
    >
      <!-- <template #title>Switch Organization</template> -->
      <ul class="max-h-240 overflow-y-auto  flex flex-col gap-y-4">
        <li
          v-for="member in members"
          :key="member._id"
          class="hover:bg-gray-100 p-4 rounded-md px-8"
          :class="{ 'bg-gray-100': member.organization === currentOrganization?._id }"
        >
          <ButtonTransparent
            class="w-full !justify-start font-semibold"
            @click="onSelectMember(member)"
          >
            <div class="flex justify-between w-full">
              {{ member.organization$.name }}
              <span
                v-if="member.organization === currentOrganization?._id"
                class="icon_v2-so_tick text-gray-400"
              />
            </div>
          </ButtonTransparent>
        </li>
      </ul>
    </b-popover>
  </div>
</FeathersVuexFind>
