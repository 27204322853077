
<div
  class="space-y-24"
  @contextmenu.stop
>
  <!-- input -->
  <b-form
    v-if="isEditor && !isLocked"
    class="relative max-w-prose mx-auto"
    @submit.prevent="onSubmit"
  >
    <span
      :class="[
        'icon-object-embed w-24 text-24 text-gray-500',
        'absolute left-10 top-10',
      ]"
    />
    <b-form-input
      :id="componentID"
      v-model="localUrl"
      type="url"
      size="lg"
      placeholder="Paste your https:// link here..."
      class="pl-40"
    />
    <b-button
      :variant="isBlankState ? 'primary' : 'secondary'"
      :disabled="!localUrl"
      type="submit"
      class="absolute right-5 top-5"
    >
      {{ isBlankState ? 'Embed' : 'Refresh' }}
    </b-button>
  </b-form>

  <FormMessage v-if="error">
    {{ error }}
  </FormMessage>

  <!-- response -->
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div
    v-if="!isBlankState"
    ref="iframeContainer"
    class="iframely-wrapper w-full flex"
    v-html="htmlSanitized"
  />
  <!-- url not supported -->
</div>
