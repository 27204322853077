<template>
  <div
    class="text-14 font-semibold flex items-center h-32 group w-full"
    :class="{ 'dark': darkTheme }"
    v-on="$listeners"
  >
    <LabelAvatar
      size="20"
      :label="letterAvatar"
      :background-color="project?.portalAccentColor"
    />
    <div class="text-13 min-w-0 ml-6 leading-none grow">
      <p class="flex-1 leading-snug truncate">
        {{ project.name }}
      </p>
      <p
        v-if="showClient && project.client$"
        class="leading-none text-gray-600 truncate opacity-50 text-11 dark:text-darkGray-600"
      >
        {{ project.client$.name }}
      </p>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import LabelAvatar from '@/components/LabelAvatar.vue'

export default defineComponent({
  name: 'ProjectSwitcherItem',
  components: {
    LabelAvatar,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    showClient: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const letterAvatar = computed(() => props.project.name.trim().slice(0, 1) ?? '')

    return {
      letterAvatar,
    }
  },
})
</script>
