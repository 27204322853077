<template>
  <!-- SIDEBAR -->
  <div
    v-if="variant==='sidebar'"
    class="flex flex-col w-full"
    v-bind="$attrs"
  >
    <!-- Approval specs -->
    <div
      class="
        flex flex-col items-start pb-12
        border border-dotted border-green-600 border-opacity-50
        rounded-lg items-center text-center overflow-hidden
        bg-green-200 bg-opacity-20 dark:bg-opacity-10
      "
    >
      <!-- Button Badge -->
      <div
        class="
          relative flex grow w-full flex-row items-center justify-center'
          px-8 py-6 leading-none
          font-bold transform bg-green-200 text-12
          text-green-700
          border-b border-dotted border-green-600 border-opacity-50
        "
      >
        <span class="w-16 mr-4 -ml-2 text-16 icon_v2-so_tick" />
        {{ $t('common.approved').toUpperCase() }}
      </div>
      <ul
        class="
          w-full grid grid-cols-2 list-none leading-none gap-16 pt-12
          px-12 divide-x divide-dotted divide-green-600 divide-opacity-50 text-center
        "
      >
        <li>
          <small class="text-green-800 dark:text-green-200 opacity-60 text-12">
            {{ $t('blocks.approval-button.approved-on') }}
          </small>
          <p class="font-medium leading-none text-green-800 dark:text-green-200 text-13">
            {{ $d(new Date(document.approvedAt),'long') }}
          </p>
        </li>
        <li>
          <small class="block text-green-800 dark:text-green-200 opacity-60 text-12">
            {{ $t('blocks.approval-button.approved-by') }}
          </small>
          <div class="font-medium text-green-800">
            <AvatarUser
              v-if="document.approvedBy"
              :user-id="document.approvedBy"
              font-size="13"
              :show-avatar="false"
            />
            <p v-else class="font-bold text-green-800 dark:text-green-200">
              {{ document.approvedByName }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <!-- Description (only show this for editors) -->
    <div
      v-if="isEditor"
      class="mt-16 leading-normal text-center text-gray-600 text-13"
    >
      <slot name="description" />
    </div>
  </div>

  <!-- FULL IN DOCUMENT -->
  <div
    v-else-if="document.isApproved"
    class="flex flex-col justify-center mt-32 print:block print:mx-32"
  >
    <!-- Approval specs -->
    <div
      class="
        flex flex-col-reverse justify-between
        p-8 border border-green-200 border-dotted rounded-lg bg-green border-lg
        lg:items-center lg:p-24 lg:space-y-0 lg:space-x-32 lg:flex-row print:flex-row print:p-24
      "
    >
      <ul
        class="
          flex flex-row justify-center space-x-32 list-none
          grow lg:justify-start print:justify-start
        "
      >
        <li>
          <small class="text-green-800 text-14">
            {{ $t('blocks.approval-button.approved-on') }}
          </small>
          <p class="font-bold text-green-600">
            {{ $d(new Date(document.approvedAt),'long') }}
          </p>
        </li>
        <li>
          <small class="block text-green-800 text-14">
            {{ $t('blocks.approval-button.approved-by') }}
          </small>
          <AvatarUser
            v-if="document.approvedBy"
            :user-id="document.approvedBy"
          />
          <p v-else class="font-bold text-green-600">
            {{ document.approvedByName }}
          </p>
        </li>
      </ul>
      <!-- Button Badge -->
      <div
        class="
          relative inline-flex flex-row items-center self-center justify-center
          px-16 py-6 rounded-lg -rotate-3 -top-20 lg:top-auto
          font-bold text-green-700 transform bg-green-200 text-24
          print:top-auto
        "
      >
        <span class="w-32 mr-4 -ml-6 text-32 icon_v2-so_tick" />
        {{ $t('common.approved').toUpperCase() }}
      </div>
    </div>
    <!-- Description (only show this for editors) -->
    <div
      v-if="isEditor"
      class="mt-16 leading-normal text-center text-gray-600 text-14"
    >
      <slot name="description" />
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'
import AvatarUser from '@/components/AvatarUser.vue'

export default defineComponent({
  name: 'DocApprovalBadge',
  components: {
    AvatarUser,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: 'full',
    },
  },
  setup() {
    const isEditor = inject('isEditor', false)

    return {
      isEditor,
    }
  },
})
</script>
