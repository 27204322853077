
<svg
  class="svg-indicator"
  :style="{ width: `${variant.size}px`, height: `${variant.size}px` }"
>
  <circle
    class="svg-indicator-track"
    :cx="center"
    :cy="center"
    fill="transparent"
    :r="radius"
    :stroke-width="variant.trackWidth"
  />
  <circle
    class="svg-indicator-indication"
    :cx="center"
    :cy="center"
    fill="transparent"
    :r="radius"
    :stroke-width="variant.indicatorWidth"
    :stroke-dasharray="dashArray"
    :stroke-dashoffset="dashOffset"
  />
</svg>
