<template>
  <div class="flex flex-col gap-8 mr-24">
    <label
      v-if="title"
      class="mb-6 font-medium text-gray-500 uppercase text-11 hover:text-gray-500"
    >
      {{ title }}
    </label>
    <ul class="filter-list">
      <li
        v-for="item in sortedItems"
        :key="item.id"
        @click="$emit('select', item)"
      >
        <router-link
          v-if="item.to"
          v-slot="{ href, navigate, isActive }"
          :to="item.to"
          custom
        >
          <a :href="href" @click="navigate">
            <CategoryListItem
              :item="item"
              :active="isActive"
            />
          </a>
        </router-link>
        <CategoryListItem
          v-else
          :item="item"
          :active="item.id === activeId"
          @click="$emit('select', item)"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import CategoryListItem from './CategoryListItem.vue'

export default defineComponent({
  name: 'CategoryList',
  components: {
    CategoryListItem,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    activeId: {
      type: String,
      default: null,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDir: {
      type: String,
      default: 'asc',
      validator: value => ['asc', 'desc'].includes(value),
    },
  },
  setup(props) {
    const sortedItems = computed(() => {
      if (!props.sortBy) {
        return props.items
      }

      return [...props.items].sort(
        (a, b) => a[props.sortBy].localeCompare(b[props.sortBy])
      );
    })

    return {
      sortedItems,
    }
  },
})
</script>
