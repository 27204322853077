<script>
import { isString } from 'ramda-adjunct'
import { watch, computed } from '@vue/composition-api'
import WebFont from 'webfontloader'

const PREVIEW_CHARS = 'thequickbrownfoxjumpsoverthelazydogTHEQUICKBROWNFOXJUMPSOVERTHELAZYDOG'

// By default, Google Fonts only includs the "normal" weight of each font
// in our case, we need more than just one weight so we need to pass that
// to the font loader.
// Documentation: https://github.com/typekit/webfontloader#google

export default {
  name: 'LoaderWebFonts',
  props: {
    families: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // By default, Google Fonts only includs the "normal" weight of each font
    // in our case, we need more than just one weight so we need to pass that
    // to the font loader.
    // Documentation: https://github.com/typekit/webfontloader#google

    // Weights we get from Google Fonts
    const families = computed(() => props.families)
    const weights = '300,400,500,600,700,800,900'

    const checkForFontLoaded = fontName => {
      if (!isString(fontName)) {
        return true
      }
      // as per https://github.com/typekit/webfontloader/issues/423

      // Google Fonts replaces ' ' with '+' in font names in the URL.
      // so font will be Something Something but the URL will be Something+Something
      const cleanFontName = fontName.replace(' ', '+')
      // const fontURI = `https://fonts.googleapis.com/css?family=${cleanFontName}:${weights}`
      const links = Array.from(document.head.getElementsByTagName('link'))
      if (!links || !links.length) return false
      // HOTFIX
      return links.find(
        link => link.hasAttribute('href')
          && link.getAttribute('href').includes(`${cleanFontName}:${weights}`)
      )
    }

    const newFontsToLoad = computed(() => {
      const newFontsWithWeights = []
      // If empty, don't do anything
      if (!props.families || !props.families.length) return newFontsWithWeights
      // If not empty, check which are the new fonts not already loaded
      props.families.forEach(font => {
        const isFontAlreadyLoaded = checkForFontLoaded(font)
        // If this is a new font, add it to the array we send over to webfontloader
        if (!isFontAlreadyLoaded || props.preview) {
          newFontsWithWeights.push(`${font}:${weights}`)
        }
      })
      return newFontsWithWeights
    })

    watch(
      () => props.families,
      () => {
        if (families.value && families.value.length) {
          if (props.preview || newFontsToLoad.value.length) {
            WebFont.load({
              google: {
                families: props.preview ? families.value : newFontsToLoad.value,
                text: props.preview ? PREVIEW_CHARS : '',
              },
              loading: () => context.emit('loading'),
              active: () => context.emit('active'),
            })
          }
        }
      },
      { immediate: true }
    )

    return () => context.slots.default?.({})
  },
}
</script>
