export function round(value, decimals = 2) {
  if (decimals === 0) {
    return Math.round(value)
  }

  const p = 10 ** decimals
  return Math.round(value * p + Number.EPSILON) / p
}

export function percent(value1, value2) {
  if (value2 === 0) {
    return 0
  }

  return (value1 / value2) * 100
}

export function ratio(value1, value2) {
  if (value2 === 0) {
    return 0
  }

  return value1 / value2
}
