
<div class="group pb-4 transition-all flex-1 flex flex-col">
  <div class="relative card-interactive transition-all flex-1">
    <MediaThumbnail
      :file="file"
      :no-min-height="noMinHeight"
      :class="variantClasses['imageClasses']"
    />
    <!-- --------------------- -->
    <!-- Image actions overlay -->
    <!-- --------------------- -->
    <div
      class="
        lg:invisible group-hover:visible absolute inset-0 z-2 overflow-hidden
        bg-black bg-opacity-40 rounded-lg
        flex flex-col items-center justify-center
      "
    >
      <div>
        <div
          class="flex items-center justify-center p-24"
          @click="$emit('click-thumbnail')"
        >
          <span
            v-b-tooltip.hover.bottom.v-info.dh0.ds200="'View'"
            class="
              w-32 text-white cursor-pointer icon-util-zoom-in
              text-32 hover:opacity-80
            "
          />
        </div>
        <FileDownload
          v-slot="{ proxyUrl }"
          :url="file.url"
        >
          <b-button
            variant="neutral"
            :href="proxyUrl"
            download
            target="_blank"
          >
            <span class="w-16 mr-4 icon_v2-so_download text-16" /> Download
          </b-button>
        </FileDownload>
      </div>
      <div
        v-if="!readOnly"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Remove Image'"
        class="absolute top-8 right-8"
        @click="$emit('remove')"
      >
        <span
          class="
            w-24 text-white opacity-75 icon_v2-so_trash
            text-24 hover:opacity-100
          "
        />
      </div>
    </div>
    <!-- --------------------- -->
    <!-- Image actions overlay -->
    <!-- --------------------- -->
  </div>
  <div class="pr-4 mt-4 leading-snug text-gray-600 lg:mt-6 text-12 lg:text-14">
    <FileName :name="file.name" />
  </div>
</div>
