<template>
  <NotificationDocument v-slot="{ document /*, badgeIcon */ }" :notification="notification">
    <DocumentLink v-slot="{ navigate }" :document="document">
      <NotificationRenderer
        v-slot="{ options }"
        :notification="notification"
        :project="project"
        icon-variant="author"
        badge-icon="icon-util-upload-alt"
        badge-class="bg-blue-500"
        @click="navigate"
        @mark-as-read="notification => $emit('mark-as-read', notification)"
      >
        <span>{{ $tc(`doc-category.${document.category}`, 1) }}</span>
        <NotificationLinkDocument :document="document" /> shared
      </NotificationRenderer>
    </DocumentLink>
  </NotificationDocument>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'
import NotificationRenderer from './NotificationRenderer.vue'
import NotificationLinkDocument from './NotificationLinkDocument.vue'
import NotificationDocument from './NotificationDocument.vue'

export default defineComponent({
  name: 'NotifDocumentsShared',
  components: {
    DocumentLink,
    NotificationRenderer,
    NotificationLinkDocument,
    NotificationDocument,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
  },
})
</script>
