import {
  BaseModel,
  makeServicePlugin,
} from '@/v2/services/feathersClientService'
import domainsService, { servicePath } from './domainsService'

class Domain extends BaseModel {
  static modelName = 'Domain'

  static setupInstance(data, { models }) {
    const { Organization } = models.api
    const _data = { ...data }

    if (_data.organization$) {
      _data.organization$ = new Organization(_data.organization$)
    }

    return _data
  }
}

export default makeServicePlugin({
  Model: Domain,
  service: domainsService,
  servicePath,
})
