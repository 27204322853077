<template>
  <div
    class="flex flex-row items-center justify-between p-8 -ml-8 space-x-4 rounded-md group hover:bg-gray-900 hover:bg-opacity-5 w-full"
  >
    <b-link
      :href="isViewerPDF ? null : file.url"
      target="_blank"
      class="flex flex-row items-center space-x-4 cursor-pointer hover:underline"
    >
      <FileIcon :content-type="file.contentType" />
      <span class="inline-flex text-blue-600 text-16 break-all">{{ file.name }}</span>
    </b-link>
    <div class="self-end invisible group-hover:visible">
      <FileDownload
        v-slot="{proxyUrl}"
        :url="file.url"
      >
        <Button
          variant="icon-isolated"
          class="w-32 h-32 p-0 ml-6"
          :href="isViewerPDF ? null : proxyUrl"
          target="_blank"
          :download="file.name"
        >
          <span class="w-20 icon_v2-so_download text-20"></span>
        </Button>
      </FileDownload>
      <Button
        v-if="!readOnly"
        variant="delete"
        class="w-32 h-32 p-0 ml-6"
        @click="$emit('remove')"
      >
        <span class="w-20 icon_v2-so_trash text-20"></span>
      </Button>
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'
import FileIcon from '@/components/Blocks/File/FileIcon.vue'
import Button from '@/components/Button.vue'
import FileDownload from '@/components/FileDownload.vue'

export default {
  name: 'DocumentContentBlockAttachmentsFile',
  components: {
    FileIcon,
    Button,
    FileDownload,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    readOnly: Boolean,
  },
  setup() {
    const isViewerPDF = inject('isViewerPDF', false)

    return {
      isViewerPDF,
    }
  },
}
</script>
