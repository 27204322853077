<script>
import { defineComponent, computed } from '@vue/composition-api'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'

const BADGE_ICON = {
  [CATEGORY.Folder]: 'icon_v2-so_folder',
  [CATEGORY.Document]: 'icon-document',
  [CATEGORY.File]: 'icon-file',
  [CATEGORY.FileLink]: 'icon-file-link',
  [CATEGORY.DocumentReusableBlock]: 'icon-document',
  [CATEGORY.DocumentCaseStudy]: 'icon-document',
  [CATEGORY.DocumentTeamBio]: 'icon-document',
  [CATEGORY.DocumentPackagedService]: 'icon-document',
}

export default defineComponent({
  name: 'NotificationDocument',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const document = computed(() => props.notification?.subject$)
    const badgeIcon = computed(() => BADGE_ICON[document.value?.category]);

    return () => context.slots.default({
      document: document.value,
      badgeIcon: badgeIcon.value,
    })
  },
})
</script>
