<template>
  <SvgIcon
    :name="iconName"
    class="icon-file flex-shrink-0"
    :size="size"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'

const DEFAULT_ICON = 'file'
const CONTENT_TYPE_MAP = {
  'application/pdf': 'file-pdf',
  'text/plain': 'file-txt',
  'video/quicktime': 'file-video',
  'text/css': 'file-css',
  'text/csv': 'file-spreadsheet',
  'video/mp4': 'file-video',
  'audio/mpeg': 'file-audio',
  'application/zip': 'file-zip',
  'application/x-rar': 'file-zip',
  'application/postscript': 'file-ai',
  'application/vnd.ms-excel': 'file-numbers',
  'application/vnd.apple.numbers': 'file-numbers',
  'application/vnd.ms-powerpoint': 'file-ppt',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-numbers',
  'application/msword': 'file-doc',
  'image/vnd.adobe.photoshop': 'file-psd',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'file-doc',
  'application/json': 'file-json',
  'text/xml': 'file-xml',
  'application/xml': 'file-xml',
  'image/apng': 'file-image',
  'image/avif': 'file-image',
  'image/gif': 'file-image',
  'image/jpeg': 'file-image',
  'image/png': 'file-image',
  'image/svg+xml': 'file-image',
  'image/webp': 'file-image',
}
export default defineComponent({
  name: 'FileIcon',
  props: {
    contentType: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'xs',
    },
  },
  computed: {
    iconName() {
      return CONTENT_TYPE_MAP[this.contentType] || DEFAULT_ICON
    },
  },
})
</script>

<style></style>
