<template>
  <!-- v-if="isToggled" -->
  <FeathersVuexFind
    v-slot="{ items: comments }"
    :params="findParams"
    service="comments"
    watch="params"
  >
    <div v-fragment>
      <!-- HEADER -->
      <div
        class="
          flex items-center px-0 py-8 space-x-6 text-14
          border-b border-gray-200 dark:border-darkGray-700
        "
      >
        <span class="text-gray-600 dark:text-darkGray-400">{{ $t('comments.showing') }}</span>
        <b-dropdown
          :text="filter"
          toggle-class="dropdown-arrow"
          variant="neutral"
          size="xs"
          menu-class="w-128 text-13"
        >
          <b-dropdown-item
            v-for="f in ['All', 'Resolved', 'Open']"
            :key="f"
            :active="filter === f"
            @click="filter = f"
          >
            {{ filterNames[f] }}
          </b-dropdown-item>
        </b-dropdown>
        <span class="text-gray-600 dark:text-darkGray-400">{{ $t('common.comments') }}</span>
      </div>

      <!-- COMMENTS -->
      <div class="flex flex-col" style="height: calc(100% - 50px);">
        <div ref="sidebarScrollContainer" class="flex-1 py-16 space-y-12 overflow-y-auto">
          <CommentsHeader
            v-if="documentSidebarStatus.commentNodeId"
            :node-id="documentSidebarStatus.commentNodeId"
            @show-all-comments="toggleCommentsWithNode(null)"
          />

          <!-- LIST OF COMMENTS -->
          <transition-group
            v-if="comments.length"
            class="space-y-12"
            name="comments-list"
            tag="div"
          >
            <CommentsItem
              v-for="comment in comments"
              :key="comment._id"
              :comment="comment"
              :active-comment="activeComment"
              :user="user"
              :comment-node-id="documentSidebarStatus.commentNodeId"
              @comment-click="() => onCommentClick(comment)"
              @comment-edit="() => onEdit(comment)"
              @comment-set-resolved="flag => onSetResolvedStatus(comment, flag)"
              @comment-remove="() => onRemove(comment)"
              @visibility-change="visible => onVisibilityChange(visible, comment)"
            />
          </transition-group>
          <div v-else class="inline-flex text-left text-gray-500  dark:text-darkGray-400 text-14">
            {{ $t('comments.no-comments') }}
          </div>
        </div>

        <div class="pb-16 md:pb-8">
          <CommentsForm
            :comments-toggled="documentSidebarStatus.isExpanded"
            :user="user"
            :new-comment-data="newCommentData"
            :comment="activeComment"
            @created="onCommentSaved"
            @updated="onCommentSaved"
          />
        </div>
      </div>
    </div>
  </FeathersVuexFind>
</template>

<script>
import Vue from 'vue'
import { computed, defineComponent, onUnmounted, onMounted, ref, watch } from '@vue/composition-api'
import { isNotNil } from 'ramda-adjunct'
import { rejectNil } from 'ramda-extension'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import CommentsItem from '@/components/CommentsItem.vue'
import CommentsHeader from '@/components/CommentsHeader.vue'
import CommentsForm from '@/components/CommentsForm.vue'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { useUser } from '@/v2/services/users/usersCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'

const { useState } = createNamespacedHelpers('documentEditor')
const { useActions } = createNamespacedHelpers('documentEditor')

const filterResolvedMap = {
  All: null,
  Resolved: true,
  Open: false,
};

export default defineComponent({
  name: 'CommentsSidebar',
  components: {
    CommentsForm,
    CommentsHeader,
    CommentsItem,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
    isToggled: {
      type: Boolean,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const { Comment } = context.root.$FeathersVuex.api
    const msgBoxConfirmDelete = useMsgBoxConfirmDelete()
    const { translate } = useLocalization()

    const filterNames = computed(() => ({
      Open: translate('comments.open').value,
      All: translate('comments.all').value,
      Resolved: translate('comments.resolved').value,
    }))

    const { user } = useUser()
    const message = ref('')

    const filter = ref('Open');

    const { documentSidebarStatus } = useState(['documentSidebarStatus'])

    const newCommentData = computed(() => rejectNil({
      document: props.documentId,
      documentNode: documentSidebarStatus.value.commentNodeId,
    }))

    const activeComment = ref(null)
    const isVisible = ref(false)


    const {
      toggleDocumentSidebar,
      toggleCommentsWithNode,
      resetCommentsNode,
    } = useActions(['toggleDocumentSidebar', 'toggleCommentsWithNode', 'resetCommentsNode'])

    const findParams = computed(() => rejectNil({
      query: rejectNil({
        $sort: { createdAt: 1 },
        document: props.documentId,
        documentNode: documentSidebarStatus.value.commentNodeId,
        isResolved: filterResolvedMap[filter.value],
      }),
    }))

    const sidebarScrollContainer = ref(null)
    const scrollToBottom = () => {
      Vue.nextTick(() => {
        sidebarScrollContainer.value.scrollTop = sidebarScrollContainer.value.scrollHeight
      })
    }

    const resetEdit = () => {
      // Object.assign(form, formDefaults(!isAuthenticated.value))
      activeComment.value = null
      message.value = ''
    }


    const onEdit = comment => {
      activeComment.value = comment
    }

    const confirmEdit = async () => {
      const editedComment = new Comment(activeComment.value) // Instantiate Feathers Vuex model
      editedComment.patch({ data: { message: message.value } }) // Send patch request
      resetEdit()
    }

    const onRemove = comment => msgBoxConfirmDelete({
      title: translate('comments.delete').value,
      message: translate('comments.delete-confirm').value,
    })
      .then(result => result && comment.remove())
      .catch(console.error)

    const onMarkRead = comment => {
      comment.patch({ data: { read: true } })
    }

    const onSetResolvedStatus = (comment, status) => {
      comment.patch({ data: { isResolved: status } })
    }

    const onCommentClick = comment => {
      if (isNotNil(comment.documentNode)) {
        // scrollToElementId(`node-${comment.documentNode}`, -70)
        context.emit('scroll-to-node', comment.documentNode)
      }
    }

    const onCommentSaved = () => {
      activeComment.value = null
    }

    const checkDeletePrivilege = comment => {
      if (
        user.value && user.value._id === comment.createdBy
        && (!activeComment || comment._id !== activeComment._id)
      ) {
        return true
      }
      return false
    }

    const timers = new Map()
    const onVisibilityChange = (visible, comment) => {
      if (!user.value) return
      if (comment.read) return

      const timer = timers.get(comment._id)

      if (visible) {
        !timer && timers.set(comment._id, setTimeout(onMarkRead, 3000, comment))
      } else {
        clearTimeout(timer)
        timers.delete(comment._id)
      }
    }

    watch(() => documentSidebarStatus.value.commentNodeId, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        activeComment.value = null
      }
    })

    watch(() => props.documentId, () => {
      resetCommentsNode()
    })

    const onCommentCreated = comment => {
      if (comment.document === props.documentId) {
        scrollToBottom()
      }
    }

    Comment.on('created', onCommentCreated)
    onUnmounted(() => {
      Comment.off('created', onCommentCreated)
      resetCommentsNode()
    })

    return {
      // methods
      onEdit,
      resetEdit,
      confirmEdit,
      onRemove,
      onMarkRead,
      onVisibilityChange,
      // scrollToElementId,
      onCommentClick,
      onCommentSaved,
      scrollToBottom,
      onSetResolvedStatus,
      toggleDocumentSidebar,
      toggleCommentsWithNode,

      // data
      user,
      findParams,
      // form,
      activeComment,
      document,
      documentSidebarStatus,
      sidebarScrollContainer,
      filter,
      filterNames,
      message,
      newCommentData,

      // flags
      isVisible,
      checkDeletePrivilege,
    }
  },
})
</script>

<style lang="scss" module>
  .textarea {
    overflow-y: auto !important;
  }
</style>

<style lang="postcss" scoped>
  .comments-list-enter-active,
  .comments-list-leave-active {
    transition: all .4s;
  }
  .comments-list-enter,
  .comments-list-leave-to {
    opacity: 0;
    transform: translateX(220px);
  }
  .comments-list-move {
    transition: all .5s;
  }
  .comments-list-leave-active {
    position: absolute;
  }


  .fade-enter-active {
    animation: fade-in .5s;
  }
  .fade-leave-active {
    animation: fade-in .5s reverse;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
      /* transform: scale(1.5); */
    }
    100% {
      opacity: 1;
    }
  }
</style>
