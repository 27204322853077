import { computed, inject } from '@vue/composition-api';
import { ROLES } from '../../members/membersTypes';
import useCurrentMember from './useCurrentMember';

/**
 * @param {Array<import('../../members/membersTypes').Role>} roles
 */
export default function useCheckMemberRole(roles = []) {
  const { member } = useCurrentMember();
  const roleSet = new Set(roles);
  const isClientPortal = inject('isClientPortal', false)
  const role = computed(() => (isClientPortal ? ROLES.client : member.value?.role))
  const hasRole = computed(() => roleSet.has(role.value) ?? false);
  return { role, hasRole };
}
