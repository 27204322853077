<template>
  <DocumentNodeEditorToolbarSettings
    :popover-target-id="popoverTargetId"
    :prevent-popover-close="preventPopoverClose"
  >
    <b-form>
      <SettingsSwitch
        v-model="showTotalGraph"
        label="Approval progress"
        tooltip="Approved/Remaining cost"
      />
    </b-form>
  </DocumentNodeEditorToolbarSettings>
</template>

<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import DocumentNodeEditorToolbarSettings from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSettings.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'

export default {
  name: 'DocumentContentBlockCostBreakdownSettings',
  components: {
    DocumentNodeEditorToolbarSettings,
    SettingsSwitch,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    preventPopoverClose: {
      type: Boolean,
      default: false,
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { showProgress, showTotalGraph } = useContentBlockSettings(props, [
      'showTotalGraph',
    ])
    return {
      showTotalGraph,
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
