import { isNotNilOrEmpty } from 'ramda-adjunct'

export default function htmlTextContent(htmlString, maxChars = Infinity) {
  let content = ''
  if (isNotNilOrEmpty(htmlString)) {
    const fragment = document.createRange().createContextualFragment(htmlString)
    content = fragment.textContent.substring(0, maxChars)
    if (content.length === maxChars) {
      return `${content}...`
    }
  }
  return content
}
