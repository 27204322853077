import { computed } from '@vue/composition-api';
import useGroupByDate from '@/v2/lib/composition/useGroupByDate';
import useFindPagination from '@/v2/lib/composition/useFindPagination';
import { ACTION, SUBJECT } from '@/v2/services/notifications/notificationsTypes';

const ACTIONS = [
  ACTION[SUBJECT.documents].firstViewGuest,
  ACTION[SUBJECT.documents].viewGuest,
];

const isFirstViewGuest = item => item.subjectType === SUBJECT.documents
  && item.action === ACTION[SUBJECT.documents].firstViewGuest

const isViewGuest = item => item.subjectType === SUBJECT.documents
    && [
      ACTION[SUBJECT.documents].firstViewGuest,
      ACTION[SUBJECT.documents].viewGuest,
    ].includes(item.action);

const isQaAnswered = item => item.subjectType === SUBJECT.contentBlocks
    && item.action === ACTION[SUBJECT.contentBlocks].qaAnswered;

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {object} params
 * @param {Ref<string>} params.documentId
 * @param {number} [params.pageSize]
 */

export default function useDocumentEvents({
  documentId,
  pageSize = 500,
}) {
  const query = computed(() => ({
    // subject: documentId.value,
    document: documentId.value,
    action: { $in: ACTIONS },
    $sort: { time: 1 },
  }));

  const {
    items: notifications,
    ...rest
  } = useFindPagination({
    modelName: 'DocumentEvent',
    query,
    pageSize,
  });

  const items = useGroupByDate(notifications, {
    dateKey: 'time',
    mapGroupItems: groupItems => groupItems.map(groupItem => ({
      ...groupItem,
      isPdfDownload: groupItem?.geoUserAgent?.browser?.name.toLowerCase().includes('headless'),
      isViewGuest: isViewGuest(groupItem),
      isFirstViewGuest: isFirstViewGuest(groupItem),
      isQaAnswered: isQaAnswered(groupItem),
    })),
  });

  return {
    ...rest,
    items,
  };
}
