<template>
  <div
    class="relative w-full max-w-[90vw] h-auto min-h-vh-1/2 aspect-w-16 aspect-h-9
              flex-1 flex-grow p-0 m-0 bg-black"
    @click.stop=""
  >
    <b-embed
      type="video"
      controls
      allowfullscreen
    >
      <source :src="file.url" />
    </b-embed>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'VideoPlayer',
  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
    }
  },
})
</script>
