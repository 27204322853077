<template>
  <div @mousedown="onMouseDown" @click.stop>
    <ChromePicker
      :value="model"
      @input="onColorPick"
    />
  </div>
</template>

<script>
import { isNotNil } from 'ramda-adjunct'
import { ref, computed, watch } from '@vue/composition-api'
import ChromePicker from 'vue-color/src/components/Chrome.vue'

const vueColorDraggableElements = ['vc-saturation-circle', 'vc-saturation--black', 'vc-hue-picker', 'vc-alpha-picker'];

export default {
  name: 'ColorPicker',
  components: {
    ChromePicker,

  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottom',
    },


  },
  setup(props, context) {
    const localValue = ref(null)

    const onColorPick = color => {
      localValue.value = color;
      context.emit('input', color.hex8);
    }

    const model = computed(() => localValue.value || (props?.value ?? '#000000'))

    const onMouseDown = ({ target }) => {
      if (vueColorDraggableElements.includes(target.className)) {
        window.document.addEventListener(
          'click',
          e => e.stopPropagation(),
          { once: true, capture: true }
        )
      }
    }

    watch(() => props.value, newVal => {
      if (isNotNil(localValue.value) && newVal !== localValue.value.hex8) {
        localValue.value = null;
      }
    });

    return {
      model,
      onColorPick,
      onMouseDown,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
