import feathersClient from '@/v2/services/feathersClientService'
import { keepModelFields } from '@/v2/lib/hooks'
// import { paramsForServer, discard } from 'feathers-hooks-common'

export const servicePath = 'content-blocks'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      keepModelFields('fieldsCreate'),
    ],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service
