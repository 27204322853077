<template>
  <div class="flex flex-1 flex-grow w-full">
    <div
      :class="[
        'fs-row-wrapper',
        size,
      ]"
      role="link"
    >
      <div class="flex items-center justify-start flex-1">
        <!-- ICON + NAME -->
        <div
          class="flex items-center"
        >
          <!-- ################## -->
          <!-- ICON -->
          <!-- ################## -->

          <!-- FILE WITH ICON -->
          <div v-if="isFile && !isImageFile" class="fs-row-icon-wrapper ">
            <!-- <IconDocument v-if="isFile" class-name="w-40 h-40 scale-50" /> -->
            <FileIcon
              :content-type="contentType"
              :size="size==='l' ? '28' : '24'"
            />
          </div>
          <!-- IMAGE -->
          <div
            v-if="isImageFile"
            class="relative w-36 h-36"
          >
            <div class="flex flex-col items-center justify-center h-full">
              <div class="max-h-36">
                <img :src="document.file.url" class="fs-card--doc-image border-2" />
              </div>
            </div>
          </div>

          <div v-if="isFolder" class="fs-row-icon-wrapper">
            <span
              :class="'icon_v2-so_folder'"
              class="w-24 text-24"
              :style="{ color: document.color || theme.textColor}"
            ></span>
          </div>

          <!-- NOT FILE -->
          <!-- NOT IMAGE -->
          <div v-if="!isFile && !isImageFile && !isFolder" class="fs-row-icon-wrapper">
            <span
              :class="document.icon || 'icon-document'"
              class="w-24 text-24"
              :style="{ color: document.color || theme.textColor}"
            ></span>
          </div>

          <!-- EMD ICON -->
          <!-- ################## -->
          <div class="fs-card-title-list">
            <DocumentTitleEditable
              :size="size"
              :document="document"
              link
              :disabled="readOnly"
              @select="event => $emit('select', event)"
            />
          </div>
        </div>
        <!-- VIEWS -->
        <DocumentAnalytics
          v-if="!isClientPortal"
          :document="document"
          slim
        />
        <!-- STATUS -->
        <DocumentStatusBadge
          :status="document.status"
          display-as="small"
          only-approved
        />
        <!-- <p>{{contentType}}</p> -->
      </div>
      <!-- Meta Data -->
      <div class="relative flex items-center justify-start space-x-8 z-1">
        <!-- QA PROGRESS -->
        <DocumentViewerProgress
          :document="document"
          variant="card"
        />
        <!-- COST, if available -->
        <div
          v-if="document.cost"
          class="text-14 text-gray-500 dark:text-darkGray-400"
        >
          <DocumentCurrency
            :value="document.cost"
            :document-id="document._id"
          />
        </div>
        <!-- Action Items Count -->
        <div
          v-if="totalActiveActionItems > 0"
          class="inline-flex items-center px-4 text-gray-500 border border-gray-200 rounded bg-gray text-13 h-26"
        >
          <span class="w-16 mr-4 icon_v2-so_action-items text-16"></span>
          {{ totalActiveActionItems }}
        </div>
        <!-- Comments Count -->
        <div
          v-if="showCommentsCount > 0"
          class="inline-flex items-center px-4 text-gray-500 border border-gray-200 rounded bg-gray text-13 h-26"
        >
          <span class="w-16 mr-4 icon_v2-so_chat text-16"></span> {{ showCommentsCount }}
        </div>
        <!-- Shared -->
        <div
          v-if="document.sharingLinkEnabled && !isClientPortal"
          v-b-tooltip.hover.top.v-info.dh0.ds200="`Public link enabled`"
          class="flex items-center justify-center w-26 h-26 text-gray-400 dark:text-darkGray-600"
        >
          <span class="icon-link text-16 md:text-24 w-16 md:w-24" />
        </div>
        <!-- Shared -->
        <div
          v-if="document.sharingClientEnabled && !isClientPortal"
          v-b-tooltip.hover.top.v-info.dh0.ds200="`Shared to client portal`"
          class="flex items-center justify-center w-26 h-26 text-gray-400 dark:text-darkGray-600"
        >
          <span
            :class="[
              'icon_v2-so_shared text-16 md:text-24 w-16 md:w-24'
            ]"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
// import tinycolor from 'tinycolor2'
import { CATEGORY } from '@/v2/services/documents/documentsTypes'
import FileIcon from '@/components/Blocks/File/FileIcon.vue'
import { isFileTypeImage } from '@/v2/lib/helpers/validators'
import DocumentStatusBadge from '@/components/Document/Editor/DocumentStatusBadge.vue'
import DocumentCurrency from '@/components/DocumentCurrency.vue'
import DocumentTitleEditable from '@/components/DocumentTitleEditable.vue'
import DocumentAnalytics from '@/components/DocumentAnalytics.vue'
import DocumentViewerProgress from '@/components/Document/Viewer/DocumentViewerProgress.vue'


export default defineComponent({
  name: 'FolderItemRow',
  components: {
    FileIcon,
    DocumentStatusBadge,
    DocumentCurrency,
    DocumentTitleEditable,
    DocumentAnalytics,
    DocumentViewerProgress,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'l',
    },
  },
  setup(props, context) {
    const isClientPortal = inject('isClientPortal', false)
    const isPublicFolder = computed(() => props.document.category === CATEGORY.ProjectPublicFolder)

    // const accentColorSpace = computed(() => tinycolor(props.document.color).monochromatic(3))
    // const mostReadable = computed(() => tinycolor.mostReadable(
    //   props.document.color,
    //   [
    //     ...accentColorSpace.value,
    //     '#FFFFFF',
    //   ],
    //   {
    //     level: 'AA',
    //     size: 'large',
    //   }
    // ).toHexString())

    const theme = computed(() => ({
      textColor: props.document.color || props.document?.sharingClientEnabled ? 'var(--cp-accent)' : 'var(--cp-secondary-color)',
    }))

    const icon = computed(() => {
      if ([CATEGORY.Folder, CATEGORY.ProjectRootFolder].includes(props.document.category)) {
        return 'icon_v2-so_folder'
      }
      if ([CATEGORY.Document].includes(props.document.category)) {
        return props.document.icon || 'icon-document-custom'
      }
      if ([CATEGORY.ProjectPublicFolder].includes(props.document.category)) {
        return props.document.icon || 'icon_v2-so_shared'
      }
      if ([CATEGORY.File, CATEGORY.FileLink].includes(props.document.category)) {
        return 'icon_v2-so_file'
      }
      return props.document.icon
    })

    const totalActiveActionItems = computed(() => props.document.countActionItemsOpenPublic
     + props.document.countActionItemsResolvedPublic)

    const showActionItemsCount = computed(() => totalActiveActionItems.value)

    const showCommentsCount = computed(() => props.document.countComments)

    const isImageFile = computed(() => isFileTypeImage(props.document?.file))
    const isFile = computed(() => props.document.category === CATEGORY.File && !isImageFile.value)
    const isFolder = computed(() => props.document.category === CATEGORY.Folder)

    const contentType = computed(() => props.document?.file?.contentType ?? null)

    return {
      isPublicFolder,
      isClientPortal,
      theme,
      icon,
      showActionItemsCount,
      showCommentsCount,
      totalActiveActionItems,
      isImageFile,
      isFile,
      isFolder,
      contentType,
    }
  },
})
</script>
<style lang="postcss" scoped>

.fs-card--doc-img::after {
  @apply bg-white dark:bg-darkGray-800;
  @apply border border-gray-200 block dark:border-darkGray-900;
  content: '';
  padding-bottom: 100%;
}

</style>
