<template>
  <div class="w-420">
    <div v-if="documents.length" class="flex flex-col items-start w-full">
      <Badge variant="primary">{{ doc }}</Badge>
      <ul class="search-results-list">
        <DocumentLink
          v-for="document in documents"
          :key="document._id"
          v-slot="{ navigate }"
          :document="document"
        >
          <li
            class="search-results-item group"
            @click="navigate"
          >
            <span class="icon-document-custom search-results-item-icon"></span>
            <p class="dark:text-darkGray-300 truncate">{{ document.title }}</p>
          </li>
        </DocumentLink>
      </ul>
    </div>
    <div v-if="actionItems.length" class="flex flex-col items-start w-full">
      <Badge variant="primary">{{ actionItem }}</Badge>
      <ul class="search-results-list">
        <li
          v-for="item in actionItems"
          :key="item._id"
          class="search-results-item group"
          @click="goToTask(item)"
        >
          <span class="icon-util-checkbox search-results-item-icon"></span>
          <span class="dark:text-darkGray-300">{{ item.title }}</span>
        </li>
      </ul>
    </div>

    <p
      v-if="!actionItems.length && !documents.length"
      class="text-gray-600 text-14"
    >
      {{ $t('common.could-not-find') }}
    </p>
  </div>
</template>
<script>
import { rejectNil } from 'ramda-extension'
import { computed, defineComponent } from '@vue/composition-api'
import { isEmpty } from 'ramda'
import { useFind } from 'feathers-vuex'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'
import { useGoToActionItem } from '@/v2/services/actionItems/compositions'
import { useProjectModules } from '@/v2/services/projects/compositions'
import DocumentLink from '@/components/DocumentLink.vue'
import Badge from '@/components/Badge.vue'

export default defineComponent({
  name: 'ProjectDashboardPageHeaderSearch',
  components: {
    Badge,
    DocumentLink,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const goToTask = useGoToActionItem()
    const { open } = useDocuments()
    const { pluralization } = useLocalization()
    const { Document, ActionItem, Project } = context.root.$FeathersVuex.api
    // In order to match all characters, replace whitespaces with .*
    const transformedSearch = computed(() => props.keyword.replace(/ /g, '.*'))
    const isNotSearchEmpty = computed(() => !isEmpty(props.keyword))
    // const reg = computed(() => new RegExp(props.keyword))

    const params = computed(() => (props.keyword.length > 0 ? {
      query: rejectNil({
        title: { $regex: transformedSearch.value, $options: 'si' },
        organization: props.organizationId,
        project: props.projectId,
      }),
    } : null))


    const { items: documents } = useFind({
      model: Document,
      params,
    })

    const project = computed(() => Project.getFromStore(props.projectId))

    const { items: actionItems } = useFind({
      model: ActionItem,
      params,
    })

    const projectModules = useProjectModules(project)

    // Labels
    const doc = computed(
      () => projectModules.value.documents.title.toLowerCase()
        || pluralization('modules.docs', documents.value.length).value.toLowerCase()
    )
    const actionItem = computed(
      () => projectModules.tasks.title.toLowerCase()
    || pluralization('modules.tasks', actionItems.value.length).value.toLowerCase()
    )

    return {
      documents,
      project,
      actionItems,
      isNotSearchEmpty,
      doc,
      actionItem,
      // Methods
      open,
      goToTask,
    }
  },
})
</script>
<style lang="postcss" scoped>
.dashboard-search-wrapper {
  @apply relative;
  @apply text-gray-600 focus-within:text-gray-700;
  @apply w-[75vw] lg:w-[670px];
  @apply rounded-full;
}


.dashboard-search-input {
  @ppply pr-16 py-8 text-15;
  @apply lg:pr-32 lg:py-14 lg:text-16;
  @apply rounded-full;
  @apply dark:text-darkGray-100;
}

.search-results-list {
 @apply flex flex-col w-full mt-12 mb-24 space-y-12;
 @apply text-left list-none text-14;
}

.search-results-item {
  @apply flex flex-1 grow flex-row items-center space-x-4 leading-none truncate cursor-pointer;
  @apply hover:bg-gray-100 dark:hover:bg-darkGray-700 dark:hover:text-darkGray-300;
  @apply rounded-md;
  @apply transition-all;
}

.search-results-item-icon {
  @apply w-16 text-16 text-gray-400 dark:text-darkGray-600;
  @apply dark:group-hover:text-darkGray-300;
}

/* Header */
.header-search-wrapper {
  @apply relative text-gray-600 focus-within:text-gray-700;
  @apply flex-auto h-40;
  @apply lg:w-[400px];
  @apply z-10 lg:z-auto;
  /* @apply max-w-112; */
}

.header-search-input {
  @apply flex items-center leading-0 text-14;
  @apply pr-8 pl-32 h-40 py-0;
  @apply bg-transparent;
  @apply w-auto md:w-176;
  @apply border-none md:border;
  @apply dark:border-darkGray-800 dark:text-darkGray-300 dark:placeholder-darkGray-400;
  @apply focus:outline-none active:outline-none focus:ring-0 focus:shadow-none;
}

.header-search-input:focus{
  @apply lg:w-320 outline-none ring-0 md:ring-2;
}
</style>
