
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="[
    'node-group-column',
  ]"
  :width="width"
  :content-class="node.styling.verticalAlign && `flex flex-col justify-${node.styling.verticalAlign}`"
  :inner-class="['px-0 lg:px-24 xl:px-32 print:h-full print:flex']"
>
  <template v-if="!isEmpty">
    <div>
      <DocumentNode
        v-for="(nodeId, index) in children"
        :key="nodeId"
        :node-id="nodeId"
        :parent="node._id"
        :index="index"
        :is-first="index === 0"
        :is-last="index === children.length - 1"
      />
    </div>
  </template>
</DocumentNodeRenderer>
