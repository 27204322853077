<template>
  <ProjectTheme
    :project="project"
    no-dark-mode
    class-name="bg-transparent relative flex flex-row flex-1 p-0 h-full"
  >
    <div
      class="
        group hover:cursor-pointer transition-all w-full overflow-visible
        p-0 px-16 py-8 flex flex-row h-full items-center hover:bg-white
      "
      @click="$emit('select')"
    >
      <div
        class="
          w-16 h-16 rounded-full inline-block portal-accent-background mr-8
        "
      >
      </div>
      <h2
        class="
          inline-flex items-center font-medium leading-tight
          cursor-pointer text-16 group-hover:underline
          text-gray-800 dark:text-darkGray-100
          flex-1
        "
      >
        <a class="hover:underline dark:hover:text-darkGray" @click.stop="$emit('select')">
          {{ project.name }}
        </a>
      </h2>
      <div
        v-if="project.client$"
        class="
          inline-flex items-center leading-none text-13 flex-1
          text-gray-800 dark:text-darkGray-100 opacity-40
        "
      >
        <span class="icon-org-clients text-16 mr-4" />
        <p class="flex-1 flex-grow">{{ project.client$.name }}</p>
      </div>

      <p class="text-13 ml-8 flex-1">
        Last update
        <LiveFilter
          filter="timeago"
          :locale="project.locale"
          :value="project.updatedAt"
        />
      </p>
      <slot name="menu" />
    </div>
  </ProjectTheme>
</template>

<script>
import { ratio } from '@/lib/math'
import ProjectTheme from '@/components/ProjectTheme.vue'
import LiveFilter from '@/components/LiveFilter.vue'

export default {
  name: 'ProjectListItem',
  components: {
    LiveFilter,
    ProjectTheme,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    readOnly: Boolean,
    hideAmounts: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const approvedRatio = () => ratio(
      props.project.statistics.approvedCost,
      props.project.statistics.cost
    )

    return {
      approvedRatio,
    }
  },
}
</script>
<style lang="postcss" scoped>
.header-background {
  /* background-color: var(--cp-header-bg); */
}
.header-background-filter {
  background-color: var(--cp-header-bg);
  @apply absolute inset-0 z-0;
}

.header-background-image {
  background-color: var(--cp-header-bg);
  @apply absolute inset-0 z-1;
  @apply transition-all;
}

.header-background-image::after {
  content: '';
  background-image: var(--cp-header-image-url);
  background-size: cover;
  background-position: center center;
  opacity: var(--cp-header-image-opacity);
  @apply absolute inset-0 z-1;
}
</style>
