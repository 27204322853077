import { keep } from 'feathers-hooks-common'
import feathersClient from '@/v2/services/feathersClientService'

export const servicePath = 'task-sections'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [
      keep(
        // details
        'organization',
        'project',
        'title'
      ),
    ],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service
