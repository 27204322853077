
<DocumentLink
  v-if="document"
  v-slot="{ navigate }"
  :document="document"
>
  <DocumentResolver
    v-slot="{ component }"
    :document="document"
  >
    <!-- Component from doc category -->
    <component
      :is="component"
      :document="document"
      :size="size"
      :read-only="readOnly"
      @select="navigate"
    />
  </DocumentResolver>
</DocumentLink>
