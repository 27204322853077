import { applySpec } from 'ramda';
import axios from 'axios'
import config from '@/config';

const makeParams = params => {
  if (config.isDevelopment) {
    return { ...params, _publicationState: 'preview' }
  }

  return params
}

const makeResource = path => client => ({
  get: id => client.get(`/${path}/${id}`),
  find: params => client.get(`/${path}`, { params: makeParams(params) }),
  count: params => client.get(`/${path}/count`, { params: makeParams(params) }),
});

const makeStrapiClient = baseURL => axios.create({
  baseURL,
  // failWithError: true,
});

const clientSpec = {
  templates: makeResource('templates'),
  templateCategories: makeResource('template-categories'),
  templateCollections: makeResource('template-collections'),
}

const strapiClient = makeStrapiClient(import.meta.env.VITE_STRAPI_ENDPOINT);
export default applySpec(clientSpec)(strapiClient)
