import Vue from 'vue'
import { useMsgBoxConfirmDelete } from '@/v2/lib/composition/useMsgBox'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { useDocumentOpen } from './useDocumentLink'
import { FS_STATE } from '../documentsTypes'

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {Ref<Object>} document
 */
function useDocumentRemove(document) {
  const { Document } = Vue.$FeathersVuex.api
  const confirmDelete = useMsgBoxConfirmDelete()
  const { routerPush } = useRouter()
  const open = useDocumentOpen()

  return async (redirect = false, route = null) => {
    const ok = await confirmDelete({
      title: `Delete ${document.value.title}`,
      message: 'Are you sure you want to delete this document? This action is irreversible.',
    })

    if (!ok) {
      return
    }

    await document.value.remove()
    // await document.value.patch({ data: { fsState: FS_STATE.delete } })
    if (redirect) {
      const parentDoc = await Document.grabOrFetch(document.value.parentFolder)
      open(parentDoc)
    } else if (route) {
      routerPush(route)
    }
  }
}

export default useDocumentRemove
