<template>
  <!-- :body-class="$style['modal-body-apps']"
    :header-class="$style['modal-header-apps']"
    :title-class="$style['modal-title-apps']" -->
  <ModalBasic
    title="Link to an externally hosted file"
    :should-close-on-backdrop="false"
    :hide-footer="true"
    content-class="modal-content-tertiary"
    body-class="modal-body-tertiary"
    header-class="modal-header-tertiary"
    footer-class="modal-footer-tertiary"
    size="md"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #body="{ close }">
      <Form
        v-slot="{ v }"
        :state="file"
        :validation="validation"
        @submit="$emit('file-linked', file)"
      >
        <b-form-group class="mb-32">
          <FormField
            label="File URL"
            required
            :v="v.url"
          >
            <b-input
              v-model="file.url"
              autofocus
              trim
              debounce="500"
              type="text"
              placeholder="https://cloud.com/some-file.txt"
            />
          </FormField>
        </b-form-group>
        <Buttons>
          <Button
            type="submit"
            size="md"
            variant="primary"
          >
            Confirm
          </Button>
          <Button
            size="md"
            variant="secondary"
            @click="close"
          >
            Cancel
          </Button>
        </Buttons>
      </Form>
    </template>
  </ModalBasic>
</template>

<script>
import { computed, defineComponent, reactive } from '@vue/composition-api';
import { url, required } from '@vuelidate/validators';
import Button from './Button.vue';
import Buttons from '@/components/Buttons.vue'
import Form from './Form.vue';
import FormField from './FormField.vue';
import ModalBasic from './Modal/ModalBasic.vue';

export default defineComponent({
  name: 'AddLinkedFileModal',
  components: { ModalBasic, Button, Form, FormField, Buttons },
  props: {
  },
  setup() {
    const file = reactive({ url: '', contentType: null, length: null })
    const validation = computed(() => ({
      url: {
        url,
        required,
      },
    }))

    return {
      // State
      file,
      validation,
    }
  },
})
</script>
