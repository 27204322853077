import {
  ORGANIZATION_LIBRARY_REUSABLE_BLOCK,
  ORGANIZATION_LIBRARY_CASE_STUDY,
  ORGANIZATION_LIBRARY_TEAM_BIO,
  ORGANIZATION_LIBRARY_PACKAGED_SERVICE,
  ORGANIZATION_LIBRARY_PAGE,
} from '@/router/organization/type'
import {
  // FS
  PROJECT_FS_FOLDER,
  PROJECT_FS_DOCUMENT,

  // Pinned Folders
  PROJECT_PINNED_FS_FOLDER,
  PROJECT_PINNED_FS_DOCUMENT,
} from '@/router/project/type'
import { CATEGORY } from '../../documentsTypes'

export default {
  // -- organization docs
  [CATEGORY.DocumentReusableBlock]: ({ document }) => ({
    name: ORGANIZATION_LIBRARY_REUSABLE_BLOCK,
    params: {
      documentId: document._id,
    },
  }),
  [CATEGORY.DocumentCaseStudy]: ({ document }) => ({
    name: ORGANIZATION_LIBRARY_CASE_STUDY,
    params: {
      documentId: document._id,
    },
  }),
  [CATEGORY.DocumentPackagedService]: ({ document }) => ({
    name: ORGANIZATION_LIBRARY_PACKAGED_SERVICE,
    params: {
      documentId: document._id,
    },
  }),
  [CATEGORY.DocumentPage]: ({ document }) => ({
    name: ORGANIZATION_LIBRARY_PAGE,
    params: {
      documentId: document._id,
    },
  }),
  [CATEGORY.DocumentTeamBio]: ({ document }) => ({
    name: ORGANIZATION_LIBRARY_TEAM_BIO,
    params: {
      documentId: document._id,
    },
  }),

  // -- folders
  [CATEGORY.ProjectRootFolder]: ({ document }) => ({
    name: PROJECT_FS_FOLDER,
    params: {
      projectId: document.project,
      folderId: document._id,
    },
  }),
  [CATEGORY.ProjectPublicFolder]: ({ document }) => ({
    name: PROJECT_FS_FOLDER,
    params: {
      projectId: document.project,
      folderId: document._id,
    },
  }),
  [CATEGORY.Folder]: ({ document, isPinnedFolder }) => ({
    name: isPinnedFolder ? PROJECT_PINNED_FS_FOLDER : PROJECT_FS_FOLDER,
    params: {
      projectId: document.project,
      folderId: document._id,
    },
  }),
  [CATEGORY.Document]: ({ document, isPinnedFolder }) => ({
    name: isPinnedFolder ? PROJECT_PINNED_FS_DOCUMENT : PROJECT_FS_DOCUMENT,
    params: {
      projectId: document.project,
      folderId: document.parentFolder,
      documentId: document._id,
    },
  }),

  // -- docs / files
  [CATEGORY.File]: ({ document, isPinnedFolder }) => ({
    name: isPinnedFolder ? PROJECT_PINNED_FS_DOCUMENT : PROJECT_FS_DOCUMENT,
    params: {
      projectId: document.project,
      folderId: document.parentFolder,
      documentId: document._id,
    },
  }),
  [CATEGORY.FileLink]: ({ document, isPinnedFolder }) => ({
    name: isPinnedFolder ? PROJECT_PINNED_FS_DOCUMENT : PROJECT_FS_DOCUMENT,
    params: {
      projectId: document.project,
      folderId: document.parentFolder,
      documentId: document._id,
    },
  }),
}
