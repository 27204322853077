<template>
  <div
    class="
      text-center font-semibold
      flex flex-col items-center
      text-gray-800 p-8
    "
  >
    <ButtonTransparent
      v-b-tooltip.hover.top.v-info.dh0.ds200="`Add new Quick Link`"
      class="
        cursor-pointer rounded-md
        text-14 text-center font-semibold
        portal-accent-background
        flex items-center justify-center transition-all
        w-44 h-44
      "
      @click="evt => $emit('click', evt)"
    >
      <span
        class="
          icon_v2-so_plus text-32 md:text-24
          text-[color:var(--cp-accent-most-readable)]
        "
      />
    </ButtonTransparent>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'

export default defineComponent({
  name: 'ProjectQuickLinkPlaceholder',
  components: {
    ButtonTransparent,
  },
})
</script>
