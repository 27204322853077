
<portal :to="`content-block-settings-${nodeId}`">
  <div class="flex flex-row">
    <DocumentNodeEditorToolbarSeparator />
    <DocumentNodeEditorToolbarGroup label="Image">
      <div class="flex flex-row items-center space-x-4">
        <b-button
          v-b-tooltip.hover.bottom.v-info
          variant="primary"
          :disabled="fileUploadState.isUploading"
          title="Upload from your device"
          class="py-5 px-10"
          @click="$emit('select-files')"
        >
          <span class="w-20 icon-util-upload text-20" />
        </b-button>
        <b-button
          v-b-tooltip.hover.bottom.v-info
          title="Use an image from Unsplash"
          variant="black"
          :disabled="fileUploadState.isUploading"
          class="ml-8 py-5 px-10"
          @click="$emit('open-unsplash')"
        >
          <span class="w-16 mr-4 icon-logo-unsplash text-16" />
          Unsplash
        </b-button>
        <DocumentNodeEditorToolbarPopover
          v-if="!isBlankState"
          class-name="w-32 rounded hover:bg-gray-500 hover:bg-opacity-10 editor-toolbar-button"
          tooltip="Remove Image"
        >
          <template #button-content>
            <span
              class="w-20 icon_v2-so_trash text-20 opacity-40"
            />
          </template>
          <template #title>
            Remove image?
          </template>
          <template #default="{ close }">
            <b-button
              variant="neutral"
              class="mr-8"
              size="md"
              @click="close"
            >
              Cancel
            </b-button>
            <b-button
              variant="danger"
              size="md"
              @click="$emit('delete-file')"
            >
              Delete
            </b-button>
          </template>
        </DocumentNodeEditorToolbarPopover>
      </div>
    </DocumentNodeEditorToolbarGroup>
  </div>
</portal>
