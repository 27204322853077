import Vue from 'vue'
import { computed, unref } from '@vue/composition-api';
import { useFind } from 'feathers-vuex';
import { isNilOrEmpty } from 'ramda-adjunct';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {Parameters<typeof useFind>[0]} UseFindOptions
 */

/**
 * Special version of useFind that keeps result order (by provided ids)
 * @param {string} model Model name
 * @param {Ref<string[]>} ids
 * @param {UseFindOptions} options
 */
export default function useFindByIds(model, ids, options) {
  const params = computed(() => {
    if (isNilOrEmpty(ids)) {
      return null
    }

    const optionsParams = unref(options?.params ?? {})
    const optionsQuery = optionsParams.query ?? {}

    return {
      ...optionsParams,
      query: {
        ...optionsQuery,
        _id: { $in: unref(ids) },
      },
    }
  })

  const { items, ...rest } = useFind({
    ...options,
    model: Vue.$FeathersVuex.api[model],
    params,
  });

  const indexedItems = computed(() => new Map(items.value.map(item => [item._id, item])));
  const orderedItems = /** @type {typeof items} */ (
    computed(
      () => ids.value
        .filter(id => indexedItems.value.has(id))
        .map(id => indexedItems.value.get(id))
    )
  );

  return {
    items: orderedItems,
    ...rest,
  };
}
