import { ROLES } from '../membersTypes'
import { useFeatureLimit } from '../../organizations/compositions'

const ROLE_FLAG = {
  [ROLES.client]: 'limitClientContacts',
  default: 'limitMembers',
}

const ROLE_LABEL = {
  [ROLES.client]: 'client contacts',
  default: 'members',
}

export default function useMemberLimit() {
  const { checkFeatureLimit } = useFeatureLimit()

  const checkMemberLimit = (role, added = 1) => checkFeatureLimit(
    ROLE_LABEL[role] ?? ROLE_LABEL.default,
    ROLE_FLAG[role] ?? ROLE_FLAG.default,
    added
  )

  return {
    checkMemberLimit,
  }
}
