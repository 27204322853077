<template v-if="!isEmptyToc">
  <div class="relative flex flex-col w-full">
    <slot />
    <!-- <pre>selectedNodeId={{ selectedNodeId }}</pre>
    <div>isAutoScrolling={{ isAutoScrolling }}</div>
    <div>isScrolling={{ isScrolling }}</div> -->
    <ul class="w-full max-h-screen p-0 list-none grow">
      <TocItem
        v-for="node in tocNodesList"
        :key="node._id"
        :node="node"
        :category="node.contentBlock$?.category"
        :selected-node-id="tocActiveNodeId"
        @go-to-node="goToNode(node)"
      />
    </ul>
    <!-- <div
      :class="[
        'absolute left-0 right-0 bottom-0',
        'h-128 z-1 pointer-events-none',
        'bg-gradient-to-t from-gray-100',
      ]"
    >
    </div> -->
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import htmlTextContent from '@/helpers/html-text-content'
import { CATEGORY as CONTENT_BLOCK_CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'

import EventBus from '@/event-bus/event-bus'

import useDocumentNav from '@/v2/lib/composition/useDocumentNav'
import TocItem from './TocItem.vue'

const SCROLL_OFFSET = -120

export default {
  name: 'DocumentViewerToc',
  components: {
    TocItem,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    isAutoScrolling: {
      type: Boolean,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const localActiveNode = ref(null)
    //
    // ToC
    //
    const { activeNodeId: tocActiveNodeId, nodes: tocNodes } = useDocumentNav(
      props.document._id,
      [CONTENT_BLOCK_CATEGORY.ElementHeading, CONTENT_BLOCK_CATEGORY.ElementQA]
    )


    const isEmptyToc = computed(() => Boolean(!tocNodes.value.length))


    const goToNode = node => {
      localActiveNode.value = node._id
      if (node) {
        // scrollToElementId(`node-${node._id}`, SCROLL_OFFSET)
        EventBus.$emit('scrollToElementId:appScrollContainer', `node-${node._id}`, SCROLL_OFFSET)
      }
    }

    const selectedNodeId = computed(() => localActiveNode.value || tocActiveNodeId.value)

    const tocNodesList = computed(
      () => tocNodes.value
        .map(node => {
          if (node.contentBlock$.category !== CONTENT_BLOCK_CATEGORY.ElementQA) {
            return {
              ...node,
              strippedTitle: node.contentBlock$
                ? htmlTextContent(node.contentBlock$.heading)
                : '...',
            }
          }
          return {
            ...node,
            strippedTitle: node.contentBlock$
              ? htmlTextContent(node.contentBlock$.title)
              : '...',
          }
        })
    )

    // watch(
    //   () => props.isScrolling,
    //   isScrolling => {
    //     console.log(props.isScrolling)
    //     if (isScrolling && !props.isAutoScrolling && localActiveNode.value) {
    //       localActiveNode.value = null
    //     }
    //   }
    // )

    return {
      tocNodes,
      tocNodesList,
      goToNode,
      selectedNodeId,
      tocActiveNodeId,
      isEmptyToc,
      // localActiveNode,
      CONTENT_BLOCK_CATEGORY,
    }
  },
}
</script>
