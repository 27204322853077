<template>
  <div :class="$style['attach-file']">
    <Spinner
      v-if="isProgress"
      :size="18"
      :ratio="ratio"
    />
    <div v-else class="w-24 h-24 flex items-center justify-center">
      <div
        class="rounded-full p-2 w-16 h-16 flex items-center justify-center"
        :class="{
          'bg-green-500': !error,
          'bg-red-500': error
        }"
      >
        <span
          class="text-12 text-white"
          :class="{
            'icon_v2-so_tick': !error,
            'icon-alert': error
          }"
        />
      </div>
    </div>
    <FileIcon :content-type="file.contentType" size="20" />
    <div
      class="
      text-gray-700 dark:text-white
      print:no-underline print:cursor-default print:pointer-events-none
      truncate
      text-14 flex-1
    "
    >
      <p>{{ file.name }}</p>
      <p class="text-10 text-red-500">{{ error }}</p>
    </div>
    <p class="text-14 text-gray-500 flex-shrink-0">
      {{ sizeFormatted }}
    </p>
    <div v-if="isProgress" :class="$style['attach-file-actions']">
      <div :class="[$style['attach-file-action'], $style['del']]">
        <b-button
          size="sm"
          variant="slim"
          class="bg-red-400"
        >
          <span class="icon_v2-so_close w-24 text-24"></span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { formatBytes } from '@/v2/services/uploads/uploadsService'
import FileIcon from '@/components/Blocks/File/FileIcon.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'DocumentContentBlockAttachmentsFileUpload',
  components: {
    FileIcon,
    Spinner,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const isProgress = computed(() => props.file.local.status === 'progress')
    const ratio = computed(() => props.file.local.ratio)
    const error = computed(() => props.file.local.error)
    const sizeFormatted = computed(
      () => (props.file.length ? formatBytes(props.file.length) : null)
    )

    return {
      isProgress,
      ratio,
      error,
      sizeFormatted,
    }
  },
}
</script>

<style module lang="scss">
@import './DocumentContentBlockAttachmentsFile.scss';
</style>
