
<div
  v-if="hasNoAnswer && isAnswerable"
  :class="[$style['a-badge-wrapper'], $style[`${position}`]]"
>
  <span
    :class="[$style['a-badge'], $style['unanswered']]"
    class="select-none"
  >
    Answer
  </span>
  <!-- <span
    v-else-if="!hasNoAnswer"
    :class="[$style['a-badge'], $style['answered']]"
    class="select-none"
  >
    <span class="icon_v2-so_tick text-12 w-12 text-green-700 mr-2 -ml-2" />
  </span> -->
</div>
