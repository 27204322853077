<template>
  <div class="space-y-12 flex flex-col justify-stretch items-center">
    <FormMessage
      v-if="formError"
      supress-generic-message
      class="w-full"
    >
      {{ formError.message }}
    </FormMessage>
    <b-form class="space-y-12 w-full" @submit.prevent="formSubmit">
      <FormField
        :v="$v.email"
        :messages="{
          required: $t('login.email-required'),
          email: $t('login.email-not-valid'),
        }"
        wrapper-class="portal-paragraph-font"
      >
        <b-input
          autofocus
          autocomplete="username"
          :placeholder="$t('login.email')"
          class="form-control-lg portal-paragraph-font"
          :value="formState.email"
          @input="onEmailInput"
        />
      </FormField>

      <FormField
        :v="$v.password"
        :messages="{
          required: $t('login.password-required'),
        }"
        wrapper-class="portal-paragraph-font"
      >
        <FormInputPassword
          v-model="formState.password"
          :placeholder="$t('login.password')"
          input-class="portal-paragraph-font"
        />
      </FormField>
      <div class="links">
        <b-link @click="$emit('recover-password')">
          {{ $t('login.password-recover') }}
        </b-link>
      </div>
      <Button
        class="mt-16 portal-paragraph-font portal-button"
        type="submit"
        size="lg"
        variant="primary"
        :block="true"
        :is-loading="formIsBusy"
      >
        {{ $t('login.login') }}
      </Button>
    </b-form>
  </div>
</template>
<script>
import { defineComponent, reactive } from '@vue/composition-api'
import { required, email } from '@vuelidate/validators'
import { email as emailSanitizer } from '@/v2/lib/helpers/sanitizer'
import { useUser } from '@/v2/services/users/usersCompositions'
import useForm from '@/v2/lib/composition/useForm'
import FormField from '@/components/FormField.vue'
import Button from '@/components/Button.vue'
import FormMessage from '@/components/FormMessage.vue'
import FormInputPassword from './FormInputPassword.vue'

export default defineComponent({
  name: 'ClientPortalLoginPassword',
  components: {
    FormField,
    Button,
    FormMessage,
    FormInputPassword,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    domain: {
      type: Object,
      required: true,
    },
    portalCustomizations: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup() {
    const { authenticateLocal } = useUser()

    const formState = reactive({
      email: '',
      password: '',
    })

    const { formSubmit, formError, formIsBusy, $v } = useForm({
      onSubmit: () => authenticateLocal(formState),
      state: formState,
      validation: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    })

    const onEmailInput = value => {
      formState.email = emailSanitizer(value)
    }

    return {
      formIsBusy,
      formState,
      formSubmit,
      formError,
      $v,
      onEmailInput,
    }
  },
})
</script>
<style lang="scss" scoped>
.links {
  color: $gray-500;
  font-size: $fs14;
  margin-bottom: 14px;
  a {
    color: $gray-500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
