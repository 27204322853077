
<ModalBasic
  title="Document Fonts"
  :hide-footer="true"
  dialog-class="w-full"
  content-class="modal-content-tertiary"
  body-class="modal-body-tertiary"
  header-class="modal-header-tertiary"
  footer-class="modal-footer-tertiary"
  size="lg"
  v-bind="$attrs"
  :show-back="openToSelectFont"
  v-on="$listeners"
  @back="backToForm"
>
  <template #body="{ close }">
    <b-form
      v-if="!openToSelectFont"
      @submit.prevent="formSubmit"
    >
      <FormMessage v-if="formError">
        {{ formError.message }}
      </FormMessage>
      <FormPickFont
        :model="model"
        @change-font="changeFont"
      />
      <Buttons>
        <Button
          type="button"
          variant="neutral"
          size="md"
          @click="close"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="md"
        >
          Save
        </Button>
      </Buttons>
    </b-form>
    <BrandingFonts
      v-if="openToSelectFont"
      :apply-font-on="applyFontOn"
      :model="model"
      @select="selectFont"
    />
  </template>
</ModalBasic>
