
<div v-fragment>
  <portal v-if="$slots['sidebar-header']" to="app-sidebar-header">
    <slot name="sidebar-header" />
  </portal>
  <portal v-if="$slots.sidebar" to="app-sidebar">
    <slot name="sidebar" />
  </portal>
  <!-- <portal to="app-sidebar-right">
    <slot name="sidebar-right" />
  </portal> -->
  <slot />
</div>
