<template>
  <div>
    <h2
      class="portal-heading-font drop-shadow-xl"
      :class="headerClass"
    >
      {{ greeting }},
      <p
        v-if="!isClientPortal"
        v-b-tooltip.hover.top.v-info.dh0.ds200="$t('greeting.tooltip')"
        class="relative inline hover:cursor-help"
      >
        <span
          class="underline decoration-wavy decoration-2 decoration-white/30 underline-offset-4"
        >
          {{ profile.firstName }}
        </span>
      </p>
      <p v-else class="inline">{{ isPortalPreview ? '[client name]' : profile.firstName }}</p>
    </h2>
  </div>
</template>

<script>
import { defineComponent, computed, inject } from '@vue/composition-api'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import useLocalization from '@/v2/lib/composition/useLocalization'

export default defineComponent({
  name: 'Greeting',
  props: {
    fontFamily: {
      type: String,
      default: 'Inter',
    },
    headerClass: {
      type: String,
      default: '',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isPortalPreview: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { profile } = useCurrentProfile()
    const { translate } = useLocalization()
    const isClientPortal = inject('isClientPortal', false)

    // greeting
    const hourNow = computed(() => {
      const today = new Date()
      return today.getHours()
    })

    const greeting = computed(() => {
      if (hourNow.value > 18 && profile.value) {
        return translate('greeting.good-evening').value
      }

      if (hourNow.value > 12 && profile.value) {
        return translate('greeting.good-afternoon').value
      }

      if (hourNow.value > 0 && profile.value) {
        return translate('greeting.good-morning').value
      }

      return translate('greeting.welcome').value
    })

    return {
      greeting,
      isClientPortal,
      profile,
    }
  },
})
</script>
