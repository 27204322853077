export const STATUS = /** @type {const} */ ({
  active: 'active',
  subscriptionRequired: 'subscriptionRequired',
})

export const ONBOARDING_STATUS = /** @type {const} */ ({
  details: 'details',
  branding: 'branding',
  projectTemplate: 'projectTemplate',
  completed: 'completed',
})

export const SUBSCRIPTION_STATUS = /** @type {const} */ ({
  inTrial: 'inTrial',
  active: 'active',
  nonRenewing: 'nonRenewing',
  cancelled: 'cancelled',
  pending: 'pending',
})

export const DOMAIN_STATUS = /** @type {const} */({
  active: 'active',
  locked: 'locked',
  awaitingCNAMEConfirmation: 'awaitingCNAMEConfirmation',
  CNAMEConfirmed: 'CNAMEConfirmed',
  setupPending: 'setupPending',
  setupError: 'setupError',
  removeRequested: 'removeRequested',
  cancelSetupRequested: 'cancelSetupRequested',
  cancelSetupPending: 'cancelSetupRequested',
});

export const EMAIL_STATUS = /** @type {const} */({
  active: 'active',
  locked: 'locked',
  removeRequested: 'removeRequested',

  // domain
  createDomainPending: 'createDomainPending',
  createDomainError: 'createDomainError',

  // DKIM
  awaitingDKIMConfirmation: 'awaitingDKIMConfirmation',
  DKIMConfirmed: 'DKIMConfirmed',
  DKIMValidationPending: 'DKIMValidationPending',
  DKIMValidationError: 'DKIMValidationError',

  // cancel setup
  cancelSetupRequested: 'cancelSetupRequested',
  cancelSetupPending: 'cancelSetupRequested',
});

export const LICENSING_TYPE = /** @type {const} */ ({
  subscription: 'subscription',
  license: 'license',
});

export const LICENSING_PROVIDER = /** @type {const} */ ({
  chargebee: 'chargebee',
  appsumo: 'appsumo',
  superokay: 'superokay', // used for old superokay free plans
})

