<template>
  <div
    :class="[
      ' text-gray-800 dark:text-darkGray-300 leading-tight max-w-list',
      isDisabled && 'text-gray-900 dark:text-darkGray-700',
    ]"
  >
    <LoaderWebFont :families="families" preview>
      <p
        :style="{
          fontFamily: font,
          fontSize: `${fontSize}px`,
        }"
      >
        {{ previewTextMap[variant] }}
      </p>
    </LoaderWebFont>
  </div>
</template>

<script>
import { isString } from 'ramda-adjunct'
import { computed } from '@vue/composition-api'
import LoaderWebFont from '@/components/LoaderWebFonts.vue'

const previewTextMap = {
  heading: 'The quick brown fox jumps over the lazy dog',
  paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque lacus felis, porttitor ut diam in, maximus interdum lectus. Maecenas gravida dui vel erat placerat, id bibendum enim scelerisque.',
}

export default {
  name: 'BrandingFontsPreview',
  components: {
    LoaderWebFont,
  },
  props: {
    font: {
      type: String,
      default: null,
    },
    fontSize: {
      type: Number,
      default: 24,
    },
    variant: {
      type: String,
      default: 'heading',
    },
  },

  setup(props) {
    const isDisabled = computed(() => !props.font)
    const families = computed(() => (isString(props.font) ? [props.font] : null))

    return {
      isDisabled,
      families,
      previewTextMap,
    }
  },
}
</script>

<style scoped>

</style>
