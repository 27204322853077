<template>
  <DocumentContentBlockQASettings
    :description="description"
    :has-title="hasTitle"
    :prevent-popover-close="true"
    :popover-target-id="popoverTargetId"
    @input="handleSettingsToggle"
  >
    <SettingsSwitch
      v-model="hasNotes"
      tooltip="Enable client notes"
      label="Notes"
    />

    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Type"
      label-for="disabled-fieldset"
      class="flex items-center justify-between btn-group-sp-wrap"
    >
      <b-form-radio-group
        id="btn-radios-1"
        v-model="selectedType"
        :options="types"
        :aria-describedby="ariaDescribedby"
        name="radios-btn-default"
        button-variant="radio"
        class="btn-group-sp"
        buttons
        plain
      />
    </b-form-group>

    <div
      v-if="selectedType === 'icon'"
      class="flex items-center justify-between"
    >
      <div class="text-gray-600 text-14">
        Symbol
      </div>
      <div class="inline-flex py-6 space-x-16 text-gray-500">
        <label
          for="rating-star"
          class="relative block w-24 h-24 cursor-pointer"
        >
          <input
            id="rating-star"
            v-model="selectedIcon"
            type="radio"
            name="rating"
            value="star"
            class="absolute z-0 opacity-0 form-control-rating"
          >
          <span
            class="relative block h-24 icon-util-star-full text-24 z-1"
          />
        </label>
        <label
          for="rating-heart"
          class="relative block w-24 h-24 cursor-pointer"
        >
          <input
            id="rating-heart"
            v-model="selectedIcon"
            type="radio"
            name="rating"
            value="heart"
            class="absolute z-0 opacity-0 form-control-rating"
          >
          <span class="relative block h-24 icon-heart text-24 z-1" />
        </label>
        <label
          for="rating-price"
          class="relative block w-24 h-24 cursor-pointer"
        >
          <input
            id="rating-price"
            v-model="selectedIcon"
            type="radio"
            name="rating"
            value="price"
            class="absolute z-0 opacity-0 form-control-rating"
          >
          <span class="relative block h-24 icon-util-price text-24 z-1" />
        </label>
        <label
          for="rating-user"
          class="relative block w-24 h-24 cursor-pointer"
        >
          <input
            id="rating-user"
            v-model="selectedIcon"
            type="radio"
            name="rating"
            value="user"
            class="absolute z-0 opacity-0 form-control-rating"
          >
          <span
            class="relative block h-24 icon-util-person-full text-24 z-1"
          />
        </label>
      </div>
    </div>

    <div class="flex items-center py-4">
      <label
        :for="componentID"
        class="mr-24 text-gray-600 text-14"
      >Steps</label>
      <RangeSlider
        v-model="steps"
        :min="minRange"
        :max="maxRange"
      />
      <div class="flex-none ml-8">
        <b-input
          :id="componentID"
          :value="steps"
          type="number"
          step="1"
          :min="minRange"
          :max="maxRange"
          size="sm"
          class="form-control-number w-36"
          number
          required
          @input="setSteps"
        />
      </div>
    </div>
    <SettingsSwitch
      v-model="showLabel"
      label="Show Labels"
    />
    <div v-if="showLabel">
      <SettingsInput
        v-model="leftLabel"
        label="Left"
        icon="util-label-l"
      />
      <SettingsInput
        v-model="centerLabel"
        label="Middle"
        icon="util-label-c"
      />
      <SettingsInput
        v-model="rightLabel"
        label="Right"
        icon="util-label-r"
      />
    </div>
  </DocumentContentBlockQASettings>
</template>

<script>
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import { computed } from '@vue/composition-api'
import { restrictValue } from '@/v2/lib/helpers/fp'
import RangeSlider from '@/components/RangeSlider.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import SettingsInput from '@/components/SettingsInput.vue'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQARatingSettings',
  components: {
    RangeSlider,
    DocumentContentBlockQASettings,
    SettingsSwitch,
    SettingsInput,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    // bindings props are refs
    const bindings = useContentBlockSettings(props, [
      'description',
      'hasTitle',
      'types',
      'selectedType',
      'showLabel',
      'leftLabel',
      'rightLabel',
      'centerLabel',
      'hasNotes',
      'steps',
      'selectedIcon',
    ])

    const maxRange = 10
    const minRange = 3
    const restrictStepsValue = restrictValue(minRange, maxRange)
    const range = computed(() => (props.value.steps * 100) / maxRange)
    const setSteps = val => {
      bindings.steps.value = restrictStepsValue(Number(val))
    }
    const handleSettingsToggle = object => {
      bindings[object.type].value = object.value
    }
    return {
      ...bindings,
      maxRange,
      minRange,
      range,
      setSteps,
      handleSettingsToggle,
    }
  },
}
</script>
