export const SUBJECT = /** @type {const} */ ({
  actionItems: 'action-items',
  comments: 'comments',
  contentBlocks: 'content-blocks',
  documents: 'documents',
  organizations: 'organizations',
  projects: 'projects',
})

export const ACTION = /** @type {const} */ ({
  [SUBJECT.actionItems]: {
    created: 'created',
    resolved: 'resolved',
    reopened: 'reopened',
    assigned: 'assigned',
  },
  [SUBJECT.comments]: {
    created: 'created',
    resolved: 'resolved',
    reopened: 'reopened',
  },
  [SUBJECT.contentBlocks]: {
    qaAnswered: 'qaAnswered',
  },
  [SUBJECT.documents]: {
    created: 'created',
    drafted: 'drafted',
    approved: 'approved',
    notify: 'notify',
    sharedByVendor: 'sharedByVendor',
    sharedByClient: 'sharedByClient',
    viewGuest: 'viewGuest',
    firstViewGuest: 'firstViewGuest',
    newViewsGuest: 'newViewsGuest',
    newQaAnswers: 'newQaAnswers',
  },
  [SUBJECT.organizations]: {
    libraryDocumentAdded: 'libraryDocumentAdded',
  },
  [SUBJECT.projects]: {
    newSharesVendor: 'newSharesVendor',
    newSharesClient: 'newSharesClient',
  },
})
