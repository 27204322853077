import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import commentsService, { servicePath } from './commentsService'

class Comment extends BaseModel {
  static modelName = 'Comment'

  static instanceDefaults() {
    return {
      read: false,
      isResolved: false,
    }
  }
}

export default makeServicePlugin({
  Model: Comment,
  service: commentsService,
  servicePath,
})
