import * as R from 'ramda'
import { v4 as uuidv4 } from 'uuid'

export const uuid = () => uuidv4()
export const uuidAssign = R.over(R.lensProp('uuid'), uuid)

export const uniqueId = prefix => {
  let id = 0
  return () => `${prefix}${id++}`
}
