<template>
  <DocumentLink v-slot="{ href, navigate }" :document="document">
    <DocumentComputedData v-slot="{ title }" :document="document">
      <a
        :href="href"
        class="line-clamp-1"
        @click="navigate"
      >
        <WrappedTitle
          :text="title"
          :pre-size="34"
        />
      </a>
    </DocumentComputedData>
  </DocumentLink>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import DocumentLink from '@/components/DocumentLink.vue'
import DocumentComputedData from '@/components/DocumentComputedData.vue'
import WrappedTitle from '@/components/WrappedTitle.vue'

export default defineComponent({
  name: 'DocumentLinkTitle',
  components: {
    DocumentLink,
    DocumentComputedData,
    WrappedTitle,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
})
</script>
