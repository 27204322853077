
<DocumentContentBlockQASettings
  :description="description"
  :has-title="hasTitle"
  :popover-target-id="popoverTargetId"
  @input="handleSettingsToggle"
>
  <SettingsSwitch
    v-model="hasNotes"
    tooltip="Enable client notes"
    label="Notes"
  />
</DocumentContentBlockQASettings>
