<template>
  <div :class="[{ error: err, valid }, wrapperClass]">
    <label
      v-if="label"
      :for="labelFor"
      class="items-center mb-4 text-14 text-gray-500 dark:text-darkGray-400"
      :class="[labelClass, aside ? 'flex' : 'inline-flex']"
    >
      {{ label }}
      <span
        v-if="infoLabel"
        v-b-tooltip.hover.top.v-info.dh0.ds200="infoLabel"
        class="icon_v2-so_info-solid text-14 ml-4 text-gray-400 bg-transparent"
      ></span>
      <sup
        v-if="required"
        class="font-medium leading-none uppercase opacity-30 -top-2 text-12"
      >*</sup>
      <span v-if="aside" class="ml-auto opacity-50">{{ aside }}</span>
    </label>
    <slot v-bind="{ error: err, valid, pending, state }" />
    <span v-if="err" :class="[errorClass, 'text-error mb-4']">{{ message }}</span>
    <div
      v-if="pending && validationPendingMessage"
      :class="[validationPendingClass, 'text-14 mt-4 flex items-center text-green-600']"
    >
      <Spinner :size="14" /><span class="ml-8">{{ validationPendingMessage }}</span>
    </div>
    <b-form-text v-if="description" :class="descriptionClass">
      <template v-if="Array.isArray(description)">
        <p v-for="line in description" :key="line">{{ line }}</p>
      </template>
      <template v-else>{{ description }}</template>
    </b-form-text>
  </div>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import Spinner from '@/components/Spinner.vue'
// $invalid is true when the field is invalid.
// $error is true when the field is invalid AND it has been $touched.
// This is useful so that we don't immediately show all fields as invalid
// before the user has had a chance to fill them out.
export default defineComponent({
  name: 'FormField',
  components: {
    Spinner,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    aside: {
      type: String,
      default: null,
    },
    description: {
      type: [String, Array],
      default: null,
    },
    labelFor: {
      type: String,
      default: null,
    },
    infoLabel: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    descriptionClass: {
      type: String,
      default: '',
    },
    errorClass: {
      type: String,
      default: null,
    },
    validationPendingClass: {
      type: String,
      default: null,
    },
    validationPendingMessage: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: null,
    },
    error: {
      type: [String, Object, Error],
      default: null,
    },
    messages: {
      type: Object,
      default() {
        return {
          required: 'This field is required',
          email: 'This does not look like an email address',
          url: 'This doesn\'t seem to be a valid URL',
        }
      },
    },
  },
  setup(props) {
    const message = computed(() => {
      // -- direct error message
      if (props.error) {
        return props.error instanceof Error
          ? props.error.message
          : String(props.error)
      }

      // -- vuelidate error message
      if (!props.v?.$error) {
        return null
      }

      const { $message, $validator, $pending } = props.v.$errors[0]

      return $pending
        ? null
        : props.messages[$validator] || $message
    });

    const err = computed(
      () => props.error || (!props.v?.$pending && props.v?.$error)
    )

    const valid = computed(
      () => !props.error && (!props.v?.$pending && !props.v?.$invalid)
    )

    const pending = computed(() => props.v?.$pending)

    const state = computed(() => {
      if (pending.value || !props.v?.$dirty || (!err.value && !valid.value)) {
        return null;
      }

      return valid.value
    })

    return {
      message,
      err,
      valid,
      pending,
      state,
    }
  },

})
</script>
