
<div
  id="page-header-search-input"
  v-click-outside.capture="() => $emit('close')"
>
  <div class="w-320 relative">
    <b-input
      :value="value"
      trim
      type="text"
      :placeholder="placeholder"
      class="rounded-sm pr-36"
      size="lg"
      @input="onKeywordChange"
      @keydown.esc="$emit('close')"
    />
    <span class="icon_v2-so_search text-gray-300 absolute right-10 top-6"></span>
  </div>
  <b-popover
    ref="popover"
    triggers="manual"
    target="page-header-search-input"
    :show="showResult"
    placement="bottomright"
    custom-class="max-w-[600px] shadow-md border-gray-300 p-8 pb-0"
    boundary-padding="25"
    @update:show="val => $emit('update:showResult', val)"
  >
    <div>
      <slot />
    </div>
  </b-popover>
</div>
