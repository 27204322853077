<template>
  <portal
    :to="`content-block-settings-${nodeId}`"
  >
    <div class="flex flex-row">
      <DocumentNodeEditorToolbarSeparator />
      <DocumentNodeEditorToolbarGroup
        label="Settings"
      >
        <DocumentContentBlockCostBreakdownSettings
          :popover-target-id="popoverTargetId"
          :value="contentBlock.settings"
          @input="(settings, data) => $emit('update', { settings, ...data })"
        />
      </DocumentNodeEditorToolbarGroup>
    </div>
  </portal>
</template>

<script>

import DocumentNodeEditorToolbarGroup from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarGroup.vue'
import DocumentNodeEditorToolbarSeparator from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSeparator.vue'
import DocumentContentBlockCostBreakdownSettings from './DocumentContentBlockCostBreakdownSettings.vue'

export default {
  name: 'DocumentContentBlockCostBreakdownToolbarPortal',
  components: {
    DocumentNodeEditorToolbarGroup,
    DocumentNodeEditorToolbarSeparator,
    DocumentContentBlockCostBreakdownSettings,

  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    popoverTargetId: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>

</style>
