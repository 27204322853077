<template>
  <ListLightDropdown
    size="sm"
    position="right"
    menu-class="p-8"
    :toggle-class="['btn-slim',
                    'text-gray-700 hover:text-gray-900',
                    className]"
    lazy
    no-caret
  >
    <template #button-content>
      <span class="inline-flex ml-4 mr-6">
        <span :class="icon" />
      </span>
      <span class="hidden md:inline">{{ selectedOption ? selectedOption.text : placeholder }}</span>
      <span
        class="flex items-center justify-center w-32 h-32 -mr-6"
      >
        <span class="inline-flex">
          <span class="icon_v2-so_direction-down"></span>
        </span>
      </span>
    </template>

    <template #default>
      <div class="text-14">
        <ListLightDropdownItem
          v-for="option in options"
          :key="option.value"
          :label="option.text"
          :icon="option.icon"
          :active="option.value === value"
          @select="$emit('input', option.value)"
        />
      </div>
    </template>
  </ListLightDropdown>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import ListLightDropdown from '@/components/List/ListLightDropdown.vue'
import ListLightDropdownItem from '@/components/List/ListLightDropdownItem.vue';


export default defineComponent({
  name: 'DropdownSelect',
  components: {
    ListLightDropdown,
    ListLightDropdownItem,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
  },
  setup(props) {
    const selectedOption = computed(
      () => props.options.find(({ value }) => value === props.value)
    )

    return {
      selectedOption,
    }
  },
})
</script>
