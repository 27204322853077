<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useGet } from 'feathers-vuex'
import debounce from '@/v2/lib/helpers/debounce'
import { keysEq } from '@/v2/lib/helpers/fp'

const isSettingsUpdate = keysEq(['settings'])

export default {
  name: 'DocumentNodeEditorModel',
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    debounce: {
      type: Number,
      default: 500,
    },
    useClone: Boolean,
  },
  setup(props, context) {
    const { DocumentNode } = context.root.$FeathersVuex.api

    const queryWhen = computed(() => {
      if (props.nodeId === 'root') {
        return false;
      }

      const _node = DocumentNode.getFromStore(props.nodeId)

      // if this node was inserted from a websocket event, we need to load its contentBlock$
      // realtime events DON'T include the populated entities (in this case, contentBlock$)
      // return true to force a node REST GET
      return !_node || (!_node.isGroup && !_node.contentBlock$)
    })

    const { item: node, isPending } = useGet({
      model: DocumentNode,
      id: props.nodeId,
      // local: true,
      queryWhen,
    })

    const isAsyncSave = ref(false)
    const nodeRef = ref(null)
    const contentBlockRef = ref(null)
    const useClone = computed(() => props.useClone || isAsyncSave.value)

    const toggleClone = newVal => {
      const contentBlock = node.value?.contentBlock$ ?? null
      if (newVal && node.value) {
        nodeRef.value = node.value.clone()
        contentBlockRef.value = contentBlock ? contentBlock.clone() : null
      } else {
        nodeRef.value = node.value
        contentBlockRef.value = contentBlock
      }
    }

    const saveNode = debounce(
      data => node.value.save({ data }).then(() => {
        isAsyncSave.value = false
      }),
      props.debounce
    )

    const updateNode = data => {
      if (!useClone.value) {
        // console.warn('calling [updateNode] when [useClone=false]')
        // return
        toggleClone(true)
      }

      Object.assign(nodeRef.value, data)
      nodeRef.value.commit()
      saveNode(data)
    }

    // const updateNodeStyling = styling =>
    //   updateNode({ styling: mergeDeepRight(nodeRef.value.styling, styling) })

    const saveContentBlock = data => node.value.contentBlock$.save({ data }).then(() => {
      isAsyncSave.value = false
    })

    const saveContentBlockDebounced = debounce(saveContentBlock, props.debounce)
    const saveContentBlockSettingsDebounced = debounce(
      saveContentBlock,
      props.debounce
    )

    const updateContentBlock = async (data, options = {}) => {
      if (!useClone.value) {
        // console.warn('calling [updateContentBlock] when [useClone=false]')
        // return
        toggleClone(true)
      }

      Object.assign(contentBlockRef.value, data)
      !options.$sync && contentBlockRef.value.commit()

      if (options.$sync) {
        await saveContentBlock(data)
        toggleClone(false)
      } else if (isSettingsUpdate(data)) {
        saveContentBlockSettingsDebounced(data)
      } else {
        saveContentBlockDebounced(data)
      }
    }

    const asyncSaveStart = () => {
      isAsyncSave.value = true
    }

    watch(isPending, newVal => {
      if (!newVal) {
        toggleClone(useClone.value)
      }
    }, { immediate: true });

    watch(useClone, toggleClone)

    return () => (isPending.value ? null : context.slots.default({
      // node
      node: nodeRef.value,
      updateNode,
      // updateNodeStyling,

      // content block
      contentBlock: contentBlockRef.value,
      updateContentBlock,

      // advanced flow management
      asyncSaveStart,
    }))
  },
}
</script>
