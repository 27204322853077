
<div>
  <!-- Enpty analytics -->
  <p v-if="!analytics.length" class="text-center text-gray-500">
    Nothing to show
  </p>
  <!-- Days / Event Groups -->
  <div class="pb-40 space-y-16">
    <!-- --- -->
    <!-- DAY -->
    <!-- --- -->
    <div
      v-for="group in analytics"
      :key="group.day"
      class="flex flex-row"
    >
      <!-- ------ -->
      <!-- Bullet -->
      <!-- ------ -->
      <div class="flex items-center justify-center w-24 h-24">
        <span class="w-12 h-12 bg-green-600 rounded-full"></span>
      </div>
      <!-- ----------------- -->
      <!-- DAY GROUP CONTENT -->
      <!-- ----------------- -->
      <div class="flex-1 min-w-0">
        <!-- TITLE -->
        <h4 class="mb-4 font-semibold text-14">
          {{ group.time | dateFormat("eeee',' dd MMMMMMMM yyyy") }}
        </h4>
        <!-- ------------------------------ -->
        <!-- LIST OF EVENTS FROM THIS GROUP -->
        <!-- ------------------------------ -->
        <div class="flex flex-row items-stretch">
          <!-- ------------ -->
          <!-- TIMELINE BAR -->
          <!-- ------------ -->
          <div class="flex items-center justify-center w-24 -ml-24 ">
            <span class="block w-2 h-full bg-gray-300 rounded-full"></span>
          </div>

          <!-- EVENTS -->
          <ul class="grow min-w-0 space-y-8">
            <li
              v-for="event in group.items"
              :key="event._id"
              class="flex items-center text-gray-700 text-14"
            >
              <!-- ICON -->
              <div class="flex items-center justify-center flex-shrink-0 w-24 bg-gray">
                <span
                  :class="[
                    'text-gray-400',
                    {
                      'icon-util-view text-gray-600': event.isViewGuest && !event.isPdfDownload,
                      'icon_v2-so_download text-gray-600': event.isViewGuest && event.isPdfDownload,
                      'icon-object-QA-answer text-gray-600': event.isQaAnswered,
                    }
                  ]"
                />
              </div>
              <!-- NOTIFICATION BODY -->
              <p v-if="event.isViewGuest" class="grow">
                <template v-if="event.isPdfDownload">
                  <span class="font-normal text-gray-700">
                    Document downloaded as PDF
                  </span>
                </template>
                <template v-else>
                  <span class="font-normal text-gray-700">
                    Viewed from {{ event.clientGeoIp | geoIp }},
                  </span>
                  <span class="text-gray-500">
                    {{ event.clientUserAgent | userAgent }}
                  </span>
                </template>
              </p>
              <h5
                v-else-if="event.isQaAnswered"
                class="flex flex-1 min-w-0 mr-16"
              >
                <span class="truncate max-w-384">Answered "{{ event.subject$.title }}"</span>
              </h5>

              <!-- Date -->
              <p class="flex-shrink-0 text-gray-500 text-12">
                {{ event.time | dateFormat('hh:mm aaa') }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
