
<button
  v-bind="$attrs"
  :disabled="disabled || isLoading"
  :class="['so-button-progress bounce']"
  v-on="$listeners"
>
  <div
    :class="[
      'absolute inset-0 z-0 bg-green-900 bg-opacity-50',
    ]"
  >
    <span
      class="progress-bar"
      :style="`width: ${progress}%`"
    >
    </span>
  </div>
  <div
    :class="[
      'relative flex items-center justify-center z-1'
    ]"
  >
    <slot />
  </div>
  <Spinner
    v-if="isLoading"
    :size="16"
    variant="light"
    class="ml-8"
  />
</button>
