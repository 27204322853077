import { iff } from 'feathers-hooks-common'
import { ErrorEx, ERROR_CODES } from '@/lib/errors'
import feathersClient from '@/v2/services/feathersClientService'
import { isStatusCodeForbidden } from '@/v2/lib/hooks/errorHandlers'

export const servicePath = 'tokens'
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [
      // NOTE: The authorization rules for the `tokens` service also checks the expiry date so no
      // expired tokens can be fetched. Because of this, the error message should be overwritten
      // to better suggest what went wrong
      iff(
        isStatusCodeForbidden,
        context => {
          context.error = new ErrorEx(ERROR_CODES.INVALID_TOKEN)
        }
      ),
    ],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default service;
