<template>
  <div class="flex items-center gap-x-8">
    <div v-if="logoUrl">
      <img :src="logoUrl" class="logo" />
    </div>
    <div v-else class="text-14 label">{{ label }}</div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MainHeaderLogo',
  components: {

  },
  props: {
    logoUrl: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
})
</script>
<style lang="postcss" scoped>
  .logo {
    max-height: 32px;
    width: auto;
  }

  .label {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow-x: hidden;
    max-width: calc(100vw / 4);
  }
</style>
