
<div class="bg-white dark:bg-darkGray-1000 -mx-16 px-16 -mb-16 pb-16 pt-16">
  <b-form @submit.prevent="onSubmit">
    <!-- GUEST DETAULS -->
    <template v-if="step === STEPS.GUEST_DETAILS">
      <div
        class="
          text-14 text-gray-700 dark:text-darkGray-400
          p-12 leading-tight border-b border-gray-200 dark:border-darkGray-700
        "
      >
        {{ $t('comments.message-need-email') }}
      </div>
      <b-form-group class="border-b border-gray-200 dark:border-darkGray-700 m-0">
        <FormField
          :v="$v.name"
          :messages="{
            required: $t('forms.name-required'),
          }"
          required
          error-class="ml-12 text-12"
        >
          <b-input
            v-model="commentModel.name"
            autofocus
            :placeholder="$t('forms.name-placeholder')"
            class="form-control-lg border-none px-12 rounded-none"
          />
        </FormField>
      </b-form-group>

      <b-form-group class="m-0">
        <FormField
          :v="$v.email"
          :messages="{
            email: $t('forms.email-invalid'),
            required: $t('forms.email-required'),
          }"
          required
          error-class="ml-12 text-12"
        >
          <b-input
            v-model.trim="commentModalEmail"
            :placeholder="$t('forms.email-placeholder')"
            class="form-control-lg border-none px-12 rounded-none"
          />
        </FormField>
      </b-form-group>
    </template>
    <!-- <div v-if="user" class="px-12 pt-12 pb-6 dark:text-darkGray-300">
      <AvatarUser
        :user-id="user._id"
        :size="32"
        font-size="14"
        :class="'font-semibold'"
      />
    </div> -->
    <div
      v-else-if="showGuestAvatar"
      class="flex items-center px-12 pt-12 pb-10"
    >
      <AvatarUser
        :fallback-name="commentModel ? commentModel.name : guestDetails.name"
        :show-name="false"
        :size="32"
        font-size="14"
      />
      <span class="flex justify-center flex-col flex-1 ml-6 whitespace-nowrap">
        <span class="text-14 font-semibold">
          {{ commentModel ? commentModel.name : guestDetails.name }}
        </span>
        <span class="text-14 text-gray-700 leading-tight">
          {{ commentModel ? commentModel.email : guestDetails.email }}
        </span>
      </span>
      <b-button
        v-if="step === STEPS.MESSAGE_FORM"
        variant="transparent"
        class="text-gray-500 px-0"
        @click="step = STEPS.GUEST_DETAILS"
      >
        <span class="icon_v2-so_pencil mr-8"></span>
      </b-button>
    </div>
    <template v-if="step === STEPS.MESSAGE_FORM">
      <b-form-group class="m-0">
        <div class="mb-16">
          <FormField
            :v="$v.message"
            :messages="{
              required: $t('forms.message-required'),
            }"
            error-class="text-12"
          >
            <b-form-textarea
              v-model="commentModel.message"
              autofocus
              :placeholder="$t('forms.message-placeholder')"
              rows="3"
              max-rows="6"
              class="bg-transparent"
              :class="$style['textarea']"
              @keyup.ctrl.enter="onSubmit"
              @keydown.meta.enter="onSubmit"
            />
          </FormField>
        </div>
      </b-form-group>
    </template>
    <!-- bottom buttons -->
    <div
      class="flex items-center flex-row-reverse justify-between"
    >
      <CommentsFormButton
        v-if="step === STEPS.NEW_MESSAGE_BTN"
        type="button"
        icon="icon_v2-so_chat-plus"
        :label="$t('comments.add-comment')"
        :is-new-guest="isNewGuest"
        @click.prevent="onNewMessage"
      />

      <CommentsFormButton
        v-else-if="step === STEPS.GUEST_DETAILS"
        type="submit"
        :label="$t('common.continue')"
        :disabled="$v.$invalid"
      />

      <CommentsFormButton
        v-if="step === STEPS.MESSAGE_FORM"
        type="submit"
        icon="icon_v2-so_chat-plus"
        :label="comment ? $t('common.save-changes') : $t('comments.post-comment')"
        :disabled="$v.$invalid"
      />
    </div>
  </b-form>
</div>
