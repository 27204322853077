<template>
  <div v-if="!readonlySections">
    <InfoBox
      :title="$t('modules.tasks')"
      icon="icon_v2-so_action-items"
    >
      Use tasks to set due dates and make a list of work that needs to get done.
    </InfoBox>
    <Button
      variant="primary"
      size="sm"
      @click="$emit('create-section')"
    >
      <span class="xl:mr-2 xl:-ml-2 icon_v2-so_plus" />
      Create Section
    </Button>
  </div>
  <InfoBox
    v-else
    :title="$t('modules.tasks')"
    icon="icon_v2-so_action-items"
  >
    {{ $tc('common.action-items', 0) }}
  </InfoBox>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Button from '@/components/Button.vue'
import InfoBox from '@/components/InfoBox.vue'

export default defineComponent({
  name: 'TasksPlaceholder',
  components: {
    InfoBox,
    Button,
  },
  props: {
    readonlySections: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
