<script>
import { computed, defineComponent } from '@vue/composition-api'
import { useCheckMemberRole } from '@/v2/services/myMembers/compositions'
import { PERMISSIONS } from '@/v2/services/projects/projectsTypes'
import { ROLES } from '@/v2/services/members/membersTypes';

/**
 * @typedef {import('@vue/composition-api').ComputedRef<T>} ComputedRef
 * @template T
 */

/**
 * @typedef {Object} Props
 * @property {import('@/v2/services/projects/projects').ProjectData} project
 */

const defaultPermissions = {
  [PERMISSIONS.create]: true,
  [PERMISSIONS.update]: true,
  [PERMISSIONS.remove]: true,
};

const defaultPermissionsRoles = [
  ROLES.admin,
  ROLES.projectManager,
  ROLES.member,
  ROLES.tokenDocumentShare,
];

const defaults = Object.fromEntries(
  defaultPermissionsRoles.map(role => [role, defaultPermissions])
)

export default defineComponent({
  name: 'ProjectPermissions',
  props: /** @type {Props} */ ({
    project: {
      type: Object,
      required: true,
    },
  }),
  /**
   * @param {Props} props
   */
  setup(props, context) {
    const { role } = useCheckMemberRole()

    /**
     * @type {ComputedRef<Props['project']['permissions']>}
     */
    const permissions = computed(() => ({
      ...defaults[role.value],
      ...props.project?.permissions?.[role.value] ?? {},
    }))

    return () => context.slots.default({ permissions: permissions.value })
  },
})
</script>
