<template>
  <b-dropdown-item :active="active" @click="$emit('select')">
    <div class="flex items-center transition-all">
      <div
        class="relative inline-flex items-center justify-center w-20 mr-8 -top-px text-20"
      >
        <span class="text-16" :class="`icon_v2-so_${icon}`"></span>
      </div>
      <span> {{ label }}</span>
    </div>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarMenuItem',
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    active: Boolean,
  },
}
</script>
