<template>
  <DocumentNodeRenderer
    :node="node"

    :section-class="$style['node-section-embedded-doc']"
    :config-override="{
      stylingToolbar: {
        features: {
          sizes: true,
        },
      },
    }"
  >
    <template #default="{styling, nodeWidth}">
      <DocumentContentBlockEmbeddedDoc
        :content-block="contentBlock"
        :styling="styling"
        standalone
        :show-analytics="isEditor"
        :node-width="nodeWidth"
        @open="open"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { defineComponent, inject } from '@vue/composition-api'
import { useDocuments } from '@/v2/services/documents/documentsCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockEmbeddedDoc from './DocumentContentBlockEmbeddedDoc.vue'

export default defineComponent({
  name: 'DocumentNodeEmbeddedDoc',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockEmbeddedDoc,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isEditor = inject('isEditor')

    const { open: open$ } = useDocuments()
    const open = document => open$(document)

    return {
      open,
      isEditor,
    }
  },
})
</script>
<style lang="scss" module>
.node-section-embedded-doc {
  @include media-breakpoint-down(sm) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
</style>
