<template>
  <div
    class="relative z-20 flex justify-center w-full h-full group"
    v-bind="$attrs"
  >
    <slot />

    <div
      v-if="showDrag && cardDrag"
      class="absolute right-0 top-0"
    >
      <button
        v-handle
        type="button"
        class="text-gray-400 w-32 h-32 hover:text-gray-800"
        title="Drag to move"
        @click.stop
      >
        <span class="icon_v2-so_drag" />
      </button>
    </div>

    <!-- delete and edit buttons OR slot-->
    <div
      v-if="hasActions"
      class="block-actions transition-none opacity-0 invisible"
      :class="[
        sizeMap[size].wrapper,
        showHover && 'group-hover:visible group-hover:opacity-100'
      ]"
    >
      <!-- drag button -->
      <button
        v-if="showDrag && !cardDrag"
        v-handle
        v-b-tooltip.hover.top.v-info.dh0.ds200
        type="button"
        class="edit-button-wrapper"
        title="Drag to move"
        @click.stop
      >
        <span class="icon_v2-so_drag so-drag-icon" />
      </button>
      <button
        v-if="showEdit"
        v-b-tooltip.hover.top.v-info.dh0.ds200="`Edit`"
        type="button"
        class="edit-button-wrapper"
        @click="$emit('edit')"
      >
        <span class="text-24 icon_v2-so_pencil"></span>
      </button>
      <button
        v-if="showCustomize"
        v-b-tooltip.hover.top.v-info.dh0.ds200="`Change color & icon`"
        type="button"
        class="edit-button-wrapper"
        @click="$emit('customize')"
      >
        <span class="text-24 icon_v2-so_paint-bucket"></span>
      </button>
      <button
        v-if="showMove"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Move'"
        type="button"
        class="edit-button-wrapper"
        @click.stop="$emit('move')"
      >
        <span class="text-24 icon_v2-so_folder-move"></span>
      </button>
      <button
        v-if="showDownload"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'Download'"
        type="button"
        class="edit-button-wrapper"
        @click.stop="$emit('download')"
      >
        <span class="text-24 icon_v2-so_download"></span>
      </button>

      <button
        v-if="showDelete"
        v-b-tooltip.hover.top.v-info.dh0.ds200="`Delete`"
        type="button"
        class="edit-button-wrapper"
        @click.stop="$emit('remove')"
      >
        <span class="text-24 icon_v2-so_trash"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { HandleDirective } from 'vue-slicksort'

const sizeMap = {
  32: {
    wrapper: 'h-32 py-0 px-2 left-6 -top-16',
  },
  40: {
    wrapper: 'h-40 py-0 px-4 left-6 -top-24',
  },
}

export default defineComponent({
  name: 'ActionsToolbar',
  directives: { handle: HandleDirective },
  components: {},
  props: {
    showEdit: {
      type: Boolean,
      default: false,
    },
    showCustomize: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showDrag: {
      type: Boolean,
      default: false,
    },
    cardDrag: {
      type: Boolean,
      default: false,
    },
    showMove: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showHover: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  setup(props) {
    const hasActions = computed(() => [
      props.showDrag,
      props.showMove,
      props.showDelete,
      props.showEdit,
      props.showCustomize,
    ].some(Boolean))

    return {
      sizeMap,
      hasActions,
    }
  },
})
</script>

<style lang="postcss" scoped>
.edit-button-wrapper {
  /* @apply flex items-center justify-center;
  @apply w-40 h-40 text-gray-600 cursor-pointer;
  @apply hover:text-gray-900; */
  @apply w-32 h-32 flex items-center justify-center;
  @apply hover:bg-gray-500 hover:bg-opacity-10 outline-none focus:outline-none;
  @apply text-gray-200 hover:text-white;
  @apply transition-none flex-shrink-0;
}

.block-actions {
  @apply absolute z-40;
  @apply bg-gray-800 dark:bg-darkGray-800 rounded-md shadow;
  @apply left-6;
  @apply dark:outline dark:outline-darkGray-700;
  @apply flex items-center justify-start;
}

.so-drag-icon {
  @apply text-gray-100;
}

</style>
