export const PORTAL_AUTH = 'PORTAL_AUTH'
export const PORTAL_LOGIN = 'PORTAL_LOGIN'
export const PORTAL_SETTINGS = 'PORTAL_SETTINGS'
export const PORTAL_PROJECT = 'PORTAL_PROJECT'
export const PORTAL_PROJECTS = 'PORTAL_PROJECTS'
export const PORTAL_DASHBOARD = 'PORTAL_DASHBOARD'
export const PORTAL_UPDATES = 'PORTAL_UPDATES'
export const PORTAL_PACKAGED_SERVICES = 'PORTAL_PACKAGED_SERVICES'
export const PORTAL_PAGE = 'PORTAL_PAGE'
export const PORTAL_PACKAGED_SERVICES_LIST = 'PORTAL_PACKAGED_SERVICES_LIST'
export const PORTAL_PACKAGED_SERVICES_DOCUMENT = 'PORTAL_PACKAGED_SERVICES_DOCUMENT'
export const PORTAL_KNOWLEDGE_BASE = 'PORTAL_KNOWLEDGE_BASE'
export const PORTAL_LINKED_APP = 'PORTAL_LINKED_APP'

export const PORTAL_FS_FOLDER = 'PORTAL_FS_FOLDER'
export const PORTAL_FS_DOCUMENT = 'PORTAL_FS_DOCUMENT'
export const PORTAL_PINNED_FS_FOLDER = 'PORTAL_PINNED_FS_FOLDER'
export const PORTAL_PINNED_FS_DOCUMENT = 'PORTAL_PINNED_FS_DOCUMENT'
export const PORTAL_RECOVER_PASSWORD_REQUEST = 'PORTAL_RECOVER_PASSWORD_REQUEST'
export const PORTAL_RECOVER_PASSWORD_RESET = 'PORTAL_RECOVER_PASSWORD_RESET'
export const PORTAL_PASSWORD_SET = 'PORTAL_PASSWORD_SET'

// -- user settings
export const PORTAL_USER_SETTINGS = 'PORTAL_USER_SETTINGS'
export const PORTAL_USER_SETTINGS_PROFILE = 'PORTAL_USER_SETTINGS_PROFILE'
export const PORTAL_USER_SETTINGS_SECURITY = 'PORTAL_USER_SETTINGS_SECURITY'
export const PORTAL_USER_SETTINGS_NOTIFICATIONS = 'PORTAL_USER_SETTINGS_NOTIFICATIONS'
export const PORTAL_USER_SETTINGS_LANGUAGE = 'PORTAL_USER_SETTINGS_LANGUAGE'

// --tasks
export const PORTAL_TASKS = 'PORTAL_TASKS'
export const PORTAL_TASKS_LIST = 'PORTAL_TASKS_LIST'
export const PORTAL_TASKS_LIST_TASK = 'PORTAL_TASKS_LIST_TASK'
export const PORTAL_TASKS_KANBAN = 'PORTAL_TASKS_KANBAN'
export const PORTAL_TASKS_KANBAN_TASK = 'PORTAL_TASKS_KANBAN_TASK'
export const PORTAL_TASKS_DOCUMENTS = 'PORTAL_TASKS_DOCUMENTS'
export const PORTAL_TASKS_DOCUMENTS_TASK = 'PORTAL_TASKS_DOCUMENTS_TASK'
