<template>
  <div>
    <div>
      <AnswerBadge
        v-if="['XL', 'XXL'].includes(nodeWidth)"
        :is-answerable="isAnswerable"
        :has-no-answer="!contentBlock.isDirty"
        position="top"
      />
      <div
        v-if="!possibleAnswers.length"
        role="radiogroup"
        class="grid grid-cols-1 gap-16 p-0 m-0 checkbox-image empty md:grid-cols-2 opacity-30"
      >
        <label class="disabled" /><label class="disabled" />
      </div>
      <LightboxGallery
        v-if="image"
        :files="[image]"
        :index="index"
        @close="index = null"
      />
      <div class="grid grid-cols-1 gap-16 p-0 m-0 checkbox-image print:grid-cols-2 md:grid-cols-2">
        <template v-if="contentBlock.settings.acceptsMultipleAnswers">
          <b-form-checkbox
            v-for="answer in possibleAnswers"
            :key="answer.uuid"
            :value="answer.uuid"
            :checked="contentBlock.answer"
            :disabled="isLocked"
            class="relative group"
            button
            button-variant="transparent"
            @change="onSelectionChange"
          >
            <span :style="`background-image: url(${answer.url})`"></span>

            <div
              v-if="answer.url"
              :class="['image-zoom-container']"
              @click.prevent="openImageGallery(answer.url)"
            >
              <i
                v-b-tooltip.hover.right.v-info.dh0.ds200="'Zoom in Image'"
                :class="[
                  'icon-util-zoom-in text-24 transform-gpu text-white',
                  'hover:opacity-80 hover:scale-110'
                ]"
              />
            </div>
          </b-form-checkbox>
        </template>
        <template v-else>
          <b-form-radio
            v-for="answer in possibleAnswers"
            :key="answer.uuid"
            :name="`qa-image-choice-radio-${contentBlock._id}`"
            :value="answer.uuid"
            :checked="contentBlock.answer[0]"
            :disabled="isLocked"
            class="relative group"
            button
            button-variant="transparent"
            @change="onSelectionChange"
          >
            <span :style="`background-image: url(${answer.url})`"></span>
            <div
              v-if="answer.url"
              :class="['image-zoom-container']"
              @click.prevent="openImageGallery(answer.url)"
            >
              <i
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Zoom in Image'"
                :class="[
                  'icon-util-zoom-in text-24 transform-gpu text-white',
                  'hover:opacity-80 hover:scale-110',
                ]"
              />
            </div>
          </b-form-radio>
        </template>
      </div>
    </div>
    <Notes
      v-if="contentBlock.settings.hasNotes"
      :is-answerable="isAnswerable"
      :text-variant="textVariant"
      :value="contentBlock.notes"
      @input="data => $emit('update', data)"
    />
  </div>
</template>

<script>
import { inject, computed, ref } from '@vue/composition-api'
import { path } from 'ramda'
import Notes from '@/components/Notes.vue'
import AnswerBadge from '@/components/Document/AnswerBadge.vue'
import LightboxGallery from '@/components/LightboxGallery.vue'

export default {
  name: 'DocumentContentBlockQAImageChoice',
  components: {
    Notes,
    AnswerBadge,
    LightboxGallery,
  },
  props: {
    contentBlock: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isAnswerable: Boolean,
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const isLocked = inject('isLocked', false)
    const image = ref(null)
    const index = ref(null)
    const updateAnswer = newAnswer => context.emit('update', { answer: newAnswer, isDirty: true })
    const onSelectionChange = selection => {
      // If a single item selection is enforced radio buttons are used and they only provide a
      // single value for the selection, not an array.
      if (
        !path(['contentBlock', 'settings', 'acceptsMultipleAnswers'], props)
      ) {
        return updateAnswer([selection])
      }
      return updateAnswer(selection)
    }

    const possibleAnswers = computed(
      () => props.contentBlock.settings?.possibleAnswers?.map(
        item => ({
          ...item,
          html: `<span style="background-image:url(${item.url}) !important"></span>`,
        })
      )
    )

    const openImageGallery = url => {
      image.value = { url, contentType: 'image/*' }
      index.value = 0
    }
    // watch(
    //   () => props.contentBlock.settings.acceptsMultipleAnswers,
    // If 'Multiple Answers' switch is toggled
    //   () => updateAnswer([]) // reset selection
    // )

    return {
      isLocked,
      image,
      index,

      // Methods
      updateAnswer,
      onSelectionChange,
      possibleAnswers,
      openImageGallery,
    }
  },
}
</script>

<style lang="scss">
.checkbox-image[role='radiogroup'] > label:before,
.checkbox-image[role='radiogroup'] > label:after {
  display: none !important;
}
.checkbox-image {
  label {
    overflow: hidden;
    height: 0;
    margin: 0 !important;
    padding-top: 56.25%;
    width: 100%;
    display: block;
    flex: 0 0 auto !important;
    border-width: 8px;
    border-style: solid;
    border-radius: 8px !important;

    border-color: theme('colors.gray.DEFAULT');
    box-shadow: 0 0 0 1px theme('colors.gray.200');
    background-color: theme('colors.gray.200');

    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      @apply block w-24 h-24 mr-8 bg-white rounded ring-1 ring-green-200;
      @apply  absolute bottom-10 left-10 z-1;
    }

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.49996 11.6667L3.33329 8.33334H1.66663L7.49996 15.8333L18.3333 4.16667H16.6666L7.49996 11.6667Z' fill='white'/%3E%3C/svg%3E%0A");
      @apply absolute w-20 h-20 bottom-12 left-12;
    }

    &.active {
      border-color: theme('colors.green.500');
      background-color: theme('colors.green.500');
      box-shadow: 0 0 0 1px theme('colors.green.700');

      &::before {
        @apply ring-green-600 bg-green-600;
      }
      &::after {
        @apply z-1;
      }
    }
  }

  position: relative;
  input {
    top: 0;
  }

  span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px !important;
    background-color: theme('colors.green.50');
  }
}

.checkbox-image {
  label:not(.disabled) {
    border-color: theme('colors.green.50');
    box-shadow: 0 0 0 1px theme('colors.green.200');
    background-color: theme('colors.green.50');
    &.active,
    &.active:hover {
      box-shadow: 0 0 0 1px theme('colors.green.700');
      border-color: theme('colors.green.500');
      background-color: theme('colors.green.500');
    }

    &:hover {
      box-shadow: 0 0 0 1px theme('colors.green.500');
      border-color: theme('colors.green.200');
      background-color: theme('colors.green.500');
      span {
        background-color: theme('colors.green.500');
      }
    }
  }
}

.checkbox-image label.disabled {
  cursor: default;
  &:hover {
    box-shadow: 0 0 0 1px theme('colors.gray.200');
  }

  &.active {
    &:hover {
      box-shadow: 0 0 0 1px theme('colors.gray.200');
    }
  }
}
</style>

<style lang="postcss" scoped>
.image-zoom-container {
  @apply lg:opacity-0 lg:invisible group-hover:visible group-hover:opacity-100 absolute z-1;
  @apply flex items-center justify-center;
  @apply p-16 cursor-pointer;
  @apply top-8 right-8 w-40 h-40;
  @apply rounded-xl bg-black bg-opacity-75 shadow-xl;
  @apply transition-all;
  @apply print:hidden
}
</style>
