export const track = tracking => {
  const listenerMap = new WeakMap()

  return {
    bind(el, { value }) {
      listenerMap.set(el, () => {
        // console.log('v-track', ...value)
        tracking.track(...value)
      })

      el.addEventListener('click', listenerMap.get(el))
    },
    unbind(el) {
      el.removeEventListener('click', listenerMap.get(el))
    },
  }
}
