
<DocumentNodeRenderer
  :node="node"
  :config-override="{
    stylingToolbar: {
      features: {
        textColor: true,
      }
    },
  }"
>
  <template #default="{ styling, textVariant, isFocus, nodeWidth }">
    <DocumentContentBlockCost
      :document="document"
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      :is-focus="isFocus"
      :node-width="nodeWidth"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
