
<Form
  v-slot="{ state, v, submit, hasChanges }"
  :state="formState"
  :on-submit="onSubmit"
  :validation="validation"
  success-message="Your password has been changed"
>
  <div>
    <div v-if="user.hasPassword" class="form-group">
      <FormField
        :v="v.currentPassword"
        :label="$t('login.password-current-placeholder')"
        :messages="{
          required: $t('login.password-current-validation-required'),
          minLength: $t('login.password-validation-min-length'),
        }"
      >
        <FormInputPassword v-model="state.currentPassword" />
      </FormField>
    </div>
    <div class="form-group">
      <FormField
        :v="v.newPassword"
        :label="$t('login.password-new-placeholder')"
        :description="$t('login.password-validation-min-length')"
        :messages="{
          required: $t('login.password-validation-required'),
          minLength: $t('login.password-validation-min-length'),
        }"
      >
        <FormInputPassword
          v-model="state.newPassword"
          autocomplete="new-password"
        />
      </FormField>
    </div>
    <div class="form-group">
      <FormField
        :v="v.newPasswordConfirm"
        :messages="{
          required: $t('login.password-confirm-validation-required'),
          sameAs: $t('login.password-confirm-validation-match'),
        }"
        :label="$t('login.password-confirm-placeholder')"
      >
        <FormInputPassword
          v-model="state.newPasswordConfirm"
          autocomplete="new-password"
        />
      </FormField>
    </div>
  </div>
  <slot name="buttons" v-bind="{ submit, hasChanges }" />
</Form>
