<template>
  <b-alert
    v-if="announcement"
    class="flex items-center mb-0 print:hidden"
    :variant="announcement.variant || 'info'"
    show
  >
    <span class="w-20 mr-8 standard-lightbulb_outline text-20"></span>
    <div class="flex-1 text-14">{{ announcement.message }}</div>
    <Button
      v-if="announcement.type === ANNOUNCEMENT_TYPE.newAppVersion"
      :disabled="reloadRequested"
      @click="reload"
    >
      Update Now
    </Button>
  </b-alert>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useAnnouncement } from '@/v2/services/announcements/compositions'
import { ANNOUNCEMENT_TYPE } from '@/v2/services/announcements/announcementsTypes';
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Announcement',
  components: {
    Button,
  },
  setup() {
    const announcement = useAnnouncement()
    const reloadRequested = ref(false)

    const reload = () => {
      reloadRequested.value = true
      setTimeout(() => window.location.reload(), 2000)
    }

    return {
      reload,
      reloadRequested,
      // announcement: { message: 'test', type: 'newAppVersion' },
      announcement,
      ANNOUNCEMENT_TYPE,
    }
  },
})
</script>
