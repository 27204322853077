
<DocumentDrawer
  :title="drawerTitle"
  hide-close-button
  @close="$emit('close')"
>
  <div v-if="currentDownload" class="px-24 py-12">
    <!-- PENDING -->
    <div v-if="currentDownload.status === STATUS.PENDING">
      <h4 class="flex items-center gap-10">
        <Spinner :size="16" />
        <span>{{ $t('downloads.pending-subtitle') }}</span>
      </h4>
      <p class="mt-16 text-gray-500 text-14">
        {{ $t('downloads.pending-message') }}
      </p>

      <div
        class="
            flex
            items-center
            justify-start
            gap-24
            p-24
            mt-16
            mb-32
            bg-yellow-100 bg-opacity-50
            rounded-md
            max-w-page
          "
      >
        <span class="text-gray-800 icon_v2-so_time-oclock text-64 opacity-30"></span>
        <div
          class="
              w-[60ch]
              text-gray-800
              dark:text-darkGray-300
              opacity-80
              text-14
            "
        >
          <h3 class="mb-4">
            {{ $t('downloads.pending-warn') }}
          </h3>
        </div>
      </div>
    </div>

    <!-- SUCCESS -->
    <div
      v-else-if="currentDownload.status === STATUS.SUCCESS"
      class="flex flex-col items-center gap-6"
    >
      <div
        class="
            border border-gray-200
            text-gray-600 text-14 text-center
            max-w-256
            flex flex-col
            items-center
            justify-center
            py-16
            px-16
            rounded-lg
            mb-8
            mt-8
          "
      >
        <FileIcon
          class="mb-4"
          :content-type="currentDownload.fileContentType"
          size="64"
        />
        <p class="">{{ currentDownload.fileName }}</p>
        <p class="text-gray-400 text-12">
          {{ currentDownload.fileSize | formatBytes }}
        </p>
      </div>

      <Button
        class="px-32 hover:opacity-90"
        size="md"
        variant="primary"
        target="_blank"
        :href="currentDownload?.url"
      >
        <span class="mr-4 !text-16 icon_v2-so_download" />
        {{ $t('downloads.download') }}
      </Button>

      <p
        v-if="document.isFolder"
        class="flex mt-12 leading-tight text-gray-500 text-14 text-center"
      >
        💡 {{ $t('downloads.success-message') }}
      </p>
    </div>

    <!-- ERROR -->
    <div v-if="currentDownload.status === STATUS.ERROR">
      <h4 class="font-bold">
        {{ $t('downloads.error-subtitle') }}
      </h4>
      <p class="mt-16 text-red-400 text-12">
        {{ $t('downloads.error-message') }}
      </p>
    </div>
  </div>
  <div v-else-if="Boolean(error)" class="px-24 py-12">
    <p class="mt-16 text-red-400 text-12">
      {{ $t('downloads.error-message') }}
    </p>
  </div>
</DocumentDrawer>
