<template>
  <DocumentContentBlockQASettings
    :description="description"
    :has-title="hasTitle"
    :prevent-popover-close="true"
    :popover-target-id="popoverTargetId"
    @input="handleSettingsToggle"
  >
    <SettingsSwitch
      v-model="hasNotes"
      label="Notes"
      tooltip="Enable client notes"
    />
    <b-form-group
      label="Possible Answers"
      label-for="qa-mc-answers"
    >
      <b-form-textarea
        id="qa-mc-answers"
        v-model="possibleAnswersToText"
        placeholder="Enter something..."
        rows="2"
        value="Option 1 \n Option 2"
        max-rows="6"
        class="relative z-1"
        @input="handleChange"
      />
      <div
        class="relative z-0 px-8 pb-4 -mt-4 text-gray-600 bg-gray-100 pt-7 rounded-b-md text-13"
      >
        One choice per row
      </div>
    </b-form-group>
    <SettingsSwitch
      :value="hasMultipleAnswers"
      label="Allow multiple answers"
      tooltip="Allow multiple answers"
      @input="setHasMultipleAnswers"
    />
    <b-form-group
      v-slot="{ ariaDescribedby }"
      label="Display"
      label-for="disabled-fieldset"
      class="flex items-center justify-between btn-group-sp-wrap"
    >
      <b-form-radio-group
        id="btn-radios-1"
        v-model="displayVertical"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="radios-btn-default"
        button-variant="radio"
        class="btn-group-sp"
        buttons
        plain
      />
    </b-form-group>
  </DocumentContentBlockQASettings>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import isEmpty from 'ramda/src/isEmpty'
import { useContentBlockSettings } from '@/v2/services/contentBlocks/contentBlocksCompositions'
import SettingsSwitch from '@/components/SettingsSwitch.vue'
import DocumentContentBlockQASettings from './DocumentContentBlockQASettings.vue'

export default {
  name: 'DocumentContentBlockQAMultipleChoiceSettings',
  components: {
    DocumentContentBlockQASettings,
    SettingsSwitch,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    // bindings props are refs
    const bindings = useContentBlockSettings(props, [
      'description',
      'hasTitle',
      'label',
      'hasMultipleAnswers',
      'displayVertical',
      'possibleAnswers',
      'hasNotes',
    ])

    const options = computed(() => [
      { text: 'Horizontal', value: false },
      { text: 'Vertical', value: true },
    ])

    const possibleAnswersToText = ref(bindings.possibleAnswers.value.join('\n'))

    const handleChange = (val = '') => {
      const strArr = val.split('\n')
      const finalArr = strArr.filter(e => !isEmpty(e))
      if (finalArr.length > 0) {
        bindings.possibleAnswers.value = finalArr
      } else bindings.possibleAnswers.value = ['']
    }

    const setHasMultipleAnswers = value => {
      bindings.hasMultipleAnswers.value = value
    }
    const handleSettingsToggle = object => {
      bindings[object.type].value = object.value
    }
    return {
      ...bindings,
      options,
      handleChange,
      possibleAnswersToText,
      setHasMultipleAnswers,
      handleSettingsToggle,
    }
  },
}
</script>
<style lang="scss" scoped></style>
