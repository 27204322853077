
<b-form-group :content-cols="contentCols">
  <template #label>
    <span class="inline-flex items-center justify-start">
      <span
        v-if="icon"
        :class="[
          `icon-${icon}`,
          'text-16 w-16 text-gray-700 mr-2',
        ]"
      />
      {{ label }}
    </span>
  </template>

  <b-form-input
    :id="componentID"
    :type="type"
    :placeholder="label"
    :value="value"
    trim
    size="sm"
    :class="inputClass"
    @input="handleInput"
  />
</b-form-group>
