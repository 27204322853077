/** Returns the height and width of the current viewport */
function getViewport() {
  return {
    height: window.innerHeight || document.documentElement.clientHeight,
    width: window.innerWidth || document.documentElement.clientWidth,
  }
}
/**
 * Given an HTML element, checks if its right and bottom side are within the viewport
 * @param {HTMLElement} element A reference to an element in the DOM
 */
// eslint-disable-next-line import/prefer-default-export
export function checkVisibility(element) {
  const { bottom, right, height, width } = element.getBoundingClientRect();
  const { height: vHeight, width: vWidth } = getViewport()

  const isBottomVisible = bottom <= vHeight
  const isRightVisible = right <= vWidth

  return { isBottomVisible, isRightVisible, height, width };
}

/**
 * Mounts the provided `htmlString` of elements inside the `containerElement` in the DOM
 * @param {String} htmlString A string containing HTML code. Can contain 1 or multiple HTML
 * elements
 * @param {HTMLElement} containerElement An existing HTML element in the DOM in which the provided
 * `htmlString` will be added
 */
export function injectElementsInContainer(htmlString, containerElement) {
  // Inject custom elements in a range
  const range = document.createRange()
  const fragment = range.createContextualFragment(htmlString)
  // Load scripts by appending to document, inside dedicated container
  containerElement.append(...fragment.children)

  range.detach() // Remove range and clear resources
}

export function changeCursorStyle(cursorStyle = '') {
  document.body.parentNode.style.cursor = cursorStyle
}
