<template>
  <PieProgressBar
    v-if="ratio"
    small
    :ratio="ratio"
    :variant="variant"
  />
  <div
    v-else
    class="sbl-circ"
    :class="variant"
    :style="style"
  ></div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import PieProgressBar from '@/components/Blocks/ProgressBar/PieProgressBar.vue'

export default defineComponent({
  name: 'Spinner',
  components: {
    PieProgressBar,
  },
  props: {
    size: {
      type: Number,
      default: 16,
    },
    variant: {
      type: String,
      validator: val => ['dark', 'light'].includes(val),
      default: 'dark',
    },
    ratio: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const style = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
    }))

    return {
      style,
    }
  },
})
</script>

<style lang="scss" scoped>
.sbl-circ {
  // height: 48px;
  // width: 48px;
  @apply text-gray-500 dark:text-darkGray-600;
  position: relative;
  display: inline-block;
  border: 2px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

.dark {
  @apply text-darkGray-600;
}

.light {
  @apply text-white;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
