import deepClone from 'rfdc/default'
import deepEqual from 'deep-equal'
import { computed, isRef, ref, unref, watch } from '@vue/composition-api'
import { isNotNil, noop } from 'ramda-adjunct'
import useVuelidate from '@vuelidate/core'
import useLocalization from './useLocalization'

export default function useForm({
  state,
  validation = null,
  onSubmit = noop,
  onTouch = noop,
  submitIfInvalid = false,
  vuelidateOptions = {},
} = {}) {
  let initialState = deepClone(state)
  const formError = ref(null)
  const isBusy = ref(false)
  const formHasChanges = ref(false)
  const { translate } = useLocalization()

  const $v = isNotNil(unref(validation))
    ? useVuelidate(validation, state, vuelidateOptions)
    : null

  const formSetPristine = () => {
    $v?.value?.$reset()
    formHasChanges.value = false
  }

  const formReset = () => {
    if (isRef(state)) {
      throw new Error('formReset for Ref<state> is not implemented')
    }
    formSetPristine()
    Object.assign(state, initialState)
  }
  const formSetError = error => {
    // TODO: Find a better, more generic way of changing error messages from BE
    if (error?.message === 'Invalid login') {
      // Overwrite default message to make more user-friendly
      // eslint-disable-next-line no-param-reassign
      error.message = translate('login.invalid-credentials').value
    }
    formError.value = error
  }
  const formClearError = () => formSetError(null)
  const formValidationPending = computed(() => Boolean($v?.value?.$pending))
  const formIsInvalid = computed(() => Boolean($v?.value?.$invalid))
  const formIsDirty = computed(() => Boolean($v?.value?.$anyDirty))

  const formSubmit = async () => {
    if (isBusy.value || formValidationPending.value) {
      return
    }

    isBusy.value = true
    formClearError()

    if ($v) {
      formSetPristine()
      // $v.value.$touch()
      await $v.value.$validate()
      onTouch($v)

      if ($v.value.$invalid) {
        isBusy.value = false
        if (!submitIfInvalid) {
          return
        }
      }
    }

    try {
      await onSubmit({ state, formReset, formSetPristine })
      initialState = deepClone(state)
    } catch (err) {
      formSetError(err)
    } finally {
      isBusy.value = false
      formSetPristine()
    }
  }

  // detect changes in state
  watch(() => state, newState => {
    formHasChanges.value = !deepEqual(initialState, newState)
  }, { deep: true })

  return {
    formIsBusy: isBusy,
    formValidationPending,
    formError,
    formSubmit,
    formReset,
    formSetError,
    formClearError,
    formIsInvalid,
    formIsDirty,
    formHasChanges,
    $v,
  }
}
