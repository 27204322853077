<template>
  <DocumentContentLoader v-slot="{ isLoading }" :document="document">
    <ScrollContainer
      v-if="!isLoading"
      class="lg:overflow-y-auto lg:flex lg:overscroll-contain lg:h-[calc(100vh-143px)] lg:rounded-br-lg lg:rounded-bl-lg"
    >
      <div class="flex-1">
        <div class="p-0 m-0">
          <div
            class="mx-auto bg-white"
          >
            <DocumentViewerContent :document="document" />
          </div>
        </div>
      </div>
    </ScrollContainer>
  </DocumentContentLoader>
</template>

<script>
import { computed, provide, toRef } from '@vue/composition-api'
import ScrollContainer from '@/components/ScrollContainer.vue'
import DocumentContentLoader from '@/components/DocumentContentLoader.vue'
import DocumentViewerContent from '@/components/Document/Viewer/DocumentViewerContent.vue'

export default {
  name: 'DocumentViewerTemplate',
  components: {
    DocumentContentLoader,
    DocumentViewerContent,
    ScrollContainer,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    inModal: {
      type: Boolean,
      required: false,
    },
    print: Boolean,
  },
  setup(props) {
    provide(
      'isEditor',
      computed(() => false)
    )
    provide(
      'isViewer',
      computed(() => true)
    )
    provide(
      'isViewerPDF',
      computed(() => false)
    )
    provide(
      'isLocked',
      computed(() => false)
    )
    provide(
      'isSandbox',
      computed(() => true)
    )
    provide('document', toRef(props, 'document'))
  },
}
</script>

<style lang="postcss" module>
@media print {
  .toc {
    display: none;
  }
}

@media print {
  .editor-stats-wrapper {
    @apply border border-gray-200 rounded-md mb-24;
  }
}

@media screen {
  .editor-stats-wrapper {
    @apply border-b border-gray-200;
  }
}
</style>
