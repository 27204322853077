<template>
  <div>
    <InfoBox v-if="!error" icon="icon_v2-so_eye">
      Experience the complete functionality of our client portal firsthand
      by navigating through it as if you were the client, allowing you to fully
      understand and evaluate the user journey and features available to your customers.
    </InfoBox>
    <!-- ERROR -->
    <InfoBox
      v-else
      icon="icon_v2-so_info"
      class="text-red-500"
    >
      {{ error }}
    </InfoBox>
    <div v-if="magicLink">
      <Button
        variant="primary"
        size="xl"
        :href="magicLink"
        target="_blank"
        @click="$emit('opened')"
      >
        <span class="mr-4 -ml-4 icon_v2-so_maximize"></span>
        <span>Click here to open the link manually</span>
      </Button>
    </div>
    <Button
      v-else
      v-track="['Preview as client link generated']"
      :is-loading="isLoading"
      variant="primary"
      size="xl"
      @click="generateLoginLink"
    >
      <span class="mr-4 -ml-4 icon_v2-so_eye"></span>
      <span>Open Client Portal as Client</span>
    </Button>
  </div>
</template>
<script>
import { defineComponent, ref, toRef } from '@vue/composition-api'
import { useClientPortalPreviewUrl } from '@/v2/services/tokens/tokensCompositions'
import { isNotString } from 'ramda-adjunct'
import Button from '@/components/Button.vue'
import InfoBox from '@/components/InfoBox.vue'

export default defineComponent({
  name: 'ProjectViewAsClient',
  components: {
    Button,
    InfoBox,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const {
      createPortalPreviewUrl,
      isLoading,
    } = useClientPortalPreviewUrl(toRef(props, 'project'))

    // const isCopied = ref(false)
    const error = ref(null)
    const magicLink = ref(null)

    const generateLoginLink = async () => {
      error.value = null
      magicLink.value = null
      try {
        const _magicLink = await createPortalPreviewUrl()

        if (isNotString(_magicLink)) {
          return
        }

        const opened = window.open(_magicLink, '_blank') ?? false
        if (!opened) {
          magicLink.value = _magicLink
          error.value = 'The preview link has been generated; '
            + 'however, a popup blocker has prevented the link from being automatically opened. '
            + 'Please click the button below again to manually open the portal preview.'
        } else {
          context.emit('opened')
        }
      } catch (err) {
        error.value = err?.message ?? 'Error'
      }
    }

    return {
      generateLoginLink,
      magicLink,
      isLoading,
      error,
    }
  },
})
</script>
