
<ProfileGetByUser
  v-if="userId"
  v-slot="{ profile }"
  :user-id="userId"
>
  <Avatar
    v-if="profile"
    :size="size"
    :profile="profile"
    :show-name="showName"
    :show-avatar="showAvatar"
    :font-size="fontSize"
    :timestamp="timestamp"
    v-bind="$attrs"
  />
</ProfileGetByUser>
<Avatar
  v-else-if="fallbackName"
  :size="size"
  :name="fallbackName"
  :show-name="showName"
  :show-avatar="showAvatar"
  :font-size="fontSize"
  :timestamp="timestamp"
  v-bind="$attrs"
/>
<Avatar
  v-else-if="!fallbackName"
  :size="size"
  :name="'N/A'"
  :show-name="showName"
  :show-avatar="showAvatar"
  :font-size="fontSize"
  :timestamp="timestamp"
  v-bind="$attrs"
/>
