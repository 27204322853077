
<div>
  <InfoBox v-if="!error" icon="icon_v2-so_eye">
    Experience the complete functionality of our client portal firsthand
    by navigating through it as if you were the client, allowing you to fully
    understand and evaluate the user journey and features available to your customers.
  </InfoBox>
  <!-- ERROR -->
  <InfoBox
    v-else
    icon="icon_v2-so_info"
    class="text-red-500"
  >
    {{ error }}
  </InfoBox>
  <div v-if="magicLink">
    <Button
      variant="primary"
      size="xl"
      :href="magicLink"
      target="_blank"
      @click="$emit('opened')"
    >
      <span class="mr-4 -ml-4 icon_v2-so_maximize"></span>
      <span>Click here to open the link manually</span>
    </Button>
  </div>
  <Button
    v-else
    v-track="['Preview as client link generated']"
    :is-loading="isLoading"
    variant="primary"
    size="xl"
    @click="generateLoginLink"
  >
    <span class="mr-4 -ml-4 icon_v2-so_eye"></span>
    <span>Open Client Portal as Client</span>
  </Button>
</div>
