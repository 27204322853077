
<div
  :id="name"
  ref="scrollContainer"
  v-scroll-direction:[scrollDirectionOptions]="setScrollDirection"
>
  <slot
    v-bind="{
      scrollTo,
      scrollToElementId,
      scrollDirection,
      isAutoScrolling,
      isScrolling
    }"
  />
</div>
