<template>
  <div v-fragment>
    <!-- overlay -->
    <div
      class="blocker-overlay cursor-default"
      @mouseover.prevent.stop="() => null"
      @click.stop="() => null"
    />

    <!-- drawer -->
    <div class="drawer-overlay">
      <div class="drawer-card">
        <div
          class="
            flex items-center bg-white dark:bg-darkGray-900
            p-24 justify-between border-b border-gray-200 dark:border-darkGray-700
          "
        >
          <h4 class="text-22 font-normal">
            {{ title }}
          </h4>
          <b-button
            size="sm"
            variant="slim"
            @click="$emit('close')"
          >
            <span class="icon_v2-so_close w-24 text-24" />
          </b-button>
        </div>
        <slot />
        <div
          v-if="!hideCloseButton"
          class="
            bg-white dark:bg-darkGray-900 flex items-center p-16
            border-t border-gray-200 dark:border-darkGray-700
          "
        >
          <b-button
            size="md"
            variant="primary"
            class="w-full"
            @click="$emit('close')"
          >
            {{ closeLabel }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DocumentDrawer',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    closeLabel: {
      type: String,
      default: 'Close',
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
})
</script>

<style lang="scss" scoped>
.blocker-overlay {
  @apply fixed inset-0 bg-darkGray-900/30 filter backdrop-blur-xs;
  z-index: 2147483044; // Because of Intercom
}
.drawer-overlay {
  @apply fixed bottom-0 right-0;
  @apply w-screen md:w-420 h-vh-90 md:h-screen overflow-hidden;
  @apply shadow-2xl bg-red-400;
  z-index: 2147483054;// Because of Intercom

}

.drawer-card {
  @apply absolute inset-0;
  @apply bg-white dark:bg-darkGray-800;
  @apply text-gray-600 dark:text-darkGray-400;
  @apply flex flex-col;
}

</style>
