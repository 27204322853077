<template>
  <span>{{ wrappedText }}</span>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { join, pipe, take, takeLast } from 'ramda'

const makeSplitter = (preSize, afterSize) => (list = []) => {
  const cond = list.length > (preSize + afterSize + 1)
  return {
    pre: cond ? take(preSize, list) : list,
    after: cond ? takeLast(afterSize, list) : '',
  }
}

const makeTextEllipsis = (preSize, afterSize, separator = '...') => pipe(
  makeSplitter(preSize, afterSize),
  ({ pre, after }) => join(after.length ? separator : '', [pre?.trim(), after?.trim()])
)


export default defineComponent({
  name: 'WrappedTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
    preSize: {
      type: Number,
      default: 28,
    },
    afterSize: {
      type: Number,
      default: 8,
    },
    separator: {
      type: String,
      default: '...',
    },
  },
  setup(props) {
    const wrappedText = computed(() => {
      const textEllipsis = makeTextEllipsis(props.preSize, props.afterSize, props.separator )
      return textEllipsis(props.text)
    })

    return {
      wrappedText,
    }
  },
})
</script>
