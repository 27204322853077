import feathersClient from '@/v2/services/feathersClientService'

export const servicePath = 'url-info'
/** @type {import('@feathersjs/feathers').Service<Object>} */
const service = feathersClient.service(servicePath)

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

// Set a generous timeout as a `GET` request on this service can easily take 10 seconds
service.timeout = 20000

export default service
