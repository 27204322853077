<template>
  <FeathersVuexGet
    id="default"
    v-slot="{ isGetPending: isPending, ...rest }"
    service="configurations"
  >
    <slot v-bind="{ isPending, ...rest }" />
  </FeathersVuexGet>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Configuration',
})
</script>
