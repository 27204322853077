
<DocumentNodeEditorToolbarSettings
  :popover-target-id="popoverTargetId"
  :prevent-popover-close="preventPopoverClose"
>
  <b-form>
    <SettingsInput
      v-model="label"
      label="Button text"
    />
    <SettingsInput
      v-model="url"
      label="Link"
    />
    <SettingsSwitch
      v-model="newTab"
      label="Open link new tab"
      tooltip=""
    />

    <div class="flex items-center justify-between py-4 mb-4">
      <div class="text-gray-600 text-14">
        Button size
      </div>
      <div class="inline-flex py-6 space-x-16 text-gray-500">
        <b-button-group>
          <b-button
            :variant="size === 'S' ? 'success' : 'neutral'"
            class="px-8"
            @click="size = 'S'"
          >
            <span class="icon-toolbar-s w-20 text-20" />
          </b-button>
          <b-button
            :variant="size === 'M' ? 'success' : 'neutral'"
            class="px-8"
            @click="size = 'M'"
          >
            <span class="icon-toolbar-m w-20 text-20" />
          </b-button>
          <b-button
            :variant="size === 'L' ? 'success' : 'neutral'"
            class="px-8"
            @click="size = 'L'"
          >
            <span class="icon-toolbar-l w-20 text-20" />
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="flex items-center justify-between py-4 mb-4">
      <div class="text-gray-600 text-14">
        Background color
      </div>
      <ColorPaletteItem
        v-model="backgroundColor"
        variant="pick"
        :presets="colorPresets"
        popover-placement="buttom"
        class="included list-item list-none"
        :size="32"
      />
    </div>
    <div class="flex items-center justify-between py-4 mb-4">
      <div class="text-gray-600 text-14">
        Text color
      </div>
      <ColorPaletteItem
        v-model="textColor"
        variant="pick"
        :presets="colorPresets"
        popover-placement="buttom"
        class="included list-item list-none"
        :size="32"
      />
    </div>
    <div class="flex items-center justify-between pt-8 pb-12">
      <div class="text-gray-600 text-14 mr-16 flex-shrink-0 leading-none">
        Border radius
      </div>
      <RangeSlider
        v-model="border"
        :min="0"
        :max="50"
      />
    </div>
  </b-form>
</DocumentNodeEditorToolbarSettings>
