<template>
  <div v-fragment>
    <slot
      v-bind="{ open }"
      name="button"
    />
    <b-button
      v-if="!hasButtonSlot"
      :id="targetId"
      :size="size"
      class="px-12 bg-white rounded-md dark:bg-darkGray-1000"
      @click.prevent="open"
    >
      <div class="flex flex-row items-center">
        <span
          class="ml-0 text-gray-600 dark:text-darkGray-300"
          :class="[
            currentIcon,
            { 'text-18 w-18': size === 'sm' },
            { 'text-24 w-24': size === 'md' },
          ]"
        />
        <span class="ml-8 icon_v2-so_direction-down text-16"></span>
      </div>
    </b-button>
    <ModalBasic
      v-if="popoverShow"
      v-model="popoverShow"
      :hide-footer="true"
      size="lg"
      :should-close-on-backdrop="false"
      dialog-class="p-0"
      content-class="p-0"
      body-class="modal-body-icon-picker"
      header-class="bg-gray-100 mb-0"
      footer-class=""
    >
      <template #body="{ close }">
        <!-- Header tabs -->
        <!-- Search -->
        <!-- Icons -->
        <div
          v-click-outside.capture="() => close()"
          class="relative"
          @click.stop
        >
          <b-tabs
            v-model="currentTab"
            content-class="fade-from-bottom "
            active-nav-item-class="text-green-500 bg-white"
            nav-class="bg-gray-100 pt-16 rounded-t-md"
            nav-wrapper-class="bg-red-500 rounded-t-md"
            lazy
            :activate-tab="switchTab"
          >
            <b-tab
              v-if="icons.brandIcons.length"
              title="Apps"
              class="tab-container"
            >
              <div class="px-16 my-8">
                <b-input
                  v-model="search"
                  type="search"
                  placeholder="Search icons"
                  size="lg"
                  autofocus
                />
              </div>
              <ul class="icon-picker-list">
                <li
                  v-for="icon in paginatedResults"
                  :key="icon.name"
                  v-b-tooltip.hover.bottom.v-info.dh0.ds200="icon.name"
                  class="icon-picker-button"
                  @click.stop="selectIcon(icon)"
                >
                  <span :class="[`${icon.icon}`, 'icon-preview']" />
                  <p class="preview-icon-name">{{ icon.name }}</p>
                </li>
              </ul>
            </b-tab>
            <b-tab
              v-if="icons.materialIcons.length"
              title="Material Icons"
              class="tab-container"
            >
              <div class="px-16 my-8">
                <b-input
                  v-model="search"
                  type="search"
                  placeholder="Search icons"
                  size="lg"
                  autofocus
                />
              </div>
              <ul class="icon-picker-list">
                <li
                  v-for="icon in paginatedResults"
                  :key="icon.name"
                  v-b-tooltip.hover.bottom.v-info.dh0.ds200="icon.name"
                  class="icon-picker-button"
                  @click.stop="selectIcon(icon)"
                >
                  <span :class="[`${icon.icon}`, 'icon-preview']" />
                  <p class="preview-icon-name">{{ icon.name }}</p>
                </li>
              </ul>
            </b-tab>
            <b-tab
              v-if="icons.standardIcons.length"
              title="Basic"
              class="tab-container"
            >
              <div class="px-16 my-8">
                <b-input
                  v-model="search"
                  type="search"
                  placeholder="Search icons"
                  size="lg"
                  autofocus
                />
              </div>
              <ul class="icon-picker-list">
                <li
                  v-for="icon in paginatedResults"
                  :key="icon.name"
                  class="icon-picker-button"
                  @click.stop="selectIcon(icon)"
                >
                  <span :class="[`${icon.icon}`, 'icon-preview']" />
                  <p class="preview-icon-name">{{ icon.name }}</p>
                </li>
              </ul>
            </b-tab>
          </b-tabs>
          <div class="p-16">
            <b-button
              v-if="canLoadMore"
              variant="neutral"
              class="w-full relative z-1060"
              @click="loadMoreIcons"
            >
              Load more icons
            </b-button>
          </div>
        </div>
      </template>
    </ModalBasic>

    <!-- </b-popover> -->
  </div>
</template>
<script>
import { defineComponent, ref, onUnmounted, getCurrentInstance, computed } from '@vue/composition-api'
import { isEmpty } from 'ramda'
import icons from '@/assets/icons'
import ModalBasic from '@/components/Modal/ModalBasic.vue'

const PAGE_SIZE = 100

export default defineComponent({
  name: 'DropdownIcons',
  components: {
    ModalBasic,
  },
  props: {
    currentIcon: {
      type: String,
      default: 'standard-heart',
    },
    targetId: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy
    const search = ref('')
    const isSearchEmpty = computed(() => isEmpty(search.value))
    const unreadPage = ref(0)

    const popoverRef = ref(null)
    const popoverShow = ref(false)
    // eslint-disable-next-line vue/custom-event-name-casing
    const onPopoverOpen = () => {
      context.emit('popover-opening')
      vm.$root.$emit('bv::hide::popover')
    }
    const close = () => {
      context.emit('popover-closing')
      popoverRef.value.$emit('close')
    }

    const onPopoverShown = bvEvent => {
      if (bvEvent.vueTarget.target === props.targetId) {
        popoverShow.value = true
      }
    }

    const onPopoverHidden = bvEvent => {
      if (bvEvent.vueTarget.target === props.targetId) {
        popoverShow.value = false
      }
    }

    const selectIcon = icon => {
      search.value = ''
      context.emit('pick-icon', icon.icon)
      popoverShow.value = false
    }

    vm.$root.$on('bv::popover::shown', onPopoverShown)
    vm.$root.$on('bv::popover::hidden', onPopoverHidden)

    onUnmounted(() => {
      vm.$root.$off('bv::popover::shown', onPopoverShown)
      vm.$root.$off('bv::popover::hidden', onPopoverHidden)
    })

    const open = () => {
      if (props.disabled) {
        return
      }
      popoverShow.value = !popoverShow.value
      context.emit('icon-dropdown-select')
    }


    const hasButtonSlot = computed(() => Boolean(context.slots.button))
    const currentTab = ref(0)

    const activeIconPack = computed(() => {
      switch (currentTab.value) {
        case 0:
          return icons.brandIcons
        case 1:
          return icons.materialIcons
        case 2:
          return icons.standardIcons
        default:
          return icons.brandIcons
      }
    })

    const switchTab = tab => {
      currentTab.value = tab
    }
    const results = computed(
      () => activeIconPack.value.filter(icon => {
        const transformedIconName = icon.name.toLowerCase()
        return transformedIconName.includes(search.value.toLowerCase())
      })
    )

    const paginatedResults = computed(
      () => results.value.slice(0, (unreadPage.value + 1) * PAGE_SIZE)
    )

    const canLoadMore = computed(
      () => paginatedResults.value.length < results.value.length
    );

    const loadMoreIcons = () => {
      unreadPage.value += 1;
    }

    return {
      icons,
      onPopoverOpen,
      popoverRef,
      close,
      popoverShow,
      selectIcon,
      open,
      hasButtonSlot,
      // Icon Search
      search,
      isSearchEmpty,
      results,
      canLoadMore,
      paginatedResults,
      loadMoreIcons,
      switchTab,
      currentTab,
    }
  },
})
</script>

<style lang="postcss" scoped>
.tab-container {
  @apply pb-24 z-1060 overflow-y-auto h-395;
}
.icon-picker-list {
  @apply flex flex-row flex-wrap justify-between items-start p-8;
  @apply mb-16;
}
.icon-picker-button {
  @apply m-0 p-0;
  @apply cursor-pointer;
  @apply flex flex-col items-center justify-center leading-none;
  @apply p-6 rounded flex-wrap;
  @apply hover:bg-gray-100 hover:ring-1 hover:ring-gray-200;
  @apply w-56;
}

.icon-preview {
  @apply text-32 w-32;
}
.preview-icon-name {
  @apply text-11 w-full truncate text-center font-sans opacity-60;
}
</style>

<style module lang="scss">
.popover {
  &[x-placement^='top'] {
    :global(.arrow) {
      &::after {
        border-top-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  &[x-placement^='bottom'] {
    :global(.arrow) {
      &::after {
        border-bottom-color: theme('colors.gray.DEFAULT');
      }
    }
  }

  :global(.popover-body) {
    color: theme('colors.gray.700');
    padding: 0;
  }
}
</style>
