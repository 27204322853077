import { getCurrentInstance } from '@vue/composition-api'

export default function useTracking() {
  const vm = getCurrentInstance().proxy
  const track = (event, data, options) => vm.$tracking.track(event, data, options)
  const trackGuest = (event, data, options) => vm.$tracking.trackGuest(event, data, options)

  return {
    track,
    trackGuest,
  }
}
