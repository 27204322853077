
<div
  class="
    top-0 left-0 bottom-0 right-0 flex flex-col
    items-center justify-center z-1060
  "
  :class="{
    'opacity-90 bg-white': variant === 'transparent',
    'bg-white/90 backdrop-blur-sm': variant === 'blur',
    'fixed': !absolute,
    'absolute': absolute,
  }"
>
  <Spinner :size="64" />
  <div
    v-if="label"
    class="mt-16 text-12 text-gray-600
  dark:text-darkGray-600 font-medium"
  >
    {{ label }}
  </div>
</div>
