/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

import { isNonEmptyArray } from 'ramda-adjunct';
import { isNil } from 'ramda';
import Vue from 'vue';
import { ref, computed, watch, getCurrentInstance, unref } from '@vue/composition-api';
import { pickDotPath } from '../helpers/fp';

/**
 * @typedef {Object} Options
 * @property {string} model
 * @property {Ref<string>} id
 * @property {Ref<Record<string, any>>} [defaults]
 * @property {Ref<Array<string>>} [fields]
 * @property {boolean} [throwError]
 * @property {boolean} [resetAfterCreate]
 */

/**
 * @param {Options} options
 */
export default function useFormEntityEditor(options = {}) {
  const vm = getCurrentInstance().proxy

  const {
    model,
    id,
    defaults = {},
    fields = [],
    throwError = true,
    resetAfterCreate = true,
  } = options

  const Model = Vue.$FeathersVuex.api[model]

  const error = ref(null)
  const isSaving = ref(false)
  const clone = ref(null)
  const item = ref(null)

  const isNew = computed(() => isNil(id.value) || id.value === 'new')

  const save = async (fieldsToSave = null) => {
    if (isSaving.value) {
      return
    }

    isSaving.value = true
    error.value = false

    const _fields = fieldsToSave ?? unref(fields) ?? null

    try {
      const result = isNonEmptyArray(_fields)
        ? await clone.value.save({ data: pickDotPath(_fields, clone.value) })
        : await clone.value.save()

      vm.$emit('save', result)

      if (isNew.value) {
        vm.$emit('created', result)
      } else {
        vm.$emit('updated', result)
      }

      if (isNew.value && resetAfterCreate) {
        item.value = new Model(unref(defaults))
      }
    } catch (err) {
      error.value = err
      if (throwError) {
        throw err
      }
    } finally {
      isSaving.value = false
    }
  }

  const reset = () => {
    item.value.reset()
    Object.assign(clone.value, unref(defaults))
  }

  const update = data => {
    Object.assign(clone.value, data)
  }

  watch(id, newId => {
    if (isNew.value) {
      item.value = new Model(unref(defaults))
    } else {
      const storeItem = Model.getFromStore(newId)
      if (!storeItem) {
        Model.get(newId).then(fetchedItem => {
          item.value = fetchedItem;
        })
      } else {
        item.value = storeItem
      }
    }
  }, { immediate: true })

  watch(item, updatedItem => {
    if (isNil(updatedItem)) {
      return
    }

    clone.value = updatedItem.clone()
  }, { immediate: true })

  return {
    // data
    state: clone,
    entity: item,
    isNew,
    isSaving,
    error,

    // methods
    save,
    update,
    reset,
  }
}
