<template>
  <FeathersVuexGet
    :id="id"
    v-slot="{ item: project, isGetPending }"
    watch="id"
    :query-when="force || notInStore"
    service="projects"
    v-bind="$attrs"
  >
    <slot v-bind="{ project, isGetPending }" />
  </FeathersVuexGet>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'ProjectGet',
  props: {
    id: {
      type: String,
      default: null,
    },
    force: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { Project } = context.root.$FeathersVuex.api
    const notInStore = computed(() => !Project.getFromStore(props.id))

    return {
      notInStore,
    }
  },
}
</script>
