<template>
  <div v-fragment>
    <!-- Drag Handle -->
    <div :class="['table-cell name relative ', { error: $v.name.$error }]">
      <div class="flex items-center">
        <!-- Drag Handle -->
        <button
          v-handle
          v-b-tooltip.hover.right.v-info.dh0.ds200
          :class="[
            'w-40 h-24 flex items-center justify-center ',
            ' -ml-40 text-gray-400 hover:text-gray-600',
            'print:hidden'
          ]"
          title="Drag to move"
          @click.stop
        >
          <span
            :class="[
              'icon_v2-so_drag'
            ]"
          ></span>
        </button>
        <!-- Input content -->
        <b-form-input
          v-if="!item.fromLibrary"
          :value="item.name || ''"
          type="text"
          size="sm"
          trim
          placeholder="Name your item"
          :class="
            [
              'h-32 leading-none'
            ]"
          :style="{
            color: styling.textColor,
          }"
          @change="value => update('name', String(value))"
        />
        <p
          v-else
          class="flex items-center h-32 pl-8"
        >
          <span
            class=""
          >
            {{ item.name }}

          </span>
          <b-badge
            variant="neutral"
            :class="[
              'absolute left-8 bottom-0',
              'px-0 py-3 rounded-b-none rounded-t-sm text-11 font-semibold',
              'bg-transparent text-gray-500 bg-opacity-50',
              'ring-0 leading-none uppercase'
            ]"
          >
            <span class="w-12 mr-2 icon_v2-so_lock text-12"></span>
            From library
          </b-badge>
        </p>
      </div>
    </div>
    <!-- Item Quantity -->
    <div class="table-cell qty" :class="{ error: $v.quantity.$error }">
      <div class="inline-flex space-x-4">
        <b-form-input
          :value="item.quantity || 0"
          type="number"
          size="sm"
          min="0"
          trim
          class="w-40 px-8"
          @input="value => update('quantity', Number(value))"
        />
        <b-form-select
          v-if="!item.fromLibrary"
          :value="item.unit || unitOptions[0]"
          :options="unitOptions"
          size="sm"
          class="w-88"
          @change="val => update('unit', val)"
        />
        <div
          v-else
          class="px-12 w-88"
        >
          {{ item.unit | pluralize(item.quantity) }}
        </div>
      </div>
    </div>
    <!-- Item Rate -->
    <div
      class="table-cell rate"
      :class="{ error: $v.rate.$error, editable: !item.fromLibrary }"
    >
      <p class="form-input-currency">
        <i><DocumentCurrency symbol :document-id="document._id" /></i>
        <b-form-input
          v-if="!item.fromLibrary"
          :value="item.rate || 0"
          type="number"
          size="sm"
          min="0"
          trim
          class="p-0 bg-transparent border-none outline-none"
          @input="value => update('rate', Number(value))"
        />
        <span v-else>{{ item.rate || 0 }}</span>
      </p>
    </div>

    <!-- Total cost -->
    <div class="table-cell cost">
      <DocumentCurrency :value="cost" :document-id="document._id" />
      <b-badge
        v-if="!item.isBillable"
        variant="neutral"
        :class="[
          'absolute right-8 bottom-0',
          'p-3 rounded-b-none rounded-t-sm text-11 font-semibold',
          'bg-gray-200 text-gray-600 bg-opacity-50',
          'ring-0 leading-none uppercase'
        ]"
      >
        Not billable
      </b-badge>
    </div>

    <!-- Item Actions -->
    <div
      class="table-cell actions"
    >
      <DocumentNodeEditorToolbarPopoverLight
        :width="180"
        placement="bottom"
        :target-id="componentID"
        triggers="click"
        :close-on-click-outside="true"
        class-name="text-gray-600 hover:text-gray-800 h-full opacity-50 hover:opacity-100"
        :container="componentID"
        @popover-opening="$emit('set-active-billable-item', true)"
        @popover-closing="$emit('set-active-billable-item', false)"
      >
        <template #button-content>
          <span class="w-24 text-24 icon_v2-so_menu-circle-vertical" />
        </template>
        <template #default>
          <div class="flex flex-col space-y-8">
            <SettingsSwitch
              label="Billable"
              :value="item.isBillable"
              @input="value => update('isBillable', value)"
            />
            <!-- Only for items from the library -->
            <span v-if="item.fromLibrary" class="w-full h-px bg-gray-200"></span>
            <div v-if="item.fromLibrary" class="flex items-center space-between">
              <span class="flex-1 text-gray-600">Detach from library</span>
              <Button
                v-b-tooltip.hover.bottom.v-info.dh0.ds200="`Detach item to edit name & rate`"
                variant="delete-icon"
                @click="update('fromLibrary', false)"
              >
                <span class="w-20 icon_v2-so_link-unlink text-20"></span>
              </Button>
            </div>
            <!-- Only for items from the library -->
            <span class="w-full h-px bg-gray-200"></span>
            <div class="flex items-center space-between">
              <span class="flex-1 text-gray-600">Remove item</span>
              <Button
                variant="delete-icon"
                @click="onRemove"
              >
                <span class="w-20 icon_v2-so_trash text-20"></span>
              </Button>
            </div>
          </div>
        </template>
      </DocumentNodeEditorToolbarPopoverLight>
    </div>
  </div>
</template>

<script>
import { alwaysEmptyObject } from 'ramda-extension'
import { toRef, computed, defineComponent, inject } from '@vue/composition-api'
import { useVuelidate } from '@vuelidate/core'
import { required, integer, minValue } from '@vuelidate/validators'
import { HandleDirective } from 'vue-slicksort'
import DocumentCurrency from '@/components/DocumentCurrency.vue'
import Button from '@/components/Button.vue'

import DocumentNodeEditorToolbarPopoverLight from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarPopoverLight.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'

const unitOptions = ['hour', 'day', 'week', 'month', 'year', 'item', 'flat fee']

export default defineComponent({
  name: 'DocumentContentBlockCostEditorItem',
  directives: { handle: HandleDirective },
  components: {
    Button,
    DocumentNodeEditorToolbarPopoverLight,
    SettingsSwitch,
    DocumentCurrency,
  },
  props: {
    item: {
      type: Object,
      default: alwaysEmptyObject,
    },
    currency: {
      type: String,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: String,
      default: null,
    },
    autofocus: Boolean,
  },
  setup(props, context) {
    const document = inject('document')
    const validationRules = {
      name: {
      },
      unit: {
        required,
      },
      rate: {
        required,
        integer,
        minValue: minValue(0),
      },
      quantity: {
        required,
        integer,
        minValue: minValue(0),
      },
    }
    const $v = useVuelidate(validationRules, toRef(props, 'item'))
    const cost = computed(() => props.item.rate * props.item.quantity || 0)

    const update = (key, value) => {
      context.emit('update', { key, value })
    }

    const onRemove = () => {
      context.emit('set-active-billable-item', false)
      context.emit('remove')
    }

    return {
      cost,
      unitOptions,
      update,
      onRemove,
      $v,
      document,
    }
  },
})
</script>
