
<ModalBasic
  :no-close="false"
  :hide-footer="true"
  content-class="modal-content-tertiary"
  body-class="modal-body-unsplash"
  header-class="modal-header-tertiary modal-header-unsplash"
  footer-class="modal-footer-tertiary"
  size="lg"
  v-bind="$attrs"
  v-on="$listeners"
>
  <template #header>
    <div
      class="flex text-18 text-gray-900 font-semibold items-center justify-center w-full"
      @click.stop
    >
      Choose photos from
      <span class="logo-unsplash-logo h-32 text-32 text-gray-1000 ml-8 relative -top-3" />
    </div>
  </template>
  <template #body>
    <div class="flex flex-row items-center border-b border-gray-200" @click.stop>
      <div class="relative grow mr-8">
        <b-input
          v-model="filter.keyword"
          autofocus
          type="search"
          placeholder="Search for photos"
          class="text-18 rounded-none border-none h-56 pl-48 text-search"
          @keyup.enter="load"
        />
        <span
          class="icon-magnify text-24 w-24 absolute left-16 top-16 text-gray-500"
        />
      </div>
      <b-button
        variant="primary"
        class="mr-16"
        @click.stop="load"
      >
        Search
      </b-button>
    </div>
    <div class="flex flex-wrap justify-between" @click.stop>
      <div
        v-if="hasNoResults"
        :class="[
          'w-full flex items-center justify-center',
          'text-gray-600 text-18 text-center',
          'py-64',
        ]"
      >
        <span>Could not find any photos for
          <span class="text-gray-700">{{ filter.keyword }}</span>
        </span>
      </div>
      <DocumentMasonry
        v-else
        :cols="{ default: 3, 700: 2, 400: 1 }"
        :gutter="{ default: '2px' }"
      >
        <template #default>
          <div
            v-for="image in images"
            :key="image.id"
            class="mb-2 relative group hover:cursor-pointer"
          >
            <img
              :src="image.urls.small"
              :alt="image.alt_description"
              @click.stop="pickImage(image)"
            />
            <span
              :class="[
                'absolute bottom-0 left-0 right-0 h-1/2',
                'bg-gradient-to-t from-black opacity-70',
                'pointer-events-none',
                'invisible group-hover:visible transition-all'
              ]"
            />
            <div
              :class="[
                'absolute bottom-0 left-0 right-0 p-16',
                'flex space-x-4',
                'invisible opacity-0 transition-all',
                'group-hover:visible group-hover:opacity-100',
                'text-white text-12',
              ]"
            >
              <span class="opacity-50">Photo by</span>
              <b-link
                class="underline text-gray-200 hover:text-white"
                target="_blank"
                :title="`${image.user.name}'s profile on Unsplash`"
                :href="image.user.links.html"
              >
                {{ image.user.name }}
              </b-link>
            </div>
          </div>
        </template>
      </DocumentMasonry>
    </div>
  </template>
</ModalBasic>
