import Vue from 'vue'
import { when, is } from 'ramda'
import { parseISO, formatDistance } from 'date-fns'

const ensureDate = when(is(String), parseISO)

Vue.filter('dateDistance', value => formatDistance(
  ensureDate(value),
  new Date(),
  { numeric: 'always' }
));
