
<ul class="space-y-12 text-16 list-none">
  <li
    v-for="(item, index) in items"
    :key="item[idProp]"
    class="item"
    :class="{
      'item-checked': selection[item[idProp]],
      'item-default': !selection[item[idProp]]
    }"
    @click="toggle(item[idProp])"
  >
    <div
      class="w-24 h-24 flex items-center justify-center rounded-md mr-6 border"
      :class="{
        'border-green-600 bg-green-500 text-green-100': selection[item[idProp]],
        'border-gray-200 bg-white text-gray-200': !selection[item[idProp]]
      }"
    >
      <span class="icon_v2-so_tick text-16 w-16"></span>
    </div>
    <slot
      :item="item"
      :index="index"
      :selected="selection[item[idProp]]"
    />
  </li>
</ul>
