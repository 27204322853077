
<div>
  <div class="relative">
    <AnswerBadge
      v-if="['XL', 'XXL'].includes(nodeWidth)"
      :is-answerable="isAnswerable"
      :has-no-answer="!contentBlock.isDirty"
      position="top"
    />
    <div
      v-if="!contentBlock.settings.hasMultipleAnswers"
      :class="isVerticalStyle"
    >
      <b-form-radio
        v-for="(answer, index) in composedAnswersFromOptions"
        :key="index"
        :name="`radio-choices`"
        :value="index"
        :checked="answer.checked ? index : null"
        :disabled="!isAnswerable"
        button
        button-variant="radio-success"
        class="whitespace-nowrap portal-paragraph-font"
        @change="val => handleCheckboxChange(true, index)"
      >
        {{ answer.label }}
      </b-form-radio>
    </div>
    <div
      v-else
      :class="isVerticalStyle"
    >
      <b-form-checkbox
        v-for="(answer, index) in composedAnswersFromOptions"
        :key="index"
        :checked="answer.checked"
        :name="`checkbox-${index}`"
        :disabled="!isAnswerable"
        button
        button-variant="checkbox-success"
        class="whitespace-nowrap portal-paragraph-font"
        @change="val => handleCheckboxChange(val, index)"
      >
        {{ answer.label }}
      </b-form-checkbox>
    </div>
  </div>
  <Notes
    v-if="contentBlock.settings.hasNotes"
    :is-answerable="isAnswerable"
    :text-variant="textVariant"
    :value="contentBlock.notes"
    @input="data => $emit('update', data)"
  />
</div>
