<template>
  <span
    v-b-tooltip.hover
    :class="['avatar-wrap', $style['avatar-wrap'], position]"
    :disabled="!showTooltip"
    :title="name"
  >
    <span
      v-if="showAvatar"
      :class="[
        'avatar',
        $style['avatar'],
        `avatar-${size}`,
        showName && 'mr-4',
      ]"
    >
      <VueAvatar
        :size="size"
        :username="fullName"
        :src="url"
        :custom-style="{
          fontFamily: 'inherit',
          fontSize: fontSize + 'px',
          fontWeight: 'bold',
          color: '#ffffff',
        }"
        :initials="computedInitials"
      />
    </span>
    <span v-if="showName || timestamp" class="flex justify-center flex-col">
      <i
        v-if="showName"
        :class="[
          'text-' + fontSize,
          'm-0 leading-tight',
          nowrap && 'whitespace-nowrap',
        ]"
      >
        {{ fullName }}
      </i>
      <i
        v-if="timestamp"
        :class="[
          'text-' + fontSize,
          'font-normal leading-tight text-gray-700 dark:text-darkGray-400 m-0',
          nowrap && 'whitespace-nowrap',
        ]"
      >
        {{ formattedTimestamp }}
      </i>
    </span>
  </span>
</template>
<script>
import VueAvatar from 'vue-avatar'
import { pathOr } from 'ramda'
import { computed } from '@vue/composition-api'
import { format } from 'date-fns'
import { getInitials } from '@/v2/lib/helpers/string'

export default {
  name: 'Avatar',
  components: {
    VueAvatar,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    profile: {
      type: Object,
      default: null,
    },
    position: {
      type: String,
      default: 'left',
    },
    size: {
      type: Number,
      default: 24,
    },
    fontSize: {
      type: String,
      default: '13',
    },
    timestamp: {
      type: String,
      default: '',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: true,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const fullName = computed(() =>
      pathOr(props.name ?? '', ['profile', 'fullName'], props)
    )

    const url = computed(() =>
      pathOr(props.src, ['profile', 'profilePhotoUrl'], props)
    )

    const computedInitials = computed(() => getInitials(fullName.value))

    const formattedTimestamp = computed(() => {
      const tempDate = format(
        new Date(props.timestamp),
        "MMM dd 'at' hh:mm aaa"
      )
      return tempDate
    })

    return {
      fullName,
      url,
      formattedTimestamp,
      computedInitials,
    }
  },
}
</script>
<style lang="scss" module>
.avatar-wrap {
  display: inline-flex;
  align-items: center;

  i {
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.avatar-wrap:global(.right) {
  flex-direction: row-reverse;
  i {
    margin-left: 0;
    margin-right: 4px;
  }
}
.avatar {
  display: inline-flex;
}
.image {
  background-color: $gray-300;
  border-radius: 100%;
}
</style>
