<template>
  <DocumentNodeEditorToolbarSettings
    :popover-target-id="popoverTargetId"
    :prevent-popover-close="preventPopoverClose"
  >
    <b-form>
      <SettingsSwitch
        tooltip="Show/hide the title"
        :value="hasTitle"
        label="Title"
        @input="value => $emit('input', { value: value, type: 'hasTitle'})"
      />
      <SettingsSwitch
        tooltip="Show/hide the description"
        :value="description"
        label="Description"
        @input="value => $emit('input', { value: value, type: 'description'})"
      />
      <slot />
    </b-form>
  </DocumentNodeEditorToolbarSettings>
</template>

<script>
import DocumentNodeEditorToolbarSettings from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSettings.vue'
import SettingsSwitch from '@/components/SettingsSwitch.vue'

export default {
  name: 'DocumentContentBlockQASettings',
  components: {
    DocumentNodeEditorToolbarSettings,
    SettingsSwitch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Boolean,
      default: true,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    preventPopoverClose: {
      type: Boolean,
      default: false,
    },
    popoverTargetId: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" scoped></style>
