
<SidebarTreeLink
  :id="node.id"
  :to="to"
  activate-on-child-route
>
  <DocumentComputedData
    v-slot="{ title, icon }"
    :document="node.data.document"
    :is-open="isOpen"
  >
    <SidebarItemBasic :icon="icon" :label="title" />
  </DocumentComputedData>
</SidebarTreeLink>
