<template>
  <button
    v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Mark as read'"
    class="
      p-8 ml-auto
      inline-flex items-center justify-center
      opacity-0 invisible group-hover:opacity-100 group-hover:visible
    "
    @click.stop="$emit('click')"
  >
    <span
      class="
        icon_v2-so_tick w-18 text-18 text-gray-700
        transform-gpu transition-all hover:-translate-y-2
      "
    />
  </button>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NotificationRendererButtonRead',
})
</script>
