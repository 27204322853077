<template>
  <div
    v-b-tooltip="{
      placement: 'bottom',
      trigger: 'hover',
      variant: 'info',
      delay: {'show':200,'hide':0},
      title: `${name}`,
    }"
    class="file-name"
  >
    <div class="file-name-part truncate">
      {{ name | fileName }}
    </div>
    <div class="file-ext-part">
      {{ name | fileExt }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileName',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.file-name {
  display: flex;
  width: 100%;

  .file-name-part {
    flex: 85%;
  }

  .file-ext-part {
    flex-grow: 1;
  }
}
</style>
