<template>
  <FeathersVuexFind
    v-slot="{ items, isFindPending }"
    service="profiles"
    :params="findParams"
    :query-when="notInStore"
    v-bind="$attrs"
  >
    <slot v-bind="{ profile: items[0] ?? null, isGetPending: isFindPending }" />
  </FeathersVuexFind>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ProfileGetByUser',
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const { Profile } = context.root.$FeathersVuex.api
    const findParams = computed(() => ({ query: { user: props.userId } }))
    const notInStore = computed(() => !Profile.countInStore(findParams))

    return {
      findParams,
      notInStore,
    }
  },
})
</script>
