<template>
  <div class="flex items-center justify-center h-screen" :class="!transparent && 'bg-page'">
    <slot v-if="$slots.default" />
    <router-view v-else />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LayoutCenter',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
