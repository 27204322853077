
<DocumentComputedData
  v-slot="{ title, icon }"
  :document="document"
>
  <DocumentLink
    v-slot="{ navigate }"
    :document="document"
  >
    <h4
      class="
      cursor-pointer flex items-center
      gap-4 mb-2 portal-heading-font group
    "
      @click="navigate"
    >
      <span
        class="text-18"
        :class="icon || ''"
        :style="{ color: document.color || 'var(--cp-accent)'}"
      />
      <span class="group-hover:portal-accent-foreground dark:text-white/70">{{ title }}</span>
    </h4>
  </DocumentLink>
</DocumentComputedData>
