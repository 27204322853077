
<div class="w-420">
  <div v-if="documents.length" class="flex flex-col items-start w-full">
    <Badge variant="primary">{{ doc }}</Badge>
    <ul class="search-results-list">
      <DocumentLink
        v-for="document in documents"
        :key="document._id"
        v-slot="{ navigate }"
        :document="document"
      >
        <li
          class="search-results-item group"
          @click="navigate"
        >
          <span class="icon-document-custom search-results-item-icon"></span>
          <p class="dark:text-darkGray-300 truncate">{{ document.title }}</p>
        </li>
      </DocumentLink>
    </ul>
  </div>
  <div v-if="actionItems.length" class="flex flex-col items-start w-full">
    <Badge variant="primary">{{ actionItem }}</Badge>
    <ul class="search-results-list">
      <li
        v-for="item in actionItems"
        :key="item._id"
        class="search-results-item group"
        @click="goToTask(item)"
      >
        <span class="icon-util-checkbox search-results-item-icon"></span>
        <span class="dark:text-darkGray-300">{{ item.title }}</span>
      </li>
    </ul>
  </div>

  <p
    v-if="!actionItems.length && !documents.length"
    class="text-gray-600 text-14"
  >
    {{ $t('common.could-not-find') }}
  </p>
</div>
