// Icons of popular apps and services
// This will be used on Project Apps and/or Quick Links

export const materialIcons = [
  {
    name: 'error',
    icon: 'material-error',
  },
  {
    name: 'error_outline',
    icon: 'material-error_outline',
  },
  {
    name: 'warning1',
    icon: 'material-warning1',
  },
  {
    name: 'add_alert',
    icon: 'material-add_alert',
  },
  {
    name: 'notification_important',
    icon: 'material-notification_important',
  },
  {
    name: 'album',
    icon: 'material-album',
  },
  {
    name: 'av_timer',
    icon: 'material-av_timer',
  },
  {
    name: 'closed_caption',
    icon: 'material-closed_caption',
  },
  {
    name: 'equalizer',
    icon: 'material-equalizer',
  },
  {
    name: 'explicit',
    icon: 'material-explicit',
  },
  {
    name: 'fast_forward',
    icon: 'material-fast_forward',
  },
  {
    name: 'fast_rewind',
    icon: 'material-fast_rewind',
  },
  {
    name: 'games',
    icon: 'material-games',
  },
  {
    name: 'hearing',
    icon: 'material-hearing',
  },
  {
    name: 'high_quality',
    icon: 'material-high_quality',
  },
  {
    name: 'loop',
    icon: 'material-loop',
  },
  {
    name: 'mic',
    icon: 'material-mic',
  },
  {
    name: 'mic_none',
    icon: 'material-mic_none',
  },
  {
    name: 'mic_off',
    icon: 'material-mic_off',
  },
  {
    name: 'movie',
    icon: 'material-movie',
  },
  {
    name: 'library_add',
    icon: 'material-library_add',
  },
  {
    name: 'library_books',
    icon: 'material-library_books',
  },
  {
    name: 'library_music',
    icon: 'material-library_music',
  },
  {
    name: 'new_releases',
    icon: 'material-new_releases',
  },
  {
    name: 'not_interested',
    icon: 'material-not_interested',
  },
  {
    name: 'pause1',
    icon: 'material-pause1',
  },
  {
    name: 'pause_circle_filled',
    icon: 'material-pause_circle_filled',
  },
  {
    name: 'pause_circle_outline',
    icon: 'material-pause_circle_outline',
  },
  {
    name: 'play_arrow',
    icon: 'material-play_arrow',
  },
  {
    name: 'play_circle_filled',
    icon: 'material-play_circle_filled',
  },
  {
    name: 'play_circle_outline',
    icon: 'material-play_circle_outline',
  },
  {
    name: 'playlist_add',
    icon: 'material-playlist_add',
  },
  {
    name: 'queue_music',
    icon: 'material-queue_music',
  },
  {
    name: 'radio',
    icon: 'material-radio',
  },
  {
    name: 'recent_actors',
    icon: 'material-recent_actors',
  },
  {
    name: 'repeat1',
    icon: 'material-repeat1',
  },
  {
    name: 'repeat_one',
    icon: 'material-repeat_one',
  },
  {
    name: 'replay',
    icon: 'material-replay',
  },
  {
    name: 'shuffle',
    icon: 'material-shuffle',
  },
  {
    name: 'skip_next',
    icon: 'material-skip_next',
  },
  {
    name: 'skip_previous',
    icon: 'material-skip_previous',
  },
  {
    name: 'snooze',
    icon: 'material-snooze',
  },
  {
    name: 'stop1',
    icon: 'material-stop1',
  },
  {
    name: 'subtitles',
    icon: 'material-subtitles',
  },
  {
    name: 'surround_sound',
    icon: 'material-surround_sound',
  },
  {
    name: 'video_library',
    icon: 'material-video_library',
  },
  {
    name: 'videocam',
    icon: 'material-videocam',
  },
  {
    name: 'videocam_off',
    icon: 'material-videocam_off',
  },
  {
    name: 'volume_down',
    icon: 'material-volume_down',
  },
  {
    name: 'volume_mute',
    icon: 'material-volume_mute',
  },
  {
    name: 'volume_off',
    icon: 'material-volume_off',
  },
  {
    name: 'volume_up',
    icon: 'material-volume_up',
  },
  {
    name: 'web',
    icon: 'material-web',
  },
  {
    name: 'hd',
    icon: 'material-hd',
  },
  {
    name: 'sort_by_alpha',
    icon: 'material-sort_by_alpha',
  },
  {
    name: 'airplay',
    icon: 'material-airplay',
  },
  {
    name: 'forward_10',
    icon: 'material-forward_10',
  },
  {
    name: 'forward_30',
    icon: 'material-forward_30',
  },
  {
    name: 'forward_5',
    icon: 'material-forward_5',
  },
  {
    name: 'replay_10',
    icon: 'material-replay_10',
  },
  {
    name: 'replay_30',
    icon: 'material-replay_30',
  },
  {
    name: 'replay_5',
    icon: 'material-replay_5',
  },
  {
    name: 'add_to_queue',
    icon: 'material-add_to_queue',
  },
  {
    name: 'fiber_dvr',
    icon: 'material-fiber_dvr',
  },
  {
    name: 'fiber_new',
    icon: 'material-fiber_new',
  },
  {
    name: 'playlist_play',
    icon: 'material-playlist_play',
  },
  {
    name: 'art_track',
    icon: 'material-art_track',
  },
  {
    name: 'fiber_manual_record',
    icon: 'material-fiber_manual_record',
  },
  {
    name: 'fiber_smart_record',
    icon: 'material-fiber_smart_record',
  },
  {
    name: 'music_video',
    icon: 'material-music_video',
  },
  {
    name: 'subscriptions',
    icon: 'material-subscriptions',
  },
  {
    name: 'playlist_add_check',
    icon: 'material-playlist_add_check',
  },
  {
    name: 'queue_play_next',
    icon: 'material-queue_play_next',
  },
  {
    name: 'remove_from_queue',
    icon: 'material-remove_from_queue',
  },
  {
    name: 'slow_motion_video',
    icon: 'material-slow_motion_video',
  },
  {
    name: 'web_asset',
    icon: 'material-web_asset',
  },
  {
    name: 'fiber_pin',
    icon: 'material-fiber_pin',
  },
  {
    name: 'branding_watermark',
    icon: 'material-branding_watermark',
  },
  {
    name: 'call_to_action',
    icon: 'material-call_to_action',
  },
  {
    name: 'featured_play_list',
    icon: 'material-featured_play_list',
  },
  {
    name: 'featured_video',
    icon: 'material-featured_video',
  },
  {
    name: 'note',
    icon: 'material-note',
  },
  {
    name: 'video_call',
    icon: 'material-video_call',
  },
  {
    name: 'video_label',
    icon: 'material-video_label',
  },
  {
    name: '4k',
    icon: 'material-4k',
  },
  {
    name: 'missed_video_call',
    icon: 'material-missed_video_call',
  },
  {
    name: 'control_camera',
    icon: 'material-control_camera',
  },
  {
    name: 'business',
    icon: 'material-business',
  },
  {
    name: 'call',
    icon: 'material-call',
  },
  {
    name: 'call_end',
    icon: 'material-call_end',
  },
  {
    name: 'call_made',
    icon: 'material-call_made',
  },
  {
    name: 'call_merge',
    icon: 'material-call_merge',
  },
  {
    name: 'call_missed',
    icon: 'material-call_missed',
  },
  {
    name: 'call_received',
    icon: 'material-call_received',
  },
  {
    name: 'call_split',
    icon: 'material-call_split',
  },
  {
    name: 'chat',
    icon: 'material-chat',
  },
  {
    name: 'clear_all',
    icon: 'material-clear_all',
  },
  {
    name: 'comment1',
    icon: 'material-comment1',
  },
  {
    name: 'contacts',
    icon: 'material-contacts',
  },
  {
    name: 'dialer_sip',
    icon: 'material-dialer_sip',
  },
  {
    name: 'dialpad',
    icon: 'material-dialpad',
  },
  {
    name: 'email',
    icon: 'material-email',
  },
  {
    name: 'forum',
    icon: 'material-forum',
  },
  {
    name: 'import_export',
    icon: 'material-import_export',
  },
  {
    name: 'invert_colors_off',
    icon: 'material-invert_colors_off',
  },
  {
    name: 'live_help',
    icon: 'material-live_help',
  },
  {
    name: 'location_off',
    icon: 'material-location_off',
  },
  {
    name: 'location_on',
    icon: 'material-location_on',
  },
  {
    name: 'message',
    icon: 'material-message',
  },
  {
    name: 'chat_bubble',
    icon: 'material-chat_bubble',
  },
  {
    name: 'chat_bubble_outline',
    icon: 'material-chat_bubble_outline',
  },
  {
    name: 'no_sim',
    icon: 'material-no_sim',
  },
  {
    name: 'phone1',
    icon: 'material-phone1',
  },
  {
    name: 'portable_wifi_off',
    icon: 'material-portable_wifi_off',
  },
  {
    name: 'contact_phone',
    icon: 'material-contact_phone',
  },
  {
    name: 'contact_mail',
    icon: 'material-contact_mail',
  },
  {
    name: 'ring_volume',
    icon: 'material-ring_volume',
  },
  {
    name: 'speaker_phone',
    icon: 'material-speaker_phone',
  },
  {
    name: 'stay_current_landscape',
    icon: 'material-stay_current_landscape',
  },
  {
    name: 'stay_current_portrait',
    icon: 'material-stay_current_portrait',
  },
  {
    name: 'swap_calls',
    icon: 'material-swap_calls',
  },
  {
    name: 'textsms',
    icon: 'material-textsms',
  },
  {
    name: 'voicemail',
    icon: 'material-voicemail',
  },
  {
    name: 'vpn_key',
    icon: 'material-vpn_key',
  },
  {
    name: 'phonelink_erase',
    icon: 'material-phonelink_erase',
  },
  {
    name: 'phonelink_lock',
    icon: 'material-phonelink_lock',
  },
  {
    name: 'phonelink_ring',
    icon: 'material-phonelink_ring',
  },
  {
    name: 'phonelink_setup',
    icon: 'material-phonelink_setup',
  },
  {
    name: 'present_to_all',
    icon: 'material-present_to_all',
  },
  {
    name: 'import_contacts',
    icon: 'material-import_contacts',
  },
  {
    name: 'mail_outline',
    icon: 'material-mail_outline',
  },
  {
    name: 'screen_share',
    icon: 'material-screen_share',
  },
  {
    name: 'stop_screen_share',
    icon: 'material-stop_screen_share',
  },
  {
    name: 'call_missed_outgoing',
    icon: 'material-call_missed_outgoing',
  },
  {
    name: 'rss_feed',
    icon: 'material-rss_feed',
  },
  {
    name: 'alternate_email',
    icon: 'material-alternate_email',
  },
  {
    name: 'mobile_screen_share',
    icon: 'material-mobile_screen_share',
  },
  {
    name: 'add_call',
    icon: 'material-add_call',
  },
  {
    name: 'cancel_presentation',
    icon: 'material-cancel_presentation',
  },
  {
    name: 'pause_presentation',
    icon: 'material-pause_presentation',
  },
  {
    name: 'unsubscribe',
    icon: 'material-unsubscribe',
  },
  {
    name: 'cell_wifi',
    icon: 'material-cell_wifi',
  },
  {
    name: 'sentiment_satisfied_alt',
    icon: 'material-sentiment_satisfied_alt',
  },
  {
    name: 'list_alt',
    icon: 'material-list_alt',
  },
  {
    name: 'domain_disabled',
    icon: 'material-domain_disabled',
  },
  {
    name: 'lightbulb',
    icon: 'material-lightbulb',
  },
  {
    name: 'add',
    icon: 'material-add',
  },
  {
    name: 'add_box',
    icon: 'material-add_box',
  },
  {
    name: 'add_circle',
    icon: 'material-add_circle',
  },
  {
    name: 'add_circle_outline',
    icon: 'material-add_circle_outline',
  },
  {
    name: 'archive1',
    icon: 'material-archive1',
  },
  {
    name: 'backspace',
    icon: 'material-backspace',
  },
  {
    name: 'block',
    icon: 'material-block',
  },
  {
    name: 'clear',
    icon: 'material-clear',
  },
  {
    name: 'content_copy',
    icon: 'material-content_copy',
  },
  {
    name: 'content_cut',
    icon: 'material-content_cut',
  },
  {
    name: 'content_paste',
    icon: 'material-content_paste',
  },
  {
    name: 'create',
    icon: 'material-create',
  },
  {
    name: 'drafts',
    icon: 'material-drafts',
  },
  {
    name: 'filter_list',
    icon: 'material-filter_list',
  },
  {
    name: 'flag1',
    icon: 'material-flag1',
  },
  {
    name: 'forward1',
    icon: 'material-forward1',
  },
  {
    name: 'gesture',
    icon: 'material-gesture',
  },
  {
    name: 'inbox1',
    icon: 'material-inbox1',
  },
  {
    name: 'link1',
    icon: 'material-link1',
  },
  {
    name: 'redo',
    icon: 'material-redo',
  },
  {
    name: 'remove1',
    icon: 'material-remove1',
  },
  {
    name: 'remove_circle',
    icon: 'material-remove_circle',
  },
  {
    name: 'remove_circle_outline',
    icon: 'material-remove_circle_outline',
  },
  {
    name: 'reply1',
    icon: 'material-reply1',
  },
  {
    name: 'reply_all',
    icon: 'material-reply_all',
  },
  {
    name: 'report',
    icon: 'material-report',
  },
  {
    name: 'save1',
    icon: 'material-save1',
  },
  {
    name: 'select_all',
    icon: 'material-select_all',
  },
  {
    name: 'send1',
    icon: 'material-send1',
  },
  {
    name: 'sort1',
    icon: 'material-sort1',
  },
  {
    name: 'text_format',
    icon: 'material-text_format',
  },
  {
    name: 'undo1',
    icon: 'material-undo1',
  },
  {
    name: 'font_download',
    icon: 'material-font_download',
  },
  {
    name: 'move_to_inbox',
    icon: 'material-move_to_inbox',
  },
  {
    name: 'unarchive',
    icon: 'material-unarchive',
  },
  {
    name: 'next_week',
    icon: 'material-next_week',
  },
  {
    name: 'weekend',
    icon: 'material-weekend',
  },
  {
    name: 'delete_sweep',
    icon: 'material-delete_sweep',
  },
  {
    name: 'low_priority',
    icon: 'material-low_priority',
  },
  {
    name: 'outlined_flag',
    icon: 'material-outlined_flag',
  },
  {
    name: 'link_off',
    icon: 'material-link_off',
  },
  {
    name: 'report_off',
    icon: 'material-report_off',
  },
  {
    name: 'save_alt',
    icon: 'material-save_alt',
  },
  {
    name: 'ballot',
    icon: 'material-ballot',
  },
  {
    name: 'file_copy',
    icon: 'material-file_copy',
  },
  {
    name: 'how_to_reg',
    icon: 'material-how_to_reg',
  },
  {
    name: 'how_to_vote',
    icon: 'material-how_to_vote',
  },
  {
    name: 'waves',
    icon: 'material-waves',
  },
  {
    name: 'where_to_vote',
    icon: 'material-where_to_vote',
  },
  {
    name: 'add_link',
    icon: 'material-add_link',
  },
  {
    name: 'inventory',
    icon: 'material-inventory',
  },
  {
    name: 'access_alarm',
    icon: 'material-access_alarm',
  },
  {
    name: 'access_alarms',
    icon: 'material-access_alarms',
  },
  {
    name: 'access_time',
    icon: 'material-access_time',
  },
  {
    name: 'add_alarm',
    icon: 'material-add_alarm',
  },
  {
    name: 'airplanemode_off',
    icon: 'material-airplanemode_off',
  },
  {
    name: 'airplanemode_on',
    icon: 'material-airplanemode_on',
  },
  {
    name: 'battery_alert',
    icon: 'material-battery_alert',
  },
  {
    name: 'battery_charging_full',
    icon: 'material-battery_charging_full',
  },
  {
    name: 'battery_full',
    icon: 'material-battery_full',
  },
  {
    name: 'battery_unknown',
    icon: 'material-battery_unknown',
  },
  {
    name: 'bluetooth1',
    icon: 'material-bluetooth1',
  },
  {
    name: 'bluetooth_connected',
    icon: 'material-bluetooth_connected',
  },
  {
    name: 'bluetooth_disabled',
    icon: 'material-bluetooth_disabled',
  },
  {
    name: 'bluetooth_searching',
    icon: 'material-bluetooth_searching',
  },
  {
    name: 'brightness_auto',
    icon: 'material-brightness_auto',
  },
  {
    name: 'brightness_high',
    icon: 'material-brightness_high',
  },
  {
    name: 'brightness_low',
    icon: 'material-brightness_low',
  },
  {
    name: 'brightness_medium',
    icon: 'material-brightness_medium',
  },
  {
    name: 'data_usage',
    icon: 'material-data_usage',
  },
  {
    name: 'developer_mode',
    icon: 'material-developer_mode',
  },
  {
    name: 'devices',
    icon: 'material-devices',
  },
  {
    name: 'dvr',
    icon: 'material-dvr',
  },
  {
    name: 'gps_fixed',
    icon: 'material-gps_fixed',
  },
  {
    name: 'gps_not_fixed',
    icon: 'material-gps_not_fixed',
  },
  {
    name: 'gps_off',
    icon: 'material-gps_off',
  },
  {
    name: 'graphic_eq',
    icon: 'material-graphic_eq',
  },
  {
    name: 'network_cell',
    icon: 'material-network_cell',
  },
  {
    name: 'network_wifi',
    icon: 'material-network_wifi',
  },
  {
    name: 'nfc',
    icon: 'material-nfc',
  },
  {
    name: 'now_wallpaper',
    icon: 'material-now_wallpaper',
  },
  {
    name: 'now_widgets',
    icon: 'material-now_widgets',
  },
  {
    name: 'screen_lock_landscape',
    icon: 'material-screen_lock_landscape',
  },
  {
    name: 'screen_lock_portrait',
    icon: 'material-screen_lock_portrait',
  },
  {
    name: 'screen_lock_rotation',
    icon: 'material-screen_lock_rotation',
  },
  {
    name: 'screen_rotation',
    icon: 'material-screen_rotation',
  },
  {
    name: 'sd_storage',
    icon: 'material-sd_storage',
  },
  {
    name: 'settings_system_daydream',
    icon: 'material-settings_system_daydream',
  },
  {
    name: 'signal_cellular_4_bar',
    icon: 'material-signal_cellular_4_bar',
  },
  {
    name: 'signal_cellular_connected_no_internet_4_bar',
    icon: 'material-signal_cellular_connected_no_internet_4_bar',
  },
  {
    name: 'signal_cellular_null',
    icon: 'material-signal_cellular_null',
  },
  {
    name: 'signal_cellular_off',
    icon: 'material-signal_cellular_off',
  },
  {
    name: 'signal_wifi_4_bar',
    icon: 'material-signal_wifi_4_bar',
  },
  {
    name: 'signal_wifi_4_bar_lock',
    icon: 'material-signal_wifi_4_bar_lock',
  },
  {
    name: 'signal_wifi_off',
    icon: 'material-signal_wifi_off',
  },
  {
    name: 'storage',
    icon: 'material-storage',
  },
  {
    name: 'usb1',
    icon: 'material-usb1',
  },
  {
    name: 'wifi_lock',
    icon: 'material-wifi_lock',
  },
  {
    name: 'wifi_tethering',
    icon: 'material-wifi_tethering',
  },
  {
    name: 'add_to_home_screen',
    icon: 'material-add_to_home_screen',
  },
  {
    name: 'device_thermostat',
    icon: 'material-device_thermostat',
  },
  {
    name: 'mobile_friendly',
    icon: 'material-mobile_friendly',
  },
  {
    name: 'mobile_off',
    icon: 'material-mobile_off',
  },
  {
    name: 'signal_cellular_alt',
    icon: 'material-signal_cellular_alt',
  },
  {
    name: 'attach_file',
    icon: 'material-attach_file',
  },
  {
    name: 'attach_money',
    icon: 'material-attach_money',
  },
  {
    name: 'border_all',
    icon: 'material-border_all',
  },
  {
    name: 'border_bottom',
    icon: 'material-border_bottom',
  },
  {
    name: 'border_clear',
    icon: 'material-border_clear',
  },
  {
    name: 'border_color',
    icon: 'material-border_color',
  },
  {
    name: 'border_horizontal',
    icon: 'material-border_horizontal',
  },
  {
    name: 'border_inner',
    icon: 'material-border_inner',
  },
  {
    name: 'border_left',
    icon: 'material-border_left',
  },
  {
    name: 'border_outer',
    icon: 'material-border_outer',
  },
  {
    name: 'border_right',
    icon: 'material-border_right',
  },
  {
    name: 'border_style',
    icon: 'material-border_style',
  },
  {
    name: 'border_top',
    icon: 'material-border_top',
  },
  {
    name: 'border_vertical',
    icon: 'material-border_vertical',
  },
  {
    name: 'format_align_center',
    icon: 'material-format_align_center',
  },
  {
    name: 'format_align_justify',
    icon: 'material-format_align_justify',
  },
  {
    name: 'format_align_left',
    icon: 'material-format_align_left',
  },
  {
    name: 'format_align_right',
    icon: 'material-format_align_right',
  },
  {
    name: 'format_bold',
    icon: 'material-format_bold',
  },
  {
    name: 'format_clear',
    icon: 'material-format_clear',
  },
  {
    name: 'format_color_fill',
    icon: 'material-format_color_fill',
  },
  {
    name: 'format_color_reset',
    icon: 'material-format_color_reset',
  },
  {
    name: 'format_color_text',
    icon: 'material-format_color_text',
  },
  {
    name: 'format_indent_decrease',
    icon: 'material-format_indent_decrease',
  },
  {
    name: 'format_indent_increase',
    icon: 'material-format_indent_increase',
  },
  {
    name: 'format_italic',
    icon: 'material-format_italic',
  },
  {
    name: 'format_line_spacing',
    icon: 'material-format_line_spacing',
  },
  {
    name: 'format_list_bulleted',
    icon: 'material-format_list_bulleted',
  },
  {
    name: 'format_list_numbered',
    icon: 'material-format_list_numbered',
  },
  {
    name: 'format_paint',
    icon: 'material-format_paint',
  },
  {
    name: 'format_quote',
    icon: 'material-format_quote',
  },
  {
    name: 'format_size',
    icon: 'material-format_size',
  },
  {
    name: 'format_strikethrough',
    icon: 'material-format_strikethrough',
  },
  {
    name: 'format_textdirection_l_to_r',
    icon: 'material-format_textdirection_l_to_r',
  },
  {
    name: 'format_textdirection_r_to_l',
    icon: 'material-format_textdirection_r_to_l',
  },
  {
    name: 'format_underlined',
    icon: 'material-format_underlined',
  },
  {
    name: 'functions',
    icon: 'material-functions',
  },
  {
    name: 'insert_chart',
    icon: 'material-insert_chart',
  },
  {
    name: 'insert_comment',
    icon: 'material-insert_comment',
  },
  {
    name: 'insert_drive_file',
    icon: 'material-insert_drive_file',
  },
  {
    name: 'insert_emoticon',
    icon: 'material-insert_emoticon',
  },
  {
    name: 'insert_invitation',
    icon: 'material-insert_invitation',
  },
  {
    name: 'insert_photo',
    icon: 'material-insert_photo',
  },
  {
    name: 'mode_comment',
    icon: 'material-mode_comment',
  },
  {
    name: 'publish',
    icon: 'material-publish',
  },
  {
    name: 'space_bar',
    icon: 'material-space_bar',
  },
  {
    name: 'strikethrough_s',
    icon: 'material-strikethrough_s',
  },
  {
    name: 'vertical_align_bottom',
    icon: 'material-vertical_align_bottom',
  },
  {
    name: 'vertical_align_center',
    icon: 'material-vertical_align_center',
  },
  {
    name: 'vertical_align_top',
    icon: 'material-vertical_align_top',
  },
  {
    name: 'wrap_text',
    icon: 'material-wrap_text',
  },
  {
    name: 'money_off',
    icon: 'material-money_off',
  },
  {
    name: 'drag_handle',
    icon: 'material-drag_handle',
  },
  {
    name: 'format_shapes',
    icon: 'material-format_shapes',
  },
  {
    name: 'highlight',
    icon: 'material-highlight',
  },
  {
    name: 'linear_scale',
    icon: 'material-linear_scale',
  },
  {
    name: 'short_text',
    icon: 'material-short_text',
  },
  {
    name: 'text_fields',
    icon: 'material-text_fields',
  },
  {
    name: 'monetization_on',
    icon: 'material-monetization_on',
  },
  {
    name: 'title',
    icon: 'material-title',
  },
  {
    name: 'table_chart',
    icon: 'material-table_chart',
  },
  {
    name: 'add_comment',
    icon: 'material-add_comment',
  },
  {
    name: 'format_list_numbered_rtl',
    icon: 'material-format_list_numbered_rtl',
  },
  {
    name: 'scatter_plot',
    icon: 'material-scatter_plot',
  },
  {
    name: 'score',
    icon: 'material-score',
  },
  {
    name: 'insert_chart_outlined',
    icon: 'material-insert_chart_outlined',
  },
  {
    name: 'bar_chart',
    icon: 'material-bar_chart',
  },
  {
    name: 'notes',
    icon: 'material-notes',
  },
  {
    name: 'attachment',
    icon: 'material-attachment',
  },
  {
    name: 'cloud1',
    icon: 'material-cloud1',
  },
  {
    name: 'cloud_circle',
    icon: 'material-cloud_circle',
  },
  {
    name: 'cloud_done',
    icon: 'material-cloud_done',
  },
  {
    name: 'cloud_download',
    icon: 'material-cloud_download',
  },
  {
    name: 'cloud_off',
    icon: 'material-cloud_off',
  },
  {
    name: 'cloud_queue',
    icon: 'material-cloud_queue',
  },
  {
    name: 'cloud_upload',
    icon: 'material-cloud_upload',
  },
  {
    name: 'file_download',
    icon: 'material-file_download',
  },
  {
    name: 'file_upload',
    icon: 'material-file_upload',
  },
  {
    name: 'folder1',
    icon: 'material-folder1',
  },
  {
    name: 'folder_open',
    icon: 'material-folder_open',
  },
  {
    name: 'folder_shared',
    icon: 'material-folder_shared',
  },
  {
    name: 'create_new_folder',
    icon: 'material-create_new_folder',
  },
  {
    name: 'cast',
    icon: 'material-cast',
  },
  {
    name: 'cast_connected',
    icon: 'material-cast_connected',
  },
  {
    name: 'computer',
    icon: 'material-computer',
  },
  {
    name: 'desktop_mac',
    icon: 'material-desktop_mac',
  },
  {
    name: 'desktop_windows',
    icon: 'material-desktop_windows',
  },
  {
    name: 'developer_board',
    icon: 'material-developer_board',
  },
  {
    name: 'dock',
    icon: 'material-dock',
  },
  {
    name: 'headset',
    icon: 'material-headset',
  },
  {
    name: 'headset_mic',
    icon: 'material-headset_mic',
  },
  {
    name: 'keyboard',
    icon: 'material-keyboard',
  },
  {
    name: 'keyboard_arrow_down',
    icon: 'material-keyboard_arrow_down',
  },
  {
    name: 'keyboard_arrow_left',
    icon: 'material-keyboard_arrow_left',
  },
  {
    name: 'keyboard_arrow_right',
    icon: 'material-keyboard_arrow_right',
  },
  {
    name: 'keyboard_arrow_up',
    icon: 'material-keyboard_arrow_up',
  },
  {
    name: 'keyboard_backspace',
    icon: 'material-keyboard_backspace',
  },
  {
    name: 'keyboard_capslock',
    icon: 'material-keyboard_capslock',
  },
  {
    name: 'keyboard_hide',
    icon: 'material-keyboard_hide',
  },
  {
    name: 'keyboard_return',
    icon: 'material-keyboard_return',
  },
  {
    name: 'keyboard_tab',
    icon: 'material-keyboard_tab',
  },
  {
    name: 'keyboard_voice',
    icon: 'material-keyboard_voice',
  },
  {
    name: 'laptop_chromebook',
    icon: 'material-laptop_chromebook',
  },
  {
    name: 'laptop_mac',
    icon: 'material-laptop_mac',
  },
  {
    name: 'laptop_windows',
    icon: 'material-laptop_windows',
  },
  {
    name: 'memory',
    icon: 'material-memory',
  },
  {
    name: 'mouse',
    icon: 'material-mouse',
  },
  {
    name: 'phone_android',
    icon: 'material-phone_android',
  },
  {
    name: 'phone_iphone',
    icon: 'material-phone_iphone',
  },
  {
    name: 'phonelink_off',
    icon: 'material-phonelink_off',
  },
  {
    name: 'router',
    icon: 'material-router',
  },
  {
    name: 'scanner',
    icon: 'material-scanner',
  },
  {
    name: 'security',
    icon: 'material-security',
  },
  {
    name: 'sim_card',
    icon: 'material-sim_card',
  },
  {
    name: 'speaker',
    icon: 'material-speaker',
  },
  {
    name: 'speaker_group',
    icon: 'material-speaker_group',
  },
  {
    name: 'tablet1',
    icon: 'material-tablet1',
  },
  {
    name: 'tablet_android',
    icon: 'material-tablet_android',
  },
  {
    name: 'tablet_mac',
    icon: 'material-tablet_mac',
  },
  {
    name: 'toys',
    icon: 'material-toys',
  },
  {
    name: 'tv1',
    icon: 'material-tv1',
  },
  {
    name: 'watch',
    icon: 'material-watch',
  },
  {
    name: 'device_hub',
    icon: 'material-device_hub',
  },
  {
    name: 'power_input',
    icon: 'material-power_input',
  },
  {
    name: 'devices_other',
    icon: 'material-devices_other',
  },
  {
    name: 'videogame_asset',
    icon: 'material-videogame_asset',
  },
  {
    name: 'device_unknown',
    icon: 'material-device_unknown',
  },
  {
    name: 'headset_off',
    icon: 'material-headset_off',
  },
  {
    name: 'adjust1',
    icon: 'material-adjust1',
  },
  {
    name: 'assistant',
    icon: 'material-assistant',
  },
  {
    name: 'audiotrack',
    icon: 'material-audiotrack',
  },
  {
    name: 'blur_circular',
    icon: 'material-blur_circular',
  },
  {
    name: 'blur_linear',
    icon: 'material-blur_linear',
  },
  {
    name: 'blur_off',
    icon: 'material-blur_off',
  },
  {
    name: 'blur_on',
    icon: 'material-blur_on',
  },
  {
    name: 'brightness_1',
    icon: 'material-brightness_1',
  },
  {
    name: 'brightness_2',
    icon: 'material-brightness_2',
  },
  {
    name: 'brightness_3',
    icon: 'material-brightness_3',
  },
  {
    name: 'brightness_4',
    icon: 'material-brightness_4',
  },
  {
    name: 'broken_image',
    icon: 'material-broken_image',
  },
  {
    name: 'brush',
    icon: 'material-brush',
  },
  {
    name: 'camera1',
    icon: 'material-camera1',
  },
  {
    name: 'camera_alt',
    icon: 'material-camera_alt',
  },
  {
    name: 'camera_front',
    icon: 'material-camera_front',
  },
  {
    name: 'camera_rear',
    icon: 'material-camera_rear',
  },
  {
    name: 'camera_roll',
    icon: 'material-camera_roll',
  },
  {
    name: 'center_focus_strong',
    icon: 'material-center_focus_strong',
  },
  {
    name: 'center_focus_weak',
    icon: 'material-center_focus_weak',
  },
  {
    name: 'collections',
    icon: 'material-collections',
  },
  {
    name: 'color_lens',
    icon: 'material-color_lens',
  },
  {
    name: 'colorize',
    icon: 'material-colorize',
  },
  {
    name: 'compare',
    icon: 'material-compare',
  },
  {
    name: 'control_point_duplicate',
    icon: 'material-control_point_duplicate',
  },
  {
    name: 'crop_16_9',
    icon: 'material-crop_16_9',
  },
  {
    name: 'crop_3_2',
    icon: 'material-crop_3_2',
  },
  {
    name: 'crop1',
    icon: 'material-crop1',
  },
  {
    name: 'crop_5_4',
    icon: 'material-crop_5_4',
  },
  {
    name: 'crop_7_5',
    icon: 'material-crop_7_5',
  },
  {
    name: 'crop_din',
    icon: 'material-crop_din',
  },
  {
    name: 'crop_free',
    icon: 'material-crop_free',
  },
  {
    name: 'crop_original',
    icon: 'material-crop_original',
  },
  {
    name: 'crop_portrait',
    icon: 'material-crop_portrait',
  },
  {
    name: 'crop_square',
    icon: 'material-crop_square',
  },
  {
    name: 'dehaze',
    icon: 'material-dehaze',
  },
  {
    name: 'details',
    icon: 'material-details',
  },
  {
    name: 'exposure',
    icon: 'material-exposure',
  },
  {
    name: 'exposure_neg_1',
    icon: 'material-exposure_neg_1',
  },
  {
    name: 'exposure_neg_2',
    icon: 'material-exposure_neg_2',
  },
  {
    name: 'exposure_plus_1',
    icon: 'material-exposure_plus_1',
  },
  {
    name: 'exposure_plus_2',
    icon: 'material-exposure_plus_2',
  },
  {
    name: 'exposure_zero',
    icon: 'material-exposure_zero',
  },
  {
    name: 'filter_1',
    icon: 'material-filter_1',
  },
  {
    name: 'filter_2',
    icon: 'material-filter_2',
  },
  {
    name: 'filter_3',
    icon: 'material-filter_3',
  },
  {
    name: 'filter1',
    icon: 'material-filter1',
  },
  {
    name: 'filter_4',
    icon: 'material-filter_4',
  },
  {
    name: 'filter_5',
    icon: 'material-filter_5',
  },
  {
    name: 'filter_6',
    icon: 'material-filter_6',
  },
  {
    name: 'filter_7',
    icon: 'material-filter_7',
  },
  {
    name: 'filter_8',
    icon: 'material-filter_8',
  },
  {
    name: 'filter_9',
    icon: 'material-filter_9',
  },
  {
    name: 'filter_9_plus',
    icon: 'material-filter_9_plus',
  },
  {
    name: 'filter_b_and_w',
    icon: 'material-filter_b_and_w',
  },
  {
    name: 'filter_center_focus',
    icon: 'material-filter_center_focus',
  },
  {
    name: 'filter_drama',
    icon: 'material-filter_drama',
  },
  {
    name: 'filter_frames',
    icon: 'material-filter_frames',
  },
  {
    name: 'filter_hdr',
    icon: 'material-filter_hdr',
  },
  {
    name: 'filter_none',
    icon: 'material-filter_none',
  },
  {
    name: 'filter_tilt_shift',
    icon: 'material-filter_tilt_shift',
  },
  {
    name: 'filter_vintage',
    icon: 'material-filter_vintage',
  },
  {
    name: 'flare',
    icon: 'material-flare',
  },
  {
    name: 'flash_auto',
    icon: 'material-flash_auto',
  },
  {
    name: 'flash_off',
    icon: 'material-flash_off',
  },
  {
    name: 'flash_on',
    icon: 'material-flash_on',
  },
  {
    name: 'flip',
    icon: 'material-flip',
  },
  {
    name: 'gradient',
    icon: 'material-gradient',
  },
  {
    name: 'grain',
    icon: 'material-grain',
  },
  {
    name: 'grid_off',
    icon: 'material-grid_off',
  },
  {
    name: 'grid_on',
    icon: 'material-grid_on',
  },
  {
    name: 'hdr_off',
    icon: 'material-hdr_off',
  },
  {
    name: 'hdr_on',
    icon: 'material-hdr_on',
  },
  {
    name: 'hdr_strong',
    icon: 'material-hdr_strong',
  },
  {
    name: 'hdr_weak',
    icon: 'material-hdr_weak',
  },
  {
    name: 'healing',
    icon: 'material-healing',
  },
  {
    name: 'image_aspect_ratio',
    icon: 'material-image_aspect_ratio',
  },
  {
    name: 'iso',
    icon: 'material-iso',
  },
  {
    name: 'leak_add',
    icon: 'material-leak_add',
  },
  {
    name: 'leak_remove',
    icon: 'material-leak_remove',
  },
  {
    name: 'lens',
    icon: 'material-lens',
  },
  {
    name: 'looks_3',
    icon: 'material-looks_3',
  },
  {
    name: 'looks',
    icon: 'material-looks',
  },
  {
    name: 'looks_4',
    icon: 'material-looks_4',
  },
  {
    name: 'looks_5',
    icon: 'material-looks_5',
  },
  {
    name: 'looks_6',
    icon: 'material-looks_6',
  },
  {
    name: 'looks_one',
    icon: 'material-looks_one',
  },
  {
    name: 'looks_two',
    icon: 'material-looks_two',
  },
  {
    name: 'loupe',
    icon: 'material-loupe',
  },
  {
    name: 'monochrome_photos',
    icon: 'material-monochrome_photos',
  },
  {
    name: 'music_note',
    icon: 'material-music_note',
  },
  {
    name: 'nature',
    icon: 'material-nature',
  },
  {
    name: 'nature_people',
    icon: 'material-nature_people',
  },
  {
    name: 'navigate_before',
    icon: 'material-navigate_before',
  },
  {
    name: 'navigate_next',
    icon: 'material-navigate_next',
  },
  {
    name: 'panorama',
    icon: 'material-panorama',
  },
  {
    name: 'panorama_fisheye',
    icon: 'material-panorama_fisheye',
  },
  {
    name: 'panorama_horizontal',
    icon: 'material-panorama_horizontal',
  },
  {
    name: 'panorama_vertical',
    icon: 'material-panorama_vertical',
  },
  {
    name: 'panorama_wide_angle',
    icon: 'material-panorama_wide_angle',
  },
  {
    name: 'photo_album',
    icon: 'material-photo_album',
  },
  {
    name: 'picture_as_pdf',
    icon: 'material-picture_as_pdf',
  },
  {
    name: 'portrait',
    icon: 'material-portrait',
  },
  {
    name: 'remove_red_eye',
    icon: 'material-remove_red_eye',
  },
  {
    name: 'rotate_90_degrees_ccw',
    icon: 'material-rotate_90_degrees_ccw',
  },
  {
    name: 'rotate_left',
    icon: 'material-rotate_left',
  },
  {
    name: 'rotate_right',
    icon: 'material-rotate_right',
  },
  {
    name: 'slideshow',
    icon: 'material-slideshow',
  },
  {
    name: 'straighten',
    icon: 'material-straighten',
  },
  {
    name: 'style',
    icon: 'material-style',
  },
  {
    name: 'switch_camera',
    icon: 'material-switch_camera',
  },
  {
    name: 'switch_video',
    icon: 'material-switch_video',
  },
  {
    name: 'texture',
    icon: 'material-texture',
  },
  {
    name: 'timelapse',
    icon: 'material-timelapse',
  },
  {
    name: 'timer_10',
    icon: 'material-timer_10',
  },
  {
    name: 'timer_3',
    icon: 'material-timer_3',
  },
  {
    name: 'timer',
    icon: 'material-timer',
  },
  {
    name: 'timer_off',
    icon: 'material-timer_off',
  },
  {
    name: 'tonality',
    icon: 'material-tonality',
  },
  {
    name: 'transform',
    icon: 'material-transform',
  },
  {
    name: 'tune',
    icon: 'material-tune',
  },
  {
    name: 'view_comfy',
    icon: 'material-view_comfy',
  },
  {
    name: 'view_compact',
    icon: 'material-view_compact',
  },
  {
    name: 'wb_auto',
    icon: 'material-wb_auto',
  },
  {
    name: 'wb_cloudy',
    icon: 'material-wb_cloudy',
  },
  {
    name: 'wb_incandescent',
    icon: 'material-wb_incandescent',
  },
  {
    name: 'wb_sunny',
    icon: 'material-wb_sunny',
  },
  {
    name: 'collections_bookmark',
    icon: 'material-collections_bookmark',
  },
  {
    name: 'photo_size_select_actual',
    icon: 'material-photo_size_select_actual',
  },
  {
    name: 'photo_size_select_large',
    icon: 'material-photo_size_select_large',
  },
  {
    name: 'photo_size_select_small',
    icon: 'material-photo_size_select_small',
  },
  {
    name: 'vignette',
    icon: 'material-vignette',
  },
  {
    name: 'wb_iridescent',
    icon: 'material-wb_iridescent',
  },
  {
    name: 'crop_rotate',
    icon: 'material-crop_rotate',
  },
  {
    name: 'linked_camera',
    icon: 'material-linked_camera',
  },
  {
    name: 'add_a_photo',
    icon: 'material-add_a_photo',
  },
  {
    name: 'movie_filter',
    icon: 'material-movie_filter',
  },
  {
    name: 'photo_filter',
    icon: 'material-photo_filter',
  },
  {
    name: 'burst_mode',
    icon: 'material-burst_mode',
  },
  {
    name: 'shutter_speed',
    icon: 'material-shutter_speed',
  },
  {
    name: 'add_photo_alternate',
    icon: 'material-add_photo_alternate',
  },
  {
    name: 'image_search',
    icon: 'material-image_search',
  },
  {
    name: 'music_off',
    icon: 'material-music_off',
  },
  {
    name: 'beenhere',
    icon: 'material-beenhere',
  },
  {
    name: 'directions',
    icon: 'material-directions',
  },
  {
    name: 'directions_bike',
    icon: 'material-directions_bike',
  },
  {
    name: 'directions_bus',
    icon: 'material-directions_bus',
  },
  {
    name: 'directions_car',
    icon: 'material-directions_car',
  },
  {
    name: 'directions_ferry',
    icon: 'material-directions_ferry',
  },
  {
    name: 'directions_subway',
    icon: 'material-directions_subway',
  },
  {
    name: 'directions_train',
    icon: 'material-directions_train',
  },
  {
    name: 'directions_walk',
    icon: 'material-directions_walk',
  },
  {
    name: 'hotel1',
    icon: 'material-hotel1',
  },
  {
    name: 'layers',
    icon: 'material-layers',
  },
  {
    name: 'layers_clear',
    icon: 'material-layers_clear',
  },
  {
    name: 'local_atm',
    icon: 'material-local_atm',
  },
  {
    name: 'local_attraction',
    icon: 'material-local_attraction',
  },
  {
    name: 'local_bar',
    icon: 'material-local_bar',
  },
  {
    name: 'local_cafe',
    icon: 'material-local_cafe',
  },
  {
    name: 'local_car_wash',
    icon: 'material-local_car_wash',
  },
  {
    name: 'local_convenience_store',
    icon: 'material-local_convenience_store',
  },
  {
    name: 'local_drink',
    icon: 'material-local_drink',
  },
  {
    name: 'local_florist',
    icon: 'material-local_florist',
  },
  {
    name: 'local_gas_station',
    icon: 'material-local_gas_station',
  },
  {
    name: 'local_grocery_store',
    icon: 'material-local_grocery_store',
  },
  {
    name: 'local_hospital',
    icon: 'material-local_hospital',
  },
  {
    name: 'local_laundry_service',
    icon: 'material-local_laundry_service',
  },
  {
    name: 'local_library',
    icon: 'material-local_library',
  },
  {
    name: 'local_mall',
    icon: 'material-local_mall',
  },
  {
    name: 'local_movies',
    icon: 'material-local_movies',
  },
  {
    name: 'local_offer',
    icon: 'material-local_offer',
  },
  {
    name: 'local_parking',
    icon: 'material-local_parking',
  },
  {
    name: 'local_pharmacy',
    icon: 'material-local_pharmacy',
  },
  {
    name: 'local_pizza',
    icon: 'material-local_pizza',
  },
  {
    name: 'local_printshop',
    icon: 'material-local_printshop',
  },
  {
    name: 'local_restaurant',
    icon: 'material-local_restaurant',
  },
  {
    name: 'local_shipping',
    icon: 'material-local_shipping',
  },
  {
    name: 'local_taxi',
    icon: 'material-local_taxi',
  },
  {
    name: 'location_history',
    icon: 'material-location_history',
  },
  {
    name: 'map1',
    icon: 'material-map1',
  },
  {
    name: 'navigation',
    icon: 'material-navigation',
  },
  {
    name: 'pin_drop',
    icon: 'material-pin_drop',
  },
  {
    name: 'rate_review',
    icon: 'material-rate_review',
  },
  {
    name: 'satellite',
    icon: 'material-satellite',
  },
  {
    name: 'store_mall_directory',
    icon: 'material-store_mall_directory',
  },
  {
    name: 'traffic',
    icon: 'material-traffic',
  },
  {
    name: 'directions_run',
    icon: 'material-directions_run',
  },
  {
    name: 'add_location',
    icon: 'material-add_location',
  },
  {
    name: 'edit_location',
    icon: 'material-edit_location',
  },
  {
    name: 'near_me',
    icon: 'material-near_me',
  },
  {
    name: 'person_pin_circle',
    icon: 'material-person_pin_circle',
  },
  {
    name: 'zoom_out_map',
    icon: 'material-zoom_out_map',
  },
  {
    name: 'restaurant',
    icon: 'material-restaurant',
  },
  {
    name: 'ev_station',
    icon: 'material-ev_station',
  },
  {
    name: 'streetview',
    icon: 'material-streetview',
  },
  {
    name: 'subway1',
    icon: 'material-subway1',
  },
  {
    name: 'train1',
    icon: 'material-train1',
  },
  {
    name: 'tram',
    icon: 'material-tram',
  },
  {
    name: 'transfer_within_a_station',
    icon: 'material-transfer_within_a_station',
  },
  {
    name: 'atm',
    icon: 'material-atm',
  },
  {
    name: 'category',
    icon: 'material-category',
  },
  {
    name: 'not_listed_location',
    icon: 'material-not_listed_location',
  },
  {
    name: 'departure_board',
    icon: 'material-departure_board',
  },
  {
    name: '360',
    icon: 'material-360',
  },
  {
    name: 'edit_attributes',
    icon: 'material-edit_attributes',
  },
  {
    name: 'transit_enterexit',
    icon: 'material-transit_enterexit',
  },
  {
    name: 'fastfood',
    icon: 'material-fastfood',
  },
  {
    name: 'trip_origin',
    icon: 'material-trip_origin',
  },
  {
    name: 'compass_calibration',
    icon: 'material-compass_calibration',
  },
  {
    name: 'money1',
    icon: 'material-money1',
  },
  {
    name: 'apps',
    icon: 'material-apps',
  },
  {
    name: 'arrow_back',
    icon: 'material-arrow_back',
  },
  {
    name: 'arrow_drop_down',
    icon: 'material-arrow_drop_down',
  },
  {
    name: 'arrow_drop_down_circle',
    icon: 'material-arrow_drop_down_circle',
  },
  {
    name: 'arrow_drop_up',
    icon: 'material-arrow_drop_up',
  },
  {
    name: 'arrow_forward',
    icon: 'material-arrow_forward',
  },
  {
    name: 'cancel',
    icon: 'material-cancel',
  },
  {
    name: 'check1',
    icon: 'material-check1',
  },
  {
    name: 'expand_less',
    icon: 'material-expand_less',
  },
  {
    name: 'expand_more',
    icon: 'material-expand_more',
  },
  {
    name: 'fullscreen',
    icon: 'material-fullscreen',
  },
  {
    name: 'fullscreen_exit',
    icon: 'material-fullscreen_exit',
  },
  {
    name: 'menu',
    icon: 'material-menu',
  },
  {
    name: 'keyboard_control',
    icon: 'material-keyboard_control',
  },
  {
    name: 'more_vert',
    icon: 'material-more_vert',
  },
  {
    name: 'refresh1',
    icon: 'material-refresh1',
  },
  {
    name: 'unfold_less',
    icon: 'material-unfold_less',
  },
  {
    name: 'unfold_more',
    icon: 'material-unfold_more',
  },
  {
    name: 'arrow_upward',
    icon: 'material-arrow_upward',
  },
  {
    name: 'subdirectory_arrow_left',
    icon: 'material-subdirectory_arrow_left',
  },
  {
    name: 'subdirectory_arrow_right',
    icon: 'material-subdirectory_arrow_right',
  },
  {
    name: 'arrow_downward',
    icon: 'material-arrow_downward',
  },
  {
    name: 'first_page',
    icon: 'material-first_page',
  },
  {
    name: 'last_page',
    icon: 'material-last_page',
  },
  {
    name: 'arrow_left',
    icon: 'material-arrow_left',
  },
  {
    name: 'arrow_right',
    icon: 'material-arrow_right',
  },
  {
    name: 'arrow_back_ios',
    icon: 'material-arrow_back_ios',
  },
  {
    name: 'arrow_forward_ios',
    icon: 'material-arrow_forward_ios',
  },
  {
    name: 'adb',
    icon: 'material-adb',
  },
  {
    name: 'disc_full',
    icon: 'material-disc_full',
  },
  {
    name: 'do_not_disturb_alt',
    icon: 'material-do_not_disturb_alt',
  },
  {
    name: 'drive_eta',
    icon: 'material-drive_eta',
  },
  {
    name: 'event_available',
    icon: 'material-event_available',
  },
  {
    name: 'event_busy',
    icon: 'material-event_busy',
  },
  {
    name: 'event_note',
    icon: 'material-event_note',
  },
  {
    name: 'folder_special',
    icon: 'material-folder_special',
  },
  {
    name: 'mms',
    icon: 'material-mms',
  },
  {
    name: 'more',
    icon: 'material-more',
  },
  {
    name: 'network_locked',
    icon: 'material-network_locked',
  },
  {
    name: 'phone_bluetooth_speaker',
    icon: 'material-phone_bluetooth_speaker',
  },
  {
    name: 'phone_forwarded',
    icon: 'material-phone_forwarded',
  },
  {
    name: 'phone_in_talk',
    icon: 'material-phone_in_talk',
  },
  {
    name: 'phone_locked',
    icon: 'material-phone_locked',
  },
  {
    name: 'phone_missed',
    icon: 'material-phone_missed',
  },
  {
    name: 'phone_paused',
    icon: 'material-phone_paused',
  },
  {
    name: 'sim_card_alert',
    icon: 'material-sim_card_alert',
  },
  {
    name: 'sms_failed',
    icon: 'material-sms_failed',
  },
  {
    name: 'sync_disabled',
    icon: 'material-sync_disabled',
  },
  {
    name: 'sync_problem',
    icon: 'material-sync_problem',
  },
  {
    name: 'system_update',
    icon: 'material-system_update',
  },
  {
    name: 'tap_and_play',
    icon: 'material-tap_and_play',
  },
  {
    name: 'vibration',
    icon: 'material-vibration',
  },
  {
    name: 'voice_chat',
    icon: 'material-voice_chat',
  },
  {
    name: 'vpn_lock',
    icon: 'material-vpn_lock',
  },
  {
    name: 'airline_seat_flat',
    icon: 'material-airline_seat_flat',
  },
  {
    name: 'airline_seat_flat_angled',
    icon: 'material-airline_seat_flat_angled',
  },
  {
    name: 'airline_seat_individual_suite',
    icon: 'material-airline_seat_individual_suite',
  },
  {
    name: 'airline_seat_legroom_extra',
    icon: 'material-airline_seat_legroom_extra',
  },
  {
    name: 'airline_seat_legroom_normal',
    icon: 'material-airline_seat_legroom_normal',
  },
  {
    name: 'airline_seat_legroom_reduced',
    icon: 'material-airline_seat_legroom_reduced',
  },
  {
    name: 'airline_seat_recline_extra',
    icon: 'material-airline_seat_recline_extra',
  },
  {
    name: 'airline_seat_recline_normal',
    icon: 'material-airline_seat_recline_normal',
  },
  {
    name: 'confirmation_number',
    icon: 'material-confirmation_number',
  },
  {
    name: 'live_tv',
    icon: 'material-live_tv',
  },
  {
    name: 'ondemand_video',
    icon: 'material-ondemand_video',
  },
  {
    name: 'personal_video',
    icon: 'material-personal_video',
  },
  {
    name: 'power',
    icon: 'material-power',
  },
  {
    name: 'wc',
    icon: 'material-wc',
  },
  {
    name: 'wifi1',
    icon: 'material-wifi1',
  },
  {
    name: 'enhanced_encryption',
    icon: 'material-enhanced_encryption',
  },
  {
    name: 'network_check',
    icon: 'material-network_check',
  },
  {
    name: 'no_encryption',
    icon: 'material-no_encryption',
  },
  {
    name: 'rv_hookup',
    icon: 'material-rv_hookup',
  },
  {
    name: 'do_not_disturb_off',
    icon: 'material-do_not_disturb_off',
  },
  {
    name: 'priority_high',
    icon: 'material-priority_high',
  },
  {
    name: 'power_off',
    icon: 'material-power_off',
  },
  {
    name: 'tv_off',
    icon: 'material-tv_off',
  },
  {
    name: 'wifi_off',
    icon: 'material-wifi_off',
  },
  {
    name: 'phone_callback',
    icon: 'material-phone_callback',
  },
  {
    name: 'pie_chart',
    icon: 'material-pie_chart',
  },
  {
    name: 'pie_chart_outlined',
    icon: 'material-pie_chart_outlined',
  },
  {
    name: 'bubble_chart',
    icon: 'material-bubble_chart',
  },
  {
    name: 'multiline_chart',
    icon: 'material-multiline_chart',
  },
  {
    name: 'show_chart',
    icon: 'material-show_chart',
  },
  {
    name: 'cake',
    icon: 'material-cake',
  },
  {
    name: 'group1',
    icon: 'material-group1',
  },
  {
    name: 'group_add',
    icon: 'material-group_add',
  },
  {
    name: 'location_city',
    icon: 'material-location_city',
  },
  {
    name: 'mood_bad',
    icon: 'material-mood_bad',
  },
  {
    name: 'notifications',
    icon: 'material-notifications',
  },
  {
    name: 'notifications_none',
    icon: 'material-notifications_none',
  },
  {
    name: 'notifications_off',
    icon: 'material-notifications_off',
  },
  {
    name: 'notifications_on',
    icon: 'material-notifications_on',
  },
  {
    name: 'notifications_paused',
    icon: 'material-notifications_paused',
  },
  {
    name: 'pages',
    icon: 'material-pages',
  },
  {
    name: 'party_mode',
    icon: 'material-party_mode',
  },
  {
    name: 'people_outline',
    icon: 'material-people_outline',
  },
  {
    name: 'person',
    icon: 'material-person',
  },
  {
    name: 'person_add',
    icon: 'material-person_add',
  },
  {
    name: 'person_outline',
    icon: 'material-person_outline',
  },
  {
    name: 'plus_one',
    icon: 'material-plus_one',
  },
  {
    name: 'public',
    icon: 'material-public',
  },
  {
    name: 'school',
    icon: 'material-school',
  },
  {
    name: 'share1',
    icon: 'material-share1',
  },
  {
    name: 'whatshot',
    icon: 'material-whatshot',
  },
  {
    name: 'sentiment_dissatisfied',
    icon: 'material-sentiment_dissatisfied',
  },
  {
    name: 'sentiment_neutral',
    icon: 'material-sentiment_neutral',
  },
  {
    name: 'sentiment_satisfied',
    icon: 'material-sentiment_satisfied',
  },
  {
    name: 'sentiment_very_dissatisfied',
    icon: 'material-sentiment_very_dissatisfied',
  },
  {
    name: 'sentiment_very_satisfied',
    icon: 'material-sentiment_very_satisfied',
  },
  {
    name: 'thumb_down_alt',
    icon: 'material-thumb_down_alt',
  },
  {
    name: 'thumb_up_alt',
    icon: 'material-thumb_up_alt',
  },
  {
    name: 'check_box',
    icon: 'material-check_box',
  },
  {
    name: 'check_box_outline_blank',
    icon: 'material-check_box_outline_blank',
  },
  {
    name: 'radio_button_on',
    icon: 'material-radio_button_on',
  },
  {
    name: 'star1',
    icon: 'material-star1',
  },
  {
    name: 'star_half',
    icon: 'material-star_half',
  },
  {
    name: 'star_outline',
    icon: 'material-star_outline',
  },
  {
    name: '3d_rotation',
    icon: 'material-3d_rotation',
  },
  {
    name: 'accessibility',
    icon: 'material-accessibility',
  },
  {
    name: 'account_balance',
    icon: 'material-account_balance',
  },
  {
    name: 'account_balance_wallet',
    icon: 'material-account_balance_wallet',
  },
  {
    name: 'account_box',
    icon: 'material-account_box',
  },
  {
    name: 'account_circle',
    icon: 'material-account_circle',
  },
  {
    name: 'add_shopping_cart',
    icon: 'material-add_shopping_cart',
  },
  {
    name: 'alarm_off',
    icon: 'material-alarm_off',
  },
  {
    name: 'alarm_on',
    icon: 'material-alarm_on',
  },
  {
    name: 'android1',
    icon: 'material-android1',
  },
  {
    name: 'announcement',
    icon: 'material-announcement',
  },
  {
    name: 'aspect_ratio',
    icon: 'material-aspect_ratio',
  },
  {
    name: 'assignment',
    icon: 'material-assignment',
  },
  {
    name: 'assignment_ind',
    icon: 'material-assignment_ind',
  },
  {
    name: 'assignment_late',
    icon: 'material-assignment_late',
  },
  {
    name: 'assignment_return',
    icon: 'material-assignment_return',
  },
  {
    name: 'assignment_returned',
    icon: 'material-assignment_returned',
  },
  {
    name: 'assignment_turned_in',
    icon: 'material-assignment_turned_in',
  },
  {
    name: 'autorenew',
    icon: 'material-autorenew',
  },
  {
    name: 'book1',
    icon: 'material-book1',
  },
  {
    name: 'bookmark1',
    icon: 'material-bookmark1',
  },
  {
    name: 'bookmark_outline',
    icon: 'material-bookmark_outline',
  },
  {
    name: 'bug_report',
    icon: 'material-bug_report',
  },
  {
    name: 'build',
    icon: 'material-build',
  },
  {
    name: 'cached',
    icon: 'material-cached',
  },
  {
    name: 'change_history',
    icon: 'material-change_history',
  },
  {
    name: 'check_circle',
    icon: 'material-check_circle',
  },
  {
    name: 'chrome_reader_mode',
    icon: 'material-chrome_reader_mode',
  },
  {
    name: 'code1',
    icon: 'material-code1',
  },
  {
    name: 'credit_card',
    icon: 'material-credit_card',
  },
  {
    name: 'dashboard1',
    icon: 'material-dashboard1',
  },
  {
    name: 'delete',
    icon: 'material-delete',
  },
  {
    name: 'description',
    icon: 'material-description',
  },
  {
    name: 'dns',
    icon: 'material-dns',
  },
  {
    name: 'done',
    icon: 'material-done',
  },
  {
    name: 'done_all',
    icon: 'material-done_all',
  },
  {
    name: 'exit_to_app',
    icon: 'material-exit_to_app',
  },
  {
    name: 'explore',
    icon: 'material-explore',
  },
  {
    name: 'extension',
    icon: 'material-extension',
  },
  {
    name: 'face',
    icon: 'material-face',
  },
  {
    name: 'favorite',
    icon: 'material-favorite',
  },
  {
    name: 'favorite_outline',
    icon: 'material-favorite_outline',
  },
  {
    name: 'find_in_page',
    icon: 'material-find_in_page',
  },
  {
    name: 'find_replace',
    icon: 'material-find_replace',
  },
  {
    name: 'flip_to_back',
    icon: 'material-flip_to_back',
  },
  {
    name: 'flip_to_front',
    icon: 'material-flip_to_front',
  },
  {
    name: 'group_work',
    icon: 'material-group_work',
  },
  {
    name: 'help',
    icon: 'material-help',
  },
  {
    name: 'highlight_remove',
    icon: 'material-highlight_remove',
  },
  {
    name: 'history1',
    icon: 'material-history1',
  },
  {
    name: 'home1',
    icon: 'material-home1',
  },
  {
    name: 'hourglass_empty',
    icon: 'material-hourglass_empty',
  },
  {
    name: 'hourglass_full',
    icon: 'material-hourglass_full',
  },
  {
    name: 'https',
    icon: 'material-https',
  },
  {
    name: 'info1',
    icon: 'material-info1',
  },
  {
    name: 'info_outline',
    icon: 'material-info_outline',
  },
  {
    name: 'input',
    icon: 'material-input',
  },
  {
    name: 'invert_colors_on',
    icon: 'material-invert_colors_on',
  },
  {
    name: 'label',
    icon: 'material-label',
  },
  {
    name: 'label_outline',
    icon: 'material-label_outline',
  },
  {
    name: 'language1',
    icon: 'material-language1',
  },
  {
    name: 'launch',
    icon: 'material-launch',
  },
  {
    name: 'list1',
    icon: 'material-list1',
  },
  {
    name: 'lock_open',
    icon: 'material-lock_open',
  },
  {
    name: 'lock_outline',
    icon: 'material-lock_outline',
  },
  {
    name: 'loyalty',
    icon: 'material-loyalty',
  },
  {
    name: 'markunread_mailbox',
    icon: 'material-markunread_mailbox',
  },
  {
    name: 'note_add',
    icon: 'material-note_add',
  },
  {
    name: 'open_in_browser',
    icon: 'material-open_in_browser',
  },
  {
    name: 'open_with',
    icon: 'material-open_with',
  },
  {
    name: 'pageview',
    icon: 'material-pageview',
  },
  {
    name: 'perm_camera_mic',
    icon: 'material-perm_camera_mic',
  },
  {
    name: 'perm_contact_calendar',
    icon: 'material-perm_contact_calendar',
  },
  {
    name: 'perm_data_setting',
    icon: 'material-perm_data_setting',
  },
  {
    name: 'perm_device_information',
    icon: 'material-perm_device_information',
  },
  {
    name: 'perm_media',
    icon: 'material-perm_media',
  },
  {
    name: 'perm_phone_msg',
    icon: 'material-perm_phone_msg',
  },
  {
    name: 'perm_scan_wifi',
    icon: 'material-perm_scan_wifi',
  },
  {
    name: 'picture_in_picture',
    icon: 'material-picture_in_picture',
  },
  {
    name: 'polymer',
    icon: 'material-polymer',
  },
  {
    name: 'power_settings_new',
    icon: 'material-power_settings_new',
  },
  {
    name: 'receipt',
    icon: 'material-receipt',
  },
  {
    name: 'redeem',
    icon: 'material-redeem',
  },
  {
    name: 'search1',
    icon: 'material-search1',
  },
  {
    name: 'settings',
    icon: 'material-settings',
  },
  {
    name: 'settings_applications',
    icon: 'material-settings_applications',
  },
  {
    name: 'settings_backup_restore',
    icon: 'material-settings_backup_restore',
  },
  {
    name: 'settings_bluetooth',
    icon: 'material-settings_bluetooth',
  },
  {
    name: 'settings_cell',
    icon: 'material-settings_cell',
  },
  {
    name: 'settings_display',
    icon: 'material-settings_display',
  },
  {
    name: 'settings_ethernet',
    icon: 'material-settings_ethernet',
  },
  {
    name: 'settings_input_antenna',
    icon: 'material-settings_input_antenna',
  },
  {
    name: 'settings_input_component',
    icon: 'material-settings_input_component',
  },
  {
    name: 'settings_input_hdmi',
    icon: 'material-settings_input_hdmi',
  },
  {
    name: 'settings_input_svideo',
    icon: 'material-settings_input_svideo',
  },
  {
    name: 'settings_overscan',
    icon: 'material-settings_overscan',
  },
  {
    name: 'settings_phone',
    icon: 'material-settings_phone',
  },
  {
    name: 'settings_power',
    icon: 'material-settings_power',
  },
  {
    name: 'settings_remote',
    icon: 'material-settings_remote',
  },
  {
    name: 'settings_voice',
    icon: 'material-settings_voice',
  },
  {
    name: 'shop',
    icon: 'material-shop',
  },
  {
    name: 'shop_two',
    icon: 'material-shop_two',
  },
  {
    name: 'shopping_basket',
    icon: 'material-shopping_basket',
  },
  {
    name: 'speaker_notes',
    icon: 'material-speaker_notes',
  },
  {
    name: 'spellcheck',
    icon: 'material-spellcheck',
  },
  {
    name: 'stars',
    icon: 'material-stars',
  },
  {
    name: 'subject',
    icon: 'material-subject',
  },
  {
    name: 'supervisor_account',
    icon: 'material-supervisor_account',
  },
  {
    name: 'swap_horiz',
    icon: 'material-swap_horiz',
  },
  {
    name: 'swap_vert',
    icon: 'material-swap_vert',
  },
  {
    name: 'swap_vertical_circle',
    icon: 'material-swap_vertical_circle',
  },
  {
    name: 'system_update_tv',
    icon: 'material-system_update_tv',
  },
  {
    name: 'tab',
    icon: 'material-tab',
  },
  {
    name: 'tab_unselected',
    icon: 'material-tab_unselected',
  },
  {
    name: 'thumb_down',
    icon: 'material-thumb_down',
  },
  {
    name: 'thumb_up',
    icon: 'material-thumb_up',
  },
  {
    name: 'thumbs_up_down',
    icon: 'material-thumbs_up_down',
  },
  {
    name: 'toc',
    icon: 'material-toc',
  },
  {
    name: 'today',
    icon: 'material-today',
  },
  {
    name: 'toll',
    icon: 'material-toll',
  },
  {
    name: 'track_changes',
    icon: 'material-track_changes',
  },
  {
    name: 'translate',
    icon: 'material-translate',
  },
  {
    name: 'trending_down',
    icon: 'material-trending_down',
  },
  {
    name: 'trending_neutral',
    icon: 'material-trending_neutral',
  },
  {
    name: 'trending_up',
    icon: 'material-trending_up',
  },
  {
    name: 'verified_user',
    icon: 'material-verified_user',
  },
  {
    name: 'view_agenda',
    icon: 'material-view_agenda',
  },
  {
    name: 'view_array',
    icon: 'material-view_array',
  },
  {
    name: 'view_carousel',
    icon: 'material-view_carousel',
  },
  {
    name: 'view_column',
    icon: 'material-view_column',
  },
  {
    name: 'view_day',
    icon: 'material-view_day',
  },
  {
    name: 'view_headline',
    icon: 'material-view_headline',
  },
  {
    name: 'view_list',
    icon: 'material-view_list',
  },
  {
    name: 'view_module',
    icon: 'material-view_module',
  },
  {
    name: 'view_quilt',
    icon: 'material-view_quilt',
  },
  {
    name: 'view_stream',
    icon: 'material-view_stream',
  },
  {
    name: 'view_week',
    icon: 'material-view_week',
  },
  {
    name: 'visibility_off',
    icon: 'material-visibility_off',
  },
  {
    name: 'card_membership',
    icon: 'material-card_membership',
  },
  {
    name: 'card_travel',
    icon: 'material-card_travel',
  },
  {
    name: 'work',
    icon: 'material-work',
  },
  {
    name: 'youtube_searched_for',
    icon: 'material-youtube_searched_for',
  },
  {
    name: 'eject1',
    icon: 'material-eject1',
  },
  {
    name: 'camera_enhance',
    icon: 'material-camera_enhance',
  },
  {
    name: 'help_outline',
    icon: 'material-help_outline',
  },
  {
    name: 'reorder1',
    icon: 'material-reorder1',
  },
  {
    name: 'zoom_in',
    icon: 'material-zoom_in',
  },
  {
    name: 'zoom_out',
    icon: 'material-zoom_out',
  },
  {
    name: 'http',
    icon: 'material-http',
  },
  {
    name: 'event_seat',
    icon: 'material-event_seat',
  },
  {
    name: 'flight_land',
    icon: 'material-flight_land',
  },
  {
    name: 'flight_takeoff',
    icon: 'material-flight_takeoff',
  },
  {
    name: 'play_for_work',
    icon: 'material-play_for_work',
  },
  {
    name: 'gif',
    icon: 'material-gif',
  },
  {
    name: 'indeterminate_check_box',
    icon: 'material-indeterminate_check_box',
  },
  {
    name: 'offline_pin',
    icon: 'material-offline_pin',
  },
  {
    name: 'all_out',
    icon: 'material-all_out',
  },
  {
    name: 'copyright1',
    icon: 'material-copyright1',
  },
  {
    name: 'fingerprint',
    icon: 'material-fingerprint',
  },
  {
    name: 'gavel1',
    icon: 'material-gavel1',
  },
  {
    name: 'lightbulb_outline',
    icon: 'material-lightbulb_outline',
  },
  {
    name: 'picture_in_picture_alt',
    icon: 'material-picture_in_picture_alt',
  },
  {
    name: 'important_devices',
    icon: 'material-important_devices',
  },
  {
    name: 'touch_app',
    icon: 'material-touch_app',
  },
  {
    name: 'accessible',
    icon: 'material-accessible',
  },
  {
    name: 'compare_arrows',
    icon: 'material-compare_arrows',
  },
  {
    name: 'date_range',
    icon: 'material-date_range',
  },
  {
    name: 'donut_large',
    icon: 'material-donut_large',
  },
  {
    name: 'donut_small',
    icon: 'material-donut_small',
  },
  {
    name: 'line_style',
    icon: 'material-line_style',
  },
  {
    name: 'line_weight',
    icon: 'material-line_weight',
  },
  {
    name: 'motorcycle1',
    icon: 'material-motorcycle1',
  },
  {
    name: 'opacity',
    icon: 'material-opacity',
  },
  {
    name: 'pets',
    icon: 'material-pets',
  },
  {
    name: 'pregnant_woman',
    icon: 'material-pregnant_woman',
  },
  {
    name: 'record_voice_over',
    icon: 'material-record_voice_over',
  },
  {
    name: 'rounded_corner',
    icon: 'material-rounded_corner',
  },
  {
    name: 'rowing',
    icon: 'material-rowing',
  },
  {
    name: 'timeline',
    icon: 'material-timeline',
  },
  {
    name: 'update',
    icon: 'material-update',
  },
  {
    name: 'watch_later',
    icon: 'material-watch_later',
  },
  {
    name: 'pan_tool',
    icon: 'material-pan_tool',
  },
  {
    name: 'euro_symbol',
    icon: 'material-euro_symbol',
  },
  {
    name: 'g_translate',
    icon: 'material-g_translate',
  },
  {
    name: 'remove_shopping_cart',
    icon: 'material-remove_shopping_cart',
  },
  {
    name: 'restore_page',
    icon: 'material-restore_page',
  },
  {
    name: 'speaker_notes_off',
    icon: 'material-speaker_notes_off',
  },
  {
    name: 'delete_forever',
    icon: 'material-delete_forever',
  },
  {
    name: 'accessibility_new',
    icon: 'material-accessibility_new',
  },
  {
    name: 'check_circle_outline',
    icon: 'material-check_circle_outline',
  },
  {
    name: 'delete_outline',
    icon: 'material-delete_outline',
  },
  {
    name: 'done_outline',
    icon: 'material-done_outline',
  },
  {
    name: 'maximize',
    icon: 'material-maximize',
  },
  {
    name: 'minimize',
    icon: 'material-minimize',
  },
  {
    name: 'offline_bolt',
    icon: 'material-offline_bolt',
  },
  {
    name: 'swap_horizontal_circle',
    icon: 'material-swap_horizontal_circle',
  },
  {
    name: 'accessible_forward',
    icon: 'material-accessible_forward',
  },
  {
    name: 'calendar_today',
    icon: 'material-calendar_today',
  },
  {
    name: 'calendar_view_day',
    icon: 'material-calendar_view_day',
  },
  {
    name: 'label_important',
    icon: 'material-label_important',
  },
  {
    name: 'restore_from_trash',
    icon: 'material-restore_from_trash',
  },
  {
    name: 'supervised_user_circle',
    icon: 'material-supervised_user_circle',
  },
  {
    name: 'text_rotate_up',
    icon: 'material-text_rotate_up',
  },
  {
    name: 'text_rotate_vertical',
    icon: 'material-text_rotate_vertical',
  },
  {
    name: 'text_rotation_angledown',
    icon: 'material-text_rotation_angledown',
  },
  {
    name: 'text_rotation_angleup',
    icon: 'material-text_rotation_angleup',
  },
  {
    name: 'text_rotation_down',
    icon: 'material-text_rotation_down',
  },
  {
    name: 'text_rotation_none',
    icon: 'material-text_rotation_none',
  },
  {
    name: 'commute',
    icon: 'material-commute',
  },
  {
    name: 'arrow_right_alt',
    icon: 'material-arrow_right_alt',
  },
  {
    name: 'work_off',
    icon: 'material-work_off',
  },
  {
    name: 'work_outline',
    icon: 'material-work_outline',
  },
  {
    name: 'drag_indicator',
    icon: 'material-drag_indicator',
  },
  {
    name: 'horizontal_split',
    icon: 'material-horizontal_split',
  },
  {
    name: 'label_important_outline',
    icon: 'material-label_important_outline',
  },
  {
    name: 'vertical_split',
    icon: 'material-vertical_split',
  },
  {
    name: 'voice_over_off',
    icon: 'material-voice_over_off',
  },
  {
    name: 'segment',
    icon: 'material-segment',
  },
  {
    name: 'contact_support',
    icon: 'material-contact_support',
  },
  {
    name: 'compress1',
    icon: 'material-compress1',
  },
  {
    name: 'filter_list_alt',
    icon: 'material-filter_list_alt',
  },
  {
    name: 'expand1',
    icon: 'material-expand1',
  },
  {
    name: 'edit_off',
    icon: 'material-edit_off',
  },
  {
    name: '10k',
    icon: 'material-10k',
  },
  {
    name: '10mp',
    icon: 'material-10mp',
  },
  {
    name: '11mp',
    icon: 'material-11mp',
  },
  {
    name: '12mp',
    icon: 'material-12mp',
  },
  {
    name: '13mp',
    icon: 'material-13mp',
  },
  {
    name: '14mp',
    icon: 'material-14mp',
  },
  {
    name: '15mp',
    icon: 'material-15mp',
  },
  {
    name: '16mp',
    icon: 'material-16mp',
  },
  {
    name: '17mp',
    icon: 'material-17mp',
  },
  {
    name: '18mp',
    icon: 'material-18mp',
  },
  {
    name: '19mp',
    icon: 'material-19mp',
  },
  {
    name: '1k',
    icon: 'material-1k',
  },
  {
    name: '1k_plus',
    icon: 'material-1k_plus',
  },
  {
    name: '20mp',
    icon: 'material-20mp',
  },
  {
    name: '21mp',
    icon: 'material-21mp',
  },
  {
    name: '22mp',
    icon: 'material-22mp',
  },
  {
    name: '23mp',
    icon: 'material-23mp',
  },
  {
    name: '24mp',
    icon: 'material-24mp',
  },
  {
    name: '2k',
    icon: 'material-2k',
  },
  {
    name: '2k_plus',
    icon: 'material-2k_plus',
  },
  {
    name: '2mp',
    icon: 'material-2mp',
  },
  {
    name: '3k',
    icon: 'material-3k',
  },
  {
    name: '3k_plus',
    icon: 'material-3k_plus',
  },
  {
    name: '3mp',
    icon: 'material-3mp',
  },
  {
    name: '4k_plus',
    icon: 'material-4k_plus',
  },
  {
    name: '4mp',
    icon: 'material-4mp',
  },
  {
    name: '5k',
    icon: 'material-5k',
  },
  {
    name: '5k_plus',
    icon: 'material-5k_plus',
  },
  {
    name: '5mp',
    icon: 'material-5mp',
  },
  {
    name: '6k',
    icon: 'material-6k',
  },
  {
    name: '6k_plus',
    icon: 'material-6k_plus',
  },
  {
    name: '6mp',
    icon: 'material-6mp',
  },
  {
    name: '7k',
    icon: 'material-7k',
  },
  {
    name: '7k_plus',
    icon: 'material-7k_plus',
  },
  {
    name: '7mp',
    icon: 'material-7mp',
  },
  {
    name: '8k',
    icon: 'material-8k',
  },
  {
    name: '8k_plus',
    icon: 'material-8k_plus',
  },
  {
    name: '8mp',
    icon: 'material-8mp',
  },
  {
    name: '9k',
    icon: 'material-9k',
  },
  {
    name: '9k_plus',
    icon: 'material-9k_plus',
  },
  {
    name: '9mp',
    icon: 'material-9mp',
  },
  {
    name: 'account_tree',
    icon: 'material-account_tree',
  },
  {
    name: 'add_chart',
    icon: 'material-add_chart',
  },
  {
    name: 'add_ic_call',
    icon: 'material-add_ic_call',
  },
  {
    name: 'add_moderator',
    icon: 'material-add_moderator',
  },
  {
    name: 'all_inbox',
    icon: 'material-all_inbox',
  },
  {
    name: 'approval',
    icon: 'material-approval',
  },
  {
    name: 'assistant_direction',
    icon: 'material-assistant_direction',
  },
  {
    name: 'assistant_navigation',
    icon: 'material-assistant_navigation',
  },
  {
    name: 'bookmarks',
    icon: 'material-bookmarks',
  },
  {
    name: 'bus_alert',
    icon: 'material-bus_alert',
  },
  {
    name: 'cases',
    icon: 'material-cases',
  },
  {
    name: 'circle_notifications',
    icon: 'material-circle_notifications',
  },
  {
    name: 'closed_caption_off',
    icon: 'material-closed_caption_off',
  },
  {
    name: 'connected_tv',
    icon: 'material-connected_tv',
  },
  {
    name: 'dangerous',
    icon: 'material-dangerous',
  },
  {
    name: 'dashboard_customize',
    icon: 'material-dashboard_customize',
  },
  {
    name: 'desktop_access_disabled',
    icon: 'material-desktop_access_disabled',
  },
  {
    name: 'drive_file_move_outline',
    icon: 'material-drive_file_move_outline',
  },
  {
    name: 'drive_file_rename_outline',
    icon: 'material-drive_file_rename_outline',
  },
  {
    name: 'drive_folder_upload',
    icon: 'material-drive_folder_upload',
  },
  {
    name: 'duo',
    icon: 'material-duo',
  },
  {
    name: 'explore_off',
    icon: 'material-explore_off',
  },
  {
    name: 'file_download_done',
    icon: 'material-file_download_done',
  },
  {
    name: 'rtt',
    icon: 'material-rtt',
  },
  {
    name: 'grid_view',
    icon: 'material-grid_view',
  },
  {
    name: 'hail',
    icon: 'material-hail',
  },
  {
    name: 'home_filled',
    icon: 'material-home_filled',
  },
  {
    name: 'imagesearch_roller',
    icon: 'material-imagesearch_roller',
  },
  {
    name: 'label_off',
    icon: 'material-label_off',
  },
  {
    name: 'library_add_check',
    icon: 'material-library_add_check',
  },
  {
    name: 'logout',
    icon: 'material-logout',
  },
  {
    name: 'margin',
    icon: 'material-margin',
  },
  {
    name: 'mark_as_unread',
    icon: 'material-mark_as_unread',
  },
  {
    name: 'menu_open',
    icon: 'material-menu_open',
  },
  {
    name: 'mp',
    icon: 'material-mp',
  },
  {
    name: 'offline_share',
    icon: 'material-offline_share',
  },
  {
    name: 'padding',
    icon: 'material-padding',
  },
  {
    name: 'panorama_photosphere',
    icon: 'material-panorama_photosphere',
  },
  {
    name: 'panorama_photosphere_select',
    icon: 'material-panorama_photosphere_select',
  },
  {
    name: 'person_add_disabled',
    icon: 'material-person_add_disabled',
  },
  {
    name: 'phone_disabled',
    icon: 'material-phone_disabled',
  },
  {
    name: 'phone_enabled',
    icon: 'material-phone_enabled',
  },
  {
    name: 'pivot_table_chart',
    icon: 'material-pivot_table_chart',
  },
  {
    name: 'print_disabled',
    icon: 'material-print_disabled',
  },
  {
    name: 'railway_alert',
    icon: 'material-railway_alert',
  },
  {
    name: 'recommend',
    icon: 'material-recommend',
  },
  {
    name: 'remove_done',
    icon: 'material-remove_done',
  },
  {
    name: 'remove_moderator',
    icon: 'material-remove_moderator',
  },
  {
    name: 'repeat_on',
    icon: 'material-repeat_on',
  },
  {
    name: 'repeat_one_on',
    icon: 'material-repeat_one_on',
  },
  {
    name: 'replay_circle_filled',
    icon: 'material-replay_circle_filled',
  },
  {
    name: 'reset_tv',
    icon: 'material-reset_tv',
  },
  {
    name: 'sd',
    icon: 'material-sd',
  },
  {
    name: 'shield1',
    icon: 'material-shield1',
  },
  {
    name: 'shuffle_on',
    icon: 'material-shuffle_on',
  },
  {
    name: 'speed',
    icon: 'material-speed',
  },
  {
    name: 'stacked_bar_chart',
    icon: 'material-stacked_bar_chart',
  },
  {
    name: 'stream',
    icon: 'material-stream',
  },
  {
    name: 'swipe',
    icon: 'material-swipe',
  },
  {
    name: 'switch_account',
    icon: 'material-switch_account',
  },
  {
    name: 'tag1',
    icon: 'material-tag1',
  },
  {
    name: 'thumb_down_off_alt',
    icon: 'material-thumb_down_off_alt',
  },
  {
    name: 'thumb_up_off_alt',
    icon: 'material-thumb_up_off_alt',
  },
  {
    name: 'toggle_off',
    icon: 'material-toggle_off',
  },
  {
    name: 'toggle_on',
    icon: 'material-toggle_on',
  },
  {
    name: 'two_wheeler',
    icon: 'material-two_wheeler',
  },
  {
    name: 'upload_file',
    icon: 'material-upload_file',
  },
  {
    name: 'view_in_ar',
    icon: 'material-view_in_ar',
  },
  {
    name: 'waterfall_chart',
    icon: 'material-waterfall_chart',
  },
  {
    name: 'wb_shade',
    icon: 'material-wb_shade',
  },
  {
    name: 'wb_twighlight',
    icon: 'material-wb_twighlight',
  },
  {
    name: 'home_work',
    icon: 'material-home_work',
  },
  {
    name: 'schedule_send',
    icon: 'material-schedule_send',
  },
  {
    name: 'bolt1',
    icon: 'material-bolt1',
  },
  {
    name: 'send_and_archive',
    icon: 'material-send_and_archive',
  },
  {
    name: 'workspaces_filled',
    icon: 'material-workspaces_filled',
  },
  {
    name: 'file_present',
    icon: 'material-file_present',
  },
  {
    name: 'workspaces_outline',
    icon: 'material-workspaces_outline',
  },
  {
    name: 'fit_screen',
    icon: 'material-fit_screen',
  },
  {
    name: 'saved_search',
    icon: 'material-saved_search',
  },
  {
    name: 'storefront',
    icon: 'material-storefront',
  },
  {
    name: 'amp_stories',
    icon: 'material-amp_stories',
  },
  {
    name: 'dynamic_feed',
    icon: 'material-dynamic_feed',
  },
  {
    name: 'euro1',
    icon: 'material-euro1',
  },
  {
    name: 'height',
    icon: 'material-height',
  },
  {
    name: 'policy',
    icon: 'material-policy',
  },
  {
    name: 'sync_alt',
    icon: 'material-sync_alt',
  },
  {
    name: 'menu_book',
    icon: 'material-menu_book',
  },
  {
    name: 'emoji_flags',
    icon: 'material-emoji_flags',
  },
  {
    name: 'emoji_food_beverage',
    icon: 'material-emoji_food_beverage',
  },
  {
    name: 'emoji_nature',
    icon: 'material-emoji_nature',
  },
  {
    name: 'emoji_people',
    icon: 'material-emoji_people',
  },
  {
    name: 'emoji_symbols',
    icon: 'material-emoji_symbols',
  },
  {
    name: 'emoji_transportation',
    icon: 'material-emoji_transportation',
  },
  {
    name: 'post_add',
    icon: 'material-post_add',
  },
  {
    name: 'people_alt',
    icon: 'material-people_alt',
  },
  {
    name: 'emoji_emotions',
    icon: 'material-emoji_emotions',
  },
  {
    name: 'emoji_events',
    icon: 'material-emoji_events',
  },
  {
    name: 'emoji_objects',
    icon: 'material-emoji_objects',
  },
  {
    name: 'sports_basketball',
    icon: 'material-sports_basketball',
  },
  {
    name: 'sports_cricket',
    icon: 'material-sports_cricket',
  },
  {
    name: 'sports_esports',
    icon: 'material-sports_esports',
  },
  {
    name: 'sports_football',
    icon: 'material-sports_football',
  },
  {
    name: 'sports_golf',
    icon: 'material-sports_golf',
  },
  {
    name: 'sports_hockey',
    icon: 'material-sports_hockey',
  },
  {
    name: 'sports_mma',
    icon: 'material-sports_mma',
  },
  {
    name: 'sports_motorsports',
    icon: 'material-sports_motorsports',
  },
  {
    name: 'sports_rugby',
    icon: 'material-sports_rugby',
  },
  {
    name: 'sports_soccer',
    icon: 'material-sports_soccer',
  },
  {
    name: 'sports',
    icon: 'material-sports',
  },
  {
    name: 'sports_volleyball',
    icon: 'material-sports_volleyball',
  },
  {
    name: 'sports_tennis',
    icon: 'material-sports_tennis',
  },
  {
    name: 'sports_handball',
    icon: 'material-sports_handball',
  },
  {
    name: 'sports_kabaddi',
    icon: 'material-sports_kabaddi',
  },
  {
    name: 'eco',
    icon: 'material-eco',
  },
  {
    name: 'museum',
    icon: 'material-museum',
  },
  {
    name: 'flip_camera_android',
    icon: 'material-flip_camera_android',
  },
  {
    name: 'flip_camera_ios',
    icon: 'material-flip_camera_ios',
  },
  {
    name: 'cancel_schedule_send',
    icon: 'material-cancel_schedule_send',
  },
  {
    name: 'apartment',
    icon: 'material-apartment',
  },
  {
    name: 'bathtub1',
    icon: 'material-bathtub1',
  },
  {
    name: 'deck',
    icon: 'material-deck',
  },
  {
    name: 'fireplace',
    icon: 'material-fireplace',
  },
  {
    name: 'house',
    icon: 'material-house',
  },
  {
    name: 'king_bed',
    icon: 'material-king_bed',
  },
  {
    name: 'nights_stay',
    icon: 'material-nights_stay',
  },
  {
    name: 'outdoor_grill',
    icon: 'material-outdoor_grill',
  },
  {
    name: 'single_bed',
    icon: 'material-single_bed',
  },
  {
    name: 'square_foot',
    icon: 'material-square_foot',
  },
  {
    name: 'double_arrow',
    icon: 'material-double_arrow',
  },
  {
    name: 'sports_baseball',
    icon: 'material-sports_baseball',
  },
  {
    name: 'attractions',
    icon: 'material-attractions',
  },
  {
    name: 'bakery_dining',
    icon: 'material-bakery_dining',
  },
  {
    name: 'breakfast_dining',
    icon: 'material-breakfast_dining',
  },
  {
    name: 'car_rental',
    icon: 'material-car_rental',
  },
  {
    name: 'car_repair',
    icon: 'material-car_repair',
  },
  {
    name: 'dinner_dining',
    icon: 'material-dinner_dining',
  },
  {
    name: 'dry_cleaning',
    icon: 'material-dry_cleaning',
  },
  {
    name: 'hardware',
    icon: 'material-hardware',
  },
  {
    name: 'liquor',
    icon: 'material-liquor',
  },
  {
    name: 'lunch_dining',
    icon: 'material-lunch_dining',
  },
  {
    name: 'nightlife',
    icon: 'material-nightlife',
  },
  {
    name: 'park',
    icon: 'material-park',
  },
  {
    name: 'ramen_dining',
    icon: 'material-ramen_dining',
  },
  {
    name: 'celebration',
    icon: 'material-celebration',
  },
  {
    name: 'theater_comedy',
    icon: 'material-theater_comedy',
  },
  {
    name: 'badge',
    icon: 'material-badge',
  },
  {
    name: 'festival',
    icon: 'material-festival',
  },
  {
    name: 'icecream',
    icon: 'material-icecream',
  },
  {
    name: 'volunteer_activism',
    icon: 'material-volunteer_activism',
  },
  {
    name: 'contactless',
    icon: 'material-contactless',
  },
  {
    name: 'delivery_dining',
    icon: 'material-delivery_dining',
  },
  {
    name: 'brunch_dining',
    icon: 'material-brunch_dining',
  },
  {
    name: 'takeout_dining',
    icon: 'material-takeout_dining',
  },
  {
    name: 'ac_unit',
    icon: 'material-ac_unit',
  },
  {
    name: 'airport_shuttle',
    icon: 'material-airport_shuttle',
  },
  {
    name: 'all_inclusive',
    icon: 'material-all_inclusive',
  },
  {
    name: 'beach_access',
    icon: 'material-beach_access',
  },
  {
    name: 'business_center',
    icon: 'material-business_center',
  },
  {
    name: 'casino',
    icon: 'material-casino',
  },
  {
    name: 'child_care',
    icon: 'material-child_care',
  },
  {
    name: 'child_friendly',
    icon: 'material-child_friendly',
  },
  {
    name: 'fitness_center',
    icon: 'material-fitness_center',
  },
  {
    name: 'golf_course',
    icon: 'material-golf_course',
  },
  {
    name: 'hot_tub',
    icon: 'material-hot_tub',
  },
  {
    name: 'kitchen',
    icon: 'material-kitchen',
  },
  {
    name: 'pool',
    icon: 'material-pool',
  },
  {
    name: 'room_service',
    icon: 'material-room_service',
  },
  {
    name: 'smoke_free',
    icon: 'material-smoke_free',
  },
  {
    name: 'smoking_rooms',
    icon: 'material-smoking_rooms',
  },
  {
    name: 'spa',
    icon: 'material-spa',
  },
  {
    name: 'no_meeting_room',
    icon: 'material-no_meeting_room',
  },
  {
    name: 'meeting_room',
    icon: 'material-meeting_room',
  },
  {
    name: 'goat',
    icon: 'material-goat',
  },
  {
    name: '5g',
    icon: 'material-5g',
  },
  {
    name: 'ad_units',
    icon: 'material-ad_units',
  },
  {
    name: 'add_business',
    icon: 'material-add_business',
  },
  {
    name: 'add_location_alt',
    icon: 'material-add_location_alt',
  },
  {
    name: 'add_road',
    icon: 'material-add_road',
  },
  {
    name: 'add_to_drive',
    icon: 'material-add_to_drive',
  },
  {
    name: 'addchart',
    icon: 'material-addchart',
  },
  {
    name: 'admin_panel_settings',
    icon: 'material-admin_panel_settings',
  },
  {
    name: 'agriculture',
    icon: 'material-agriculture',
  },
  {
    name: 'alt_route',
    icon: 'material-alt_route',
  },
  {
    name: 'analytics',
    icon: 'material-analytics',
  },
  {
    name: 'anchor1',
    icon: 'material-anchor1',
  },
  {
    name: 'animation',
    icon: 'material-animation',
  },
  {
    name: 'api',
    icon: 'material-api',
  },
  {
    name: 'app_blocking',
    icon: 'material-app_blocking',
  },
  {
    name: 'app_registration',
    icon: 'material-app_registration',
  },
  {
    name: 'app_settings_alt',
    icon: 'material-app_settings_alt',
  },
  {
    name: 'architecture',
    icon: 'material-architecture',
  },
  {
    name: 'arrow_circle_down',
    icon: 'material-arrow_circle_down',
  },
  {
    name: 'arrow_circle_up',
    icon: 'material-arrow_circle_up',
  },
  {
    name: 'article',
    icon: 'material-article',
  },
  {
    name: 'attach_email',
    icon: 'material-attach_email',
  },
  {
    name: 'auto_awesome',
    icon: 'material-auto_awesome',
  },
  {
    name: 'auto_awesome_mosaic',
    icon: 'material-auto_awesome_mosaic',
  },
  {
    name: 'auto_awesome_motion',
    icon: 'material-auto_awesome_motion',
  },
  {
    name: 'auto_delete',
    icon: 'material-auto_delete',
  },
  {
    name: 'auto_fix_high',
    icon: 'material-auto_fix_high',
  },
  {
    name: 'auto_fix_normal',
    icon: 'material-auto_fix_normal',
  },
  {
    name: 'auto_fix_off',
    icon: 'material-auto_fix_off',
  },
  {
    name: 'auto_stories',
    icon: 'material-auto_stories',
  },
  {
    name: 'baby_changing_station',
    icon: 'material-baby_changing_station',
  },
  {
    name: 'backpack',
    icon: 'material-backpack',
  },
  {
    name: 'backup_table',
    icon: 'material-backup_table',
  },
  {
    name: 'batch_prediction',
    icon: 'material-batch_prediction',
  },
  {
    name: 'bedtime',
    icon: 'material-bedtime',
  },
  {
    name: 'bento',
    icon: 'material-bento',
  },
  {
    name: 'bike_scooter',
    icon: 'material-bike_scooter',
  },
  {
    name: 'biotech',
    icon: 'material-biotech',
  },
  {
    name: 'block_flipped',
    icon: 'material-block_flipped',
  },
  {
    name: 'browser_not_supported',
    icon: 'material-browser_not_supported',
  },
  {
    name: 'build_circle',
    icon: 'material-build_circle',
  },
  {
    name: 'calculate',
    icon: 'material-calculate',
  },
  {
    name: 'campaign',
    icon: 'material-campaign',
  },
  {
    name: 'carpenter',
    icon: 'material-carpenter',
  },
  {
    name: 'cast_for_education',
    icon: 'material-cast_for_education',
  },
  {
    name: 'charging_station',
    icon: 'material-charging_station',
  },
  {
    name: 'checkroom',
    icon: 'material-checkroom',
  },
  {
    name: 'circle1',
    icon: 'material-circle1',
  },
  {
    name: 'cleaning_services',
    icon: 'material-cleaning_services',
  },
  {
    name: 'close_fullscreen',
    icon: 'material-close_fullscreen',
  },
  {
    name: 'closed_caption_disabled',
    icon: 'material-closed_caption_disabled',
  },
  {
    name: 'comment_bank',
    icon: 'material-comment_bank',
  },
  {
    name: 'construction',
    icon: 'material-construction',
  },
  {
    name: 'corporate_fare',
    icon: 'material-corporate_fare',
  },
  {
    name: 'countertops',
    icon: 'material-countertops',
  },
  {
    name: 'design_services',
    icon: 'material-design_services',
  },
  {
    name: 'directions_off',
    icon: 'material-directions_off',
  },
  {
    name: 'dirty_lens',
    icon: 'material-dirty_lens',
  },
  {
    name: 'do_not_step',
    icon: 'material-do_not_step',
  },
  {
    name: 'do_not_touch',
    icon: 'material-do_not_touch',
  },
  {
    name: 'domain_verification',
    icon: 'material-domain_verification',
  },
  {
    name: 'drive_file_move',
    icon: 'material-drive_file_move',
  },
  {
    name: 'dry',
    icon: 'material-dry',
  },
  {
    name: 'dynamic_form',
    icon: 'material-dynamic_form',
  },
  {
    name: 'east',
    icon: 'material-east',
  },
  {
    name: 'edit_road',
    icon: 'material-edit_road',
  },
  {
    name: 'electric_bike',
    icon: 'material-electric_bike',
  },
  {
    name: 'electric_car',
    icon: 'material-electric_car',
  },
  {
    name: 'electric_moped',
    icon: 'material-electric_moped',
  },
  {
    name: 'electric_rickshaw',
    icon: 'material-electric_rickshaw',
  },
  {
    name: 'electric_scooter',
    icon: 'material-electric_scooter',
  },
  {
    name: 'electrical_services',
    icon: 'material-electrical_services',
  },
  {
    name: 'elevator',
    icon: 'material-elevator',
  },
  {
    name: 'engineering',
    icon: 'material-engineering',
  },
  {
    name: 'escalator',
    icon: 'material-escalator',
  },
  {
    name: 'escalator_warning',
    icon: 'material-escalator_warning',
  },
  {
    name: 'face_retouching_natural',
    icon: 'material-face_retouching_natural',
  },
  {
    name: 'fact_check',
    icon: 'material-fact_check',
  },
  {
    name: 'family_restroom',
    icon: 'material-family_restroom',
  },
  {
    name: 'fence',
    icon: 'material-fence',
  },
  {
    name: 'filter_alt',
    icon: 'material-filter_alt',
  },
  {
    name: 'fire_extinguisher',
    icon: 'material-fire_extinguisher',
  },
  {
    name: 'flaky',
    icon: 'material-flaky',
  },
  {
    name: 'food_bank',
    icon: 'material-food_bank',
  },
  {
    name: 'forward_to_inbox',
    icon: 'material-forward_to_inbox',
  },
  {
    name: 'foundation',
    icon: 'material-foundation',
  },
  {
    name: 'grading',
    icon: 'material-grading',
  },
  {
    name: 'grass',
    icon: 'material-grass',
  },
  {
    name: 'handyman',
    icon: 'material-handyman',
  },
  {
    name: 'hdr_enhanced_select',
    icon: 'material-hdr_enhanced_select',
  },
  {
    name: 'hearing_disabled',
    icon: 'material-hearing_disabled',
  },
  {
    name: 'help_center',
    icon: 'material-help_center',
  },
  {
    name: 'highlight_alt',
    icon: 'material-highlight_alt',
  },
  {
    name: 'history_edu',
    icon: 'material-history_edu',
  },
  {
    name: 'history_toggle_off',
    icon: 'material-history_toggle_off',
  },
  {
    name: 'home_repair_service',
    icon: 'material-home_repair_service',
  },
  {
    name: 'horizontal_rule',
    icon: 'material-horizontal_rule',
  },
  {
    name: 'hourglass_bottom',
    icon: 'material-hourglass_bottom',
  },
  {
    name: 'hourglass_disabled',
    icon: 'material-hourglass_disabled',
  },
  {
    name: 'hourglass_top',
    icon: 'material-hourglass_top',
  },
  {
    name: 'house_siding',
    icon: 'material-house_siding',
  },
  {
    name: 'hvac',
    icon: 'material-hvac',
  },
  {
    name: 'image_not_supported',
    icon: 'material-image_not_supported',
  },
  {
    name: 'insights',
    icon: 'material-insights',
  },
  {
    name: 'integration_instructions',
    icon: 'material-integration_instructions',
  },
  {
    name: 'ios_share',
    icon: 'material-ios_share',
  },
  {
    name: 'legend_toggle',
    icon: 'material-legend_toggle',
  },
  {
    name: 'local_fire_department',
    icon: 'material-local_fire_department',
  },
  {
    name: 'local_police',
    icon: 'material-local_police',
  },
  {
    name: 'location_pin',
    icon: 'material-location_pin',
  },
  {
    name: 'lock_clock',
    icon: 'material-lock_clock',
  },
  {
    name: 'login',
    icon: 'material-login',
  },
  {
    name: 'maps_ugc',
    icon: 'material-maps_ugc',
  },
  {
    name: 'mark_chat_read',
    icon: 'material-mark_chat_read',
  },
  {
    name: 'mark_chat_unread',
    icon: 'material-mark_chat_unread',
  },
  {
    name: 'mark_email_read',
    icon: 'material-mark_email_read',
  },
  {
    name: 'mark_email_unread',
    icon: 'material-mark_email_unread',
  },
  {
    name: 'mediation',
    icon: 'material-mediation',
  },
  {
    name: 'medical_services',
    icon: 'material-medical_services',
  },
  {
    name: 'mic_external_off',
    icon: 'material-mic_external_off',
  },
  {
    name: 'mic_external_on',
    icon: 'material-mic_external_on',
  },
  {
    name: 'microwave',
    icon: 'material-microwave',
  },
  {
    name: 'military_tech',
    icon: 'material-military_tech',
  },
  {
    name: 'miscellaneous_services',
    icon: 'material-miscellaneous_services',
  },
  {
    name: 'model_training',
    icon: 'material-model_training',
  },
  {
    name: 'monitor',
    icon: 'material-monitor',
  },
  {
    name: 'moped',
    icon: 'material-moped',
  },
  {
    name: 'more_time',
    icon: 'material-more_time',
  },
  {
    name: 'motion_photos_off',
    icon: 'material-motion_photos_off',
  },
  {
    name: 'motion_photos_on',
    icon: 'material-motion_photos_on',
  },
  {
    name: 'motion_photos_paused',
    icon: 'material-motion_photos_paused',
  },
  {
    name: 'multiple_stop',
    icon: 'material-multiple_stop',
  },
  {
    name: 'nat',
    icon: 'material-nat',
  },
  {
    name: 'near_me_disabled',
    icon: 'material-near_me_disabled',
  },
  {
    name: 'next_plan',
    icon: 'material-next_plan',
  },
  {
    name: 'night_shelter',
    icon: 'material-night_shelter',
  },
  {
    name: 'nightlight_round',
    icon: 'material-nightlight_round',
  },
  {
    name: 'no_cell',
    icon: 'material-no_cell',
  },
  {
    name: 'no_drinks',
    icon: 'material-no_drinks',
  },
  {
    name: 'no_flash',
    icon: 'material-no_flash',
  },
  {
    name: 'no_food',
    icon: 'material-no_food',
  },
  {
    name: 'no_meals',
    icon: 'material-no_meals',
  },
  {
    name: 'no_photography',
    icon: 'material-no_photography',
  },
  {
    name: 'no_stroller',
    icon: 'material-no_stroller',
  },
  {
    name: 'no_transfer',
    icon: 'material-no_transfer',
  },
  {
    name: 'north',
    icon: 'material-north',
  },
  {
    name: 'north_east',
    icon: 'material-north_east',
  },
  {
    name: 'north_west',
    icon: 'material-north_west',
  },
  {
    name: 'not_accessible',
    icon: 'material-not_accessible',
  },
  {
    name: 'not_started',
    icon: 'material-not_started',
  },
  {
    name: 'online_prediction',
    icon: 'material-online_prediction',
  },
  {
    name: 'open_in_full',
    icon: 'material-open_in_full',
  },
  {
    name: 'outbox',
    icon: 'material-outbox',
  },
  {
    name: 'outgoing_mail',
    icon: 'material-outgoing_mail',
  },
  {
    name: 'outlet',
    icon: 'material-outlet',
  },
  {
    name: 'panorama_horizontal_select',
    icon: 'material-panorama_horizontal_select',
  },
  {
    name: 'panorama_vertical_select',
    icon: 'material-panorama_vertical_select',
  },
  {
    name: 'panorama_wide_angle_select',
    icon: 'material-panorama_wide_angle_select',
  },
  {
    name: 'payments',
    icon: 'material-payments',
  },
  {
    name: 'pedal_bike',
    icon: 'material-pedal_bike',
  },
  {
    name: 'pending',
    icon: 'material-pending',
  },
  {
    name: 'pending_actions',
    icon: 'material-pending_actions',
  },
  {
    name: 'person_add_alt',
    icon: 'material-person_add_alt',
  },
  {
    name: 'person_add_alt_1',
    icon: 'material-person_add_alt_1',
  },
  {
    name: 'person_remove',
    icon: 'material-person_remove',
  },
  {
    name: 'person_search',
    icon: 'material-person_search',
  },
  {
    name: 'pest_control',
    icon: 'material-pest_control',
  },
  {
    name: 'pest_control_rodent',
    icon: 'material-pest_control_rodent',
  },
  {
    name: 'photo_camera_back',
    icon: 'material-photo_camera_back',
  },
  {
    name: 'photo_camera_front',
    icon: 'material-photo_camera_front',
  },
  {
    name: 'plagiarism',
    icon: 'material-plagiarism',
  },
  {
    name: 'play_disabled',
    icon: 'material-play_disabled',
  },
  {
    name: 'plumbing',
    icon: 'material-plumbing',
  },
  {
    name: 'point_of_sale',
    icon: 'material-point_of_sale',
  },
  {
    name: 'preview',
    icon: 'material-preview',
  },
  {
    name: 'privacy_tip',
    icon: 'material-privacy_tip',
  },
  {
    name: 'psychology',
    icon: 'material-psychology',
  },
  {
    name: 'public_off',
    icon: 'material-public_off',
  },
  {
    name: 'push_pin',
    icon: 'material-push_pin',
  },
  {
    name: 'qr_code',
    icon: 'material-qr_code',
  },
  {
    name: 'qr_code_scanner',
    icon: 'material-qr_code_scanner',
  },
  {
    name: 'quickreply',
    icon: 'material-quickreply',
  },
  {
    name: 'read_more',
    icon: 'material-read_more',
  },
  {
    name: 'receipt_long',
    icon: 'material-receipt_long',
  },
  {
    name: 'request_quote',
    icon: 'material-request_quote',
  },
  {
    name: 'rice_bowl',
    icon: 'material-rice_bowl',
  },
  {
    name: 'roofing',
    icon: 'material-roofing',
  },
  {
    name: 'room_preferences',
    icon: 'material-room_preferences',
  },
  {
    name: 'rule',
    icon: 'material-rule',
  },
  {
    name: 'rule_folder',
    icon: 'material-rule_folder',
  },
  {
    name: 'run_circle',
    icon: 'material-run_circle',
  },
  {
    name: 'science',
    icon: 'material-science',
  },
  {
    name: 'screen_search_desktop',
    icon: 'material-screen_search_desktop',
  },
  {
    name: 'search_off',
    icon: 'material-search_off',
  },
  {
    name: 'self_improvement',
    icon: 'material-self_improvement',
  },
  {
    name: 'sensor_door',
    icon: 'material-sensor_door',
  },
  {
    name: 'sensor_window',
    icon: 'material-sensor_window',
  },
  {
    name: 'set_meal',
    icon: 'material-set_meal',
  },
  {
    name: 'shopping_bag',
    icon: 'material-shopping_bag',
  },
  {
    name: 'signal_cellular_0_bar',
    icon: 'material-signal_cellular_0_bar',
  },
  {
    name: 'signal_wifi_0_bar',
    icon: 'material-signal_wifi_0_bar',
  },
  {
    name: 'smart_button',
    icon: 'material-smart_button',
  },
  {
    name: 'snippet_folder',
    icon: 'material-snippet_folder',
  },
  {
    name: 'soap',
    icon: 'material-soap',
  },
  {
    name: 'source',
    icon: 'material-source',
  },
  {
    name: 'south',
    icon: 'material-south',
  },
  {
    name: 'south_east',
    icon: 'material-south_east',
  },
  {
    name: 'south_west',
    icon: 'material-south_west',
  },
  {
    name: 'sports_bar',
    icon: 'material-sports_bar',
  },
  {
    name: 'stairs',
    icon: 'material-stairs',
  },
  {
    name: 'star_outline1',
    icon: 'material-star_outline1',
  },
  {
    name: 'star_rate',
    icon: 'material-star_rate',
  },
  {
    name: 'sticky_note_2',
    icon: 'material-sticky_note_2',
  },
  {
    name: 'stop_circle',
    icon: 'material-stop_circle',
  },
  {
    name: 'stroller',
    icon: 'material-stroller',
  },
  {
    name: 'subscript1',
    icon: 'material-subscript1',
  },
  {
    name: 'subtitles_off',
    icon: 'material-subtitles_off',
  },
  {
    name: 'superscript1',
    icon: 'material-superscript1',
  },
  {
    name: 'support1',
    icon: 'material-support1',
  },
  {
    name: 'support_agent',
    icon: 'material-support_agent',
  },
  {
    name: 'switch_left',
    icon: 'material-switch_left',
  },
  {
    name: 'switch_right',
    icon: 'material-switch_right',
  },
  {
    name: 'table_rows',
    icon: 'material-table_rows',
  },
  {
    name: 'table_view',
    icon: 'material-table_view',
  },
  {
    name: 'tapas',
    icon: 'material-tapas',
  },
  {
    name: 'taxi_alert',
    icon: 'material-taxi_alert',
  },
  {
    name: 'text_snippet',
    icon: 'material-text_snippet',
  },
  {
    name: 'tour',
    icon: 'material-tour',
  },
  {
    name: 'tty1',
    icon: 'material-tty1',
  },
  {
    name: 'umbrella1',
    icon: 'material-umbrella1',
  },
  {
    name: 'upgrade',
    icon: 'material-upgrade',
  },
  {
    name: 'verified',
    icon: 'material-verified',
  },
  {
    name: 'video_settings',
    icon: 'material-video_settings',
  },
  {
    name: 'view_sidebar',
    icon: 'material-view_sidebar',
  },
  {
    name: 'wash',
    icon: 'material-wash',
  },
  {
    name: 'water_damage',
    icon: 'material-water_damage',
  },
  {
    name: 'west',
    icon: 'material-west',
  },
  {
    name: 'wheelchair_pickup',
    icon: 'material-wheelchair_pickup',
  },
  {
    name: 'wifi_calling',
    icon: 'material-wifi_calling',
  },
  {
    name: 'wifi_protected_setup',
    icon: 'material-wifi_protected_setup',
  },
  {
    name: 'wine_bar',
    icon: 'material-wine_bar',
  },
  {
    name: 'wrong_location',
    icon: 'material-wrong_location',
  },
  {
    name: 'wysiwyg',
    icon: 'material-wysiwyg',
  },
  {
    name: 'leaderboard',
    icon: 'material-leaderboard',
  },
  {
    name: '6_ft_apart',
    icon: 'material-6_ft_apart',
  },
  {
    name: 'book_online',
    icon: 'material-book_online',
  },
  {
    name: 'clean_hands',
    icon: 'material-clean_hands',
  },
  {
    name: 'connect_without_contact',
    icon: 'material-connect_without_contact',
  },
  {
    name: 'coronavirus',
    icon: 'material-coronavirus',
  },
  {
    name: 'elderly',
    icon: 'material-elderly',
  },
  {
    name: 'follow_the_signs',
    icon: 'material-follow_the_signs',
  },
  {
    name: 'leave_bags_at_home',
    icon: 'material-leave_bags_at_home',
  },
  {
    name: 'masks',
    icon: 'material-masks',
  },
  {
    name: 'reduce_capacity',
    icon: 'material-reduce_capacity',
  },
  {
    name: 'sanitizer',
    icon: 'material-sanitizer',
  },
  {
    name: 'send_to_mobile',
    icon: 'material-send_to_mobile',
  },
  {
    name: 'sick',
    icon: 'material-sick',
  },
  {
    name: 'add_task',
    icon: 'material-add_task',
  },
  {
    name: 'contact_page',
    icon: 'material-contact_page',
  },
  {
    name: 'disabled_by_default',
    icon: 'material-disabled_by_default',
  },
  {
    name: 'facebook1',
    icon: 'material-facebook1',
  },
  {
    name: 'groups',
    icon: 'material-groups',
  },
  {
    name: 'luggage',
    icon: 'material-luggage',
  },
  {
    name: 'no_backpack',
    icon: 'material-no_backpack',
  },
  {
    name: 'no_luggage',
    icon: 'material-no_luggage',
  },
  {
    name: 'outbond',
    icon: 'material-outbond',
  },
  {
    name: 'published_with_changes',
    icon: 'material-published_with_changes',
  },
  {
    name: 'request_page',
    icon: 'material-request_page',
  },
  {
    name: 'stacked_line_chart',
    icon: 'material-stacked_line_chart',
  },
  {
    name: 'unpublished',
    icon: 'material-unpublished',
  },
  {
    name: 'align_horizontal_center',
    icon: 'material-align_horizontal_center',
  },
  {
    name: 'align_horizontal_left',
    icon: 'material-align_horizontal_left',
  },
  {
    name: 'align_horizontal_right',
    icon: 'material-align_horizontal_right',
  },
  {
    name: 'align_vertical_bottom',
    icon: 'material-align_vertical_bottom',
  },
  {
    name: 'align_vertical_center',
    icon: 'material-align_vertical_center',
  },
  {
    name: 'align_vertical_top',
    icon: 'material-align_vertical_top',
  },
  {
    name: 'horizontal_distribute',
    icon: 'material-horizontal_distribute',
  },
  {
    name: 'qr_code_2',
    icon: 'material-qr_code_2',
  },
  {
    name: 'update_disabled',
    icon: 'material-update_disabled',
  },
  {
    name: 'vertical_distribute',
    icon: 'material-vertical_distribute',
  },
];
