
<NotificationLinkActionItem v-slot="{ navigate }" :notification="notification">
  <NotificationRenderer
    v-slot="{ options }"
    :notification="notification"
    icon-variant="author"
    badge-icon="icon-link"
    badge-class="bg-violet-500"
    @click="navigate"
    @mark-as-read="notification => $emit('mark-as-read', notification)"
  >
    <div class="inline-flex justify-center space-x-6">
      <span>
        Action item
        <span class="font-semibold">{{ notification.subject$.title }}</span>
        assigned to
      </span>
      <Avatar
        class="font-semibold"
        :profile="notification.subject$.assignedTo$.profile$"
        font-size="14"
        :size="18"
        show-name
      />
      <span v-if="options.showDocument && notification.document">
        on <NotificationLinkDocument :document="notification.document$" />
      </span>
      <span v-else-if="!notification.document">
        on <NotificationLinkProjectActionItems :notification="notification" />
      </span>
    </div>
  </NotificationRenderer>
</NotificationLinkActionItem>
