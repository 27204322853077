
<div class="screen:flex flex-col relative w-full">
  <!-- ------------------------------------------------ -->
  <!-- DOCUMENT CONTENT ------------------------------- -->
  <!-- ------------------------------------------------ -->
  <DocumentGuestResolver
    v-slot="{ component }"
    :document="document"
  >
    <component
      :is="component"
      v-if="document"
      :document="document"
      :view-context="DOCUMENT_VIEW_DEFAULTS"
    />
  </DocumentGuestResolver>
</div>
