
<span
  class="
    inline-flex items-center justify-center
    flex-none border rounded-100
  "
  :class="[
    `tick-icon-wrapper-${size}`,
    {
      'text-white bg-green-500 border-green-500':
        checked,
      'text-gray-400 bg-white border-gray-300':
        !checked,
    }]"
>
  <span
    class="icon_v2-so_tick"
    :class="`tick-icon-${size}`"
  />
</span>
