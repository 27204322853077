
<div class="space-y-12">
  <h2 class="title-h2 text-center">
    {{ $t('login.password-create') }}
  </h2>
  <p class="text-center">
    {{ $t('login.password-requirement') }}
  </p>
  <FormMessage v-if="formError">
    {{ formError.message }}
  </FormMessage>
  <b-form class="space-y-12" @submit.prevent="formSubmit">
    <b-form-group class="mb-0">
      <FormField
        :v="$v.password"
        required
        :messages="{
          required: $t('login.password-validation-required'),
          minLength: $t('login.password-validation-min-length'),
        }"
      >
        <FormInputPassword
          v-model="formState.password"
          autofocus
          :placeholder="$t('login.password-new-placeholder')"
        />
      </FormField>
    </b-form-group>
    <b-form-group class="mb-0">
      <FormField
        :v="$v.passwordConfirm"
        required
        :messages="{
          required: $t('login.password-confirm-validation-required'),
          sameAs: $t('login.password-confirm-validation-match'),
        }"
      >
        <FormInputPassword
          v-model="formState.passwordConfirm"
          :placeholder="$t('login.password-confirm-placeholder')"
        />
      </FormField>
    </b-form-group>
    <Button
      type="submit"
      size="lg"
      variant="primary"
      :block="true"
      :is-loading="formIsBusy"
      :class="buttonClass"
    >
      {{ $t('login.password-save') }}
    </Button>
  </b-form>
</div>
