
<section class="space-y-12">
  <h2 class="title-h2 text-center mb-16">{{ $t('login.password-recover') }}</h2>
  <FormMessage v-if="formError">
    {{ formError.message }}
  </FormMessage>
  <b-form class="space-y-12" @submit.prevent="formSubmit">
    <b-form-group class="mb-0">
      <FormField
        :v="$v.email"
        required
        :messages="{
          required: $t('login.email-required'),
          email: $t('login.email-not-valid'),
        }"
      >
        <b-input
          autofocus
          :placeholder="$t('login.email')"
          :value="formState.email"
          autocomplete="username"
          class="form-control-lg"
          @input="setEmail"
        />
      </FormField>
    </b-form-group>
    <Button
      type="submit"
      size="lg"
      variant="primary"
      :block="true"
      :is-loading="formIsBusy"
      :class="buttonClass"
    >
      {{ $t('login.password-recover-send-link') }}
    </Button>
  </b-form>
</section>
