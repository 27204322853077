<template>
  <div v-if="showProgress" v-fragment>
    <!-- Sidebar Variant  -->

    <div v-if="variant === 'sidebar'" :class="'card sidebar-wrapper dark:bg-darkGray-800'">
      <!-- ----------------- -->
      <!--  NOT STARTED  -->
      <!-- ----------------- -->
      <div
        v-if="step === STEPS.NOT_STARTED"
        class="flex flex-col items-start justify-center space-y-8 w-full"
      >
        <span class="text-14 text-gray-500">{{ $t('qa-progress.qa-progress') }}</span>
        <ButtonProgress
          :is-loading="false"
          :disabled="false"
          :value="0"
          :max="0"
          @click="handleSubmit('START')"
        >
          <span class="icon-util-resume text-14 w-14 mr-4" />
          {{ $t('qa-progress.get-started') }}
        </ButtonProgress>
      </div>

      <!-- ----------------- -->
      <!--  While Answering  -->
      <!-- ----------------- -->
      <div
        v-if="step === STEPS.IN_PROGRESS"
        :class="[
          'flex flex-row items-start justify-between w-full lg:w-240'
        ]"
      >
        <Confetti v-if="showConfetti" />

        <!-- Content -->
        <div
          :class="[
            'flex lg:flex-col items-center lg:items-start justify-start flex-1 lg:space-y-16 lg:mr-24'
          ]"
        >
          <!-- Copy -->
          <div class="grow">
            <span class="text-14 text-gray-500 dark:text-darkGray-500">{{ $t('qa-progress.your-progress') }}</span>
            <p class="text-gray-700 dark:text-darkGray-400 grow flex-1 text-14 lg:text-18 leading-none">
              {{ $t('qa-progress.completed',{totalAnswered, totalQuestions}) }}
            </p>
          </div>

          <!-- Submit Button -->
          <ButtonProgress
            v-if="showSubmitButton"
            :is-loading="false"
            :disabled="false"
            :value="totalAnswered"
            :max="totalQuestions"
            @click="handleSubmit('SUBMIT')"
          >
            <span class="icon-util-notify text-14 w-14 mr-4" />
            {{ step }}
          </ButtonProgress>
        </div>

        <ProgressCircle
          v-if="!isMobile"
          :diameter="56"
          :completed-steps="totalAnswered"
          :total-steps="totalQuestions"
          :stroke-width="6"
          :inner-stroke-width="6"
          start-color="#3FD255"
          stop-color="#23B839"
          inner-stroke-color="#00000010"
        >
          <span class="text-12 text-gray-600 dark:text-darkGray-400 font-semibold">
            {{ Math.round((totalAnswered/totalQuestions)*100) }}%
          </span>
        </ProgressCircle>
      </div>

      <!-- ------------------------- -->
      <!--  SUBMISSION CONFIRMATION  -->
      <!-- ------------------------- -->
      <div
        v-if="step === STEPS.SUBMITTING"
        class="flex flex-col items-start justify-center space-y-12 w-full lg:w-240"
      >
        <div>
          <span class="text-14 text-gray-500 dark:text-darkGray-500">{{ $t('common.summary') }}</span>
          <p class="text-gray-700 dark:text-darkGray-400">
            {{ $t('qa-progress.notify',{name:organization.name}) }}
          </p>
        </div>

        <div class="flex w-full flex-row items-center justify-between">
          <ButtonProgress
            :is-loading="false"
            :disabled="false"
            @click="handleSubmit('CONFIRM')"
          >
            <span class="icon_v2-so_tick text-14 w-14 mr-4" />
            {{ step }}
          </ButtonProgress>
          <b-button
            variant="slim"
            size="xs"
            class="font-normal text-gray-600 underline hover:underline hover:text-gray-800"
            @click="handleSubmit('DISCARD')"
          >
            {{ $t('common.discard') }}
          </b-button>
        </div>
      </div>


      <!-- ----------------------- -->
      <!-- Submission Success      -->
      <!-- ----------------------- -->
      <div
        v-if="step === STEPS.FINISHED"
        :class="[
          'flex flex-row items-center justify-start w-full lg:w-240',
        ]"
      >
        <Confetti />
        <div
          class="p-12 border-2 border-green-600 rounded-full flex items-center justify-center"
        >
          <span class="icon_v2-so_tick text-24 text-green-600" />
        </div>
        <div class="grow ml-24">
          <h5 class="font-semibold text-gray-800 dark:text-darkGray-300">
            {{ $t('qa-progress.content-submitted') }}
          </h5>
        </div>
      </div>
    </div>

    <!-- Card Variant
      TBD, this would be awesome added on the Brief cards
    -->

    <div
      v-if="variant === 'card'"
      v-b-tooltip.hover.top.v-info.dh0.ds200="'Questionnaire progress'"
      class="
      inline-flex items-center rounded
      font-normal whitespace-nowrap leading-none
      text-gray-700 dark:text-darkGray-400
      h-24 px-6 text-12 relative
      bg-gray-100 bg-opacity-70
      dark:bg-darkGray-900
      overflow-hidden
    "
      @click.stop="$emit('progress-clicked')"
    >
      <span class="inline-block mr-4 text-12 opacity-75 icon-object-QA" />
      <span>{{ totalAnswered }}/{{ totalQuestions }}</span>
      <div class="absolute inset-x-0 bottom-0 h-2">
        <b-progress
          :value="totalAnswered"
          :max="totalQuestions"
          class="flex-1 h-4 rounded-none"
        />
      </div>
    </div>

    <!-- Card Variant
      TBD, this would be awesome added on the Brief cards
    -->

    <div
      v-if="variant === 'inline'"
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Questionnaire progress'"
      class="flex items-center"
      :class="'inline-wrapper'"
    >
      <b-progress
        :value="totalAnswered"
        :max="totalQuestions"
        class="flex-1"
        :class="width"
      />
      <span
        class="block ml-8 leading-none text-gray-600 dark:text-darkGray-400"
      ><span
        class="text-gray-700 font-semibold dark:text-darkGray-300"
      ><span class="text-green-600">{{ totalAnswered }}</span>
        / {{ totalQuestions }}</span>
      </span>
    </div>

    <!-- HEADER -->
    <div
      v-if="variant === 'header'"
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="'Questionnaire progress'"
      :class="'header-wrapper'"
    >
      <b-progress
        :value="totalAnswered"
        :max="totalQuestions"
        class="flex-1 w-96"
      /><span
        class="block ml-8 leading-none text-gray-600"
      ><span
        class="text-gray-700"
      ><span class="text-green-600 font-semibold">{{ totalAnswered }}</span>
        / {{ totalQuestions }}</span></span>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject, watch } from '@vue/composition-api'
import { useUser } from '@/v2/services/users/usersCompositions'
import ButtonProgress from '@/components/ButtonProgress.vue'
import ProgressCircle from '@/components/Blocks/ProgressBar/ProgressCircle.vue'
import Confetti from '@/components/Graphic/Confetti.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'

// const variantMap = ['sidebar', 'footer', 'card']

export default {
  name: 'DocumentViewerProgress',
  components: {
    Confetti,
    ButtonProgress,
    ProgressCircle,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    document: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'w-80',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const totalQuestions = computed(() => props.document.countQA)
    const totalAnswered = computed(() => props.document.countQAAnswered || 0)
    const showProgress = computed(() => Boolean(totalQuestions.value))
    const showStartBtn = ref(false)
    const { translate } = useLocalization()

    const STEPS = {
      NOT_STARTED: translate('qa-progress.start-questionnaire').value,
      IN_PROGRESS: translate('qa-progress.in-progress').value,
      SUBMITTING: translate('common.confirm').value,
      FINISHED: translate('common.finished').value,
    }

    // Use non-reactive values to figure out initial state of the
    // brief questionnaire
    // const completedOnLoad = totalItems.value === answeredItems.value
    const completedOnLoad = props.document.countQA === props.document.countQAAnswered
    const step = completedOnLoad ? ref(STEPS.IN_PROGRESS) : ref(STEPS.NOT_STARTED)

    const showSubmitButton = ref(!completedOnLoad)

    const { isAuthenticated } = useUser();
    // Check if viewer mode (default to false to avoid errors)
    const isViewer = inject('isViewer', false)


    // Use reactive values to figure out if the progress is completed while the
    // user is on the page.
    const showConfetti = computed(() => Boolean(
      totalQuestions.value === totalAnswered.value && !completedOnLoad
    ))
    // Don't show the Confetti on document load if it's already completed

    // Handle submit actions
    const handleSubmit = action => {
      switch (action) {
        case 'START':
          // Scroll to first unanswered question and remove the 'Start' button
          context.emit('first-unanswered')
          step.value = STEPS.IN_PROGRESS
          break;
        case 'SUBMIT':
          // Show confirmation dialog
          step.value = STEPS.SUBMITTING
          break;
        case 'CONFIRM':
          // Submit answers and increement counter
          step.value = STEPS.FINISHED
          context.emit('increment-submit')
          break;
        case 'DISCARD':
          // Return to IN_PROGRESS
          step.value = STEPS.IN_PROGRESS
          break;
        default:
          step.value = STEPS.IN_PROGRESS
          break;
      }
    }

    // Re-activate IN PROGRESS phase if a new question gets answered
    watch(() => totalAnswered.value, () => {
      step.value = STEPS.IN_PROGRESS
      showSubmitButton.value = true
    })

    return {
      totalQuestions,
      totalAnswered,
      showConfetti,
      showProgress,
      completedOnLoad,
      isViewer,
      showStartBtn,
      step,
      handleSubmit,
      STEPS,
      isAuthenticated,
      showSubmitButton,
    }
  },
}
</script>

<style lang="postcss" scoped>
.sidebar-wrapper {
  @apply flex flex-row leading-none;
  /* @apply py-12 px-24 lg:p-24 lg:rounded-lg; */
  @apply p-24 rounded-md;
  @apply relative overflow-hidden text-14;
}

.footer-wrapper {
  @apply hidden md:flex flex-row items-center justify-between mt-24 p-16 ml-auto mr-auto;
  @apply bg-white sm:shadow sm:border sm:rounded-lg sm:border-gray-200 sm:max-w-editor print:border-none;
}

.card-wrapper {
  @apply inline-flex flex-row items-center justify-between;
  @apply lg:px-8 py-4;
  @apply bg-white sm:border sm:rounded-lg sm:border-gray-200;
  @apply dark:bg-darkGray-900 dark:border-none;
  @apply sm:max-w-editor print:border-none;
  @apply text-14;
  @apply self-start;
}

.inline-wrapper {
  @apply flex flex-row items-center justify-start;
  @apply py-4 ml-auto mr-auto;
  @apply sm:max-w-editor print:border-none;
  @apply text-13;
}

.header-wrapper {
  @apply hidden md:flex flex-row items-center justify-between;
  @apply mx-auto leading-none;
  @apply sm:max-w-editor print:border-none;
  @apply text-14;
}

</style>

<style lang="postcss" scoped>

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease;
  transition-delay: 1.5s;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-400px) rotate(-12deg);
  opacity: 0;
}
</style>
