import { propOr } from 'ramda';
import { isFunction, isObject } from 'ramda-adjunct';
import useCurrentOrganization from './useCurrentOrganization';
import useUpgradeDialog from './useUpgradeDialog';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @typedef {import('@typings/organizations').OrganizationData} OrganizationData
*/

const DEFAULT_COUNT = 0;
const DEFAULT_LIMIT = 0;

const counterOrDefault = propOr(DEFAULT_COUNT);

/**
 * @type {Record<string, (counters: OrganizationData['counters']) => number}
 */
const computeCount = {
  limitMembers: c => counterOrDefault('teamMemberCount', c) - 1,
  limitClients: counterOrDefault('clientCount'),
  limitClientContacts: counterOrDefault('clientContactCount'),
  limitProjects: counterOrDefault('projectCount'),
  limitActionItems: counterOrDefault('actionItemCount'),
  limitQuickLinks: counterOrDefault('quickLinkCount'),
  limitEmbeddedApps: counterOrDefault('linkedAppCount'),
  limitPackagedServices: counterOrDefault('docDocumentPackagedServiceCount'),
  limitPages: counterOrDefault('docDocumentPageCount'),
  limitDocuments: c => {
    const docCount = counterOrDefault('docCount', c);
    const rootFolderCount = counterOrDefault('docProjectRootFolderCount', c);
    const publicFolderCount = counterOrDefault('docProjectPublicFolderCount', c);
    const packagedServiceCount = counterOrDefault('docDocumentPackagedServiceCount', c);

    return docCount - (rootFolderCount + publicFolderCount + packagedServiceCount);
  },
};

export const getCountForFlag = (organization, flag) => {
  const fn = computeCount[flag];

  return isFunction(fn) && isObject(organization?.featureFlags)
    ? Math.max(0, fn(organization.counters))
    : DEFAULT_COUNT;
};

const getLimitForFlag = (organization, flag) => {
  const limit = organization?.featureFlags?.[flag];

  // if limit is `null` -> no limit
  // if limit is `undefined` -> limit is DEFAULT_LIMIT
  return limit === null ? Infinity : (limit ?? DEFAULT_LIMIT);
};

const getRemainingForFlag = (organization, flag) => {
  const limit = getLimitForFlag(organization, flag);
  const count = getCountForFlag(organization, flag);

  return Math.max(limit - count, 0);
};

/**
 * @param {string[]} flags Properties of organization.featureFlags
 */
export default function useFeatureLimit() {
  const organization = useCurrentOrganization()
  const upgradeDialog = useUpgradeDialog()

  const showUpgradeDialog = (label/* , remaining, added */) => upgradeDialog({
    message:
        'Your account currently has the maximum number of '
        + `${label} allowed by your current subscription`,
  })


  const checkFeatureLimit = (label, flag, added = 1) => {
    if (added < 1) {
      return false;
    }

    const remaining = getRemainingForFlag(organization.value, flag)
    const isLimit = remaining - added < 0;

    if (isLimit) {
      showUpgradeDialog(label/* , remaining, added */)
      return true
    }

    return false
  }

  return {
    checkFeatureLimit,
  }
}
