<script>
import { defineComponent, computed } from '@vue/composition-api'
import { ROLES } from '@/v2/services/members/membersTypes';
import { useCurrentMember } from '@/v2/services/myMembers/compositions';

export default defineComponent({
  name: 'MemberRole',
  setup(props, context) {
    const { member } = useCurrentMember();

    const roles = computed(
      () => Object.keys(ROLES).reduce((acc, role) => ({
        ...acc,
        [role]: member.value?.role ? role === member.value?.role : false,
      }), { isOwner: member.value?.isOwner ?? false })
    )

    return () => context.slots.default(roles.value);
  },
})
</script>
