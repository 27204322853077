
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="hideOnPrint"
  :content-class="['mx-auto max-w-prose']"
  :config-override="{
    styling: {
      size: 'L',
      paddingTop: 32,
      paddingBottom: 40,
    },
  }"
>
  <template #default="{ styling, textVariant, nodeWidth }">
    <DocumentContentBlockApproval
      :document="document"
      :content-block="contentBlock"
      :styling="styling"
      :text-variant="textVariant"
      :node-width="nodeWidth"
      @update="data => $emit('update', data)"
    />
  </template>
  <template #plugin="{ config, styling, textVariant }">
    <slot name="editor" v-bind="{ config, styling, textVariant }" />
  </template>
</DocumentNodeRenderer>
