<template>
  <section data-intercom-target="Approval Button Block">
    <!-- If not approved -->
    <div v-if="!document.isApproved" class="relative approve portal-paragraph-font">
      <!-- FORM -->
      <b-form
        :class="[
          'w-full',
          isEditor && 'opacity-80'
        ]"
        @submit.prevent="formSubmit"
      >
        <div class="sign">
          <!-- SIGN HERE BADGE -->
          <span
            v-if="!['XXS','XS','S','M'].includes(nodeWidth) && !isEditor"
            :class="[
              'badge',
              isEditor && 'opacity-20',
            ]"
          >
            {{ $t('blocks.approval-button.sign-and-approve') }}
          </span>
          <div
            :class="[
              'flex flex-col md:flex-row md:items-center self-stretch flex-1',
              'space-y-8 md:space-y-0',
            ]"
          >
            <b-form-input
              v-if="!isProfileLoading"
              v-model="formState.name"
              aria-describedby="input-live-feedback"
              :disabled="nameInputDisabled"
              :state="nameValidationState"
              :placeholder="placeholder"
              class="input"
              size="md"
            />
            <b-form-invalid-feedback
              v-if="nameValidationState === false"
              id="input-live-feedback"
              class="error"
            >
              {{ $t('blocks.approval-button.please-type') }}
            </b-form-invalid-feedback>
            <b-button
              type="submit"
              variant="success"
              size="md"
              :class="[isEditor && 'opacity-60']"
            >
              <span class="w-24 mr-4 icon_v2-so_tick text-24"></span>
              {{ $t('blocks.approval-button.label') }}
            </b-button>
          </div>
        </div>
        <div class="mt-8 leading-normal text-center text-gray-600 text-16">
          <OrganizationGet
            :id="document.organization"
            v-slot="{ organization }"
          >
            {{ $t('blocks.approval-button.description') }}
          </OrganizationGet>
        </div>
      </b-form>
      <!-- EDITOR MODE OVERLAY -->
      <div
        v-if="isEditor"
        class="absolute inset-0 flex flex-col justify-end border border-gray-200 border-dashed rounded-md rounded-br-none top preview-overlay"
      >
        <div
          class="relative flex flex-row items-stretch justify-end mt-1 h-26 -mb-28 top-px -right-px"
        >
          <span
            class="flex items-center px-6 font-medium text-gray-900 bg-opacity-50 border-b border-l border-r border-gray-200 border-dashed bg-yellow-50 text-14 leading-0 rounded-br-md rounded-bl-md"
          >
            <span
              class="flex items-center px-4 py-2 mr-4 font-bold leading-none uppercase bg-yellow-400 rounded-md text-gray-1000 text-12"
            >{{ $t('blocks.approval-button.preview') }}</span>
            {{ $t('blocks.approval-button.what-client-sees') }}</span>
        </div>
      </div>
    </div>
    <!-- If approved -->

    <DocApprovalBadge
      v-else
      :document="document"
    />
  </section>
</template>

<script>
import { isNotNil, noop } from 'ramda-extension'
import { reactive, computed, watch, inject } from '@vue/composition-api'
// import { CATEGORY as ELEMENT_CATEGORY } from '@/types/element'
import { required, minLength } from '@vuelidate/validators'
import { useCurrentProfile } from '@/v2/services/profiles/profilesCompositions'
import { useMsgBoxConfirm, useMsgBoxOK } from '@/v2/lib/composition/useMsgBox'
import { getCurrentInstanceOrThrow } from '@/v2/lib/composition/helpers'
import { useRouteParams } from '@/v2/lib/composition/useRouter'
import useForm from '@/v2/lib/composition/useForm'
import OrganizationGet from '@/components/OrganizationGet.vue'
import useLocalization from '@/v2/lib/composition/useLocalization'
import DocApprovalBadge from '@/components/Doc/DocApprovalBadge.vue'

const NOTICE_MESSAGE = `
    This button only works when you share a link to this document with your clients.
    If you want to change the status of the document, please click the "No Status" dropdown from the
    top right corner of this page and select "Approve".
`
export default {
  name: 'DocumentContentBlockApproval',
  components: {
    OrganizationGet,
    DocApprovalBadge,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props) {
    const isEditor = inject('isEditor', false)
    const isViewer = inject('isViewer')
    const isLocked = inject('isLocked')
    const isSandbox = inject('isSandbox')
    const { translate } = useLocalization()

    const vm = getCurrentInstanceOrThrow()
    const { sharingToken } = useRouteParams(['sharingToken'])
    const confirm = useMsgBoxConfirm()
    const notice = useMsgBoxOK()

    const { profile, isLoading: isProfileLoading } = useCurrentProfile()

    const formState = reactive({ name: '' })
    const formSubmitEditor = () => notice({
      title: 'Approval Button',
      message: NOTICE_MESSAGE,
      size: 'md',
    })

    const onSubmit = async () => {
      // this.$container.get('ga').send({
      //   category: ELEMENT_CATEGORY.ElementApproval,
      //   action: 'clickApprovalButton',
      // })

      const ok = await confirm({
        title: translate('blocks.approval-button.aprove-doc', { doc: props.document.title }).value,
        okLabel: translate('common.approve').value,
        cancelLabel: translate('common.cancel').value,
        size: 'md',
        message: translate('blocks.approval-button.confirm-body').value,
      })

      if (ok) {
        // return props.document.patch({
        //   data: { status: 'APPROVED', approvedByName: formState.name },
        //   params: { sharingToken: sharingToken },
        // })
        return vm.$store.dispatch('documents/patch', [
          props.document._id,
          { status: 'APPROVED', approvedByName: formState.name },
          { sharingToken: sharingToken.value },
        ])
      }
    }

    const { formSubmit: formSubmitViewer, $v } = useForm({
      onSubmit,
      state: formState,
      validation: {
        name: {
          required,
          minLength: minLength(2),
        },
      },
    })

    // const revert = () => props.document.patch({ data: { status: 'DRAFT' } })
    const formSubmit = () => {
      if (isSandbox.value) {
        noop()
      } else if (isViewer.value) {
        formSubmitViewer()
      } else {
        // might be redundant
        formSubmitEditor()
      }
    }
    // const formSubmit = formSubmitEditor

    const placeholder = computed(() => (isViewer.value ? translate('blocks.approval-button.placeholder-name').value : 'Client name'))

    // true => valid, false => invalid, null => pristine (not touched yet)
    const nameValidationState = computed(() => ($v.value.name.$dirty ? !$v.value.name.$invalid : null))

    const nameInputDisabled = computed(
      () => isLocked.value
        || isEditor.value
        || (isViewer.value && isNotNil(profile.value))
    )

    watch(
      profile,
      newVal => {
        if (isNotNil(newVal) && isViewer.value && !isLocked.value) {
          formState.name = newVal.fullName
        }
      },
      { immediate: true }
    )

    return {
      profile,
      isProfileLoading,
      $v,
      nameValidationState,
      nameInputDisabled,
      formState,
      formSubmit,
      placeholder,
      // revert,
      isEditor,
      isSandbox,
    }
  },
}
</script>

<style lang="scss" scoped>
.approve {
  @apply flex flex-col justify-center items-center;
}

.approve p {
  @apply text-center;
}

.preview-overlay {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.01),
    rgba(0, 0, 0, 0.01) 20px,
    rgba(0, 0, 0, 0) 20px,
    rgba(0, 0, 0, 0) 40px
  );

  &:hover {
    background: none;
  }
}

.sign {
  @apply flex-1 grow;
  @apply flex flex-col md:flex-row items-center relative p-8 md:p-4;
  @apply lg:text-left border border-gray-200 rounded-lg relative;
  @apply bg-white;
  @apply hover:ring-2 hover:ring-green-300;
}

.input {
  @apply flex-1 text-18;
  @apply mr-0 md:mr-4 ;
  @apply text-gray-900 rounded-none;
  @apply focus:outline-none active:outline-none;
  @apply rounded;
  @apply border-0 border-none focus:ring-0;
  @apply py-16 md:py-0;
  // flex: 1;
  // border: none;
  // margin-right: 4px;
  // padding: 0 12px;
  // font-size: $fs20;
  // color: $gray-900;
  // border-radius: $border-radius;
  &[type='text']:focus,
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba($gray-500, 0.8);
  }

}

.error {
  position: absolute;
  top: -28px;
}


.badge {
  @apply bg-yellow-400 text-gray-900 text-13 self-center;
  @apply font-medium rounded-md absolute;
  @apply hidden md:inline-flex;
  @apply right-full top-14 -translate-y-1/2 mb-0 rounded-tr-none rounded-br-none;
  @apply py-0 pl-6 pr-0 h-24 items-center mr-18 capitalize;
  white-space: nowrap;
}

.badge::after {
  @apply absolute w-12 h-full top-full left-1/2 transform rotate-90 md:rotate-0 -translate-y-8 md:translate-y-0 -translate-x-1/2 md:top-auto md:left-full md:transform-none;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  content: '';
  @apply bg-yellow-400;
}


.head {
  margin-bottom: 20px;
  text-align: center;
}
.approved {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}
</style>
