<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isEmpty"
    :root-class="[
      'node-group-columns-container',
      $props.node.styling.stack && 'stacked-on-mobile'
    ]"
  >
    <section
      v-if="!isEmpty"
      class="w-full flex items-stretch flex-col lg:flex-row"
      :class="$props.node.styling.stack ? 'screen:cols-stacked-on-mobile' : 'cols-on-desktop'"
    >
      <DocumentNode
        v-for="(nodeId, index) in children"
        :key="nodeId"
        :node-id="nodeId"
        :parent="node._id"
        :index="index"
        :is-first="index === 0"
        :is-last="index === children.length - 1"
        :width="`${ratios[index]}%`"
        :style="isMobile && 'width: auto;'"
      />
    </section>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import useNestedComponents from '@/v2/lib/composition/useNestedComponents'
import { useStructureBranch } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentNode from '@/v2/features/document/documentNode/DocumentNode.vue'

export default {
  name: 'DocumentNodeGroupColumnsContainer',
  components: {
    DocumentNodeRenderer,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    useNestedComponents({ DocumentNode })
    const structureBranch = useStructureBranch()
    const { children, isEmpty } = structureBranch(props.node._id)
    const ratios = computed(() => props.node?.styling?.ratio || [])
    const isMobile = computed(() => context.root.$mq === 'sm')

    return {
      children,
      isEmpty,
      ratios,
      isMobile,
    }
  },
}
</script>
