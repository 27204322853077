
<div class="task-info">
  <div v-if="task.createdAt" class="flex items-center gap-4">
    <div>
      <div class="text-10 uppercase">{{ $t('tasks.taskEditor.created') }}</div>
      <LiveFilter
        class="font-semibold"
        filter="timeago"
        :value="task.createdAt"
      />
      <div v-if="task.createdBy" class="flex items-center gap-4">
        <!-- <span>by</span> -->
        <AvatarUser
          class="font-light"
          :user-id="task.createdBy"
          show-avatar
          font-size="13"
          :size="20"
        />
      </div>
    </div>
  </div>
  <div v-if="task.updatedAt" class="flex items-center gap-4">
    <div>
      <div class="text-10 uppercase">{{ $t('tasks.taskEditor.updated') }}</div>
      <LiveFilter
        class="font-semibold"
        filter="timeago"
        :value="task.updatedAt"
      />
      <div v-if="task.updatedBy" class="flex items-center gap-4">
        <!-- <span>by</span> -->
        <AvatarUser
          class="font-light"
          :user-id="task.updatedBy"
          show-avatar
          font-size="13"
          :size="20"
        />
      </div>
    </div>
  </div>
</div>
