
<DocumentLink v-slot="{ navigate }" :document="document">
  <div
    :class="width"
    class="flex flex-col hover:cursor-pointer group"
    @click.stop="navigate"
  >
    <div class="portfolio-image-wrap">
      <img
        v-if="document.imageUrl"
        class="portfolio-image"
        :style="{ backgroundImage: `url(${document.imageUrl})` }"
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
      />
      <span
        v-if="!document.imageUrl"
        class="icon_v2-so_image opacity-20 text-32"
      ></span>
    </div>


    <h3
      class="
        text-14 mb-4 dark:text-darkGray-300
        group-hover:underline"
    >
      {{ document.title }}
    </h3>
    <p
      v-if="document.description && description"
      class="text-11 mb-8 text-gray-500 dark:text-darkGray-400"
    >
      {{ document.description | textEllipsis(70) }}
    </p>
    <DocumentCurrency
      :value="document.cost"
      :document-id="document._id"
      class="text-gray-500 text-16 mt-auto dark:text-darkGray-400"
    />
  </div>
</DocumentLink>
