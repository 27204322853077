
<div
  class="w-full relative h-full"
  :style="widthStyle"
>
  <img
    :style="cssStyle"
    class="h-full w-full"
    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  />
  <slot />
</div>
