<template>
  <div>
    <button
      :id="componentID"
      v-b-tooltip.hover.bottom.v-info.dh0.ds200="tooltip"
      :class="['flex items-center justify-center h-32 text-white relative', className]"
    >
      <slot name="button-content" />
    </button>
    <b-popover
      ref="popover"
      :target="componentID"
      :triggers="triggers"
      :placement="placement"
      :custom-class="`${$style.popover} p-0 mt-12 bg-gray-800`"
      :no-fade="true"
      delay="0"
      boundary="main"
      @show="onPopoverOpen"
    >
      <div
        v-click-outside.capture="
          triggers !== 'click blur'
            ? () => closeOnClickOutside && close()
            : null
        "
        class="p-12"
        @click.stop
      >
        <!-- Check if slot has content, otherwise don't render title -->
        <div
          class="font-semibold text-white text-14"
        >
          <slot name="title" />
        </div>
        <div
          class="w-192"
          :style="{ width: `${width}px` }"
        >
          <slot v-bind="{ close }" />
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
export default {
  name: 'DocumentNodeEditorToolbarPopover',
  props: {
    placement: {
      type: String,
      default: 'bottomleft',
    },
    width: {
      type: Number,
      default: null,
    },
    triggers: {
      type: String,
      default: 'click blur',
    },
    className: {
      type: [String, Object, Array],
      default: null,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  methods: {
    onPopoverOpen() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$root.$emit('bv::hide::popover')
    },
    close() {
      this.$refs.popover.$emit('close')
    },
  },
}
</script>
<style module lang="scss">
.popover {
  &[x-placement^='top'] {
    :global(.arrow) {
      &::after {
        border-top-color: theme('colors.gray.800');
      }
    }
  }

  &[x-placement^='bottom'] {
    :global(.arrow) {
      &::after {
        border-bottom-color: theme('colors.gray.800');
      }
    }
  }

  :global(.popover-body) {
    color: theme('colors.white');
    padding: 0;
  }
}
</style>
