<template>
  <div>
    <!-- <pre>Active Font: {{ activeFont }}</pre> -->
    <div class="relative text-gray-600 focus-within:text-gray-700 mb-8">
      <b-input
        :value="filterFont"
        autofocus
        placeholder="Search fonts"
        size="lg"
        class="pr-32"
        @input="searchFont"
      />
      <span class="icon-magnify text-18 w-18 absolute right-12 top-12 text-gray-600" />
    </div>
    <div class="text-14 text-gray-600 text-right mb-12">
      Our library includes {{ receivedFonts && receivedFonts.length }} fonts from Google Fonts
    </div>
    <LoaderWebFont :families="families" preview>
      <div>
        <div
          v-for="family in families"
          :key="family"
          class="border-b border-gray-200 pb-16 mb-16"
        >
          <p class="text-gray-700 mb-4">
            {{ family }}
          </p>
          <div class="flex justify-between">
            <p
              class="text-24"
              :style="{fontFamily: family }"
            >
              The quick brown fox jumps over the lazy dog.
            </p>
            <b-button variant="primary" @click="$emit('select', family, applyFontOn)">
              Choose
            </b-button>
          </div>
        </div>
      </div>
    </LoaderWebFont>
    <div class="flex justify-center mt-16 mb-32 space-x-16">
      <b-button
        :disabled="Boolean(page===1)"
        variant="primary"
        class="w-128"
        @click="changePage('prev')"
      >
        Previous
      </b-button>
      <b-button
        :disabled="!nextPage"
        variant="primary"
        class="w-128"
        @click="changePage('next')"
      >
        Next
      </b-button>
    </div>
  </div>
</template>

<script>
import { pluck } from 'ramda'
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'
import LoaderWebFont from '@/components/LoaderWebFonts.vue'

const pluckFamily = pluck('family')

export default {
  name: 'BrandingFonts',
  components: {
    LoaderWebFont,
  },
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
    applyFontOn: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const perPage = 16
    const apiKey = import.meta.env.VITE_GOOGLE_FONTS_KEY
    const receivedFonts = ref(null)
    const filterFont = ref('')
    const page = ref(1)

    const fontsLength = receivedFonts.length

    const max = computed(() => page.value * perPage)
    const min = computed(() => (page.value - 1) * perPage)
    const activeFont = computed(() => props.model[props.applyFontOn]
    && props.model[props.applyFontOn])

    const changePage = action => {
      action === 'next' && page.value++
      action === 'prev' && page.value--
    }

    // search
    const filteredFonts = computed(() => receivedFonts.value
      && receivedFonts.value.filter(font => font.family
        .toLowerCase()
        .includes(filterFont.value.toLowerCase())))

    // show a limit no of fonts per page
    const fonts = computed(() => filteredFonts.value
      && filteredFonts.value.filter(
        (font, index) => index >= min.value && index < max.value
      ))
    const families = computed(() => fonts.value && pluckFamily(fonts.value))

    const nextPage = computed(() => fonts.value && fonts.value.length === perPage)

    const searchFont = val => {
      filterFont.value = val
      page.value = 1
    }
    // load the fonts from Google Fonts
    const fetchFonts = () => {
      axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
        .then(({ data }) => {
          receivedFonts.value = data.items.map(el => ({ filterByFamily: el.family.replace(/ /g, '+'), ...el }))
        })
    }
    fetchFonts()

    return {
      page,
      changePage,
      min,
      max,
      receivedFonts,
      fonts,
      filterFont,
      families,
      activeFont,
      fontsLength,
      searchFont,
      nextPage,
    }
  },

}

</script>

<style lang="scss" scoped>

</style>
