<template>
  <FeathersVuexGet
    :id="id"
    v-slot="{ item: document, ...rest }"
    watch="id"
    :edit-scope="editScope"
    :query-when="force || notInStore"
    :params="params"
    service="documents"
  >
    <slot v-bind="{ document, ...rest }" />
  </FeathersVuexGet>
</template>

<script>
import { identity } from 'ramda'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DocumentGet',
  props: {
    id: {
      type: String,
      required: true,
    },
    editScope: {
      type: Function,
      default: identity,
    },
    params: {
      type: Object,
      default: null,
    },
    force: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { Document } = context.root.$FeathersVuex.api
    const notInStore = computed(() => !Document.getFromStore(props.id))

    return {
      notInStore,
    }
  },
})
</script>
