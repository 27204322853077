
<FeathersVuexGet
  :id="id"
  v-slot="{ item: organization, isGetPending }"
  watch="id"
  :query-when="force || notInStore"
  service="organizations"
  v-bind="$attrs"
>
  <slot v-bind="{ organization, isGetPending }" />
</FeathersVuexGet>
