<template>
  <AvatarIcon
    v-if="!authorProfile"
    variant="gray"
    icon="icon-util-person-full"
    :icon-size="iconSize"
    :size="size"
  />
  <Avatar
    v-else
    :profile="authorProfile"
    :show-name="false"
    :font-size="String(fontSize)"
    :size="size"
  />
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api'
import { useNotificationAuthor } from '@/v2/services/notifications/compositions'
import Avatar from '@/components/Blocks/Avatar/Avatar.vue'
import AvatarIcon from '@/components/AvatarIcon.vue'

export default defineComponent({
  name: 'NotificationAvatar',
  components: {
    Avatar,
    AvatarIcon,
  },
  props: {
    notification: {
      type: Object,
      default: null,
    },
    size: {
      type: Number,
      default: 18,
    },
    iconSize: {
      type: Number,
      default: 12,
    },
    fontSize: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const {
      authorProfile,
      authorName,
    } = useNotificationAuthor(toRef(props, 'notification'))

    return {
      authorProfile,
      authorName,
    }
  },
})
</script>
