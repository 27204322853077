import { isNotNilOrEmpty } from 'ramda-adjunct'
import { dissoc } from 'ramda';
import { BaseModel, makeServicePlugin } from '@/v2/services/feathersClientService'
import profilesService, { servicePath } from './profilesService'

class Profile extends BaseModel {
  static modelName = 'Profile'

  get fullName() {
    return [this.firstName, this.lastName]
      .filter(isNotNilOrEmpty)
      .join(' ');
  }

  static setupInstance(data) {
    return dissoc('fullName', data);
  }

  static instanceDefaults() {
    return {
      user: null,
      firstName: '',
      lastName: '',
      role: null,
      profilePhotoUrl: '',
      // fullName: '',
    }
  }
}

export default makeServicePlugin({
  Model: Profile,
  service: profilesService,
  servicePath,
})
