<template>
  <!-- <portal :to="`context-menu-node-${node._id}`">
      <div v-fragment>
        <b-dropdown-divider />
        <b-dropdown-item>Heading Action 1</b-dropdown-item>
        <b-dropdown-item>Heading Action 2</b-dropdown-item>
      </div>
    </portal> -->
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isBlankState"
    :root-class="$style['node-heading']"
    :section-class="$style['node-heading-section']"
    :config-override="{
      stylingToolbar: {
        features: {
          headingSizes: true,
          textColor: true,
        },
      },
      styling: {
        size: 'S',
      },
    }"
  >
    <template #default="{ styling, textVariant }">
      <DocumentContentBlockHeading
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        @update="data => $emit('update', data)"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot
        name="editor"
        v-bind="{ config, styling, textVariant }"
      />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { computed } from '@vue/composition-api'
import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockHeading from './DocumentContentBlockHeading.vue'

export default {
  name: 'DocumentNodeHeading',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockHeading,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isBlankState = computed(() => pathIsNilOrEmpty(['contentBlock', 'heading'], props))

    return {
      isBlankState,
    }
  },
}
</script>
<style lang="scss" module>
@media only screen and (max-width: 768px) {
  .node-heading {

  }

  .node-heading-section {

  }
}
</style>
