<template>
  <b-badge :variant="variant"><slot /></b-badge>
</template>
<script>
export default {
  name: 'Badge',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
