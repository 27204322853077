<template>
  <b-dropdown-item
    class="relative group"
    link-class="min-h-0 p-0 transition-all bg-transparent hover:bg-transparent"
    @click.stop="$emit('select')"
  >
    <ListLightItem v-bind="$attrs" />
    <slot />
  </b-dropdown-item>
</template>

<script>
import ListLightItem from '@/components/List/ListLightItem.vue'

export default {
  name: 'ListLightDropdownItem',
  components: {
    ListLightItem,
  },
}
</script>
