<template>
  <div class="flex items-end" :class="className">
    <b-dropdown
      v-if="!disableMenu"
      variant="link"
      left
      :no-caret="noCarret"
      :class="dropdownClass"
      :menu-class="menuClass"
      :toggle-class="[
        toggleClass,
        'flex items-center text-white rounded hover:bg-gray-500 hover:bg-opacity-10 hover:text-white',
      ]"
      class="h-32"
      @shown="$root.$emit('bv::hide::popover')"
    >
      <template slot="button-content">
        <slot name="button" />
      </template>
      <slot />
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'DocumentNodeEditorToolbarMenu',
  props: {
    label: String,
    disableMenu: Boolean,
    noCarret: {
      type: Boolean,
      default: true,
    },
    menuClass: String,
    toggleClass: {
      type: String,
      default: 'p-0 justify-center',
    },
    dropdownClass: String,
    className: {
      type: String,
      default: 'justify-center',
    },
  }
}
</script>
