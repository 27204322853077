
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
>
  <DocumentMasonry v-if="!isEmpty">
    <DocumentNode
      v-for="(nodeId, index) in children"
      :key="nodeId"
      :node-id="nodeId"
      :parent="node._id"
      :index="index"
      :is-first="index === 0"
      :is-last="index === children.length - 1"
      rounded-selection
      grid
    />
  </DocumentMasonry>
  <DocumentNodeGroupPackagedServicePlaceholder v-else />
</DocumentNodeRenderer>
