
<LoaderWebFont :families="[ styling.font, paragraphFont, headingFont]">
  <div
    :class="[
      'block-element-text',
      `text-variant-${textVariant}`,
      `text-${styling.textAlign}`,
      'portal-paragraph-font',
    ]"
    :style="{
      color: styling.textColor,
    }"
  >
    <!-- toolbar -->
    <!-- <DocumentContentBlockTableToolbarPortal
    :popover-target-id="settingsPopoverTargetId"
    :content-block="contentBlock"
    :node-id="nodeId"
    s
    @update="(data, options) => $emit('update', data, options)"
  /> -->

    <!-- input -->
    <section @contextmenu.stop>
      <TableEditor
        v-if="isEditable"
        :lazy="true"
        :value="contentBlock.content"
        :is-focus="isFocus"
        @input="val => $emit('update', { content: val })"
      />
      <div
        v-else
        :class="['editor-content table-editor-content', 'editor-content-preview']"
        v-html="contentBlock.content"
      />
    </section>
  </div>
</LoaderWebFont>
