<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-fragment>
    <!-- Custom Toolbar -->
    <!-- QA Input Type settings -->
    <DocumentContentBlockQAToolbarPortal
      :is-editable="isEditable"
      :node-id="nodeId"
      :input-type="inputType"
      :input-types="inputTypes"
      @select="onInputTypeSelect"
    >
      <component
        :is="inputType.settingsComponent"
        :value="contentBlock.settings"
        :popover-target-id="settingsPopoverTargetId"
        @input="(settings, data) => $emit('update', { settings, ...data })"
        @upload-start="handleUploadStart"
        @upload-done="handleUploadDone"
      />
    </DocumentContentBlockQAToolbarPortal>

    <!-- QA Block Container -->
    <div
      :class="[
        $style['qa-container'],

      ]"
      data-intercom-target="Q&amp;A Block"
    >
      <!-- Answer saved notification -->
      <transition
        name="synced"
        appear
      >
        <div
          v-if="isRecentlySaved"
          :class="[
            'absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-0',
            'flex items-center text-green-700 text-13',
            'bg-green-200 bg-opacity-60 rounded-t-md py-2 pl-4 pr-6'
          ]"
        >
          <span class="mr-4 text-green-700 icon-sync-done text-18 w-18"></span>
          {{ $t('blocks.qa.answer-saved') }}
        </div>
      </transition>

      <!-- Title & Description -->
      <div :class="$style['q-container']" @contextmenu.stop>
        <!-- Title if available -->
        <div
          class="relative flex flex-row items-center justify-start"
        >
          <span
            :class="[
              $style[`text-variant-${textVariant}`],
              $style['q-index'],
              !contentBlock.isDirty
                ? 'bg-yellow-50 text-gray-800'
                : 'bg-green-100 text-green-700 ring-2 ring-green-200 ',
              ['XXS','XS','S'].includes(nodeWidth) && 'q-index-mobile'
            ]"
          >
            {{ indexByCategory + 1 }}
            <span
              v-if="contentBlock.isDirty"
              :class="[
                'absolute w-14 h-14 -bottom-6 right-6',
                'flex items-center justify-center rounded-full',
                'bg-green-200 text-green-700 text-12 font-semibold',
              ]"
            >
              <span class="icon_v2-so_tick text-10" />
            </span>
          </span>

          <!-- Question Title -->
          <ContentEditable
            tag="h5"
            :autofocus="isFocus"
            placeholder="Untitled question..."
            :lazy="true"
            :no-newline="true"
            :no-html="true"
            :contenteditable="isEditable"
            :class="[$style['q-title'], $style[`text-variant-${textVariant}`], 'portal-heading-font']"
            :value="contentBlock.title"
            @input="val => $emit('update', { title: val })"
          />
        </div>
        <!-- Question Description Editable -->
        <TextEditor
          v-if="isEditable && settings.description"
          placeholder="Add instructions..."
          :lazy="true"
          :class="[
            $style['q-instructions'],
            $style[`text-variant-${textVariant}`],
            'editor-content',
            'portal-paragraph-font',
            ['XXS','XS','S'].includes(nodeWidth) && 'q-instructions-mobile'
          ]"
          :show-floating-menu="false"
          :value="contentBlock.description"
          :editor-features="textEditorFeatures"
          @input="val => $emit('update', { description: val })"
        />
        <!-- Question Description non-editable -->
        <div
          v-if="!isEditable && settings.description"
          :class="[
            $style['q-instructions'],
            $style[`text-variant-${textVariant}`],
            'editor-content',
            'editor-content-preview',
            'portal-paragraph-font',
          ]"
          v-html="contentBlock.description"
        />
      </div>
      <!-- Input Type -->
      <div
        :class=" [$style['a-container'],
                  ['XXS','XS','S'].includes(nodeWidth) && 'a-container-mobile']"
      >
        <span
          v-if="!contentBlock.settings.hasTitle"
          :class="[
            $style[`text-variant-${textVariant}`],
            $style['q-index'],
            !contentBlock.isDirty
              ? 'bg-yellow-50 text-gray-800'
              : 'bg-green-100 text-green-700',
          ]"
        >
          {{ indexByCategory + 1 }}
          <span
            v-if="contentBlock.isDirty"
            :class="[
              'absolute w-50 h-20 -top-8 -right-8',
              'flex items-center justify-center rounded-full',
              'bg-green-200 text-green-700 text-12 print:hidden',
            ]"
          >
            <span class="w-12 icon_v2-so_tick text-12" />
            SAVED
          </span>
        </span>

        <component
          :is="inputType.component"
          :content-block="contentBlock"
          :is-answerable="isAnswerable"
          :text-variant="textVariant"
          :is-empty="!contentBlock.isDirty"
          :node-id="nodeId"
          :node-width="nodeWidth"
          @update="updateAnswer"
          @reset="resetAnswer"
          @async-save-start="$emit('async-save-start')"
        />

        <!-- Show a generic placeholder if there is no answer
            and the document is locked / approved -->
        <div
          v-if="!contentBlock.isDirty && isLocked"
          :class="[
            'inline-flex flex-col items-center justify-center px-8 py-2',
            'mt-8 text-gray-600 bg-gray-100 rounded-md',
            'portal-paragraph-font'
          ]"
        >
          No answer
        </div>
      </div>
    </div>
    <!-- -------------------- -->
    <!-- TOGGLE NODE SETTINGS -->
    <!-- -------------------- -->
    <div
      v-if="isEditable"
      :class="[
        'mt-16 space-x-4 bg-gray-100 p-4 rounded-md inline-flex transition-opacity print:hidden',
        isHover || isFocus ? 'opacity-100' : 'opacity-30',
        $style['i-index']
      ]"
    >
      <b-button
        variant="neutral"
        size="xs"
        @click="openSettings"
      >
        <span class="mr-6 icon-toolbar-settings" />
        Edit Question
      </b-button>
      <b-button
        variant="neutral"
        size="xs"
        @click.stop="resetAnswer"
      >
        <span class="mr-4 icon_v2-so_close" />
        Reset Answer
      </b-button>
    </div>
  </div>
</template>

<script>
import { indexBy, prop, pathOr } from 'ramda'
import { computed, inject, getCurrentInstance, ref, defineComponent, onMounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import debounce from '@/v2/lib/helpers/debounce'
import { CATEGORY } from '@/v2/services/contentBlocks/contentBlocksTypes'
import { EDITOR_FEATURES } from '@/types/editor'

import ContentEditable from '@/components/ContentEditable.vue'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import DocumentNodeEditorToolbarMenu from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarMenu.vue'
import DocumentNodeEditorToolbarMenuItem from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarMenuItem.vue'
import DocumentNodeEditorToolbarGroup from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarGroup.vue'
import DocumentNodeEditorToolbarSeparator from '@/v2/features/document/documentNodeEditor/DocumentNodeEditorToolbarSeparator.vue'

// Long Text
import contentBlocksDefaults from '@/v2/services/contentBlocks/contentBlocksDefaults'
import DocumentContentBlockQAToolbarPortal from './DocumentContentBlockQAToolbarPortal.vue'
// Long Text
import DocumentContentBlockQALongText from './DocumentContentBlockQALongText.vue'
import DocumentContentBlockQALongTextSettings from './DocumentContentBlockQALongTextSettings.vue'
// Multiple Choice
import DocumentContentBlockQAMultipleChoice from './DocumentContentBlockQAMultipleChoice.vue'
import DocumentContentBlockQAMultipleChoiceSettings from './DocumentContentBlockQAMultipleChoiceSettings.vue'
// Date
import DocumentContentBlockQADate from './DocumentContentBlockQADate.vue'
import DocumentContentBlockQADateSettings from './DocumentContentBlockQADateSettings.vue'
// File Upload
import DocumentContentBlockQAFileUpload from './DocumentContentBlockQAFileUpload.vue'
import DocumentContentBlockQAFileUploadSettings from './DocumentContentBlockQAFileUploadSettings.vue'
// Rating
import DocumentContentBlockQARating from './DocumentContentBlockQARating.vue'
import DocumentContentBlockQARatingSettings from './DocumentContentBlockQARatingSettings.vue'
// Slider
import DocumentContentBlockQASlider from './DocumentContentBlockQASlider.vue'
import DocumentContentBlockQASliderSettings from './DocumentContentBlockQASliderSettings.vue'
// 2D Slider
import DocumentContentBlockQASlider2D from './DocumentContentBlockQASlider2D.vue'
import DocumentContentBlockQASlider2DSettings from './DocumentContentBlockQASlider2DSettings.vue'
// Image Choice
import DocumentContentBlockQAImageChoice from './DocumentContentBlockQAImageChoice.vue'
import DocumentContentBlockQAImageChoiceSettings from './DocumentContentBlockQAImageChoiceSettings.vue'

// Answer Badge

const inputTypes = [
  {
    id: 'longText',
    label: 'Long Text',
    icon: 'subject',
    component: DocumentContentBlockQALongText,
    settingsComponent: DocumentContentBlockQALongTextSettings,
  },
  {
    id: 'multipleChoice',
    label: 'Multiple Choice',
    icon: 'checkmark-rectangle',
    component: DocumentContentBlockQAMultipleChoice,
    settingsComponent: DocumentContentBlockQAMultipleChoiceSettings,
  },

  {
    id: 'fileUpload',
    label: 'File Upload',
    icon: 'file-upload',
    component: DocumentContentBlockQAFileUpload,
    settingsComponent: DocumentContentBlockQAFileUploadSettings,
  },
  {
    id: 'rating',
    label: 'Rating',
    icon: 'star',
    component: DocumentContentBlockQARating,
    settingsComponent: DocumentContentBlockQARatingSettings,
  },
  {
    id: 'imageChoice',
    label: 'Image Choice',
    icon: 'image-favorite',
    component: DocumentContentBlockQAImageChoice,
    settingsComponent: DocumentContentBlockQAImageChoiceSettings,
  },
  {
    id: 'slider',
    label: 'Slider',
    icon: 'slider',
    component: DocumentContentBlockQASlider,
    settingsComponent: DocumentContentBlockQASliderSettings,
  },
  {
    id: '2dSlider',
    label: '2D Slider',
    icon: 'slider-2d',
    component: DocumentContentBlockQASlider2D,
    settingsComponent: DocumentContentBlockQASlider2DSettings,
  },
  // { id: 'competitors', label: 'Competitors', icon: 'util-card' },
  {
    id: 'date',
    label: 'Date',
    icon: 'calendar',
    component: DocumentContentBlockQADate,
    settingsComponent: DocumentContentBlockQADateSettings,
  },
]

const inputTypesDict = indexBy(prop('id'), inputTypes)

// // TODO: a nicer way of checking for these?
// const checkNoAnswer = props => (
//   pathIsNilOrEmpty(['contentBlock', 'answer'], props)
//     || !props.contentBlock.answer
// )

const { useMutations, useState } = createNamespacedHelpers('documentEditor')

export default defineComponent({
  name: 'DocumentContentBlockQA',
  components: {
    DocumentNodeEditorToolbarMenu,
    DocumentNodeEditorToolbarMenuItem,
    DocumentNodeEditorToolbarGroup,
    DocumentNodeEditorToolbarSeparator,
    ContentEditable,
    TextEditor,
    DocumentContentBlockQAToolbarPortal,
  },
  inheritAttrs: false,
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    indexByCategory: {
      type: Number,
      default: null,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    styling: {
      type: Object,
      required: true,
    },
    textVariant: {
      type: String,
      required: true,
    },
    isFocus: Boolean,
    isHover: {
      type: Boolean,
      default: false,
    },
    nodeWidth: {
      type: String,
      default: 'XL',
    },
  },
  setup(props, context) {
    const vm = getCurrentInstance().proxy

    const { ContentBlock } = context.root.$FeathersVuex.api
    const isEditor = inject('isEditor')
    const isLocked = inject('isLocked')
    const scrollToElementId = isEditor.value ? inject('scrollToElementId') : null
    const textEditorFeatures = [
      EDITOR_FEATURES.highlight,
      EDITOR_FEATURES.link,
      EDITOR_FEATURES.bold,
      EDITOR_FEATURES.italic,
      EDITOR_FEATURES.strike,
    ]

    const { lastCreatedNodeId } = useState(['lastCreatedNodeId'])
    const { setFilesUploadingNode } = useMutations(['setFilesUploadingNode'])

    // Refs
    const isRecentlySaved = ref(null)

    // computed
    const isEditable = computed(() => isEditor.value && !isLocked.value)
    const isAnswerable = computed(() => !isLocked.value)

    const settingsPopoverTargetId = computed(
      () => `settings-popover-${props.nodeId}`
    )

    const emitShowPopover = () => setTimeout(
      () => vm.$root.$emit('bv::show::popover', settingsPopoverTargetId.value),
      0
    )

    const openSettings = () => {
      emitShowPopover()
      scrollToElementId(`node-${props.nodeId}`, -70)
    }


    const onInputTypeSelect = inputType => {
      const {
        answer, settings, isDirty, notes,
      } = new ContentBlock({
        inputType,
        category: CATEGORY.ElementQA,
      })
      context.emit('update', {
        inputType, answer, settings, isDirty, notes,
      })
      openSettings()
    }

    const inputType = computed(
      () => inputTypesDict[props.contentBlock.inputType]
    )

    const settings = computed(() => pathOr({}, ['contentBlock', 'settings'], props))

    const resetAnswer = () => {
      context.emit('update', {
        isDirty: false,
        answer: contentBlocksDefaults().ElementQA[props.contentBlock.inputType].answer,
      })
    }

    // Show the "Asnwer saved" notification for 2 seconds, but with a
    // debounce so it doesn't flicker
    const showAnswerSavedNotification = debounce(() => {
      isRecentlySaved.value = true
      setTimeout(() => {
        isRecentlySaved.value = false
      }, 2000);
    }, 1200)

    const updateAnswer = data => {
      context.emit('update', data)
      showAnswerSavedNotification()
    }

    // set isUploading flag to true while data is being uploaded on this node

    const handleUploadStart = () => {
      setFilesUploadingNode({ nodeId: props.nodeId, isUploading: true })
      context.emit('async-save-start')
    }
    const handleUploadDone = () => {
      setFilesUploadingNode({ nodeId: props.nodeId, isUploading: false })
    }

    onMounted(() => lastCreatedNodeId.value === props.nodeId && emitShowPopover())


    return {
      inputTypes,
      inputTypesDict,
      inputType,
      textEditorFeatures,
      settings,
      settingsPopoverTargetId,

      // flags
      isEditable, // Title and description can be changed
      isAnswerable, // Answer can be added
      isLocked,
      isRecentlySaved,

      // methods
      openSettings,
      onInputTypeSelect,
      updateAnswer,
      resetAnswer,
      handleUploadStart,
      handleUploadDone,
    }
  },
})
</script>


<style lang="postcss" scoped>

.q-index-mobile {
  @apply inline-flex;
  @apply relative left-auto right-auto top-auto bottom-auto;
  @apply mr-8;
}
.a-container-mobile {
  /* @apply pl-40; */
}
.q-instructions-mobile{
  /* @apply pl-40; */
}
</style>

<style lang="postcss" module>

.text-variant-light {
  color: #fff !important;
}

.q-title {
  @apply text-18 lg:text-24 font-semibold text-gray-900 leading-snug lg:leading-snug;
}

.q-title[placeholder]:empty {
  @apply text-gray-600 font-semibold;
}

.q-instructions {
  @apply text-gray-700 mt-8;

}

.q-container {
  @apply mb-8 lg:mb-4;
}

.a-container {
  @apply mt-16 relative;
}

.q-index {
  @apply text-16 font-semibold text-center leading-none;
  @apply flex items-center justify-center ;
  @apply rounded-full;
  @apply absolute;
  @apply w-32 h-32 -left-56 top-auto;
  @apply flex-shrink-0;
}

:global(.is-edit-view) :global(.node-group-columns-container) .q-container,
:global(.is-edit-view) :global(.node-group-columns-container) .i-index,
:global(.is-edit-view) :global(.node-group-columns-container) .a-container {
  @apply ml-56;
}
:global(.node-group-columns-container) .a-container {
  @apply mr-88;
}

</style>

<style scoped>
/* Enter and leave animations */
.synced-enter-active {
  transition: all .2s ease-out;
}

.synced-leave-active {
  transition: all .1s ease-in;
}
.synced-enter {
  transform: translateY(10px) translateX(-50%);
  opacity: 0;
}
.synced-leave-to {
  opacity: 0;
  transform: translateY(5px) translateX(-50%);
}
</style>
