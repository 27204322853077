<template>
  <b-popover :custom-class="customClass" v-bind="$attrs">
    <div v-click-outside="hide">
      <slot />
    </div>
  </b-popover>
</template>

<script>
import * as R from 'ramda'
import { computed, useCssModule } from '@vue/composition-api'

const makeCustomClass = R.pipe(R.filter(Boolean), R.join(' '))

export default {
  name: 'Popover',
  props: {
    hideOnClickOutside: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const $style = useCssModule()

    const customClass = computed(() => makeCustomClass([
      $style['popover-theme'],
      props.size && $style[props.size],
      props.noPadding && $style['no-padding'],
      props.mode && $style[`mode-${props.mode}`],
    ]))
    function hide() {
      if (props.hideOnClickOutside) {
        emit('update:show', false)
      }
    }
    return {
      customClass,
      hide,
    }
  },
  // computed: {
  //   customClass() {
  //     return makeCustomClass([
  //       this.$style['popover-theme'],
  //       this.size && this.$style[this.size],
  //       this.noPadding && this.$style['no-padding'],
  //       this.mode && this.$style[`mode-${this.mode}`],
  //     ])
  //   },
  // },
  // methods: {
  //   hide() {
  //     if (this.hideOnClickOutside) {
  //       this.$emit('update:show', false)
  //     }
  //   },
  // },
}
</script>
<style lang="scss" module>
.popover-theme {
  @apply shadow-2xl;
  @apply rounded border-none;
  // box-shadow: 0px 4px 12px rgba(54, 60, 79, 0.21);
  // border-radius: 4px;
  border: none;
}

.no-padding {
  & > :global(.popover-body) {
    padding: 0;
  }
}

.large {
  max-width: 480px;
}

.mode-rich {
  width: 280px;
  margin-left: 6px;
  :global(.arrow) {
    display: none;
  }
}
</style>
