
<DocumentNodeRenderer
  :node="node"
  :media-print-hide="isEmpty"
  :root-class="[
    'node-group-columns-container',
    $props.node.styling.stack && 'stacked-on-mobile'
  ]"
>
  <section
    v-if="!isEmpty"
    class="w-full flex items-stretch flex-col lg:flex-row"
    :class="$props.node.styling.stack ? 'screen:cols-stacked-on-mobile' : 'cols-on-desktop'"
  >
    <DocumentNode
      v-for="(nodeId, index) in children"
      :key="nodeId"
      :node-id="nodeId"
      :parent="node._id"
      :index="index"
      :is-first="index === 0"
      :is-last="index === children.length - 1"
      :width="`${ratios[index]}%`"
      :style="isMobile && 'width: auto;'"
    />
  </section>
</DocumentNodeRenderer>
