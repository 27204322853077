// import { tap } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { rejectNil } from 'ramda-extension'
import { CATEGORY, FOLDER_CATEGORIES } from './documentsTypes'

const folderCategorySet = new Set(FOLDER_CATEGORIES)

const buildProjectTree = (folder, getters) => ({
  folder,
  children: (folder.documents ?? [])
    .filter(({ category }) => folderCategorySet.has(category))
    .map(({ document: docId }) => getters.get(docId))
    .filter(isNotNil)
    .map(childFolder => buildProjectTree(childFolder, getters)),
})

const buildIndexedTrees = (rootFolders, getters) => rootFolders.reduce(
  (acc, rootFolder) => ({
    ...acc,
    [rootFolder.project]: buildProjectTree(rootFolder, getters),
  }),
  {}
)


export default () => /** @type {import('vuex').Module} */ ({
  state: {
    folderTreeRootCategory: CATEGORY.ProjectRootFolder,
  },
  mutations: {
    setFolderTreeRootCategory(state, value) {
      // eslint-disable-next-line no-param-reassign
      state.folderTreeRootCategory = value
    },
  },
  /** CUSTOM ACTIONS */
  actions: {
    /**
     * Remove document + all related data (structure, nodes, blocks, comments, etc) from store
     * @param {import('vuex').ActionContext} actionContext
     * @param {string} id
     */
    purgeFromStore({ commit, rootGetters }, id) {
      // -- remove document
      commit('removeItem', id)

      // -- remove all related data
      const namespaces = [
        'document-structures',
        'document-nodes',
        'content-blocks',
        'action-items',
        'comments',
        'notifications',
      ]

      const query = { document: id }

      namespaces.forEach(ns => {
        const { data: items } = rootGetters[`${ns}/find`]({ query })
        commit(`${ns}/removeItems`, items, { root: true })
      })
    },
    /**
     * Load all folders for a organization id (for all projects)
     * @param {import('vuex').ActionContext} actionContext
     * @param {string} organizationId
     */
    loadFolders({ commit, dispatch }, payload) {
      const {
        organizationId,
        projectId,
        folderTreeRootCategory = CATEGORY.ProjectRootFolder,
      } = payload

      if (isNotNil(folderTreeRootCategory)) {
        commit('setFolderTreeRootCategory', folderTreeRootCategory)
      }

      const folderCategories = folderTreeRootCategory === CATEGORY.ProjectRootFolder
        ? FOLDER_CATEGORIES
        : FOLDER_CATEGORIES.filter(c => c !== CATEGORY.ProjectRootFolder)

      const query = rejectNil({
        organization: organizationId,
        project: projectId,
        category: { $in: folderCategories },
      })

      return dispatch('find', { query })/* .then(tap(console.log)) */
    },
  },
  /** CUSTOM GETTERS */
  getters: {
    folderTreeByProject(state, getters) {
      const { data } = getters.find({ query: { category: state.folderTreeRootCategory } })
      return buildIndexedTrees(data, getters)
    },
  },
})
