
<portal to="page-header">
  <div class="flex items-center gap-x-4 h-full flex-1">
    <ButtonTransparent
      v-if="showBack"
      @click="$emit('back')"
    >
      <span class="icon_v2-so_direction-left" />
    </ButtonTransparent>
    <div class="flex flex-1 justify-between items-center">
      <h2 v-if="title" class="font-semibold text-16">{{ title }}</h2>
      <slot />
      <div class="flex items-center gap-x-16">
        <slot name="actions" />
        <PageHeaderSearch
          v-if="search"
          v-model="keyword"
          :show-result="showSearchResult"
          :placeholder="searchPlaceholder"
          @update:show-result="val => $emit('update:show-search-result', val)"
          @close="closeSearchResult"
        >
          <slot name="search-result" />
        </PageHeaderSearch>
        <slot name="filter" />
      </div>
    </div>
  </div>
</portal>
