import { computed } from '@vue/composition-api';
import useCurrentOrganization from './useCurrentOrganization';

/**
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 * @template T
 */

/**
 * @param {string[]} flags Properties of organization.featureFlags
 */
export default function useFeatureFlags(flags) {
  const organization = useCurrentOrganization()

  /** @type {Record<string, Ref<boolean>} */
  const featureFlags = flags.reduce((acc, flag) => {
    const valRef = computed(() => organization.value?.featureFlags?.[flag] ?? false)
    return { ...acc, [flag]: valRef }
  }, {})

  return featureFlags
}
