<template>
  <div class="task-board">
    <TaskSections
      :sections="sections"
      :selected-task-id="selectedTaskId"
      :no-section-pin-to-dashboard="noSectionPinToDashboard"
      :lock-tasks-to-section="lockTasksToSection"
      :readonly-sections="readonlySections"
      :no-task-reordering="noTaskReordering"
      :no-section-menu="noSectionMenu"
      @update="(section, data) => $emit('update-section', section, data)"
      @remove="section => $emit('remove-section', section)"
      @reorder="reorderedSections => $emit('reorder-sections', reorderedSections)"
      @menu-action="(section, action) => $emit('section-menu-action', section, action)"
      @create-task="(section, index) => $emit('create-task', section, index)"
      @remove-task="(section, task) => $emit('remove-task', section, task)"
      @reorder-tasks="(section, reorderedTasks) => $emit('reorder-tasks', section, reorderedTasks)"
      @drag-event-tasks="onDragEventTasks"
    >
      <template #section-title="{ section }">
        <slot name="section-title" v-bind="{ section }" />
      </template>
      <template #task="{ section, task }">
        <slot name="task" v-bind="{ section, task }" />
      </template>
    </TaskSections>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import Button from '@/components/Button.vue'
import TaskSections from '@/components/Tasks/TaskSections.vue'

export default defineComponent({
  name: 'TaskBoard',
  components: {
    Button,
    TaskSections,
  },
  props: {
    sections: {
      type: Array,
      default: () => ([]),
    },
    selectedTaskId: {
      type: String,
      default: null,
    },
    noSectionPinToDashboard: {
      type: Boolean,
      default: false,
    },
    lockTasksToSection: {
      type: Boolean,
      default: false,
    },
    noTaskReordering: {
      type: Boolean,
      default: false,
    },
    noSectionMenu: {
      type: Boolean,
      default: false,
    },
    readonlySections: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let lastDragEvent = null
    let lastDragSection = null
    const onDragEventTasks = (section, event) => {
      if (event.moved) {
        const { oldIndex, newIndex, element: task } = event.moved
        context.emit('move-task', {
          task,
          oldSection: section,
          oldIndex,
          newSection: section,
          newIndex,
        })
        return
      }

      if (event.added) {
        lastDragSection = section
        lastDragEvent = event
        return
      }

      if (event.removed && lastDragEvent.added) {
        const { oldIndex } = event.removed
        const oldSection = section

        const { newIndex, element: task } = lastDragEvent.added
        const newSection = lastDragSection

        lastDragEvent = null
        lastDragSection = null

        context.emit('move-task', {
          task,
          oldSection,
          oldIndex,
          newSection,
          newIndex,
        })
      }
    }

    return {
      onDragEventTasks,
    }
  },
})
</script>
<style lang="postcss" scoped>
  .task-board {
    /* @apply gap-12; */
  }
</style>
