<template>
  <DocumentNodeRenderer
    :node="node"
    :media-print-hide="isBlankState"
    :root-class="[$style['node-qa']]"
    :config-override="{
      events: {
        syncStatus: true,
      },
      styling: {
        size: 'S',
        paddingTop: 40,
        paddingBottom: 40,
      },
    }"
  >
    <template #default="{ styling, textVariant, isHover, nodeWidth }">
      <DocumentContentBlockQA
        :node-id="node._id"
        :index-by-category="indexByCategory"
        :content-block="contentBlock"
        :styling="styling"
        :text-variant="textVariant"
        :is-focus="isFocus"
        :is-hover="isHover"
        :node-width="nodeWidth"
        @update="(data, options) => $emit('update', data, options)"
        @async-save-start="$emit('async-save-start')"
      />
    </template>
    <template #plugin="{ config, styling, textVariant }">
      <slot name="editor" v-bind="{ config, styling, textVariant }" />
    </template>
  </DocumentNodeRenderer>
</template>

<script>
import { pathOr } from 'ramda'
import { computed } from '@vue/composition-api'
import { pathIsNilOrEmpty } from '@/v2/lib/helpers/fp'
import { useStructure } from '@/v2/services/documentStructures/documentStructuresCompositions'
import DocumentNodeRenderer from '@/v2/features/document/documentNode/DocumentNodeRenderer.vue'
import DocumentContentBlockQA from './DocumentContentBlockQA.vue'

export default {
  name: 'DocumentNodeQA',
  components: {
    DocumentNodeRenderer,
    DocumentContentBlockQA,
  },
  inheritAttrs: false,
  props: {
    node: {
      type: Object,
      required: true,
    },
    contentBlock: {
      type: Object,
      required: true,
    },
    isFocus: Boolean,
  },
  setup(props) {
    const documentStructure = useStructure()
    const indexByCategory = computed(() => pathOr(
      null,
      [props.node._id, 'indexByCategory'],
      documentStructure.value.meta
    ))

    const isBlankState = computed(() => pathIsNilOrEmpty(
      ['contentBlock', 'title'],
      props
    ))

    return {
      isBlankState,
      indexByCategory,
    }
  },
}
</script>
<style lang="postcss" module>
.node-qa {
  @apply px-0;
}
</style>
