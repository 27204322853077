<script>
import { defineComponent } from '@vue/composition-api'
import { useFeatureLimit } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'FeatureLimit',
  components: {

  },
  props: {
    flag: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    ignore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { checkFeatureLimit } = useFeatureLimit()

    const checkLimit = (...args) => {
      if (props.ignore) {
        context.emit('allow', ...args)
        return
      }
      const isLimit = checkFeatureLimit(props.label, props.flag, 1)

      if (!isLimit) {
        context.emit('allow', ...args)
      }

      const [event] = args;
      event?.preventDefault?.()
    }

    return () => context.slots.default({
      checkFeatureLimit: checkLimit,
    })
  },
})
</script>
