
<div class="flex items-center gap-x-16">
  <DropdownSelect
    v-model="sortBy"
    :icon="sortByIcons[sortBy]"
    :options="optionsSortBy"
    class-name="dark:border-darkGray-700 dark:text-darkGray-400 dark:hover:text-darkGray-300"
  />
  <DropdownSelect
    v-model="viewAs"
    :icon="viewAsIcons[viewAs]"
    :options="optionsViewAs"
    class-name="dark:border-darkGray-700 dark:text-darkGray-400 dark:hover:text-darkGray-300"
  />
</div>
